import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import Select from "react-select";
import Button from "@mui/material/Button";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  ExpressWayMemberAction,
  getClasses,
  getExpressWayMemberDetail,
  getIndustryTypeList,
  getJobTitleList,
  getRegistrationMaterial,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import { formatDate, isPositiveNumbersOnly } from "../../util/utils";
import { Link } from "react-router-dom";
import Loader from "../../Components/common/Loader";
import { FormattedMessage, useIntl } from "react-intl";
import { useFormik } from "formik";
import { ApproveMemberSchema } from "../../util/validationSchema";
import { Cached } from "@mui/icons-material";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import NoDatFound from "../../Components/common/NoDatFound";
import noImage from "../../assets/img/noImage.jpeg";
import { Buffer } from "buffer";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { IconButton, Stack } from "@mui/material";
import { BsDash, BsPlus } from "react-icons/bs";
import { BsChevronLeft } from "../../reactIcons";

const indentityProof: any = [
  {
    label: "Hong Kong Identity Card",
    value: "Hong kong Id Card",
  },
  {
    label: "Passport",
    value: "Passport",
  },
];

export const CURTSYTITLE: any = {
  "0": "OTHERS",
  "1": "MR",
  "2": "MRS",
  "3": "MS",
  "4": "DR",
};

const curtsyTitle: any = [
  { title: "Mr.", value: 1 },
  { title: "Mrs.", value: 2 },
  { title: "Miss", value: 3 },
  { title: "Dr", value: 4 },
];
const MemeberDetails = () => {
  const dispatch = useDispatch();

  const urlParams = useParams();
  const userDetails = useSelector(
    (state: Store) => state.expressWayReducer.ewUserDetail,
  ) as any;
  const classData = useSelector(
    (state: Store) => state.expressWayReducer.ewClassDetail,
  ) as any;
  const isApprove = useSelector(
    (state: Store) => state.expressWayReducer.isApprove,
  ) as any;
  const isLoading = useSelector(
    (state: Store) => state.expressWayReducer.Loading.isLoading,
  ) as any;
  const industry = useSelector(
    (state: Store) => state.userDataReducer.industry,
  ) as any;
  const jobTitles = useSelector(
    (state: Store) => state.userDataReducer.jobTitles,
  ) as any;
  const paymentTypeLst = useSelector(
    (state: Store) => state.userDataReducer.paymentTypeLst,
  ) as any;
  const districtLst = useSelector(
    (state: Store) => state.userDataReducer.districtLst,
  ) as any;
  const classes = useSelector(
    (state: Store) => state.classReducer.classes || [],
  ) as any;

  const [Loading, setLoading] = useState<any>(false);
  const [userDetail, setUserDetail] = useState<any>({});
  const [selectedIndustry, setSelectedIndustry] = useState({});
  const [selectedJobTitle, setSelectedJobTitle] = useState({});
  const [selectedCTitle, setSelectedCTitle] = useState(null);
  const [selectedPaymentType, setSelectedPaymentType] = useState({});
  const [selectedIndentityType, setSelectedIdentityType] = useState({});
  const [district, setDistrict] = useState({});
  const [btnClick, setBtnClick] = useState(false);
  const [btnRjClick, setBtnRjClick] = useState(false);
  const [accounts, setAccounts] = useState<any>({
    demoLimit: 0,
    liveLimit: 0,
  });

  const [accountsCreated, setAccountsCreated] = useState<any>({
    demoLimit: 0,
    liveLimit: 0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const id: string = urlParams.id as string;
    if (id.trim().length > 0) {
      dispatch(getExpressWayMemberDetail(false, id));
      dispatch(getIndustryTypeList(false, "en"));
      dispatch(getRegistrationMaterial(false, "en"));
      dispatch(getClasses(false, 0, { by: "createdAt", order: "ASC" }));
    }
  }, []);

  useEffect(() => {
    if (!isLoading && isApprove) {
      navigate("/users");
      setBtnClick(false);
      setBtnRjClick(false);
    }
  }, [isLoading, isApprove]);

  useEffect(() => {
    const id: string = urlParams.id as string;
    if (id.trim().length > 0) {
      if (userDetails) {
        // let mobile = userDetails.phoneNumber;
        if (userDetails.phoneNumber && userDetails.phoneNumber.length > 8) {
          // mobile = mobile.substr(-8, 8);
        }
        setUserDetail({
          ...userDetails,
          remark: "",
          price: userDetails?.payment?.price || 0,
          industry: userDetails?.industry?.id || 0,
          jobTitle: userDetails?.jobTitle?.id || 0,
          // phoneNumber: mobile,
          paymentType: userDetails?.payment?.id || 0,
        });
        if (userDetails && userDetails.jobTitle) {
          dispatch(
            getJobTitleList(false, "en", parseInt(userDetails.industry.id)),
          );
        }

        if (userDetails.identityType) {
          indentityProof.map((i: any) => {
            if (i.value === userDetails.identityType) {
              setSelectedIdentityType({ label: i.label, value: i.value });
            }
          });
        }

        if (userDetails && userDetails.industry) {
          setSelectedIndustry({
            id: userDetails?.industry?.id || 0,
            name:
              userDetails?.industry?.industryLanguageDetails?.[0]?.name || "",
          });
        }
        if (userDetails && userDetails.jobTitle) {
          setSelectedJobTitle({
            id: userDetails?.jobTitle?.id || 0,
            name: userDetails?.jobTitle?.jobTitleDetails?.[0]?.name || "",
          });
        }

        if (userDetails.title) {
          curtsyTitle.map((t: any) => {
            if (t.value === parseInt(userDetails.title)) {
              setSelectedCTitle(t);
            }
          });
        }
        if (userDetails.district) {
          districtLst.map((t: any) => {
            if (
              t.districtId ===
              parseInt(userDetails?.district?.districtLanguageDetails?.[0]?.id)
            ) {
              setDistrict(t);
            }
          });
        }
      }
    }
  }, [userDetails, classData]);

  useEffect(() => {
    if (paymentTypeLst && userDetail.payment) {
      paymentTypeLst.map((i: any) => {
        if (i.paymentTypeId === userDetail?.payment?.paymentType?.id)
          setSelectedPaymentType({
            name: i.name,
            paymentTypeId: i.paymentTypeId,
          });
      });
    }
  }, [paymentTypeLst, userDetail]);

  const onClickReject = () => {
    const details = {
      id: userDetail.id,
      studentClassChoice: userDetails?.userClass[0]?.id || 1,
      remark: formik.values.remark,
    };
    setBtnRjClick(true);
    dispatch(ExpressWayMemberAction(true, details, "denied"));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: userDetail.email,
      phoneNumber: userDetail.phoneNumber,
      dialCode: userDetail?.dialCode || "852",
      preferredNickName: userDetail.preferredNickName,
      englishLastName: userDetail.englishLastName,
      englishFirstName: userDetail.englishFirstName,
      DOB: formatDate(userDetail.DOB, "YYYY-MM-DD"),
      identityId: userDetail.identityId,
      identityType: userDetail.identityType,
      chineseLastName: userDetail.chineseLastName,
      chineseFirstName: userDetail.chineseFirstName,
      jobTitle: userDetail.jobTitle,
      industry: userDetail.industry,
      countryCode: userDetail.countryCode || "",
      address: userDetail.address,
      district: userDetail?.district?.id,
      emergencyContactName: userDetail.emergencyContactName,
      emergencyContactRelationship: userDetail.emergencyContactRelationship,
      emergencyContactNumber: userDetail.emergencyContactNumber,
      price: userDetail?.payment?.price,
      title: userDetail.title,
      idImage: userDetail.idImage,
      repeater: userDetail.repeater,
      remark: userDetail.remark,
      demoLimit: userDetail.demoLimit,
      liveLimit: userDetail.liveLimit,
    },
    // validationSchema: ApproveMemberSchema,
    onSubmit: () => {
      //sashaj
      onClickApprove();
    },
  });

  const onClickApprove = () => {
    const details = {
      id: userDetail.id,
      email: formik.values.email,
      identityType: formik.values.identityType,
      identityId: formik.values.identityId.toString(),
      phoneNumber: formik.values.phoneNumber,
      dialCode: values?.dialCode || "852",
      address: formik.values.address,
      emergencyContactName: formik.values.emergencyContactName,
      emergencyContactRelationship: formik.values.emergencyContactRelationship,
      emergencyContactNumber: formik.values.emergencyContactNumber,
      preferredNickName: formik.values.preferredNickName,
      englishFirstName: formik.values.englishFirstName,
      englishLastName: formik.values.englishLastName,
      chineseFirstName: formik.values.chineseFirstName,
      chineseLastName: formik.values.chineseLastName,
      title: formik.values.title,
      district: formik.values.district,
      DOB: formatDate(formik.values.DOB, "YYYY-MM-DD"),
      jobTitle: formik.values.jobTitle,
      industry: formik.values.industry,
      idImage: formik.values.idImage,
      countryCode: formik.values.countryCode || "",
      repeater: formik.values.repeater,
      studentClassChoice: userDetail.userClass[0].id,
      remark: formik.values.remark,
      liveLimit: formik.values.liveLimit,
      demoLimit: formik.values.demoLimit,
    };
    setBtnClick(true);
    dispatch(ExpressWayMemberAction(true, details, "approve"));
  };

  const { errors, touched, values, handleBlur, handleChange } = formik;

  const toBase64 = (arr: any) => {
    const a = btoa(
      arr.reduce(
        (data: any, byte: any) => data + String.fromCharCode(byte),
        "",
      ),
    );
    return "data:image/jpg;base64," + atob(a);
  };

  useEffect(() => {
    if (userDetails && Object.keys(userDetails).length > 0 && Loading == true) {
      const user = {
        email: userDetail.email,
        phoneNumber: userDetail.phoneNumber,
        preferredNickName: userDetail.preferredNickName,
        englishLastName: userDetail.englishLastName,
        englishFirstName: userDetail.englishFirstName,
        DOB: formatDate(userDetail.DOB, "YYYY-MM-DD"),
        identityId: userDetail.identityId,
        identityType: userDetail.identityType,
        chineseLastName: userDetail.chineseLastName,
        chineseFirstName: userDetail.chineseFirstName,
        jobTitle: userDetail.jobTitle,
        industry: userDetail.industry,
        address: userDetail.address,
        district: userDetail?.district?.id,
        countryCode: userDetail.countryCode || "",
        emergencyContactName: userDetail.emergencyContactName,
        emergencyContactRelationship: userDetail.emergencyContactRelationship,
        emergencyContactNumber: userDetail.emergencyContactNumber,
        price: userDetail?.payment?.price,
        title: userDetail.title,
        idImage: userDetail.idImage,
        repeater: userDetail.repeater,
      };
      indentityProof.map((i: any) => {
        if (i.value === userDetails.identityType) {
          setSelectedIdentityType({ label: i.label, value: i.value });
        }
      });

      if (userDetails.industry) {
        setSelectedIndustry({
          id: userDetails.industry.id,
          name: userDetails.industry.industryLanguageDetails[0].name,
        });
      }
      if (userDetails.jobTitle) {
        setSelectedJobTitle({
          id: userDetails.jobTitle.id,
          name: userDetails.jobTitle.jobTitleDetails[0].name,
        });
      }
      if (userDetails.title) {
        curtsyTitle.map((t: any) => {
          if (t.value === parseInt(userDetails.title)) {
            setSelectedCTitle(t);
          }
        });
      }
      if (paymentTypeLst && userDetail.payment) {
        paymentTypeLst.map((i: any) => {
          if (i.paymentTypeId === userDetail?.payment?.paymentType?.id)
            setSelectedPaymentType({
              name: i.name,
              paymentTypeId: i.paymentTypeId,
            });
        });
      }

      for (const [key, value] of Object.entries(user)) {
        formik.setFieldValue(key, value);
        formik.setFieldTouched(key, false);
      }
    }
    setLoading(false);
  }, [Loading]);

  const resetFormValues = () => {
    setLoading(true);
  };

  const onIndustryChange = (option: any) => {
    setSelectedIndustry(option);
    setSelectedJobTitle({});
    formik.setFieldValue("industry", option.id);
    formik.setFieldValue("jobTitle", 0);
    dispatch(getJobTitleList(false, "EN", option.id));
  };

  const encode = (data: any) => {
    const buf = Buffer.from(data);
    const base64 = buf.toString("base64");
    toBase64(data);
    return base64;
  };

  const onAdminAllowedText = (e: any, id: any) => {
    const account = accounts;
    const accountCreated = accountsCreated;

    if (id === "d") {
      let demoL = accounts.demoLimit !== null ? accounts.demoLimit : 0;
      if (e === "+") {
        demoL++;
        setAccounts((prv: any) => ({ ...prv, demoLimit: demoL }));
      } else {
        const demoCreated = accountCreated.demoAccountCreated;
        demoL--;

        setAccounts((prv: any) => ({ ...prv, demoLimit: demoL }));

        if (demoL <= 0) {
          setAccounts((prv: any) => ({ ...prv, demoLimit: null }));

          // toast.warn("Limit can not be less than accounts created")
        }

        // }
      }
    }

    if (id === "l") {
      let liveL = accounts.liveLimit !== null ? accounts.liveLimit : 0;
      if (e === "+") {
        liveL++;
        setAccounts((prv: any) => ({ ...prv, liveLimit: liveL }));
      } else {
        const liveCreated = accountCreated.liveAccountCreated;
        liveL--;
        // if (liveL >= liveCreated) {
        //   setAccounts((prv: any) => ({ ...prv, liveLimit: liveL }));
        // }
        // else {
        //   if (liveL > 0) {
        //     toast.warn("Limit can not be less than accounts created")
        //   }

        setAccounts((prv: any) => ({ ...prv, liveLimit: liveL }));
        if (liveL <= 0) {
          setAccounts((prv: any) => ({ ...prv, liveLimit: null }));
          // toast.warn("Limit can not be less than accounts created")
        }
        // }
      }
    }
  };

  const handleOnChange = (
    value: any,
    data: any,
    event: any,
    formattedValue: any,
  ) => {
    // this.setState({ rawPhone: value.slice(data.dialCode.length) })
    // formik.setFieldValue("phoneNumber", value);
    formik.setFieldValue("dialCode", data.dialCode);
    formik.setFieldValue("phoneNumber", value.slice(data.dialCode.length));

    formik.setFieldValue("countryCode", data.countryCode);
  };

  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex flex-wrap align-items-center">
            <div className="mr-auto">
              <h1>
                <FormattedMessage
                  id="head.UserDetails"
                  defaultMessage="User Details"
                />
              </h1>
            </div>
            <Link to="/users" className="btn btn-secondary">
              <i className="back-button">
                <BsChevronLeft /> &nbsp;
              </i>
              <FormattedMessage id="page.back" defaultMessage="Back" />
            </Link>
          </div>
        </header>
        <section className="page-content container-fluid">
          {/* {isLoading && <Loader />} */}
          {Loading && <CenterLoader />}

          {Object.keys(userDetail).length <= 0 && !isLoading ? (
            <div className="card">
              <div className="card-body p-0">
                <div className="no-data d-flex align-items-center justify-content-center flex-column p-4 p-md-5">
                  <div className="icon mb-4">
                    <i className="la la-5x la-clipboard-list text-muted"></i>
                  </div>
                  <NoDatFound />
                  {/* <h3 className="font-size-24 font-weight-600"> No data found</h3>
                                    <p className="font-size-18">There are no matching details</p> */}
                </div>
              </div>
            </div>
          ) : (
            <Card>
              <form className="form-horizontal">
                <Card.Body>
                  <Row>
                    <Col>
                      {" "}
                      <h4 className="font-weight-500">
                        <FormattedMessage
                          id="head.PersonalInformation"
                          defaultMessage="Personal Information"
                        />
                      </h4>
                    </Col>
                    <Col style={{ textAlign: "end" }}>
                      {" "}
                      <Button onClick={resetFormValues}>
                        <Cached />
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col sm={12} md={6} lg={6}>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <th style={{ width: "25%" }}></th>
                          <td>
                            <div className="ueser-img150 mb-3">
                              {userDetail?.avatar ? (
                                <img src={userDetail?.avatar} />
                              ) : (
                                <img
                                  className="image"
                                  style={{
                                    height: "100px",
                                    width: "100px",
                                    border: "0px solid #efefef",
                                  }}
                                  src={noImage}
                                />
                              )}
                            </div>
                          </td>
                        </tr>
                      </table>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <th style={{ width: "22%" }}>
                            <label className="mb-0">
                              <FormattedMessage
                                id="table.head.EnglishLastName"
                                defaultMessage="English Last Name"
                              />
                            </label>
                          </th>
                          <td>
                            <input
                              type="text"
                              name="englishLastName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control form-control-md form-group"
                              value={values.englishLastName}
                            />
                            {errors.englishLastName &&
                              touched.englishLastName && (
                                <div className="text-error">
                                  <FormattedMessage
                                    id={errors.englishLastName.toString()}
                                    defaultMessage="This field is required"
                                  />
                                </div>
                              )}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "22%" }}>
                            <label className="mb-0">
                              <FormattedMessage
                                id="table.head.EnglishFirstName"
                                defaultMessage="English First Name"
                              />
                            </label>
                          </th>
                          <td>
                            <input
                              type="text"
                              name="englishFirstName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control form-control-md form-group"
                              value={values.englishFirstName}
                            />
                            {errors.englishFirstName &&
                              touched.englishFirstName && (
                                <div className="text-error">
                                  <FormattedMessage
                                    id={errors.englishFirstName.toString()}
                                    defaultMessage="This field is required"
                                  />
                                </div>
                              )}
                          </td>
                        </tr>

                        <tr>
                          <th style={{ width: "24%" }}>
                            <label className="mb-0">
                              <FormattedMessage
                                id="table.head.ChineseLastName"
                                defaultMessage="Chinese Last Name"
                              />
                            </label>{" "}
                          </th>
                          <td>
                            <input
                              type="text"
                              name="chineseLastName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.chineseLastName}
                              className="form-control form-control-md form-group"
                            />
                            {errors.chineseLastName &&
                              touched.chineseLastName && (
                                <div className="text-error">
                                  <FormattedMessage
                                    id={errors.chineseLastName.toString()}
                                    defaultMessage="This field is required"
                                  />
                                </div>
                              )}
                          </td>
                        </tr>

                        <tr>
                          <th style={{ width: "24%" }}>
                            <label className="mb-0">
                              <FormattedMessage
                                id="table.head.ChinesefirstName"
                                defaultMessage="Chinese First Name"
                              />
                            </label>
                          </th>
                          <td>
                            <input
                              type="text"
                              name="chineseFirstName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.chineseFirstName}
                              className="form-control form-control-md form-group"
                            />
                            {errors.chineseFirstName &&
                              touched.chineseFirstName && (
                                <div className="text-error">
                                  <FormattedMessage
                                    id={errors.chineseFirstName.toString()}
                                    defaultMessage="This field is required"
                                  />
                                </div>
                              )}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "20%" }}>
                            <label className="mb-0">
                              <FormattedMessage
                                id="head.PrefferdNickname"
                                defaultMessage="Prefer Nickname"
                              />
                            </label>{" "}
                          </th>
                          <td>
                            <input
                              type="text"
                              name="preferredNickName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.preferredNickName}
                              className="form-control form-control-md form-group"
                            />
                            {errors.preferredNickName &&
                              touched.preferredNickName && (
                                <div className="text-error">
                                  <FormattedMessage
                                    id={errors.preferredNickName.toString()}
                                    defaultMessage="This field is required"
                                  />
                                </div>
                              )}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "20%" }}>
                            <label className="mb-0">
                              <FormattedMessage
                                id="Admin.Title.Announcement"
                                defaultMessage="Title"
                              />
                            </label>
                          </th>
                          <td>
                            <Select
                              options={curtsyTitle}
                              getOptionLabel={(option: any) => option.title}
                              getOptionValue={(option: any) => option.value}
                              value={selectedCTitle}
                              onChange={(option: any) => {
                                formik.setFieldValue("title", option.value);
                                setSelectedCTitle(option);
                              }}
                              onBlur={() =>
                                formik.setFieldTouched("title", true)
                              }
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                              // placeholder={useIntl().formatMessage({
                              //   id: "select.title",
                              //   defaultMessage: "Select Title",
                              // })}
                            />
                          </td>
                        </tr>
                      </table>
                    </Col>

                    <Col sm={12} md={6} lg={6}>
                      <table>
                        <tr>
                          <th style={{ width: "20%" }}>
                            <label className="mb-0">
                              <FormattedMessage
                                id="table.head.DateofBirth"
                                defaultMessage="Birthday"
                              />
                            </label>{" "}
                          </th>
                          <td>
                            <input
                              type="date"
                              className="form-control form-control-md form-group"
                              name="DOB"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.DOB}
                            />
                            {errors.DOB && touched.DOB && (
                              <div className="text-error">
                                <FormattedMessage
                                  id={errors.DOB.toString()}
                                  defaultMessage="This field is required"
                                />
                              </div>
                            )}
                          </td>
                        </tr>

                        <tr>
                          <th style={{ width: "20%" }}>
                            <label className="mb-0">
                              <FormattedMessage
                                id="table.head.Email"
                                defaultMessage="Email"
                              />
                            </label>
                          </th>
                          <td>
                            <input
                              type="text"
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              className="form-control form-control-md form-group"
                            />
                            {errors.email && touched.email && (
                              <div className="text-error">
                                <FormattedMessage
                                  id={errors.email.toString()}
                                  defaultMessage="This field is required"
                                />
                              </div>
                            )}
                          </td>
                        </tr>

                        <tr>
                          <th style={{ width: "20%" }}>
                            <label className="mb-0">
                              <FormattedMessage
                                id="head.PhoneNumber"
                                defaultMessage="Phone Number"
                              />
                            </label>{" "}
                          </th>
                          <td>
                            <ReactPhoneInput
                              // country={"hk"}
                              // value="1425652"
                              // value={values.phoneNumber}
                              value={values.dialCode + values.phoneNumber}
                              onChange={handleOnChange}
                              onBlur={() =>
                                formik.setFieldTouched("phoneNumber", true)
                              }
                              // name="phoneNumber"
                              // defaultCountry="it"
                              // preferredCountries={["it", "se"]}
                              // name="phoneNumber"
                              // defaultCountry="it"
                              // preferredCountries={["it", "se"]}
                              // name="phoneNumber"

                              // defaultCountry="it"
                              // preferredCountries={["it", "se"]}
                            />
                            {/* <input
                              type="text"
                              name="phoneNumber"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.phoneNumber}
                              className="form-control form-control-md"
                            /> */}
                            {errors.phoneNumber && touched.phoneNumber && (
                              <div className="text-error">
                                <FormattedMessage
                                  id={errors.phoneNumber.toString()}
                                  defaultMessage="This field is required"
                                />
                              </div>
                            )}
                          </td>
                        </tr>

                        {/* <tr>
                          <th style={{ width: "20%" }}>
                            <label className="mb-0">
                              <FormattedMessage
                                id="table.head.Address"
                                defaultMessage="Address"
                              />
                            </label>{" "}
                          </th>
                          <td>
                            <input
                              type="text"
                              className="form-control form-control-md form-group"
                              name="address"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.address}
                            />
                            {errors.address && touched.address && (
                              <div className="text-error">
                                <FormattedMessage
                                  id={errors.address.toString()}
                                  defaultMessage="This field is required"
                                />
                              </div>
                            )}
                          </td>
                        </tr> */}

                        {/* <tr>
                          <th style={{ width: "18%" }}>
                            <label className="mb-0">
                              {" "}
                              <FormattedMessage
                                id="table.head.EmergencyName"
                                defaultMessage="Emergency Name"
                              />
                            </label>{" "}
                          </th>
                          <td>
                            <input
                              type="text"
                              name="emergencyContactName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.emergencyContactName}
                              className="form-control form-control-md form-group"
                            />
                            {errors.emergencyContactName &&
                              touched.emergencyContactName && (
                                <div className="text-error">
                                  <FormattedMessage
                                    id={errors.emergencyContactName.toString()}
                                    defaultMessage="This field is required"
                                  />
                                </div>
                              )}
                          </td>
                        </tr> */}

                        <tr>
                          <th style={{ width: "20%" }}>
                            <label className="mb-0">
                              <FormattedMessage
                                id="table.head.District"
                                defaultMessage="District"
                              />
                            </label>{" "}
                          </th>
                          <td>
                            <Select
                              options={districtLst}
                              value={district}
                              getOptionLabel={(option: any) => option.name}
                              getOptionValue={(option: any) =>
                                option.districtId
                              }
                              onChange={(option: any) => {
                                setDistrict(option);
                                formik.setFieldValue(
                                  "district",
                                  option.districtId,
                                );
                              }}
                              onBlur={() =>
                                formik.setFieldTouched("district", true)
                              }
                              className="form-group"
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                              // isMulti
                              // isDisabled
                            />
                            {errors.address && touched.address && (
                              <div className="text-error">
                                <FormattedMessage
                                  id={errors.address.toString()}
                                  defaultMessage="This field is required"
                                />
                              </div>
                            )}
                          </td>
                        </tr>

                        {/* <tr>
                          <th style={{ width: "20%" }}>
                            <label className="mb-0">
                              <FormattedMessage
                                id="head.EmergencyContactName"
                                defaultMessage="Emergency Contact Name"
                              />
                            </label>
                          </th>
                          <td>
                            <input
                              type="text"
                              name="emergencyContactName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.emergencyContactName}
                              className="form-control form-control-md form-group"
                            />
                            {errors.emergencyContactName &&
                              touched.emergencyContactName && (
                                <div className="text-error">
                                  <FormattedMessage
                                    id={errors.emergencyContactName.toString()}
                                    defaultMessage="This field is required"
                                  />
                                </div>
                              )}
                          </td>
                        </tr> */}

                        {/* <tr>
                          <th style={{ width: "25%" }}>
                            <label className="mb-0">
                              <FormattedMessage
                                id="head.EmergencyContactRelationship"
                                defaultMessage="Emergency Contact Relationship"
                              />
                            </label>
                          </th>
                          <td>
                            <input
                              type="text"
                              name="emergencyContactRelationship"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.emergencyContactRelationship}
                              className="form-control form-control-md form-group"
                            />
                            {errors.emergencyContactRelationship &&
                              touched.emergencyContactRelationship && (
                                <div className="text-error">
                                  <FormattedMessage
                                    id={errors.emergencyContactRelationship.toString()}
                                    defaultMessage="This field is required"
                                  />
                                </div>
                              )}
                          </td>
                        </tr> */}

                        {/* <tr>
                          <th style={{ width: "20%" }}>
                            <label className="mb-0">
                              <FormattedMessage
                                id="head.EmergencyContact"
                                defaultMessage="Emergency Contact"
                              />
                            </label>
                          </th>
                          <td>
                            <ReactPhoneInput
                              country={"hk"}
                              value={values.emergencyContactNumber}
                              onChange={(value: any) => {
                                formik.setFieldValue(
                                  "emergencyContactNumber",
                                  value
                                );
                              }}
                              onBlur={() =>
                                formik.setFieldTouched(
                                  "emergencyContactNumber",
                                  true
                                )
                              }
                            />

                            {errors.emergencyContactNumber &&
                              touched.emergencyContactNumber && (
                                <div className="text-error">
                                  <FormattedMessage
                                    id={errors.emergencyContactNumber.toString()}
                                    defaultMessage="This field is required"
                                  />
                                </div>
                              )}
                          </td>
                        </tr> */}
                      </table>
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col sm={4} md={4} lg={5}>
                      <h4 className="font-weight-500">
                        <FormattedMessage
                          id="head.CoursePaymentInformation"
                          defaultMessage="Course & Payment Information"
                        />{" "}
                      </h4>
                    </Col>
                    <Col sm={3} md={3} lg={2}></Col>
                    <Col sm={4} md={4} lg={5}>
                      <h4 className="font-weight-500">
                        <FormattedMessage
                          id="head.JobInformation"
                          defaultMessage="Job Information"
                        />{" "}
                      </h4>
                    </Col>
                  </Row>
                  <hr />

                  <Row>
                    <Col sm={12} md={12} lg={6}>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <th style={{ width: "25%" }}></th>
                          <td>
                            <div className="ueser-img150 mb-3">
                              {userDetail?.payment ? (
                                <img
                                  src={userDetail?.payment.slipPath || noImage}
                                />
                              ) : (
                                <p>
                                  <img
                                    className="image"
                                    style={{
                                      height: "150px",
                                      width: "150px",
                                      border: "0px solid #efefef",
                                    }}
                                    src={noImage}
                                  />
                                </p>
                              )}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "20%" }}>
                            <label className="mb-0">
                              <FormattedMessage
                                id="head.ClassNameID"
                                defaultMessage="Class Name/ID"
                              />
                            </label>
                          </th>
                          <td>
                            <Select
                              options={classes}
                              value={classData}
                              getOptionValue={(option: any) => option.class.id}
                              getOptionLabel={(option: any) =>
                                option.class.name + "/" + option.class.id
                              }
                              isMulti
                              isDisabled
                              className="form-group"
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "22%" }}>
                            <label className="mb-0">
                              {" "}
                              <FormattedMessage
                                id="head.PaymentMethod"
                                defaultMessage="Payment Method"
                              />
                            </label>
                          </th>
                          <td>
                            <Select
                              name="toolType"
                              value={selectedPaymentType}
                              onChange={(option: any) => {
                                setSelectedPaymentType(option);
                                formik.setFieldValue(
                                  "paymentType",
                                  option.paymentTypeId,
                                );
                              }}
                              onBlur={() =>
                                formik.setFieldTouched("paymentType", true)
                              }
                              options={paymentTypeLst}
                              getOptionLabel={(option: any) => option.name}
                              getOptionValue={(option: any) =>
                                option.paymentTypeId
                              }
                              className="form-group"
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                            />{" "}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "20%" }}>
                            <label className="mb-0">
                              <FormattedMessage
                                id="head.Prices"
                                defaultMessage="Prices"
                              />
                            </label>
                          </th>
                          <td>
                            <input
                              type="text"
                              className="form-control form-control-md form-group"
                              name="price"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.price}
                            />
                            {errors.price && touched.price && (
                              <div className="text-error">
                                <FormattedMessage
                                  id={errors.price.toString()}
                                  defaultMessage="This field is required"
                                />
                              </div>
                            )}
                          </td>
                        </tr>
                      </table>
                    </Col>
                    <Col sm={12} md={12} lg={6}>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <th style={{ width: "22%" }}>
                            <label className="mb-0">
                              <FormattedMessage
                                id="head.Industry"
                                defaultMessage="Industry"
                              />
                            </label>
                          </th>
                          <td>
                            <Select
                              name="toolType"
                              value={selectedIndustry}
                              onChange={(option: any) => {
                                onIndustryChange(option);
                              }}
                              onBlur={() =>
                                formik.setFieldTouched("industry", true)
                              }
                              options={industry}
                              getOptionLabel={(option: any) => option.name}
                              getOptionValue={(option: any) => option.id}
                              className="form-group"
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "22%" }}>
                            <label className="mb-0">
                              <FormattedMessage
                                id="head.Job"
                                defaultMessage="Job"
                              />
                            </label>
                          </th>
                          <td>
                            <Select
                              name="toolType"
                              value={selectedJobTitle}
                              onChange={(option: any) => {
                                setSelectedJobTitle(option);
                                formik.setFieldValue("jobTitle", option.id);
                              }}
                              onBlur={() =>
                                formik.setFieldTouched("jobTitle", true)
                              }
                              options={jobTitles}
                              getOptionLabel={(option: any) => option.name}
                              getOptionValue={(option: any) => option.id}
                              className="form-group"
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                            />{" "}
                          </td>
                        </tr>
                      </table>
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col sm={4} md={4} lg={5}>
                      <h4 className="font-weight-500">
                        <FormattedMessage
                          id="head.IndentityInformation"
                          defaultMessage="Identity Information"
                        />
                      </h4>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col sm={3} md={3} lg={8}>
                      {userDetail.signaturePath ? (
                        <img src={userDetail.signaturePath} />
                      ) : (
                        <img
                          className="image"
                          style={{
                            height: "150px",
                            width: "150px",
                            border: "0px solid #efefef",
                          }}
                          src={noImage}
                        />
                      )}

                      {userDetail.idImage && userDetail.idImage ? (
                        <img
                          style={{ maxWidth: "25%" }}
                          src={userDetail.idImage}
                        />
                      ) : (
                        <img
                          className="image"
                          style={{
                            height: "150px",
                            width: "150px",
                            border: "0px solid #efefef",
                          }}
                          src={noImage}
                        />
                      )}

                      <Row className="mt-4 align-items-center">
                        <Col sm={3} md={3} lg={2}>
                          <FormattedMessage
                            id="table.head.Remark"
                            defaultMessage="Remark"
                          />
                          :
                        </Col>
                        <Col>
                          <input
                            type="text"
                            className="form-control form-control-md"
                            name="remark"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.remark}
                            // placeholder={useIntl().formatMessage({
                            //   id: "activitymodal.Remark",
                            //   defaultMessage: "Remark",
                            // })}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={4} md={4} lg={4}>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <th style={{ width: "20%" }}>
                            <label className="mb-0">
                              <FormattedMessage
                                id="head.IDType"
                                defaultMessage="ID Type"
                              />
                            </label>
                          </th>
                          <td>
                            <Select
                              name="toolType"
                              value={selectedIndentityType}
                              onChange={(option: any) => {
                                setSelectedIdentityType(option);
                                formik.setFieldValue("identityId", "");
                                formik.setFieldValue(
                                  "identityType",
                                  option.value,
                                );
                              }}
                              onBlur={() =>
                                formik.setFieldTouched("identityId", true)
                              }
                              options={indentityProof}
                              getOptionLabel={(option: any) => option.label}
                              getOptionValue={(option: any) => option.value}
                              className="form-group"
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                            />
                          </td>
                        </tr>

                        <tr>
                          <th style={{ width: "20%" }}>
                            <label className="mb-0">
                              <FormattedMessage
                                id="head.IDNubmer"
                                defaultMessage="ID Nubmer"
                              />
                            </label>
                          </th>
                          <td>
                            {formik.values.identityType ===
                            "Hong kong Id Card" ? (
                              <input
                                type="text"
                                name="identityId"
                                maxLength={4}
                                minLength={4}
                                onChange={(e: any) => {
                                  // if (
                                  //   e.target.value.length <= 4 ||
                                  //   e.target.value == ""
                                  // ) {
                                  formik.handleChange(e);
                                  // }
                                }}
                                onBlur={handleBlur}
                                value={values.identityId}
                                className="form-control form-control-md form-group"
                              />
                            ) : (
                              <input
                                type="text"
                                name="identityId"
                                maxLength={6}
                                minLength={6}
                                onChange={(e: any) => {
                                  if (
                                    e.target.value.length <= 6 ||
                                    e.target.value == ""
                                  ) {
                                    formik.handleChange(e);
                                  }
                                }}
                                onBlur={handleBlur}
                                value={values.identityId}
                                className="form-control form-control-md form-group"
                              />
                            )}
                            {errors.identityId && touched.identityId && (
                              <div className="text-error">
                                <FormattedMessage
                                  id={errors.identityId.toString()}
                                  defaultMessage="This field is required"
                                />
                              </div>
                            )}
                          </td>
                        </tr>
                      </table>
                    </Col>
                  </Row>
                  <br />
                  <h4 className="font-weight-400 mt-4">
                    <FormattedMessage
                      id="head.accountSetting"
                      defaultMessage="Accounting Setting"
                    />{" "}
                  </h4>
                  <hr />

                  <Row>
                    <Col xs={12} sm={12} md={6} lg={5}>
                      <Row className="mb-2 align-items-center">
                        <Col xs={12} sm={12} md={12} lg={4}></Col>
                        <Col xs={12} sm={12} md={12} lg={8}>
                          <FormattedMessage
                            id="head.limit"
                            defaultMessage="Set Limit"
                          />
                        </Col>
                      </Row>
                      <Row className="align-items-center form-group">
                        <Col xs={12} sm={12} md={12} lg={4}>
                          <FormattedMessage
                            id="head.demoAccount"
                            defaultMessage="Demo Account"
                          />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={8}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <input
                              style={{ backgroundColor: "white", width: "50%" }}
                              type="text"
                              name="demoLimit"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.demoLimit}
                              className="form-control form-control-md form-group"
                            />
                            {errors.demoLimit && touched.demoLimit && (
                              <div className="text-error">
                                <FormattedMessage
                                  id={errors.demoLimit.toString()}
                                  defaultMessage="This field is required"
                                />
                              </div>
                            )}
                          </Stack>
                        </Col>
                      </Row>

                      <Row className="align-items-center form-group">
                        <Col xs={12} sm={12} md={12} lg={4}>
                          <FormattedMessage
                            id="head.liveAccount"
                            defaultMessage="Created"
                          />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={8}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <input
                              style={{ backgroundColor: "white", width: "50%" }}
                              type="text"
                              name="liveLimit"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.liveLimit}
                              className="form-control form-control-md form-group"
                            />
                            {errors.liveLimit && touched.liveLimit && (
                              <div className="text-error">
                                <FormattedMessage
                                  id={errors.liveLimit.toString()}
                                  defaultMessage="This field is required"
                                />
                              </div>
                            )}
                          </Stack>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {/* {error.length > 0 ? (
              <label className="text-error">{error}</label>
            ) : (
              ""
            )} */}
                  {/* </Card.Body>
                  </Card> */}
                </Card.Body>
                <Card.Footer className="bg-light text-right">
                  <Button
                    variant="contained"
                    startIcon={<CloseIcon />}
                    color="error"
                    onClick={() => onClickReject()}
                  >
                    <FormattedMessage
                      id="page.Reject"
                      defaultMessage="Reject"
                    />
                    {isLoading && btnRjClick && <Loader />}
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<DoneIcon />}
                    className="m-l-10"
                    color="success"
                    onClick={() => formik.handleSubmit()}
                  >
                    <FormattedMessage
                      id="page.Approve"
                      defaultMessage="Approve"
                    />
                    {isLoading && btnClick && <Loader />}
                  </Button>
                </Card.Footer>
              </form>
            </Card>
          )}
        </section>
      </div>
    </div>
  );
};

export default MemeberDetails;
