import React, { useState } from "react";
// import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
// import { Store } from "../../redux/Actions";

import { ACTIVITIES_LIST_TABS } from "../../Constants";
import ActivityList from "./ActivityList";
import AttendenceList from "./AttendenceList";
import { Card } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import { BsChevronLeft } from "react-icons/bs";
import AddActivityModal from "../../Components/Activity/AddActivityModal";

const Activites = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [activityModal, setActivityModal] = useState({
    modal: false,
    activity: {},
  });

  // const classDetail: any = useSelector(
  //   (state: Store) => state.classReducer.classDetail
  // ) as any;

  const setAciveTab = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };
  const classes = useStyles();

  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex flex-wrap align-items-center">
            <div className="mr-auto">
              <h1>
                {" "}
                <FormattedMessage
                  id="Admin.Activity"
                  defaultMessage="Admin Activity"
                />
              </h1>
            </div>

            {activeTab === ACTIVITIES_LIST_TABS.ACTIVITY_LIST && (
              <div className="m-l-10 common-right-button">
                <a
                  href="#"
                  data-toggle="modal"
                  onClick={() =>
                    setActivityModal({ modal: true, activity: {} })
                  }
                  data-target="#AddCourse"
                  type="button"
                  className="btn btn-primary"
                >
                  <FormattedMessage
                    id="Admin.AddActivity"
                    defaultMessage="dd Activity "
                  />
                </a>
              </div>
            )}
          </div>
        </header>

        <section className="page-content container-fluid">
          <Card>
            <Card.Body className="p-0">
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={activeTab}
                  onChange={setAciveTab}
                  className={classes.tabs}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label={
                      <FormattedMessage
                        id="tab.Activity"
                        defaultMessage="Activity"
                      />
                    }
                    {...a11yProps(ACTIVITIES_LIST_TABS.ACTIVITY_LIST)}
                  />
                  <Tab
                    label={
                      <FormattedMessage
                        id="tab.AttendenceLog"
                        defaultMessage="Attendence log"
                      />
                    }
                    {...a11yProps(ACTIVITIES_LIST_TABS.ATTENDENCE_LOG_LIST)}
                  />
                </Tabs>
              </Box>
              <TabPanel
                value={activeTab}
                index={ACTIVITIES_LIST_TABS.ACTIVITY_LIST}
              >
                <ActivityList />
              </TabPanel>
              <TabPanel
                value={activeTab}
                index={ACTIVITIES_LIST_TABS.ATTENDENCE_LOG_LIST}
              >
                <AttendenceList />
              </TabPanel>
            </Card.Body>
          </Card>
        </section>
      </div>
      {activityModal.modal && (
        <AddActivityModal
          onModalClose={() => setActivityModal({ modal: false, activity: {} })}
          activity={activityModal.activity}
        />
      )}
    </div>
  );
};

export default Activites;

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#68439a",
      height: 1,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#68439a",
      BsBorderBottom: "1px solid",
    },
  },
});

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
