/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/APIServices/baseApiCall";
import {
  GET_GIFT_LIST,
  ADD_GIFT_LIST,
  EDIT_GIFT_LIST,
  GET_GIFT_DETAILS,
  GET_GIFT_CATEGORY_LIST,
  EDIT_GIFT_CATEGORY_LIST,
  ADD_GIFT_CATEGORY_LIST,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { getAPIEndPoint, setToken } from "../../util/utils";
import {
  GET_GIFT_LIST_URL,
  ADD_GIFT_LIST_URL,
  EIDT_GIFT_LIST_URL,
  ADD_GIFT_CATEGORY_LIST_URL,
  EDIT_GIFT_CATEGORY_LIST_URL,
  GET_GIFT_CATEGORY_LIST_URL,
} from "../../service/APIServices/apiEndPoints";
import { _UploadMedia } from "../../service/MediaUpload/classes.service";

//Get Admin Roles List
export const getGiftList =
  (
    showToast = true,
    pageNumber = 0,
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getGiftListInit(showToast, pageNumber, pageLimit));
  };

export const getGiftListInit =
  (
    showToast = true,
    pageNumber: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_GIFT_LIST.GET_GIFT_LIST_INITIALIZATION,
    });
    apiCall(
      getAPIEndPoint(GET_GIFT_LIST_URL, pageNumber, false, pageLimit) +
        "&sort=DESC&sortBy=createdAt",
      {},
      (res: Object) => dispatch(getGiftListSuccess(res)),
      (err: any) => dispatch(getGiftListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getGiftListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_GIFT_LIST.GET_GIFT_LIST_SUCCESS,
      payload: res,
    });
  };

export const getGiftListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_GIFT_LIST.GET_GIFT_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const getGiftCategoryList =
  (
    showToast = true,
    pageNumber = 0,
    sort = false,
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getGiftCategoryListInit(showToast, pageNumber, sort, pageLimit));
  };

export const getGiftCategoryListInit =
  (
    showToast = true,
    pageNumber: number,
    sort: boolean,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_GIFT_CATEGORY_LIST.GET_GIFT_CATEGORY_LIST_INITIALIZATION,
    });
    let url = GET_GIFT_CATEGORY_LIST_URL;
    if (sort) {
      url = GET_GIFT_CATEGORY_LIST_URL + "?sort=DESC&sortBy=createdAt";
    }
    apiCall(
      getAPIEndPoint(url, pageNumber, sort, pageLimit),
      {},
      (res: Object) => dispatch(getGiftCategoryListSuccess(res)),
      (err: any) => dispatch(getGiftCategoryListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getGiftCategoryListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_GIFT_CATEGORY_LIST.GET_GIFT_CATEGORY_LIST_SUCCESS,
      payload: res,
    });
  };

export const getGiftCategoryListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_GIFT_CATEGORY_LIST.GET_GIFT_CATEGORY_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const addGiftCategoryList =
  (
    showToast = true,
    gift: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(addGiftCategoryListInit(showToast, gift));
  };

export const addGiftCategoryListInit =
  (
    showToast = true,
    gift: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_GIFT_CATEGORY_LIST.ADD_GIFT_CATEGORY_LIST_INITIALIZATION,
    });
    apiCall(
      ADD_GIFT_CATEGORY_LIST_URL,
      gift,
      (res: Object) => dispatch(addGiftCategoryListSuccess(res)),
      (err: any) => dispatch(addGiftCategoryListError()),
      METHOD.POST,
      { showToast },
    );
  };

export const addGiftCategoryListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_GIFT_CATEGORY_LIST.ADD_GIFT_CATEGORY_LIST_SUCCESS,
      payload: res.data,
    });
    dispatch(getGiftCategoryList(false));
  };

export const addGiftCategoryListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_GIFT_CATEGORY_LIST.ADD_GIFT_CATEGORY_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const editGiftCategoryList =
  (
    showToast = true,
    gift: any,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(editGiftCategoryListInit(showToast, gift, id));
  };

export const editGiftCategoryListInit =
  (
    showToast = true,
    gift: any,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_GIFT_CATEGORY_LIST.EDIT_GIFT_CATEGORY_LIST_INITIALIZATION,
    });
    apiCall(
      EDIT_GIFT_CATEGORY_LIST_URL + id,
      gift,
      (res: Object) => dispatch(editGiftCategoryListSuccess(res)),
      (err: any) => dispatch(editGiftCategoryListError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const editGiftCategoryListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_GIFT_CATEGORY_LIST.EDIT_GIFT_CATEGORY_LIST_SUCCESS,
      payload: res.data,
    });
    dispatch(getGiftCategoryList(false));
  };

export const editGiftCategoryListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: EDIT_GIFT_CATEGORY_LIST.EDIT_GIFT_CATEGORY_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const addGiftList =
  (
    showToast = true,
    indicatorData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(addGiftListInit(showToast, indicatorData));
  };

export const addGiftListInit =
  (
    showToast = true,
    indicatorData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch({
      type: ADD_GIFT_LIST.ADD_GIFT_LIST_INITIALIZATION,
    });

    const data = indicatorData;
    let err = false;
    // url = "";
    if (indicatorData.giftCategory == 11) {
      const f = indicatorData.giftAttachmentPath;
      const { fileUploadResponse, error }: any = await _UploadMedia(f as File);
      err = error;
      data.giftAttachmentPath = fileUploadResponse;
    }

    apiCall(
      ADD_GIFT_LIST_URL,
      data,
      (res: Object) => dispatch(addGiftListSuccess(res)),
      (err: any) => dispatch(addGiftListError()),
      METHOD.POST,
      { showToast },
    );
  };

export const addGiftListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_GIFT_LIST.ADD_GIFT_LIST_SUCCESS,
    });
    dispatch(getGiftList(false, 1));
  };

export const addGiftListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_GIFT_LIST.ADD_GIFT_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const editGiftList =
  (
    showToast = true,
    id: any,
    indicatorData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(editGiftListInit(showToast, id, indicatorData));
  };

export const editGiftListInit =
  (
    showToast = true,
    id: any,
    indicatorData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_GIFT_LIST.EDIT_GIFT_LIST_INITIALIZATION,
    });
    apiCall(
      EIDT_GIFT_LIST_URL + id,
      indicatorData,
      (res: Object) => dispatch(editGiftListSuccess(res)),
      (err: any) => dispatch(editGiftListError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const editGiftListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_GIFT_LIST.EDIT_GIFT_LIST_SUCCESS,
      payload: res.data,
    });
    dispatch(getGiftList(false, 1));
  };

export const editGiftListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: EDIT_GIFT_LIST.EDIT_GIFT_LIST_ERROR,
    });
  };
