import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Store } from "../../../redux/Actions";
import { useEffect, useState } from "react";
import { dispatch } from "../../../redux";
import { getConsumerProductPurchaseByUser } from "../../../redux/action";
import { FormattedMessage } from "react-intl";
import NoDatFound from "../../../Components/common/NoDatFound";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Pagination from "../../../Components/common/Paginate/Pagination";

export default function ConsumerProductList() {
  const params = useParams();

  const consumerProductList = useSelector(
    (state: Store) => state.expressWayReducer.consumerProductList,
  );

  const totalRecord = useSelector(
    (state: Store) => state.expressWayReducer.totalRecord,
  );

  const isLoading = useSelector(
    (state: Store) => state.expressWayReducer.Loading.isLoading,
  );

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const onPageChange = (pageNumber: number) => {
    setPageIndex(pageNumber - 1);
  };

  useEffect(() => {
    dispatch(
      getConsumerProductPurchaseByUser(
        false,
        parseInt(params.id as string),
        pageIndex,
        pageSize,
      ),
    );
  }, [params, pageIndex, pageSize]);

  return (
    <>
      <div className="table-responsive">
        {consumerProductList && consumerProductList.length > 0 ? (
          <>
            <table className="table table-hover m-0">
              <thead>
                <tr>
                  <th key="head_summry">
                    <FormattedMessage
                      id="table.head.product.name"
                      defaultMessage="Product Name"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {consumerProductList.map((consumerProduct: any) => {
                  let toolName = "";
                  switch (consumerProduct.productType) {
                    case "ea":
                      toolName = consumerProduct?.expertAdvisor?.name;
                      break;

                    case "indicator":
                      toolName = consumerProduct?.indicator?.name;
                      break;

                    case "utilities":
                      toolName = consumerProduct?.utility?.name;
                      break;

                    default:
                      break;
                  }
                  return (
                    <tr>
                      <td>{toolName}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        ) : (
          <p>{!isLoading && <NoDatFound />}</p>
        )}
      </div>
      {totalRecord > 10 && consumerProductList.length > 0 && (
        <>
          <FormControl className="pagelimit">
            <FormLabel id="demo-row-radio-buttons-group-label">
              <FormattedMessage id="page_size" defaultMessage="Page Size" />
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={pageSize}
              onChange={(e: any) => {
                setPageSize(e.target.value);
                setPageIndex(0);
              }}
            >
              <FormControlLabel value={10} control={<Radio />} label="10" />
              <FormControlLabel value={20} control={<Radio />} label="20" />
              <FormControlLabel value={50} control={<Radio />} label="50" />
            </RadioGroup>
          </FormControl>
          <Pagination
            itemsPerPage={pageSize}
            totalPages={totalRecord}
            onPageChage={onPageChange}
            page={pageIndex}
          />
        </>
      )}
    </>
  );
}
