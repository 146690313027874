import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
import UserSelectionByClass from "../../Components/Selection/UserSelectionByClass";
import { dispatch } from "../../redux";
import {
  getClasses,
  getGiftCategoryList,
  GrantPoints,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import { AddGrantPointSchema } from "../../util/validationSchema";

import { FormattedMessage } from "react-intl";
import { isPositiveNumbersOnly } from "../../util/utils";
import { union } from "lodash";
import Loading from "../../Components/common/Loading";

const GrantPoint = ({ setActiveTab }: any) => {
  const userSelectionRef = useRef<any>();
  const [selectedType, setSelectedType] = useState("add");
  const [buttonClick, setButtonClick] = useState({});
  const { isLoading, isPointGrantsSuccess } = useSelector(
    (state: Store) => state.grantReducer,
  );
  const local = useSelector((state: Store) => state.userDataReducer.local);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      points: "",
      remarks: "",
      usersId: [],
      additionalUserIds: "",
      parsedAdditionalUserIds: [],
    },
    validationSchema: AddGrantPointSchema,
    onSubmit: async (values) => {
      if (!buttonClick) {
        values.remarks = values.remarks.trim();
        values.usersId = union(values.usersId, values.parsedAdditionalUserIds);
        const { points, remarks, usersId } = values;
        dispatch(GrantPoints(true, { points, remarks, usersId }, selectedType));
        setButtonClick(true);
      }
    },
  });

  const { errors, touched, resetForm, setFieldValue, setFieldError } = formik;

  const onFormReset = (e: any) => {
    e.preventDefault();
    setSelectedType("add");
    resetForm();
    userSelectionRef?.current();
  };

  const handleParsedAdditionalUserIds = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const s = e.target.value.replace(/\n+$/, "");
    if (s === "") {
      setFieldError("parsedAdditionalUserIds", undefined);
      setFieldValue("parsedAdditionalUserIds", []);
      return;
    }

    const parsedAdditionalUserIds = s.split("\n").map((c) => parseInt(c));
    if (parsedAdditionalUserIds.some((d) => isNaN(d))) {
      setFieldError("parsedAdditionalUserIds", "Check you input format");
      setFieldValue("parsedAdditionalUserIds", []);
    } else {
      setFieldError("parsedAdditionalUserIds", undefined);
      setFieldValue("parsedAdditionalUserIds", parsedAdditionalUserIds);
    }
  };

  useEffect(() => {
    dispatch(getClasses(false, 0, { by: "createdAt", order: "ASC" }));
    dispatch(getGiftCategoryList(false));
  }, []);

  useEffect(() => {
    if (!isLoading && buttonClick) {
      resetForm();
      setSelectedType("add");
      userSelectionRef?.current();
      setButtonClick(false);
      if (isPointGrantsSuccess) {
        setActiveTab(0);
      }
    }
  }, [isLoading, isPointGrantsSuccess]);

  return (
    <form
      onSubmit={(e: any) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <div className="card-body">
        <div className="row">
          <div className="col-xl-8">
            <Row>
              <Col>
                <UserSelectionByClass
                  refs={userSelectionRef}
                  setFinalUsers={(users: any) =>
                    formik.setFieldValue("usersId", users)
                  }
                  users={formik.values.usersId}
                />
                {errors.usersId && touched.usersId && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.usersId.toString()}
                      defaultMessage="At least one user should be selected"
                    />
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="form-group">
                  <label className="control-label">Additional User Ids</label>
                  <Row>
                    <Col>
                      <textarea
                        className="form-control"
                        name="additionalUserIds"
                        id="additionalUserIds"
                        style={{
                          width: "100%",
                          height: "100%",
                          boxSizing: "border-box",
                        }}
                        value={formik.values.additionalUserIds}
                        onChange={(e) => {
                          formik.handleChange(e);
                          handleParsedAdditionalUserIds(e);
                        }}
                        onBlur={formik.handleBlur}
                        rows={8}
                      />
                      {touched.additionalUserIds &&
                        errors.parsedAdditionalUserIds && (
                          <div className="text-error">
                            <FormattedMessage
                              id={errors.parsedAdditionalUserIds.toString()}
                              defaultMessage="At least one user should be selected"
                            />
                          </div>
                        )}
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="control-label">
                    Result Of Parsing (
                    {`${formik.values.parsedAdditionalUserIds.length} ids are parsed`}
                    )
                  </label>
                  <textarea
                    className="form-control"
                    name="additionalUserIds"
                    id="additionalUserIds"
                    style={{
                      width: "100%",
                      height: "100%",
                      boxSizing: "border-box",
                    }}
                    value={formik.values.parsedAdditionalUserIds.join("\n")}
                    disabled={true}
                    rows={8}
                  ></textarea>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="form-group">
                  <label className="control-label">
                    <FormattedMessage
                      id="activitymodal.Point"
                      defaultMessage="Points"
                    />{" "}
                  </label>
                  <Row>
                    <Col md={6}>
                      <Select
                        name="belong"
                        value={[
                          { id: "add", value: local === "en" ? "Add" : "添加" },
                          {
                            id: "drop",
                            value: local === "en" ? "Drop" : "降低",
                          },
                        ].map((i) => {
                          if (i.id == selectedType) {
                            return i;
                          }
                        })}
                        onChange={(option: any) => setSelectedType(option.id)}
                        onBlur={() =>
                          formik.setFieldTouched("classCategory", true)
                        }
                        options={[
                          { id: "add", value: local === "en" ? "Add" : "添加" },
                          {
                            id: "drop",
                            value: local === "en" ? "Drop" : "降低",
                          },
                        ]}
                        getOptionLabel={(option: any) => option.value}
                        getOptionValue={(option: any) => option.id}
                        className="custom-select-dropdown"
                        classNamePrefix="react-select-dropdown"
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <input
                        type="text"
                        name="points"
                        value={formik.values.points}
                        onChange={(e: any) => {
                          if (!isPositiveNumbersOnly(e, e.target.value)) {
                            return false;
                          }
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        min={0}
                      />
                      {errors.points && touched.points && (
                        <div className="text-error">
                          <FormattedMessage
                            id={errors.points.toString()}
                            defaultMessage="This field is required"
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="control-label">
                    <FormattedMessage
                      id="Admin.GrantPoint.Logid"
                      defaultMessage="Log ID (eg: 100,101,102,103)"
                    />
                  </label>
                  <input
                    type="text"
                    name="remarks"
                    disabled
                    className="form-control"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="control-label">
                    <FormattedMessage
                      id="table.head.Remarks"
                      defaultMessage="Remarks"
                    />
                  </label>
                  <input
                    type="text"
                    name="remarks"
                    value={formik.values.remarks}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-control"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="card-footer bg-light text-right">
        <button
          className="btn btn-secondary mr-3"
          onClick={(e: any) => onFormReset(e)}
        >
          <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
        </button>

        <Button type="submit">
          {buttonClick ? (
            <Loading />
          ) : selectedType === "add" ? (
            <FormattedMessage id="Admin.GrantPoint.ADD" defaultMessage="ADD " />
          ) : (
            <FormattedMessage
              id="Admin.GrantPoint.Drop"
              defaultMessage="ADD "
            />
          )}{" "}
        </Button>
      </div>
    </form>
  );
};

export default GrantPoint;
