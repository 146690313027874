import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import Loader from "../common/Loader";
import {
  addExpertAdvisorList,
  editExpertAdvisorList,
} from "../../redux/action";
import { Row, Col, Modal } from "react-bootstrap";
import Select from "react-select";
import { AddEASchema } from "../../util/validationSchema";
import FileUpload from "../common/MediaUpload/FileUpload";
import {
  _DeleteMedia,
  _UploadMedia,
} from "../../service/MediaUpload/classes.service";
import { BsTrash } from "react-icons/bs";
import { FormattedMessage, useIntl } from "react-intl";
import CenterLoader from "../common/CenterLoader/CenterLoader";
import { isPositiveNumbersOnly } from "../../util/utils";
import { FaTimesCircle } from "react-icons/fa";
import { toast } from "react-toastify";

const AddExpertAdvisorModal = ({
  onModalClose,
  editExpertAdvisor,
  rolesList,
}: any) => {
  const dispatch = useDispatch();
  const [ButtonClick, setButtonClick] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [fileUploadURL, setFileUploadURL] = useState<any>(
    editExpertAdvisor?.image_path || null,
  );
  const [deletedFilesUrl, setDeletedFilesUrl] = useState<any>(null);
  const [files, setFiles] = useState<any>(null);
  const [fileLoading, setFileLoading] = useState(false);

  const isAPILoading = useSelector(
    (state: Store) => state.expertAdvisorReducer.onSaveLoading,
  );
  const formik = useFormik({
    initialValues: {
      name: editExpertAdvisor !== null ? editExpertAdvisor.name : "",
      info: editExpertAdvisor !== null ? editExpertAdvisor.info : "",
      cost: editExpertAdvisor !== null ? editExpertAdvisor.cost : 0,
      originalPrice:
        editExpertAdvisor !== null ? editExpertAdvisor.originalPrice : 0,
      sellingPrice:
        editExpertAdvisor !== null ? editExpertAdvisor.sellingPrice : 0,
      belong: editExpertAdvisor !== null ? editExpertAdvisor.belong : [],
      image_path:
        editExpertAdvisor !== null ? editExpertAdvisor.image_path : "",
      indicatorLikeValidation:
        editExpertAdvisor !== null
          ? editExpertAdvisor.indicatorLikeValidation
          : false,
      wooCommerceProductId:
        editExpertAdvisor !== null
          ? editExpertAdvisor.wooCommerceProductId
          : "",
    },
    validationSchema: AddEASchema,
    onSubmit: async (values) => {
      if (!ButtonClick) {
        if (deletedFilesUrl !== null) {
          // deleteFromS3();
        }

        let isUpload = false;
        if (files) {
          values.image_path = files;
          isUpload = true;
        }

        if (editExpertAdvisor === null) {
          dispatch(addExpertAdvisorList(true, values));
        } else {
          dispatch(
            editExpertAdvisorList(true, editExpertAdvisor.id, values, isUpload),
          );
        }
        setButtonClick(true);
      }
    },
  });

  useEffect(() => {
    if (!isAPILoading && ButtonClick) {
      onModalClose();
    }
  }, [isAPILoading]);

  useEffect(() => {
    formik.setFieldValue("image_path", fileUploadURL);
  }, [fileUploadURL]);

  const handleChange = (selectedOption: any) => {
    setSelectedRoles(selectedOption);
    const belong = selectedOption.map((i: any) => i.id);
    formik.setFieldValue("belong", belong);
  };

  useEffect(() => {
    if (editExpertAdvisor !== null) {
      setSelectedRoles(editExpertAdvisor.belong);
      const ind = editExpertAdvisor.belong.map((i: any) => i.id.toString());
      formik.setFieldValue("belong", ind);
    }
  }, [editExpertAdvisor]);

  const handleDrop = async (acceptedFiles: any) => {
    // setFileLoading(true);

    // const { fileUploadResponse, error }: any = await _UploadMedia(acceptedFiles[0] as File);
    // setFiles(acceptedFiles[0]);

    // uploadFileAmazonS3(acceptedFiles[0]);
    if (acceptedFiles[0].name) {
      const ImageTypes = ["png", "jpg", "jpeg", "bmp", "gif"];
      const extention = acceptedFiles[0].name.split(".");
      if (
        extention.length > 0 &&
        ImageTypes.includes(
          extention[extention.length - 1]?.toLocaleLowerCase(),
        )
      ) {
        formik.setFieldValue("image_path", acceptedFiles[0]);
        setFileUploadURL(URL.createObjectURL(acceptedFiles[0]));
        setFiles(acceptedFiles[0]);
      } else {
        toast.error("Only image files are allowed");
      }
    }
    // if (!error) {
    //     formik.setFieldValue("image_path", fileUploadResponse)
    //     setFileUploadURL(fileUploadResponse)
    // }
    // setFileLoading(false);
  };

  const onDeleteImage = (imagePath: any) => {
    if (
      typeof imagePath === "string" &&
      editExpertAdvisor?.image_path === imagePath
    ) {
      setDeletedFilesUrl(imagePath);
    }

    formik.setFieldValue("image_path", "");
    setFileUploadURL(null);
  };

  const deleteFromS3 = async () => {
    const { response, error }: any = await _DeleteMedia(
      "d218c993bb14dc0ab29e-image-12.png",
    );
  };

  const { touched, errors } = formik;
  return (
    <>
      {fileLoading && <CenterLoader />}
      <Modal centered show={true} onHide={() => onModalClose(false)}>
        <Modal.Header>
          <h4 className="modal-title">
            {editExpertAdvisor !== null ? (
              <FormattedMessage
                id="head.EditExpertAdvisor"
                defaultMessage="Edit Expert Advisor"
              />
            ) : (
              <FormattedMessage
                id="head.AddExpertAdvisor"
                defaultMessage="Add Expert Advisor"
              />
            )}
          </h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onModalClose}
          >
            <FaTimesCircle />
          </button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="expertAdvi.Name"
                    defaultMessage="Name"
                  />{" "}
                </label>
                <input
                  type="text"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                />
                {errors.name && touched.name && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.name.toString()}
                      defaultMessage="require"
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="table.head.info"
                    defaultMessage="info"
                  />
                </label>
                <textarea
                  rows={1}
                  cols={50}
                  name="info"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.info}
                  className="form-control"
                />
                {errors.info && touched.info && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.info.toString()}
                      defaultMessage="require"
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="expertAdvi.Belong"
                    defaultMessage="Belong to"
                  />
                </label>
                <Select
                  name="belong"
                  value={selectedRoles}
                  onChange={handleChange}
                  options={rolesList}
                  getOptionLabel={(option: any) => option.roleName}
                  getOptionValue={(option: any) => option.id}
                  isMulti={true}
                  className="custom-select-dropdown multi"
                  classNamePrefix="react-select-dropdown"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
                {errors.belong && touched.belong && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.belong.toString()}
                      defaultMessage="require"
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="expertAdvi.Cost"
                    defaultMessage="Cost"
                  />
                </label>
                <input
                  type="number"
                  value={formik.values.cost}
                  // onChange={ formik.handleChange}
                  onChange={(e: any) => {
                    if (!isPositiveNumbersOnly(e, e.target.value)) {
                      return false;
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  className="form-control"
                  name="cost"
                  min={0}
                />
                {errors.cost && touched.cost && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.cost.toString()}
                      defaultMessage="require"
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="expertAdvi.Original"
                    defaultMessage="Original Price"
                  />
                </label>
                <input
                  type="number"
                  value={formik.values.originalPrice}
                  // onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  min={0}
                  className="form-control"
                  name="originalPrice"
                  onChange={(e: any) => {
                    if (!isPositiveNumbersOnly(e, e.target.value)) {
                      return false;
                    }
                    formik.handleChange(e);
                  }}
                  // onKeyPress={(e: any) => {
                  //   if (!isPositiveNumbersOnly(e.code,e.target.value)) {
                  //     e.preventDefault();
                  //   }
                  // }}
                />
                {errors.originalPrice && touched.originalPrice && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.originalPrice.toString()}
                      defaultMessage="require"
                    />{" "}
                  </div>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="expertAdvi.Selling"
                    defaultMessage="Selling Price"
                  />
                </label>
                <input
                  type="number"
                  value={formik.values.sellingPrice}
                  // onChange={formik.handleChange}
                  onChange={(e: any) => {
                    if (!isPositiveNumbersOnly(e, e.target.value)) {
                      return false;
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  className="form-control"
                  name="sellingPrice"
                  min={0}
                  //                   onKeyPress={(e: any) => {
                  //   if (!isPositiveNumbersOnly(e.code,e.target.value)) {
                  //     e.preventDefault();
                  //   }
                  // }}
                />
                {errors.sellingPrice && touched.sellingPrice && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.sellingPrice.toString()}
                      defaultMessage="require"
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="wooCommerce.product.id"
                    defaultMessage="WooCommerce Product Id"
                  />
                </label>
                <input
                  value={formik.values.wooCommerceProductId}
                  onChange={(e: any) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  className="form-control"
                  name="wooCommerceProductId"
                />
                {errors.wooCommerceProductId &&
                  touched.wooCommerceProductId && (
                    <div className="text-error">
                      <FormattedMessage
                        id={errors.wooCommerceProductId.toString()}
                        defaultMessage="require"
                      />
                    </div>
                  )}
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="expertAdvi.IndicatorLikeValidation"
                    defaultMessage="Selling Price"
                  />
                </label>
                <input
                  name="indicatorLikeValidation"
                  type="checkbox"
                  className="ml-2"
                  checked={formik.values.indicatorLikeValidation}
                  onChange={formik.handleChange}
                />
              </div>
            </Col>
          </Row>
          <div className="form-group mt-2">
            {fileUploadURL == null ? (
              <FileUpload handleDrop={handleDrop} accept="image/*" />
            ) : (
              <div>
                <Row className="align-items-center">
                  <Col className="col-md-9">
                    {/* <img style={{ width: '80%' }} src={fileUploadURL    } /> */}
                    <img style={{ width: "80%" }} src={fileUploadURL} />
                  </Col>
                  <Col className="text-right col-md-3">
                    <button
                      className="border-delete-icon delete"
                      onClick={() => {
                        onDeleteImage(formik.values.image_path);
                      }}
                    >
                      <BsTrash />
                    </button>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={() => {
              onModalClose();
            }}
          >
            <FormattedMessage id="expertAdvi.Cancel" defaultMessage="Cancel" />
          </button>
          <button
            type="button"
            disabled={isAPILoading}
            className="btn btn-primary"
            style={{ display: "inherit" }}
            onClick={() => formik.handleSubmit()}
            data-dismiss="modal"
          >
            {editExpertAdvisor !== null ? (
              <FormattedMessage id="page.Edit" defaultMessage="Edit" />
            ) : (
              <FormattedMessage id="page.Add" defaultMessage="Add" />
            )}
            {isAPILoading && <Loader />}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddExpertAdvisorModal;
