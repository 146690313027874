import { Switch } from "@mui/material";
import { useFormik } from "formik";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import Loader from "../../Components/common/Loader";
import { addAccountInfoList, getAccountInfoList } from "../../redux/action";
import { Store } from "../../redux/Actions";
import { GET_ACCOUNT_INFO_LIST } from "../../redux/constants/action-types";
import { AccountInfoValidationSchema } from "../../util/validationSchema";

interface AccountInfoProps {
  activeTab: number;
  accountType: string;
}
const AccountInfo = ({ activeTab, accountType }: AccountInfoProps) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { eaAccountInfo } = useSelector(
    (state: Store) => state.expertAdvisorReducer,
  );
  const isAPILoading = useSelector(
    (state: Store) => state.expertAdvisorReducer?.onSaveLoading,
  );
  const isLoading = useSelector(
    (state: Store) => state.expertAdvisorReducer?.isLoading,
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      demoAccountsPaid: eaAccountInfo?.paid_user_num_of_demo_account || 0,
      realAccountsPaid: eaAccountInfo?.paid_user_num_of_real_account || 0,
      totalAccountsPaid: eaAccountInfo?.paid_user_total_account || 0,
      demoAccountsFree: eaAccountInfo?.free_user_num_of_demo_account || 0,
      realAccountsFree: eaAccountInfo?.free_user_num_of_real_account || 0,
      totalAccountsFree: eaAccountInfo?.free_user_total_account || 0,
      isActivePaidUsers:
        eaAccountInfo?.paid_user_account_combned === 1 ? true : false,
      isActiveFreeUsers:
        eaAccountInfo?.free_user_account_combned === 1 ? true : false,
    },
    validationSchema: AccountInfoValidationSchema,
    onSubmit: (values) => {
      const formatedData = {
        paidUserAccountCombned: values.isActivePaidUsers,
        paidUserDemoAccount:
          values.isActivePaidUsers === false ? values?.demoAccountsPaid : 0,
        paidUserRealAccount:
          values.isActivePaidUsers === false ? values?.realAccountsPaid : 0,
        paidUserTotalAccount:
          values.isActivePaidUsers === true ? values?.totalAccountsPaid : 0,
        freeUserAccountCombned: values.isActiveFreeUsers,
        freeUserDemoAccount:
          values.isActiveFreeUsers === false ? values?.demoAccountsFree : 0,
        freeUserRealAccount:
          values.isActiveFreeUsers === false ? values?.realAccountsFree : 0,
        freeUserTotalAccount:
          values.isActiveFreeUsers === true ? values?.totalAccountsFree : 0,
        toolId: id,
        type: accountType,
      };
      dispatch(addAccountInfoList(true, formatedData, id, accountType));
    },
  });

  useEffect(() => {
    if (activeTab == 4) {
      dispatch(getAccountInfoList(false, id, accountType));
    } else {
      dispatch({
        type: GET_ACCOUNT_INFO_LIST.GET_ACCOUNT_INFO_LIST_DISCRAED,
      });
    }
  }, [activeTab, dispatch, id]);
  const { touched, errors } = formik;

  return (
    <div className="content-wrapper">
      <div className="content">
        {isLoading && <CenterLoader />}
        <form onSubmit={formik.handleSubmit}>
          <div className="account-info">
            <h2>
              Paid Users: Demo and Real account combined
              <Switch
                checked={formik.values.isActivePaidUsers}
                name="isActivePaidUsers"
                onChange={formik.handleChange}
              />
            </h2>
            <ul>
              {!formik.values.isActivePaidUsers ? (
                <>
                  <li className="head-li">
                    No. of demo accounts:{" "}
                    <input
                      type="number"
                      name="demoAccountsPaid"
                      value={formik.values.demoAccountsPaid}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="form-control d-inline-block w-150"
                    />
                    {errors?.demoAccountsPaid && touched?.demoAccountsPaid && (
                      <div className="text-error">
                        <FormattedMessage
                          id={errors?.demoAccountsPaid.toString()}
                          defaultMessage="require"
                        />
                      </div>
                    )}
                  </li>
                  <li className="head-li">
                    No. of real accounts:{" "}
                    <input
                      type="number"
                      name="realAccountsPaid"
                      value={formik.values.realAccountsPaid}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="form-control mx-3 my-1 d-inline-block w-150"
                    />
                    {errors?.realAccountsPaid && touched?.realAccountsPaid && (
                      <div className="text-error">
                        <FormattedMessage
                          id={errors?.realAccountsPaid.toString()}
                          defaultMessage="require"
                        />
                      </div>
                    )}
                  </li>
                </>
              ) : (
                <li className="head-li">
                  No. of demo + real account:{" "}
                  <input
                    type="number"
                    name="totalAccountsPaid"
                    value={formik.values.totalAccountsPaid}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-control d-inline-block w-150"
                  />
                  {errors?.totalAccountsPaid &&
                    touched?.totalAccountsPaid &&
                    formik.values.isActivePaidUsers && (
                      <div className="text-error">
                        <FormattedMessage
                          id={errors?.totalAccountsPaid.toString()}
                          defaultMessage="require"
                        />
                      </div>
                    )}
                </li>
              )}
            </ul>
            <h2>
              Free Users: Demo and Real account combined{" "}
              <Switch
                checked={formik.values.isActiveFreeUsers}
                name="isActiveFreeUsers"
                onChange={formik.handleChange}
              />{" "}
            </h2>
            <ul>
              {!formik.values.isActiveFreeUsers ? (
                <>
                  <li className="head-li">
                    No. of demo accounts:{" "}
                    <input
                      type="number"
                      name="demoAccountsFree"
                      value={formik.values.demoAccountsFree}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="form-control d-inline-block w-150"
                    />
                    {errors?.demoAccountsFree && touched?.demoAccountsFree && (
                      <div className="text-error">
                        <FormattedMessage
                          id={errors?.demoAccountsFree.toString()}
                          defaultMessage="require"
                        />
                      </div>
                    )}
                  </li>
                  <li className="head-li">
                    No. of real accounts:{"  "}
                    <input
                      type="number"
                      name="realAccountsFree"
                      value={formik.values.realAccountsFree}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="form-control mx-3 my-1 d-inline-block w-150"
                    />
                    {errors?.realAccountsFree && touched?.realAccountsFree && (
                      <div className="text-error">
                        <FormattedMessage
                          id={errors?.realAccountsFree.toString()}
                          defaultMessage="require"
                        />
                      </div>
                    )}
                  </li>
                </>
              ) : (
                <li className="head-li">
                  No. of demo + real account:{" "}
                  <input
                    type="number"
                    name="totalAccountsFree"
                    value={formik.values.totalAccountsFree}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-control d-inline-block w-150"
                  />
                  {errors?.totalAccountsFree && touched?.totalAccountsFree && (
                    <div className="text-error">
                      <FormattedMessage
                        id={errors?.totalAccountsFree.toString()}
                        defaultMessage="require"
                      />
                    </div>
                  )}
                </li>
              )}
            </ul>
            <div className="d-flex justify-content-end p-3">
              <button
                disabled={isAPILoading}
                type="submit"
                className="btn btn-primary"
              >
                <FormattedMessage id="page.Save" defaultMessage="Save" />{" "}
                {isAPILoading && <Loader />}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AccountInfo;
