/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/APIServices/baseApiCall";
import {
  GET_STUDENT_LIST,
  GET_EXPRESSWAY_RECORD_LIST,
  GET_STUDENT_BY_CLASS_LIST,
  EXAMINE_STUDENT_LIST,
  GET_STUDENT_BY_ID_LIST,
  ADD_STUDENT_MANAUAL_LIST,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { setToken } from "../../util/utils";
import {
  GET_STUDENT_LIST_URL,
  GET_STUDENT_BY_CLASS_LIST_URL,
  EXAMINE_STUDENT_LIST_URL,
  GET_STUDENT_BY_ID_LIST_URL,
  ADD_STUDENT_MANAUAL_URL,
} from "../../service/APIServices/apiEndPoints";
import { toast } from "react-toastify";
// import { _getExpressWayPendingList } from "../../service";
// import { AxiosResponse } from "axios";

//Get Admin Roles List
export const getStudentList =
  (
    showToast = true,
    status: string,
    pageNumber = 0,
    sort: any,
    filter = "",
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      getStudentListInit(
        showToast,
        status,
        pageNumber,
        sort,
        filter,
        pageLimit,
      ),
    );
  };

export const getStudentListInit =
  (
    showToast = true,
    status: string,
    pageNumber: number,
    sort: any,
    filter: any,
    pageLimit: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_STUDENT_LIST.GET_STUDENT_LIST_INITIALIZATION,
    });
    let url = `${GET_STUDENT_LIST_URL}?status=${status}&sort=${sort.order}&sortBy=${sort.by}`;
    if (pageNumber > 0) {
      url = url + `&page=${pageNumber}&perPage=${pageLimit}${filter}`;
    }
    apiCall(
      url,
      {},
      (res: Object) => dispatch(getStudentListSuccess(res)),
      (err: any) => dispatch(getStudentListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getStudentListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_STUDENT_LIST.GET_STUDENT_LIST_SUCCESS,
      payload: res,
    });
  };

export const getStudentListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_STUDENT_LIST.GET_STUDENT_LIST_ERROR,
    });
  };

export const getStudentByClassList =
  (
    showToast = true,
    classId: string,
    userStatus = 2,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getStudentByClassListInit(showToast, classId, userStatus));
  };

export const getStudentByClassListInit =
  (
    showToast = true,
    classId: string,
    userStatus = 0,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_STUDENT_BY_CLASS_LIST.GET_STUDENT_BY_CLASS_LIST_INITIALIZATION,
    });
    apiCall(
      `${GET_STUDENT_BY_CLASS_LIST_URL}${classId}?userStatus=${userStatus} `,
      { userStatus: userStatus },
      (res: Object) => dispatch(getStudentByClassListSuccess(res)),
      (err: any) => dispatch(getStudentByClassListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getStudentByClassListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_STUDENT_BY_CLASS_LIST.GET_STUDENT_BY_CLASS_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getStudentByClassListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_STUDENT_BY_CLASS_LIST.GET_STUDENT_BY_CLASS_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const examineStudentList =
  (
    showToast = true,
    user: any,
    type: any,
    t: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(examineStudentListInit(showToast, user, type, t));
  };

export const examineStudentListInit =
  (
    showToast = true,
    user: any,
    type: any,
    t: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EXAMINE_STUDENT_LIST.EXAMINE_STUDENT_LIST_INITIALIZATION,
    });
    apiCall(
      `${EXAMINE_STUDENT_LIST_URL}${type}`,
      user,
      (res: Object) => dispatch(examineStudentListSuccess(res, t)),
      (err: any) => dispatch(examineStudentListError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const examineStudentListSuccess =
  (res: any, type: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EXAMINE_STUDENT_LIST.EXAMINE_STUDENT_LIST_SUCCESS,
    });
    dispatch(
      getStudentList(false, type, 1, { order: "DESC", by: "createdAt" }),
    );
  };

export const examineStudentListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: EXAMINE_STUDENT_LIST.EXAMINE_STUDENT_LIST_ERROR,
    });
  };

export const getStudentByIdList =
  (
    showToast = true,
    Id: string,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getStudentByIdListInit(showToast, Id));
  };

export const getStudentByIdListInit =
  (
    showToast = true,
    Id: string,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_STUDENT_BY_ID_LIST.GET_STUDENT_BY_ID_LIST_INITIALIZATION,
    });
    apiCall(
      `${GET_STUDENT_BY_ID_LIST_URL}${Id} `,
      {},
      (res: Object) => dispatch(getStudentByIdListSuccess(res)),
      (err: any) => dispatch(getStudentByIdListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getStudentByIdListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_STUDENT_BY_ID_LIST.GET_STUDENT_BY_ID_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getStudentByIdListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_STUDENT_BY_ID_LIST.GET_STUDENT_BY_ID_LIST_ERROR,
    });
  };

export const addStudentManualList =
  (
    showToast = true,
    Id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(addStudentManualListInit(showToast, Id));
  };

export const addStudentManualListInit =
  (
    showToast = true,
    data: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_STUDENT_MANAUAL_LIST.ADD_STUDENT_MANAUAL_LIST_INITIALIZATION,
    });
    apiCall(
      `${ADD_STUDENT_MANAUAL_URL} `,
      data,
      (res: Object) => dispatch(addStudentManualListSuccess(res)),
      (err: any) => dispatch(addStudentManualListError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const addStudentManualListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_STUDENT_MANAUAL_LIST.ADD_STUDENT_MANAUAL_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const addStudentManualListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_STUDENT_MANAUAL_LIST.ADD_STUDENT_MANAUAL_LIST_ERROR,
    });
  };
