import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Dropdown, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
// import { BsChevronLeft, BsTrash } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import { BsChevronLeft } from "react-icons/bs";

import "../Classes/classes.css";
import {
  addAnnouncementComment,
  deleteAnnouncement,
  deleteAnnouncementComment,
  editAnnouncements,
  getAnnouncementComment,
  getAnnouncementDetails,
  getClasses,
  getQR,
  getTicketGroupList,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import EditorContainer from "../../Components/RichTextEditor/Editor";
// import { Description } from "@mui/icons-material";
import draftToHtml from "draftjs-to-html";
import { convertToRaw, EditorState, convertFromHTML } from "draft-js";
import { ContentState } from "draft-js";
import {
  formatDate,
  getFileDetail,
  get_url_extensions,
} from "../../util/utils";
import DeletePopup from "../../Components/common/Popups/DeletePopup";
import Viewer from "react-viewer";
import MoreHorizIcon from "@mui/icons-material/MoreHorizOutlined";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { AttachmentIcon } from "../../util/iconStyle";
import { FormattedMessage } from "react-intl";
import { _UploadMedia } from "../../service/MediaUpload/classes.service";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import EditAnnouncementClassModal from "../../Components/Announcement/EditAnnouncementClassModal";
import AnnouncementComments from "./AnnouncementComment";
import { GROUP_TYPE } from "../../Constants";
import { AppendedMyComponent } from "../../Components/append-to-body-drop-down";
import { FaTrash } from "../../reactIcons";
import { toast } from "react-toastify";
import { ToastDownload } from "../../Components/common/ToastDownload";
import htmlToDraft from "html-to-draftjs";

const StyledMenu = styled((props: any) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }: any) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 120,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));
const IMAGE_TYPES = ["png", "jpeg", "jpg"];

const AnnouncementDetail = () => {
  const urlParams = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [decription, setDescription] = useState("Inital Test");
  const [deleteCall, setDeleteCall] = useState(false);
  const [comment, setComment] = useState("");
  const [commentDelete, setCommentDelete] = useState({ modal: false, id: 0 });
  const [img, setImg] = useState({ image: [], show: false, index: 0 });
  const attchRef = useRef<any>();
  const [announcementAttachment, setAnnouncemenAttachment] = useState<any>([]);
  const [fileAdded, setFileAdded] = useState<any>(false);
  const [deletes, setDelete] = useState({ modal: false, id: 0 });
  const btnRef = useRef<any>();

  const announcementsDetails: any = useSelector(
    (state: Store) => state.announcementsReducer.announcementsDetails,
  ) as any;
  const announcementComments: any = useSelector(
    (state: Store) => state.announcementsReducer.announcementComments,
  ) as any;
  const classes: any = useSelector(
    (state: Store) => state.classReducer.classes,
  ) as any;
  const isLoading: any = useSelector(
    (state: Store) => state.announcementsReducer.isLoading,
  ) as any;
  const onSaveLoading: any = useSelector(
    (state: Store) => state.announcementsReducer.onSaveLoading,
  ) as any;

  const groupList = useSelector(
    (state: Store) => state.ticketReducer.groupList,
  );
  // const myInfo: any = useSelector(
  //   (state: Store) => state.userDataReducer.myInfo
  // ) as any;
  const [fileLoading, setFileLoading] = useState(false);
  const [editedClass, setEditedClass] = useState(false);
  const [Load, setLoad] = useState<any>([]);

  // const blocksFromHTML = convertFromHTML("<p>Test</p>");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [classEdit, setClassEdit] = useState(false);

  const [editorConfig, setEditorConfig] = useState({
    toolbar: false,
    editable: false,
    height: "200px",
    overflowY: "unset",
    border: "1px solid transparent",
  });

  useEffect(() => {
    const { id } = urlParams;
    if (id && parseInt(id) > 0 && classEdit) {
      dispatch(getAnnouncementDetails(false, parseInt(id)));
    }
  }, [classEdit]);

  useEffect(() => {
    const { id } = urlParams;
    if (id && parseInt(id) > 0) {
      dispatch(getAnnouncementDetails(false, parseInt(id)));
      // dispatch(getAnnouncementComment(false, id));
      dispatch(getClasses(false, 0, { by: "createdAt", order: "DESC" }));
      dispatch(getQR());
      dispatch(getTicketGroupList(false, GROUP_TYPE.CLASS));
    }
  }, []);

  useEffect(() => {
    if (!isLoading && deleteCall) {
      navigate(-1);
      setDeleteCall(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (announcementsDetails && Object.keys(announcementsDetails).length) {
      setDescription(() => announcementsDetails.content);

      if (announcementsDetails?.content.trim() !== "<p></p>") {
        const blocksFromHTML = htmlToDraft(announcementsDetails?.content);
        let estate: any;
        try {
          estate = EditorState.createWithContent(
            ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap,
            ),
          );
        } catch (e: any) {
          const estate = EditorState.createEmpty();
          setEditorState(estate);
        }
        setEditorState(estate);
      } else {
        const estate = EditorState.createEmpty();
        setEditorState(estate);
      }
    }

    setAnnouncemenAttachment([]);
    if (announcementsDetails.attechment) {
      const files = getFileDetail(
        announcementsDetails?.attechment.map((i: any) => i.attachmentPath),
      );
      const f: any = [];
      const ff: any = []; ///_.sortBy(files,"extension",)
      const ff1: any = []; ///_.sortBy(files,"extension",)

      (files || []).map((i: any) => {
        if (IMAGE_TYPES.includes(i.extention)) {
          f.push({ src: i.path, alt: "No Image" });
          ff.push(i);
        } else {
          ff1.push(i);
        }
      });
      console.log("ff", ff);
      console.log("ff1", ff1);
      setAnnouncemenAttachment([...ff, ...ff1]);
      setImg({ show: false, image: f, index: 0 });
      // }
    }

    // announcementAttachment

    if (announcementsDetails.class_group) {
      const class_group = announcementsDetails.class_group.map(
        (i: any) => i.id,
      );
      setEditedClass(class_group);
    }
  }, [announcementsDetails]);

  const onCommentImageClick = (index: any) => {
    const filesImg: any = [];
    announcementAttachment.map((i: any) => {
      if (IMAGE_TYPES.includes(get_url_extensions(i?.path || "") || "")) {
        filesImg.push({ src: i.path, alt: "No Image" });
      }
    });
    console.log("ticketAttachment", announcementAttachment);

    setImg({ show: true, image: filesImg, index: index });
  };

  useEffect(() => {
    // const announcementAttachments = announcementAttachment;
    // announcementAttachments.push(...files);
    // setAnnouncemenAttachment(() => [...announcementAttachments]);

    if (
      fileAdded &&
      announcementAttachment &&
      Object.keys(announcementsDetails).length > 0
    ) {
      const Detail = {
        title: announcementsDetails.title,
        content: announcementsDetails.content,
        class_group: announcementsDetails?.class_group?.map((i: any) => i?.id),
        attachmentPath: [
          ...announcementAttachment.map((i: any) => i.path),
          // fileUploadResponse,
        ], //??
      };
      const { id } = urlParams;
      setFileAdded(false);
      dispatch(editAnnouncements(true, id, Detail));
    }
  }, [announcementAttachment]);

  const isEditComment = () => {
    const enableEditing = {
      toolbar: true,
      editable: true,
      height: "200px",
      overflowY: "scroll",
      border: "1px solid #efefef",
    };
    setEditorConfig(() => enableEditing);
  };

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
  };

  const onCancelEditClick = () => {
    const enableEditing = {
      toolbar: false,
      editable: false,
      height: "200px",
      overflowY: "unset",
      border: "1px solid transparent",
    };
    setEditorConfig(() => ({ ...enableEditing }));

    const blocksFromHTML = htmlToDraft(decription);
    const editorsState = EditorState.createWithContent(
      ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      ),
    );

    onEditorStateChange(editorsState);
  };

  const onAddCommnent = () => {
    const { id } = urlParams;

    if (comment.trim().length > 0) {
      dispatch(
        addAnnouncementComment(true, { comment: comment, announcement: id }),
      );
      setComment("");
    }
  };

  const onEditClick = () => {
    const eState = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    const enableEditing = {
      toolbar: false,
      editable: false,
      height: "200px",
      overflowY: "unset",
      border: "1px solid transparent",
    };
    setEditorConfig(() => ({ ...enableEditing }));
    const { id } = urlParams;

    if (eState.trim() != "<p></p>") {
      announcementsDetails.content = eState;
      const temp = {
        title: announcementsDetails.title,
        content: announcementsDetails.content,
        class_group: announcementsDetails?.class_group?.map((i: any) => i?.id),
        attachmentPath: announcementsDetails.attechment.map(
          (i: any) => i.attachmentPath,
        ), //??
      };

      dispatch(editAnnouncements(true, id, temp));
    } else {
      const blocksFromHTML = htmlToDraft(decription);
      const editorsState = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        ),
      );
      onEditorStateChange(editorsState);
    }
  };

  const deleteComment = (commentId: any) => {
    const { id } = urlParams;

    dispatch(deleteAnnouncementComment(true, commentId, id));
    setCommentDelete({ modal: false, id: 0 });
  };

  const deleteCommentModal = (id: any) => {
    setCommentDelete({ modal: true, id: id });
  };

  const attchmentClick = () => {
    if (attchRef.current) {
      attchRef?.current.click();
    }
  };

  const handleDrop = async (e: any) => {
    const acceptedFiles = e.target.files;
    setFileLoading(true);
    setFileAdded(true);
    const files: any = [];
    for (let i = 0; i < acceptedFiles.length; i++) {
      if (acceptedFiles[i].size > 104857600) {
        toast.error(
          `${acceptedFiles[i].name} file is not allowed because it's size is more than 100MB`,
        );
      } else {
        files.push(acceptedFiles[i]);
      }
    }
    if (files.length > 0) {
      const { fileUploadResponse, error }: any = await _UploadMedia(
        files[0] as File,
      );
      if (!error) {
        const files = getFileDetail([fileUploadResponse]);
        const announcementAttachments = announcementAttachment;
        announcementAttachments.push(...files);
        setAnnouncemenAttachment(() => [...announcementAttachments]);

        //   const Detail = {
        //     title: announcementsDetails.title,
        //     content: announcementsDetails.content,
        //     class_group: announcementsDetails.class_group,
        //     attachmentPath: [
        //       ...announcementsDetails.attechment.map((i: any) => i.attachmentPath),
        //       fileUploadResponse,
        //     ], //??
        //   };
        //   const { id } = urlParams;
        //   dispatch(editAnnouncements(true, id, Detail));
      }
    }
    e.target.value = "";
    setFileLoading(false);
  };

  const onImageDelete = (index: any) => {
    // const ticketAttachments = announcementAttachment;
    // setFileAdded(true);
    const a = announcementAttachment.filter(
      (i: any, ind: any) => ind !== index,
    );
    setAnnouncemenAttachment(() => [...a]);
    const { id } = urlParams;
    const announcementDetail = {
      title: announcementsDetails.title,
      content: announcementsDetails.content,
      class_group: announcementsDetails?.class_group?.map((i: any) => i?.id),
      attachmentPath: a.map((i: any) => i.path), //??
    };
    dispatch(editAnnouncements(true, id, announcementDetail));
    setDelete({ modal: false, id: 0 });
  };

  return (
    <div className="content-wrapper">
      {fileLoading && <CenterLoader />}
      {isLoading && <CenterLoader />}

      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                <FormattedMessage
                  id="announcementDetail.head"
                  defaultMessage="Announcement Details"
                />
              </h1>
            </div>
            <div className="m-l-10 common-right-button">
              <Button
                style={{ textTransform: "none" }}
                onClick={() => {
                  setClassEdit(true);
                }}
                variant="contained"
                color="primary"
                className="mr-2"
              >
                <FormattedMessage
                  id="modal.head.Changeclass"
                  defaultMessage="Edit class"
                />
              </Button>
              <Link to="/announcements" className="btn btn-secondary">
                <i className="back-button">
                  <BsChevronLeft /> &nbsp;
                </i>
                <FormattedMessage id="page.back" defaultMessage="Back" />
              </Link>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          <div>
            <input
              type="file"
              ref={attchRef}
              style={{ display: "none" }}
              onChangeCapture={(e: any) => handleDrop(e)}
            />
          </div>
          <Card>
            <Card.Body>
              {announcementsDetails &&
                Object.keys(announcementsDetails).length !== 0 && (
                  <div>
                    <div className="d-flex align-items-center justify-content-between mb-4">
                      <h4 className="font-weight-500">
                        {announcementsDetails.title}
                      </h4>
                      <div className="d-flex align-items-center">
                        <Button
                          onClick={attchmentClick}
                          variant="outlined"
                          style={{ textTransform: "none" }}
                          size="small"
                        >
                          <i
                            className="MuiListItemIcon-root-tbl"
                            style={{
                              fill: "#1976d2",
                              width: "16px",
                              height: "16px",
                            }}
                          >
                            {" "}
                            <AttachmentIcon />
                          </i>
                          <FormattedMessage
                            id="page.Attach"
                            defaultMessage="Attach"
                          />
                        </Button>
                        {/* <div className="dropdown ml-2">
                        <Button
                          id="demo-customized-button"
                          aria-controls={
                            open ? "demo-customized-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          variant="text"
                          color="inherit"
                          disableElevation
                          onClick={handleClick}
                          endIcon={<MoreHorizIcon />}
                          style={{ textTransform: "none" }}
                        ></Button>
                        <StyledMenu
                          id="demo-customized-menu"
                          MenuListProps={{
                            "aria-labelledby": "demo-customized-button",
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                        >
                          <MenuItem
                            onClick={() => {
                              dispatch(deleteAnnouncement(true, urlParams.id));
                              setDeleteCall(true);
                            }}
                            disableRipple
                          >
                            <FormattedMessage
                              id="page.Delete"
                              defaultMessage="Delete"
                            />
                          </MenuItem>
                          {announcementsDetails?.isCritical ?
                            <MenuItem
                              onClick={() => {
                                dispatch(editAnnouncements(true, announcementsDetails?.id, {
                                  title: announcementsDetails.title,
                                  class_group: announcementsDetails.class_group.map((i: any) => i.id),
                                  isCritical: false
                                }));
                                // setDeleteCall(true);
                              }}
                              disableRipple
                            >
                              <FormattedMessage
                                id="Tickets.tuncritical"
                                defaultMessage="Delete"
                              />
                            </MenuItem> :
                            <MenuItem
                              onClick={() => {
                                dispatch(editAnnouncements(true, announcementsDetails?.id, {
                                  title: announcementsDetails.title,
                                  class_group: announcementsDetails.class_group.map((i: any) => i.id),
                                  isCritical: true
                                }));
                                // setDeleteCall(true);
                              }}
                              disableRipple
                            >
                              <FormattedMessage
                                id="Tickets.tcritical"
                                defaultMessage="Delete"
                              />
                            </MenuItem>}
                        </StyledMenu>
                      </div> */}

                        <div className="dropdown ml-2">
                          <Dropdown className="btn-group">
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              className="btn btn-sm btn-icon-only"
                            >
                              <i className="icon dripicons-dots-3 zmdi-hc-fw"></i>
                            </Dropdown.Toggle>
                            <AppendedMyComponent>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  href="#"
                                  onClick={() => {
                                    dispatch(
                                      deleteAnnouncement(true, urlParams.id),
                                    );
                                    setDeleteCall(true);
                                  }}
                                >
                                  {/* <i className="fa fa-edit fa-fw text-accent-custom"></i> */}
                                  <FormattedMessage
                                    id="page.Delete"
                                    defaultMessage="Delete"
                                  />
                                </Dropdown.Item>
                                {announcementsDetails?.isCritical ? (
                                  <Dropdown.Item
                                    href="#"
                                    onClick={() =>
                                      dispatch(
                                        editAnnouncements(
                                          true,
                                          announcementsDetails?.id,
                                          {
                                            title: announcementsDetails.title,
                                            class_group:
                                              announcementsDetails.class_group.map(
                                                (i: any) => i.id,
                                              ),
                                            isCritical: false,
                                          },
                                        ),
                                      )
                                    }
                                  >
                                    {/* <i className="fa fa-user-graduate fa-fw text-accent-custom"></i> */}
                                    <FormattedMessage
                                      id="Tickets.tuncritical"
                                      defaultMessage="Delete"
                                    />
                                  </Dropdown.Item>
                                ) : (
                                  <Dropdown.Item
                                    href="#"
                                    onClick={() =>
                                      dispatch(
                                        editAnnouncements(
                                          true,
                                          announcementsDetails?.id,
                                          {
                                            title: announcementsDetails.title,
                                            class_group:
                                              announcementsDetails.class_group.map(
                                                (i: any) => i.id,
                                              ),
                                            isCritical: true,
                                          },
                                        ),
                                      )
                                    }
                                  >
                                    {/* <i className="fa fa-user-graduate fa-fw text-accent-custom"></i> */}
                                    <FormattedMessage
                                      id="Tickets.tcritical"
                                      defaultMessage="Delete"
                                    />
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </AppendedMyComponent>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                    <Row>
                      <Col lg={2} md={3} sm={4}>
                        <div className="form-group mt-3">
                          <FormattedMessage
                            id="announcementDetail.description"
                            defaultMessage="Description"
                          />
                          :
                        </div>
                      </Col>
                      <Col lg={10} md={9} sm={8}>
                        <div className="form-group" onClick={isEditComment}>
                          {decription && (
                            <EditorContainer
                              onEditorStateChange={onEditorStateChange}
                              editorState={editorState}
                              toolbar={editorConfig.toolbar}
                              editable={editorConfig.editable}
                              height={editorConfig.height}
                              overflowY={editorConfig.overflowY}
                              border={editorConfig.border}
                            />
                          )}
                        </div>
                        {editorConfig.editable && (
                          <div className="mt-2">
                            {" "}
                            <button
                              className="btn btn-primary mr-2"
                              onClick={onEditClick}
                            >
                              <FormattedMessage
                                id="page.Save"
                                defaultMessage="Save"
                              />
                            </button>
                            <button
                              className="btn btn-secondary"
                              onClick={onCancelEditClick}
                            >
                              <FormattedMessage
                                id="page.Cancel"
                                defaultMessage="Cancel"
                              />
                            </button>{" "}
                          </div>
                        )}
                      </Col>
                    </Row>

                    <h4 className="font-weight-500 mt-4 mb-3">
                      <FormattedMessage
                        id="announcementDetail.attachment"
                        defaultMessage="Attchament"
                      />
                    </h4>

                    {announcementAttachment.map((imgs: any, index: any) => (
                      <>
                        {IMAGE_TYPES.includes(imgs.extention) ? (
                          <>
                            <img
                              width={100}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                // setImg((prv)=>({
                                //   // image: (announcementAttachment || []).map((i: any) => { return { src: i.path, alt: 'No Image' } }),
                                //   // image: imgs.path,
                                //   ...prv,
                                //   show: true,
                                //   index: index
                                // }));
                                onCommentImageClick(index);
                              }}
                              src={imgs.path}
                            />
                            <button
                              onClick={() => {
                                setDelete({ modal: true, id: index });
                              }}
                              style={{
                                background: "transparent",
                                border: "none",
                              }}
                            >
                              <i>
                                {" "}
                                <FaTrash />
                              </i>{" "}
                            </button>
                            {console.log("img.index", img.index)}

                            {img.image.length > 0 && (
                              <Viewer
                                visible={img.show}
                                onClose={() => {
                                  setImg({ show: false, image: [], index: 0 });
                                }}
                                noToolbar={true}
                                disableMouseZoom={true}
                                drag={false}
                                activeIndex={img.index}
                                images={img.image}
                                onChange={(activeImage: any, index: number) => {
                                  setImg((prv: any) => ({
                                    ...prv,
                                    index: index,
                                  }));
                                }}
                                // [{ src: img.image, alt: "Nor FOund" }]}
                              />
                            )}
                          </>
                        ) : (
                          <p>
                            <ToastDownload
                              Load={Load}
                              setLoad={setLoad}
                              ref={btnRef}
                            />
                            <a
                              // href={imgs.path}
                              onClick={() => {
                                if (btnRef.current) {
                                  setLoad((prv: any) => [
                                    ...prv,
                                    `annD${index}`,
                                  ]);
                                  btnRef?.current.downloadFiles(
                                    imgs.path,
                                    `annD${index}`,
                                  );
                                }
                              }}
                            >
                              {imgs.name}{" "}
                            </a>{" "}
                            <a onClick={() => onImageDelete(index)}>Delete</a>
                          </p>
                        )}
                      </>
                    ))}
                  </div>
                )}
              {/* <h4 className="font-weight-500 my-4">
                <FormattedMessage
                  id="announcementDetail.comment"
                  defaultMessage="Comment"
                />{" "}
                <small className="font-size-12 ml-2">
                  (*{" "}
                  {
                    <FormattedMessage
                      id="announcementDetail.comment.subhead"
                      defaultMessage="You will leave a message as GemsFX Development Team"
                    />
                  }
                  )
                </small>
              </h4> */}
              <div className="comments-media">
                {/* <div className="media">
                  <Avatar sx={{ bgcolor: "gray" }} aria-label="recipe">
                    G
                  </Avatar>
                  <div className="media-body ml-3">
                    <textarea
                      rows={2}
                      cols={50}
                      className="form-control"
                      onChange={(e) => setComment(e.target.value)}
                      value={comment}
                    >
                      {" "}
                    </textarea>
                    <button
                      className="btn btn-primary btn-sm mt-2"
                      onClick={() => onAddCommnent()}
                    >
                      <FormattedMessage
                        id="page.submit"
                        defaultMessage="Submit"
                      />
                    </button>
                  </div>
                </div> */}
                {/* {announcementComments &&
                  announcementComments.map((comment: any) => (
                    <>
                      <div className="media">
                        <Avatar>
                          {comment?.createdBy?.avatar ? (
                            <img src={comment.createdBy.avatar} />
                          ) : (
                            <>{comment.comment.charAt(0)} </>
                          )}
                        </Avatar>
                        <div className="media-body ml-3">
                          <div className="d-flex align-items-center justify-content-between mb-1">
                            <p className="font-size-14 font-weight-600 m-0">
                              {comment.createdBy.userType == "admin" ? (
                                "GemsFX Development Team"
                              ) : (
                                <>
                                  {comment.createdBy ? (
                                    <>
                                      {comment?.createdBy?.englishFirstName +
                                        " " +
                                        comment.createdBy.englishLastName}
                                    </>
                                  ) : (
                                    "unknown"
                                  )}{" "}
                                </>
                              )}
                              <small className="text-muted ml-1">
                                {formatDate(comment.createdAt, "DD MMM, YYYY")}{" "}
                              </small>
                            </p>
                            <button
                              className="no-back-red "
                              onClick={() => {
                                deleteCommentModal(comment.id);
                              }}
                              style={{
                                fontSize: "12px",
                                color: "lightskyblue",
                                cursor: "pointer",
                                padding: "0",
                              }}
                            >
                              <i className="fa fa-trash-alt"></i>
                            </button>
                          </div>
                          <p className="mb-1"><div dangerouslySetInnerHTML={{__html:comment.comment}} /></p>
                        </div>
                      </div>
                    </>
                  ))} */}
                {Object.keys(announcementsDetails).length > 0 && (
                  <AnnouncementComments
                    announcementsDetails={announcementsDetails}
                    announcementComments={announcementComments}
                    isLoading={isLoading}
                    // commentDelete={commentDelete}
                    // setCommentDelete={setCommentDelete}
                  />
                )}
              </div>
            </Card.Body>
          </Card>
        </section>
      </div>
      {/* {
        commentDelete && (
          <DeletePopup
            onClose={() => setCommentDelete({ modal: false, id: 0 })}
            onDeleteClick={() => deleteComment(commentDelete.id)}
            open={commentDelete.modal}
          />
        )
      } */}

      {
        // classEdit && !isLoading && (
        <EditAnnouncementClassModal
          onModalClose={() => setClassEdit(false)}
          classList={classes}
          selectedClass={announcementsDetails.class_group}
          isAPILoading={onSaveLoading}
          // j
          isLoading={isLoading}
          setEditedClass={(classes: any) => {
            setEditedClass(classes);
            onEditClick();
          }}
          announcementsDetails={announcementsDetails}
          show={classEdit}
        />
        // )
      }

      {/* {
        deletes && ( */}
      <DeletePopup
        onClose={() => {
          setDelete({ modal: false, id: 0 });
        }}
        onDeleteClick={() => onImageDelete(deletes.id)}
        open={deletes.modal}
      />
      {/* )
      } */}
    </div>
  );
};
export default AnnouncementDetail;
