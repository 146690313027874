import { z } from "zod";

export const createGemsAdvertisementSchema = z.object({
  body: z
    .object({
      title: z.string().min(1, "Title is required.").max(255),
      imageUrl: z.string().max(255),
      promotionUrl: z.string().max(255),
      startDate: z.coerce.date(),
      endDate: z.coerce.date(),
      targetClassesIds: z
        .array(z.number().int().safe().nonnegative())
        .min(1, "At least 1 target class is required."),
    })
    .refine((val) => val.startDate <= val.endDate, {
      message: "End date cannot be earlier than start date.",
      path: ["endDate"],
    }),
});

export type CreateGemsAdvertisementDto = z.infer<
  typeof createGemsAdvertisementSchema.shape.body
>;
