import { GemsClass } from "../../gemsClasses/types";

export class GemsAdvertisement {
  id: number;
  title: string;
  imageUrl: string;
  promotionUrl: string;
  isActive: boolean;
  isDeleted: boolean;
  startDate: Date;
  endDate: Date;
  targetClasses: GemsClass[];
  createdAt: Date;
  updatedAt: Date;

  static get sortableColumns(): Partial<
    Record<keyof GemsAdvertisement, keyof GemsAdvertisement>
  > {
    return {
      id: "id",
      title: "title",
      imageUrl: "imageUrl",
      promotionUrl: "promotionUrl",
      isActive: "isActive",
      isDeleted: "isDeleted",
      startDate: "startDate",
      endDate: "endDate",
      createdAt: "createdAt",
      updatedAt: "updatedAt",
    } as const;
  }

  static get stringFilterableColumns(): Partial<
    Record<keyof GemsAdvertisement, keyof GemsAdvertisement>
  > {
    return {
      id: "id",
      title: "title",
      imageUrl: "imageUrl",
      promotionUrl: "promotionUrl",
    } as const;
  }

  static get datetimeRangeFilterableColumns(): Partial<
    Record<keyof GemsAdvertisement, keyof GemsAdvertisement>
  > {
    return {
      startDate: "startDate",
      endDate: "endDate",
      createdAt: "createdAt",
      updatedAt: "updatedAt",
    } as const;
  }
}
