import { useEffect, useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import noImage from "../../assets/img/noImage.jpeg";

import "../Classes/classes.css";
import {
  editScriptsList,
  getAdminRolesList,
  getScriptsDetails,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import ScriptVersion from "./ScriptVersion";
import { FormattedMessage } from "react-intl";
import Script from "../../assets/img/Script.svg";
import { toast } from "react-toastify";
import AddRemoveToolRoleModal from "../../Components/common/Popups/AddRemoveToolRoleModal";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import { BsChevronLeft } from "../../reactIcons";

const ScriptDetail = () => {
  const urlParams = useParams();
  const dispatch = useDispatch();
  const [belong, setBelong] = useState<any>([]);
  const [toolValue, setToolValue] = useState<any>({
    name: "",
    info: "",
    cost: 0,
    originalPrice: 0,
    sellingPrice: 0,
    belong: [],
    image_path: "",
  });
  const [changeRole, setChangeRole] = useState({
    modal: false,
    id: 0,
    action: "add",
  });

  const [activeTab, setActiveTab] = useState(1);

  const scriptsDetails: any = useSelector(
    (state: Store) => state.scriptsReducer.scriptsDetails,
  ) as any;
  const adminRoleList: any = useSelector(
    (state: Store) => state.adminRolesReducer.adminRoleList,
  ) as any;
  const isLoading = useSelector(
    (state: Store) => state.scriptsReducer.isLoading,
  );

  useEffect(() => {
    const { id } = urlParams;
    if (id && parseInt(id) > 0) {
      dispatch(getScriptsDetails(false, parseInt(id)));
      dispatch(getAdminRolesList(false));
    }
  }, []);

  useEffect(() => {
    if (scriptsDetails) {
      const a = (scriptsDetails?.belong || []).map((i: any) => i.id);
      setBelong(a);
      setToolValue({
        name: scriptsDetails?.name,
        info: scriptsDetails?.info,
        cost: scriptsDetails?.cost,
        originalPrice: scriptsDetails?.originalPrice,
        sellingPrice: scriptsDetails?.sellingPrice,
        belong: a,
        image_path: scriptsDetails?.image_path,
      });
    }
  }, [scriptsDetails]);

  const setAciveTab = (tab: number) => {
    setActiveTab(tab);
  };

  const changeBelong = (roleId: any) => {
    let roleList = true;
    if (belong.includes(roleId)) {
      if (belong.length > 1) {
        belong.splice(belong.indexOf(roleId), 1);
        roleList = false;
      }
    } else {
      belong.push(roleId);
      roleList = false;
    }
    if (!roleList) {
      setToolValue({ ...toolValue, belong: belong });
      dispatch(editScriptsList(true, scriptsDetails.id, toolValue, false));
    } else {
      toast.error("At least one role is required");
    }
    setChangeRole({ modal: false, id: 0, action: "add" });
  };
  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                <FormattedMessage
                  id="head.AdminScriptDetails"
                  defaultMessage="Admin Script Details"
                />
              </h1>
            </div>
            <div className="m-l-10 common-right-button">
              <Link to="/scripts" className="btn btn-secondary">
                <i className="back-button">
                  <BsChevronLeft /> &nbsp;
                </i>
                <FormattedMessage id="page.back" defaultMessage="Back" />
              </Link>
            </div>
          </div>
        </header>

        <section className="page-content container-fluid">
          {isLoading && <CenterLoader />}
          <Card>
            <Card.Body className="p-0">
              <ul className="nav nav-tabs primary-tabs m-0">
                <li
                  className="nav-item"
                  role="presentation"
                  onClick={() => setAciveTab(1)}
                >
                  <a
                    href="#tab-1"
                    className={
                      activeTab === 1 ? "nav-link active show" : "nav-link"
                    }
                    data-toggle="tab"
                    aria-expanded="true"
                  >
                    <FormattedMessage
                      id="classDetail.Details"
                      defaultMessage="Details"
                    />
                  </a>
                </li>

                <li
                  className="nav-item"
                  role="presentation"
                  onClick={() => setAciveTab(3)}
                >
                  <a
                    href="#tab-2"
                    className={
                      activeTab === 3 ? "nav-link active show" : "nav-link"
                    }
                    data-toggle="tab"
                    aria-expanded="true"
                  >
                    <FormattedMessage
                      id="classDetail.Version"
                      defaultMessage="Version"
                    />
                  </a>
                </li>
              </ul>
              <div className="">
                <Card.Text>
                  {activeTab === 1 && (
                    <div
                      className={
                        activeTab === 1
                          ? "tab-pane fadeIn active"
                          : "tab-pane fadeIn"
                      }
                      id="tab-1"
                    >
                      <div className="card-body">
                        <Row>
                          <Col sm={6} md={6} lg={4}>
                            {scriptsDetails.image_path ? (
                              <img
                                style={{ minWidth: "100%" }}
                                src={scriptsDetails.image_path}
                              />
                            ) : (
                              <i>
                                {" "}
                                <img
                                  src={Script}
                                  style={{ height: "190px" }}
                                />{" "}
                              </i>
                            )}
                          </Col>
                          <Col sm={6} md={6} lg={8}>
                            {Object.keys(scriptsDetails).length > 0 && (
                              <Table borderless>
                                <tbody>
                                  <tr>
                                    <td className="details-td head-td">
                                      <FormattedMessage
                                        id="table.head.ID"
                                        defaultMessage="ID"
                                      />
                                      :{" "}
                                    </td>
                                    <td
                                      className="details-td"
                                      style={{ textAlign: "left" }}
                                    >
                                      {scriptsDetails.id}
                                    </td>
                                  </tr>
                                  <tr style={{ height: "20px" }}></tr>

                                  <tr>
                                    <td className="head-td details-td">
                                      <FormattedMessage
                                        id="table.head.Name"
                                        defaultMessage="Name"
                                      />
                                      :{" "}
                                    </td>
                                    <td className="details-td">
                                      {scriptsDetails?.name || "N/A"}
                                    </td>
                                  </tr>
                                  <tr style={{ height: "20px" }}></tr>

                                  <tr>
                                    <td
                                      className="head-td details-td"
                                      style={{ verticalAlign: "baseline" }}
                                    >
                                      <FormattedMessage
                                        id="table.head.info"
                                        defaultMessage="Info"
                                      />
                                      :{" "}
                                    </td>
                                    <td className="details-td">
                                      {scriptsDetails.info}
                                    </td>
                                  </tr>
                                  <tr style={{ height: "20px" }}></tr>

                                  <tr>
                                    <td className="head-td details-td">
                                      <FormattedMessage
                                        id="table.head.Status"
                                        defaultMessage="Status"
                                      />
                                      :{" "}
                                    </td>
                                    <td className="details-td">
                                      {scriptsDetails.isActive
                                        ? "Active"
                                        : "InActive"}
                                    </td>
                                  </tr>

                                  <tr style={{ height: "20px" }}></tr>

                                  <tr>
                                    <td className="head-td details-td">
                                      <FormattedMessage
                                        id="table.head.Belong"
                                        defaultMessage="Belong"
                                      />
                                      :{" "}
                                    </td>
                                    <td className="details-td">
                                      {adminRoleList.map((r: any) => (
                                        <>
                                          {r.isEARole && (
                                            <div className="custom-control custom-checkbox">
                                              <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={`defaultUncheckedDisabled${r.id}`}
                                                checked={belong.includes(r.id)}
                                                onClick={() =>
                                                  setChangeRole({
                                                    modal: true,
                                                    id: r.id,
                                                    action: belong.includes(
                                                      r.id,
                                                    )
                                                      ? "remove"
                                                      : "add",
                                                  })
                                                }
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor={`defaultUncheckedDisabled${r.id}`}
                                              >
                                                {r.roleName}
                                              </label>
                                            </div>
                                          )}
                                        </>
                                      ))}
                                    </td>
                                  </tr>
                                  <tr style={{ height: "20px" }}></tr>

                                  <tr>
                                    <td className="head-td details-td">
                                      <FormattedMessage
                                        id="table.head.originalPrice"
                                        defaultMessage="Original Price"
                                      />
                                      :{" "}
                                    </td>
                                    <td className="details-td">
                                      USD$ {scriptsDetails.originalPrice}
                                    </td>
                                  </tr>
                                  <tr style={{ height: "20px" }}></tr>

                                  <tr>
                                    <td className="head-td details-td">
                                      <FormattedMessage
                                        id="table.head.sellingPrice"
                                        defaultMessage="Selling  Price"
                                      />
                                      :{" "}
                                    </td>
                                    <td className="details-td">
                                      USD$ {scriptsDetails.sellingPrice}
                                    </td>
                                  </tr>
                                  <tr style={{ height: "20px" }}></tr>

                                  <tr>
                                    <td className="head-td details-td">
                                      <FormattedMessage
                                        id="table.head.info"
                                        defaultMessage="info"
                                      />
                                      :{" "}
                                    </td>
                                    <td className="details-td">
                                      {scriptsDetails.info}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}

                  {activeTab === 2 && (
                    <div
                      className={
                        activeTab === 2
                          ? "tab-pane fadeIn active"
                          : "tab-pane fadeIn"
                      }
                      id="tab-2"
                    ></div>
                  )}
                  {activeTab === 3 && (
                    <div
                      className={
                        activeTab === 3
                          ? "tab-pane fadeIn active"
                          : "tab-pane fadeIn"
                      }
                      id="tab-2"
                    >
                      <ScriptVersion />
                    </div>
                  )}
                </Card.Text>
              </div>
            </Card.Body>
          </Card>
        </section>
      </div>
      {changeRole && (
        <AddRemoveToolRoleModal
          onClose={() => {
            setChangeRole({ modal: false, id: 0, action: "add" });
          }}
          onDeleteClick={() => changeBelong(changeRole.id)}
          open={changeRole.modal}
          text={changeRole.action}
        />
      )}
    </div>
  );
};
export default ScriptDetail;
