import { Switch } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
import UserSelectionByClass from "../../Components/Selection/UserSelectionByClass";
import { dispatch } from "../../redux";
import {
  addUserMembershipList,
  getClasses,
  getGiftCategoryList,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import { MemebershipGrantTimeOption } from "../../util/constants/UserGroup";
import { AddUserMembershipSchema } from "../../util/validationSchema";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "../../Components/common/Loader";
import {
  formatDate,
  formatDateDiff,
  isPositiveNumbersOnly,
} from "../../util/utils";
import { union } from "lodash";

const User = () => {
  const userSelectionRef = useRef<any>();

  const [selectedDays, setselectedDays] = useState({
    id: 0,
    value: "Manual",
    valueZh: "手動的",
  });
  const [showCalender, setShowCalender] = useState(false);

  const isLoading = useSelector(
    (state: Store) => state.membershipReducer.isLoading,
  );

  const local = useSelector((state: Store) => state.userDataReducer.local);

  const [buttonClick, setButtonClick] = useState({});

  useEffect(() => {
    dispatch(getClasses(false, 0, { by: "createdAt", order: "ASC" }));
    dispatch(getGiftCategoryList(false));
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      permanent: false,
      grantTime: 0,
      grantDate: `${new Date().getFullYear()}-${
        new Date().getMonth() + 1
      }-${new Date().getDate()}`,
      days: 0,
      remarks: "",
      usersId: [],
      additionalUserIds: "",
      parsedAdditionalUserIds: [],
    },
    validationSchema: AddUserMembershipSchema,
    onSubmit: async (values) => {
      if (!buttonClick) {
        values.grantTime = 0;
        values.remarks = values.remarks.trim();
        values.usersId = union(values.usersId, values.parsedAdditionalUserIds);

        const { additionalUserIds, parsedAdditionalUserIds, ...payload } =
          values;

        dispatch(addUserMembershipList(true, payload));
        setButtonClick(true);
        setShowCalender(false);
      }
    },
  });
  const { errors, touched, resetForm, setFieldError, setFieldValue } = formik;

  const handleParsedAdditionalUserIds = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const s = e.target.value.replace(/\n+$/, "");
    if (s === "") {
      setFieldError("parsedAdditionalUserIds", undefined);
      setFieldValue("parsedAdditionalUserIds", []);
      return;
    }

    const parsedAdditionalUserIds = s.split("\n").map((c) => parseInt(c));
    if (parsedAdditionalUserIds.some((d) => isNaN(d))) {
      setFieldError("parsedAdditionalUserIds", "Check you input format");
      setFieldValue("parsedAdditionalUserIds", []);
    } else {
      setFieldError("parsedAdditionalUserIds", undefined);
      setFieldValue("parsedAdditionalUserIds", parsedAdditionalUserIds);
    }
  };

  useEffect(() => {
    if (formik.values.permanent) {
      // formik.setFieldValue("grantDate",new Date())
      formik.setFieldValue("days", 1);
    } else {
      // formik.setFieldValue("grantDate","")
      formik.setFieldValue("days", 0);
    }
    formik.setFieldValue("grantTime", false);

    setselectedDays({ id: 0, value: "Manual", valueZh: "手動的" });
    setShowCalender(false);
    formik.setFieldValue("grantDate", new Date());
  }, [formik.values.permanent]);

  useEffect(() => {
    if (!isLoading && buttonClick) {
      resetForm();
      setselectedDays({ id: 0, value: "Manual", valueZh: "手動的" });
      userSelectionRef?.current();
      setButtonClick(false);
    }
  }, [isLoading]);

  const onFormReset = (e: any) => {
    e.preventDefault();
    setselectedDays({ id: 0, value: "Manual", valueZh: "手動的" });
    resetForm();
    setShowCalender(false);
    userSelectionRef?.current();
  };

  const getDateDay = (date: any) => {
    formik.setFieldValue("grantDate", date);
    const dateTime = new Date();
    const currentDate = `${dateTime.getFullYear()}-${
      dateTime.getMonth() + 1
    }-${dateTime.getDate()}`;
    const days = formatDateDiff(date, currentDate);
    formik.setFieldValue("days", 0);
    // formik.setFieldValue("grantTime", days);
  };

  return (
    <form
      onSubmit={(e: any) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <div className="card-body">
        <Row>
          <Col xl={8}>
            <Row>
              <Col md={12}>
                <UserSelectionByClass
                  refs={userSelectionRef}
                  setFinalUsers={(users: any) =>
                    formik.setFieldValue("usersId", users)
                  }
                  users={formik.values.usersId}
                />
                {errors.usersId && touched.usersId && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.usersId.toString()}
                      defaultMessage="this field required"
                    />
                  </div>
                )}
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="control-label">Additional User Ids</label>
                  <Row>
                    <Col>
                      <textarea
                        className="form-control"
                        name="additionalUserIds"
                        id="additionalUserIds"
                        style={{
                          width: "100%",
                          height: "100%",
                          boxSizing: "border-box",
                        }}
                        value={formik.values.additionalUserIds}
                        onChange={(e) => {
                          formik.handleChange(e);
                          handleParsedAdditionalUserIds(e);
                        }}
                        onBlur={formik.handleBlur}
                        rows={8}
                      />
                      {touched.additionalUserIds &&
                        errors.parsedAdditionalUserIds && (
                          <div className="text-error">
                            <FormattedMessage
                              id={errors.parsedAdditionalUserIds.toString()}
                              defaultMessage="At least one user should be selected"
                            />
                          </div>
                        )}
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="control-label">
                    Result Of Parsing (
                    {`${formik.values.parsedAdditionalUserIds.length} ids are parsed`}
                    )
                  </label>
                  <textarea
                    className="form-control"
                    name="additionalUserIds"
                    id="additionalUserIds"
                    style={{
                      width: "100%",
                      height: "100%",
                      boxSizing: "border-box",
                    }}
                    value={formik.values.parsedAdditionalUserIds.join("\n")}
                    disabled={true}
                    rows={8}
                  ></textarea>
                </div>
              </Col>
              {!formik.values.permanent && (
                <>
                  {" "}
                  <Col md={6}>
                    <div className="form-group">
                      <label>
                        <FormattedMessage
                          id="Membership.user.Time"
                          defaultMessage="Grant Time "
                        />
                      </label>
                      <Select
                        name="belong"
                        value={selectedDays}
                        onChange={(option: any) => {
                          formik.setFieldValue("grantTime", option.id == 1);
                          console.log("option.id", option.id);
                          if (option.id == 0) {
                            console.log("==0", option.id);
                            formik.setFieldValue("days", 0);
                            // formik.setFieldValue("grantTime", null);
                          } else {
                            formik.setFieldValue("days", option.id);
                            // formik.setFieldValue("grantTime", option.id);
                          }
                          if (option.id !== 1) {
                            // formik.setFieldError("grantDate", "0");
                            setselectedDays(option);
                            setShowCalender(false);
                            formik.setFieldValue("grantDate", new Date());
                          } else {
                            setShowCalender(true);
                            formik.setFieldValue("days", 0);
                            // formik.setFieldValue("grantDate", null);
                            formik.setFieldValue("grantDate", null);

                            setselectedDays(option);
                          }
                        }}
                        onBlur={() => formik.setFieldTouched("days", true)}
                        options={MemebershipGrantTimeOption}
                        getOptionLabel={(option: any) =>
                          local === "zh" ? option.valueZh : option.value
                        }
                        getOptionValue={(option: any) => option.id}
                        className="custom-select-dropdown"
                        classNamePrefix="react-select-dropdown"
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </div>
                  </Col>
                  {!showCalender && (
                    <Col md={6}>
                      <div className="form-group">
                        {/* <label className="d-block">&nbsp;</label> */}
                        <label className="control-label">
                          <FormattedMessage
                            id="Admin.Membership.Days"
                            defaultMessage="Days"
                          />{" "}
                        </label>
                        <input
                          type="number"
                          name="days"
                          // placeholder={useIntl().formatMessage({
                          //   id: "select.Days",
                          //   defaultMessage: "Days",
                          // })}
                          value={formik.values.days}
                          onChange={(e: any) => {
                            if (!isPositiveNumbersOnly(e, e.target.value)) {
                              return false;
                            }
                            formik.handleChange(e);
                            // formik.setFieldValue("grantTime", parseInt(e.target.value));
                          }}
                          onBlur={formik.handleBlur}
                          className="form-control"
                          disabled={selectedDays.id > 0}
                          min={0}
                          max={3650}
                          // onKeyPress={(e: any) => {
                          //   if (!isPositiveNumbersOnly(e.code)) {
                          //     e.preventDefault();
                          //   }
                          // }}
                        />
                        {errors.days && touched.days && (
                          <div className="text-error">
                            <FormattedMessage
                              id={errors.days.toString()}
                              defaultMessage="this field required."
                            />
                          </div>
                        )}
                      </div>
                    </Col>
                  )}
                  {showCalender && (
                    <Col md={6}>
                      <div className="form-group">
                        <label className="control-label">
                          <FormattedMessage
                            id="Admin.Membership.Date"
                            defaultMessage="Date"
                          />{" "}
                        </label>
                        <input
                          type="date"
                          name="grantDate"
                          value={formik.values.grantDate}
                          onChange={(e) => {
                            getDateDay(e.target.value);
                          }}
                          // onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="form-control form-control-md"
                          id="colFormLabelSm"
                          // placeholder={useIntl().formatMessage({
                          //   id: "Classes.Details",
                          //   defaultMessage: "Detail",
                          // })}
                        />
                        {errors.grantDate && touched.grantDate && (
                          <div className="text-error">
                            <FormattedMessage
                              id={errors.grantDate.toString()}
                              defaultMessage="This field is required."
                            />
                          </div>
                        )}
                      </div>
                    </Col>
                  )}
                </>
              )}

              <Col md={6}>
                <div className="form-group">
                  <label className="d-block">
                    <FormattedMessage
                      id="Membership.user.Permanent"
                      defaultMessage="Permanent"
                    />{" "}
                  </label>
                  <Switch
                    checked={formik.values.permanent}
                    onChange={() => {
                      // if (!formik.values.permanent) {
                      formik.setFieldValue("days", 0);
                      // formik.setFieldValue("grantTime", 0);

                      // }
                      formik.setFieldValue(
                        "permanent",
                        !formik.values.permanent,
                      );
                    }}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label>
                    <FormattedMessage
                      id="Membership.user.Remarks"
                      defaultMessage="Remarks "
                    />
                  </label>
                  <input
                    type="text"
                    name="remarks"
                    value={formik.values.remarks}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-control"
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="card-footer bg-light text-right">
        <button
          className="btn btn-secondary mr-3"
          onClick={(e: any) => onFormReset(e)}
        >
          <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
        </button>

        <Button type="submit">
          <FormattedMessage
            id="Membership.user.Submit"
            defaultMessage="Submit "
          />
          {isLoading && <Loader />}
        </Button>
      </div>
    </form>
  );
};

export default User;
