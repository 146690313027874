import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
// import { useSelector } from "react-redux";
import UserSelectionByClass from "../../Components/Selection/UserSelectionByClass";
import { dispatch } from "../../redux";
import {
  addGiftList,
  addUserRefaralList,
  getClasses,
  getGiftCategoryList,
  getStudentByClassList,
} from "../../redux/action";
// import { Store } from "../../redux/Actions";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "react-select";
import { useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import { addReferalSchema } from "../../util/validationSchema";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import Loader from "../../Components/common/Loader";
import { isPositiveNumbersOnly } from "../../util/utils";
import { toast } from "react-toastify";

const ReferalList = () => {
  const classes = useSelector(
    (state: Store) => state.classReducer.classes,
  ) as any;
  const classStudentsList = useSelector(
    (state: Store) => state.studentReducer.classStudentsList,
  ) as any;

  const [buttonClick, setButtonClick] = useState(false);
  const [referalStudent, setReferalStudent] = useState([]);
  const [referalClass, setReferalClass] = useState({});

  const [refeeStudent, setrefreeStudent] = useState([]);
  const [refeeClass, setRefeeClass] = useState({});
  const [refeeClassChange, setRefeeClassChange] = useState(false);
  const [referalClassChange, setReferalClassChange] = useState(false);

  const local = useSelector((state: Store) => state.userDataReducer.local);

  const isLoading = useSelector(
    (state: Store) => state.membershipReducer.isLoading,
  ) as any;

  const Loading = useSelector(
    (state: Store) => state.studentReducer.Loading.isLoading,
  ) as any;

  // const [selectedClassRefereeStudent, setselectedRefereeClassStudent] =
  //   useState([]);
  // const [selectedRefereeClass, setselectedRefereeClass] = useState({});

  // const [selectedClassReferalStudent, setselectedReferalClassStudent] =
  //   useState([]);
  // const [selectedReferalClass, setselectedReferalClass] = useState({});

  const [selectedClassRefereeStudent, setselectedRefereeClassStudent] =
    useState<any>(null);
  const [selectedRefereeClass, setselectedRefereeClass] = useState<any>(null);
  const [selectedRewardType, setSelectedRewardType] = useState<any>(null);
  // const [reward, setReward] = useState<any>(null); //  ?? Done by yash kevdiya

  const rewardType = [
    {
      id: "membership",
      name: "Membership",
    },
    {
      id: "points",
      name: "Point",
    },
  ];

  const [selectedClassReferalStudent, setselectedReferalClassStudent] =
    useState<any>(null);
  const [selectedReferalClass, setselectedReferalClass] = useState<any>(null);

  useEffect(() => {
    dispatch(getClasses(false, 0, { by: "createdAt", order: "ASC" }));
    dispatch(getGiftCategoryList(false));
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      referer: 0,
      refree: 0,
      refreeClass: 0,
      refralClass: 0,
      rewardType: "",
      rewardPoint: 0,
    },
    validationSchema: addReferalSchema,
    onSubmit: async (values) => {
      if (!buttonClick) {
        if (values.referer === values.refree) {
          toast.warn("User can not refer themselves");
        } else {
          dispatch(
            addUserRefaralList(true, {
              referer: values.referer,
              refree: values.refree,
              rewardType: values.rewardType,
              rewardPoint: values.rewardPoint,
              refreeClass: values.refreeClass,
              refralClass: values.refralClass,
            }),
          );
          setButtonClick(true);
        }
      }
    },
  });

  useEffect(() => {
    if (!Loading && buttonClick) {
      setButtonClick(false);
      formik.resetForm();
      setselectedReferalClass(null);
      setselectedRefereeClass(null);
      setselectedRefereeClassStudent(null);
      setselectedReferalClassStudent(null);
      setSelectedRewardType(null);
      // setReward(null);
    }
  }, [Loading, buttonClick]);

  const onClassChange = (option: any) => {
    dispatch(getStudentByClassList(false, option.id));
  };

  useEffect(() => {
    if (referalClassChange && !Loading) {
      setReferalStudent(classStudentsList);
      setReferalClassChange(false);
    }
  }, [referalClassChange, Loading, classStudentsList]);

  useEffect(() => {
    if (refeeClassChange && !Loading) {
      setrefreeStudent(classStudentsList);
      setRefeeClassChange(false);
    }
  }, [selectedRefereeClass, classStudentsList, refeeClassChange]);

  const onClassRefreeChange = (option: any) => {
    dispatch(getStudentByClassList(false, option.id, 1));
    setselectedRefereeClass(option);
    formik.setFieldValue("refreeClass", parseInt(option.id));
    setRefeeClassChange(true);
  };

  const onClassRefralChange = (option: any) => {
    setReferalClassChange(true);
    setselectedReferalClass(option);
    dispatch(getStudentByClassList(false, option.id, 1));
    formik.setFieldValue("refralClass", parseInt(option.id));
  };
  const { errors, touched } = formik;
  return (
    <div>
      {Loading && <CenterLoader />}
      <div className="card-body">
        <Row>
          <Col xl={8}>
            <Row>
              <Col md={12}>
                <FormattedMessage
                  id="head.Referral"
                  defaultMessage="Referral"
                />
                <Row>
                  <Col>
                    <div className="form-group">
                      <label className="control-label">
                        <FormattedMessage
                          id="head.SelectClass"
                          defaultMessage="Select Class"
                        />
                      </label>
                      <Select
                        name="refralClass"
                        value={selectedReferalClass}
                        onChange={(option: any) => {
                          onClassRefralChange(option);
                          setselectedReferalClassStudent(null);

                          // setselectedClass(option);
                          //CHanges on referaral stydent  onclass change x
                        }}
                        options={classes}
                        getOptionLabel={(option: any) => option.name}
                        getOptionValue={(option: any) => option.id}
                        className="custom-select-dropdown"
                        classNamePrefix="react-select-dropdown"
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        // placeholder={useIntl().formatMessage({
                        //   id: "select.RefererClass",
                        //   defaultMessage: "Select referer class",
                        // })}
                      />
                      {errors.refralClass && touched.refralClass && (
                        <div className="text-error">
                          <FormattedMessage
                            id={errors.refralClass.toString()}
                            defaultMessage="This field is required"
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group">
                      <label className="control-label">
                        <FormattedMessage
                          id="head.SelectStudents"
                          defaultMessage="Select Students"
                        />
                      </label>
                      <Select
                        name="referer"
                        value={selectedClassReferalStudent}
                        onChange={(option: any) => {
                          setselectedReferalClassStudent(option);
                          formik.setFieldValue("referer", parseInt(option.id));
                        }}
                        options={referalStudent}
                        getOptionLabel={(option: any) =>
                          `(${option.id}) ${option.englishFirstName} ${option.englishLastName} (${option.preferredNickName})`
                        }
                        getOptionValue={(option: any) => option.id}
                        // isMulti
                        className="custom-select-dropdown multi"
                        classNamePrefix="react-select-dropdown"
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        // placeholder={useIntl().formatMessage({
                        //   id: "select.RefererStudent",
                        //   defaultMessage: "Select referer student",
                        // })}
                      />
                      {errors.referer && touched.referer && (
                        <div className="text-error">
                          <FormattedMessage
                            id={errors.referer.toString()}
                            defaultMessage="This field is required"
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  {/* <Col>
                    <div className="form-group">
                      <label className="control-label">
                        <FormattedMessage
                          id="head.FinalList"
                          defaultMessage="Final List"
                        />
                      </label>
                      <div
                        style={{
                          border: "1px solid #dfe7f3",
                          borderRadius: "5px",
                          padding: "7px",
                          height: "115px",
                        }}
                      >
                        {selectedFinalStudent.map((i: any) => (
                          <Chip
                            label={`${i.englishFirstName} ${i.englishLastName}`}
                            variant="outlined"
                            size="small"
                            className="mr-1"
                            onDelete={() => onDeleteUser(i.id)}
                          />
                        ))}
                      </div>
                    </div>
                  </Col> */}
                </Row>
                <FormattedMessage id="head.Referee" defaultMessage="Referee" />
                <Row>
                  <Col>
                    <div className="form-group">
                      <label className="control-label">
                        <FormattedMessage
                          id="head.SelectClass"
                          defaultMessage="Select Class"
                        />
                      </label>
                      <Select
                        name="refreeClass"
                        value={selectedRefereeClass}
                        onChange={(option: any) => {
                          onClassRefreeChange(option);
                          setselectedRefereeClassStudent(null);
                          // setselectedClass(option);
                        }}
                        options={classes}
                        getOptionLabel={(option: any) => option.name}
                        getOptionValue={(option: any) => option.id}
                        className="custom-select-dropdown"
                        classNamePrefix="react-select-dropdown"
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        // placeholder={useIntl().formatMessage({
                        //   id: "select.RefereeClass",
                        //   defaultMessage: "Select referee class",
                        // })}
                      />

                      {errors.refreeClass && touched.refreeClass && (
                        <div className="text-error">
                          <FormattedMessage
                            id={errors.refreeClass.toString()}
                            defaultMessage="This field is required"
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group">
                      <label className="control-label">
                        <FormattedMessage
                          id="head.SelectStudents"
                          defaultMessage="Select Students"
                        />
                      </label>
                      <Select
                        name="refree"
                        value={selectedClassRefereeStudent}
                        onChange={(option: any) => {
                          setselectedRefereeClassStudent(option);
                          formik.setFieldValue("refree", parseInt(option.id));
                        }}
                        options={refeeStudent}
                        getOptionLabel={(option: any) =>
                          `(${option.id}) ${option.englishFirstName} ${option.englishLastName} (${option.preferredNickName})`
                        }
                        getOptionValue={(option: any) => option.id}
                        // isMulti
                        className="custom-select-dropdown multi"
                        classNamePrefix="react-select-dropdown"
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        // placeholder={useIntl().formatMessage({
                        //   id: "select.RefereeStudent",
                        //   defaultMessage: "Select referee student",
                        // })}
                      />
                      {errors.refree && touched.refree && (
                        <div className="text-error">
                          <FormattedMessage
                            id={errors.refree.toString()}
                            defaultMessage="This field is required"
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  {/* <Col>
                    <div className="form-group">
                      <label className="control-label">
                        <FormattedMessage
                          id="head.FinalList"
                          defaultMessage="Final List"
                        />
                      </label>
                      <div
                        style={{
                          border: "1px solid #dfe7f3",
                          borderRadius: "5px",
                          padding: "7px",
                          height: "115px",
                        }}
                      >
                        {selectedFinalStudent.map((i: any) => (
                          <Chip
                            label={`${i.englishFirstName} ${i.englishLastName}`}
                            variant="outlined"
                            size="small"
                            className="mr-1"
                            onDelete={() => onDeleteUser(i.id)}
                          />
                        ))}
                      </div>
                    </div>
                  </Col> */}
                </Row>
                <Row>
                  <Col>
                    <div className="form-group">
                      <label className="control-label">
                        <FormattedMessage
                          id="head.RewardType"
                          defaultMessage="Reward Type"
                        />
                      </label>
                      <Select
                        name="rewardType"
                        value={selectedRewardType}
                        onChange={(option: any) => {
                          setSelectedRewardType(option);
                          formik.setFieldValue("rewardType", option.id);
                        }}
                        options={rewardType}
                        getOptionLabel={(option: any) => option.name}
                        getOptionValue={(option: any) => option.id}
                        className="custom-select-dropdown"
                        classNamePrefix="react-select-dropdown"
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        // placeholder={useIntl().formatMessage({
                        //   id: "select.RewardType",
                        //   defaultMessage: "Select Reward Type",
                        // })}
                      />

                      {errors.rewardType && touched.rewardType && (
                        <div className="text-error">
                          <FormattedMessage
                            id={errors.rewardType.toString()}
                            defaultMessage="This field is required"
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group">
                      <label className="control-label">
                        <FormattedMessage
                          id="head.Reward"
                          defaultMessage="Reward"
                        />
                        {selectedRewardType &&
                        selectedRewardType.id === "membership"
                          ? " (Days)"
                          : " (Points)"}
                      </label>
                      <input
                        type="text"
                        name="rewardPoint"
                        // value={reward === null ? "null" : reward} //  ?? Done by yash kevdiya
                        value={formik.values.rewardPoint}
                        onChange={(e: any) => {
                          if (!isPositiveNumbersOnly(e, e.target.value)) {
                            return false;
                          }
                          formik.handleChange(e);
                          // if (e.target.value >= 0) {
                          //   formik.handleChange(e);
                          // } else {
                          //   return false;
                          // }  // setReward(parseInt(e.target.value));
                          // formik.setFieldValue(
                          //   "rewardPoint",
                          //   parseInt(e.target.value)
                          // );
                        }}
                        // min={0}
                        // onKeyPress={(e: any) => {
                        //   if (!isPositiveNumbersOnly(e.code)) {
                        //     e.preventDefault();
                        //   }
                        // }}
                        className="form-control"
                        // placeholder={useIntl().formatMessage({
                        //   id: "select.Reward",
                        //   defaultMessage: "Reward",
                        // })}
                      />
                      {errors.rewardPoint && touched.rewardPoint && (
                        <div className="text-error">
                          <FormattedMessage
                            id={errors.rewardPoint.toString()}
                            defaultMessage="This field is required"
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
              {/* <Col md={6}>
                <div className="form-group">
                  <label className="control-label">
                    <FormattedMessage
                      id="Membership.class.Remarks"
                      defaultMessage="Remarks"
                    />{" "}
                  </label>
                  <input
                    type="text"
                    name="initRepeaterExpiryDays"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-control"
                  />
                </div>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </div>
      <div className="card-footer bg-light text-right">
        <Button onClick={() => formik.handleSubmit()}>
          <FormattedMessage
            id="Membership.Refer.Submit"
            defaultMessage="Submit "
          />
          {isLoading && buttonClick ? <Loader /> : ""}
          {/* <Loader /> */}
        </Button>
      </div>
    </div>
  );
};

export default ReferalList;
