import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useLayoutEffect,
  useEffect,
} from "react";
import { getFileName } from "../../util/utils";
import * as _ from "lodash";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import { IconButton } from "@mui/material";
import { dispatch } from "../../redux";
import {
  downloadMediaList,
  setCompleteMediaDownload,
  setDownloadMediaListProgress,
  setSneekBarShow,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import { useSelector } from "react-redux";
import { FaTimesCircle } from "react-icons/fa";

export const ToastDownload = forwardRef((props: any, ref: any) => {
  const [showProg, setShowProg] = useState<any>(true);

  const downloadingFiles: any = useSelector(
    (state: Store) => state.mediaUploadReducer.downloadingFiles,
  ) as any;

  const progressPercentages: any = useSelector(
    (state: Store) => state.mediaUploadReducer.progressPercentages,
  ) as any;
  const show: any = useSelector(
    (state: Store) => state.mediaUploadReducer.show,
  ) as any;

  useEffect(() => {
    dispatch(setSneekBarShow(showProg));
  }, [showProg]);

  const handleToClose = () => {
    setShowProg(false);
  };
  const handleToCloses = (event: any, reason: any) => {
    if ("clickaway" == reason) return;
    setShowProg(false);
  };
  useImperativeHandle(ref, () => ({
    downloadFiles(url: any, elId: any) {
      dispatch(setSneekBarShow(true));

      setShowProg(true);
      const Load: Array<string> = props.Load;
      if (!Load.includes(elId)) {
        downloadToastFile(url, elId);
      }
    },
  }));

  const downloadToastFile = async (url: any, id: any) => {
    const fileName = getFileName(url);
    dispatch(downloadMediaList({ id: id, fileName: fileName }));
    const str = fileName;
    console.log("event", id);
    await axios({
      url: url,
      method: "GET",
      responseType: "blob", // important
      onDownloadProgress: (progressEvent) => {
        if (
          Math.round(
            (progressEvent.loaded * 100) / (progressEvent.total || 1),
          ) < 100
        ) {
          const progress = Math.round(
            (progressEvent.loaded * 100) / (progressEvent.total || 1),
          );
          progressPercentages[id] = progress;
          dispatch(setDownloadMediaListProgress(id, progress));
        } else {
          dispatch(setCompleteMediaDownload(id));
          let arr = props.Load;
          arr = arr.filter(function (item: any) {
            return item !== id;
          });
          props.setLoad(arr);
        }
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", str);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        //None
      });
  };

  return (
    <>
      {downloadingFiles.length > 0 && (
        <Snackbar
          open={show}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          message={downloadingFiles.map((i: any, index: any) => (
            <>
              <p style={{ color: "white" }} key={i?.id}>
                {i.fileName} downloading...{" "}
                {Object.keys(progressPercentages).length > 0 ? (
                  <>{progressPercentages[i.id]} </>
                ) : (
                  0
                )}
                %
              </p>
              {downloadingFiles.length - 1 !== index && <hr />}
            </>
          ))}
          onClose={handleToCloses}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleToClose}
              >
                <FaTimesCircle fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      )}
    </>
  );
});

// export const donwloadViaToast = (url:any,id:any)=>{
//     downloadToastFile(url,id)
// }
