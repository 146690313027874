/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/APIServices/baseApiCall";
import {
  GET_ACTIVITY_POINTS_LIST,
  EDIT_ACTIVITY_DETAILS_LIST,
  GET_ACTIVITY_DETAILS_LIST,
  GET_ACTIVITY_ATTENDENCE_LIST,
  ADD_ACTIVITY_LIST,
  GET_ACTIVITY_LIST,
  GET_ACTIVITY_ATTENDENCE_LOG_LIST,
  ADD_ACTIVITY_POINTS_LIST,
  REGISTER_ACTIVITY_LIST,
  ROLL_CALL_ACTIVITY_LIST,
  ATTEND_ACTIVITY,
  SET_PAGE,
  CHECK_STUDENT_ACTIVITY,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { getAPIEndPoint, getPage, setToken } from "../../util/utils";
import {
  GET_ACTIVITY_LIST_URL,
  ADD_ACTIVITY_LIST_URL,
  GET_ACTIVITY_ATTENDENCE_LIST_URL,
  GET_ACTIVITY_DETAILS_URL,
  EDIT_ACTIVITY_DETAILS_URL,
  GET_ACTIVITY_POINTS_URL,
  GET_ACTIVITY_ATTENDENCE_LOG_LIST_URL,
  ADD_ACTIVITY_POINTS_URL,
  ATTENND_ACTIVITY_URL,
  ROLL_CALL_ACTIVITY_URL,
  REGISTER_ACTIVITY_URL,
  GET_USER_ATTENDENCE_LIST_URL,
  CHECK_STUDENT_ACTIVITY_URL,
} from "../../service/APIServices/apiEndPoints";
import { toast } from "react-toastify";

export const setCurrentActivityPage =
  (pageNumber: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: SET_PAGE,
      payload: pageNumber,
    });
  };

//Get Admin Roles List
export const getActivityList =
  (
    showToast = true,
    sort: any,
    pageNumber = 0,
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getActivityListInit(showToast, pageNumber, sort, pageLimit));
  };

export const getActivityListInit =
  (
    showToast = true,
    pageNumber: number,
    sort: any,
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ACTIVITY_LIST.GET_ACTIVITY_LIST_INITIALIZATION,
    });
    apiCall(
      getAPIEndPoint(
        GET_ACTIVITY_LIST_URL + `?sort=${sort.order}&sortBy=${sort.by}`,
        pageNumber,
        true,
        pageLimit,
      ),
      {},
      (res: Object) => dispatch(getActivityListSuccess(res)),
      (err: any) => dispatch(getActivityListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getActivityListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ACTIVITY_LIST.GET_ACTIVITY_LIST_SUCCESS,
      payload: res,
    });
  };

export const getActivityListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_ACTIVITY_LIST.GET_ACTIVITY_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const addActivity =
  (
    showToast = true,
    activity: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(addActivityInit(showToast, activity));
  };

export const addActivityInit =
  (
    showToast = true,
    activity: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_ACTIVITY_LIST.ADD_ACTIVITY_LIST_INITIALIZATION,
    });
    apiCall(
      ADD_ACTIVITY_LIST_URL,
      activity,
      (res: Object) => dispatch(addActivitySuccess(res)),
      (err: any) => dispatch(addActivityError()),
      METHOD.POST,
      { showToast },
    );
  };

export const addActivitySuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_ACTIVITY_LIST.ADD_ACTIVITY_LIST_SUCCESS,
      payload: res.data,
    });

    dispatch(setCurrentActivityPage(1));
    dispatch(getActivityList(false, { by: "createdAt", order: "DESC" }, 1));
  };

export const addActivityError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_ACTIVITY_LIST.ADD_ACTIVITY_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const getActivityAttendence =
  (
    showToast = true,
    pageNumber = 0,
    isUser = 0,
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      getActivityAttendenceInit(showToast, pageNumber, isUser, pageLimit),
    );
  };

export const getActivityAttendenceInit =
  (
    showToast = true,
    pageNumber: number,
    isUser: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ACTIVITY_ATTENDENCE_LIST.GET_ACTIVITY_ATTENDENCE_LIST_INITIALIZATION,
    });

    let url = getAPIEndPoint(
      GET_ACTIVITY_ATTENDENCE_LIST_URL,
      pageNumber,
      false,
      pageLimit,
    );
    if (isUser > 0) {
      url = getAPIEndPoint(
        GET_USER_ATTENDENCE_LIST_URL + isUser,
        pageNumber,
        false,
        pageLimit,
      );
    }
    apiCall(
      url + "&sort=DESC&sortBy=createdAt",
      {},
      (res: Object) => dispatch(getActivityAttendenceSuccess(res)),
      (err: any) => dispatch(getActivityAttendenceError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getActivityAttendenceSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ACTIVITY_ATTENDENCE_LIST.GET_ACTIVITY_ATTENDENCE_LIST_SUCCESS,
      payload: res,
    });
  };

export const getActivityAttendenceError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_ACTIVITY_ATTENDENCE_LIST.GET_ACTIVITY_ATTENDENCE_LIST_ERROR,
    });
  };

export const getActivityAttendenceLog =
  (
    showToast = true,
    id: any,
    pageNumber = 1,
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      getActivityAttendenceLogInit(showToast, id, pageNumber, pageLimit),
    );
  };

export const getActivityAttendenceLogInit =
  (
    showToast = true,
    id: any,
    pageNumber: any,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ACTIVITY_ATTENDENCE_LOG_LIST.GET_ACTIVITY_ATTENDENCE_LOG_LIST_INITIALIZATION,
    });
    apiCall(
      GET_ACTIVITY_ATTENDENCE_LOG_LIST_URL +
        id +
        `?page=${pageNumber}&perPage=${pageLimit}`,
      {},
      (res: Object) => dispatch(getActivityAttendenceLogSuccess(res)),
      (err: any) => dispatch(getActivityAttendenceLogError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getActivityAttendenceLogSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ACTIVITY_ATTENDENCE_LOG_LIST.GET_ACTIVITY_ATTENDENCE_LOG_LIST_SUCCESS,
      payload: res,
    });
  };

export const getActivityAttendenceLogError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_ACTIVITY_ATTENDENCE_LOG_LIST.GET_ACTIVITY_ATTENDENCE_LOG_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const getActivityDetails =
  (
    showToast = true,
    activityId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getActivityDetailsInit(showToast, activityId));
  };

export const getActivityDetailsInit =
  (
    showToast = true,
    activityId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ACTIVITY_DETAILS_LIST.GET_ACTIVITY_DETAILS_LIST_INITIALIZATION,
    });
    apiCall(
      `${GET_ACTIVITY_DETAILS_URL}${activityId}`,
      {},
      (res: Object) => dispatch(getActivityDetailsSuccess(res)),
      (err: any) => dispatch(getActivityDetailsError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getActivityDetailsSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ACTIVITY_DETAILS_LIST.GET_ACTIVITY_DETAILS_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getActivityDetailsError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_ACTIVITY_DETAILS_LIST.GET_ACTIVITY_DETAILS_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const editActivityDetails =
  (
    showToast = true,
    activity: any,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(editActivityDetailsInit(showToast, activity, id));
  };

export const editActivityDetailsInit =
  (
    showToast = true,
    activity: any,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_ACTIVITY_DETAILS_LIST.EDIT_ACTIVITY_DETAILS_LIST_INITIALIZATION,
    });
    apiCall(
      EDIT_ACTIVITY_DETAILS_URL + id,
      activity,
      (res: Object) => dispatch(editActivityDetailsSuccess(res, id)),
      (err: any) => dispatch(editActivityDetailsError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const editActivityDetailsSuccess =
  (res: any, id: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_ACTIVITY_DETAILS_LIST.EDIT_ACTIVITY_DETAILS_LIST_SUCCESS,
      payload: res.data,
    });
    dispatch(getActivityDetails(false, id));
  };

export const editActivityDetailsError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: EDIT_ACTIVITY_DETAILS_LIST.EDIT_ACTIVITY_DETAILS_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const getActivityPoints =
  (
    showToast = true,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getActivityPointsInit(showToast, id));
  };

export const getActivityPointsInit =
  (
    showToast = true,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ACTIVITY_POINTS_LIST.GET_ACTIVITY_POINTS_LIST_INITIALIZATION,
    });
    apiCall(
      GET_ACTIVITY_POINTS_URL + id,
      {},
      (res: Object) => dispatch(getActivityPointsSuccess(res)),
      (err: any) => dispatch(getActivityPointsError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getActivityPointsSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ACTIVITY_POINTS_LIST.GET_ACTIVITY_POINTS_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getActivityPointsError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_ACTIVITY_POINTS_LIST.GET_ACTIVITY_POINTS_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const addActivityPoints =
  (
    showToast = true,
    point: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(addActivityPointsInit(showToast, point));
  };

export const addActivityPointsInit =
  (
    showToast = true,
    point: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_ACTIVITY_POINTS_LIST.ADD_ACTIVITY_POINTS_LIST_INITIALIZATION,
    });
    apiCall(
      ADD_ACTIVITY_POINTS_URL,
      point,
      (res: Object) => dispatch(addActivityPointsSuccess(res)),
      (err: any) => dispatch(addActivityPointsError()),
      METHOD.POST,
      { showToast },
    );
  };

export const addActivityPointsSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    // dispatch(getActivityPoints(false));
    dispatch({
      type: ADD_ACTIVITY_POINTS_LIST.ADD_ACTIVITY_POINTS_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const addActivityPointsError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_ACTIVITY_POINTS_LIST.ADD_ACTIVITY_POINTS_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const registerActivity =
  (
    showToast = true,
    activity: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(registerActivityInit(showToast, activity));
  };

export const registerActivityInit =
  (
    showToast = true,
    activity: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: REGISTER_ACTIVITY_LIST.REGISTER_ACTIVITY_LIST_INITIALIZATION,
    });
    apiCall(
      REGISTER_ACTIVITY_URL,
      activity,
      (res: Object) => dispatch(registerActivitySuccess(res)),
      (err: any) => dispatch(registerActivityError()),
      METHOD.POST,
      { showToast },
    );
  };

export const attendActivity =
  (
    showToast = true,
    activity: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(attendActivityInit(showToast, activity));
  };
export const attendActivityInit =
  (
    showToast: boolean,
    activity: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ATTEND_ACTIVITY.ATTEND_ACTIVITY_INITIALIZATION,
    });
    apiCall(
      ATTENND_ACTIVITY_URL,
      activity,
      (res: Object) => dispatch(attendActivitySuccess(res)),
      (err: any) => dispatch(attendActivityError()),
      METHOD.POST,
      { showToast },
    );
  };

export const attendActivitySuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ATTEND_ACTIVITY.ATTEND_ACTIVITY_SUCCESS,
      payload: res.data,
    });
  };
export const attendActivityError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ATTEND_ACTIVITY.ATTEND_ACTIVITY_ERROR,
    });
  };
export const registerActivitySuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: REGISTER_ACTIVITY_LIST.REGISTER_ACTIVITY_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const registerActivityError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: REGISTER_ACTIVITY_LIST.REGISTER_ACTIVITY_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const rollCallActivity =
  (
    showToast = true,
    activity: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(rollCallActivityInit(showToast, activity));
  };

export const rollCallActivityInit =
  (
    showToast = true,
    activity: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ROLL_CALL_ACTIVITY_LIST.ROLL_CALL_ACTIVITY_LIST_INITIALIZATION,
    });
    apiCall(
      ROLL_CALL_ACTIVITY_URL,
      activity,
      (res: Object) => dispatch(rollCallActivitySuccess(res)),
      (err: any) => dispatch(rollCallActivityError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const rollCallActivitySuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ROLL_CALL_ACTIVITY_LIST.ROLL_CALL_ACTIVITY_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const rollCallActivityError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ROLL_CALL_ACTIVITY_LIST.ROLL_CALL_ACTIVITY_LIST_ERROR,
    });
  };

export const checkStudentActivityRegister =
  (
    showToast = true,
    activity: any,
    studentId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(checkStudentActivityRegisterInit(showToast, activity, studentId));
  };

export const checkStudentActivityRegisterInit =
  (
    showToast = true,
    activity: any,
    studentId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: CHECK_STUDENT_ACTIVITY.CHECK_STUDENT_ACTIVITY_INITIALIZATION,
    });
    apiCall(
      CHECK_STUDENT_ACTIVITY_URL + studentId + "/" + activity,
      {},
      (res: Object) => dispatch(checkStudentActivityRegisterSuccess(res)),
      (err: any) => dispatch(checkStudentActivityRegisterError()),
      METHOD.GET,
      { showToast },
    );
  };

export const checkStudentActivityRegisterSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: CHECK_STUDENT_ACTIVITY.CHECK_STUDENT_ACTIVITY_SUCCESS,
      payload: { registerd: res.registered, userData: res.userData },
    });
  };

export const checkStudentActivityRegisterError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: CHECK_STUDENT_ACTIVITY.CHECK_STUDENT_ACTIVITY_ERROR,
    });
  };
