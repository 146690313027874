import { useSelector } from "react-redux";
import { Store } from "../../../redux/Actions";
import { useParams } from "react-router";
import { useEffect } from "react";
import { dispatch } from "../../../redux";
import { getExpressWayMemberDetail } from "../../../redux/action";
import { Card, Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import ReactPhoneInput from "react-phone-input-2";

export default function ConsumerDetail() {
  const userDetails = useSelector(
    (state: Store) => state.expressWayReducer?.ewUserDetail,
  );

  const urlParams = useParams();

  useEffect(() => {
    const id = urlParams.id as string;
    dispatch(getExpressWayMemberDetail(false, id));
  }, [urlParams]);

  return (
    <div className="p-4">
      <Row className="mb-4 align-items-center">
        <Col>
          <h4 className="font-weight-500">Personal Information</h4>
        </Col>
      </Row>
      <Card className="m-0 shadow-none border">
        <Card.Body>
          <Row>
            <Col sm={6} md={6} lg={6}>
              <table
                style={{
                  width: "100%",
                  borderSpacing: "1rem",
                  borderCollapse: "separate",
                }}
              >
                <tr>
                  <th>
                    <label>
                      <FormattedMessage
                        id="table.head.Email"
                        defaultMessage="Email"
                      />
                    </label>
                  </th>
                  <td>
                    <input
                      type="text"
                      name="email"
                      value={userDetails?.email}
                      className="form-control form-control-md"
                      style={{ cursor: "text" }}
                      disabled
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <label>
                      <FormattedMessage
                        id="table.head.Phone"
                        defaultMessage="Phone"
                      />
                    </label>
                  </th>
                  <td>
                    <ReactPhoneInput
                      value={
                        (userDetails?.dialCode || "852") +
                        userDetails?.phoneNumber
                      }
                      disabled
                      containerStyle={{ marginBottom: 0 }}
                      inputStyle={{ cursor: "text" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <label>
                      <FormattedMessage
                        id="table.head.PreferredNickname"
                        defaultMessage="Preferred Nickname"
                      />
                    </label>
                  </th>
                  <td>
                    <input
                      type="text"
                      name="preferredNickName"
                      value={userDetails?.preferredNickName}
                      className="form-control form-control-md"
                      style={{ cursor: "text" }}
                      disabled
                    />
                  </td>
                </tr>
              </table>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}
