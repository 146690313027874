import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { editFAQCategory } from "../../redux/action";
import { Store } from "../../redux/Actions";
import Loader from "../common/Loader";
import { dispatch } from "../../redux";
import { Switch } from "@mui/material";
import { Modal } from "react-bootstrap";
import { EditFaqCategorySchema } from "../../util/validationSchema";
import { FormattedMessage } from "react-intl";
import { FaTimesCircle } from "react-icons/fa";

const EditFaqCategoryModal = ({ onModalClose, faq }: any) => {
  const [ButtonClick, setButtonClick] = useState<boolean>(false);
  const isAPILoading = useSelector(
    (state: Store) => state.giftReducer.onSaveLoading,
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: faq?.name,
      isActive: faq?.isActive,
    },
    validationSchema: EditFaqCategorySchema,
    onSubmit: (values: Record<string, any>) => {
      if (!ButtonClick) {
        if (Object.keys(faq).length > 0) {
          dispatch(editFAQCategory(values, faq.id, true));
        }
        setButtonClick(true);
      }
    },
  });

  useEffect(() => {
    if (!isAPILoading && ButtonClick) {
      onModalClose();
      setButtonClick(false);
    }
  }, [isAPILoading, ButtonClick]);

  const { touched, errors } = formik;
  return (
    <Modal centered show={true} onHide={() => onModalClose(false)}>
      <Modal.Header>
        <h4 className="modal-title" id="AddCourseLabel">
          <FormattedMessage
            id="FAQ.EditCategory"
            defaultMessage="Edit Faq Category"
          />
        </h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onModalClose}
        >
          <FaTimesCircle />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label className="control-label">
            <FormattedMessage id="Faq.Name" defaultMessage="Name" />
          </label>
          <input
            type="text"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="form-control"
          />
          {errors.name && touched.name && (
            <div className="text-error">
              <FormattedMessage
                id={errors.name.toString()}
                defaultMessage="This field is required"
              />
            </div>
          )}
        </div>

        <div className="form-group">
          <FormattedMessage id="Faq.Isactive" defaultMessage="Isactive" />
          <Switch
            checked={formik.values.isActive}
            onChange={() => {
              formik.setFieldValue("isActive", !formik.values.isActive);
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          onClick={onModalClose}
        >
          <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
        </button>
        <button
          type="button"
          className="btn btn-primary"
          style={{ display: "inherit" }}
          onClick={() => formik.handleSubmit()}
          data-dismiss="modal"
        >
          <FormattedMessage id="page.submit" defaultMessage="Submit" />
          {isAPILoading && <Loader />}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditFaqCategoryModal;
