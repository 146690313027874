/* eslint-disable no-case-declarations */
import {
  ADD_FAQ_LIST,
  DELETE_FAQ,
  EDIT_FAQ_LIST,
  GET_FAQ_CATEGORY_LIST,
  GET_FAQ_LIST,
  GET_ALL_FAQ_CATEGORY_LIST,
  ADD_FAQ_CATEGORY,
  EDIT_FAQ_CATEGORY,
  DELETE_FAQ_CATEGORY,
} from "../constants/action-types";
import { FAQData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";

const initialState = {
  progress: false,
  isLoading: false,
  onSaveLoading: false,
  FAQList: [],
  FAQDetails: {},
  FAQCategoryList: [],
  FAQCategoryDetails: {},
  totalRecord: 0,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: FAQData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case GET_FAQ_LIST.GET_FAQ_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_FAQ_LIST.GET_FAQ_LIST_SUCCESS:
        return {
          ...state,
          FAQList: action.payload,
          isLoading: false,
        };

      case GET_FAQ_LIST.GET_FAQ_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_FAQ_CATEGORY_LIST.GET_FAQ_CATEGORY_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_FAQ_CATEGORY_LIST.GET_FAQ_CATEGORY_LIST_SUCCESS:
        return {
          ...state,
          FAQCategoryList: action.payload,
          isLoading: false,
        };

      case GET_FAQ_CATEGORY_LIST.GET_FAQ_CATEGORY_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_FAQ_LIST.ADD_FAQ_LIST_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case ADD_FAQ_LIST.ADD_FAQ_LIST_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case ADD_FAQ_LIST.ADD_FAQ_LIST_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case EDIT_FAQ_LIST.EDIT_FAQ_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case EDIT_FAQ_LIST.EDIT_FAQ_LIST_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case EDIT_FAQ_LIST.EDIT_FAQ_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case DELETE_FAQ.DELETE_FAQ_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case DELETE_FAQ.DELETE_FAQ_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case DELETE_FAQ.DELETE_FAQ_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_ALL_FAQ_CATEGORY_LIST.GET_ALL_FAQ_CATEGORY_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_ALL_FAQ_CATEGORY_LIST.GET_ALL_FAQ_CATEGORY_LIST_SUCCESS:
        return {
          ...state,
          FAQCategoryList: action.payload,
          isLoading: false,
        };

      case GET_ALL_FAQ_CATEGORY_LIST.GET_ALL_FAQ_CATEGORY_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_FAQ_CATEGORY.ADD_FAQ_CATEGORY_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case ADD_FAQ_CATEGORY.ADD_FAQ_CATEGORY_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_FAQ_CATEGORY.ADD_FAQ_CATEGORY_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case EDIT_FAQ_CATEGORY.EDIT_FAQ_CATEGORY_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case EDIT_FAQ_CATEGORY.EDIT_FAQ_CATEGORY_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case EDIT_FAQ_CATEGORY.EDIT_FAQ_CATEGORY_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case DELETE_FAQ_CATEGORY.DELETE_FAQ_CATEGORY_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case DELETE_FAQ_CATEGORY.DELETE_FAQ_CATEGORY_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case DELETE_FAQ_CATEGORY.DELETE_FAQ_CATEGORY_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      default:
        return state;
    }
  },
);

export default reducer;
