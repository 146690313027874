/* eslint-disable no-case-declarations */
import {
  GET_LIBRARY_LIST,
  ADD_LIBRARY_LIST,
  EDIT_LIBRARY_LIST,
  DELETE_LIBRARY_LIST,
  GET_LIBRARY_VERSION_LIST,
  ADD_LIBRARY_VERSION_LIST,
  EDIT_LIBRARY_VERSION_LIST,
  DELETE_LIBRARY_VERSION_LIST,
  GET_LIBRARY_DETAIL,
  SET_PAGE,
} from "../constants/action-types";
import { LibraryData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";

const initialState = {
  progress: false,
  isLoading: false,
  onSaveLoading: false,
  libraryList: [],
  libraryVersion: [],
  totalRecord: 0,
  currentPage: 1,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: LibraryData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case SET_PAGE:
        return {
          ...state,
          currentPage: action.payload,
        };

      case GET_LIBRARY_LIST.GET_LIBRARY_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_LIBRARY_LIST.GET_LIBRARY_LIST_SUCCESS:
        const libs: any = action.payload;
        return {
          ...state,
          libraryList: libs.data,
          totalRecord: libs.totalCount,
          isLoading: false,
        };

      case GET_LIBRARY_LIST.GET_LIBRARY_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_LIBRARY_VERSION_LIST.GET_LIBRARY_VERSION_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_LIBRARY_VERSION_LIST.GET_LIBRARY_VERSION_LIST_SUCCESS:
        const libVersion: any = action.payload;
        return {
          ...state,
          libraryVersion: libVersion.data,
          totalRecord: libVersion.totalCount,
          isLoading: false,
        };

      case GET_LIBRARY_VERSION_LIST.GET_LIBRARY_VERSION_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_LIBRARY_LIST.ADD_LIBRARY_LIST_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case ADD_LIBRARY_LIST.ADD_LIBRARY_LIST_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case ADD_LIBRARY_LIST.ADD_LIBRARY_LIST_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case EDIT_LIBRARY_LIST.EDIT_LIBRARY_LIST_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case EDIT_LIBRARY_LIST.EDIT_LIBRARY_LIST_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case EDIT_LIBRARY_LIST.EDIT_LIBRARY_LIST_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case GET_LIBRARY_DETAIL.GET_LIBRARY_DETAIL_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_LIBRARY_DETAIL.GET_LIBRARY_DETAIL_SUCCESS:
        return {
          ...state,
          indicatorsDetails: action.payload,
          isLoading: false,
        };

      case GET_LIBRARY_DETAIL.GET_LIBRARY_DETAIL_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_LIBRARY_VERSION_LIST.ADD_LIBRARY_VERSION_LIST_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case ADD_LIBRARY_VERSION_LIST.ADD_LIBRARY_VERSION_LIST_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case ADD_LIBRARY_VERSION_LIST.ADD_LIBRARY_VERSION_LIST_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case EDIT_LIBRARY_VERSION_LIST.EDIT_LIBRARY_VERSION_LIST_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case EDIT_LIBRARY_VERSION_LIST.EDIT_LIBRARY_VERSION_LIST_SUCCESS:
        return {
          ...state,
          indicatorVersion: action.payload,
          onSaveLoading: false,
        };

      case EDIT_LIBRARY_VERSION_LIST.EDIT_LIBRARY_VERSION_LIST_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case DELETE_LIBRARY_VERSION_LIST.DELETE_LIBRARY_VERSION_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case DELETE_LIBRARY_VERSION_LIST.DELETE_LIBRARY_VERSION_LIST_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case DELETE_LIBRARY_VERSION_LIST.DELETE_LIBRARY_VERSION_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case DELETE_LIBRARY_LIST.DELETE_LIBRARY_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case DELETE_LIBRARY_LIST.DELETE_LIBRARY_LIST_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case DELETE_LIBRARY_LIST.DELETE_LIBRARY_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      default:
        return state;
    }
  },
);

export default reducer;
