import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

import { Store } from "../../../redux/Actions";

import { ACTIVITIES_DETAIL_TABS } from "../../../Constants";
import Points from "./Points";
import AttendencePoint from "./Attendence";
import { Link, useParams } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
// import { Row, Col } from "react-bootstrap";
import { dispatch } from "../../../redux";
import { getActivityDetails } from "../../../redux/action";
import { formatDate } from "../../../util/utils";
import AddActivityModal from "../../../Components/Activity/AddActivityModal";
// import { InputFiles } from "typescript";
import { FormattedMessage } from "react-intl";
import CenterLoader from "../../../Components/common/CenterLoader/CenterLoader";

const ActivityDetail = () => {
  const [activeTab, setActiveTab] = useState(0);
  const urlParams = useParams();
  const activityDetails: any = useSelector(
    (state: Store) => state.ActivityReducer.activityDetails,
  ) as any;
  const [activityModal, setActivityModal] = useState({
    modal: false,
    activity: {},
  });
  const getAdminRoleLoading = useSelector(
    (state: Store) => state.adminRolesReducer.isLoading,
  );
  const isAPILoading = useSelector(
    (state: Store) => state.ActivityReducer.isLoading,
  );
  // const [data, setData] = useState("No result");
  // const qrReaderRef = useRef<InputFiles>();

  // const constraints = {
  //   facingMode: { exact: "zCSHYj1sRPXQRa67" },
  //   width: 1920,
  //   height: 1080,
  //   aspectRatio: 1.777777778,
  // };
  useEffect(() => {
    const { id } = urlParams;
    dispatch(getActivityDetails(false, id));
  }, []);

  const setAciveTab = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };
  const classes = useStyles();

  // const handleScan = (data: any, error: any) => {
  //   if (data) {
  //     // setData(data);
  //   }
  // };

  // const handleError = (err: any) => {
  //   console.error(err);
  // };

  return (
    <div className="content-wrapper">
      <div className="content">
        {getAdminRoleLoading || (isAPILoading && <CenterLoader />)}
        <header className="page-header">
          <div className="d-flex flex-wrap align-items-center">
            <div className="mr-auto">
              <h1>
                <FormattedMessage
                  id="head.AdminActivityDetails"
                  defaultMessage="Admin Activity Details"
                />
              </h1>
            </div>
            <div className="m-l-10 common-right-button">
              <Link className="btn btn-secondary" to="/activities">
                {" "}
                <span style={{ verticalAlign: "middle" }}>
                  {" "}
                  <i className="back-button">
                    <BsChevronLeft /> &nbsp;
                  </i>
                  <FormattedMessage id="page.back" defaultMessage="Back" />
                </span>
              </Link>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          <Card>
            <Card.Body className="p-0">
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={activeTab}
                  onChange={setAciveTab}
                  className={classes.tabs}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label={
                      <FormattedMessage
                        id="classDetail.Detail"
                        defaultMessage="Detail"
                      />
                    }
                    {...a11yProps(ACTIVITIES_DETAIL_TABS.ACTIVITY_DETAIL)}
                  />
                  <Tab
                    label={
                      <FormattedMessage id="tab.Point" defaultMessage="Point" />
                    }
                    {...a11yProps(ACTIVITIES_DETAIL_TABS.POINT_LIST)}
                  />
                  <Tab
                    label={
                      <FormattedMessage
                        id="head.Attendencelog"
                        defaultMessage="Attendence log"
                      />
                    }
                    {...a11yProps(
                      ACTIVITIES_DETAIL_TABS.ACTIVITES_ATTENDENCE_LOG_LIST,
                    )}
                  />
                </Tabs>
              </Box>

              <TabPanel
                value={activeTab}
                index={ACTIVITIES_DETAIL_TABS.ACTIVITY_DETAIL}
              >
                <div className="d-flex justify-content-end p-4">
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#AddCourse"
                    onClick={() =>
                      setActivityModal({
                        modal: true,
                        activity: activityDetails,
                      })
                    }
                    type="button"
                    className="btn btn-primary"
                  >
                    <FormattedMessage
                      id="head.EditActivityDetails"
                      defaultMessage="Edit Activity Details"
                    />
                  </a>
                </div>
                <div>
                  {Object.keys(activityDetails).length ? (
                    <div className="table-responsive">
                      <table
                        style={{ border: "1px solid #f0f6ff", width: "100%" }}
                        className="table table-hover table-striped m-0"
                      >
                        <tbody>
                          <tr>
                            <td style={{ width: "33%" }}>
                              <label className="ml-3">
                                <FormattedMessage
                                  id="head.ActivityID"
                                  defaultMessage="Activity ID"
                                />
                                :{" "}
                              </label>{" "}
                            </td>
                            <td style={{ textAlign: "left" }}>
                              {activityDetails.id}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label className="ml-3">
                                {" "}
                                <FormattedMessage
                                  id="Admin.Activitie.Name"
                                  defaultMessage="Name"
                                />
                                :{" "}
                              </label>{" "}
                            </td>
                            <td style={{ textAlign: "left" }}>
                              {activityDetails.name}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label className="ml-3">
                                <FormattedMessage
                                  id="classmodal.name"
                                  defaultMessage="class Name"
                                />
                              </label>{" "}
                            </td>
                            <td style={{ textAlign: "left" }}>
                              {activityDetails?.class?.name || "N/A"}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label className="ml-3">
                                {" "}
                                <FormattedMessage
                                  id="activitymodal.Start"
                                  defaultMessage="Start Date"
                                />
                                :{" "}
                              </label>{" "}
                            </td>
                            <td style={{ textAlign: "left" }}>
                              {formatDate(
                                activityDetails.startDate,
                                "DD/MM/YYYY",
                              )}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label className="ml-3">
                                <FormattedMessage
                                  id="activitymodal.End"
                                  defaultMessage="End Date"
                                />
                                :{" "}
                              </label>{" "}
                            </td>
                            <td style={{ textAlign: "left" }}>
                              {formatDate(
                                activityDetails.endDate,
                                "DD/MM/YYYY",
                              )}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label className="ml-3">
                                <FormattedMessage
                                  id="activitymodal.CloseApplication"
                                  defaultMessage="Close Application Date"
                                />
                                :{" "}
                              </label>{" "}
                            </td>
                            <td style={{ textAlign: "left" }}>
                              {formatDate(
                                activityDetails.closeApplicationDate,
                                "DD/MM/YYYY",
                              )}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label className="ml-3">
                                <FormattedMessage
                                  id="activitymodal.limit"
                                  defaultMessage="Limit"
                                />
                                :{" "}
                              </label>{" "}
                            </td>
                            <td style={{ textAlign: "left" }}>
                              {activityDetails?.registerCount +
                                "/" +
                                activityDetails.user_limit}{" "}
                            </td>
                          </tr>
                          {/* <tr>
                            <td>
                              <label className="ml-3">
                                <FormattedMessage
                                  id="activitymodal.UserRegister"
                                  defaultMessage="User need to register"
                                />
                                :{" "}
                              </label>{" "}
                            </td>
                            <td style={{ textAlign: "left" }}>
                              {activityDetails.needToRegister ? "Yes" : "No"}{" "}
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </div>
              </TabPanel>
              <TabPanel
                value={activeTab}
                index={ACTIVITIES_DETAIL_TABS.POINT_LIST}
              >
                <Points />
              </TabPanel>
              <TabPanel
                value={activeTab}
                index={ACTIVITIES_DETAIL_TABS.ACTIVITES_ATTENDENCE_LOG_LIST}
              >
                <AttendencePoint />
              </TabPanel>
            </Card.Body>
          </Card>

          {activityModal.modal && (
            <AddActivityModal
              onModalClose={() =>
                setActivityModal({ modal: false, activity: {} })
              }
              activity={activityModal.activity}
            />
          )}
        </section>
      </div>
    </div>
  );
};

export default ActivityDetail;

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#68439a",
      height: 1,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#68439a",
      BsBorderBottom: "1px solid",
    },
  },
});

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
