import React from "react";

const Loader = () => {
  return (
    <span
      className="ml-2"
      style={{
        flexDirection: "row",
        display: "inline-flex",
        paddingTop: "0.4rem",
      }}
    >
      <div className="loading-1">
        <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em">
          <circle cx="0.6em" cy="0.6em" r="0.5em" className="circle" />
        </svg>
      </div>
    </span>
  );
};

export default Loader;
