/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/APIServices/baseApiCall";
import {
  GET_RENTS_LIST,
  GET_RENTS_CATEGORY_LIST,
  ADD_RENTS_LIST,
  EDIT_RENTS_LIST,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { getAPIEndPoint, setToken } from "../../util/utils";
import {
  GET_RENTS_LIST_URL,
  GET_RENTS_CATEGORY_LIST_URL,
  ADD_RENTS_LIST_URL,
} from "../../service/APIServices/apiEndPoints";
import { toast } from "react-toastify";
// import { _getExpressWayPendingList } from "../../service";
// import { AxiosResponse } from "axios";
//Get Admin Roles List
export const getRentsList =
  (
    showToast = true,
    pageNumber = 0,
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getRentsListInit(showToast, pageNumber, pageLimit));
  };

export const getRentsListInit =
  (
    showToast = true,
    pageNumber: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_RENTS_LIST.GET_RENTS_LIST_INITIALIZATION,
    });
    apiCall(
      getAPIEndPoint(GET_RENTS_LIST_URL, pageNumber, false, pageLimit) +
        "&sort=DESC&sortBy=createdAt",
      {},
      (res: Object) => dispatch(getRentsListSuccess(res)),
      (err: any) => dispatch(getRentsListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getRentsListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_RENTS_LIST.GET_RENTS_LIST_SUCCESS,
      payload: res,
    });
  };

export const getRentsListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_RENTS_LIST.GET_RENTS_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const getRentsCategoryList =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getRentsCategoryListInit(showToast));
  };

export const getRentsCategoryListInit =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_RENTS_CATEGORY_LIST.GET_RENTS_CATEGORY_LIST_INITIALIZATION,
    });
    apiCall(
      GET_RENTS_CATEGORY_LIST_URL,
      {},
      (res: Object) => dispatch(getRentsCategoryListSuccess(res)),
      (err: any) => dispatch(getRentsCategoryListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getRentsCategoryListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_RENTS_CATEGORY_LIST.GET_RENTS_CATEGORY_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getRentsCategoryListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_RENTS_CATEGORY_LIST.GET_RENTS_CATEGORY_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const addRentsList =
  (
    showToast = true,
    rent: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(addRentsListInit(showToast, rent));
  };

export const addRentsListInit =
  (
    showToast = true,
    rent: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_RENTS_LIST.ADD_RENTS_LIST_INITIALIZATION,
    });
    apiCall(
      ADD_RENTS_LIST_URL,
      rent,
      (res: Object) => dispatch(addRentsListSuccess(res)),
      (err: any) => dispatch(addRentsListError()),
      METHOD.POST,
      { showToast },
    );
  };

export const addRentsListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_RENTS_LIST.ADD_RENTS_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const addRentsListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_RENTS_LIST.ADD_RENTS_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const editRentsList =
  (
    showToast = true,
    rent: any,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(editRentsListInit(showToast, rent, id));
  };

export const editRentsListInit =
  (
    showToast = true,
    rent: any,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_RENTS_LIST.EDIT_RENTS_LIST_INITIALIZATION,
    });
    apiCall(
      ADD_RENTS_LIST_URL + "/" + id,
      rent,
      (res: Object) => dispatch(editRentsListSuccess(res)),
      (err: any) => dispatch(editRentsListError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const editRentsListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_RENTS_LIST.EDIT_RENTS_LIST_SUCCESS,
      payload: res.data,
    });
    dispatch(getRentsList(false));
  };

export const editRentsListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: EDIT_RENTS_LIST.EDIT_RENTS_LIST_ERROR,
    });
  };
