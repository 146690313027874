/* eslint-disable no-case-declarations */
import {
  GET_TICKET_LIST,
  GET_TICKET_DETAILS,
  ADD_TICKET_LIST,
  EDIT_TICKET_LIST,
  GET_TICKET_ISSUE_TYPE_LIST,
  GET_TICKET_COMMENT_LIST,
  ADD_TICKET_COMMENT_LIST,
  GET_TICKET_TOOL_VERSION_LIST,
  GET_TICKET_TOOL_LIST,
  DELETE_TICKET_LIST,
  GET_TICKET_ASSIGNEE_LIST,
  GET_TICKET_GROUP_LIST,
  CREATE_TICKET_GROUP_LIST,
  ADD_GROUP_MEMBER_LIST,
  DELETE_GROUP_MEMBER_LIST,
  GET_ADMIN_MEMBER_LIST,
  DELETE_TICKET_COMMENT_LIST,
  EDIT_TICKET_COMMENT_LIST,
  DELETE_TICKET_GROUP,
} from "../constants/action-types";
import { TicketData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";

const initialState = {
  progress: false,
  isLoading: false,
  onSaveLoading: false,
  onCSaveLoading: false,
  ticketList: [],
  commentList: [],
  issueList: [],
  ticketDetails: {},
  toolList: [],
  toolVersionList: [],
  isDeleted: false,
  totalRecord: 0,
  ticketAsignee: [],
  adminMember: [],
  groupList: [],
  totalCommentRecords: 0,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: TicketData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case GET_TICKET_LIST.GET_TICKET_LIST_INITIALIZATION:
        return {
          ...state,
          ticketDetails: {},
          ticketAsignee: [],
          // totalRecord: 0,
          isLoading: true,
        };

      case GET_TICKET_LIST.GET_TICKET_LIST_SUCCESS:
        const allPost: any = action.payload;
        return {
          ...state,
          ticketList: allPost.data,
          totalRecord: allPost.totalCount,
          isLoading: false,
        };

      case GET_TICKET_LIST.GET_TICKET_LIST_ERROR:
        return {
          ...state,
          totalRecord: 0,
          isLoading: false,
        };

      case ADD_TICKET_LIST.ADD_TICKET_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case ADD_TICKET_LIST.ADD_TICKET_LIST_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_TICKET_LIST.ADD_TICKET_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case EDIT_TICKET_LIST.EDIT_TICKET_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case EDIT_TICKET_LIST.EDIT_TICKET_LIST_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case EDIT_TICKET_LIST.EDIT_TICKET_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_TICKET_DETAILS.GET_TICKET_DETAILS_INITIALIZATION:
        return {
          ...state,
          // ticketDetails: [],
          isDeleted: false,
          isLoading: true,
        };

      case GET_TICKET_DETAILS.GET_TICKET_DETAILS_SUCCESS:
        return {
          ...state,
          ticketDetails: action.payload,
          isDeleted: false,
          isLoading: false,
        };

      case GET_TICKET_DETAILS.GET_TICKET_DETAILS_ERROR:
        return {
          ...state,
          isDeleted: false,
          isLoading: false,
        };

      case GET_TICKET_ISSUE_TYPE_LIST.GET_TICKET_ISSUE_TYPE_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_TICKET_ISSUE_TYPE_LIST.GET_TICKET_ISSUE_TYPE_LIST_SUCCESS:
        const issuesList = action.payload; // [{ id: 2, body: "Abcd", title: "title", userId: 3 }, { id: 2, body: "Abcd", title: "title", userId: 3 }];
        return {
          ...state,
          issueList: issuesList,
          isLoading: false,
        };

      case GET_TICKET_ISSUE_TYPE_LIST.GET_TICKET_ISSUE_TYPE_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_TICKET_COMMENT_LIST.GET_TICKET_COMMENT_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_TICKET_COMMENT_LIST.GET_TICKET_COMMENT_LIST_SUCCESS:
        const commentList: any = action.payload; // [{ id: 2, body: "Abcd", title: "title", userId: 3 }, { id: 2, body: "Abcd", title: "title", userId: 3 }];
        return {
          ...state,
          totalCommentRecords: commentList.totalCount,
          commentList: commentList.data,
          isLoading: false,
        };

      case GET_TICKET_COMMENT_LIST.GET_TICKET_COMMENT_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_TICKET_COMMENT_LIST.ADD_TICKET_COMMENT_LIST_INITIALIZATION:
        return {
          ...state,
          onCSaveLoading: true,
        };

      case ADD_TICKET_COMMENT_LIST.ADD_TICKET_COMMENT_LIST_SUCCESS:
        return {
          ...state,
          onCSaveLoading: false,
        };

      case ADD_TICKET_COMMENT_LIST.ADD_TICKET_COMMENT_LIST_ERROR:
        return {
          ...state,
          onCSaveLoading: false,
        };

      case GET_TICKET_TOOL_LIST.GET_TICKET_TOOL_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_TICKET_TOOL_LIST.GET_TICKET_TOOL_LIST_SUCCESS:
        // const tools:any = action.payload;
        // const tool:any = tools.filter((i)=>i.type === 4);
        return {
          ...state,
          toolList: action.payload,
          isLoading: false,
        };

      case GET_TICKET_TOOL_LIST.GET_TICKET_TOOL_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_TICKET_TOOL_VERSION_LIST.GET_TICKET_TOOL_VERSION_LIST_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case GET_TICKET_TOOL_VERSION_LIST.GET_TICKET_TOOL_VERSION_LIST_SUCCESS:
        return {
          ...state,
          toolVersionList: action.payload,
          onSaveLoading: false,
        };

      case GET_TICKET_TOOL_VERSION_LIST.GET_TICKET_TOOL_VERSION_LIST_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case DELETE_TICKET_LIST.DELETE_TICKET_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
          isDeleted: false,
        };

      case DELETE_TICKET_LIST.DELETE_TICKET_LIST_SUCCESS:
        return {
          ...state,
          isDeleted: true,
          isLoading: false,
        };

      case DELETE_TICKET_LIST.DELETE_TICKET_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
          isDeleted: false,
        };

      case GET_TICKET_ASSIGNEE_LIST.GET_TICKET_ASSIGNEE_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_TICKET_ASSIGNEE_LIST.GET_TICKET_ASSIGNEE_LIST_SUCCESS:
        return {
          ...state,
          ticketAsignee: action.payload,
          isLoading: false,
        };

      case GET_TICKET_ASSIGNEE_LIST.GET_TICKET_ASSIGNEE_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_TICKET_GROUP_LIST.GET_TICKET_GROUP_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_TICKET_GROUP_LIST.GET_TICKET_GROUP_LIST_SUCCESS:
        return {
          ...state,
          groupList: action.payload,
          isLoading: false,
        };

      case GET_TICKET_GROUP_LIST.GET_TICKET_GROUP_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };
      case CREATE_TICKET_GROUP_LIST.CREATE_TICKET_GROUP_LIST_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case CREATE_TICKET_GROUP_LIST.CREATE_TICKET_GROUP_LIST_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case CREATE_TICKET_GROUP_LIST.CREATE_TICKET_GROUP_LIST_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case DELETE_TICKET_GROUP.DELETE_TICKET_GROUP_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case DELETE_TICKET_GROUP.DELETE_TICKET_GROUP_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case DELETE_TICKET_GROUP.DELETE_TICKET_GROUP_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_GROUP_MEMBER_LIST.ADD_GROUP_MEMBER_LIST_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case ADD_GROUP_MEMBER_LIST.ADD_GROUP_MEMBER_LIST_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case ADD_GROUP_MEMBER_LIST.ADD_GROUP_MEMBER_LIST_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case GET_ADMIN_MEMBER_LIST.GET_ADMIN_MEMBER_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_ADMIN_MEMBER_LIST.GET_ADMIN_MEMBER_LIST_SUCCESS:
        return {
          ...state,
          adminMember: action.payload,
          isLoading: false,
        };

      case GET_ADMIN_MEMBER_LIST.GET_ADMIN_MEMBER_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case DELETE_GROUP_MEMBER_LIST.DELETE_GROUP_MEMBER_LIST_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case DELETE_GROUP_MEMBER_LIST.DELETE_GROUP_MEMBER_LIST_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case DELETE_GROUP_MEMBER_LIST.DELETE_GROUP_MEMBER_LIST_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };
      case EDIT_TICKET_COMMENT_LIST.EDIT_TICKET_COMMENT_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case EDIT_TICKET_COMMENT_LIST.EDIT_TICKET_COMMENT_LIST_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case EDIT_TICKET_COMMENT_LIST.EDIT_TICKET_COMMENT_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case DELETE_TICKET_COMMENT_LIST.DELETE_TICKET_COMMENT_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case DELETE_TICKET_COMMENT_LIST.DELETE_TICKET_COMMENT_LIST_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case DELETE_TICKET_COMMENT_LIST.DELETE_TICKET_COMMENT_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };
      default:
        return state;
    }
  },
);

export default reducer;
