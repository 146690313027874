import { FormattedMessage } from "react-intl";

export const PlanCategoriesHeader = () => {
  return (
    <header className="page-header">
      <div className="d-flex align-items-center">
        <div className="mr-auto">
          <h1>
            <FormattedMessage
              id="Admin.PlanCategories"
              defaultMessage="Admin Plan Categories"
            />
          </h1>
        </div>
      </div>
    </header>
  );
};

export default PlanCategoriesHeader;
