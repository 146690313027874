import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { FormattedMessage } from "react-intl";

const MakeAdminModal = ({ onClose, open, onApproveClick, text }: any) => {
  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        {" "}
        {text || (
          <FormattedMessage
            id="msg.makeAdmin"
            defaultMessage="Are you sure you want to make this user an admin?"
          />
        )}{" "}
      </DialogTitle>
      <List
        sx={{ pt: 0 }}
        style={{ textAlign: "end", padding: "12px 12px 12px 12px" }}
      >
        <Button
          onClick={handleClose}
          variant="outlined"
          startIcon={<CloseIcon />}
          className="mr-2"
          color="error"
        >
          <FormattedMessage id="page.No" defaultMessage="No" />
        </Button>
        <Button
          onClick={onApproveClick}
          variant="outlined"
          startIcon={<DoneIcon />}
          color="success"
        >
          <FormattedMessage id="page.Yes" defaultMessage="Yes" />
        </Button>
      </List>
    </Dialog>
  );
};

MakeAdminModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onApproveClick: PropTypes.func.isRequired,
  text: PropTypes.any,
};

export default MakeAdminModal;
