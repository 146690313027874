import { Button, Card } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { dispatch } from "../../redux";
import { getFAQCategoryList, deleteFAQCategory } from "../../redux/action";
import { Store } from "../../redux/Actions";
import AddFaqCategoryModal from "../../Components/Faq/AddFaqCategoryModal";
import EditFaqCategoryModal from "../../Components/Faq/EditFaqCategoryModal";
import ViewFaqCategoryModal from "../../Components/Faq/ViewFaqCategoryModal";
import NoDatFound from "../../Components/common/NoDatFound";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import { FormattedMessage } from "react-intl";
import { Dropdown } from "react-bootstrap";
import { AppendedMyComponent } from "../../Components/append-to-body-drop-down";
import { FaEdit, FaTrash } from "../../reactIcons";
import DeletePopup from "../../Components/common/Popups/DeletePopup";
import { BsFillInfoCircleFill } from "../../reactIcons";

const FaqsCategory = () => {
  const FAQList = useSelector(
    (state: Store) => state.faqReducer.FAQCategoryList,
  );
  const [modal, setmodal] = useState<Record<string, any>>({
    modal: 4,
    faq: {},
  });

  const isLoading = useSelector((state: Store) => state.faqReducer.isLoading);

  useEffect(() => {
    dispatch(getFAQCategoryList());
  }, []);

  const deleteCategory = () => {
    dispatch(deleteFAQCategory(modal?.faq?.id, true));
    setmodal({ modal: 4, faq: {} });
  };

  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                <FormattedMessage
                  id="head.AdminFaqCategory"
                  defaultMessage="Admin FAQ Category"
                />
              </h1>
            </div>
            <div className="m-l-10 text-end common-right-button">
              <Button
                type="button"
                onClick={() => setmodal({ modal: 0, faq: {} })}
              >
                <FormattedMessage
                  id="head.AddFaqCategory"
                  defaultMessage="Add FAQ Category"
                />
              </Button>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          {isLoading && <CenterLoader />}

          <Card style={{ width: "100%" }} className="mt-3">
            <Card.Body className="p-0">
              <div>
                <div className="table-responsive">
                  {FAQList && FAQList.length > 0 ? (
                    <>
                      {" "}
                      <table className="table table-hover m-0">
                        <thead>
                          <tr>
                            <th>
                              <FormattedMessage
                                id="table.head.ID"
                                defaultMessage="Id"
                              />
                            </th>
                            <th>
                              <FormattedMessage
                                id="table.head.Name"
                                defaultMessage="Name"
                              />
                            </th>
                            <th>
                              <FormattedMessage
                                id="table.head.Isactive"
                                defaultMessage="Isactive"
                              />
                            </th>
                            <th className="text-center">
                              <FormattedMessage
                                id="table.head.Actions"
                                defaultMessage="Action"
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {FAQList &&
                            FAQList.length > 0 &&
                            FAQList.map((i: any) => (
                              <tr>
                                <td>{i.id}</td>
                                <td>{i.name}</td>
                                <td>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={i.isActive}
                                      id="flexCheckChecked"
                                    />
                                  </div>
                                </td>
                                <td className="table-field-status">
                                  <Dropdown className="btn-group">
                                    <Dropdown.Toggle
                                      id="dropdown-basic"
                                      className="btn btn-sm btn-icon-only"
                                    >
                                      <i className="icon dripicons-dots-3 zmdi-hc-fw"></i>
                                    </Dropdown.Toggle>
                                    <AppendedMyComponent>
                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={() =>
                                            setmodal({ modal: 3, faq: i })
                                          }
                                        >
                                          <i className=" text-accent-custom">
                                            <BsFillInfoCircleFill />{" "}
                                          </i>
                                          <FormattedMessage
                                            id="classDetail.Details"
                                            defaultMessage="Details"
                                          />
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={() =>
                                            setmodal({ modal: 1, faq: i })
                                          }
                                        >
                                          <i className="text-accent-custom">
                                            <FaEdit />{" "}
                                          </i>
                                          <FormattedMessage
                                            id="page.Edit"
                                            defaultMessage="Edit"
                                          />
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={() =>
                                            setmodal({ modal: 2, faq: i })
                                          }
                                        >
                                          <i className="fa-fw text-accent-custom">
                                            <FaTrash />{" "}
                                          </i>
                                          <FormattedMessage
                                            id="page.Delete"
                                            defaultMessage="Delete"
                                          />
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </AppendedMyComponent>
                                  </Dropdown>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <p>{!isLoading && <NoDatFound />}</p>
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>

          {modal.modal === 0 && (
            <AddFaqCategoryModal
              onModalClose={() => setmodal({ modal: 4, faq: {} })}
              faq={modal.faq}
            />
          )}

          {modal.modal === 1 && (
            <EditFaqCategoryModal
              onModalClose={() => setmodal({ modal: 4, faq: {} })}
              faq={modal.faq}
            />
          )}

          {modal.modal === 2 && (
            <DeletePopup
              onClose={() => setmodal({ modal: 4, faq: {} })}
              onDeleteClick={deleteCategory}
              open={modal.modal === 2}
            />
          )}

          {modal.modal === 3 && (
            <ViewFaqCategoryModal
              onModalClose={() => setmodal({ modal: 4, faq: {} })}
              faq={modal.faq}
            />
          )}
        </section>
      </div>
    </div>
  );
};

export default FaqsCategory;
