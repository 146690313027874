import { useState, useEffect, memo, useCallback } from "react";
import { BsSortDown, BsSortUp } from "react-icons/bs";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getTicketList } from "../../redux/action";
import { Store } from "../../redux/Actions";
import { useNavigate } from "react-router";
import { Chip, Tooltip, Typography } from "@mui/material";
import { BugIcon, AdviseIcon, ProblemIcon } from "../../util/iconStyle";
import {
  customFormatDatetimeWithLocale,
  getReporterTextClassName,
  getTicketGroupNameAbbreviation,
} from "../../util/utils";
import { FormattedMessage } from "react-intl";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import Pagination from "../../Components/common/Paginate/Pagination";
import NoDatFound from "../../Components/common/NoDatFound";
import { Link, useLocation } from "react-router-dom";
import { Settings } from "@mui/icons-material";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

const Tickets = ({ pageProps }: any) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const data: any = location?.state;
  const ticketList = useSelector(
    (state: Store) => state.ticketReducer.ticketList,
  ) as any;
  const navigate = useNavigate();
  const isLoading = useSelector(
    (state: Store) => state.ticketReducer.isLoading,
  );

  const [pageNumber, setPageNumber] = useState<any>();
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const totalRecord = useSelector(
    (state: Store) => state.ticketReducer.totalRecord,
  ) as any;

  const locale = useSelector((state: Store) => state.userDataReducer.local);

  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [filterQ, setFilterQ] = useState("");
  const [filterText, setFilterText] = useState("");
  const [Alltext, setAllText] = useState("");

  useEffect(() => {
    const tid = setTimeout(() => {
      setAllText(filterText);
    }, 1000);
    return () => {
      clearTimeout(tid);
    };
  }, [filterText]);

  useEffect(() => {
    setFilter(filterText);
  }, [Alltext]);

  const setFilter = (text: string) => {
    let q = "";
    if (text.length > 0) q = q + `&q=${text?.trim()}`;

    setFilterQ(q);
    setPageNumber(1);
    return 0;
  };

  const setSorting = (prop: string) => {
    if (sortBy === prop) {
      setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
    } else {
      setSortBy(prop);
      setSortOrder("ASC");
    }
  };

  useEffect(() => {
    const data: any = location?.state;
    if (pageNumber && pageLimit) {
      dispatch(
        getTicketList(
          false,
          pageNumber,
          { by: sortBy, order: sortOrder },
          filterQ,
          pageLimit,
        ),
      );
    }
  }, [filterQ, sortOrder, sortBy, pageNumber, pageLimit]);

  useEffect(() => {
    const storedPageNumber = localStorage.getItem("pageNumber");
    const storedPageLimit = localStorage.getItem("pageLimit");
    if (storedPageNumber && storedPageLimit) {
      setPageNumber(storedPageNumber);
      setPageLimit(storedPageLimit);
    } else {
      setPageNumber(1);
      setPageLimit(10);
    }
  }, []);

  const ticketDetail = (id: any) => {
    localStorage.setItem("pageNumber", pageNumber);
    localStorage.setItem("pageLimit", pageLimit);
    navigate("/tickets/" + id, { state: { pageProps: pageNumber } });
  };

  const onPageChange = useCallback(
    (pageNum: any) => {
      localStorage.setItem("pageNumber", pageNum.toString());
      localStorage.setItem("pageLimit", pageLimit.toString());
      setPageNumber(pageNum);
    },
    [pageNumber],
  );

  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-right">
            <div className="mr-auto">
              <h1>
                {" "}
                <FormattedMessage id="Tickets" defaultMessage="Tickets " />
              </h1>
            </div>
            <div className="m-l-10 common-right-button">
              <Link className="btn btn-secondary" to="/tickets/group/settings">
                {" "}
                <Settings />
                <span style={{ verticalAlign: "top" }}>
                  {" "}
                  <label style={{ marginTop: "1px" }}>
                    <FormattedMessage
                      id="Tickets.groupSettings"
                      defaultMessage="Group Settings"
                    />{" "}
                  </label>
                </span>
              </Link>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          <Card>
            <Card.Body className="p-0">
              <Row className="p-20">
                <Col style={{ display: "inline-flex" }}>
                  <label style={{ position: "absolute", bottom: "0" }}>
                    <FormattedMessage id="Filter" defaultMessage="Filter" />
                    {": "}
                  </label>
                  <input
                    onChange={(e) => {
                      setFilterText(e.target.value);
                    }}
                    type="text"
                    style={{
                      maxWidth: "220px",
                      paddingLeft: "39px",
                      borderRadius: "0px",
                      border: "none",
                      borderBottom: "1px solid",
                    }}
                    className="form-control"
                  />
                </Col>
              </Row>
              <div className="table-responsive">
                {isLoading && <CenterLoader />}
                {ticketList && ticketList.length ? (
                  <>
                    {" "}
                    <table className="table table-hover m-0">
                      <thead>
                        <tr>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("id")}
                          >
                            {" "}
                            #
                            {sortBy === "id" ? (
                              sortOrder === "ASC" ? (
                                <BsSortUp />
                              ) : (
                                <BsSortDown />
                              )
                            ) : null}
                          </th>
                          <th
                            key="head_summry"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("summary")}
                          >
                            <FormattedMessage
                              id="Tickets.Summary"
                              defaultMessage="Summary "
                            />
                            {sortBy === "summary" ? (
                              sortOrder === "ASC" ? (
                                <BsSortUp />
                              ) : (
                                <BsSortDown />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("reporter")}
                          >
                            <FormattedMessage
                              id="Tickets.Reporter"
                              defaultMessage="Reporter"
                            />
                            {sortBy === "reporter" ? (
                              sortOrder === "ASC" ? (
                                <BsSortUp />
                              ) : (
                                <BsSortDown />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("class")}
                          >
                            <FormattedMessage
                              id="Tickets.Class"
                              defaultMessage="Class"
                            />
                            {sortBy === "class" ? (
                              sortOrder === "ASC" ? (
                                <BsSortUp />
                              ) : (
                                <BsSortDown />
                              )
                            ) : null}
                          </th>
                          <th
                            key="head_summry"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("type")}
                          >
                            <FormattedMessage
                              id="Tickets.Type"
                              defaultMessage="Type"
                            />
                            {sortBy === "type" ? (
                              sortOrder === "ASC" ? (
                                <BsSortUp />
                              ) : (
                                <BsSortDown />
                              )
                            ) : null}
                          </th>
                          <th
                            key="head_summry"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("assignee")}
                          >
                            <FormattedMessage
                              id="Tickets.Assignee"
                              defaultMessage="Assignee"
                            />
                            {sortBy === "assignee" ? (
                              sortOrder === "ASC" ? (
                                <BsSortUp />
                              ) : (
                                <BsSortDown />
                              )
                            ) : null}
                          </th>
                          <th
                            key="head_summry"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("software")}
                          >
                            <FormattedMessage
                              id="Tickets.Software"
                              defaultMessage="Software"
                            />
                            {sortBy === "software" ? (
                              sortOrder === "ASC" ? (
                                <BsSortUp />
                              ) : (
                                <BsSortDown />
                              )
                            ) : null}
                          </th>
                          <th
                            key="head_summry"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("version")}
                          >
                            <FormattedMessage
                              id="Tickets.Version"
                              defaultMessage="Version "
                            />
                            {sortBy === "version" ? (
                              sortOrder === "ASC" ? (
                                <BsSortUp />
                              ) : (
                                <BsSortDown />
                              )
                            ) : null}
                          </th>
                          <th
                            key="head_summry"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("date")}
                          >
                            <FormattedMessage
                              id="Tickets.Date"
                              defaultMessage="Date"
                            />
                            {sortBy === "date" ? (
                              sortOrder === "ASC" ? (
                                <BsSortUp />
                              ) : (
                                <BsSortDown />
                              )
                            ) : null}
                          </th>
                          <th
                            key="head_summry"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("status")}
                          >
                            <FormattedMessage
                              id="Tickets.Status"
                              defaultMessage="Status"
                            />
                            {sortBy === "status" ? (
                              sortOrder === "ASC" ? (
                                <BsSortUp />
                              ) : (
                                <BsSortDown />
                              )
                            ) : null}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {ticketList &&
                          ticketList.map((i: any) => {
                            const reporterText =
                              i?.reporter?.preferredNickName ||
                              i?.reporter?.englishFirstName;
                            const reporterTextClassName =
                              getReporterTextClassName(i?.reporter?.isVip);

                            return (
                              <tr
                                onClick={() => ticketDetail(i.id)}
                                style={{ cursor: "pointer" }}
                              >
                                <td>{i?.id}</td>
                                <td>{i?.title}</td>
                                <td className={reporterTextClassName}>
                                  {reporterText}
                                </td>
                                <td>
                                  {i?.reporter?.userClass[0]?.class?.name}
                                </td>
                                <td>
                                  {i?.type?.id === 1 && (
                                    <i
                                      className="MuiListItemIcon-root-tbl"
                                      style={{
                                        fill: "rgb(223 184 20)",
                                        height: "16px",
                                      }}
                                    >
                                      {" "}
                                      <AdviseIcon />
                                    </i>
                                  )}
                                  {i?.type?.id === 2 && (
                                    <i
                                      className="MuiListItemIcon-root-tbl"
                                      style={{
                                        fill: "rgb(255 72 72)",
                                        height: "16px",
                                      }}
                                    >
                                      {" "}
                                      <BugIcon />
                                    </i>
                                  )}
                                  {i?.type?.id === 3 && (
                                    <i
                                      className="MuiListItemIcon-root-tbl"
                                      style={{
                                        fill: "#9c27b0",
                                        height: "16px",
                                      }}
                                    >
                                      {" "}
                                      <ProblemIcon />
                                    </i>
                                  )}
                                  {i?.type?.name || "Bug"}
                                </td>
                                <td>
                                  <Tooltip
                                    arrow
                                    placement="top-start"
                                    title={
                                      <Typography color="inherit">
                                        {i?.ticketGroup?.group_name}
                                      </Typography>
                                    }
                                  >
                                    <div className="">
                                      {getTicketGroupNameAbbreviation(
                                        i?.ticketGroup?.group_name,
                                      )}
                                    </div>
                                  </Tooltip>
                                </td>
                                <td>{i?.software?.name}</td>
                                <td>{i?.version?.version}</td>
                                <td>
                                  {customFormatDatetimeWithLocale(
                                    i.updatedAt,
                                    locale,
                                  )}
                                </td>
                                <td>
                                  {i?.status === "closed" ? (
                                    <Chip
                                      variant="filled"
                                      label={i?.status}
                                      color="success"
                                    />
                                  ) : null}
                                  {i?.status === "open" ? (
                                    <Chip
                                      variant="outlined"
                                      label={i?.status}
                                      color="secondary"
                                    />
                                  ) : null}
                                  {i?.status === "inprogress" ? (
                                    <Chip
                                      variant="outlined"
                                      label={"In Progress"}
                                      color="default"
                                    />
                                  ) : null}
                                  {i?.status === "reopen" ? (
                                    <Chip
                                      variant="outlined"
                                      label={i?.status}
                                      color="warning"
                                    />
                                  ) : null}
                                  {i?.status === "resolved" ? (
                                    <Chip
                                      variant="outlined"
                                      label={i?.status}
                                      color="info"
                                    />
                                  ) : null}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <p>{!isLoading && <NoDatFound />}</p>
                )}
              </div>
              {totalRecord > 10 && ticketList.length && (
                <>
                  <FormControl className="pagelimit">
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      <FormattedMessage
                        id="page_size"
                        defaultMessage="Page Size"
                      />
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={pageLimit}
                      onChange={(e: any) => {
                        setPageLimit(e.target.value);
                        setPageNumber(1);
                      }}
                    >
                      <FormControlLabel
                        value={10}
                        control={<Radio />}
                        label="10"
                      />
                      <FormControlLabel
                        value={20}
                        control={<Radio />}
                        label="20"
                      />
                      <FormControlLabel
                        value={50}
                        control={<Radio />}
                        label="50"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Pagination
                    itemsPerPage={pageLimit}
                    totalPages={totalRecord}
                    onPageChage={onPageChange}
                    page={pageNumber - 1}
                  />
                </>
              )}
            </Card.Body>
          </Card>
        </section>
      </div>
    </div>
  );
};
export default memo(Tickets);
