import Select from "react-select";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { dispatch } from "../../redux";
import {
  addStudentManualList,
  getAdminRolesList,
  getClasses,
  getStudentByClassList,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import { AddUserManualSchema } from "../../util/validationSchema";
import { UserStatusOption } from "../../util/constants/UserGroup";
import { FormattedMessage, useIntl } from "react-intl";
import { union } from "lodash";

const ManualStudents = () => {
  const classes = useSelector(
    (state: Store) => state.classReducer.classes,
  ) as Array<Object>;
  const classStudentsList = useSelector(
    (state: Store) => state.studentReducer.classStudentsList,
  ) as any;
  const adminRoleList = useSelector(
    (state: Store) => state.adminRolesReducer.adminRoleList,
  ) as any;

  const local = useSelector((state: Store) => state.userDataReducer.local);

  const [selectedClass, setselectedClass] = useState<any>(null);
  const [oldClass, setOldClass] = useState<any>(null);
  // const [selectedStatus, setSelectedStatus] = useState<any>(null);
  const [selectedType, setSelectedType] = useState<any>(null);
  const [selectedStudent, setselectedStudent] = useState<any>(null);
  const [selectedStatus, setselectedStatus] = useState<any>(null);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      oldClass: 0,
      classId: 0,
      userId: 0,
      role: 0,
      status: "",
      additionalUserIds: "",
      parsedAdditionalUserIds: [],
    },
    validationSchema: AddUserManualSchema,
    onSubmit: async (values) => {
      let userIds = values.parsedAdditionalUserIds;
      if (values.userId > 0) {
        userIds = union(userIds, [values.userId]) as never[];
      }

      const v = {
        classId: values.classId,
        role: values.role,
        status: values.status,
        userIds,
      };
      dispatch(addStudentManualList(true, v));
      resetForm();
      setselectedStudent(null);
      setSelectedType(null);
      setselectedClass(null);
      setOldClass(null);
      setselectedStatus(null);
    },
  });

  useEffect(() => {
    dispatch(getClasses(false, 0, { by: "createdAt", order: "ASC" }));
    dispatch(getAdminRolesList(false));
  }, []);

  const classChange = (option: any) => {
    dispatch(getStudentByClassList(false, option.id));
    setOldClass(option);
    setselectedStudent(null);
    formik.setFieldValue("oldClass", option.id);
    // formik.setFieldTouched("userId", false);
  };

  const { errors, touched, resetForm, setFieldError, setFieldValue } = formik;

  const handleParsedAdditionalUserIds = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const s = e.target.value.replace(/\n+$/, "");
    if (s === "") {
      setFieldError("parsedAdditionalUserIds", undefined);
      setFieldValue("parsedAdditionalUserIds", []);
      return;
    }

    const parsedAdditionalUserIds = s.split("\n").map((c) => parseInt(c));
    if (parsedAdditionalUserIds.some((d) => isNaN(d))) {
      setFieldError("parsedAdditionalUserIds", "Check you input format");
      setFieldValue("parsedAdditionalUserIds", []);
    } else {
      setFieldError("parsedAdditionalUserIds", undefined);
      setFieldValue("parsedAdditionalUserIds", parsedAdditionalUserIds);
    }
  };

  return (
    <form
      onSubmit={(e: any) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <div className="p-4">
        <Row>
          <Col md={6}>
            <div className="form-group">
              <label className="control-label">Additional User Ids</label>
              <Row>
                <Col>
                  <textarea
                    className="form-control"
                    name="additionalUserIds"
                    id="additionalUserIds"
                    style={{
                      width: "100%",
                      height: "100%",
                      boxSizing: "border-box",
                    }}
                    value={formik.values.additionalUserIds}
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleParsedAdditionalUserIds(e);
                    }}
                    onBlur={formik.handleBlur}
                    rows={8}
                  />
                  {/* {touched.additionalUserIds &&
                        errors.parsedAdditionalUserIds && (
                          <div className="text-error">
                            <FormattedMessage
                              id={errors.parsedAdditionalUserIds.toString()}
                              defaultMessage="At least one user should be selected"
                            />
                          </div>
                        )} */}
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={6}>
            <div className="form-group">
              <label className="control-label">
                Result Of Parsing (
                {`${formik.values.parsedAdditionalUserIds.length} ids are parsed`}
                )
              </label>
              <textarea
                className="form-control"
                name="additionalUserIds"
                id="additionalUserIds"
                style={{
                  width: "100%",
                  height: "100%",
                  boxSizing: "border-box",
                }}
                value={formik.values.parsedAdditionalUserIds.join("\n")}
                disabled={true}
                rows={8}
              ></textarea>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <div className="form-group">
              <label className="control-label">
                <FormattedMessage
                  id="Student.Manual"
                  defaultMessage="Student Class "
                />
              </label>
              <Select
                name="oldClass"
                onChange={(option: any) => {
                  classChange(option);
                }}
                onBlur={() => formik.setFieldTouched("oldClass", true)}
                options={classes}
                value={oldClass}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.id}
                className="custom-select-dropdown"
                classNamePrefix="react-select-dropdown"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />

              {errors.oldClass && touched.oldClass && (
                <div className="text-error">{errors.oldClass}</div>
              )}
            </div>
          </Col>
          <Col lg={4}>
            <div className="form-group">
              <label className="control-label">
                <FormattedMessage
                  id="Student.Manual.Student"
                  defaultMessage="Student"
                />
              </label>
              <Select
                name="userId"
                value={selectedStudent}
                onChange={(option: any) => {
                  formik.setFieldValue("userId", option.id);
                  setselectedStudent(option);
                }}
                onBlur={() => formik.setFieldTouched("userId", true)}
                options={classStudentsList}
                getOptionLabel={(option: any) =>
                  option.englishFirstName + " " + option.englishLastName
                }
                getOptionValue={(option: any) => option.id}
                className="custom-select-dropdown"
                classNamePrefix="react-select-dropdown"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
              {errors.userId && touched.userId && (
                <div className="text-error">{errors.userId}</div>
              )}
            </div>
          </Col>
        </Row>
        <Row lg={10}>
          <Col lg={4}>
            <div className="form-group">
              <label className="control-label">
                <FormattedMessage
                  id="Student.Manual.Class"
                  defaultMessage="Class"
                />
              </label>
              <Select
                name="classId"
                onChange={(option: any) => {
                  formik.setFieldValue("classId", option.id);
                  setselectedClass(option);
                  // classChange(option);
                }}
                value={selectedClass}
                onBlur={() => formik.setFieldTouched("classId", true)}
                options={classes}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.id}
                className="custom-select-dropdown"
                classNamePrefix="react-select-dropdown"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
              {errors.classId && touched.classId && (
                <div className="text-error">{errors.classId}</div>
              )}
            </div>
          </Col>

          <Col lg={4}>
            <div className="form-group">
              <label className="control-label">
                {" "}
                <FormattedMessage
                  id="activitymodal.Role"
                  defaultMessage="Type"
                />
              </label>
              <Select
                name="classId"
                options={adminRoleList}
                onChange={(option: any) => {
                  formik.setFieldValue("role", option.id);
                  setSelectedType(option);
                }}
                value={selectedType}
                onBlur={() => formik.setFieldTouched("role", true)}
                getOptionLabel={(option: any) => option.roleName}
                getOptionValue={(option: any) => option.id}
                className="custom-select-dropdown"
                classNamePrefix="react-select-dropdown"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
              {errors.role && touched.role && (
                <div className="text-error">{errors.role}</div>
              )}
            </div>
          </Col>
        </Row>
        <Row lg={10}>
          <Col lg={4}>
            <div className="form-group">
              <label className="control-label">
                {" "}
                <FormattedMessage
                  id="Student.Manual.Status"
                  defaultMessage="Status"
                />
              </label>
              <Select
                name="status"
                options={UserStatusOption}
                onChange={(option: any) => {
                  formik.setFieldValue("status", option.value);
                  setselectedStatus(option);
                }}
                value={selectedStatus}
                onBlur={() => formik.setFieldTouched("status", true)}
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => option.name}
                className="custom-select-dropdown"
                classNamePrefix="react-select-dropdown"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
              {errors.status && touched.status && (
                <div className="text-error">{errors.status}</div>
              )}
            </div>
          </Col>
          <Col lg={5}></Col>
        </Row>
        <div className="mt-2">
          <Button type="submit">
            <FormattedMessage id="page.submit" defaultMessage="SUBMIT" />
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ManualStudents;
