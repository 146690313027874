/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/APIServices/baseApiCall";
import {
  GET_EXPERT_ADVISOR_LIST,
  ADD_EXPERT_ADVISOR_LIST,
  EDIT_EXPERT_ADVISOR_LIST,
  GET_EXPERT_ADVISOR_DETAILS,
  GET_EA_VERSION_LIST,
  ADD_EA_VERSION,
  EDIT_EA_VERSION,
  DELETE_EA_VERSION,
  DELETE_EA,
  SET_PAGE,
  ADD_EA_VERSION_TO_ALL_CLASS,
  SET_ACCOUNT_LIMIT,
  ADD_ACCOUNT_INFO_LIST,
  GET_ACCOUNT_INFO_LIST,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { getAPIEndPoint, getPage, setToken } from "../../util/utils";
import {
  GET_EXPERT_ADVISOR_LIST_URL,
  ADD_EXPERT_ADVISOR_LIST_URL,
  EIDT_EXPERT_ADVISOR_LIST_URL,
  GET_EA_VERSION_LIST_URL,
  ADD_EA_VERSION_URL,
  EDIT_EA_VERSION_URL,
  DELETE_EA_VERSION_URL,
  DELETE_EA_URL,
  ADD_EA_VERSION_TO_ALL_CLASS_URL,
  SET_ACCOUNT_LIMIT_URL,
  ADD_ACCOUNT_INFO_LIST_URL,
  GET_ACCOUNT_INFO_LIST_URL,
} from "../../service/APIServices/apiEndPoints";
import {
  _UploadMedia,
  _UploadTool,
} from "../../service/MediaUpload/classes.service";
import { toast } from "react-toastify";
import { getExpressWayPendingList } from "./experessWayActions";
// import { _getExpressWayPendingList } from "../../service";
// import { AxiosResponse } from "axios";

export const setEACurrentPage =
  (pageNumber: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: SET_PAGE,
      payload: pageNumber,
    });
  };

//Get Admin Roles List
export const getExpertAdvisorList =
  (
    showToast = false,
    pageNumber = 0,
    sort = { by: "createdAt", order: "DESC" },
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getExpertAdvisorListInit(showToast, pageNumber, sort, pageLimit));
  };

export const getExpertAdvisorListInit =
  (
    showToast: boolean,
    pageNumber: number,
    sort: any,
    pageLimit: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_EXPERT_ADVISOR_LIST.GET_EXPERT_ADVISOR_LIST_INITIALIZATION,
    });
    apiCall(
      getAPIEndPoint(
        GET_EXPERT_ADVISOR_LIST_URL + `?sort=${sort.order}&sortBy=${sort.by}`,
        pageNumber,
        true,
        pageLimit,
      ),
      {},
      (res: Object) => dispatch(getExpertAdvisorListSuccess(res)),
      (err: any) => dispatch(getExpertAdvisorListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getExpertAdvisorListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_EXPERT_ADVISOR_LIST.GET_EXPERT_ADVISOR_LIST_SUCCESS,
      payload: res,
    });
  };

export const getExpertAdvisorListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_EXPERT_ADVISOR_LIST.GET_EXPERT_ADVISOR_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const addExpertAdvisorList =
  (
    showToast = true,
    indicatorData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch({
      type: ADD_EXPERT_ADVISOR_LIST.ADD_EXPERT_ADVISOR_LIST_INITIALIZATION,
    });
    const files = indicatorData.image_path;
    let url = "";
    if (files) {
      const { fileUploadResponse = "", error = false }: any =
        await _UploadMedia(files as File);
      url = fileUploadResponse;
      if (error) {
        dispatch(addExpertAdvisorListError());
        return;
      }
    }
    indicatorData.image_path = url;
    dispatch(addExpertAdvisorListInit(showToast, indicatorData));
  };

export const addExpertAdvisorListInit =
  (
    showToast = true,
    indicatorData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    apiCall(
      ADD_EXPERT_ADVISOR_LIST_URL,
      indicatorData,
      (res: Object) => dispatch(addExpertAdvisorListSuccess(res)),
      (err: any) => dispatch(addExpertAdvisorListError()),
      METHOD.POST,
      { showToast },
    );
  };

export const addExpertAdvisorListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_EXPERT_ADVISOR_LIST.ADD_EXPERT_ADVISOR_LIST_SUCCESS,
    });

    dispatch(setEACurrentPage(1));
    dispatch(getExpertAdvisorList(false, 1));
    // localStorage.clear();
    // toast.success("addExpertAdvisorList successfully")
    // window.location.reload();
  };

export const addExpertAdvisorListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_EXPERT_ADVISOR_LIST.ADD_EXPERT_ADVISOR_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const editExpertAdvisorList =
  (
    showToast = true,
    id: any,
    indicatorData: any,
    isFile: boolean,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch({
      type: EDIT_EXPERT_ADVISOR_LIST.EDIT_EXPERT_ADVISOR_LIST_INITIALIZATION,
    });

    if (isFile) {
      const files = indicatorData.image_path;
      const { fileUploadResponse, error }: any = await _UploadMedia(
        files as File,
      );
      if (error) {
        dispatch(editExpertAdvisorListError());
        return;
      }
      indicatorData.image_path = fileUploadResponse;
    }
    dispatch(editExpertAdvisorListInit(showToast, id, indicatorData));
  };

export const editExpertAdvisorListInit =
  (
    showToast = true,
    id: any,
    indicatorData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    apiCall(
      EIDT_EXPERT_ADVISOR_LIST_URL + id,
      indicatorData,
      (res: Object) => dispatch(editExpertAdvisorListSuccess(res)),
      (err: any) => dispatch(editExpertAdvisorListError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const editExpertAdvisorListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_EXPERT_ADVISOR_LIST.EDIT_EXPERT_ADVISOR_LIST_SUCCESS,
      payload: res.data,
    });

    dispatch(setEACurrentPage(1));
    dispatch(getExpertAdvisorList(false, 1));
    // localStorage.clear();
    // toast.success("editExpertAdvisorList successfully")
    // window.location.reload();
  };

export const editExpertAdvisorListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: EDIT_EXPERT_ADVISOR_LIST.EDIT_EXPERT_ADVISOR_LIST_ERROR,
    });
  };

export const getExpertAdvisorDetails =
  (
    showToast = true,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getExpertAdvisorDetailsInit(showToast, id));
  };

export const getExpertAdvisorDetailsInit =
  (
    showToast = true,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_EXPERT_ADVISOR_DETAILS.GET_EXPERT_ADVISOR_DETAILS_INITIALIZATION,
    });
    apiCall(
      GET_EXPERT_ADVISOR_LIST_URL + "/" + id,
      {},
      (res: Object) => dispatch(getExpertAdvisorDetailsSuccess(res)),
      (err: any) => dispatch(getExpertAdvisorDetailsError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getExpertAdvisorDetailsSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_EXPERT_ADVISOR_DETAILS.GET_EXPERT_ADVISOR_DETAILS_SUCCESS,
      payload: res.data,
    });
    // localStorage.clear();
    // toast.success("getExpertAdvisorDetails successfully")
    // window.location.reload();
  };

export const getExpertAdvisorDetailsError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_EXPERT_ADVISOR_DETAILS.GET_EXPERT_ADVISOR_DETAILS_ERROR,
    });
  };

//Get Admin Roles
export const deleteEA =
  (
    showToast = true,
    indicator: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(deleteEAInit(showToast, indicator));
  };

export const deleteEAInit =
  (
    showToast = true,
    indicator: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_EA.DELETE_EA_INITIALIZATION,
    });
    apiCall(
      `${DELETE_EA_URL}${indicator}`,
      {},
      (res: Object) => dispatch(deleteEASuccess(res, indicator)),
      (err: any) => dispatch(deleteEAError()),
      METHOD.DELETE,
      { showToast },
    );
  };

export const deleteEASuccess =
  (
    res: any,
    indicator: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_EA.DELETE_EA_SUCCESS,
      payload: res.data,
    });
    dispatch(getExpertAdvisorList(false));
    // localStorage.clear();
    // toast.success("deleteEA successfully")
    // window.location.reload();
  };

export const deleteEAError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: DELETE_EA.DELETE_EA_ERROR,
    });
  };

//Get Admin Roles List
export const getEAVersionList =
  (
    showToast = true,
    indicator: string,
    pageNumber = 0,
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getEAVersionListInit(showToast, indicator, pageNumber, pageLimit));
  };

export const getEAVersionListInit =
  (
    showToast = true,
    indicator: string,
    pageNumber: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_EA_VERSION_LIST.GET_EA_VERSION_LIST_INITIALIZATION,
    });
    apiCall(
      getAPIEndPoint(
        `${GET_EA_VERSION_LIST_URL}${indicator}`,
        pageNumber,
        false,
        pageLimit,
      ),
      {},
      (res: Object) => dispatch(getEAVersionListSuccess(res)),
      (err: any) => dispatch(getEAVersionListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getEAVersionListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_EA_VERSION_LIST.GET_EA_VERSION_LIST_SUCCESS,
      payload: res,
    });
  };

export const getEAVersionListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_EA_VERSION_LIST.GET_EA_VERSION_LIST_ERROR,
    });
  };

export const addEAVersion =
  (
    showToast = true,
    indicator: any,
    id: any,
    isFile: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    // dispatch(addEAVersionInit(showToast, indicator, id));

    dispatch({
      type: ADD_EA_VERSION.ADD_EA_VERSION_INITIALIZATION,
    });

    if (isFile.toolUpload) {
      const toolFile = indicator.download_path;
      const { fileUploadResponse, error }: any = await _UploadTool(
        toolFile as File,
      );
      if (!error) {
        indicator.download_path = fileUploadResponse;
      } else {
        dispatch(addEAVersionError());
        return;
      }
    }

    if (isFile.manualUpload) {
      const toolFile = indicator.manual_path;
      const { fileUploadResponse, error }: any = await _UploadMedia(
        toolFile as File,
      );
      if (!error) {
        indicator.manual_path = fileUploadResponse;
      } else {
        dispatch(addEAVersionError());
        return;
      }
    }
    dispatch(addEAVersionInit(showToast, indicator, id));
  };

export const addEAVersionInit =
  (
    showToast = true,
    indicator: any,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    apiCall(
      `${ADD_EA_VERSION_URL}`,
      indicator,
      (res: Object) => dispatch(addEAVersionSuccess(res, id)),
      (err: any) => dispatch(addEAVersionError()),
      METHOD.POST,
      { showToast },
    );
  };

export const addEAVersionSuccess =
  (
    res: any,
    indicator: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_EA_VERSION.ADD_EA_VERSION_SUCCESS,
      payload: res.data,
    });

    dispatch(setEACurrentPage(1));
    dispatch(getEAVersionList(false, indicator, 1));
  };

export const addEAVersionError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_EA_VERSION.ADD_EA_VERSION_ERROR,
    });
  };

//Get Admin Roles
export const editEAVersion =
  (
    showToast = true,
    EAId: string,
    EAVersion: any,
    classId: any,
    isFile: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch({
      type: EDIT_EA_VERSION.EDIT_EA_VERSION_INITIALIZATION,
    });

    if (isFile.toolUpload) {
      const toolFile = EAVersion.download_path;
      const { fileUploadResponse, error }: any = await _UploadTool(
        toolFile as File,
      );
      if (!error) {
        EAVersion.download_path = fileUploadResponse;
      } else {
        dispatch(editEAVersionError());
        return;
      }
    }

    if (isFile.manualUpload) {
      const toolFile = EAVersion.manual_path;
      const { fileUploadResponse, error }: any = await _UploadMedia(
        toolFile as File,
      );
      if (!error) {
        EAVersion.manual_path = fileUploadResponse;
      } else {
        dispatch(editEAVersionError());
        return;
      }
    }
    dispatch(editEAVersionInit(showToast, EAId, EAVersion, classId));
  };

export const editEAVersionInit =
  (
    showToast = true,
    EAId: string,
    EAVersion: any,
    classId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    // dispatch({
    //   type: EDIT_EA_VERSION.EDIT_EA_VERSION_INITIALIZATION
    // });
    apiCall(
      `${EDIT_EA_VERSION_URL}${EAId}`,
      EAVersion,
      (res: Object) => dispatch(editEAVersionSuccess(res, classId)),
      (err: any) => dispatch(editEAVersionError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const editEAVersionSuccess =
  (res: any, classId: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_EA_VERSION.EDIT_EA_VERSION_SUCCESS,
      payload: res.data,
    });
    dispatch(setEACurrentPage(1));
    dispatch(getEAVersionList(false, classId, 1));
  };

export const editEAVersionError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: EDIT_EA_VERSION.EDIT_EA_VERSION_ERROR,
    });
  };

//Get Admin Roles
export const deleteEAVersion =
  (
    showToast = true,
    indicator: any,
    ea: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(deleteEAVersionInit(showToast, indicator, ea));
  };

export const deleteEAVersionInit =
  (
    showToast = true,
    indicator: any,
    ea: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_EA_VERSION.DELETE_EA_VERSION_INITIALIZATION,
    });
    apiCall(
      `${DELETE_EA_VERSION_URL}${indicator}`,
      {},
      (res: Object) => dispatch(deleteEAVersionSuccess(res, ea)),
      (err: any) => dispatch(deleteEAVersionError()),
      METHOD.DELETE,
      { showToast },
    );
  };

export const deleteEAVersionSuccess =
  (
    res: any,
    indicator: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_EA_VERSION.DELETE_EA_VERSION_SUCCESS,
      payload: res.data,
    });
    dispatch(getEAVersionList(false, indicator));
  };

export const deleteEAVersionError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: DELETE_EA_VERSION.DELETE_EA_VERSION_ERROR,
    });
  };

//Get Admin Roles
export const AddEAVersionToAllClass =
  (
    showToast = true,
    // indicator: any,
    ea: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(AddEAVersionToAllClassInit(showToast, ea));
  };

export const AddEAVersionToAllClassInit =
  (
    showToast = true,
    ea: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_EA_VERSION_TO_ALL_CLASS.ADD_EA_VERSION_TO_ALL_CLASS_INITIALIZATION,
    });
    apiCall(
      `${ADD_EA_VERSION_TO_ALL_CLASS_URL}`,
      ea,
      (res: Object) => dispatch(AddEAVersionToAllClassSuccess(res, ea)),
      (err: any) => dispatch(AddEAVersionToAllClassError()),
      METHOD.POST,
      { showToast },
    );
  };

export const AddEAVersionToAllClassSuccess =
  (
    res: any,
    indicator: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_EA_VERSION_TO_ALL_CLASS.ADD_EA_VERSION_TO_ALL_CLASS_SUCCESS,
      payload: res.data,
    });
    // dispatch(getEAVersionList(false, indicator));
  };

export const AddEAVersionToAllClassError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_EA_VERSION_TO_ALL_CLASS.ADD_EA_VERSION_TO_ALL_CLASS_ERROR,
    });
  };

export const setAccountLimit =
  (
    accountLimit: any,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(setAccountLimitInit(accountLimit, id));
  };

export const setAccountLimitInit =
  (
    accountLimit: any,
    id: boolean,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: SET_ACCOUNT_LIMIT.SET_ACCOUNT_LIMIT_INITIALIZATION,
    });
    apiCall(
      SET_ACCOUNT_LIMIT_URL + id,
      accountLimit,
      // { email },
      () => dispatch(setAccountLimitSuccess()),
      () => {
        dispatch(setAccountLimitError());
      },
      METHOD.PUT,
      { showToast: true },
    );
  };

export const setAccountLimitError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: SET_ACCOUNT_LIMIT.SET_ACCOUNT_LIMIT_ERROR,
      // payload: resend,
    });
  };

export const setAccountLimitSuccess =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: SET_ACCOUNT_LIMIT.SET_ACCOUNT_LIMIT_SUCCESS,
      // payload: resend,
    });
    const pageNumber = sessionStorage.getItem("pageE") || "1";
    const filterQ = sessionStorage.getItem("filterE") || "";
    dispatch(
      getExpressWayPendingList(
        false,
        "approved",
        parseInt(pageNumber),
        true,
        filterQ,
        10,
      ),
    );
  };

//Get AccountInfo  List
export const getAccountInfoList =
  (
    showToast = true,
    eaId: string | undefined,
    accountType: string,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getAccountInfoListInit(showToast, eaId, accountType));
  };

export const getAccountInfoListInit =
  (
    showToast = true,
    eaId: string | undefined,
    accountType: string,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ACCOUNT_INFO_LIST.GET_ACCOUNT_INFO_LIST_INITIALIZATION,
    });
    apiCall(
      `${GET_ACCOUNT_INFO_LIST_URL}?id=${eaId}&type=${accountType}`,
      {},
      (res: Object) => dispatch(getAccountInfoListSuccess(res)),
      (err: any) => dispatch(getAccountInfoListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getAccountInfoListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ACCOUNT_INFO_LIST.GET_ACCOUNT_INFO_LIST_SUCCESS,
      payload: res,
    });
  };

export const getAccountInfoListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_ACCOUNT_INFO_LIST.GET_ACCOUNT_INFO_LIST_ERROR,
    });
  };

// Add AccountInfo

export const addAccountInfoList =
  (
    showToast = true,
    accountData: any,
    eaId: any,
    accountType: string,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch({
      type: ADD_ACCOUNT_INFO_LIST.ADD_ACCOUNT_INFO_LIST_INITIALIZATION,
    });

    dispatch(addAccountInfoListInit(showToast, accountData, eaId, accountType));
  };

export const addAccountInfoListInit =
  (
    showToast = true,
    accountData: any,
    eaId: any,
    accountType: string,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    apiCall(
      `${ADD_ACCOUNT_INFO_LIST_URL}`,
      accountData,
      (res: Object) =>
        dispatch(addAccountInfoListSuccess(res, eaId, accountType)),
      (err: any) => dispatch(addAccountInfoListError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const addAccountInfoListSuccess =
  (
    res: any,
    eaId: any,
    accountType: string,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_ACCOUNT_INFO_LIST.ADD_ACCOUNT_INFO_LIST_SUCCESS,
    });

    dispatch(getAccountInfoList(false, eaId, accountType));
  };

export const addAccountInfoListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_ACCOUNT_INFO_LIST.ADD_ACCOUNT_INFO_LIST_ERROR,
    });
  };
