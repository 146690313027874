/* eslint-disable no-case-declarations */
import {
  GET_UTILITY_LIST,
  ADD_UTILITY_LIST,
  EDIT_UTILITY_LIST,
  GET_UTILITY_DETAILS,
  GET_UTILITY_VERSION_LIST,
  ADD_UTILITY_VERSION,
  EDIT_UTILITY_VERSION,
  DELETE_UTILITY_VERSION,
  DELETE_UTILITY,
  SET_PAGE,
} from "../constants/action-types";
import { UtilityData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";
// import { Posts } from "../Actions";

const initialState = {
  progress: false,
  isLoading: false,
  onSaveLoading: false,
  utilityList: {},
  utilityDetails: {},
  utilityVersion: {},
  totalRecord: 0,
  currentPage: 1,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: UtilityData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case SET_PAGE:
        return {
          ...state,
          currentPage: action.payload,
        };

      case GET_UTILITY_LIST.GET_UTILITY_LIST_INITIALIZATION:
        return {
          ...state,
          totalRecord: 0,
          isLoading: true,
        };

      case GET_UTILITY_LIST.GET_UTILITY_LIST_SUCCESS:
        const allPost: any = action.payload; // [{ id: 2, body: "Abcd", title: "title", userId: 3 }, { id: 2, body: "Abcd", title: "title", userId: 3 }];
        return {
          ...state,
          utilityList: allPost.data,
          totalRecord: allPost.totalCount,
          isLoading: false,
        };

      case GET_UTILITY_LIST.GET_UTILITY_LIST_ERROR:
        return {
          ...state,
          totalRecord: 0,
          isLoading: false,
        };

      case ADD_UTILITY_LIST.ADD_UTILITY_LIST_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case ADD_UTILITY_LIST.ADD_UTILITY_LIST_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case ADD_UTILITY_LIST.ADD_UTILITY_LIST_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case EDIT_UTILITY_LIST.EDIT_UTILITY_LIST_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case EDIT_UTILITY_LIST.EDIT_UTILITY_LIST_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case EDIT_UTILITY_LIST.EDIT_UTILITY_LIST_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case GET_UTILITY_DETAILS.GET_UTILITY_DETAILS_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_UTILITY_DETAILS.GET_UTILITY_DETAILS_SUCCESS:
        return {
          ...state,
          utilityDetails: action.payload,
          isLoading: false,
        };

      case GET_UTILITY_DETAILS.GET_UTILITY_DETAILS_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_UTILITY_VERSION_LIST.GET_UTILITY_VERSION_LIST_INITIALIZATION:
        return {
          ...state,
          totalRecord: 0,
          isLoading: true,
        };

      case GET_UTILITY_VERSION_LIST.GET_UTILITY_VERSION_LIST_SUCCESS:
        const utilityVersion: any = action.payload;
        return {
          ...state,
          utilityVersion: utilityVersion.data, // action.payload,
          totalRecord: utilityVersion.totalCount || 1,
          isLoading: false,
        };

      case GET_UTILITY_VERSION_LIST.GET_UTILITY_VERSION_LIST_ERROR:
        return {
          ...state,
          totalRecord: 0,
          isLoading: false,
        };

      case ADD_UTILITY_VERSION.ADD_UTILITY_VERSION_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case ADD_UTILITY_VERSION.ADD_UTILITY_VERSION_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case ADD_UTILITY_VERSION.ADD_UTILITY_VERSION_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case EDIT_UTILITY_VERSION.EDIT_UTILITY_VERSION_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case EDIT_UTILITY_VERSION.EDIT_UTILITY_VERSION_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case EDIT_UTILITY_VERSION.EDIT_UTILITY_VERSION_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case DELETE_UTILITY_VERSION.DELETE_UTILITY_VERSION_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case DELETE_UTILITY_VERSION.DELETE_UTILITY_VERSION_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case DELETE_UTILITY_VERSION.DELETE_UTILITY_VERSION_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case DELETE_UTILITY.DELETE_UTILITY_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case DELETE_UTILITY.DELETE_UTILITY_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case DELETE_UTILITY.DELETE_UTILITY_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      default:
        return state;
    }
  },
);

export default reducer;
