import { Button, Card } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { dispatch } from "../../redux";
import { getGiftCategoryList } from "../../redux/action";
import { Store } from "../../redux/Actions";
import AddGiftCategoryModal from "../../Components/Gift/AddGiftCategoryModal";
import EditGiftCategoryModal from "../../Components/Gift/EditGiftCategoryModal";
// import { ButtonBase } from "@mui/material";
import Pagination from "../../Components/common/Paginate/Pagination";
import NoDatFound from "../../Components/common/NoDatFound";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import { FormattedMessage } from "react-intl";
import { FaEdit } from "../../reactIcons";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

const GiftsCategory = () => {
  const giftCatList = useSelector(
    (state: Store) => state.giftReducer.giftCatList,
  );
  const [modal, setmodal] = useState({ modal: 2, gift: {} });

  const isLoading = useSelector((state: Store) => state.giftReducer.isLoading);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const totalRecord = useSelector(
    (state: Store) => state.giftReducer.totalRecordCat,
  ) as any;

  const onPageChange = (pageNum: number) => {
    setPageNumber(pageNum);
  };

  useEffect(() => {
    dispatch(getGiftCategoryList(false, pageNumber, true, pageLimit));
  }, [pageNumber, pageLimit]);

  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                <FormattedMessage
                  id="head.AdminGiftCategory"
                  defaultMessage="Admin Gift Category"
                />
              </h1>
            </div>
            {/* <div className="m-l-10 text-end">
              <Button
                type="button"
                onClick={() => setmodal({ modal: 0, gift: {} })}
              >
                <FormattedMessage
                  id="head.AddGiftCategory"
                  defaultMessage="Add Gift Category"
                />
              </Button>
            </div> */}
          </div>
        </header>
        <section className="page-content container-fluid">
          {isLoading && <CenterLoader />}

          <Card style={{ width: "100%" }} className="mt-3">
            <Card.Body className="p-0">
              <div>
                <div className="table-responsive">
                  {giftCatList && giftCatList.length > 0 ? (
                    <>
                      {" "}
                      <table className="table table-hover m-0">
                        <thead>
                          <tr>
                            <th>
                              <FormattedMessage
                                id="table.head.ID"
                                defaultMessage="Id"
                              />
                            </th>
                            <th>
                              <FormattedMessage
                                id="table.head.Name"
                                defaultMessage="Name"
                              />
                            </th>
                            <th>
                              <FormattedMessage
                                id="page.Show"
                                defaultMessage="Show"
                              />
                            </th>
                            <th>
                              <FormattedMessage
                                id="page.Edit"
                                defaultMessage="Edit"
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {giftCatList &&
                            giftCatList.length > 0 &&
                            giftCatList.map((i: any) => (
                              <tr>
                                <td>{i.id}</td>
                                <td>{i.name}</td>
                                <td>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={i.show}
                                      id="flexCheckChecked"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <Button
                                    type="button"
                                    onClick={() =>
                                      setmodal({ modal: 1, gift: i })
                                    }
                                    className="btn btn-primary"
                                  >
                                    <i className="" style={{ color: "white" }}>
                                      <FaEdit />{" "}
                                    </i>
                                  </Button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <p>{!isLoading && <NoDatFound />}</p>
                  )}
                </div>
                {totalRecord > 10 && giftCatList.length > 0 && (
                  <>
                    <FormControl className="pagelimit">
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Page Size
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={pageLimit}
                        onChange={(e: any) => {
                          setPageLimit(e.target.value);
                          setPageNumber(1);
                        }}
                      >
                        <FormControlLabel
                          value={10}
                          control={<Radio />}
                          label="10"
                        />
                        <FormControlLabel
                          value={20}
                          control={<Radio />}
                          label="20"
                        />
                        <FormControlLabel
                          value={50}
                          control={<Radio />}
                          label="50"
                        />
                      </RadioGroup>
                    </FormControl>
                    <Pagination
                      itemsPerPage={pageLimit}
                      totalPages={totalRecord}
                      onPageChage={onPageChange}
                      page={pageNumber - 1}
                    />
                  </>
                )}
              </div>
            </Card.Body>
          </Card>

          {modal.modal === 0 && (
            <AddGiftCategoryModal
              onModalClose={() => setmodal({ modal: 2, gift: {} })}
              gift={modal.gift}
            />
          )}

          {modal.modal === 1 && (
            <EditGiftCategoryModal
              onModalClose={() => setmodal({ modal: 2, gift: {} })}
              gift={modal.gift}
            />
          )}
        </section>
      </div>
    </div>
  );
};

export default GiftsCategory;
