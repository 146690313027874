import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { dispatch } from "../../redux";
import {
  // attendActivity,
  getActivityDetails,
  rollCallActivity,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import { Row, Col, Card, Button } from "react-bootstrap";
// import { BsChevronLeft } from "react-icons/bs";
// import Switch from "@mui/material/Switch/Switch";
import UserSelectionByClass from "../../Components/Selection/UserSelectionByClass";
import { useFormik } from "formik";
import { ActivityRollCallSchema } from "../../util/validationSchema";
import { FormattedMessage } from "react-intl";
import Loader from "../../Components/common/Loader";
import { BsChevronLeft } from "../../reactIcons";
import { union } from "lodash";
import Loading from "../../Components/common/Loading";

const ActivityRollCall = () => {
  const urlParams = useParams();
  const [ButtonClick, setButtonClick] = useState(false);
  const navigate = useNavigate();
  const activityDetails: any = useSelector(
    (state: Store) => state.ActivityReducer.activityDetails,
  ) as any;

  const onSaveLoading: any = useSelector(
    (state: Store) => state.ActivityReducer.onSaveLoading,
  ) as any;

  useEffect(() => {
    if (!onSaveLoading && ButtonClick) {
      navigate("/activities");
      setButtonClick(false);
    }
  }, [onSaveLoading]);

  useEffect(() => {
    const { id } = urlParams;
    dispatch(getActivityDetails(false, id));
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      usersId: [],
      activityId: parseInt(activityDetails.id),
      additionalUserIds: "",
      parsedAdditionalUserIds: [],
    },
    validationSchema: ActivityRollCallSchema,
    onSubmit: async (values) => {
      if (!ButtonClick) {
        values.usersId = union(values.usersId, values.parsedAdditionalUserIds);
        const { usersId, activityId } = values;
        dispatch(rollCallActivity(true, { usersId, activityId }));
        setButtonClick(true);
      }
    },
  });

  const { errors, touched, setFieldValue, setFieldError } = formik;

  const handleParsedAdditionalUserIds = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const s = e.target.value.replace(/\n+$/, "");
    if (s === "") {
      setFieldError("parsedAdditionalUserIds", undefined);
      setFieldValue("parsedAdditionalUserIds", []);
      return;
    }

    const parsedAdditionalUserIds = s.split("\n").map((c) => parseInt(c));
    if (parsedAdditionalUserIds.some((d) => isNaN(d))) {
      setFieldError("parsedAdditionalUserIds", "Check you input format");
      setFieldValue("parsedAdditionalUserIds", []);
    } else {
      setFieldError("parsedAdditionalUserIds", undefined);
      setFieldValue("parsedAdditionalUserIds", parsedAdditionalUserIds);
    }
  };

  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex flex-wrap align-items-center">
            <div className="mr-auto">
              <h1>
                <FormattedMessage
                  id="head.AdminActivityRollCall"
                  defaultMessage="Admin Activity Roll Call"
                />
              </h1>
            </div>
            <div className="m-l-10 common-right-button">
              <Link to="/activities" className="btn btn-secondary">
                <i className="back-button">
                  <BsChevronLeft /> &nbsp;
                </i>
                <FormattedMessage id="page.back" defaultMessage="Back" />
              </Link>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          <Card>
            <Card.Body>
              <Row>
                <Col sm={3} md={4} lg={2}>
                  <label className="">
                    <FormattedMessage
                      id="classDetail.UserSelection"
                      defaultMessage="User Selection"
                    />
                  </label>
                </Col>
                <Col style={{ maxWidth: "60%" }}>
                  <UserSelectionByClass
                    setFinalUsers={(users: any) =>
                      formik.setFieldValue("usersId", users)
                    }
                    users={formik.values.usersId}
                  />
                  {errors.usersId && touched.usersId && (
                    <div className="text-error">
                      <FormattedMessage
                        id={errors.usersId.toString()}
                        defaultMessage="At least one user should be selected"
                      />
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="form-group">
                    <label className="control-label">Additional User Ids</label>
                    <Row>
                      <Col>
                        <textarea
                          className="form-control"
                          name="additionalUserIds"
                          id="additionalUserIds"
                          style={{
                            width: "100%",
                            height: "100%",
                            boxSizing: "border-box",
                          }}
                          value={formik.values.additionalUserIds}
                          onChange={(e) => {
                            formik.handleChange(e);
                            handleParsedAdditionalUserIds(e);
                          }}
                          onBlur={formik.handleBlur}
                          rows={8}
                        />
                        {touched.additionalUserIds &&
                          errors.parsedAdditionalUserIds && (
                            <div className="text-error">
                              <FormattedMessage
                                id={errors.parsedAdditionalUserIds.toString()}
                                defaultMessage="At least one user should be selected"
                              />
                            </div>
                          )}
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <label className="control-label">
                      Result Of Parsing (
                      {`${formik.values.parsedAdditionalUserIds.length} ids are parsed`}
                      )
                    </label>
                    <textarea
                      className="form-control"
                      name="additionalUserIds"
                      id="additionalUserIds"
                      style={{
                        width: "100%",
                        height: "100%",
                        boxSizing: "border-box",
                      }}
                      value={formik.values.parsedAdditionalUserIds.join("\n")}
                      disabled={true}
                      rows={8}
                    ></textarea>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={3} md={4} lg={2}>
                  <label className="">
                    <FormattedMessage
                      id="classDetail.ActivityId"
                      defaultMessage="Activity Id"
                    />
                  </label>
                </Col>
                <Col style={{ maxWidth: "60%" }}>{activityDetails.id}</Col>
              </Row>
              <Row>
                <Col sm={3} md={4} lg={2}>
                  <label className="">
                    <FormattedMessage
                      id="classDetail.ActivityName"
                      defaultMessage="Activity Name"
                    />
                  </label>
                </Col>
                <Col style={{ maxWidth: "60%" }}>{activityDetails.name}</Col>
              </Row>
              <div className="form-group row mt-3">
                <label className="col-sm-2 col-form-label col-form-label-sm">
                  {" "}
                </label>

                <Button className="ml-3" onClick={() => formik.handleSubmit()}>
                  <FormattedMessage
                    id="moreMenu.RollCall"
                    defaultMessage="Roll Call"
                  />{" "}
                  {onSaveLoading && <Loader />}
                </Button>
              </div>
            </Card.Body>
          </Card>
        </section>
      </div>
    </div>
  );
};
export default ActivityRollCall;
