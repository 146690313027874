import React, { useEffect, useState } from "react";
// import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import NoDatFound from "../../Components/common/NoDatFound";
import Pagination from "../../Components/common/Paginate/Pagination";
import { dispatch } from "../../redux";
import {
  getActivityAttendence,
  // getActivityList,
} from "../../redux/action/ActivityActions";
import { Store } from "../../redux/Actions";
import { customFormatDatetimeWithLocale, formatDate } from "../../util/utils";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

const AttendenceList = () => {
  const attendenceList = useSelector(
    (state: Store) => state.ActivityReducer.attendenceList,
  ) as Array<Object>;
  const isLoading = useSelector(
    (state: Store) => state.ActivityReducer.isLoading,
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const totalRecord = useSelector(
    (state: Store) => state.ActivityReducer.totalRecord,
  ) as any;

  const onPageChange = (pageNum: number) => {
    setPageNumber(pageNum);
  };

  const locale = useSelector((state: Store) => state.userDataReducer.local);

  useEffect(() => {
    dispatch(getActivityAttendence(false, pageNumber, 0, pageLimit));
  }, [pageNumber, pageLimit]);

  return (
    <div>
      {/* <div className="mt-3 ml-2">
                <h5>Activities</h5>
            </div> */}
      <div className="table-responsive">
        {isLoading && <CenterLoader />}
        {attendenceList && attendenceList.length ? (
          <>
            {" "}
            <table className="table table-hover m-0">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="table.head.ID" defaultMessage="ID" />{" "}
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.Member"
                      defaultMessage="Member"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.Message"
                      defaultMessage="Message"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.Date"
                      defaultMessage="Date"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {attendenceList &&
                  attendenceList.length &&
                  attendenceList.map((i: any) => (
                    <tr>
                      <td>{i.id}</td>
                      <td>{i?.user?.id}</td>
                      <td
                        dangerouslySetInnerHTML={{
                          __html: i?.remarks?.split(",").join("<br/>"),
                        }}
                      />
                      <td>
                        {customFormatDatetimeWithLocale(i.createdAt, locale)}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        ) : (
          <p>{!isLoading && <NoDatFound />}</p>
        )}{" "}
      </div>
      {totalRecord > 10 && attendenceList.length && (
        <>
          <FormControl className="pagelimit">
            <FormLabel id="demo-row-radio-buttons-group-label">
              <FormattedMessage id="page_size" defaultMessage="Page Size" />
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={pageLimit}
              onChange={(e: any) => {
                setPageLimit(e.target.value);
                setPageNumber(1);
              }}
            >
              <FormControlLabel value={10} control={<Radio />} label="10" />
              <FormControlLabel value={20} control={<Radio />} label="20" />
              <FormControlLabel value={50} control={<Radio />} label="50" />
            </RadioGroup>
          </FormControl>
          <Pagination
            itemsPerPage={pageLimit}
            totalPages={totalRecord}
            onPageChage={onPageChange}
            page={pageNumber - 1}
          />
        </>
      )}
    </div>
  );
};

export default AttendenceList;
