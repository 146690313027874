import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { dispatch } from "../../redux";
import {
  setMediaUploadProgress,
  uploadMediaList,
  uploadMediaListError,
  uploadMediaListSuccess,
} from "../../redux/action/mediaUploadAction";
import { getToken, valiadteToken } from "../../util/utils";
// import { apiCall, METHOD } from "../APIServices/baseApiCall";
const BLACKLISTED_FILE_TYPES = ["webm"];

const _UploadMediaMutiple = (
  imageData: File[],
  statekey: string,
  // fileKey: string
) => {
  dispatch(uploadMediaList());
  const token = getToken("login-auth-token");

  if (valiadteToken()) {
    const auth = token ? `Bearer ${token}` : "";

    // return new Promise((resolve, reject) => {

    const formData = new FormData();
    let acceptedFiles = 0;
    const rejectedFiles: any[] = [];
    imageData.map((img) => {
      const extension = img.name.split(".").at(-1);
      if (
        extension !== undefined &&
        BLACKLISTED_FILE_TYPES.includes(extension)
      ) {
        rejectedFiles.push(img.name);
      } else {
        formData.append("file", img);
        acceptedFiles += 1;
      }
    });

    if (!acceptedFiles) {
      toast.error("No file Allowed!");
      dispatch(uploadMediaListError());
      return;
    }

    axios
      .post(`${process.env.REACT_APP_BASE_URL}files/multiple`, formData, {
        headers: {
          Authorization: auth,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data: any) => {
          if (Math.round((100 * data.loaded) / data.total) > 0) {
            dispatch(
              setMediaUploadProgress(
                Math.round((100 * data.loaded) / data.total),
              ),
            );
          }
        },
      })
      .then((res: AxiosResponse) => {
        if (res.data.data) {
          const newlyUploaded: any = [];
          res.data.data.map((i: any) => {
            newlyUploaded.push({ key: i.key, location: i.location });
          });
          dispatch(
            uploadMediaListSuccess({
              newlyUploaded: newlyUploaded,
              stateKey: statekey,
            }),
          );
        }
      })
      .catch(() => {
        toast.error("File upload unsuccessful!");
        dispatch(uploadMediaListError());
      });

    if (rejectedFiles.length > 0) {
      toast.error(
        `The following files are not supported: ${rejectedFiles.join(", ")}`,
      );
    }
  }
};

const _UploadMedia = (imageData: File) => {
  const token = getToken("login-auth-token");

  if (valiadteToken()) {
    const auth = token ? `Bearer ${token}` : "";

    return new Promise((resolve) => {
      const formData = new FormData();
      formData.append("image", imageData);
      axios
        .post(`${process.env.REACT_APP_BASE_URL}files/image`, formData, {
          headers: {
            Authorization: auth,
          },
        })
        .then((res: AxiosResponse) => {
          if (res?.data?.data?.location) {
            resolve({
              fileUploadResponse: res.data.data.location,
              error: false,
            });
            toast.success("File uploaded successfully!");
          } else {
            toast.error("Something went wrong while uploading");
            resolve({ fileUploadResponse: "", error: true });
          }

          // resolve(res.data.data.location);
          // toast.success('File uploaded successfully!')
        })
        .catch(() => {
          toast.error("Something went wrong while uploading");
          resolve({ fileUploadResponse: "", error: true });
        });
    });
  }
};

const _DeleteMedia = (key: string) => {
  const token = getToken("login-auth-token");

  if (valiadteToken()) {
    const auth = token ? `Bearer ${token}` : "";

    return new Promise((resolve, reject) => {
      axios
        .delete(`${process.env.REACT_APP_BASE_URL}files/delete`, {
          data: { location: key },
          headers: {
            Authorization: auth,
          },
        })
        .then((res: Object) => {
          resolve(res);
          // toast.success('File deleted sucessfully!');
        })
        .catch((err: any) => {
          toast.error("File upload failed !");
          reject(err);
        });
    });
  }
};

const _UploadTool = (imageData: File) => {
  const token = getToken("login-auth-token");

  if (valiadteToken()) {
    const auth = token ? `Bearer ${token}` : "";

    return new Promise((resolve) => {
      const formData = new FormData();
      formData.append("tool", imageData);
      axios
        .post(`${process.env.REACT_APP_BASE_URL}files/tools`, formData, {
          headers: {
            Authorization: auth,
          },
        })
        .then((res: AxiosResponse) => {
          if (res?.data?.data?.location) {
            resolve({
              fileUploadResponse: res.data.data.location,
              error: false,
            });
            // toast.success('File uploaded successfully!');
          } else {
            toast.error("Something went wrong while uploading");
            resolve({ fileUploadResponse: "", error: true });
          }

          // resolve(res.data.data.location);
          // toast.success('File uploaded successfully!')
        })
        .catch(() => {
          // reject(err)
          toast.error("Something went wrong while uploading");
          resolve({ fileUploadResponse: "", error: true });
        });
    });
  }
};

export { _UploadMedia, _DeleteMedia, _UploadTool, _UploadMediaMutiple };
