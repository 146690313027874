import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

import { Store } from "../../redux/Actions";
import { dispatch } from "../../redux";
import { FormattedMessage, useIntl } from "react-intl";
import { STUDENT_LIST_TABS } from "../../Constants";
import { getClasses, getStudentList } from "../../redux/action";
import PendingStudent from "./Pending";
import RejectedStudents from "./Rejected";
import ManualStudents from "./Manual";
import ApproveStudents from "./Approve";
import Pagination from "../../Components/common/Paginate/Pagination";
import NoDatFound from "../../Components/common/NoDatFound";
import Select from "react-select";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

const Students = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [status, setStatus] = useState("pending");

  const studentLists: any = useSelector(
    (state: Store) => state.studentReducer.studentList,
  ) as any;

  const isLoading = useSelector(
    (state: Store) => state.studentReducer.Loading.isLoading,
  );
  const local = useSelector((state: Store) => state.userDataReducer.local);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const totalRecord = useSelector(
    (state: Store) => state.studentReducer.totalRecord,
  ) as any;

  const [studentList, setStudentList] = useState<any>([]);
  const [selectedClass, setselectedClass] = useState<any>(null);
  const [filterQ, setFilterQ] = useState("");
  const [text, settext] = useState("");
  const [Alltext, setAllText] = useState("");
  const classess = useSelector(
    (state: Store) => state.classReducer.classes,
  ) as any;

  const [sortBy, setSortBy] = useState("updatedAt");
  const [sortOrder, setSortOrder] = useState("DESC");

  useEffect(() => {
    // dispatch(getStudentList(false, status, pageNumber, { order: sortOrder, by: sortBy }));
    setStudentList(studentLists);
  }, [studentLists]);

  useEffect(() => {
    // dispatch(getStudentList(false, status, pageNumber, { order: sortOrder, by: sortBy }));
  }, [status]);

  useEffect(() => {
    if (status !== "man") {
      dispatch(
        getStudentList(
          false,
          status,
          pageNumber,
          { order: sortOrder, by: sortBy },
          filterQ,
          pageLimit,
        ),
      );
    }
  }, [pageNumber, filterQ, sortOrder, sortBy, status, pageLimit]);

  const onPageChange = (pageNum: number) => {
    setPageNumber(pageNum);
  };

  const setAciveTab = (event: any, newValue: any) => {
    setActiveTab(newValue);
    setPageLimit(10);
    if (newValue === 0) {
      setStatus("pending");
    } else if (newValue === 1) {
      setStatus("approved");
    } else if (newValue === 2) {
      setStatus("rejected");
    } else if (newValue === 3) {
      //None
      setStatus("man");
    }
    settext("");
    setAllText("");
    setFilter("", 0);
    setselectedClass(null);
    setPageNumber(1);
    setSortBy("updatedAt");
    setSortOrder("DESC");
    setStudentList([]);
  };

  const setFilter = (text: string, classId: any) => {
    let q = "";
    if (text.length > 0) q = q + `&q=${encodeURIComponent(text)}`;
    if (classId > 0) {
      q = q + `&classId=${classId}`;
    }
    setFilterQ(q);
    setPageNumber(1);
    return 0;
  };

  useEffect(() => {
    const tid = setTimeout(() => {
      setAllText(text);
    }, 1000);
    return () => {
      clearTimeout(tid);
    };
  }, [text]);

  useEffect(() => {
    const classId: any = selectedClass?.id || 0;
    setFilter(text, classId);
  }, [Alltext]);

  useEffect(() => {
    dispatch(
      getClasses(false, 0, { by: "createdAt", order: "ASC" }, pageLimit),
    );
  }, []);

  useEffect(() => {
    setPageNumber(1);
  }, [filterQ]);

  const classes = useStyles();

  const setSorting = (prop: any) => {
    if (sortBy === prop) {
      setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
    } else {
      setSortBy(prop);
      setSortOrder("ASC");
    }
  };

  return (
    <div className="content-wrapper">
      <div className="content">
        {isLoading && <CenterLoader />}
        <header className="page-header">
          <div className="d-flex flex-wrap align-items-center">
            <div className="mr-auto">
              <h1>
                <FormattedMessage
                  id="Admin.Student"
                  defaultMessage="Admin Student"
                />
              </h1>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          <Card style={{ width: "100%" }}>
            <Card.Body className="p-0">
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={activeTab}
                  onChange={setAciveTab}
                  className={classes.tabs}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label={
                      <FormattedMessage
                        id="tab.Pending"
                        defaultMessage="Pending"
                      />
                    }
                    {...a11yProps(STUDENT_LIST_TABS.PENDING_LIST)}
                  />
                  <Tab
                    label={
                      <FormattedMessage
                        id="tab.Approved"
                        defaultMessage="Approved"
                      />
                    }
                    {...a11yProps(STUDENT_LIST_TABS.APPROVE_LIST)}
                  />
                  <Tab
                    label={
                      <FormattedMessage
                        id="tab.Rejected"
                        defaultMessage="Rejected"
                      />
                    }
                    {...a11yProps(STUDENT_LIST_TABS.REJECTED_LIST)}
                  />
                  <Tab
                    label={
                      <FormattedMessage
                        id="tab.Manual"
                        defaultMessage="Mannual"
                      />
                    }
                    {...a11yProps(STUDENT_LIST_TABS.MANUAL_LIST)}
                  />
                </Tabs>
              </Box>
              {activeTab === STUDENT_LIST_TABS.MANUAL_LIST ? (
                ""
              ) : (
                <Row className="p-20">
                  <Col
                    style={{ display: "inline-flex" }}
                    className="col-sm-6 col-12"
                  >
                    <div className="justify-content-start active-user">
                      <label style={{ position: "absolute" }}>
                        <FormattedMessage id="Filter" defaultMessage="Filter" />
                        :
                      </label>
                      <input
                        value={text}
                        onChange={(e) => settext(e.target.value)}
                        type="text"
                        style={{
                          maxWidth: "220px",
                          paddingLeft: "39px",
                          borderRadius: "0px",
                          border: "none",
                          borderBottom: "1px solid",
                        }}
                        className="form-control"
                      />
                    </div>
                  </Col>
                  <Col className="col-sm-6 col-12 mt-4 mt-sm-0">
                    <div className="justify-content-end p-2 active-user">
                      <label className="control-label mb-0 d-flex nowrap">
                        <FormattedMessage
                          id="Admin.GrantTool.Classes"
                          defaultMessage="Class Type"
                        />
                      </label>
                      <Select
                        name="classId"
                        value={selectedClass}
                        onChange={(option: any) => {
                          setselectedClass(option);
                          if (option && Object.keys(option).length > 0) {
                            setFilter(text, option.id);
                          } else {
                            setFilter(text, 0);
                          }
                        }}
                        isClearable
                        options={classess}
                        getOptionLabel={(option: any) => option.name}
                        getOptionValue={(option: any) => option.id}
                        className="custom-select-dropdown w-200 ml-3"
                        classNamePrefix="react-select-dropdown"
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        // placeholder={useIntl().formatMessage({
                        //   id: "head.SelectClass",
                        //   defaultMessage: "Select class",
                        // })}
                      />
                    </div>
                  </Col>
                </Row>
              )}
              <TabPanel
                value={activeTab}
                index={STUDENT_LIST_TABS.PENDING_LIST}
              >
                {studentList.length > 0 ? (
                  <>
                    <PendingStudent
                      studentList={studentList}
                      setSorting={setSorting}
                      sortBy={sortBy}
                      sortOrder={sortOrder}
                    />
                    {totalRecord > 10 && (
                      <>
                        <FormControl className="pagelimit">
                          <FormLabel id="demo-row-radio-buttons-group-label">
                            <FormattedMessage
                              id="page_size"
                              defaultMessage="Page Size"
                            />
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={pageLimit}
                            onChange={(e: any) => {
                              setPageLimit(e.target.value);
                              setPageNumber(1);
                            }}
                          >
                            <FormControlLabel
                              value={10}
                              control={<Radio />}
                              label="10"
                            />
                            <FormControlLabel
                              value={20}
                              control={<Radio />}
                              label="20"
                            />
                            <FormControlLabel
                              value={50}
                              control={<Radio />}
                              label="50"
                            />
                          </RadioGroup>
                        </FormControl>
                        <Pagination
                          itemsPerPage={pageLimit}
                          totalPages={totalRecord}
                          onPageChage={onPageChange}
                          page={pageNumber - 1}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <p>{!isLoading && <NoDatFound />}</p>
                )}
              </TabPanel>
              <TabPanel
                value={activeTab}
                index={STUDENT_LIST_TABS.APPROVE_LIST}
              >
                {studentList.length > 0 ? (
                  <>
                    <ApproveStudents
                      studentList={studentList}
                      setSorting={setSorting}
                      sortBy={sortBy}
                      sortOrder={sortOrder}
                    />
                    {totalRecord > 10 && (
                      <>
                        <FormControl className="pagelimit">
                          <FormLabel id="demo-row-radio-buttons-group-label">
                            <FormattedMessage
                              id="page_size"
                              defaultMessage="Page Size"
                            />
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={pageLimit}
                            onChange={(e: any) => {
                              setPageLimit(e.target.value);
                              setPageNumber(1);
                            }}
                          >
                            <FormControlLabel
                              value={10}
                              control={<Radio />}
                              label="10"
                            />
                            <FormControlLabel
                              value={20}
                              control={<Radio />}
                              label="20"
                            />
                            <FormControlLabel
                              value={50}
                              control={<Radio />}
                              label="50"
                            />
                          </RadioGroup>
                        </FormControl>
                        <Pagination
                          itemsPerPage={pageLimit}
                          totalPages={totalRecord}
                          onPageChage={onPageChange}
                          page={pageNumber - 1}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <p>{!isLoading && <NoDatFound />}</p>
                )}
              </TabPanel>
              <TabPanel
                value={activeTab}
                index={STUDENT_LIST_TABS.REJECTED_LIST}
              >
                {studentList.length > 0 ? (
                  <>
                    <RejectedStudents studentList={studentList} />
                    {totalRecord > 10 && (
                      <>
                        <FormControl className="pagelimit">
                          <FormLabel id="demo-row-radio-buttons-group-label">
                            <FormattedMessage
                              id="page_size"
                              defaultMessage="Page Size"
                            />
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={pageLimit}
                            onChange={(e: any) => {
                              setPageLimit(e.target.value);
                              setPageNumber(1);
                            }}
                          >
                            <FormControlLabel
                              value={10}
                              control={<Radio />}
                              label="10"
                            />
                            <FormControlLabel
                              value={20}
                              control={<Radio />}
                              label="20"
                            />
                            <FormControlLabel
                              value={50}
                              control={<Radio />}
                              label="50"
                            />
                          </RadioGroup>
                        </FormControl>
                        <Pagination
                          itemsPerPage={pageLimit}
                          totalPages={totalRecord}
                          onPageChage={onPageChange}
                          page={pageNumber - 1}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <p>{!isLoading && <NoDatFound />}</p>
                )}
              </TabPanel>
              <TabPanel value={activeTab} index={STUDENT_LIST_TABS.MANUAL_LIST}>
                <ManualStudents />
              </TabPanel>
            </Card.Body>
          </Card>
        </section>
      </div>
    </div>
  );
};

export default Students;

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#68439a",
      height: 1,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#68439a",
      BsBorderBottom: "1px solid",
    },
  },
});

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
