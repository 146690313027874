import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Store } from "../../redux/Actions";
import Loader from "../common/Loader";
import Select from "react-select";
import { AddVersionSchema } from "../../util/validationSchema";
import {
  _UploadMedia,
  _UploadTool,
} from "../../service/MediaUpload/classes.service";
import ToolUpload from "../common/MediaUpload/ToolUpload";
import FileUploadSmall from "../common/MediaUpload/FileUploadSmall";
import {
  addIndicatorsVersion,
  editIndicatorsVersion,
  getlibraryList,
  getlibraryVersionList,
} from "../../redux/action";
import { BsTrash } from "react-icons/bs";
import { toast } from "react-toastify";
import { Col, Row, Modal } from "react-bootstrap";
import { useParams } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import CenterLoader from "../common/CenterLoader/CenterLoader";
import draftToHtml from "draftjs-to-html";
import EditorContainer from "../RichTextEditor/Editor";
import { convertToRaw, EditorState, convertFromHTML } from "draft-js";
import { ContentState } from "draft-js";
import { FaTimesCircle } from "react-icons/fa";
import { isHTML } from "../../util/utils";

const AddIndicatorVersionModal = ({
  onModalClose,
  indicatorId,
  selectedIndicatorV,
}: any) => {
  const dispatch = useDispatch();
  const urlParams = useParams();
  const [ButtonClick, setButtonClick] = useState(false);
  const [selectedLibrary, setSelectedlibrary] = useState([]);
  const [selectedLibraryVersion, setSelectedlibraryVersion] = useState(null);
  const [toolFileUploadURL, setToolFileUploadURL] = useState(null);
  const [manualFileUploadURL, setManualFileUploadURL] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const [toolFiles, setToolFiles] = useState<any>(null);
  const [manualFiles, setManualFiles] = useState<any>(null);

  const isAPILoading = useSelector(
    (state: Store) => state.indicatorsReducer.onSaveLoading,
  );
  const libraryList = useSelector(
    (state: Store) => state.libraryReducer.libraryList,
  );
  const libraryVersion = useSelector(
    (state: Store) => state.libraryReducer.libraryVersion,
  );
  const formik = useFormik({
    initialValues: {
      version:
        selectedIndicatorV && Object.keys(selectedIndicatorV).length !== 0
          ? selectedIndicatorV.version
          : "",
      info:
        selectedIndicatorV && Object.keys(selectedIndicatorV).length !== 0
          ? selectedIndicatorV.info
          : "",
      library:
        selectedIndicatorV && Object.keys(selectedIndicatorV).length !== 0
          ? selectedIndicatorV?.library?.id
          : 0,
      libraryVersion:
        selectedIndicatorV && Object.keys(selectedIndicatorV).length !== 0
          ? selectedIndicatorV?.libraryVersion?.id
          : 0,
      download_path:
        selectedIndicatorV && Object.keys(selectedIndicatorV).length !== 0
          ? selectedIndicatorV.download_path
          : "",
      manual_path:
        selectedIndicatorV && Object.keys(selectedIndicatorV).length !== 0
          ? selectedIndicatorV.manual_path
          : "",
      isArchive:
        selectedIndicatorV && Object.keys(selectedIndicatorV).length !== 0
          ? selectedIndicatorV?.isArchive || false
          : false,
      indicator: parseInt(indicatorId),
    },
    validationSchema: AddVersionSchema,
    onSubmit: (values) => {
      if (!ButtonClick) {
        let toolUpload = false;
        if (toolFiles) {
          values.download_path = toolFiles;
          toolUpload = true;
        }

        let manualUpload = false;
        if (manualFiles) {
          values.manual_path = manualFiles;
          manualUpload = true;
        }
        const isUpload: any = { manualUpload, toolUpload };

        if (Object.keys(selectedIndicatorV).length > 0) {
          dispatch(
            editIndicatorsVersion(
              true,
              selectedIndicatorV.id,
              values,
              urlParams.id,
              isUpload,
            ),
          );
        } else {
          dispatch(addIndicatorsVersion(true, values, urlParams.id, isUpload));
        }

        setButtonClick(true);
      }
    },
  });

  useEffect(() => {
    if (
      Object.keys(selectedIndicatorV).length > 0 &&
      selectedIndicatorV.info.length > 0 &&
      selectedIndicatorV.info.trim() !== "<p></p>"
    ) {
      let info = selectedIndicatorV.info;
      if (!isHTML(info)) {
        info = `<p>${info}</p>`;
        info = info.replace(/(\r\n|\n)/g, "</p>\n<p>");
      }

      const blocksFromHTML = convertFromHTML(info);
      const estate = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        ),
      );
      setEditorState(estate);
    }
  }, [selectedIndicatorV]);

  const onDeleteImage = (fieldName: any) => {
    //ajksa
    formik.setFieldValue(fieldName, "");
  };

  useEffect(() => {
    dispatch(getlibraryList(false));
    if (selectedIndicatorV.library) {
      setSelectedlibrary(selectedIndicatorV.library);
    }
    if (selectedIndicatorV.library) {
      setSelectedlibraryVersion(selectedIndicatorV.libraryVersion);
    }
    if (selectedIndicatorV?.download_path) {
      setToolFileUploadURL(selectedIndicatorV?.download_path);
    }
    if (selectedIndicatorV?.manual_path) {
      setManualFileUploadURL(selectedIndicatorV?.manual_path);
    }
  }, [selectedIndicatorV]);

  useEffect(() => {
    if (!isAPILoading && ButtonClick) {
      onModalClose();
      setButtonClick(false);
    }
  }, [isAPILoading]);

  const handleLibraryChange = (selectedOption: any) => {
    setSelectedlibrary(selectedOption);
    setSelectedlibraryVersion(null);
    dispatch(getlibraryVersionList(false, selectedOption.id));
    formik.setFieldValue("library", selectedOption.id);
  };

  const setToolURL = async (acceptedFiles: any) => {
    if (acceptedFiles[0].name) {
      const extention = acceptedFiles[0].name.split(".");

      if (
        extention.length > 0 &&
        (extention[extention.length - 1] === "ex4" ||
          extention[extention.length - 1] === "ex5")
      ) {
        // setFileLoading(true);
        // const { fileUploadResponse, error }: any = await _UploadTool(
        //   acceptedFiles[0] as File
        // );
        // if (!error) {
        //   setToolFileUploadURL(acceptedFiles[0].name);
        //   formik.setFieldValue("download_path", fileUploadResponse);
        // }
        // setFileLoading(false);
        setToolFileUploadURL(acceptedFiles[0].name);
        formik.setFieldValue("download_path", acceptedFiles[0]);
        setToolFiles(acceptedFiles[0]);
      } else {
        toast.error("Only .ex4 and .ex5 files are allowed");
      }
    }
  };

  const setManualURL = async (acceptedFiles: any) => {
    if (acceptedFiles[0].name) {
      // setFileLoading(true);
      // const { fileUploadResponse, error }: any = await _UploadMedia(
      //   acceptedFiles[0] as File
      // );
      // if (!error) {
      //   setManualFileUploadURL(acceptedFiles[0].name);
      //   formik.setFieldValue("manual_path", fileUploadResponse);
      // }
      // setFileLoading(false);
      const extention = acceptedFiles[0].name.split(".");
      if (extention.length > 0 && extention[extention.length - 1] === "pdf") {
        setManualFileUploadURL(acceptedFiles[0].name);
        formik.setFieldValue("manual_path", acceptedFiles[0]);
        setManualFiles(acceptedFiles[0]);
      } else {
        toast.error("Only pdf files are allowed");
      }
    }
  };

  useEffect(() => {
    if (fileLoading) {
      setTimeout(() => {
        setFileLoading(false);
      }, 10000);
    }
  }, [fileLoading]);

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    formik.setFieldValue(
      "info",
      draftToHtml(convertToRaw(editorState.getCurrentContent())),
    );
  };

  const { touched, errors } = formik;
  return (
    <>
      {fileLoading && <CenterLoader />}

      <Modal centered show={true} onHide={() => onModalClose(false)}>
        <Modal.Header>
          <h4 className="modal-title" id="AddCourseLabel">
            {Object.keys(selectedIndicatorV).length <= 0 ? (
              <FormattedMessage
                id="head.Addnewversion"
                defaultMessage="Add new version"
              />
            ) : (
              <FormattedMessage
                id="head.Editnewversion"
                defaultMessage="Edit new version"
              />
            )}
          </h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onModalClose}
          >
            <FaTimesCircle />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="control-label">
              <FormattedMessage
                id="classDetail.Version"
                defaultMessage="Version"
              />
            </label>
            <input
              type="text"
              name="version"
              value={formik.values.version}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control"
            />
            {errors.version && touched.version && (
              <div className="text-error">
                <FormattedMessage
                  id={errors.version.toString()}
                  defaultMessage="This field is required"
                />
              </div>
            )}
          </div>
          <div className="form-group">
            <label className="control-label">
              {" "}
              <FormattedMessage
                id="annousmentmodal.Description"
                defaultMessage="Description"
              />
            </label>
            <EditorContainer
              onEditorStateChange={onEditorStateChange}
              editorState={editorState}
              toolbar={false}
              editable={true}
              height={"150px"}
              overflowY={"scroll"}
              border={"1px solid #dfe7f3"}
            />
            {errors.info && touched.info && (
              <div className="text-error">{errors.info.toString()}</div>
            )}
          </div>

          <Row className="form-row">
            <Col>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="head.library"
                    defaultMessage="Library"
                  />
                </label>
                <Select
                  name="library"
                  value={selectedLibrary}
                  onChange={handleLibraryChange}
                  options={libraryList}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.id}
                  isMulti={false}
                  menuPlacement="top"
                  className="custom-select-dropdown"
                  classNamePrefix="react-select-dropdown"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  // placeholder={useIntl().formatMessage({
                  //   id: "select.Library",
                  //   defaultMessage: "Select Library",
                  // })}
                />
                {errors.library && touched.library && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.library.toString()}
                      defaultMessage="This field is required"
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="head.Libraryversion"
                    defaultMessage="Library version"
                  />
                </label>
                <Select
                  name="libraryVersion"
                  value={selectedLibraryVersion}
                  onChange={(option: any) => {
                    formik.setFieldValue("libraryVersion", option.id);
                    setSelectedlibraryVersion(option);
                  }}
                  options={libraryVersion}
                  getOptionLabel={(option: any) => option.version}
                  getOptionValue={(option: any) => option.id}
                  isMulti={false}
                  menuPlacement="top"
                  className="custom-select-dropdown"
                  classNamePrefix="react-select-dropdown"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  // placeholder={useIntl().formatMessage({
                  //   id: "select.Version",
                  //   defaultMessage: "Select Version",
                  // })}
                />
                {errors.libraryVersion && touched.libraryVersion && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.libraryVersion.toString()}
                      defaultMessage="This field is required"
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <label>
                <FormattedMessage id="is.Archive" defaultMessage="Archive" />:
              </label>
              <input
                type="checkbox"
                className="ml-2"
                checked={formik.values.isArchive}
                onChange={() =>
                  formik.setFieldValue("isArchive", !formik.values.isArchive)
                }
              />
            </Col>
          </Row>
          <div className="form-group">
            <label className="control-label">
              <FormattedMessage id="head.tool" defaultMessage="Tool" />
            </label>
            {toolFileUploadURL === null ? (
              <ToolUpload
                name="download_path"
                url={toolFileUploadURL}
                handleDrop={setToolURL}
              />
            ) : (
              <div>
                <Row className="align-items-center">
                  <Col md={9}>
                    <p className="m-0">{toolFileUploadURL} </p>
                    {/* <img style={{ width: '50%', height: '30%' }} src={toolFileUploadURL} />*/}
                  </Col>
                  <Col md={3} style={{ textAlign: "right" }}>
                    <button
                      className="border-delete-icon delete"
                      onClick={() => {
                        onDeleteImage("download_path");
                        setToolFileUploadURL(null);
                      }}
                    >
                      <BsTrash />
                    </button>
                  </Col>
                </Row>
              </div>
            )}
            {errors.download_path && touched.download_path && (
              <div className="text-error">
                <FormattedMessage
                  id={errors.download_path.toString()}
                  defaultMessage="This field is required"
                />
              </div>
            )}
          </div>
          <div className="form-group">
            <label className="control-label">
              <FormattedMessage id="head.Manual" defaultMessage="Manual" />
            </label>
            {manualFileUploadURL === null ? (
              <FileUploadSmall
                name="manual_path"
                url={manualFileUploadURL}
                handleDrop={setManualURL}
                accept="application/pdf"
              />
            ) : (
              <div>
                <Row className="align-items-center">
                  <Col md={9}>
                    <p className="m-0">{manualFileUploadURL} </p>
                    {/* <img style={{ width: '50%', height: '30%' }} src={manualFileUploadURL} /> */}
                  </Col>
                  <Col md={3} style={{ textAlign: "right" }}>
                    <button
                      className="border-delete-icon delete"
                      onClick={() => {
                        onDeleteImage("manual_path");
                        setManualFileUploadURL(null);
                      }}
                    >
                      <BsTrash />
                    </button>
                  </Col>
                </Row>
              </div>
            )}
            {errors.manual_path && touched.manual_path && (
              <div className="text-error">
                <FormattedMessage
                  id={errors.manual_path.toString()}
                  defaultMessage="This field is required"
                />
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={() => {
              onModalClose();
            }}
          >
            <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
          </button>
          <button
            type="button"
            disabled={isAPILoading}
            className="btn btn-primary"
            style={{ display: "inherit" }}
            onClick={() => formik.handleSubmit()}
            data-dismiss="modal"
          >
            {Object.keys(selectedIndicatorV).length > 0 ? (
              <FormattedMessage id="page.Edit" defaultMessage="Edit" />
            ) : (
              <FormattedMessage id="page.Save" defaultMessage="Save" />
            )}{" "}
            {isAPILoading && <Loader />}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddIndicatorVersionModal;
