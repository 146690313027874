import React, { useEffect, useState } from "react";
import { BsSortDown, BsSortUp } from "react-icons/bs";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import NoDatFound from "../../Components/common/NoDatFound";
import Pagination from "../../Components/common/Paginate/Pagination";
import { getAdminRolesList } from "../../redux/action";
import { Store } from "../../redux/Actions";
// import { VerticalDots } from "../../util/iconStyle";
import { formatDate, sort } from "../../util/utils";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

const RoleList = () => {
  const dispatch = useDispatch();
  const adminRolesLists: any = useSelector(
    (state: Store) => state.adminRolesReducer.adminRoleList,
  ) as any;
  const isLoading = useSelector(
    (state: Store) => state.adminRolesReducer.isLoading,
  );
  const [pageNumber, setPageNumber] = useState(1);
  const totalRecord = useSelector(
    (state: Store) => state.adminRolesReducer.totalRecord,
  ) as any;

  const [adminRolesList, setAdminRolesList] = useState([]);
  const [sortBy, setSortBy] = useState("updatedAt");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );

  useEffect(() => {
    setAdminRolesList(adminRolesLists);
  }, [adminRolesLists]);

  useEffect(() => {
    dispatch(getAdminRolesList(false, pageNumber, pageLimit));
  }, [pageNumber, pageLimit]);

  useEffect(() => {
    // dispatch(getEAList(false, { by: sortBy, order: sortOrder }))
    // dispatch(getEAUnauthorizedList(false))
    const t: any = sort(adminRolesList, sortBy, sortOrder.toLowerCase());
    setAdminRolesList(t);
  }, [sortOrder, sortBy]);

  const setSorting = (prop: any) => {
    if (sortBy === prop) {
      setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
    } else {
      setSortBy(prop);
      setSortOrder("ASC");
    }
  };

  const onPageChange = (pageNum: number) => {
    setPageNumber(pageNum);
  };

  return (
    <>
      <div className="table-responsive">
        {isLoading && <CenterLoader />}
        {adminRolesList.length > 0 ? (
          <>
            {" "}
            <table className="table table-hover m-0">
              <thead>
                <tr>
                  <th onClick={() => setSorting("id")} className="c-p">
                    <FormattedMessage id="table.head.ID" defaultMessage="ID" />
                    {sortBy === "id" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                  <th onClick={() => setSorting("roleName")} className="c-p">
                    <FormattedMessage
                      id="table.head.Role"
                      defaultMessage="Role"
                    />
                    {sortBy === "roleName" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                  <th onClick={() => setSorting("remark")} className="c-p">
                    <FormattedMessage
                      id="table.head.Remark"
                      defaultMessage="Remark"
                    />
                    {sortBy === "remark" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                  <th onClick={() => setSorting("createdAt")} className="c-p">
                    <FormattedMessage
                      id="table.head.Date"
                      defaultMessage="Date"
                    />
                    {sortBy === "createdAt" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                  <th onClick={() => setSorting("isActive")} className="c-p">
                    <FormattedMessage
                      id="table.head.Active"
                      defaultMessage="Active"
                    />
                    {sortBy === "isActive" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                  <th onClick={() => setSorting("isClassRole")} className="c-p">
                    <FormattedMessage
                      id="table.head.ClassRole"
                      defaultMessage="Class Role"
                    />
                    {sortBy === "isClassRole" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                  <th onClick={() => setSorting("isEARole")} className="c-p">
                    <FormattedMessage
                      id="table.head.EARole"
                      defaultMessage="EA Role"
                    />
                    {sortBy === "isEARole" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                </tr>
              </thead>
              <tbody>
                {adminRolesList.length &&
                  adminRolesList.map((i: any) => (
                    <tr>
                      <td>{i.id}</td>
                      <td>{i.roleName}</td>
                      <td>{i.remark}</td>
                      <td>{formatDate(i.createdAt, "DD-MM-YYYY")}</td>
                      <td>{i.isActive ? "Yes" : "No"}</td>
                      <td>{i.isClassRole ? "Yes" : "No"}</td>
                      <td>{i.isEARole ? "Yes" : "No"}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        ) : (
          <p>{!isLoading && <NoDatFound />}</p>
        )}
      </div>
      {totalRecord > 10 && adminRolesList.length > 0 && (
        <>
          <FormControl className="pagelimit">
            <FormLabel id="demo-row-radio-buttons-group-label">
              <FormattedMessage id="page_size" defaultMessage="Page Size" />
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={pageLimit}
              onChange={(e: any) => {
                setPageLimit(e.target.value);
                setPageNumber(1);
              }}
            >
              <FormControlLabel value={10} control={<Radio />} label="10" />
              <FormControlLabel value={20} control={<Radio />} label="20" />
              <FormControlLabel value={50} control={<Radio />} label="50" />
            </RadioGroup>
          </FormControl>
          <Pagination
            itemsPerPage={pageLimit}
            totalPages={totalRecord}
            onPageChage={onPageChange}
            page={pageNumber - 1}
          />
        </>
      )}
    </>
  );
};

export default RoleList;
