import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Card, Col, Row, Button } from "react-bootstrap";
import { getExpressWayRecordList } from "../../redux/action";
import { Store } from "../../redux/Actions";
import { formatDateMM } from "../../util/utils";
import NoDatFound from "../../Components/common/NoDatFound";
// import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import Pagination from "../../Components/common/Paginate/Pagination";
import { FormattedMessage } from "react-intl";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

const RecordsList = () => {
  const dispatch = useDispatch();
  const recordList = useSelector(
    (state: Store) => state.expressWayReducer.RecordList,
  ) as any;
  const isLoading = useSelector(
    (state: Store) => state.expressWayReducer.Loading.isLoading,
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );

  const totalRecord = useSelector(
    (state: Store) => state.expressWayReducer.totalRecord,
  ) as any;

  useEffect(() => {
    dispatch(getExpressWayRecordList(false, pageNumber, pageLimit));
  }, [pageNumber, pageLimit]);

  const onPageChange = (pageNum: number) => {
    setPageNumber(pageNum);
  };

  return (
    <>
      <div className="table-responsive">
        {recordList && recordList.length ? (
          <>
            {" "}
            <table className="table table-hover m-0">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage
                      id="table.head.MID"
                      defaultMessage="MID"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.Remark"
                      defaultMessage="Remark"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.ApprovedBy"
                      defaultMessage="Approved By"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.Date"
                      defaultMessage="Date"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {recordList &&
                  recordList.length &&
                  recordList.map((i: any) => (
                    <tr>
                      <td>{i.id}</td>
                      <td
                        dangerouslySetInnerHTML={{
                          __html: i?.remarks?.split(",").join("<br/>") || "--",
                        }}
                      />
                      <td>
                        {i?.createdBy?.englishFirstName !== undefined
                          ? i?.createdBy?.englishFirstName
                          : "-"}
                        {i?.createdBy?.englishNickName
                          ? ` ${i?.createdBy?.englishNickName} `
                          : " "}
                        {i?.createdBy?.englishLastName !== undefined
                          ? i?.createdBy?.englishLastName
                          : "-"}
                      </td>
                      <td>
                        {i.createdAt
                          ? formatDateMM(i.createdAt, "YYYY-MM-DD")
                          : "-"}{" "}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        ) : (
          <p>{!isLoading && <NoDatFound />}</p>
        )}
      </div>
      {totalRecord > 10 && recordList.length && (
        <>
          <FormControl className="pagelimit">
            <FormLabel id="demo-row-radio-buttons-group-label">
              <FormattedMessage id="page_size" defaultMessage="Page Size" />
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={pageLimit}
              onChange={(e: any) => {
                setPageLimit(e.target.value);
                setPageNumber(1);
              }}
            >
              <FormControlLabel value={10} control={<Radio />} label="10" />
              <FormControlLabel value={20} control={<Radio />} label="20" />
              <FormControlLabel value={50} control={<Radio />} label="50" />
            </RadioGroup>
          </FormControl>
          <Pagination
            itemsPerPage={pageLimit}
            totalPages={totalRecord}
            onPageChage={onPageChange}
            page={pageNumber - 1}
          />
        </>
      )}
    </>
  );
};

export default RecordsList;
