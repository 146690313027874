import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
// import { BsChevronLeft } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import noImage from "../../assets/img/noImage.jpeg";
import "../Classes/classes.css";
import { getAdminRolesList, getLibraryDetails } from "../../redux/action";
import { Store } from "../../redux/Actions";
// import Products from "../Classes/Product";
import LibraryVersion from "./LibraryVersion";
import { FormattedMessage } from "react-intl";
import { BsChevronLeft } from "react-icons/bs";

const LibraryDetail = () => {
  const urlParams = useParams();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(3);
  const [belong, setBelong] = useState<any>([]);

  const indicatorsDetails: any = useSelector(
    (state: Store) => state.indicatorsReducer.indicatorsDetails,
  ) as any;
  // const products: any = useSelector(
  //   (state: Store) => state.classReducer.products
  // ) as any;
  const adminRoleList: any = useSelector(
    (state: Store) => state.adminRolesReducer.adminRoleList,
  ) as any;

  useEffect(() => {
    const { id } = urlParams;
    if (id && parseInt(id) > 0) {
      dispatch(getLibraryDetails(false, parseInt(id)));
      dispatch(getAdminRolesList(false));
    }
  }, []);

  useEffect(() => {
    if (indicatorsDetails) {
      const a = (indicatorsDetails?.belong || []).map((i: any) => i.id);
      setBelong(a);
    }
  }, [indicatorsDetails]);

  const setAciveTab = (tab: number) => {
    setActiveTab(tab);
  };

  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                <FormattedMessage
                  id="head.AdminLibrarysDetails"
                  defaultMessage="Admin Librarys Details"
                />
              </h1>
            </div>
            <div className="m-l-10 common-right-button">
              <Link to="/library" className="btn btn-secondary">
                <i className="back-button">
                  <BsChevronLeft /> &nbsp;
                </i>
                <FormattedMessage id="page.back" defaultMessage="Back" />
              </Link>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          <Card>
            <Card.Body className="p-0">
              <ul className="nav nav-tabs primary-tabs m-0">
                <li
                  className="nav-item"
                  role="presentation"
                  onClick={() => setAciveTab(3)}
                >
                  <a
                    href="#tab-2"
                    className={
                      activeTab === 3 ? "nav-link active show" : "nav-link"
                    }
                    data-toggle="tab"
                    aria-expanded="true"
                  >
                    <FormattedMessage
                      id="classDetail.Version"
                      defaultMessage="Version"
                    />
                  </a>
                </li>
              </ul>
              <Card.Text>
                {activeTab === 1 && (
                  <div
                    className={
                      activeTab === 1
                        ? "tab-pane fadeIn active"
                        : "tab-pane fadeIn"
                    }
                    id="tab-1"
                  >
                    <div className="p-4">
                      <Row>
                        <Col sm={6} md={6} lg={4}>
                          {indicatorsDetails.image_path ? (
                            <img
                              style={{ minWidth: "100%" }}
                              src={indicatorsDetails.image_path}
                            />
                          ) : (
                            <i>
                              {" "}
                              <img
                                src={noImage}
                                style={{ opacity: ".3", height: "90px" }}
                              />{" "}
                            </i>
                          )}
                        </Col>
                        <Col sm={7} md={7} lg={8}>
                          {Object.keys(indicatorsDetails).length > 0 && (
                            <Table borderless>
                              <tbody>
                                <tr>
                                  <td className="details-td head-td">
                                    <FormattedMessage
                                      id="table.head.ID"
                                      defaultMessage="ID"
                                    />
                                    :{" "}
                                  </td>
                                  <td
                                    className="details-td"
                                    style={{ textAlign: "left" }}
                                  >
                                    {indicatorsDetails.id}
                                  </td>
                                </tr>
                                <tr style={{ height: "20px" }}></tr>

                                <tr>
                                  <td className="head-td details-td">
                                    <FormattedMessage
                                      id="table.head.Name"
                                      defaultMessage="Name"
                                    />
                                    :{" "}
                                  </td>
                                  <td className="details-td">
                                    {indicatorsDetails?.name || "N/A"}
                                  </td>
                                </tr>
                                <tr style={{ height: "20px" }}></tr>

                                <tr>
                                  <td className="head-td details-td">
                                    <FormattedMessage
                                      id="table.head.info"
                                      defaultMessage="Info"
                                    />
                                    :{" "}
                                  </td>
                                  <td className="details-td">
                                    {indicatorsDetails.info}
                                  </td>
                                </tr>
                                <tr style={{ height: "20px" }}></tr>

                                <tr>
                                  <td className="head-td details-td">
                                    <FormattedMessage
                                      id="table.head.Status"
                                      defaultMessage="Status"
                                    />
                                    :{" "}
                                  </td>
                                  <td className="details-td">
                                    {indicatorsDetails.isActive
                                      ? "Active"
                                      : "InActive"}
                                  </td>
                                </tr>

                                <tr style={{ height: "20px" }}></tr>

                                <tr>
                                  <td className="head-td details-td">
                                    <FormattedMessage
                                      id="table.head.Belong"
                                      defaultMessage="Belong"
                                    />
                                    :{" "}
                                  </td>
                                  <td className="details-td">
                                    {adminRoleList.map((r: any) => (
                                      <div className="custom-control custom-checkbox">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="defaultUncheckedDisabled"
                                          checked={belong.includes(r.id)}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="defaultUncheckedDisabled"
                                        >
                                          {r.roleName}
                                        </label>
                                      </div>
                                    ))}
                                  </td>
                                </tr>
                                <tr style={{ height: "20px" }}></tr>

                                <tr>
                                  <td className="head-td details-td">
                                    <FormattedMessage
                                      id="table.head.originalPrice"
                                      defaultMessage="Original Price"
                                    />
                                    :{" "}
                                  </td>
                                  <td className="details-td">
                                    USD$ {indicatorsDetails.originalPrice}
                                  </td>
                                </tr>
                                <tr style={{ height: "20px" }}></tr>

                                <tr>
                                  <td className="head-td details-td">
                                    <FormattedMessage
                                      id="table.head.sellingPrice"
                                      defaultMessage="Selling  Price"
                                    />
                                    :{" "}
                                  </td>
                                  <td className="details-td">
                                    USD$ {indicatorsDetails.sellingPrice}
                                  </td>
                                </tr>
                                <tr style={{ height: "20px" }}></tr>

                                <tr>
                                  <td className="head-td details-td">
                                    <FormattedMessage
                                      id="table.head.info"
                                      defaultMessage="info"
                                    />
                                    :{" "}
                                  </td>
                                  <td className="details-td">
                                    {indicatorsDetails.info}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
                {activeTab === 2 && (
                  <div
                    className={
                      activeTab === 2
                        ? "tab-pane fadeIn active"
                        : "tab-pane fadeIn"
                    }
                    id="tab-2"
                  >
                    <Row>
                      <Col></Col>
                    </Row>
                  </div>
                )}
                {activeTab === 3 && (
                  <div
                    className={
                      activeTab === 3
                        ? "tab-pane fadeIn active"
                        : "tab-pane fadeIn"
                    }
                    id="tab-2"
                  >
                    <LibraryVersion />
                  </div>
                )}
              </Card.Text>
            </Card.Body>
          </Card>
        </section>
      </div>
    </div>
  );
};
export default LibraryDetail;
