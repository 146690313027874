import axios from "axios";
import moment from "moment-timezone";
import { saveAs } from "file-saver";
import * as _ from "lodash";
import secureLocalStorage from "react-secure-storage";
import { CSSProperties } from "react";

export const getToken = (key: string) => {
  let v: any = "";
  v = secureLocalStorage.getItem(key);
  if (v !== null) {
    return v.toString();
  }
  return v;
};

export const valiadteToken = () => {
  const token = getToken("login-auth-token");
  const time = parseInt(getToken("exp") || "0");
  const ctime = new Date().getTime();
  const isLoggedIn = getToken("isLoggedIn");
  if (time > ctime) {
    if (token && isLoggedIn) {
      return true;
    }
  }
  return false;
};

export const setToken = (key: string, token: string) =>
  Promise.resolve().then(() => {
    secureLocalStorage.setItem(key, token);
  });

export const setSort = (sortOrder: string, sortBy: string) => {
  sessionStorage.setItem("sortOrder", sortOrder);
  sessionStorage.setItem("sortBy", sortBy);
};

export const getPostData = async () => {
  const response = await axios.get(
    "https://jsonplaceholder.typicode.com/posts",
  );
  const { data } = response;
  return data;
};

export const getCurrentDatetimeWithTimezone = (timezone = "Asia/Hong_Kong") => {
  return moment().tz(timezone).format("YYYY-MM-DD");
};

export const customFormatDatetimeWithLocale = (
  datetimeString: string,
  locale: string,
  needTime = false,
): string => {
  const datetime = new Date(datetimeString);
  const year = datetime.getFullYear();
  const month = datetime.getMonth() + 1; // Month is zero-indexed
  const day = datetime.getDate();

  const paddedYear: string = String(year).padStart(2, "0");
  const paddedMonth: string = String(month).padStart(2, "0");
  const paddedDay: string = String(day).padStart(2, "0");

  let s;
  if (locale === "zh") {
    s = `${paddedYear}年${paddedMonth}月${paddedDay}日`;
  } else {
    s = `${paddedYear}-${paddedMonth}-${paddedDay}`;
  }

  if (needTime) {
    const hour = datetime.getHours();
    const minute = datetime.getMinutes();

    const paddedHour: string = String(hour).padStart(2, "0");
    const paddedMinute: string = String(minute).padStart(2, "0");

    s += ` ${paddedHour}:${paddedMinute}`;
  }

  return s;
};

export const formatDate = (dateJson: any, format = "YYYY-MM-DD") => {
  if (dateJson === "0000-00-00") {
    return "0001-01-01";
  }
  const date = new Date(dateJson);

  return moment(date).subtract(2.5, "h").format(format);
};

export const formatDateTime = (dateJson: any) => {
  const date = new Date(dateJson);
  const format1 = "YYYY-MM-DD HH:mm:ss";
  return moment(date).subtract(2.5, "h").format(format1);
};

export const getTimeofDayInLetter = (dateTime: any) => {
  //----logic----
  const returnValue: Array<string> = [];
  for (const i of dateTime) {
    const value = formatDate(i, "YYYY-MM-DDTHH:mm:ss.SSSZ");
    const day = new Date(value);
    const currentHour: number = day.getHours();

    if (currentHour >= 0 && currentHour <= 2) {
      returnValue.push("A");
    } else if (currentHour > 2 && currentHour <= 6) {
      returnValue.push("E");
    } else if (currentHour > 5 && currentHour <= 9) {
      returnValue.push("N");
    } else {
      returnValue.push("M");
    }
  }

  return returnValue.toString();
};

export const isEmptyObject = (obj: any) => {
  return Object.keys(obj).length === 0;
};

export const getDeviceType = (type: number) => {
  const device_type = type === 1 ? "Android" : "iOS";
  return device_type;
};

export const formatDateMM = (dateJson: any, format = "MM-DD-YYYY") => {
  const date = new Date(dateJson);
  return moment(date).subtract(2.5, "h").format(format);
};

export const formatDateDiff = (startDate: any, endDate: any) => {
  return moment(startDate).diff(moment(endDate), "days");
};

export const formatTime = (dateJson: any) => {
  const date = new Date(dateJson);
  const format1 = "hh:mma z";
  return moment(date).subtract(2.5, "h").format(format1);
};

export function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function arrayToStr(arr: any, field: string) {
  const name: any = [];
  if (arr) {
    arr.map((i: any) => {
      if (Object.prototype.hasOwnProperty.call(i, field)) {
        name.push(i[field]);
      }
    });
    return name.join(",");
  }
  return "";
}

export function getFileName(fileURL: any) {
  try {
    if (fileURL && fileURL.length > 0) {
      let filename = fileURL.substring(
        fileURL.lastIndexOf("/") + 1,
        fileURL.length,
      );
      let splitFileName = filename.split("-");

      if (splitFileName.length > 1) {
        const hexRegex = /^[0-9A-Fa-f]{20}$/g;
        const hex = splitFileName[0].match(hexRegex);
        if (hex) {
          splitFileName = splitFileName.slice(1, splitFileName.length);
        }
      }

      filename = splitFileName.join("-");

      filename = filename.replace(/\+/g, "%20");
      filename = filename.replace(/ /g, "%20");
      filename = decodeURIComponent(filename);
      return filename;
    }
  } catch (error) {
    return "";
  }
}

export function get_url_extension(fileName: string) {
  if (fileName != undefined && fileName != null && fileName != "") {
    const extension = fileName.split(".").pop();
    return extension;
  }
  return "";
}

export function get_url_extensions(url: any) {
  if (url != undefined && url != null && url != "") {
    const fileName: string = getFileName(url);
    const extension = fileName.split(".").pop();
    return extension;
  }
  return "";
}

export function getFileDetail(arrURL: any) {
  const tmp: any = [];
  if (arrURL.length > 0) {
    arrURL.map((i: any) => {
      tmp.push({
        path: i,
        name: getFileName(i),
        extention: get_url_extension(getFileName(i)),
      });
    });
    return tmp;
  }
  return [];
}

export const setPage = (pageNum: number) => {
  secureLocalStorage.setItem("currentPage", pageNum.toString());
  return 0;
};

export const getCurrentPage = () => {
  if (
    secureLocalStorage.getItem("currentPage") === undefined ||
    secureLocalStorage.getItem("currentPage") === null
  ) {
    return 1;
  } else {
    const page: string =
      secureLocalStorage.getItem("currentPage")?.toString() || "0";
    return parseInt(page);
  }
};

export const getAPIEndPoint = (
  endPoint: string,
  pageNumber: number,
  isSort = false,
  pageLimit: number,
) => {
  if (pageNumber > 0 && !isSort) {
    return `${endPoint}?page=${pageNumber}&perPage=${pageLimit}`;
  } else if (pageNumber > 0 && isSort) {
    return `${endPoint}&page=${pageNumber}&perPage=${pageLimit}`;
  }
  return endPoint;
};

export const toBase64 = (arr: any) => {
  const a = btoa(
    arr.reduce((data: any, byte: any) => data + String.fromCharCode(byte), ""),
  );
  return "data:image/jpg;base64," + atob(a);
};

export const saveFile = (url: any) => {
  const fileName = getFileName(url);
  saveAs(url, fileName);
};

export const disablePastDate = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
};

export const getPage = () => {
  return sessionStorage.getItem("page") || "1";
};

export const sort = (lst: any, by: string, order = "asc") => {
  if (order === "asc") {
    return _.orderBy(lst, [by], ["asc"]);
  }
  if (order === "desc") {
    return _.orderBy(lst, [by], ["desc"]);
  }
};

export const sorting = (lst: any, sortBy: string, sortOrder: string) => {
  const t: any = sort(lst, sortBy, sortOrder.toLowerCase());
  return t;
};

export const isPositiveNumbersOnly = (e: any, value: any) => {
  if (isNaN(value)) {
    return false;
  }
  return true;
};

export function formatDateKH(newDate: any, addTime = 2.5) {
  const momentDate: any = moment(newDate).add(addTime, "h");
  const date: any = new Date(momentDate);
  let month = "" + (date.getMonth() + 1),
    day = "" + date.getDate();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [date.getFullYear(), month, day].join("-");
}

export const getTextFromHtmlRowText = (eState: any) => {
  let text = eState.toString().replaceAll("&nbsp;", "");
  text = text.replaceAll("<p>", "");
  text = text.replaceAll("</p>", "");
  text = text.replaceAll("<br>", "");
  text = text.replaceAll("\n", "");

  text = text.trim();
  return text;
};

export const getJsonDate = () => {
  return new Date().getTime();
};

export const isHTML = (str: string) => {
  const doc = new DOMParser().parseFromString(str, "text/html");
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
};

export const getTicketGroupNameAbbreviation = (s?: string) => {
  if (!s) {
    return "";
  }

  return s
    .split(" ")
    .filter((word) => word)
    .map((word) => word[0].toLocaleUpperCase())
    .join("");
};

export const getReporterTextClassName = (isVip: boolean): string => {
  if (!isVip) {
    return "";
  }

  return "glowing-text";
};
