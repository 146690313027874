import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  addActivity,
  addAdmin,
  editActivityDetails,
  getAdminRolesList,
  getClasses,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import Loader from "../common/Loader";
import Select from "react-select";
import { Row, Col, Table, Modal, Form, Button } from "react-bootstrap";
import { ROLES_POINT, UserType } from "../../util/constants/UserGroup";
import {
  AddActivitySchema,
  getAddAdminProfileSchema,
} from "../../util/validationSchema";
import { FormattedMessage, useIntl } from "react-intl";
import PhoneInput from "react-phone-input-2";
import { _UploadMedia } from "../../service/MediaUpload/classes.service";
import NoImage from "../../assets/img/image.png";
import { FaTimesCircle } from "react-icons/fa";

const AddAdminModal = ({ onModalClose, activity }: any) => {
  const dispatch = useDispatch();
  const isAPILoading = useSelector(
    (state: Store) => state.announcementsReducer.onSaveLoading,
  );
  const [ButtonClick, setButtonClick] = useState(false);
  const [selectedClass, setselectedClass] = useState({});
  const [validationSchema, setValidationSchema] = useState<any>();
  const [fileUploadURL, setFileUploadURL] = useState<any>("");
  const [selectedUserType, setSelectedUserType] = useState({
    id: "default",
    name: useIntl().formatMessage({
      id: "addadminModel.default",
    }),
  });

  // useEffect(()=>{setValidationSchema(getEditProfileSchema(11))},[])

  const [points, setPoints] = useState<any>([]);
  const classes = useSelector(
    (state: Store) => state.classReducer.classes,
  ) as any;
  const adminRoleList = useSelector(
    (state: Store) => state.adminRolesReducer.adminRoleList,
  ) as Array<Object>;
  const [error, setFileError] = useState("");
  const [fileLoading, setFileLoading] = useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      englishFirstName:
        Object.keys(activity).length > 0 ? activity.englishFirstName : "",
      englishLastName:
        Object.keys(activity).length > 0 ? activity.englishFirstName : "",
      chineseFirstName:
        Object.keys(activity).length > 0 ? activity.chineseFirstName : "",
      chineseLastName:
        Object.keys(activity).length > 0 ? activity.chineseLastName : "",
      address: Object.keys(activity).length > 0 ? activity?.address : "",
      email: Object.keys(activity).length > 0 ? "" : "",
      phoneNumber: Object.keys(activity).length > 0 ? activity.phoneNumber : "",
      password: Object.keys(activity).length > 0 ? "" : "",
      avatar: Object.keys(activity).length > 0 ? activity.avatar : "",
      DOB: Object.keys(activity).length > 0 ? activity.DOB : "",
      userType: Object.keys(activity).length > 0 ? "" : "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      //   if (Object.keys(activity).length <= 0) {

      dispatch(
        addAdmin({
          ...values,
          phoneNumber: values.phoneNumber.slice(values.dialCode.length),
        }),
      );
      //   }
      //   else {
      //     dispatch(editActivityDetails(true, values, activity.id));
      //   }
      onModalClose();
    },
  });
  const handleDrop = async (acceptedFiles: any) => {
    setFileLoading(true);

    const { fileUploadResponse, error, get }: any = await _UploadMedia(
      acceptedFiles[0] as File,
    );
    if (!error) {
      formik.setFieldValue("avatar", fileUploadResponse);
      setFileUploadURL(fileUploadResponse);
    }
    setFileLoading(false);
  };
  const onDeleteImage = async () => {
    console.log("hello");
    formik.setFieldValue("avatar", "");
    setFileUploadURL(undefined);
    setFileError("");
  };
  useEffect(() => {
    console.log(formik.values);
  }, []);
  useEffect(() => {
    if (!isAPILoading && ButtonClick) {
      onModalClose();
      setButtonClick(false);
    }
  }, [isAPILoading]);
  const handleOnChange = async (
    value: any,
    data: any,
    event: any,
    formattedValue: any,
  ) => {
    console.log(data.dialCode);
    let s = data?.format?.match(/\./g).length || 11; // output 60
    if (s > 15) {
      s = 15;
    }
    const v = getAddAdminProfileSchema(s);
    await setValidationSchema(v);

    formik.setFieldValue("phoneNumber", value);
    formik.setFieldValue("countryCode", data.countryCode);
    formik.setFieldValue("dialCode", data.dialCode);
  };
  useEffect(() => {
    setValidationSchema(getAddAdminProfileSchema(11));
  }, []);
  useEffect(() => {
    dispatch(getClasses(false, 0, { by: "createdAt", order: "ASC" }));
    dispatch(getAdminRolesList(false));
    // if (activity !== {}) {
    //   setselectedClass(activity.class);
    // }
  }, [activity]);

  useEffect(() => {
    const p: any = [];
    if (adminRoleList) {
      adminRoleList.map((i: any) => {
        const roln = i.roleName.toUpperCase();
        p.push({
          name: i.roleName,
          id: i.id,
          points: ROLES_POINT[roln],
          remarks: "default points",
        });
      });

      setPoints(p);
    }
  }, [adminRoleList]);

  const validateSelectedFile = (file: any) => {
    const MIN_FILE_SIZE = 10; // 1MB
    const MAX_FILE_SIZE = 2058; // 5MB

    if (!file) {
      setFileError("Please choose a file");
      // setIsSuccess(false);
      return false;
    }

    const fileSizeKiloBytes = file.size / 1024;

    if (fileSizeKiloBytes < MIN_FILE_SIZE) {
      setFileError("File size is less than minimum limit");
      // setIsSuccess(false);
      return false;
    }
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setFileError(
        "File size is greater than maximum limit file size should be less than or equal to 2 MB",
      );
      formik.setFieldError(
        "avatar",
        "File size is greater than maximum limit file size should be less than or equal to 2 MB",
      );
      // setIsSuccess(false);
      return false;
    }
    // setIsSuccess(true);
    return true;
  };
  const { touched, errors } = formik;
  return (
    <Modal centered show={true} onHide={() => onModalClose(false)}>
      <Modal.Header>
        <h4 className="modal-title" id="AddCourseLabel">
          {Object.keys(activity).length <= 0 ? (
            <FormattedMessage id="Admin.AddAdmin" defaultMessage="Add Admin" />
          ) : (
            <FormattedMessage
              id="head.EditActivity"
              defaultMessage="Edit Activity"
            />
          )}{" "}
        </h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onModalClose}
        >
          <FaTimesCircle />
        </button>
      </Modal.Header>
      <Form autoComplete="off">
        <Modal.Body className="overflow-x-hidden">
          <Row className="g-3">
            <Col xs={12} md={{ span: 4, offset: 1, order: 2 }}>
              <Form.Group controlId="formBasicProfilePhoto">
                <Form.Label className="d-block">Profile Photo</Form.Label>
                <div
                  className="fileinput text-center fileinput-new"
                  data-provides="fileinput"
                >
                  <div className="btn-file">
                    <>
                      <div
                        className="thumbnail fileinput-new rounded"
                        style={{ height: "173px", width: "173px" }}
                      >
                        <img
                          src={fileUploadURL ? fileUploadURL : NoImage}
                          alt="Image"
                        />
                      </div>
                      {
                        (errors.avatar || touched.avatar) && (
                          // (!isSuccess && (
                          <Form.Text className="text-danger">
                            <> {error}</>
                          </Form.Text>
                        )
                        // )
                        // )
                      }
                      <div className="clearfix"></div>
                      <Button
                        variant="outline-primary"
                        className="fileinput-new btn-file w-100 upload-btn mt-2"
                        size="sm"
                      >
                        Upload Photo
                      </Button>

                      <input
                        type="hidden"
                        value=""
                        name="..."
                        accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG"
                      />
                      <input
                        type="hidden"
                        value=""
                        name="Users[image]"
                        accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG"
                      />
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG"
                        onChange={(e) => {
                          handleDrop(e.target.files);
                        }}
                      />
                      <div
                        className="fileinput-preview fileinput-exists thumbnail uploaded-user-image rounded"
                        // height={173}
                        // width="173"
                      ></div>
                    </>
                  </div>

                  <div className="text-center mt-2">
                    <Button
                      variant="link"
                      size="sm"
                      className="fileinput-exists0 remove-btn"
                      type="button"
                      onClick={() => {
                        onDeleteImage();
                      }}
                    >
                      Remove Photo
                    </Button>
                  </div>
                </div>
              </Form.Group>
            </Col>
            <Col xs={12} md={{ span: 7, order: 1 }}>
              <Form.Group className="mb-4">
                <Select
                  name="userType"
                  value={selectedUserType}
                  onChange={(option: any) => {
                    formik.setFieldValue("userType", option.id);
                    setSelectedUserType(option);
                  }}
                  onBlur={() => formik.setFieldTouched("userType", true)}
                  options={UserType}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.id}
                  className="custom-select-dropdown"
                  classNamePrefix="react-select-dropdown"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
                {errors.userType && touched.userType && (
                  <div className="text-error">
                    {" "}
                    <FormattedMessage id="addadmin.userType" />
                  </div>
                )}
              </Form.Group>
              <Form.Group className="mb-4" controlId="formBasicEmail">
                {/* <Form.Label>First Name</Form.Label> */}
                <Form.Control
                  type="text"
                  size="lg"
                  name="englishFirstName"
                  placeholder={useIntl().formatMessage({
                    id: "admin.englishfirstname",
                    defaultMessage: "English First name",
                  })}
                  defaultValue={formik.values.englishFirstName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  autoComplete="none"
                />
                {errors.englishFirstName && touched.englishFirstName && (
                  <div className="text-error">
                    {" "}
                    <FormattedMessage
                      id={errors.englishFirstName.toString()}
                      defaultMessage="English first name is required"
                    />
                  </div>
                )}
              </Form.Group>
              <Form.Group className="mb-4" controlId="formBasicEmail">
                {/* <Form.Label>Last Name</Form.Label> */}
                <Form.Control
                  type="text"
                  size="lg"
                  name="englishLastName"
                  placeholder={useIntl().formatMessage({
                    id: "admin.englishlastname",
                    defaultMessage: "English Last name",
                  })}
                  defaultValue={formik.values.englishLastName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {errors.englishLastName && touched.englishLastName && (
                  <div className="text-error">
                    {" "}
                    <FormattedMessage
                      id={errors.englishLastName.toString()}
                      defaultMessage="English last name is required"
                    />
                  </div>
                )}
              </Form.Group>
              <Form.Group className="mb-4" controlId="formBasicEmail">
                {/* <Form.Label>Last Name</Form.Label> */}
                <Form.Control
                  type="text"
                  size="lg"
                  name="chineseFirstName"
                  placeholder={useIntl().formatMessage({
                    id: "admin.chinesefirstname",
                    defaultMessage: "Chinese first name",
                  })}
                  defaultValue={formik.values.chineseFirstName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {errors.chineseFirstName && touched.chineseFirstName && (
                  <div className="text-error">
                    {" "}
                    <FormattedMessage
                      id={errors.chineseFirstName.toString()}
                      defaultMessage="Chinese first name is required"
                    />
                  </div>
                )}
              </Form.Group>
              <Form.Group className="mb-4" controlId="formBasicEmail">
                {/* <Form.Label>Last Name</Form.Label> */}
                <Form.Control
                  type="text"
                  size="lg"
                  name="chineseLastName"
                  placeholder={useIntl().formatMessage({
                    id: "admin.chineselastname",
                    defaultMessage: "Chinese Last name",
                  })}
                  defaultValue={formik.values.chineseLastName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {errors.chineseLastName && touched.chineseLastName && (
                  <div className="text-error">
                    {" "}
                    <FormattedMessage
                      id={errors.chineseLastName.toString()}
                      defaultMessage="Chinese last name is required"
                    />
                  </div>
                )}
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Control
                  // type="textarea"
                  as="textarea"
                  size="lg"
                  name="address"
                  defaultValue={formik.values.address}
                  placeholder={useIntl().formatMessage({
                    id: "admin.address",
                    defaultMessage: "Address",
                  })}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  autoComplete="Address"
                />
                {errors.address && touched.address && (
                  <div className="text-error">
                    {" "}
                    <FormattedMessage
                      id={errors.address.toString()}
                      defaultMessage="Address is required"
                    />
                  </div>
                )}
              </Form.Group>
              {/* <Form.Label>Email</Form.Label> */}
              <Form.Group className="mb-4">
                <Form.Control
                  type="email"
                  size="lg"
                  name="email"
                  value={formik.values.email}
                  placeholder={useIntl().formatMessage({
                    id: "admin.email",
                    defaultMessage: "Email",
                  })}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  autoComplete="off"
                />
                {errors.email && touched.email && (
                  <div className="text-error">
                    {" "}
                    <FormattedMessage
                      id={errors.email.toString()}
                      defaultMessage="Email is required"
                    />
                  </div>
                )}
              </Form.Group>
              <Form.Group className="mb-4">
                {/* <Form.Label>Last Name</Form.Label> */}
                <Form.Control
                  type="password"
                  size="lg"
                  name="password"
                  autoComplete="new-password"
                  placeholder={useIntl().formatMessage({
                    id: "admin.password",
                    defaultMessage: "Password",
                  })}
                  defaultValue={formik.values.password}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {errors.password && touched.password && (
                  <div className="text-error">
                    {" "}
                    <FormattedMessage
                      id={errors.password.toString()}
                      defaultMessage="Password is required"
                    />
                  </div>
                )}
              </Form.Group>
              <Form.Group
                className="mb-4 phone-input"
                controlId="formBasicEmail"
              >
                <Form.Label>Phone Number</Form.Label>

                <PhoneInput
                  country={"hk"}
                  // name="phoneNumber"
                  value={formik.values.phoneNumber}
                  onChange={handleOnChange}
                />
                {errors.phoneNumber && touched.phoneNumber && (
                  <div className="text-error">
                    {" "}
                    <FormattedMessage
                      id={errors.phoneNumber.toString()}
                      defaultMessage="require"
                    />
                  </div>
                )}
              </Form.Group>
              <Form.Group>
                <input
                  type="date"
                  name="DOB"
                  // min={disablePastDate()}
                  value={formik.values.DOB}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                />
                {errors.DOB && touched.DOB && (
                  <div className="text-error">
                    {" "}
                    <FormattedMessage
                      id={errors?.DOB.toString()}
                      defaultMessage="require"
                    />
                  </div>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={onModalClose}
          >
            {" "}
            <FormattedMessage id="classmodal.Cancel" defaultMessage="Cancel" />
          </button>
          <button
            type="button"
            className="btn btn-primary"
            style={{ display: "inherit" }}
            onClick={() => formik.handleSubmit()}
            data-dismiss="modal"
          >
            {Object.keys(activity).length <= 0 ? (
              <FormattedMessage id="page.Add" defaultMessage="Add" />
            ) : (
              <FormattedMessage id="page.Edit" defaultMessage="Edit" />
            )}
            {isAPILoading && <Loader />}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddAdminModal;
