import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { CreateGemsAdvertisementDto } from "../schemas/createGemsAdvertisementSchema";
import { GemsAdvertisementResponse } from "../types";
import { customFetch } from "../../../util/axios";

interface CreateGemsAdvertisementResponse {
  gemsAdvertisement: GemsAdvertisementResponse;
}

const createGemsAdvertisement = async (body: CreateGemsAdvertisementDto) => {
  const response = await customFetch.post("/gems-advertisements", body);
  return response.data;
};

export const useCreateGemsAdvertisement = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    CreateGemsAdvertisementResponse,
    AxiosError | Error,
    CreateGemsAdvertisementDto
  >({
    mutationFn: createGemsAdvertisement,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["gems-advertisements"],
      }),
  });

  return mutation;
};
