/* eslint-disable no-case-declarations */
import {
  GET_ACTIVITY_POINTS_LIST,
  EDIT_ACTIVITY_DETAILS_LIST,
  GET_ACTIVITY_DETAILS_LIST,
  GET_ACTIVITY_ATTENDENCE_LIST,
  ADD_ACTIVITY_LIST,
  GET_ACTIVITY_LIST,
  GET_ACTIVITY_ATTENDENCE_LOG_LIST,
  ADD_ACTIVITY_POINTS_LIST,
  REGISTER_ACTIVITY_LIST,
  ATTEND_ACTIVITY,
  ROLL_CALL_ACTIVITY_LIST,
  SET_PAGE,
  CHECK_STUDENT_ACTIVITY,
} from "../constants/action-types";
import { ActivityData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";

const initialState = {
  progress: false,
  isLoading: false,
  onSaveLoading: false,
  activityList: [],
  activityDetails: [],
  attendenceList: [],
  actAttendenceLog: [],
  pointsList: [],
  isAttendend: false,
  totalRecord: 0,
  currentPage: 1,
  checkActivity: {},
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: ActivityData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case SET_PAGE:
        return {
          ...state,
          currentPage: action.payload,
        };

      case CHECK_STUDENT_ACTIVITY.CHECK_STUDENT_ACTIVITY_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
          checkActivity: {},
        };
      case CHECK_STUDENT_ACTIVITY.CHECK_STUDENT_ACTIVITY_SUCCESS:
        return {
          ...state,
          isLoading: false,
          checkActivity: action.payload,
        };

      case CHECK_STUDENT_ACTIVITY.CHECK_STUDENT_ACTIVITY_ERROR:
        return {
          ...state,
          isLoading: false,
          checkActivity: {},
        };

      case GET_ACTIVITY_LIST.GET_ACTIVITY_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_ACTIVITY_LIST.GET_ACTIVITY_LIST_SUCCESS:
        const allPost: any = action.payload; // [{ id: 2, body: "Abcd", title: "title", userId: 3 }, { id: 2, body: "Abcd", title: "title", userId: 3 }];
        return {
          ...state,
          activityList: allPost.data,
          totalRecord: allPost.totalCount,
          isLoading: false,
        };

      case GET_ACTIVITY_LIST.GET_ACTIVITY_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_ACTIVITY_ATTENDENCE_LOG_LIST.GET_ACTIVITY_ATTENDENCE_LOG_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_ACTIVITY_ATTENDENCE_LOG_LIST.GET_ACTIVITY_ATTENDENCE_LOG_LIST_SUCCESS:
        const act: any = action.payload;
        return {
          ...state,
          actAttendenceLog: act.data, //action.payload,
          totalRecord: act.totalCount,
          isLoading: false,
        };

      case GET_ACTIVITY_ATTENDENCE_LOG_LIST.GET_ACTIVITY_ATTENDENCE_LOG_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_ACTIVITY_LIST.ADD_ACTIVITY_LIST_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case ADD_ACTIVITY_LIST.ADD_ACTIVITY_LIST_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case ADD_ACTIVITY_LIST.ADD_ACTIVITY_LIST_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case ADD_ACTIVITY_POINTS_LIST.ADD_ACTIVITY_POINTS_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case ADD_ACTIVITY_POINTS_LIST.ADD_ACTIVITY_POINTS_LIST_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_ACTIVITY_POINTS_LIST.ADD_ACTIVITY_POINTS_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case EDIT_ACTIVITY_DETAILS_LIST.EDIT_ACTIVITY_DETAILS_LIST_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case EDIT_ACTIVITY_DETAILS_LIST.EDIT_ACTIVITY_DETAILS_LIST_SUCCESS:
        return {
          ...state,
          activityDetails: action.payload,
          onSaveLoading: false,
        };

      case EDIT_ACTIVITY_DETAILS_LIST.EDIT_ACTIVITY_DETAILS_LIST_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case GET_ACTIVITY_DETAILS_LIST.GET_ACTIVITY_DETAILS_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_ACTIVITY_DETAILS_LIST.GET_ACTIVITY_DETAILS_LIST_SUCCESS:
        return {
          ...state,
          activityDetails: action.payload,
          isLoading: false,
        };

      case GET_ACTIVITY_DETAILS_LIST.GET_ACTIVITY_DETAILS_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_ACTIVITY_ATTENDENCE_LIST.GET_ACTIVITY_ATTENDENCE_LIST_INITIALIZATION:
        return {
          ...state,
          totalRecord: 0,
          isLoading: true,
        };

      case GET_ACTIVITY_ATTENDENCE_LIST.GET_ACTIVITY_ATTENDENCE_LIST_SUCCESS:
        const data: any = action.payload;
        return {
          ...state,
          attendenceList: data.data, // action.payload,
          totalRecord: data.totalCount,
          isLoading: false,
        };

      case GET_ACTIVITY_ATTENDENCE_LIST.GET_ACTIVITY_ATTENDENCE_LIST_ERROR:
        return {
          ...state,
          totalRecord: 0,
          isLoading: false,
        };

      case GET_ACTIVITY_POINTS_LIST.GET_ACTIVITY_POINTS_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_ACTIVITY_POINTS_LIST.GET_ACTIVITY_POINTS_LIST_SUCCESS:
        return {
          ...state,
          pointsList: action.payload,
          isLoading: false,
        };

      case GET_ACTIVITY_POINTS_LIST.GET_ACTIVITY_POINTS_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };
      case ATTEND_ACTIVITY.ATTEND_ACTIVITY_INITIALIZATION:
        return {
          ...state,
          isAttendend: false,
          isLoading: true,
        };
      case ATTEND_ACTIVITY.ATTEND_ACTIVITY_SUCCESS:
        return {
          ...state,
          isAttendend: true,
          isLoading: false,
        };
      case ATTEND_ACTIVITY.ATTEND_ACTIVITY_ERROR:
        return {
          ...state,
          isAttendend: false,
          isLoading: false,
        };

      case REGISTER_ACTIVITY_LIST.REGISTER_ACTIVITY_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case REGISTER_ACTIVITY_LIST.REGISTER_ACTIVITY_LIST_SUCCESS:
        return {
          ...state,
          pointsList: action.payload,
          isLoading: false,
        };

      case REGISTER_ACTIVITY_LIST.REGISTER_ACTIVITY_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case ROLL_CALL_ACTIVITY_LIST.ROLL_CALL_ACTIVITY_LIST_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case ROLL_CALL_ACTIVITY_LIST.ROLL_CALL_ACTIVITY_LIST_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case ROLL_CALL_ACTIVITY_LIST.ROLL_CALL_ACTIVITY_LIST_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };
      default:
        return state;
    }
  },
);

export default reducer;
