import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { dispatch } from "../redux";
import { changePassword } from "../redux/action";
import { Store } from "../redux/Actions";
import { ChangePasswordSchema } from "../util/validationSchema";
import { isEdge } from "react-device-detect";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const ChangePassword = () => {
  const onSaveLoading = useSelector(
    (state: Store) => state.userDataReducer.onSaveLoading,
  );
  const errMsg = useSelector((state: Store) => state.userDataReducer.errorMsg);
  const [buttonClick, setButtonClick] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: async (values) => {
      if (!buttonClick) {
        const userData = {
          oldPassword: values.oldPassword,
          newPassword: values.password,
        };
        dispatch(changePassword(true, userData));
        setButtonClick(true);
      }
    },
  });

  const { errors, touched, values, resetForm } = formik;

  useEffect(() => {
    if (!onSaveLoading && buttonClick) {
      resetForm();
      setButtonClick(false);
    }
  }, [onSaveLoading]);

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((oldShowPassword) => !oldShowPassword);
  };

  const PasswordIcon = showPassword ? AiFillEyeInvisible : AiFillEye;

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword((oldShowPassword) => !oldShowPassword);
  };

  const ConfirmPasswordIcon = showConfirmPassword
    ? AiFillEyeInvisible
    : AiFillEye;

  const [showOldPassword, setShowOldPassword] = useState(false);

  const toggleShowOldPassword = () => {
    setShowOldPassword((showOldPassword) => !showOldPassword);
  };

  const OldPasswordIcon = showOldPassword ? AiFillEyeInvisible : AiFillEye;

  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                <FormattedMessage
                  id="head.ChangePassword"
                  defaultMessage="Change Password"
                />
              </h1>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          <div className="card">
            <form
              className="form-horizontal"
              onSubmit={(e: any) => {
                e.preventDefault();
                formik.handleSubmit();
              }}
            >
              <div className="card-body">
                <div className="mt-3">
                  <div className="form-group row">
                    <label className="control-label text-md-right col-md-3">
                      <FormattedMessage
                        id="head.oldPassword"
                        defaultMessage="Old Password"
                      />
                    </label>
                    <div className="col-md-5">
                      <div className="position-relative pass-hideshow">
                        <input
                          type={showOldPassword ? "text" : "password"}
                          name="oldPassword"
                          value={values.oldPassword}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          className="form-control"
                          // placeholder={useIntl().formatMessage({
                          //   id: "select.oldPassword",
                          //   defaultMessage: "Old Password",
                          // })}
                        />
                        {!isEdge && (
                          <OldPasswordIcon
                            className="show-password-register"
                            onClick={toggleShowOldPassword}
                          />
                        )}
                      </div>
                      {/* <div className="text-error mr-5">{errMsg}</div> */}
                      {errors.oldPassword && touched.oldPassword && (
                        <div className="text-error">
                          <FormattedMessage
                            id={errors.oldPassword.toString()}
                            defaultMessage="This field is required"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="control-label text-md-right col-md-3">
                      <FormattedMessage
                        id="head.newPassword"
                        defaultMessage="New Password"
                      />
                    </label>
                    <div className="col-md-5">
                      <div className="position-relative pass-hideshow">
                        <input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          value={values.password}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          className="form-control"
                          // placeholder={useIntl().formatMessage({
                          //   id: "select.newPassword",
                          //   defaultMessage: "New Password",
                          // })}
                        />
                        {!isEdge && (
                          <PasswordIcon
                            className="show-password-register"
                            onClick={toggleShowPassword}
                          />
                        )}
                      </div>
                      {errors.password && touched.password && (
                        <div className="text-error">
                          <FormattedMessage
                            id={errors.password.toString()}
                            defaultMessage="This field is required"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="control-label text-md-right col-md-3">
                      <FormattedMessage
                        id="select.ConfirmPassword"
                        defaultMessage="Confirm Password"
                      />
                    </label>
                    <div className="col-md-5">
                      <div className="position-relative pass-hideshow">
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          name="confirmPassword"
                          value={values.confirmPassword}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          className="form-control"
                          // placeholder={useIntl().formatMessage({
                          //   id: "select.ConfirmPassword",
                          //   defaultMessage: "Confirm Password",
                          // })}
                        />
                        {!isEdge && (
                          <ConfirmPasswordIcon
                            className="show-password-register"
                            onClick={toggleShowConfirmPassword}
                          />
                        )}
                      </div>
                      {errors.confirmPassword && touched.confirmPassword && (
                        <div className="text-error">
                          <FormattedMessage
                            id={errors.confirmPassword.toString()}
                            defaultMessage="This field is required"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer bg-light text-right">
                <button
                  type="button"
                  className="btn btn-secondary clear-form mr-2"
                  onClick={() => {
                    resetForm();
                    navigate("/home");
                  }}
                >
                  <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
                </button>
                <button type="submit" className="btn btn-primary">
                  <FormattedMessage id="page.submit" defaultMessage="Submit" />
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ChangePassword;
