import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState } from "react";
import { customFetch } from "../../../util/axios";

interface UploadSingleFileResponse {
  data: {
    fieldname: string;
    originalname: string;
    encoding: string;
    mimetype: string;
    size: number;
    bucket: string;
    key: string;
    acl: string;
    contentType: string | null;
    contentDisposition: string | null;
    contentEncoding: string | null;
    storageClass: string;
    serverSideEncryption: string | null;
    metadata: {
      fieldName: string;
    };
    location: string;
    etag: string;
    versionId: string;
  };
}

const uploadSingleFile = async (
  file: File,
  progressCallBack?: (progressEvent: ProgressEvent) => void,
) => {
  const formDate = new FormData();
  formDate.append("image", file);

  const response = await customFetch.post("/files/image", formDate, {
    onUploadProgress: (progressEvent: ProgressEvent) => {
      if (progressCallBack) {
        progressCallBack(progressEvent);
      }
    },
  });

  return response.data;
};

export const useUploadSingleFile = () => {
  const [progress, setProgress] = useState(0);

  const progressCallBack = (progressEvent: ProgressEvent) => {
    setProgress(
      (100 * progressEvent.loaded) / Math.max(0, progressEvent.total),
    );
  };

  const mutation = useMutation<
    UploadSingleFileResponse,
    AxiosError | Error,
    File
  >({
    mutationFn: (file) => uploadSingleFile(file, progressCallBack),
  });

  return { ...mutation, progress };
};
