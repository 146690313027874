import React, { useEffect, useRef, useState, memo } from "react";
import { useNavigate, useParams } from "react-router";
import { Breadcrumb, Card, Col, Row } from "react-bootstrap";
import { BsChevronLeft } from "react-icons/bs";
import { Chip } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import {
  deleteTicketList,
  editTicketList,
  getQR,
  getTicketCommentList,
  getTicketDetail,
} from "../../redux/action";
import {
  AdviseIcon,
  AttachmentIcon,
  BugIcon,
  ProblemIcon,
} from "../../util/iconStyle";
import MoreHorizIcon from "@mui/icons-material/MoreHorizOutlined";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Viewer from "react-viewer";
import { getFileDetail, get_url_extensions } from "../../util/utils";
import { _UploadMedia } from "../../service/MediaUpload/classes.service";
import { FormattedMessage } from "react-intl";
import TicketAssignee from "./TicketAssignee";
import TicketComment from "./TicketComment";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import EditorContainer from "../../Components/RichTextEditor/Editor";
import draftToHtml from "draftjs-to-html";
import { convertToRaw, EditorState, convertFromHTML } from "draft-js";
import { ContentState } from "draft-js";
import { Settings } from "@mui/icons-material";
import DeletePopup from "../../Components/common/Popups/DeletePopup";
import Pagination from "../../Components/common/Paginate/Pagination";
import { toast } from "react-toastify";
import { BsTrash } from "react-icons/bs";
import { ToastDownload } from "../../Components/common/ToastDownload";
import htmlToDraft from "html-to-draftjs";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

const IMAGE_TYPES = ["png", "jpeg", "jpg"];
const StyledMenu = styled((props: any) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }: any) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 120,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const TicketDetail = () => {
  const urlParams = useParams();
  const navigate = useNavigate();
  const { id } = urlParams;
  const attchRef = useRef<any>();
  const dispatch = useDispatch();
  const [img, setImg] = useState({ image: [], show: false, index: 0 });
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const location = useLocation();
  const [load, setLoad] = useState(false);

  const btnRef = useRef<any>();
  const [LoadToastId, setLoadToastId] = useState<any>([]);

  const ticketDetails: any = useSelector(
    (state: Store) => state.ticketReducer.ticketDetails,
  ) as any;
  const myInfo: any = useSelector(
    (state: Store) => state.userDataReducer.myInfo,
  ) as any;
  const ticketComment: any = useSelector(
    (state: Store) => state.ticketReducer.commentList,
  ) as any;
  const totalRecord: any = useSelector(
    (state: Store) => state.ticketReducer.totalCommentRecords,
  ) as any;
  const isLoading: any = useSelector(
    (state: Store) => state.ticketReducer.isLoading,
  ) as any;
  const isDeleted: any = useSelector(
    (state: Store) => state.ticketReducer.isDeleted,
  ) as any;
  const [ticketText, setTicketText] = useState({ ...ticketDetails });
  const [ticketAttachment, setTicketAttachment] = useState<any>([]);
  const [fileLoading, setFileLoading] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [decription, setDescription] = useState("Inital Test");
  const [deletes, setDelete] = useState({ modal: false, id: 0 });

  const [commentEdit, setCommentEdit] = useState({
    title: false,
    content: false,
  });

  const [editorConfig, setEditorConfig] = useState({
    toolbar: false,
    editable: false,
    height: "200px",
    overflowY: "unset",
    border: "1px solid transparent",
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const { id } = urlParams;
    if (id && parseInt(id) > 0) {
      // dispatch(getTicketCommentList(false, parseInt(id)));
      dispatch(getTicketDetail(false, parseInt(id)));
    }
  }, []);

  const onPageChange = (pageNum: number) => {
    setPageNumber(pageNum);
  };

  useEffect(() => {
    const { id } = urlParams;
    if (id && parseInt(id) > 0) {
      dispatch(
        getTicketCommentList(false, parseInt(id), pageNumber, pageLimit),
      );
    }
  }, [pageNumber, pageLimit]);
  useEffect(() => {
    const data: any = location?.state;
    if (data?.pageProps) {
      console.log(data?.pageProps, "page numbers");
    }
  }, []);

  useEffect(() => {
    setPageNumber(1);
  }, [ticketDetails]);

  useEffect(() => {
    setTicketText(ticketDetails);
    if (ticketDetails.content) {
      setDescription(() => ticketDetails.content);

      if (ticketDetails?.content.trim() !== "<p></p>") {
        const blocksFromHTML = htmlToDraft(ticketDetails?.content);
        const estate = EditorState.createWithContent(
          ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap,
          ),
        );
        setEditorState(estate);
      } else {
        const estate = EditorState.createEmpty();
        setEditorState(estate);
      }
    }

    if (ticketDetails.attachmentList) {
      const files = getFileDetail(
        ticketDetails?.attachmentList.map((i: any) => i.attachmentPath),
      );
      const ff: any = []; ///_.sortBy(files,"extension",)
      const ff1: any = []; ///_.sortBy(files,"extension",)
      const filesImg: any = [];
      files.map((i: any) => {
        if (IMAGE_TYPES.includes(i.extention)) {
          filesImg.push({ src: i.attachmentPath, alt: "No Image" });
          ff.push(i);
        } else {
          ff1.push(i);
        }
      });
      setTicketAttachment([...ff, ...ff1]);
      setImg({ show: false, image: filesImg, index: 0 });
    }
  }, [ticketDetails]);

  const onCommentImageClick = (index: any) => {
    const filesImg: any = [];
    ticketAttachment.map((i: any) => {
      if (IMAGE_TYPES.includes(get_url_extensions(i?.path || "") || "")) {
        filesImg.push({ src: i.path, alt: "No Image" });
      }
    });
    console.log("ticketAttachment", ticketAttachment);
    setImg({ show: true, image: filesImg, index: index });
  };
  useEffect(() => {
    dispatch(getQR());
  }, []);
  useEffect(() => {
    if (!isLoading && isDeleted) {
      navigate("/tickets", { state: { pageProps: 1 } });
    }
  }, [isLoading, isDeleted]);

  const onCancleEdit = () => {
    if (commentEdit.title) {
      setCommentEdit(() => ({ ...commentEdit, title: false }));
    }
    if (commentEdit.content) {
      setCommentEdit(() => ({ ...commentEdit, content: false }));
      const enableEditing = {
        toolbar: false,
        editable: false,
        height: "200px",
        overflowY: "unset",
        border: "1px solid transparent",
      };
      setEditorConfig(() => ({ ...enableEditing }));
    }
  };
  const onTextChange = (field: any, value: any) => {
    const ttext = ticketText || {};
    ttext[field] = value;
    setTicketText((prv: any) => ({ ...prv, ...ttext }));
  };

  const onUpdateTicket = (isPublic = false) => {
    const { id } = urlParams;
    const ticketDetail: any = {
      title: ticketText.title,
      content: ticketText.content,
      software: ticketDetails.software.id,
      version: ticketDetails?.version?.id || null,
      isPublic: isPublic,
      status: ticketDetails.status,
      tool: ticketDetails.tool,
      type: ticketDetails.type.id,
      ticketGroupId: ticketDetails.ticketGroup.id,
      // ticketGroupId: myInfo?.ticketGroup?.[0]?.id || 0, //selectedGroup.id,
      attachmentList: ticketAttachment.map((i: any) => i.path), //??
    };
    delete ticketDetail.createdAt;
    delete ticketDetail.updatedAt;
    delete ticketDetail.reporter;

    dispatch(editTicketList(true, id, ticketDetail));
    setAnchorEl(null);
  };

  const attchmentClick = () => {
    if (attchRef.current) {
      attchRef?.current.click();
    }
  };

  const handleDrop = async (e: any) => {
    const acceptedFiles = e.target.files;
    true;
    const files: any = [];
    for (let i = 0; i < acceptedFiles.length; i++) {
      if (acceptedFiles[i].size > 104857600) {
        toast.error(
          `${acceptedFiles[i].name} file is not allowed because it's size is more than 100MB`,
        );
      } else {
        files.push(acceptedFiles[i]);
      }
    }
    if (files.length > 0) {
      setLoad(true);

      const { fileUploadResponse, error }: any = await _UploadMedia(
        files[0] as File,
      );
      setLoad(false);
      if (!error) {
        const files = getFileDetail([fileUploadResponse]);
        const ticketAttachments = ticketAttachment;
        ticketAttachments.push(...files);

        setTicketAttachment(() => [...ticketAttachments]);
        const ticketDetail: any = {
          title: ticketText.title,
          content: ticketText.content,
          software: ticketDetails.software.id,
          version: ticketDetails?.version?.id || 0,
          isPublic: ticketDetails.isPublic,
          status: ticketDetails.status,
          tool: ticketDetails.tool,
          type: ticketDetails.type.id,
          ticketGroupId: ticketDetails.ticketGroup.id,
          // ticketGroupId: myInfo?.ticketGroup?.[0]?.id || 0, //selectedGroup.id,
          attachmentList: ticketAttachments.map((i: any) => i.path), //??
        };
        const { id } = urlParams;
        delete ticketDetail.createdAt;
        delete ticketDetail.updatedAt;
        delete ticketDetail.reporter;

        dispatch(editTicketList(true, id, ticketDetail));
      }
    }
    e.target.value = "";
    setFileLoading(false);
  };

  const onImageDelete = (index: any) => {
    const ticketAttachments = ticketAttachment;
    const a = ticketAttachments.filter((i: any, ind: any) => ind !== index);
    setTicketAttachment(() => [...a]);
    const { id } = urlParams;
    const ticketDetail: any = {
      title: ticketText.title,
      content: ticketText.content,
      software: ticketDetails.software.id,
      version: ticketDetails?.version?.id || null,
      isPublic: ticketDetails.isPublic,
      status: ticketDetails.status,
      tool: ticketDetails.tool,
      type: ticketDetails.type.id,
      // ticketGroupId: myInfo?.ticketGroup?.[0]?.id || 0, //selectedGroup.id,
      ticketGroupId: ticketDetails.ticketGroup.id,
      attachmentList: a.map((i: any) => i.path), //??
    };
    delete ticketDetail.createdAt;
    delete ticketDetail.updatedAt;
    delete ticketDetail.reporter;
    dispatch(editTicketList(true, id, ticketDetail));
    setDelete({ modal: false, id: 0 });
  };

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
  };

  const isEditComment = () => {
    const enableEditing = {
      toolbar: true,
      editable: true,
      height: "200px",
      overflowY: "scroll",
      border: "1px solid #efefef",
    };
    setEditorConfig(() => enableEditing);
  };

  const onEditClick = (isPublic = false) => {
    const eState = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    const enableEditing = {
      toolbar: false,
      editable: false,
      height: "200px",
      overflowY: "unset",
      border: "1px solid transparent",
    };
    setEditorConfig(() => ({ ...enableEditing }));
    const { id } = urlParams;

    if (eState.trim() != "<p></p>") {
      const ticketDetail: any = {
        title: ticketText.title,
        content: eState,
        software: ticketDetails.software.id,
        version: ticketDetails?.version?.id || null,
        isPublic: isPublic,
        status: ticketDetails.status,
        type: ticketDetails.type.id,
        tool: ticketDetails.tool,
        // ticketGroupId: myInfo?.ticketGroup?.[0]?.id || 0, //selectedGroup.id,
        ticketGroupId: ticketDetails.ticketGroup.id,
        attachmentList: ticketAttachment.map((i: any) => i.path), //??
      };
      delete ticketDetail.createdAt;
      delete ticketDetail.updatedAt;
      delete ticketDetail.reporter;

      dispatch(editTicketList(true, id, ticketDetail));
    } else {
      const blocksFromHTML = htmlToDraft(decription);
      const editorsState = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        ),
      );
      onEditorStateChange(editorsState);
    }
  };

  return (
    <div className="content-wrapper">
      {(fileLoading || isLoading) && <CenterLoader />}
      {load && <CenterLoader />}
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <Link to="/tickets" state={location?.state}>
                {" "}
                <span style={{ verticalAlign: "middle" }}>
                  {" "}
                  <i className="back-button">
                    <BsChevronLeft /> &nbsp;
                  </i>
                  <FormattedMessage id="page.back" defaultMessage="Back" />{" "}
                </span>
              </Link>
            </div>
            {/* <Link className="btn btn-secondary" to="/classes">
              {" "}
                <Settings />
              <span style={{ verticalAlign: "top" }}>
                {" "}
                <label  style={{marginTop:'1px'}}>
                  <FormattedMessage
                    id="classDetail.classess"
                    defaultMessage="Group Settings"
                  />{" "}
                </label>
              </span>
            </Link> */}
          </div>
        </header>
        <div>
          <input
            type="file"
            ref={attchRef}
            style={{ display: "none" }}
            onChangeCapture={(e: any) => handleDrop(e)}
          />
        </div>
        <section className="page-content container-fluid">
          {/* <Breadcrumb>
            <Breadcrumb.Item
              style={{ background: "transparent", verticalAlign: "middle" }}
            >
              <Link to="/tickets">
                {" "}
                <span style={{ verticalAlign: "middle" }}>
                  {" "}
                  <BsChevronLeft />
                  <FormattedMessage
                    id="ticketDetail.ticket"
                    defaultMessage="Tickets"
                  />{" "}
                </span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb> */}

          <Card style={{ width: "100%" }}>
            <Card.Body>
              <Row>
                <Col lg={9} md={8} sm={6}>
                  <div className="mt-3">
                    <Card.Text>
                      {ticketDetails?.type && (
                        <div className="mb-3">
                          {ticketDetails.type.id === 2 && (
                            <Chip
                              icon={
                                <i
                                  className="MuiListItemIcon-root-tbl"
                                  style={{ fill: "#ffffff", height: "16px" }}
                                >
                                  {" "}
                                  <BugIcon />
                                </i>
                              }
                              label="Bug"
                              style={{
                                color: "#ffffff",
                                borderColor: "rgb(255 72 72)",
                                background: "rgb(255 72 72)",
                                fontWeight: "500",
                              }}
                              variant="outlined"
                            />
                          )}

                          {ticketDetails.type.id === 1 && (
                            <Chip
                              icon={
                                <i
                                  className="MuiListItemIcon-root-tbl"
                                  style={{ fill: "#ffffff", height: "16px" }}
                                >
                                  {" "}
                                  <AdviseIcon />
                                </i>
                              }
                              label="Advice"
                              style={{
                                color: "#ffffff",
                                borderColor: "rgb(223 184 20)",
                                background: "rgb(223 184 20)",
                                fontWeight: "500",
                              }}
                              variant="outlined"
                            />
                          )}

                          {ticketDetails.type.id === 3 && (
                            <Chip
                              icon={
                                <i
                                  className="MuiListItemIcon-root-tbl"
                                  style={{ fill: "#ffffff", height: "16px" }}
                                >
                                  {" "}
                                  <ProblemIcon />
                                </i>
                              }
                              label="Problem"
                              style={{
                                color: "#ffffff",
                                borderColor: "#820398",
                                background: "#9c27b0",
                                fontWeight: "500",
                              }}
                              variant="outlined"
                            />
                          )}
                          <small className="ml-2">
                            {" "}
                            FFI - {ticketDetails.id}{" "}
                          </small>
                        </div>
                      )}
                      <div>
                        <h2 className="mb-4" style={{ fontWeight: "600" }}>
                          {!commentEdit.title && (
                            <p
                              onClick={() =>
                                setCommentEdit({
                                  ...commentEdit,
                                  title: true,
                                })
                              }
                            >
                              {" "}
                              {ticketDetails.title}
                              {/* <small className="ml-2">
                                {" "}
                                FF - {ticketDetails.id}{" "}
                              </small> */}
                            </p>
                          )}
                          {commentEdit.title && (
                            <>
                              <input
                                type="text"
                                value={ticketText.title}
                                onChange={(e) =>
                                  onTextChange("title", e.target.value)
                                }
                                className="form-control"
                              />
                              <Button
                                size="small"
                                className="mr-2 mt-2"
                                variant="contained"
                                onClick={() => {
                                  onUpdateTicket();
                                  setCommentEdit({
                                    ...commentEdit,
                                    title: false,
                                  });
                                }}
                                style={{ textTransform: "none" }}
                              >
                                Save
                              </Button>
                              <Button
                                size="small"
                                onClick={onCancleEdit}
                                className="mt-2"
                                variant="text"
                                color="inherit"
                                style={{ textTransform: "none" }}
                              >
                                Cancel
                              </Button>
                            </>
                          )}
                        </h2>
                        <Row className="ml-1 mb-4">
                          <Button
                            onClick={attchmentClick}
                            variant="outlined"
                            style={{ textTransform: "none" }}
                            size="small"
                          >
                            <i
                              className="MuiListItemIcon-root-tbl"
                              style={{
                                fill: "#1976d2",
                                width: "16px",
                                height: "16px",
                              }}
                            >
                              {" "}
                              <AttachmentIcon />
                            </i>
                            <FormattedMessage
                              id="announcementDetail.attach"
                              defaultMessage="Attach"
                            />{" "}
                          </Button>
                          <div className="dropdown">
                            <Button
                              id="demo-customized-button"
                              aria-controls={
                                open ? "demo-customized-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              variant="text"
                              color="inherit"
                              disableElevation
                              onClick={handleClick}
                              endIcon={<MoreHorizIcon />}
                              style={{ textTransform: "none" }}
                            ></Button>
                            <StyledMenu
                              id="demo-customized-menu"
                              MenuListProps={{
                                "aria-labelledby": "demo-customized-button",
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                            >
                              {/* <MenuItem
                                onClick={() => onUpdateTicket(true)}
                                disableRipple
                              >
                                <FormattedMessage
                                  id="ticketDetail.turnToPublic"
                                  defaultMessage="Turn to public"
                                />
                              </MenuItem> */}
                              {!ticketDetails.isPublic ? (
                                <MenuItem
                                  onClick={
                                    () => onUpdateTicket(true)
                                    // dispatch(editTicketList(true, urlParams.id, {
                                    // ...ticketDetails, ...ticketText,
                                    // isPublic: true,
                                    // ticketGroupId: ticketDetails.ticketGroup.id
                                    //     }))
                                  }
                                  disableRipple
                                >
                                  <FormattedMessage
                                    id="ticketDetail.turnToPublic"
                                    defaultMessage="Turn to public"
                                  />
                                </MenuItem>
                              ) : (
                                <MenuItem
                                  onClick={() => onUpdateTicket(false)}
                                  disableRipple
                                >
                                  <FormattedMessage
                                    id="ticketDetail.turnToPrivate"
                                    defaultMessage="Delete Ticket"
                                  />
                                </MenuItem>
                              )}

                              <MenuItem
                                onClick={() =>
                                  dispatch(deleteTicketList(true, urlParams.id))
                                }
                                disableRipple
                              >
                                <FormattedMessage
                                  id="ticketDetail.deleteTicket"
                                  defaultMessage="Delete Ticket"
                                />
                              </MenuItem>
                            </StyledMenu>
                          </div>
                        </Row>

                        <Row className="mb-4">
                          <Col lg={2} md={3} className="pr-0 mt-3">
                            <h4>
                              <FormattedMessage
                                id="announcementDetail.description"
                                defaultMessage="Description"
                              />
                              :
                            </h4>
                          </Col>
                          <Col lg={10} md={9}>
                            <div className="form-group" onClick={isEditComment}>
                              {decription && (
                                <>
                                  <EditorContainer
                                    onEditorStateChange={onEditorStateChange}
                                    editorState={editorState}
                                    toolbar={editorConfig.toolbar}
                                    editable={editorConfig.editable}
                                    height={editorConfig.height}
                                    overflowY={editorConfig.overflowY}
                                    border={editorConfig.border}
                                  />{" "}
                                </>
                              )}
                            </div>
                            {editorConfig.editable && (
                              <div className="mt-2">
                                <Button
                                  size="small"
                                  className="mr-2 mt-2"
                                  variant="contained"
                                  onClick={() => {
                                    onEditClick();
                                    setCommentEdit({
                                      ...commentEdit,
                                      content: false,
                                    });
                                  }}
                                  style={{ textTransform: "none" }}
                                >
                                  Save
                                </Button>
                                <Button
                                  size="small"
                                  onClick={() => {
                                    const enableEditing = {
                                      toolbar: false,
                                      editable: false,
                                      height: "200px",
                                      overflowY: "unset",
                                      border: "1px solid transparent",
                                    };
                                    setEditorConfig(() => ({
                                      ...enableEditing,
                                    }));
                                    if (ticketDetails.content) {
                                      setDescription(
                                        () => ticketDetails.content,
                                      );

                                      if (
                                        ticketDetails?.content.trim() !==
                                        "<p></p>"
                                      ) {
                                        const blocksFromHTML = htmlToDraft(
                                          ticketDetails?.content,
                                        );
                                        const estate =
                                          EditorState.createWithContent(
                                            ContentState.createFromBlockArray(
                                              blocksFromHTML.contentBlocks,
                                              blocksFromHTML.entityMap,
                                            ),
                                          );
                                        setEditorState(estate);
                                      } else {
                                        const estate =
                                          EditorState.createEmpty();
                                        setEditorState(estate);
                                      }
                                    }
                                  }}
                                  className="mt-2"
                                  variant="text"
                                  color="inherit"
                                  style={{ textTransform: "none" }}
                                >
                                  Cancel
                                </Button>
                              </div>
                            )}
                          </Col>
                        </Row>

                        <div className="d-flex flex-wrap mb-3">
                          {ticketAttachment.map((imgs: any, index: any) => (
                            <>
                              {IMAGE_TYPES.includes(imgs.extention) ? (
                                <>
                                  <div className="attch-container mr-3 mb-3">
                                    <img
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // setImg((prv) => ({
                                        //   // image: imgs.path,
                                        //   ...prv,
                                        //   show: true,
                                        //   index: index
                                        // }));
                                        onCommentImageClick(index);
                                      }}
                                      src={imgs.path}
                                    />
                                    <button
                                      className="btns"
                                      // onClick={() => onImageDelete(index)}
                                      onClick={() =>
                                        setDelete({
                                          modal: true,
                                          id: index,
                                        })
                                      }
                                    >
                                      <BsTrash />
                                    </button>
                                  </div>
                                  <Viewer
                                    visible={img.show}
                                    noToolbar={true}
                                    disableMouseZoom={true}
                                    drag={false}
                                    onClose={() => {
                                      setImg((prv) => ({
                                        ...prv,
                                        show: false,
                                        index: 0,
                                      }));
                                    }}
                                    activeIndex={img.index}
                                    images={img.image}
                                    onChange={(
                                      activeImage: any,
                                      index: number,
                                    ) => {
                                      setImg((prv: any) => ({
                                        ...prv,
                                        index: index,
                                      }));
                                    }}
                                    // {[
                                    //   { src: img.image, alt: "Nor FOund" },
                                    // ]}
                                  />
                                </>
                              ) : (
                                <React.Fragment></React.Fragment>
                              )}
                            </>
                          ))}
                        </div>

                        <Row className="mb-3">
                          {ticketAttachment.map((imgs: any, index: any) => (
                            <>
                              {IMAGE_TYPES.includes(imgs.extention) ? (
                                <></>
                              ) : (
                                <Col lg={12} md={12} sm={12}>
                                  <ToastDownload
                                    Load={LoadToastId}
                                    setLoad={setLoadToastId}
                                    ref={btnRef}
                                  />
                                  <a
                                    // href={imgs.path}
                                    onClick={() => {
                                      if (btnRef.current) {
                                        setLoadToastId((prv: any) => [
                                          ...prv,
                                          `TickD${index}`,
                                        ]);
                                        btnRef?.current.downloadFiles(
                                          imgs.path,
                                          `TickD${index}`,
                                        );
                                      }
                                    }}
                                  >
                                    {imgs.name}{" "}
                                  </a>{" "}
                                  <a
                                    className="ml-1 mt-1 d-inline-block align-middle delete-btn"
                                    onClick={() => onImageDelete(index)}
                                  >
                                    {/* Delete */}
                                    <BsTrash />
                                  </a>
                                  {/* <br /> */}
                                </Col>
                              )}
                            </>
                          ))}
                        </Row>
                      </div>
                    </Card.Text>
                  </div>
                  <TicketComment />
                </Col>
                <Col lg={3} md={4} sm={6}>
                  <TicketAssignee ticketDetail={ticketDetails} id={id} />
                </Col>
              </Row>
              {ticketComment &&
                ticketComment.length > 0 &&
                ticketComment !== null &&
                totalRecord > 10 && (
                  <>
                    <FormControl className="pagelimit">
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        <FormattedMessage
                          id="page_size"
                          defaultMessage="Page Size"
                        />
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={pageLimit}
                        onChange={(e: any) => {
                          setPageLimit(e.target.value);
                          setPageNumber(1);
                        }}
                      >
                        <FormControlLabel
                          value={10}
                          control={<Radio />}
                          label="10"
                        />
                        <FormControlLabel
                          value={20}
                          control={<Radio />}
                          label="20"
                        />
                        <FormControlLabel
                          value={50}
                          control={<Radio />}
                          label="50"
                        />
                      </RadioGroup>
                    </FormControl>
                    <div className="pag text-right">
                      <Pagination
                        itemsPerPage={pageLimit}
                        totalPages={totalRecord}
                        onPageChage={onPageChange}
                        page={pageNumber - 1}
                      />
                    </div>
                  </>
                )}
            </Card.Body>
          </Card>
        </section>
      </div>
      {deletes && (
        <DeletePopup
          onClose={() => {
            setDelete({ modal: false, id: 0 });
          }}
          onDeleteClick={() => onImageDelete(deletes.id)}
          open={deletes.modal}
        />
      )}
    </div>
  );
};

export default memo(TicketDetail);
