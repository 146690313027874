import Select from "react-select";
import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import { dispatch } from "../../redux";
import { getAllUsersList, getExpressWayPendingList } from "../../redux/action";
import { Store } from "../../redux/Actions";
import CenterLoader from "../common/CenterLoader/CenterLoader";
import { useIntl } from "react-intl";
import { getToken } from "../../util/utils";

const UserSelectionAutoComplete = ({ getList, onClear }: any) => {
  const List: any = useSelector(
    (state: Store) => state.expressWayReducer.allUserList,
  ) as any;
  const isLoading = useSelector(
    (state: Store) => state.expressWayReducer.Loading.isLoading,
  ) as any;
  const [selectedOption, setselectedOption] = useState<any>([]);
  const previousController = useRef<any>();

  useEffect(() => {
    if (List) {
      setselectedOption(List);
    } else {
      setselectedOption([]);
    }
  }, [List]);

  const intl = useIntl();

  const [text, settext] = useState("");
  const [Alltext, setAllText] = useState("");
  const [filterQ, setFilterQ] = useState("");
  const [te, setT] = useState("");

  useEffect(() => {
    const tid = setTimeout(() => {
      setAllText(text);
    }, 1000);
    return () => {
      clearTimeout(tid);
    };
  }, [text]);

  useEffect(() => {
    // if (filterQ.length > 0) {
    dispatch(getAllUsersList(false, 0, true, filterQ));
    // }
  }, [filterQ]);

  useEffect(() => {
    setFilter(text);
  }, [Alltext]);

  const setFilter = (text: string) => {
    let q = "";
    if (text !== undefined && text.length > 0) {
      q = q + `&q=${encodeURIComponent(text)}`;
      setFilterQ(q);
      setT(text);
    }
    sessionStorage.setItem("filterES", q);

    return 0;
  };

  const handleOnSelect = (item: any, newVal: any) => {
    console.log("on select");
    if (newVal != null) {
      getList(newVal.id);
    } else {
      onClear();
    }
  };

  const onInputChange = (event: any, value: any, reason: any) => {
    if (value == "") {
      setselectedOption([]);
      setFilterQ("");
      setAllText("");
      settext("");
      return;
    }

    const ind = value.indexOf("-");
    const ind1 = value.indexOf("(");
    const ind2 = value.indexOf(")");

    if (value && !(ind > -1 && ind1 > -1 && ind2 > -1)) {
      getData(value);
    }
  };

  const getData = (searchTerm: any) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    // const signal = controller.signal;
    previousController.current = controller;
    const token = getToken("login-auth-token");
    // const auth = token ? `Bearer ${token}` : "";
    settext(searchTerm);
  };

  return (
    <div>
      <div className="form-group">
        <div>
          {isLoading && <CenterLoader />}

          <Autocomplete
            disablePortal
            id="combo-box-demo"
            autoComplete
            options={selectedOption}
            getOptionLabel={(option: any) =>
              `${option.id}-${
                option.englishFirstName + " " + option.englishLastName
              } (${option.preferredNickName ? option.preferredNickName : "-"})`
            }
            filterOptions={(options, state) => options}
            clearOnBlur={false}
            onInputChange={onInputChange}
            sx={{ width: 300 }}
            className="member-autocomplete"
            onChange={handleOnSelect}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label={intl.formatMessage({
                  id: "Select.Member",
                  defaultMessage: "Member",
                })}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default UserSelectionAutoComplete;
