/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/APIServices/baseApiCall";
import {
  GET_EXPRESSWAY_PENDING_LIST,
  GET_EXPRESSWAY_RECORD_LIST,
  GET_EXPRESSWAY_MEMBER_DETAIL,
  ACT_EXPRESSWAY_MEMBER_DETAIL,
  DENIED_MEMEBER_DETAIL,
  MODIFY_MEMBER_DETAIL,
  GET_EMAIL_VERFICATION_LIST,
  GET_ADMIN_LIST,
  UPDATE_ADMIN_STATUS,
  GET_ALL_USERS_LIST,
  GET_ALL_CONSUMER,
  GET_CONSUMER_ACCOUNTS_BY_USER,
  GET_CONSUMER_PRODUCT_PURCHASE_BY_USER,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { setToken } from "../../util/utils";
import {
  GET_EXPRESSWAY_PENDING_LIST_URL,
  GET_EXPRESSWAY_RECORDS_LIST_URL,
  GET_USERS_LIST_URL,
  GET_EXPRESSWAY_MEMBER_DETAIL_URL,
  ACT_EXPRESSWAY_MEMBER_DETAIL_URL,
  MODIFY_MEMBER_DETAIL_URL,
  DENIED_MEMEBER_DETAIL_URL,
  GET_EMAIL_VERFICATION_LIST_URL,
  GET_ADMIN_LIST_URL,
  UPDATE_ADMIN_STATUS_URL,
  GET_ALL_USERS_LIST_URL,
  GET_ALL_CONSUMER_URL,
} from "../../service/APIServices/apiEndPoints";
import { toast } from "react-toastify";

export const getExpressWayRecordList =
  (
    showToast = true,
    page: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getExpressWayRecordListInit(showToast, page, pageLimit));
  };

export const getExpressWayRecordListInit =
  (
    showToast = true,
    page: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_EXPRESSWAY_RECORD_LIST.GET_EXPRESSWAY_RECORD_LIST_INITIALIZATION,
    });
    apiCall(
      GET_EXPRESSWAY_RECORDS_LIST_URL +
        "?page=" +
        page +
        "&perPage=" +
        pageLimit +
        "&sort=DESC&sortBy=createdAt",
      {},
      (res: Object) => dispatch(getExpressWayRecordListSuccess(res)),
      (err: any) => dispatch(getExpressWayRecordListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getExpressWayRecordListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_EXPRESSWAY_RECORD_LIST.GET_EXPRESSWAY_RECORD_LIST_SUCCESS,
      payload: res,
    });
  };

export const getExpressWayRecordListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_EXPRESSWAY_RECORD_LIST.GET_EXPRESSWAY_RECORD_LIST_ERROR,
    });
  };

export const getExpressWayPendingList =
  (
    showToast = true,
    status: string,
    pageNumber: number,
    isUser = false,
    filter = "",
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      getExpressWayPendingListInit(
        showToast,
        status,
        pageNumber,
        isUser,
        filter,
        pageLimit,
      ),
    );
  };

export const getExpressWayPendingListInit =
  (
    showToast = true,
    status: string,
    pageNumber: number,
    isUser: boolean,
    filter: any,
    pageLimit: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_EXPRESSWAY_PENDING_LIST.GET_EXPRESSWAY_PENDING_LIST_INITIALIZATION,
    });
    let url = `${GET_EXPRESSWAY_PENDING_LIST_URL}?status=${status}&page=${pageNumber}&perPage=${pageLimit}&sort=DESC&sortBy=updatedAt`;
    if (isUser) {
      url = `${GET_USERS_LIST_URL}?status=${status}&page=${pageNumber}&perPage=${pageLimit}${filter}&sort=DESC&sortBy=updatedAt`;
    }

    if (isUser && pageNumber == 0) {
      url = `${GET_USERS_LIST_URL}?status=${status}&sort=DESC&sortBy=updatedAt${filter}`;
    }
    apiCall(
      url,
      {},
      (res: Object) => dispatch(getExpressWayPendingListSuccess(res)),
      (err: any) => dispatch(getExpressWayPendingListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getExpressWayPendingListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_EXPRESSWAY_PENDING_LIST.GET_EXPRESSWAY_PENDING_LIST_SUCCESS,
      payload: res,
    });
  };

export const getExpressWayPendingListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_EXPRESSWAY_PENDING_LIST.GET_EXPRESSWAY_PENDING_LIST_ERROR,
    });
  };

export const getAdminList =
  (
    showToast = true,
    status: string,
    pageNumber: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getAdminListInit(showToast, status, pageNumber, pageLimit));
  };

export const getAdminListInit =
  (
    showToast = true,
    status: string,
    pageNumber: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ADMIN_LIST.GET_ADMIN_LIST_INITIALIZATION,
    });
    const url = `${GET_ADMIN_LIST_URL}?type=admin&page=${pageNumber}&perPage=${pageLimit}&sort=DESC&sortBy=createdAt`;
    apiCall(
      url,
      {},
      (res: Object) => dispatch(getAdminListSuccess(res)),
      (err: any) => dispatch(getAdminListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getAdminListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ADMIN_LIST.GET_ADMIN_LIST_SUCCESS,
      payload: res,
    });
  };

export const getAdminListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_ADMIN_LIST.GET_ADMIN_LIST_ERROR,
    });
  };
export const updateAdminUserStatus =
  (
    showToast = true,
    status: boolean,
    id: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(updateAdminUserStatusInit(showToast, status, id));
  };

export const updateAdminUserStatusInit =
  (
    showToast = true,
    status: boolean,
    id: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: UPDATE_ADMIN_STATUS.UPDATE_ADMIN_STATUS_INITIALIZATION,
    });
    // const url = `${GET_ADMIN_LIST_URL}?type=admin&page=${id}&perPage=${process.env.REACT_APP_RECORDS_PER_PAGE}&sort=DESC&sortBy=createdAt`;
    apiCall(
      UPDATE_ADMIN_STATUS_URL + `${id}?isActive=${status}`,
      {},
      (res: Object) => dispatch(updateAdminUserStatusSuccess(res)),
      (err: any) => dispatch(updateAdminUserStatusError()),
      METHOD.GET,
      { showToast },
    );
  };

export const updateAdminUserStatusSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: UPDATE_ADMIN_STATUS.UPDATE_ADMIN_STATUS_SUCCESS,
      payload: res,
    });
  };

export const updateAdminUserStatusError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: UPDATE_ADMIN_STATUS.UPDATE_ADMIN_STATUS_ERROR,
    });
  };

export const getExpressWayMemberDetail =
  (
    showToast = true,
    userId: string,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getExpressWayMemberDetailInit(showToast, userId));
  };

export const getExpressWayMemberDetailInit =
  (
    showToast: boolean,
    userId: string,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_EXPRESSWAY_MEMBER_DETAIL.GET_EXPRESSWAY_MEMBER_DETAIL_INITIALIZATION,
    });
    apiCall(
      `${GET_EXPRESSWAY_MEMBER_DETAIL_URL}${userId}`,
      {},
      (res: Object) => dispatch(getExpressWayMemberDetailSuccess(res)),
      (err: any) => dispatch(getExpressWayMemberDetailError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getExpressWayMemberDetailSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_EXPRESSWAY_MEMBER_DETAIL.GET_EXPRESSWAY_MEMBER_DETAIL_SUCCESS,
      payload: res.data,
    });
  };

export const getExpressWayMemberDetailError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_EXPRESSWAY_MEMBER_DETAIL.GET_EXPRESSWAY_MEMBER_DETAIL_ERROR,
    });
  };

export const ExpressWayMemberAction =
  (
    showToast = true,
    detail: any,
    action: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(ExpressWayMemberActionInit(showToast, detail, action));
  };

export const ExpressWayMemberActionInit =
  (
    showToast = true,
    detail: any,
    action: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ACT_EXPRESSWAY_MEMBER_DETAIL.ACT_EXPRESSWAY_MEMBER_DETAIL_INITIALIZATION,
    });
    apiCall(
      `${ACT_EXPRESSWAY_MEMBER_DETAIL_URL}${action}`,
      detail,
      (res: Object) => dispatch(ExpressWayMemberActionSuccess(res)),
      (err: any) => dispatch(ExpressWayMemberActionError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const ExpressWayMemberActionSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ACT_EXPRESSWAY_MEMBER_DETAIL.ACT_EXPRESSWAY_MEMBER_DETAIL_SUCCESS,
      payload: res.data,
    });
  };

export const ExpressWayMemberActionError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ACT_EXPRESSWAY_MEMBER_DETAIL.ACT_EXPRESSWAY_MEMBER_DETAIL_ERROR,
    });
  };

export const UserMemberDenied =
  (
    showToast = true,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(UserMemberDeniedInit(showToast, id));
  };

export const UserMemberDeniedInit =
  (
    showToast = true,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DENIED_MEMEBER_DETAIL.DENIED_MEMEBER_DETAIL_INITIALIZATION,
    });
    apiCall(
      `${DENIED_MEMEBER_DETAIL_URL}${id}`,
      {},
      (res: Object) => dispatch(UserMemberDeniedSuccess(res)),
      (err: any) => dispatch(UserMemberDeniedError()),
      METHOD.DELETE,
      { showToast },
    );
  };

export const UserMemberDeniedSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DENIED_MEMEBER_DETAIL.DENIED_MEMEBER_DETAIL_SUCCESS,
      payload: res.data,
    });
  };

export const UserMemberDeniedError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: DENIED_MEMEBER_DETAIL.DENIED_MEMEBER_DETAIL_ERROR,
    });
  };

export const UserMemberModify =
  (
    showToast = true,
    detail: any,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(UserMemberModifyInit(showToast, detail, id));
  };

export const UserMemberModifyInit =
  (
    showToast = true,
    detail: any,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: MODIFY_MEMBER_DETAIL.MODIFY_MEMBER_DETAIL_INITIALIZATION,
    });
    apiCall(
      `${MODIFY_MEMBER_DETAIL_URL}${id}`,
      detail,
      (res: Object) => dispatch(UserMemberModifySuccess(res)),
      (err: any) => dispatch(UserMemberModifyError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const UserMemberModifySuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: MODIFY_MEMBER_DETAIL.MODIFY_MEMBER_DETAIL_SUCCESS,
      payload: res.data,
    });
  };

export const UserMemberModifyError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: MODIFY_MEMBER_DETAIL.MODIFY_MEMBER_DETAIL_ERROR,
    });
  };

export const pendingEmailVerificationList =
  (
    showToast = true,
    pageNumber: any,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      pendingEmailVerificationListInit(showToast, pageNumber, pageLimit),
    );
  };

export const pendingEmailVerificationListInit =
  (
    showToast = true,
    pageNumber: any,
    pageLimit: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_EMAIL_VERFICATION_LIST.GET_EMAIL_VERFICATION_LIST_INITIALIZATION,
    });
    apiCall(
      `${GET_EMAIL_VERFICATION_LIST_URL}?page=${pageNumber}&perPage=${pageLimit}&sortBy=createdAt&sort=DESC`,
      {},
      (res: Object) => dispatch(pendingEmailVerificationListSuccess(res)),
      (err: any) => dispatch(pendingEmailVerificationListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const pendingEmailVerificationListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_EMAIL_VERFICATION_LIST.GET_EMAIL_VERFICATION_LIST_SUCCESS,
      payload: res,
    });
  };

export const pendingEmailVerificationListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_EMAIL_VERFICATION_LIST.GET_EMAIL_VERFICATION_LIST_ERROR,
    });
  };
export const getAllUsersList =
  (
    showToast = true,
    // status: string,
    pageNumber: number,
    isUser = false,
    filter = "",
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      getAllUsersListInit(
        showToast,
        // status,
        pageNumber,
        isUser,
        filter,
      ),
    );
  };

export const getAllUsersListInit =
  (
    showToast = true,
    // status: string,
    pageNumber: number,
    isUser: boolean,
    filter: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ALL_USERS_LIST.GET_ALL_USERS_LIST_INITIALIZATION,
    });
    let url = `${GET_ALL_USERS_LIST_URL}?page=${pageNumber}&perPage=${process.env.REACT_APP_RECORDS_PER_PAGE}&sort=DESC&sortBy=updatedAt`;
    if (isUser) {
      url = `${GET_ALL_USERS_LIST_URL}?page=${pageNumber}&perPage=${process.env.REACT_APP_RECORDS_PER_PAGE}${filter}&sort=DESC&sortBy=updatedAt`;
    }

    if (isUser && pageNumber == 0) {
      url = `${GET_ALL_USERS_LIST_URL}?sort=DESC&sortBy=updatedAt${filter}`;
    }
    apiCall(
      url,
      {},
      (res: Object) => dispatch(getAllUsersListSuccess(res)),
      (err: any) => dispatch(getAllUsersListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getAllUsersListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ALL_USERS_LIST.GET_ALL_USERS_LIST_SUCCESS,
      payload: res,
    });
  };

export const getAllUsersListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_ALL_USERS_LIST.GET_ALL_USERS_LIST_ERROR,
    });
  };

export const getAllConsumer =
  (
    showToast = true,
    pageNumber: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getAllConsumerInit(showToast, pageNumber, pageLimit));
  };

export const getAllConsumerInit =
  (
    showToast = true,
    pageNumber: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ALL_CONSUMER.GET_ALL_CONSUMER_INITIALIZATION,
    });
    const url = `${GET_ALL_CONSUMER_URL}`;
    apiCall(
      url,
      {},
      (res: Object) => dispatch(getAllConsumerSuccess(res)),
      (err: any) => dispatch(getAllConsumerError()),
      METHOD.GET,
      { showToast, params: { pageIndex: pageNumber - 1, pageSize: pageLimit } },
    );
  };

export const getAllConsumerSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ALL_CONSUMER.GET_ALL_CONSUMER_SUCCESS,
      payload: res,
    });
  };

export const getAllConsumerError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_ALL_CONSUMER.GET_ALL_CONSUMER_ERROR,
    });
  };

export const getConsumerAccountsByUser =
  (
    showToast = true,
    id: number,
    pageNumber: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      getConsumerAccountsByUserInit(showToast, id, pageNumber, pageLimit),
    );
  };

export const getConsumerAccountsByUserInit =
  (
    showToast = true,
    id: number,
    pageNumber: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_CONSUMER_ACCOUNTS_BY_USER.GET_CONSUMER_ACCOUNTS_BY_USER_INITIALIZATION,
    });
    const url = `/users/${id}/consumerAccounts`;
    apiCall(
      url,
      {},
      (res: Object) => dispatch(getConsumerAccountsByUserSuccess(res)),
      (err: any) => dispatch(getConsumerAccountsByUserError()),
      METHOD.GET,
      { showToast, params: { pageIndex: pageNumber, pageSize: pageLimit } },
    );
  };

export const getConsumerAccountsByUserSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_CONSUMER_ACCOUNTS_BY_USER.GET_CONSUMER_ACCOUNTS_BY_USER_SUCCESS,
      payload: res,
    });
  };

export const getConsumerAccountsByUserError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_CONSUMER_ACCOUNTS_BY_USER.GET_CONSUMER_ACCOUNTS_BY_USER_ERROR,
    });
  };
export const getConsumerProductPurchaseByUser =
  (
    showToast = true,
    id: number,
    pageNumber: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      getConsumerProductPurchaseByUserInit(
        showToast,
        id,
        pageNumber,
        pageLimit,
      ),
    );
  };

export const getConsumerProductPurchaseByUserInit =
  (
    showToast = true,
    id: number,
    pageNumber: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_CONSUMER_PRODUCT_PURCHASE_BY_USER.GET_CONSUMER_PRODUCT_PURCHASE_BY_USER_INITIALIZATION,
    });
    const url = `/users/${id}/consumer-product-purchase`;
    apiCall(
      url,
      {},
      (res: Object) => dispatch(getConsumerProductPurchaseByUserSuccess(res)),
      (err: any) => dispatch(getConsumerProductPurchaseByUserError()),
      METHOD.GET,
      { showToast, params: { pageIndex: pageNumber, pageSize: pageLimit } },
    );
  };

export const getConsumerProductPurchaseByUserSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_CONSUMER_PRODUCT_PURCHASE_BY_USER.GET_CONSUMER_PRODUCT_PURCHASE_BY_USER_SUCCESS,
      payload: res,
    });
  };

export const getConsumerProductPurchaseByUserError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_CONSUMER_PRODUCT_PURCHASE_BY_USER.GET_CONSUMER_PRODUCT_PURCHASE_BY_USER_ERROR,
    });
  };
