/* eslint-disable no-case-declarations */
import {
  // GET_ACTIVITY_POINTS_LIST,
  // EDIT_ACTIVITY_DETAILS_LIST,
  // GET_ACTIVITY_DETAILS_LIST,
  // GET_ACTIVITY_ATTENDENCE_LIST,
  ADD_ACTIVITY_LIST,
  DELETE_ADVERTISEMENT,
  EDIT_ADVERTISEMENT,
  // GET_ACTIVITY_LIST,
  // GET_ACTIVITY_ATTENDENCE_LOG_LIST,
  GET_ADVERTISEMENT_LIST,
  GET_ADVERTISEMENT_TYPE_LIST,
  GET_ADVERTISEMENT_TYPE_TOOL_LIST,
} from "../constants/action-types";
import { AdvertisementData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";
// import { ADVERTISMENT_LIST_TABS } from "../../Constants";
// import { Posts } from "../Actions";

const initialState = {
  progress: false,
  isLoading: false,
  onSaveLoading: false,
  activeTab: 0,
  advertisementList: [],
  advertisementTypeList: [],
  advertisementToolTypeList: [],
  totalRecord: 0,
  advertisementEditSuccess: false,
  advertisementDeleteSuccess: false,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: AdvertisementData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case "SET_TAB":
        return {
          ...state,
          activeTab: action.payload,
        };

      case GET_ADVERTISEMENT_LIST.GET_ADVERTISEMENT_LIST_INITIALIZATION:
        return {
          ...state,
          advertisementList: [],
          isLoading: true,
        };

      case GET_ADVERTISEMENT_LIST.GET_ADVERTISEMENT_LIST_SUCCESS:
        const advertisementList: any = action.payload; // [{ id: 2, body: "Abcd", title: "title", userId: 3 }, { id: 2, body: "Abcd", title: "title", userId: 3 }];
        return {
          ...state,
          advertisementList: advertisementList.data,
          isLoading: false,
          totalRecord: advertisementList.totalCount,
        };

      case GET_ADVERTISEMENT_LIST.GET_ADVERTISEMENT_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_ADVERTISEMENT_TYPE_LIST.GET_ADVERTISEMENT_TYPE_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_ADVERTISEMENT_TYPE_LIST.GET_ADVERTISEMENT_TYPE_LIST_SUCCESS:
        const advertisementTypeList = action.payload; // [{ id: 2, body: "Abcd", title: "title", userId: 3 }, { id: 2, body: "Abcd", title: "title", userId: 3 }];
        return {
          ...state,
          advertisementTypeList: advertisementTypeList,
          isLoading: false,
        };

      case GET_ADVERTISEMENT_TYPE_LIST.GET_ADVERTISEMENT_TYPE_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };
      case GET_ADVERTISEMENT_TYPE_TOOL_LIST.GET_ADVERTISEMENT_TYPE_TOOL_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_ADVERTISEMENT_TYPE_TOOL_LIST.GET_ADVERTISEMENT_TYPE_TOOL_LIST_SUCCESS:
        const advertisementToolTypeList = action.payload; // [{ id: 2, body: "Abcd", title: "title", userId: 3 }, { id: 2, body: "Abcd", title: "title", userId: 3 }];
        return {
          ...state,
          advertisementToolTypeList: advertisementToolTypeList,
          isLoading: false,
        };

      case GET_ADVERTISEMENT_TYPE_TOOL_LIST.GET_ADVERTISEMENT_TYPE_TOOL_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };
      case ADD_ACTIVITY_LIST.ADD_ACTIVITY_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case ADD_ACTIVITY_LIST.ADD_ACTIVITY_LIST_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_ACTIVITY_LIST.ADD_ACTIVITY_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case EDIT_ADVERTISEMENT.EDIT_ADVERTISEMENT_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
          advertisementEditSuccess: false,
        };

      case EDIT_ADVERTISEMENT.EDIT_ADVERTISEMENT_SUCCESS:
        return {
          ...state,
          isLoading: false,
          advertisementEditSuccess: true,
        };

      case EDIT_ADVERTISEMENT.EDIT_ADVERTISEMENT_ERROR:
        return {
          ...state,
          isLoading: false,
          advertisementEditSuccess: false,
        };
      case DELETE_ADVERTISEMENT.DELETE_ADVERTISEMENT_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
          advertisementDeleteSuccess: false,
        };

      case DELETE_ADVERTISEMENT.DELETE_ADVERTISEMENT_SUCCESS:
        return {
          ...state,
          isLoading: false,
          advertisementDeleteSuccess: true,
        };

      case DELETE_ADVERTISEMENT.DELETE_ADVERTISEMENT_ERROR:
        return {
          ...state,
          isLoading: false,
          advertisementDeleteSuccess: false,
        };
      default:
        return state;
    }
  },
);

export default reducer;
