import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import Loader from "../common/Loader";
import { dispatch } from "../../redux";
import { Switch } from "@mui/material";
import { Modal } from "react-bootstrap";
import draftToHtml from "draftjs-to-html";
import EditorContainer from "../RichTextEditor/Editor";
import { convertToRaw, EditorState, convertFromHTML } from "draft-js";
import Select from "react-select";
import { FormattedMessage } from "react-intl";
import { FaTimesCircle } from "react-icons/fa";
import { editFaqList, getFAQCategoryList } from "../../redux/action/faqActions";
import { EditFaqSchema } from "../../util/validationSchema";
import { ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";

const EditFaqModal = ({ onModalClose, faq }: any) => {
  const [ButtonClick, setButtonClick] = useState<boolean>(false);
  const [answer, setAnswer] = useState<string>("Inital Test");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [selectedClass, setselectedClass] = useState<Record<string, any>>({});
  const isAPILoading = useSelector(
    (state: Store) => state.faqReducer.onSaveLoading,
  );

  const FaqCategoryList = useSelector(
    (state: Store) => state.faqReducer.FAQCategoryList,
  );

  const Filterfaqcategorylist = FaqCategoryList.filter(
    (data: any) => data.isActive == true,
  );

  useEffect(() => {
    dispatch(getFAQCategoryList(false));
  }, []);

  useEffect(() => {
    if (faq && Object.keys(faq).length) {
      setAnswer(() => faq.answer);
      if (faq.answer && faq.answer?.trim() !== "<p></p>") {
        const blocksFromHTML = htmlToDraft(faq.answer);
        let estate: any;
        try {
          estate = EditorState.createWithContent(
            ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap,
            ),
          );

          setEditorState(estate);
        } catch (e: any) {
          const estate = EditorState.createEmpty();
          setEditorState(estate);
        }
        setEditorState(estate);
      } else {
        const estate = EditorState.createEmpty();
        setEditorState(estate);
      }
    }
    setselectedClass(faq?.faqCategory);
  }, [faq]);

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    formik.setFieldValue(
      "answer",
      draftToHtml(convertToRaw(editorState.getCurrentContent())),
    );
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      faqCategory: faq?.faqCategory?.id,
      question: faq?.question,
      answer: faq?.answer,
      code: faq?.code,
      isActive: faq?.isActive,
    },
    validationSchema: EditFaqSchema,
    onSubmit: (values: Record<string, any>) => {
      if (!ButtonClick) {
        if (Object.keys(faq).length > 0) {
          dispatch(editFaqList(true, faq.id, values));
        }
        setButtonClick(true);
      }
    },
  });

  useEffect(() => {
    if (!isAPILoading && ButtonClick) {
      onModalClose();
      setButtonClick(false);
    }
  }, [isAPILoading, ButtonClick]);

  const { touched, errors } = formik;
  return (
    <Modal centered show={true} onHide={() => onModalClose(false)}>
      <Modal.Header>
        <h4 className="modal-title" id="AddCourseLabel">
          <FormattedMessage id="Faq.EditFaq" defaultMessage="Edit FAQ" />
        </h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onModalClose}
        >
          <FaTimesCircle />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label className="control-label">
            <FormattedMessage
              id="Faq.FaqCategory"
              defaultMessage="FaqCategory"
            />
          </label>
          <Select
            name="class_group"
            value={selectedClass}
            onChange={(option: any) => {
              formik.setFieldValue("faqCategory", option.id);
              setselectedClass(option);
            }}
            onBlur={() => formik.setFieldTouched("faqCategory", true)}
            options={Filterfaqcategorylist}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            className="custom-select-dropdown multi w-100p"
            classNamePrefix="react-select-dropdown"
          />

          {errors.faqCategory && touched.faqCategory && (
            <div className="text-error">
              <FormattedMessage
                id={"script.faqCategory.required"}
                defaultMessage="This field is required"
              />
            </div>
          )}
        </div>
        <div className="form-group">
          <label className="control-label">
            <FormattedMessage id="Faq.Question" defaultMessage="Question" />
          </label>
          <input
            type="text"
            name="question"
            value={formik.values.question}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="form-control"
          />
          {errors.question && touched.question && (
            <div className="text-error">
              <FormattedMessage
                id={"script.question.required"}
                defaultMessage="This field is required"
              />
            </div>
          )}
        </div>
        <div className="form-group">
          <label className="control-label">
            <FormattedMessage id="Faq.Answer" defaultMessage="Answer" />
          </label>
          {answer && (
            <EditorContainer
              onEditorStateChange={onEditorStateChange}
              editorState={editorState}
              toolbar={true}
              editable={true}
              height={"200px"}
              overflowY={"scroll"}
              border={"1px solid #dfe7f3"}
            />
          )}
          {errors.answer && touched.answer && (
            <div className="text-error">
              <FormattedMessage
                id={"script.answer.required"}
                defaultMessage="This field is required"
              />
            </div>
          )}
        </div>
        <div className="form-group">
          <label className="control-label">
            <FormattedMessage id="Faq.Code" defaultMessage="Code" />
          </label>
          <input
            type="text"
            name="code"
            value={formik.values.code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="form-control"
          />
          {errors.code && touched.code && (
            <div className="text-error">
              <FormattedMessage
                id={"script.code.required"}
                defaultMessage="This field is required"
              />
            </div>
          )}
        </div>

        <div className="form-group">
          <FormattedMessage id="Faq.Isactive" defaultMessage="isActive" />
          <Switch
            checked={formik.values.isActive}
            onChange={() => {
              formik.setFieldValue("isActive", !formik.values.isActive);
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          onClick={onModalClose}
        >
          <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
        </button>
        <button
          type="button"
          className="btn btn-primary"
          style={{ display: "inherit" }}
          onClick={() => formik.handleSubmit()}
          data-dismiss="modal"
        >
          <FormattedMessage id="page.submit" defaultMessage="Submit" />
          {isAPILoading && <Loader />}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditFaqModal;
