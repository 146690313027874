import React, { useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { Button } from "react-bootstrap";
import {
  BsFillTrashFill,
  BsPencilSquare,
  BsPlusSquare,
  BsTrash,
} from "react-icons/bs";
import AddMemberinGroup from "./AddMemberinGroup";
import {
  AddGroupMemberList,
  deleteMediaLocalInit,
  DeleteTicketGroupInit,
  GetAdminMemberList,
  getClasses,
} from "../../redux/action";
import { dispatch } from "../../redux";
import { FormattedMessage } from "react-intl";
import { GROUP_TYPE } from "../../Constants";
import { Store } from "../../redux/Actions";
import { useSelector } from "react-redux";
import CenterLoader from "../common/CenterLoader/CenterLoader";
import DeletePopup from "../common/Popups/DeletePopup";

const ListGroup = ({ GroupList, type }: any) => {
  const adminMember: any = useSelector(
    (store: Store) => store.ticketReducer.adminMember,
  );

  const isLoading = useSelector(
    (state: Store) => state.ticketReducer.isLoading || false,
  );
  const classes: any = useSelector(
    (store: Store) => store.classReducer.classes,
  );

  const [members, setMembers] = React.useState<string | false>(false);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [addMember, setAddMember] = React.useState<any>({
    group: 0,
    show: false,
    users: [],
  });
  const [deleteGroup, setDeleteGroup] = React.useState<any>({
    modal: false,
    id: 0,
    groupType: "Ticket",
  });

  useEffect(() => {
    // dispatch(GetAdminMemberList(false));
    if (GROUP_TYPE.TICKET === type) {
      setMembers(adminMember);
    }
    if (GROUP_TYPE.CLASS === type) {
      setMembers(classes);
    }
  }, [type, adminMember, classes]);

  useEffect(() => {
    dispatch(GetAdminMemberList(false, type));
  }, []);

  useEffect(() => {
    dispatch(getClasses(false, 0, { order: "DESC", by: "createdAt" }));
  }, []);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const onSave = (members: any) => {
    dispatch(
      AddGroupMemberList(
        {
          groupId: addMember.group,
          userId: members,
          groupType: type,
        },
        true,
      ),
    );
  };

  const onDelete = (id: number, groupType: string) => {
    dispatch(DeleteTicketGroupInit(true, { id, groupType }));
    setDeleteGroup({ modal: false, id: 0, groupType: "Ticket" });
  };

  return (
    <div>
      {isLoading && <CenterLoader />}
      {!isLoading &&
        GroupList.map((vt: any) => (
          <Accordion
            expanded={expanded === vt.id}
            onChange={handleChange(vt.id)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <a>
                <Typography title="click here to edit Vps types">
                  {/* {vt.group_name} */}
                  {vt.group_name}
                </Typography>
              </a>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ textAlign: "end" }} className="mb-3">
                {type === GROUP_TYPE.TICKET && (
                  <Button
                    onClick={() => {
                      setAddMember(() => ({
                        group: vt.id,
                        show: true,
                        users: vt.users,
                      }));
                    }}
                  >
                    <BsPlusSquare className="mr-1" />{" "}
                    <FormattedMessage
                      id="Tickets.AddMember"
                      defaultMessage="Add Member"
                    />
                  </Button>
                )}
                {type === GROUP_TYPE.CLASS && (
                  <Button
                    onClick={() => {
                      setAddMember(() => ({
                        group: vt.id,
                        show: true,
                        users: vt.class_group,
                      }));
                    }}
                  >
                    <BsPlusSquare className="mr-1" />{" "}
                    <FormattedMessage
                      id="Tickets.AddMember"
                      defaultMessage="Add Member"
                    />
                  </Button>
                )}

                <Button
                  onClick={() => {
                    setDeleteGroup(() => ({
                      modal: true,
                      id: vt?.id,
                      groupType:
                        type === GROUP_TYPE.TICKET ? "Ticket" : "Class",
                    }));
                  }}
                  className="ml-2"
                >
                  <BsFillTrashFill className="mr-1" />
                  <FormattedMessage
                    id="Tickets.DeleteGroup"
                    defaultMessage="Delete Group"
                  />
                </Button>
                {/* <Button
                onClick={() => {
                  setAddMember(() => ({
                    group: vt.id,
                    show: true,
                    users: vt.users,
                  }));
                }}
              >
                <BsPlusSquare />
              </Button> */}
                {/* <Button><BsPencilSquare /></Button> */}
              </div>
              {type === GROUP_TYPE.TICKET && <GroupUserList users={vt.users} />}
              {type === GROUP_TYPE.CLASS && (
                <GroupClassList users={vt.class_group} />
              )}
            </AccordionDetails>
          </Accordion>
        ))}

      {addMember.show && (
        <AddMemberinGroup
          onModalClose={() =>
            setAddMember({
              group: 0,
              show: false,
            })
          }
          users={addMember.users}
          allUser={members}
          onSave={onSave}
          type={type}
        />
      )}

      {deleteGroup && (
        <DeletePopup
          onClose={() => {
            setDeleteGroup({ modal: false, id: 0, groupType: "Ticket" });
          }}
          onDeleteClick={() => onDelete(deleteGroup.id, deleteGroup.groupType)}
          open={deleteGroup.modal}
        />
      )}
    </div>
  );
};

export default ListGroup;

const GroupUserList = ({ users }: any) => {
  return (
    <div className="responsive-table">
      {(users || []).length > 0 ? (
        <table className="table table-bordered group-table">
          <tr>
            <th>
              <FormattedMessage
                id="Tickets.MemberId"
                defaultMessage="Member Id"
              />
            </th>
            <th>
              <FormattedMessage id="Tickets.Name" defaultMessage="Name" />
            </th>
            <th>
              <FormattedMessage id="Tickets.Email" defaultMessage="Email" />
            </th>
            <th>
              <FormattedMessage id="Tickets.Phone" defaultMessage="Phone" />
            </th>
          </tr>
          {users.map((i: any) => (
            <tr>
              <td>{i.id}</td>
              <td>{i.englishFirstName + " " + i.englishLastName}</td>
              <td>{i.email}</td>
              <td>
                +{i?.dialCode || ""} {i.phoneNumber}
              </td>
              {/* <td><BsTrash /></td> */}
            </tr>
          ))}
        </table>
      ) : (
        <h3
          style={{ textAlign: "center" }}
          className="font-size-24 font-weight-300"
        >
          {" "}
          <FormattedMessage
            id="no.Datafound"
            defaultMessage="No data found "
          />{" "}
        </h3>
      )}
    </div>
  );
};

const GroupClassList = ({ users }: any) => {
  return (
    <>
      {(users || []).length > 0 ? (
        <table className="table table-bordered">
          <tr>
            <th style={{ width: "20%" }}>
              <FormattedMessage
                id="Tickets.MemberId"
                defaultMessage="Member Id"
              />
            </th>
            <th style={{ width: "80%" }}>
              <FormattedMessage id="Tickets.Name" defaultMessage="Name" />
            </th>
            {/* <th>
              <FormattedMessage id="Tickets.Email" defaultMessage="Email" />
            </th>
            <th>
              <FormattedMessage id="Tickets.Phone" defaultMessage="Phone" />
            </th> */}
          </tr>
          {users.map((i: any) => (
            <tr>
              <td>{i.id}</td>
              {/* <td>{i.englishFirstName + "" + i.englishLastName}</td> */}
              {/* <td>{i.email}</td> */}
              <td>{i.name}</td>
              {/* <td><BsTrash /></td> */}
            </tr>
          ))}
        </table>
      ) : (
        <h3
          style={{ textAlign: "center" }}
          className="font-size-24 font-weight-300"
        >
          {" "}
          <FormattedMessage
            id="no.Datafound"
            defaultMessage="No data found "
          />{" "}
        </h3>
      )}
    </>
  );
};
