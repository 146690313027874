import { z } from "zod";

export const createPlanCategorySchema = z.object({
  name: z
    .string()
    .trim()
    .min(1, { message: "Name is required." })
    .max(255, { message: "Name is too long." }),
});
export type CreatePlanCategoryDto = z.infer<typeof createPlanCategorySchema>;
