import { useState } from "react";
import { Card } from "react-bootstrap";
// import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
// import { Store } from "../../../redux/Actions";

import { MEMBERDETAIL_LIST_TABS } from "../../../Constants";
import MemberDetail from "./MemberDetail";
import AttendenceList from "./AttendenceList";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { BsChevronLeft } from "../../../reactIcons";

const Member = () => {
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();
  const setAciveTab = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };
  const classes = useStyles();

  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                <FormattedMessage
                  id="head.AdminUsersDetails"
                  defaultMessage="Admin Users Details"
                />
              </h1>
            </div>
            <div className="m-l-10 common-right-button">
              <button
                onClick={() => {
                  navigate(-1);
                }}
                className="btn btn-secondary back-button"
              >
                <i className="back-button">
                  <BsChevronLeft /> &nbsp;
                </i>
                <FormattedMessage id="page.back" defaultMessage="Back" />
              </button>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          <Card className="m-0">
            <Card.Body className="p-0">
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={activeTab}
                  onChange={setAciveTab}
                  className={classes.tabs}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label={
                      <FormattedMessage
                        id="tab.Detail"
                        defaultMessage="Details"
                      />
                    }
                    tabIndex={0}
                    {...a11yProps(MEMBERDETAIL_LIST_TABS.MEMBER_DETAIL)}
                  />
                  <Tab
                    label={
                      <FormattedMessage
                        id="tab.Attendence"
                        defaultMessage="Attendence"
                      />
                    }
                    tabIndex={1}
                    {...a11yProps(MEMBERDETAIL_LIST_TABS.ATTENDENCE_LIST)}
                  />
                </Tabs>
              </Box>
              <TabPanel
                value={activeTab}
                index={MEMBERDETAIL_LIST_TABS.MEMBER_DETAIL}
              >
                <MemberDetail />
              </TabPanel>
              <TabPanel
                value={activeTab}
                index={MEMBERDETAIL_LIST_TABS.ATTENDENCE_LIST}
              >
                <AttendenceList />
              </TabPanel>
            </Card.Body>
          </Card>
        </section>
      </div>
    </div>
  );
};

export default Member;

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#68439a",
      height: 1,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#68439a",
      BsBorderBottom: "1px solid",
    },
  },
});

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
