import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import Pagination from "../../Components/common/Paginate/Pagination";
import { formatDateMM } from "../../util/utils";
import {
  getExpressWayMemberDetail,
  getMembershipExpireUserList,
} from "../../redux/action";
import { dispatch } from "../../redux";
import NoDatFound from "../../Components/common/NoDatFound";
import { BsSortDown, BsSortUp } from "react-icons/bs";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

const MembershipExpiredUserLIst = () => {
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState<any>();
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const [sortBy, setSortBy] = useState("expiryDate");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [filterQ, setFilterQ] = useState("");
  // const [text, settext] = useState("");

  const membershipExpireUserList: any = useSelector(
    (state: Store) => state.userDataReducer.membershipExpireUserList,
  ) as Array<Object>;

  const viewDetail = (id: any) => {
    localStorage.setItem("pageNumber", pageNumber);
    localStorage.setItem("pageLimit", pageLimit);
    dispatch(getExpressWayMemberDetail(false, id));
    navigate("/users/" + id);
  };

  const onPageChange = (pageNum: any) => {
    localStorage.setItem("pageNumber", pageNum.toString());
    localStorage.setItem("pageLimit", pageLimit.toString());
    setPageNumber(pageNum);
    // dispatch(
    //   getMembershipExpireUserList(
    //     false,
    //     pageNum,
    //     { order: sortOrder, by: sortBy },
    //     filterQ,
    //     pageLimit
    //   )
    // );
  };

  useEffect(() => {
    if (pageNumber && pageLimit) {
      dispatch(
        getMembershipExpireUserList(
          false,
          pageNumber,
          { order: sortOrder, by: sortBy },
          filterQ,
          pageLimit,
        ),
      );
    }
  }, [pageNumber, filterQ, sortOrder, sortBy, pageLimit]);

  useEffect(() => {
    const storedPageNumber = localStorage.getItem("pageNumber");
    const storedPageLimit = localStorage.getItem("pageLimit");
    if (storedPageNumber && storedPageLimit) {
      setPageNumber(storedPageNumber);
      setPageLimit(storedPageLimit);
    } else {
      setPageNumber(1);
      setPageLimit(10);
    }
  }, []);

  const setSorting = (prop: any) => {
    if (sortBy === prop) {
      setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
    } else {
      setSortBy(prop);
      setSortOrder("ASC");
    }
  };
  return (
    <>
      <div className="table-responsive">
        <Row className="p-20">
          <Col style={{ display: "inline-flex" }}>
            <label style={{ position: "absolute", bottom: "0" }}>
              <FormattedMessage id="Filter" defaultMessage="Filter" />:
            </label>
            <input
              onChange={(e) => {
                setFilterQ(e.target.value), setPageNumber(1);
              }}
              value={filterQ}
              type="text"
              style={{
                maxWidth: "220px",
                paddingLeft: "39px",
                borderRadius: "0px",
                border: "none",
                borderBottom: "1px solid",
              }}
              className="form-control"
            />
          </Col>
        </Row>
        {membershipExpireUserList &&
        membershipExpireUserList?.data?.length > 0 ? (
          <>
            <table className="table table-hover m-0">
              <thead>
                <tr>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => setSorting("id")}
                  >
                    <FormattedMessage
                      id="table.head.MID"
                      defaultMessage="MID"
                    />
                    {sortBy === "id" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => setSorting("englishFirstName")}
                  >
                    <FormattedMessage
                      id="table.head.Name"
                      defaultMessage="Name"
                    />
                    {sortBy === "englishFirstName" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.Nickname"
                      defaultMessage="Nickname"
                    />
                  </th>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => setSorting("email")}
                  >
                    <FormattedMessage
                      id="table.head.Email"
                      defaultMessage="Email"
                    />
                    {sortBy === "email" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.MobileNumber"
                      defaultMessage="Mobile Number"
                    />
                  </th>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => setSorting("expiryDate")}
                  >
                    <FormattedMessage
                      id="table.head.ExpiryDate"
                      defaultMessage="Expiry Date"
                    />
                    {sortBy === "expiryDate" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                </tr>
              </thead>
              <tbody>
                {membershipExpireUserList?.data.map((i: any) => (
                  <tr onClick={() => viewDetail(i.id)}>
                    <td className="pointer">{i.id}</td>
                    <td className="pointer">
                      {i.englishFirstName}
                      {i.englishNickName ? ` ${i.englishNickName} ` : " "}
                      {i.englishLastName}
                    </td>
                    <td className="pointer">{i?.preferredNickName}</td>
                    <td className="pointer">{i.email}</td>
                    <td className="pointer">
                      +{i?.dialCode || ""} {i.phoneNumber}
                    </td>
                    <td className="pointer">
                      {formatDateMM(i.expiryDate, "YYYY/MM/DD")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : (
          <NoDatFound />
        )}
      </div>
      {membershipExpireUserList?.totalCount > 10 && (
        <>
          <FormControl className="pagelimit">
            <FormLabel id="demo-row-radio-buttons-group-label">
              <FormattedMessage id="page_size" defaultMessage="Page Size" />
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={pageLimit}
              onChange={(e: any) => {
                setPageLimit(e.target.value);
                localStorage.setItem("pageLimit", e.target.value);
                localStorage.setItem("pageNumber", pageNumber);
                setPageNumber(1);
              }}
            >
              <FormControlLabel value={10} control={<Radio />} label="10" />
              <FormControlLabel value={20} control={<Radio />} label="20" />
              <FormControlLabel value={50} control={<Radio />} label="50" />
            </RadioGroup>
          </FormControl>
          <Pagination
            itemsPerPage={pageLimit}
            totalPages={membershipExpireUserList?.totalCount}
            onPageChage={onPageChange}
            page={pageNumber - 1}
          />{" "}
        </>
      )}
    </>
  );
};

export default MembershipExpiredUserLIst;
