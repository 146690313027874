import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Card, Col, Row, Button } from "react-bootstrap";
import {
  getExpressWayMemberDetail,
  getExpressWayPendingList,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import { useNavigate } from "react-router";
import { formatDateMM } from "../../util/utils";
import NoDatFound from "../../Components/common/NoDatFound";
// import { TheaterComedyOutlined } from "@mui/icons-material";
import Pagination from "../../Components/common/Paginate/Pagination";
import { FormattedMessage } from "react-intl";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

const PendingList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(
    (state: Store) => state.expressWayReducer.Loading.isLoading,
  );
  const [pageNumber, setPageNumber] = useState<any>();
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const totalRecord = useSelector(
    (state: Store) => state.expressWayReducer.totalRecord,
  ) as any;

  const pendingList = useSelector(
    (state: Store) => state.expressWayReducer.PendingList,
  ) as any;
  useEffect(() => {
    if (pageNumber && pageLimit) {
      dispatch(
        getExpressWayPendingList(
          false,
          "pending",
          pageNumber,
          false,
          "",
          pageLimit,
        ),
      );
    }
  }, [pageNumber, pageLimit]);

  const onPageChange = (pageNum: number) => {
    localStorage.setItem("pageNumber", pageNum.toString());
    localStorage.setItem("pageLimit", pageLimit);
    setPageNumber(pageNum);
  };

  useEffect(() => {
    const storedPageNumber = localStorage.getItem("pageNumber");
    const storedPageLimit = localStorage.getItem("pageLimit");
    if (storedPageNumber && storedPageLimit) {
      setPageNumber(parseInt(storedPageNumber));
      setPageLimit(parseInt(storedPageLimit));
    } else {
      setPageNumber(1);
      setPageLimit(10);
    }
  }, []);

  const viewDetail = (id: any) => {
    localStorage.setItem("pageNumber", pageNumber);
    localStorage.setItem("pageLimit", pageLimit);
    dispatch(getExpressWayMemberDetail(false, id));
    navigate("/users/expressWay/" + id);
  };

  return (
    <>
      <div className="table-responsive">
        {pendingList && pendingList.length ? (
          <>
            {" "}
            <table className="table table-hover m-0">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage
                      id="table.head.MID"
                      defaultMessage="MID"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.Class"
                      defaultMessage="Class"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.Name"
                      defaultMessage="Name"
                    />
                  </th>
                  {/* <th><FormattedMessage id="table.head.First" defaultMessage="First" /></th> */}
                  {/* <th><FormattedMessage id="table.head.Middle" defaultMessage="Middle" /></th> */}
                  <th>
                    <FormattedMessage
                      id="table.head.Nickname"
                      defaultMessage="Nickname"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.Email"
                      defaultMessage="Email"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.MobileNumber"
                      defaultMessage="Mobile Number"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.repeater"
                      defaultMessage="Repeater"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.repeaterClass"
                      defaultMessage="Repeate Class"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.RegisterDate"
                      defaultMessage="Register Date"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {pendingList.length &&
                  pendingList.map((i: any) => (
                    <tr className="pointer" onClick={() => viewDetail(i.id)}>
                      <td>{i.id}</td>
                      <td>{i?.classData ? i.classData.name : "-"}</td>
                      {/* <td>{i.chineseLastName}</td> */}
                      <td>{i.englishFirstName + " " + i.englishLastName}</td>
                      <td>{i.preferredNickName}</td>
                      <td>{i.email}</td>
                      <td>
                        +{i?.dialCode || ""} {i.phoneNumber}
                      </td>
                      <td>{i.repeater ? "Yes" : "No"}</td>
                      <td>
                        {i.repeater && i?.repeaterClass
                          ? i?.repeaterClass?.name
                          : "-"}
                      </td>
                      <td>{formatDateMM(i.createdAt, "YYYY/MM/DD")} </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        ) : (
          <p>{!isLoading && <NoDatFound />}</p>
        )}
      </div>
      {totalRecord > 10 && pendingList.length && (
        <>
          <FormControl className="pagelimit">
            <FormLabel id="demo-row-radio-buttons-group-label">
              <FormattedMessage id="page_size" defaultMessage="Page Size" />
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={pageLimit}
              onChange={(e: any) => {
                setPageLimit(e.target.value);
                localStorage.setItem("pageLimit", e.target.value);
                localStorage.setItem("pageNumber", pageNumber);
                setPageNumber(1);
              }}
            >
              <FormControlLabel value={10} control={<Radio />} label="10" />
              <FormControlLabel value={20} control={<Radio />} label="20" />
              <FormControlLabel value={50} control={<Radio />} label="50" />
            </RadioGroup>
          </FormControl>
          <Pagination
            itemsPerPage={pageLimit}
            totalPages={totalRecord}
            onPageChage={onPageChange}
            page={pageNumber - 1}
          />
        </>
      )}
    </>
  );
};

export default PendingList;
