/* eslint-disable no-case-declarations */
import {
  GET_GRANT_LIST,
  GRANT_ACCOUNT,
  GRANT_POINT_LIST,
  GRANT_TOOL_LIST,
} from "../constants/action-types";
import { GrantData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";
// import { Posts } from "../Actions";

const initialState = {
  progress: false,
  isLoading: false,
  onSaveLoading: false,
  isPointGrantsSuccess: false,
  isToolGrantsSuccess: false,
  isAccountGrantsSuccess: false,
  totalRecord: 0,
  GrantsList: [],
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: GrantData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case GET_GRANT_LIST.GET_GRANT_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
          isPointGrantsSuccess: false,
          isToolGrantsSuccess: false,
          isAccountGrantsSuccess: false,
        };

      case GET_GRANT_LIST.GET_GRANT_LIST_SUCCESS:
        const lst: any = action.payload;
        return {
          ...state,
          GrantsList: lst.data, // action.payload,
          totalRecord: lst.totalCount,
          isLoading: false,
        };

      case GET_GRANT_LIST.GET_GRANT_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GRANT_POINT_LIST.GRANT_POINT_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GRANT_POINT_LIST.GRANT_POINT_LIST_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isPointGrantsSuccess: true,
        };

      case GRANT_POINT_LIST.GRANT_POINT_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
          isPointGrantsSuccess: false,
        };

      case GRANT_TOOL_LIST.GRANT_TOOL_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GRANT_TOOL_LIST.GRANT_TOOL_LIST_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isToolGrantsSuccess: true,
        };

      case GRANT_TOOL_LIST.GRANT_TOOL_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
          isToolGrantsSuccess: true,
        };

      case GRANT_ACCOUNT.GRANT_ACCOUNT_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GRANT_ACCOUNT.GRANT_ACCOUNT_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isAccountGrantsSuccess: true,
        };

      case GRANT_ACCOUNT.GRANT_ACCOUNT_ERROR:
        return {
          ...state,
          isLoading: false,
          isAccountGrantsSuccess: true,
        };
      default:
        return state;
    }
  },
);

export default reducer;
