import React, { useState } from "react";
import { Card } from "react-bootstrap";
// import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

// import { Store } from "../../redux/Actions";

import { GIFT_LIST_TAB } from "../../Constants";
import GiftLits from "./GiftLists";
import GrantsList from "./GrantsList";
import GiftsCategory from "./GiftsCategory";
import { FormattedMessage } from "react-intl";

const Gifts = () => {
  const [activeTab, setActiveTab] = useState(0);

  // const classDetail: any = useSelector(
  //   (state: Store) => state.classReducer.classDetail
  // ) as any;

  const setAciveTab = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };
  const classes = useStyles();

  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                <FormattedMessage
                  id="header.AdminGift"
                  defaultMessage="Admin Gift"
                />
              </h1>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          <Card>
            <Card.Body className="p-0">
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={activeTab}
                  onChange={setAciveTab}
                  className={classes.tabs}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label={
                      <FormattedMessage id="tab.List" defaultMessage="List" />
                    }
                    {...a11yProps(GIFT_LIST_TAB.GIFT_LISTS)}
                  />
                  <Tab
                    label={
                      <FormattedMessage id="tab.Grant" defaultMessage="Grant" />
                    }
                    {...a11yProps(GIFT_LIST_TAB.GRANT_LISTS)}
                  />
                  {/* <Tab label="Category" {...a11yProps(GIFT_LIST_TAB.CATEGORY_LISTS)} /> */}
                </Tabs>
              </Box>
              <TabPanel value={activeTab} index={GIFT_LIST_TAB.GIFT_LISTS}>
                <GiftLits />
              </TabPanel>
              <TabPanel value={activeTab} index={GIFT_LIST_TAB.GRANT_LISTS}>
                <GrantsList />
              </TabPanel>
              <TabPanel value={activeTab} index={GIFT_LIST_TAB.CATEGORY_LISTS}>
                <GiftsCategory />
              </TabPanel>
            </Card.Body>
          </Card>
        </section>
      </div>
    </div>
  );
};

export default Gifts;

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#68439a",
      height: 1,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#68439a",
      BsBorderBottom: "1px solid",
    },
  },
});

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
