/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/APIServices/baseApiCall";
import {
  GET_INDUSTRY_LIST,
  GET_JOB_LIST,
  LOGIN,
  LOGOUT,
  CHANGE_LOCAL,
  GET_USER_DETAIL,
  EDIT_USER_DETAIL,
  GET_USER_ATTENDENCE,
  GET_REGISTRATION_MATERIAL,
  GET_QR_CODE,
  CHANGE_PASSWORD,
  GET_DASHBOARD_COUNT,
  GET_DASHBOARD_MEMBERSHIP_EXPIRED_USER_LIST,
  GET_USER_CLASS,
  GET_USER_CLASS_ROLE,
  GET_USER_PRINT_DETAIL,
  RESEND_OTP,
  SET_PAGE,
  MAKE_ADMIN,
  SET_STATUS_CODE,
  ADD_ADMIN_USER,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { getAPIEndPoint, setToken } from "../../util/utils";
import {
  GET_INDUSTRY_LIST_URL,
  GET_JOB_LIST_URL,
  USER_SIGNIN,
  GET_USER_DETAIL_URL,
  EDIT_USER_DETAIL_URL,
  GET_USER_ATTENDENCE_URL,
  GET_REGISTRATION_MATERIAL_URL,
  GET_QR_CODE_URL,
  CHANGE_PASSWORD_URL,
  GET_DASHBOARD_COUNT_URL,
  GET_USER_CLASS_URL,
  GET_USER_CLASS_ROLE_URL,
  GET_USER_PRINT_DETAIL_URL,
  RESEND_OTP_URL,
  GET_DASHBOARD_MEMBERSHIP_EXPIRED_USER_LIST_URL,
  MAKE_ADMIN_URL,
  ADD_ADMIN_USER_URL,
} from "../../service/APIServices/apiEndPoints";
import { toast } from "react-toastify";
// import { AxiosResponse } from "axios";
import { LOGIN_TYPE } from "../../util/constants/UserGroup";
import RoleList from "../../Pages/AdminRoles/RoleList";
import axios from "axios";
import { dispatch } from "..";
import moment from "moment";
import secureLocalStorage from "react-secure-storage";
// import { STATUS_CODES } from "";

export const translate =
  (local: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: CHANGE_LOCAL,
      payload: local,
    });
  };

export const setAPIStatusCode =
  (local: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: SET_STATUS_CODE,
      payload: local,
    });
  };

export const setCurrenttPage =
  (pageNumber: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: SET_PAGE,
      payload: { pageNumber: pageNumber },
    });
  };

export const login =
  (
    email: any,
    password: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(loginInit(email, password));
  };

export const loginInit =
  (
    email: any,
    password: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: LOGIN.LOGIN_INITLIZATION,
    });
    apiCall(
      USER_SIGNIN,
      {
        email: email,
        password: password,
        type: LOGIN_TYPE.ADMIN,
      },
      (res: Object) => dispatch(loginSuccess(res, email, password)),
      (err: any) => {
        dispatch(loginError());
      },
      METHOD.POST,
      { showToast: true },
    );
  };

export const loginSuccess =
  (
    res: any,
    email: any,
    password: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    setToken("login-auth-token", res.data.access_token).then(function () {
      setToken("isLoggedIn", "true");
      const date: any = moment(new Date().getTime()).add(24, "hours").valueOf();
      setToken("exp", date);

      dispatch({
        type: LOGIN.LOGIN_SUCCESS,
        payload: { response: res, email, password },
      });
    });
  };

export const loginError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: LOGIN.LOGIN_ERORR,
    });
  };

//logout
export const logout =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(logoutInit(showToast));
  };

export const logoutInit =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: LOGOUT.LOGOUT_INITLIZATION,
    });
    dispatch(logoutSuccess(showToast));
  };

export const logoutSuccess =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: LOGOUT.LOGOUT_SUCCESS,
    });
    sessionStorage.clear();
    localStorage.clear();
    secureLocalStorage.clear();

    if (showToast) {
      toast.success("logout successfully");
    }
  };

export const logoutError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: LOGOUT.LOGOUT_ERORR,
    });
  };

export const getUserDetail =
  (
    showToast = true,
    userId: string,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getUserDetailInit(showToast, userId));
  };

export const getUserDetailInit =
  (
    showToast = true,
    userId: string,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_USER_DETAIL.GET_USER_DETAIL_INITIALIZATION,
    });
    apiCall(
      `${GET_USER_DETAIL_URL}${userId}`,
      {},
      (res: Object) => dispatch(getUserDetailSuccess(res)),
      (err: any) => dispatch(getUserDetailError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getUserDetailSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_USER_DETAIL.GET_USER_DETAIL_SUCCESS,
      payload: res.data,
    });
  };

export const getUserDetailError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_USER_DETAIL.GET_USER_DETAIL_ERROR,
    });
  };

export const getUserAttendenceDetail =
  (
    showToast = true,
    userId: string,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getUserAttendenceDetailInit(showToast, userId));
  };

export const getUserAttendenceDetailInit =
  (
    showToast = true,
    userId: string,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_USER_ATTENDENCE.GET_USER_ATTENDENCE_INITIALIZATION,
    });
    apiCall(
      `${GET_USER_ATTENDENCE_URL}${userId}`,
      {},
      (res: Object) => dispatch(getUserAttendenceDetailSuccess(res)),
      (err: any) => dispatch(getUserAttendenceDetailError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getUserAttendenceDetailSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_USER_ATTENDENCE.GET_USER_ATTENDENCE_SUCCESS,
      payload: res.data,
    });
  };

export const getUserAttendenceDetailError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_USER_ATTENDENCE.GET_USER_ATTENDENCE_ERROR,
    });
  };

export const UserActionInit =
  (
    showToast = true,
    status: string,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_USER_DETAIL.EDIT_USER_DETAIL_INITIALIZATION,
    });
    apiCall(
      `${EDIT_USER_DETAIL_URL}?status=${status}`,
      {},
      (res: Object) => dispatch(UserActionSuccess(res)),
      (err: any) => dispatch(UserActionError()),
      METHOD.GET,
      { showToast },
    );
  };

export const UserActionSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_USER_DETAIL.EDIT_USER_DETAIL_SUCCESS,
      payload: res.data,
    });
  };

export const UserActionError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: EDIT_USER_DETAIL.EDIT_USER_DETAIL_ERROR,
    });
  };

export const getIndustryTypeList =
  (
    showToast = false,
    local: string,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getIndustryTypeListInit(showToast, local));
  };

export const getIndustryTypeListInit =
  (
    showToast: boolean,
    local: string,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_INDUSTRY_LIST.GET_INDUSTRY_LIST_INITLIZATION,
    });
    apiCall(
      GET_INDUSTRY_LIST_URL + local.toUpperCase(),
      {},
      (res: Object) => dispatch(getIndustryTypeListSuccess(res)),
      (err: any) => dispatch(getIndustryTypeListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getIndustryTypeListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_INDUSTRY_LIST.GET_INDUSTRY_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getIndustryTypeListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_INDUSTRY_LIST.GET_INDUSTRY_LIST_ERORR,
    });
  };

export const getJobTitleList =
  (
    showToast = false,
    local: string,
    industry: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getJobTitleListInit(showToast, local, industry));
  };

export const getJobTitleListInit =
  (
    showToast: any,
    local: string,
    industry: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_JOB_LIST.GET_JOB_LIST_INITLIZATION,
    });
    apiCall(
      GET_JOB_LIST_URL + local.toUpperCase() + "/" + industry,
      {},
      (res: Object) => dispatch(getJobTitleListSuccess(res)),
      (err: any) => dispatch(getJobTitleListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getJobTitleListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_JOB_LIST.GET_JOB_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getJobTitleListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_JOB_LIST.GET_JOB_LIST_ERORR,
    });
  };

export const getRegistrationMaterial =
  (
    showToast = true,
    local: string,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getRegistrationMaterialInit(showToast, local));
  };

export const getRegistrationMaterialInit =
  (
    showToast: boolean,
    local: string,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_REGISTRATION_MATERIAL.GET_REGISTRATION_MATERIAL_INITIALIZATION,
    });
    apiCall(
      GET_REGISTRATION_MATERIAL_URL + local.toUpperCase(),
      {},
      (res: Object) => dispatch(getRegistrationMaterialSuccess(res)),
      (err: any) => dispatch(getRegistrationMaterialError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getRegistrationMaterialSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_REGISTRATION_MATERIAL.GET_REGISTRATION_MATERIAL_SUCCESS,
      payload: res.data,
    });
  };

export const getRegistrationMaterialError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_REGISTRATION_MATERIAL.GET_REGISTRATION_MATERIAL_ERROR,
    });
  };

export const getQR =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch(getQRInit());
  };

export const getQRInit =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_QR_CODE.GET_QR_CODE_INITIALIZATION,
    });
    apiCall(
      GET_QR_CODE_URL,
      {},
      (res: Object) => dispatch(getQRSuccess(res)),
      (err: any) => {
        dispatch(getQRError());
      },
      METHOD.GET,
      {},
    );
  };

export const getQRSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    sessionStorage.removeItem("getQR_completion");
    dispatch({
      type: GET_QR_CODE.GET_QR_CODE_SUCCESS,
      payload: res.data,
    });
  };

export const getQRError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_QR_CODE.GET_QR_CODE_ERROR,
    });
  };

export const changePassword =
  (
    showToast = true,
    password: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(changePasswordInit(showToast, password));
  };

export const changePasswordInit =
  (
    showToast: boolean,
    password: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: CHANGE_PASSWORD.CHANGE_PASSWORD_INITLIZATION,
    });
    apiCall(
      CHANGE_PASSWORD_URL,
      password,
      (res: Object) => dispatch(changePasswordSuccess()),
      (err: any) => {
        dispatch(changePasswordError(err));
      },
      METHOD.PATCH,
      { showToast: showToast },
    );
  };

export const changePasswordSuccess =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: CHANGE_PASSWORD.CHANGE_PASSWORD_SUCCESS,
    });
  };

export const changePasswordError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: CHANGE_PASSWORD.CHANGE_PASSWORD_ERORR,
      payload: err?.message,
    });
  };

export const getDashboardCount =
  (showToast = false): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getDashboardCountInit(showToast));
  };

export const getDashboardCountInit =
  (showToast: boolean): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_DASHBOARD_COUNT.GET_DASHBOARD_COUNT_INITIALIZATION,
    });
    apiCall(
      GET_DASHBOARD_COUNT_URL,
      {},
      (res: Object) => dispatch(getDashboardCountSuccess(res)),
      (err: any) => {
        dispatch(getDashboardCountError(err));
      },
      METHOD.GET,
      { showToast: showToast },
    );
  };

export const getDashboardCountSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_DASHBOARD_COUNT.GET_DASHBOARD_COUNT_SUCCESS,
      payload: res,
    });
  };

export const getDashboardCountError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_DASHBOARD_COUNT.GET_DASHBOARD_COUNT_ERROR,
      payload: err?.message,
    });
  };

export const getMembershipExpireUserList =
  (
    showToast = false,
    pageNumber = 0,
    sort: any,
    filter = "",
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      getMembershipExpireUserListInit(
        showToast,
        pageNumber,
        sort,
        filter,
        pageLimit,
      ),
    );
  };

export const getMembershipExpireUserListInit =
  (
    showToast: boolean,
    pageNumber: number,
    sort: any,
    filter: any,
    pageLimit: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_DASHBOARD_MEMBERSHIP_EXPIRED_USER_LIST.GET_DASHBOARD_MEMBERSHIP_EXPIRED_USER_LIST_INITIALIZATION,
    });
    let url = `${GET_DASHBOARD_MEMBERSHIP_EXPIRED_USER_LIST_URL}?sort=${sort.order}&sortBy=${sort.by}&page=${pageNumber}&perPage=${pageLimit}`;
    if (filter !== "") {
      url = url + `&q=${encodeURIComponent(filter)}`;
    }
    apiCall(
      // getAPIEndPoint(
      //   GET_DASHBOARD_MEMBERSHIP_EXPIRED_USER_LIST_URL +
      //     `?sort=${sort.order}&sortBy=${sort.by}`,
      //   pageNumber,
      //   true
      // ),
      url,
      {},
      (res: Object) => dispatch(getMembershipExpireUserListSuccess(res)),
      (err: any) => {
        dispatch(getMembershipExpireUserListError(err));
      },
      METHOD.GET,
      { showToast: showToast },
    );
  };

export const getMembershipExpireUserListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_DASHBOARD_MEMBERSHIP_EXPIRED_USER_LIST.GET_DASHBOARD_MEMBERSHIP_EXPIRED_USER_LIST_SUCCESS,
      payload: res,
    });
  };

export const getMembershipExpireUserListError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_DASHBOARD_MEMBERSHIP_EXPIRED_USER_LIST.GET_DASHBOARD_MEMBERSHIP_EXPIRED_USER_LIST_ERROR,
      payload: err?.message,
    });
  };

export const getUserClass =
  (
    showToast = false,
    studentId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getUserClassInit(showToast, studentId));
  };

export const getUserClassInit =
  (
    showToast: boolean,
    studentId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_USER_CLASS.GET_USER_CLASS_INITIALIZATION,
    });
    apiCall(
      GET_USER_CLASS_URL + studentId,
      {},
      (res: Object) => dispatch(getUserClassSuccess(res)),
      (err: any) => {
        dispatch(getUserClassError(err));
      },
      METHOD.GET,
      { showToast: showToast },
    );
  };

export const getUserClassSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_USER_CLASS.GET_USER_CLASS_SUCCESS,
      payload: res.data,
    });
  };

export const getUserClassError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_USER_CLASS.GET_USER_CLASS_ERROR,
      payload: err?.message,
    });
  };

export const getUserClassRole =
  (
    showToast = false,
    studentId: any,
    classId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getUserClassRoleInit(showToast, studentId, classId));
  };

export const getUserClassRoleInit =
  (
    showToast: boolean,
    studentId: any,
    classId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_USER_CLASS_ROLE.GET_USER_CLASS_ROLE_INITIALIZATION,
    });
    apiCall(
      GET_USER_CLASS_ROLE_URL + studentId + "/" + classId,
      {
        studentId: studentId,
        classId: classId,
      },
      (res: Object) => dispatch(getUserClassRoleSuccess(res)),
      (err: any) => {
        dispatch(getUserClassRoleError(err));
      },
      METHOD.GET,
      { showToast: showToast },
    );
  };

export const getUserClassRoleSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_USER_CLASS_ROLE.GET_USER_CLASS_ROLE_SUCCESS,
      payload: res.data,
    });
  };

export const getUserClassRoleError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_USER_CLASS_ROLE.GET_USER_CLASS_ROLE_ERROR,
      payload: err?.message,
    });
  };

export const getUserPrintDetail =
  (
    studentId: any,
    classId: any,
    role: any,
    authToken: any,
    showToast = false,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      getUserPrintDetailInit(studentId, classId, role, authToken, showToast),
    );
  };

export const getUserPrintDetailInit =
  (
    studentId: any,
    classId: any,
    role: any,
    authToken: any,
    showToast: boolean,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_USER_PRINT_DETAIL.GET_USER_PRINT_DETAIL_INITIALIZATION,
    });
    apiCall(
      GET_USER_PRINT_DETAIL_URL + studentId + "/" + classId + "?role=" + role,
      {},
      (res: Object) => dispatch(getUserPrintDetailSuccess(res)),
      (err: any) => {
        dispatch(getUserPrintDetailError(err));
      },
      METHOD.GET,
      {
        showToast: showToast,
        headers: { Authorization: `Bearer ${authToken}` },
      },
    );
  };

export const getUserPrintDetailSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_USER_PRINT_DETAIL.GET_USER_PRINT_DETAIL_SUCCESS,
      payload: res.data,
    });
  };

export const getUserPrintDetailError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_USER_PRINT_DETAIL.GET_USER_PRINT_DETAIL_ERROR,
      payload: err?.message,
    });
  };

export const resendOtp =
  (
    email: any,
    resend: boolean,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(resendOtpInit(email, resend));
  };

export const resendOtpInit =
  (
    email: any,
    resend: boolean,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: RESEND_OTP.RESEND_OTP_INITIALIZATION,
    });
    apiCall(
      RESEND_OTP_URL,
      { email },
      (res: Object) => dispatch(resendOtpSuccess(res, resend)),
      (err: any) => {
        dispatch(resendOtpError(resend));
      },
      METHOD.POST,
      { showToast: true },
    );
  };

export const resendOtpError =
  (resend: boolean): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: RESEND_OTP.RESEND_OTP_ERROR,
      payload: resend,
    });
  };

export const resendOtpSuccess =
  (
    res: any,
    resend: boolean,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: RESEND_OTP.RESEND_OTP_SUCCESS,
      payload: resend,
    });
  };

export const makeAdminInit =
  (id: string): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: MAKE_ADMIN.MAKE_ADMIN_INITIALIZATION,
    });
    apiCall(
      MAKE_ADMIN_URL,
      { id },
      (res: Object) => dispatch(makeAdminSuccess(res)),
      (err: any) => {
        dispatch(makeAdminError(err));
      },
      METHOD.POST,
      { showToast: true },
    );
  };

export const makeAdminSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: MAKE_ADMIN.MAKE_ADMIN_SUCCESS,
      payload: res,
    });
  };

export const makeAdminError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: MAKE_ADMIN.MAKE_ADMIN_ERROR,
      payload: err?.message,
    });
  };
export const addAdmin =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(addAdminInit(data));
  };

export const addAdminInit =
  (data: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_ADMIN_USER.ADD_ADMIN_USER_INITIALIZATION,
    });
    apiCall(
      ADD_ADMIN_USER_URL,
      data,
      (res: Object) => dispatch(addAdminSuccess(res)),
      (err: any) => {
        dispatch(addAdminError());
      },
      METHOD.POST,
      { showToast: true },
    );
  };

export const addAdminSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_ADMIN_USER.ADD_ADMIN_USER_SUCCESS,
      payload: { response: res },
    });
  };

export const addAdminError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_ADMIN_USER.ADD_ADMIN_USER_ERROR,
    });
  };
