import React from "react";
import { Card } from "react-bootstrap";

const TicketSettings = () => {
  return (
    <div className="content-wrapper">
      <div className="content">
        <section className="page-content container-fluid">
          <Card style={{ width: "100%" }}>
            <Card.Body>In Progress</Card.Body>
          </Card>
        </section>
      </div>
    </div>
  );
};

export default TicketSettings;
