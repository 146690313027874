import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
import UserSelectionByClass from "../../Components/Selection/UserSelectionByClass";
import { dispatch } from "../../redux";
import {
  addGiftList,
  getClasses,
  getGiftCategoryList,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import { AddGiftSchema } from "../../util/validationSchema";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "../../Components/common/Loader";
import { disablePastDate, isPositiveNumbersOnly } from "../../util/utils";
import FileUpload from "../../Components/common/MediaUpload/FileUpload";
import { toast } from "react-toastify";
import { ISO_8601 } from "../../assets/vendor/daterangepicker/moment";
import { BsTrash } from "react-icons/bs";
import { ViewDaySharp } from "@mui/icons-material";
import { union } from "lodash";
import Loading from "../../Components/common/Loading";
import { getVPSMachineTypeList } from "../../redux/action/vpsMachineActions";

const IMAGE_TYPES = ["png", "jpeg", "jpg", "pdf"];

const GrantsList = () => {
  const giftCatList = useSelector(
    (state: Store) => state.giftReducer.giftCatList,
  );
  const [selectedGiftCat, setselectedGiftCat] = useState<any>({
    id: 1,
    name: "VPS",
    show: true,
    beforeImage: "https://gemsfx-assets.s3.amazonaws.com/gift/image/vps.png",
    afterImage: "vps_redeemed.png",
    info: null,
    createdAt: "2020-04-30T14:48:42.000Z",
    updatedAt: "2022-12-29T08:22:37.000Z",
    isActive: true,
  });

  const userSelectionRef = useRef<any>();
  const isLoading = useSelector((state: Store) => state.giftReducer.isLoading);
  const [buttonClick, setButtonClick] = useState({});
  const [memberCodes, setMemberCodes] = useState<any>({});
  const [members, setMembers] = useState<any>({});
  const [files, setFiles] = useState<any>(null);

  const [attachFileUploadURL, setAttachFileUploadURL] = useState<any>(null);
  const [fileLoading, setFileLoading] = useState(false);
  const [attachFiles, setAttachFiles] = useState<any>(null);

  const local = useSelector((state: Store) => state.userDataReducer.local);
  const VPSMachineTypeList = useSelector(
    (state: Store) => state.vpsMachineReducer.VPSMachineTypeList,
  );

  useEffect(() => {
    dispatch(getClasses(false, 0, { by: "createdAt", order: "ASC" }));
    dispatch(getGiftCategoryList(false));
    dispatch({ type: "CLEAR_DATA" });
    dispatch(getVPSMachineTypeList());
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      details: "",
      amount: "",
      reason: "",
      code: "",
      giftAttachmentPath: "",
      pointsRequired: "",
      exipryDate: "",
      giftCategory: 1,
      userIds: [],
      additionalUserIds: "",
      parsedAdditionalUserIds: [],
    },
    validationSchema: AddGiftSchema,
    onSubmit: async (values) => {
      if (!buttonClick) {
        const ids: any = values.userIds;
        if (values.giftCategory == 1) {
          if (memberCodes.length > 0) {
            toast.error("File doesn't contain a members");
          } else {
            if (
              Object.keys(memberCodes).every((value: any) =>
                ids.includes(value),
              )
            ) {
              const vpscat: any = [];
              ids.map((i: any) => {
                vpscat.push({
                  id: i,
                  code: memberCodes[i][0],
                });
              });

              const data: any = {
                ...values,
                pointsRequired: parseInt(values.pointsRequired),
                amount: 0,
                giftAttachmentPath: "",
                vpscat: vpscat,
                userIds: union(values.userIds, values.parsedAdditionalUserIds),
              };
              delete data.parsedAdditionalUserIds;
              delete data.additionalUserIds;
              // values.amount = parseInt(values.amount);
              dispatch(addGiftList(true, data));
              setButtonClick(true);
            } else {
              toast.warning(
                "Member id of selected user and codes do not match",
              );
            }
          }
        } else if (values.giftCategory === 11) {
          // let  days = 0;

          const data: any = {
            ...values,
            days: "0",
            pointsRequired: parseInt(values.pointsRequired),
            amount: 0,
            giftAttachmentPath: attachFiles,
            userIds: union(values.userIds, values.parsedAdditionalUserIds),
          };
          delete data.parsedAdditionalUserIds;
          delete data.additionalUserIds;
          dispatch(addGiftList(true, data));
        } else {
          let code = "",
            days = 0;
          const amount = 0;
          if (values.giftCategory === 2) {
            code = values.code;
            // values.amount = 0;
          }
          if ([5, 16].includes(values.giftCategory)) {
            days = parseInt(values.amount);
            // values.amount = 0;
          }
          if (values.giftCategory === 6) {
            //1 month Membership with categoryId = 6
            days = 30; // parseInt(values.amount);
            // values.amount = 0;
          }

          if (values.giftCategory === 7) {
            //3 month Membership with categoryId = 7
            days = 90; // parseInt(values.amount);
            // values.amount = 0;
          }

          if (values.giftCategory === 8) {
            //6 month Membership with categoryId = 8
            days = 180; // parseInt(values.amount);
            // values.amount = 0;
          }

          if (values.giftCategory === 12) {
            //3 month Membership with categoryId = 12
            code = values.code; // parseInt(values.amount);
            // values.amount = 0;
          }

          if (values.giftCategory === 13) {
            //6 month Membership with categoryId = 13
            code = values.code; // parseInt(values.amount);
            // values.amount = 0;
          }

          if (values.giftCategory === 14) {
            //12 month Membership with categoryId = 14
            code = values.code; // parseInt(values.amount);
            // values.amount = 0;
          }

          const data: any = {
            ...values,
            // amount: parseInt(values.pointsRequired),
            amount: 0,
            code: code.toString(),
            giftAttachmentPath: "",
            days: days.toString(),
            userIds: union(values.userIds, values.parsedAdditionalUserIds),
          };
          delete data.parsedAdditionalUserIds;
          delete data.additionalUserIds;
          dispatch(addGiftList(true, data));
        }
      }
      setButtonClick(true);
    },
  });

  const { touched, errors, resetForm, setFieldError, setFieldValue } = formik;

  const handleParsedAdditionalUserIds = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const s = e.target.value.replace(/\n+$/, "");
    if (s === "") {
      setFieldError("parsedAdditionalUserIds", undefined);
      setFieldValue("parsedAdditionalUserIds", []);
      return;
    }

    const parsedAdditionalUserIds = s.split("\n").map((c) => parseInt(c));
    if (parsedAdditionalUserIds.some((d) => isNaN(d))) {
      setFieldError("parsedAdditionalUserIds", "Check you input format");
      setFieldValue("parsedAdditionalUserIds", []);
    } else {
      setFieldError("parsedAdditionalUserIds", undefined);
      setFieldValue("parsedAdditionalUserIds", parsedAdditionalUserIds);
    }
  };

  useEffect(() => {
    if (!isLoading && buttonClick) {
      resetForm();
      setselectedGiftCat({
        id: 1,
        name: "VPS",
        show: true,
        beforeImage:
          "https://gemsfx-assets.s3.amazonaws.com/gift/image/vps.png",
        afterImage: "vps_redeemed.png",
        info: null,
        createdAt: "2020-04-30T14:48:42.000Z",
        updatedAt: "2022-12-29T08:22:37.000Z",
        isActive: true,
      });
      if (formik.values.giftCategory !== 1) {
        userSelectionRef?.current();
      }
      setButtonClick(false);
      setFiles(null);

      setAttachFiles(null);
      setAttachFileUploadURL(null);
    }
  }, [isLoading]);

  const onFormReset = (e: any) => {
    e.preventDefault();
    setFiles(null);
    setselectedGiftCat({
      id: 1,
      name: "VPS",
      show: true,
      beforeImage: "https://gemsfx-assets.s3.amazonaws.com/gift/image/vps.png",
      afterImage: "vps_redeemed.png",
      info: null,
      createdAt: "2020-04-30T14:48:42.000Z",
      updatedAt: "2022-12-29T08:22:37.000Z",
      isActive: true,
    });
    resetForm();
    userSelectionRef?.current();
  };

  const handleDrop = (e: any) => {
    const acceptedFiles = e.target.files[0];
    setFiles(e.target.files[0].name);
    readCSVFile(acceptedFiles);
    // e.target.value = "";
  };

  const handleDropGiftAttachment = (e: any) => {
    const acceptedFiles = e.target.files; //[0];
    const extention = acceptedFiles[0].name.split(".")[1] || "";
    console.log("extention", extention);
    if (!IMAGE_TYPES.includes(extention.toLowerCase())) {
      toast.error("Gift Attachment should be image [png,jpg,jpeg] or pdf");
      e.target.value = "";
    } else {
      try {
        setFileLoading(true);
        formik.setFieldValue("giftAttachmentPath", acceptedFiles[0].name);
        setAttachFileUploadURL(acceptedFiles[0].name);
        setAttachFiles(acceptedFiles[0]);
      } catch (err: any) {
        setFileLoading(false);
      }
    }
    e.target.value = "";
  };

  const readCSVFile = (file: any) => {
    let myFile: any = [];
    const reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = (e: any) => {
      // if (ac.type == 'code') {
      const abc: any = e.target.result
        .split(/\r\n|\n/)
        .filter((d: any) => d !== undefined && d !== "");
      const newResult: any = {};
      let memberId: any = [];
      const m: any = [];
      let i;
      for (i = 0; i < abc.length; i++) {
        if (abc[i]?.charAt(0) === ",") {
          const newArray = newResult[memberId];
          newArray.push(abc[i].replace(",", ""));
          newResult[memberId] = newArray;
        } else {
          memberId = abc[i].split(",")[0];
          newResult[memberId] = [abc[i].split(",")[1]];
          m.push(memberId);
        }
      }
      setMemberCodes(newResult);
      setMembers(m);
      formik.setFieldValue("userIds", m);

      formik.setFieldValue("amount", 1);
      formik.setFieldValue("code", "1");
      myFile = newResult;
      // } else {
      //   setFile(e.target.result.split(/\r\n|\n/).filter(Boolean));
      // }
    };
    // reader.readAsBinaryString(file);
  };

  return (
    <form
      onSubmit={(e: any) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <div className="card-body">
        <Row>
          <Col md={8}>
            {formik.values.giftCategory !== 1 && (
              <>
                <Row>
                  <Col md={12}>
                    <UserSelectionByClass
                      refs={userSelectionRef}
                      setFinalUsers={(users: any) =>
                        formik.setFieldValue("userIds", users)
                      }
                      users={formik.values.userIds}
                    />
                    {errors.userIds && touched.userIds && (
                      <div className="text-error">
                        <FormattedMessage
                          id={errors.userIds.toString()}
                          defaultMessage="At least one user should be selected"
                        />
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div className="form-group">
                      <label className="control-label">
                        Additional User Ids
                      </label>
                      <Row>
                        <Col>
                          <textarea
                            className="form-control"
                            name="additionalUserIds"
                            id="additionalUserIds"
                            style={{
                              width: "100%",
                              height: "100%",
                              boxSizing: "border-box",
                            }}
                            value={formik.values.additionalUserIds}
                            onChange={(e) => {
                              formik.handleChange(e);
                              handleParsedAdditionalUserIds(e);
                            }}
                            onBlur={formik.handleBlur}
                            rows={8}
                          />
                          {touched.additionalUserIds &&
                            errors.parsedAdditionalUserIds && (
                              <div className="text-error">
                                <FormattedMessage
                                  id={errors.parsedAdditionalUserIds.toString()}
                                  defaultMessage="At least one user should be selected"
                                />
                              </div>
                            )}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <label className="control-label">
                        Result Of Parsing (
                        {`${formik.values.parsedAdditionalUserIds.length} ids are parsed`}
                        )
                      </label>
                      <textarea
                        className="form-control"
                        name="additionalUserIds"
                        id="additionalUserIds"
                        style={{
                          width: "100%",
                          height: "100%",
                          boxSizing: "border-box",
                        }}
                        value={formik.values.parsedAdditionalUserIds.join("\n")}
                        disabled={true}
                        rows={8}
                      ></textarea>
                    </div>
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col md={6}>
                <div className="form-group">
                  <label className="control-label">
                    <FormattedMessage
                      id="head.Category"
                      defaultMessage="Category"
                    />
                  </label>
                  <Select
                    name="giftCategory"
                    value={selectedGiftCat}
                    onChange={(option: any) => {
                      formik.setFieldValue("amount", "");
                      formik.setFieldValue("code", "");
                      formik.setFieldValue("giftAttachmentPath", "");

                      formik.setFieldValue("giftCategory", option.id);
                      setAttachFiles(null);
                      setAttachFileUploadURL(null);
                      setselectedGiftCat(option);
                      if ([6, 7, 8].includes(option.id)) {
                        if (option.id === 6) {
                          formik.setFieldValue("amount", 30);
                        } else if (option.id === 7) {
                          formik.setFieldValue("amount", 90);
                        } else if (option.id === 8) {
                          formik.setFieldValue("amount", 180);
                        }
                      } else {
                        formik.setFieldValue("amount", "");
                      }

                      if (option.name === "VIP Free Course Code") {
                        formik.setFieldValue(
                          "reason",
                          "Randomly generated password",
                        );
                      }
                    }}
                    onBlur={() => formik.setFieldTouched("giftCategory", true)}
                    options={giftCatList}
                    getOptionLabel={(option: any) => option.name}
                    getOptionValue={(option: any) => option.id}
                    className="custom-select-dropdown"
                    classNamePrefix="react-select-dropdown"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                  {errors.giftCategory && touched.giftCategory && (
                    <div className="text-error">
                      <FormattedMessage
                        id={errors.giftCategory.toString()}
                        defaultMessage="This field is required"
                      />
                    </div>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="control-label">
                    <FormattedMessage id="tab.Detail" defaultMessage="Detail" />
                  </label>
                  <input
                    type="text"
                    name="details"
                    value={formik.values.details}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-control form-control-md"
                    id="colFormLabelSm"
                    // placeholder={useIntl().formatMessage({
                    //   id: "Admin.GiftsList.Detail",
                    //   defaultMessage: "Detail",
                    // })}
                  />
                  {errors.details && touched.details && (
                    <div className="text-error">
                      <FormattedMessage
                        id={errors.details.toString()}
                        defaultMessage="This field is required"
                      />{" "}
                    </div>
                  )}{" "}
                </div>
              </Col>
              <Col md={6}>
                {formik.values.giftCategory === 1 && (
                  <>
                    <label className="control-label">
                      <FormattedMessage
                        id="head.Amount"
                        defaultMessage="Amount"
                      />
                    </label>
                    {/* <FileUpload /> */}
                    {files == null ? (
                      <input
                        type="file"
                        //  onClick={handleDrop}
                        onChangeCapture={handleDrop}
                      />
                    ) : (
                      <p>
                        {/* {files} */}
                        <Row className="align-items-center">
                          <Col className="col-md-9">
                            <div>{files}</div>
                          </Col>
                          <Col className="col-md-3 text-right">
                            <button
                              className="border-delete-icon delete"
                              onClick={() => {
                                setFiles(null);
                                formik.setFieldValue("amount", 0);
                                formik.setFieldValue("code", 0);
                                formik.setFieldValue("userIds", []);
                              }}
                            >
                              <BsTrash />
                            </button>
                          </Col>
                        </Row>
                      </p>
                    )}
                  </>
                )}
                <div className="form-group">
                  {(formik.values.giftCategory == 2 ||
                    formik.values.giftCategory == 6 ||
                    formik.values.giftCategory == 7 ||
                    formik.values.giftCategory == 8 ||
                    formik.values.giftCategory == 5 ||
                    formik.values.giftCategory === 16) && (
                    <>
                      <label className="control-label">
                        <FormattedMessage
                          id={
                            [1, 2].includes(formik.values.giftCategory)
                              ? "head.Amount"
                              : "days"
                          }
                          defaultMessage="Amount"
                        />
                      </label>
                      {formik.values.giftCategory !== 2 && (
                        <input
                          type="number"
                          name="amount"
                          value={formik.values.amount}
                          onChange={(e: any) => {
                            if (formik.values.giftCategory !== 2) {
                              if (!isPositiveNumbersOnly(e, e.target.value)) {
                                return false;
                              }
                            }
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          disabled={[6, 7, 8].includes(
                            formik.values.giftCategory,
                          )}
                          className="form-control form-control-md"
                          id="colFormLabelSm"
                          // placeholder={useIntl().formatMessage({
                          //   id: "head.Amount",
                          //   defaultMessage: "Amount",
                          // })}
                          min={0}
                          // onKeyPress={(e: any) => {
                          //   if (formik.values.giftCategory === 5) {
                          //     if (!isPositiveNumbersOnly(e.code)) {
                          //       e.preventDefault();
                          //     }
                          //   }
                          // }}
                        />
                      )}

                      {formik.values.giftCategory == 2 && (
                        <input
                          type="text"
                          name="code"
                          value={formik.values.code}
                          onChange={(e: any) => {
                            // if (formik.values.giftCategory !== 2) {
                            //   if (!isPositiveNumbersOnly(e, e.target.value)) {
                            //     return false;
                            //   }
                            // }
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          disabled={[6, 7, 8].includes(
                            formik.values.giftCategory,
                          )}
                          className="form-control form-control-md"
                          id="colFormLabelSm"
                          // placeholder={useIntl().formatMessage({
                          //   id: "head.Amount",
                          //   defaultMessage: "Amount",
                          // })}
                          min={0}
                          // onKeyPress={(e: any) => {
                          //   if (formik.values.giftCategory === 5) {
                          //     if (!isPositiveNumbersOnly(e.code)) {
                          //       e.preventDefault();
                          //     }
                          //   }
                          // }}
                        />
                      )}
                    </>
                  )}
                  {(formik.values.giftCategory == 12 ||
                    formik.values.giftCategory == 13 ||
                    formik.values.giftCategory == 14) && (
                    <>
                      <label className="control-label">
                        <FormattedMessage
                          id="Vps.Type"
                          defaultMessage="VPS Type"
                        />
                      </label>
                      <Select
                        name="VPSType"
                        value={VPSMachineTypeList.find(
                          (option: any) => option.id === formik.values.code,
                        )}
                        onChange={(option: any) => {
                          formik.setFieldValue("code", option.id);
                        }}
                        onBlur={() => formik.setFieldTouched("code", true)}
                        options={VPSMachineTypeList}
                        getOptionLabel={(option: any) => option.typeName}
                        getOptionValue={(option: any) => option.id}
                        className="custom-select-dropdown"
                        classNamePrefix="react-select-dropdown"
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </>
                  )}
                  {errors.amount &&
                    touched.amount &&
                    formik.values.giftCategory == 1 && (
                      <div className="text-error">
                        <FormattedMessage
                          id="vps.type.required"
                          defaultMessage="This field is required"
                        />
                      </div>
                    )}

                  {errors.amount &&
                    touched.amount &&
                    [5, 6, 7, 8].includes(formik.values.giftCategory) && (
                      <div className="text-error">
                        <FormattedMessage
                          id={errors.amount} //"vps.type.membership"
                          defaultMessage="This field is required"
                        />
                      </div>
                    )}

                  {errors.code &&
                    touched.code &&
                    formik.values.giftCategory == 2 && (
                      <div className="text-error">
                        <FormattedMessage
                          id="tickdata.type.required"
                          defaultMessage="This field is required"
                        />
                      </div>
                    )}

                  {errors.code &&
                    touched.code &&
                    [12, 13, 14].includes(formik.values.giftCategory) && (
                      <div className="text-error">
                        <FormattedMessage
                          id="VPSType.required"
                          defaultMessage="This field is required"
                        />
                      </div>
                    )}
                </div>

                {formik.values.giftCategory === 11 && (
                  <>
                    <label className="control-label">
                      <FormattedMessage
                        id="classmodal.SelectFile"
                        defaultMessage="Amount"
                      />
                    </label>
                    {/* <FileUpload /> */}
                    {attachFileUploadURL == null ? (
                      <input
                        type="file"
                        //  onClick={handleDrop}
                        onChangeCapture={handleDropGiftAttachment}
                      />
                    ) : (
                      <p>
                        {/* {files} */}
                        <Row className="align-items-center">
                          <Col className="col-md-9">
                            <div>{attachFileUploadURL}</div>
                          </Col>
                          <Col className="col-md-3 text-right">
                            <button
                              className="border-delete-icon delete"
                              onClick={() => {
                                setAttachFileUploadURL(null);
                                setAttachFiles(null);

                                formik.setFieldValue("giftAttachmentPath", "");
                                formik.setFieldError(
                                  "giftAttachmentPath",
                                  "vps.type.required",
                                );
                                // formik.setFieldValue("amount", 0);
                                // formik.setFieldValue("code", 0);
                                // formik.setFieldValue("userIds", []);
                              }}
                            >
                              <BsTrash />
                            </button>
                          </Col>
                        </Row>
                      </p>
                    )}

                    {errors.giftAttachmentPath &&
                      touched.giftAttachmentPath &&
                      formik.values.giftCategory == 11 && (
                        <div className="text-error">
                          <FormattedMessage
                            id="vps.type.required"
                            defaultMessage="This field is required"
                          />
                        </div>
                      )}
                  </>
                )}
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="control-label">
                    <FormattedMessage
                      id="Admin.Grant.Reason"
                      defaultMessage="Reason"
                    />
                  </label>
                  <input
                    type="Status"
                    name="reason"
                    value={formik.values.reason}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-control form-control-md"
                    id="colFormLabelSm"
                    disabled={formik.values.giftCategory === 18}
                    // placeholder={useIntl().formatMessage({
                    //   id: "select.Reason",
                    //   defaultMessage: "Reason",
                    // })}
                  />
                  {errors.reason && touched.reason && (
                    <div className="text-error">
                      <FormattedMessage
                        id={errors.reason.toString()}
                        defaultMessage="This field is required"
                      />
                    </div>
                  )}{" "}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="control-label">
                    <FormattedMessage
                      id="Admin.Grant.Ponits"
                      defaultMessage="Points Required"
                    />
                  </label>
                  <input
                    type="number"
                    value={formik.values.pointsRequired}
                    name="pointsRequired"
                    onChange={(e: any) => {
                      if (!isPositiveNumbersOnly(e, e.target.value)) {
                        return false;
                      }
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    className="form-control form-control-md"
                    id="colFormLabelSm"
                    // placeholder={useIntl().formatMessage({
                    //   id: "Admin.GrantPoint.Points",
                    //   defaultMessage: "Points",
                    // })}
                    min={0}
                    max={100000}
                    // onKeyPress={(e: any) => {
                    //   if (!isPositiveNumbersOnly(e.code)) {
                    //     e.preventDefault();
                    //   }
                    // }}
                  />
                  {errors.pointsRequired && touched.pointsRequired && (
                    <div className="text-error">
                      <FormattedMessage
                        id={errors.pointsRequired.toString()}
                        defaultMessage="This field is required"
                      />
                    </div>
                  )}{" "}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="control-label">
                    <FormattedMessage
                      id="Admin.Grant.Expiry"
                      defaultMessage="Expiry Date"
                    />
                  </label>
                  <input
                    type="date"
                    name="exipryDate"
                    min={disablePastDate()}
                    value={formik.values.exipryDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-control form-control-md"
                    id="colFormLabelSm"
                  />
                  {errors.exipryDate && touched.exipryDate && (
                    <div className="text-error">
                      <FormattedMessage
                        id={errors.exipryDate.toString()}
                        defaultMessage="This field is required"
                      />
                    </div>
                  )}{" "}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="card-footer bg-light text-right">
        <button
          className="btn btn-secondary mr-3"
          onClick={(e: any) => onFormReset(e)}
        >
          <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
        </button>

        <Button
          className="ml-3"
          disabled={isLoading}
          onClick={() => formik.handleSubmit()}
        >
          <FormattedMessage id="Admin.Grant.Submit" defaultMessage="Submit" />
          {isLoading && <Loader />}
        </Button>
      </div>
    </form>
  );
};
export default GrantsList;
