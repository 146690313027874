/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/APIServices/baseApiCall";
import {
  GET_LIBRARY_LIST,
  GET_LIBRARY_VERSION_LIST,
  GET_LIBRARY_DETAIL,
  ADD_LIBRARY_LIST,
  EDIT_LIBRARY_LIST,
  ADD_LIBRARY_VERSION_LIST,
  EDIT_LIBRARY_VERSION_LIST,
  DELETE_LIBRARY_VERSION_LIST,
  DELETE_LIBRARY_LIST,
  SET_PAGE,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { getAPIEndPoint, getPage, setToken } from "../../util/utils";
import {
  GET_LIBRARY_LIST_URL,
  ADD_LIBRARY_LIST_URL,
  EDIT_LIBRARY_LIST_URL,
  DELETE_LIBRARY_LIST_URL,
  GET_LIBRARY_VERSION_URL,
  ADD_LIBRARY_VERSION_URL,
  EDIT_LIBRARY_VERSION_URL,
  DELETE_LIBRARY_VERSION_URL,
} from "../../service/APIServices/apiEndPoints";
import { toast } from "react-toastify";
import {
  _UploadMedia,
  _UploadTool,
} from "../../service/MediaUpload/classes.service";
// import { _getExpressWayPendingList } from "../../service";
// import { AxiosResponse } from "axios";

export const setCurrentLibPage =
  (pageNumber: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: SET_PAGE,
      payload: pageNumber,
    });
  };

//Get Admin Roles List
export const getlibraryList =
  (
    showToast = false,
    pagenumber = 0,
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getlibraryListInit(showToast, pagenumber, pageLimit));
  };

export const getlibraryListInit =
  (
    showToast: boolean,
    pageNumber: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_LIBRARY_LIST.GET_LIBRARY_LIST_INITIALIZATION,
    });
    apiCall(
      getAPIEndPoint(
        GET_LIBRARY_LIST_URL + "?sort=DESC&sortBy=updatedAt",
        pageNumber,
        true,
        pageLimit,
      ),
      {},
      (res: Object) => dispatch(getlibraryListSuccess(res)),
      (err: any) => dispatch(getlibraryListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getlibraryListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_LIBRARY_LIST.GET_LIBRARY_LIST_SUCCESS,
      payload: res,
    });
  };

export const getlibraryListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_LIBRARY_LIST.GET_LIBRARY_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const getlibraryVersionList =
  (
    showToast = true,
    libId: any,
    pageNumber = 0,
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      getlibraryVersionListInit(showToast, libId, pageNumber, pageLimit),
    );
  };

export const getlibraryVersionListInit =
  (
    showToast = true,
    libId: any,
    pageNumber: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_LIBRARY_VERSION_LIST.GET_LIBRARY_VERSION_LIST_INITIALIZATION,
    });

    apiCall(
      getAPIEndPoint(
        `${GET_LIBRARY_VERSION_URL}${libId}`,
        pageNumber,
        false,
        pageLimit,
      ),
      {},
      (res: Object) => dispatch(getlibraryVersionListSuccess(res)),
      (err: any) => dispatch(getlibraryVersionListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getlibraryVersionListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_LIBRARY_VERSION_LIST.GET_LIBRARY_VERSION_LIST_SUCCESS,
      payload: res,
    });
    dispatch(getlibraryList(false));
  };

export const getlibraryVersionListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_LIBRARY_VERSION_LIST.GET_LIBRARY_VERSION_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const addLibraryList =
  (
    showToast = true,
    indicatorData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(addLibraryListInit(showToast, indicatorData));
  };

export const addLibraryListInit =
  (
    showToast = true,
    indicatorData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_LIBRARY_LIST.ADD_LIBRARY_LIST_INITIALIZATION,
    });
    apiCall(
      ADD_LIBRARY_LIST_URL,
      indicatorData,
      (res: Object) => dispatch(addLibraryListSuccess(res)),
      (err: any) => dispatch(addLibraryListError()),
      METHOD.POST,
      { showToast },
    );
  };

export const addLibraryListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_LIBRARY_LIST.ADD_LIBRARY_LIST_SUCCESS,
    });
    dispatch(setCurrentLibPage(1));
    dispatch(getlibraryList(false, 1));
  };

export const addLibraryListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_LIBRARY_LIST.ADD_LIBRARY_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const editLibraryList =
  (
    showToast = true,
    id: any,
    indicatorData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(editLibraryListInit(showToast, id, indicatorData));
  };

export const editLibraryListInit =
  (
    showToast = true,
    id: any,
    indicatorData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_LIBRARY_LIST.EDIT_LIBRARY_LIST_INITIALIZATION,
    });
    apiCall(
      EDIT_LIBRARY_LIST_URL + id,
      indicatorData,
      (res: Object) => dispatch(editLibraryListSuccess(res)),
      (err: any) => dispatch(editLibraryListError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const editLibraryListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_LIBRARY_LIST.EDIT_LIBRARY_LIST_SUCCESS,
      payload: res.data,
    });
    dispatch(setCurrentLibPage(1));
    dispatch(getlibraryList(false, 1));
  };

//Get Admin Roles
export const deleteLibrary =
  (
    showToast = true,
    indicator: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(deleteLibraryInit(showToast, indicator));
  };

export const deleteLibraryInit =
  (
    showToast = true,
    indicator: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_LIBRARY_LIST.DELETE_LIBRARY_LIST_INITIALIZATION,
    });
    apiCall(
      `${DELETE_LIBRARY_LIST_URL}${indicator}`,
      {},
      (res: Object) => dispatch(deleteLibrarySuccess(res)),
      (err: any) => dispatch(deleteLibraryError()),
      METHOD.DELETE,
      { showToast },
    );
  };

export const deleteLibrarySuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_LIBRARY_LIST.DELETE_LIBRARY_LIST_SUCCESS,
      payload: res.data,
    });
    dispatch(getlibraryList(false));
  };

export const deleteLibraryError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: DELETE_LIBRARY_LIST.DELETE_LIBRARY_LIST_ERROR,
    });
  };

export const editLibraryListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: EDIT_LIBRARY_LIST.EDIT_LIBRARY_LIST_ERROR,
    });
  };

export const getLibraryDetails =
  (
    showToast = false,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getLibraryDetailsInit(showToast, id));
  };

export const getLibraryDetailsInit =
  (
    showToast: boolean,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_LIBRARY_DETAIL.GET_LIBRARY_DETAIL_INITIALIZATION,
    });
    apiCall(
      GET_LIBRARY_LIST_URL + "/" + id,
      {},
      (res: Object) => dispatch(getLibraryDetailsSuccess(res)),
      (err: any) => dispatch(getLibraryDetailsError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getLibraryDetailsSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_LIBRARY_DETAIL.GET_LIBRARY_DETAIL_SUCCESS,
      payload: res.data,
    });
  };

export const getLibraryDetailsError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_LIBRARY_DETAIL.GET_LIBRARY_DETAIL_ERROR,
    });
  };

//Get Admin Roles List
export const addLibraryVersion =
  (
    showToast = true,
    indicator: any,
    classId: any,
    isFile: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch({
      type: ADD_LIBRARY_VERSION_LIST.ADD_LIBRARY_VERSION_LIST_INITIALIZATION,
    });
    if (isFile.toolUpload) {
      const toolFile = indicator.download_path;
      const { fileUploadResponse, error }: any = await _UploadTool(
        toolFile as File,
      );
      if (!error) {
        indicator.download_path = fileUploadResponse;
      } else {
        dispatch(addLibraryVersionError());
        return;
      }
    }

    if (isFile.manualUpload) {
      const toolFile = indicator.manual_path;
      const { fileUploadResponse, error }: any = await _UploadMedia(
        toolFile as File,
      );
      if (!error) {
        indicator.manual_path = fileUploadResponse;
      } else {
        dispatch(addLibraryVersionError());
        return;
      }
    }
    dispatch(addLibraryVersionInit(showToast, indicator, classId));
  };

export const addLibraryVersionInit =
  (
    showToast = true,
    indicator: any,
    classId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    apiCall(
      `${ADD_LIBRARY_VERSION_URL}`,
      indicator,
      (res: Object) => dispatch(addLibraryVersionSuccess(res, classId)),
      (err: any) => dispatch(addLibraryVersionError()),
      METHOD.POST,
      { showToast },
    );
  };

export const addLibraryVersionSuccess =
  (res: any, classId: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_LIBRARY_VERSION_LIST.ADD_LIBRARY_VERSION_LIST_SUCCESS,
      payload: res.data,
    });
    dispatch(setCurrentLibPage(1));
    dispatch(getlibraryVersionList(false, classId, 1));
  };

export const addLibraryVersionError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_LIBRARY_VERSION_LIST.ADD_LIBRARY_VERSION_LIST_ERROR,
    });
  };

//Get Admin Roles
export const editLibraryVersion =
  (
    showToast = true,
    id: any,
    indicatorVersion: any,
    classId: any,
    isFile: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch({
      type: EDIT_LIBRARY_VERSION_LIST.EDIT_LIBRARY_VERSION_LIST_INITIALIZATION,
    });
    if (isFile.toolUpload) {
      const toolFile = indicatorVersion.download_path;
      const { fileUploadResponse, error }: any = await _UploadTool(
        toolFile as File,
      );
      if (!error) {
        indicatorVersion.download_path = fileUploadResponse;
      } else {
        dispatch(editLibraryVersionError());
        return;
      }
    }

    if (isFile.manualUpload) {
      const toolFile = indicatorVersion.manual_path;
      const { fileUploadResponse, error }: any = await _UploadMedia(
        toolFile as File,
      );
      if (!error) {
        indicatorVersion.manual_path = fileUploadResponse;
      } else {
        dispatch(editLibraryVersionError());
        return;
      }
    }
    dispatch(editLibraryVersionInit(showToast, id, indicatorVersion, classId));
  };

export const editLibraryVersionInit =
  (
    showToast = true,
    id: any,
    indicatorVersion: any,
    classId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    apiCall(
      `${EDIT_LIBRARY_VERSION_URL}${id}`,
      indicatorVersion,
      (res: Object) => dispatch(editLibraryVersionSuccess(res, classId)),
      (err: any) => dispatch(editLibraryVersionError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const editLibraryVersionSuccess =
  (res: any, classId: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_LIBRARY_VERSION_LIST.EDIT_LIBRARY_VERSION_LIST_SUCCESS,
      payload: res.data,
    });

    dispatch(setCurrentLibPage(1));
    dispatch(getlibraryVersionList(false, classId, 1));
  };

export const editLibraryVersionError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: EDIT_LIBRARY_VERSION_LIST.EDIT_LIBRARY_VERSION_LIST_ERROR,
    });
  };

//Get Admin Roles
export const deleteLibraryVersion =
  (
    showToast = true,
    indicator: any,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(deleteLibraryVersionInit(showToast, indicator, id));
  };

export const deleteLibraryVersionInit =
  (
    showToast = true,
    indicator: any,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_LIBRARY_VERSION_LIST.DELETE_LIBRARY_VERSION_LIST_INITIALIZATION,
    });
    apiCall(
      `${DELETE_LIBRARY_VERSION_URL}${indicator}`,
      {},
      (res: Object) => dispatch(deleteLibraryVersionSuccess(res, id)),
      (err: any) => dispatch(deleteLibraryVersionError()),
      METHOD.DELETE,
      { showToast },
    );
  };

export const deleteLibraryVersionSuccess =
  (res: any, id: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_LIBRARY_VERSION_LIST.DELETE_LIBRARY_VERSION_LIST_SUCCESS,
      payload: res.data,
    });
    dispatch(getlibraryVersionList(false, id));
  };

export const deleteLibraryVersionError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: DELETE_LIBRARY_VERSION_LIST.DELETE_LIBRARY_VERSION_LIST_ERROR,
    });
  };
