export const CLASS_DETAILS_TAB = {
  CLASS_DETAILS: 0,
  PRODUCTS: 1,
};

export const EXPRESSWAY_LIST_TABS = {
  PENDING_LIST: 0,
  RECORDS_LIST: 1,
};

export const ADMINROLES_LIST_TABS = {
  ROLE_LIST: 0,
  TOOL_LIST: 1,
  USER_PERMISSIONS_LIST: 2,
  USER_LOGGING_LIST: 3,
  USER_EA_ACCOUNT_LIST: 4,
};

export const ADMINUSERS_LIST_TABS = {
  ACTIVE_LIST: 0,
  DENIED_LIST: 1,
  PENDING_LIST: 3,
  EMAIL_VERFICATION_LIST: 2,
  RECORD_LIST: 4,
  ADMIN_LIST: 5,
  CONSUMER_LIST: 6,
};

export const STUDENT_LIST_TABS = {
  APPROVE_LIST: 1,
  REJECTED_LIST: 2,
  PENDING_LIST: 0,
  MANUAL_LIST: 3,
};

export const ADVERTISMENT_LIST_TABS = {
  CLASS_LIST: 0,
  SCRIPT_LIST: 1,
  EXPERT_ADVISOR_LIST: 4,
  UTILITY_LIST: 3,
  INDICATOR_LIST: 2,
};

export const ACTIVITIES_DETAIL_TABS = {
  ACTIVITY_DETAIL: 0,
  POINT_LIST: 1,
  ACTIVITES_ATTENDENCE_LOG_LIST: 2,
};

export const ACTIVITIES_LIST_TABS = {
  ACTIVITY_LIST: 0,
  ATTENDENCE_LOG_LIST: 1,
};

export const RENTS_LIST_TABS = {
  RENT_LIST: 0,
  ADD_RENT: 1,
};

export const GRANT_LIST_TABS = {
  GRANT_LIST: 0,
  TOOL_RENT: 1,
  POINT_RENT: 2,
  ACCOUNT: 3,
};

export const MEMBERSHIP_LIST_TABS = {
  MEMBERSHIP_EXPIRED: 0,
  USER_LIST: 1,
  CLASS_LIST: 2,
  REFERAL_LIST: 0,
  REFERAL_TREE: 1,
};

export const GIFT_LIST_TABS = {
  GIFT_LIST: 0,
  GRANT_LIST: 1,
  CATEGORY_LIST: 2,
};

export const STATISTICS_LIST_TABS = {
  TOOL_LIST: 0,
  CLASS_LIST: 1,
};

export const MEMBERDETAIL_LIST_TABS = {
  MEMBER_DETAIL: 0,
  ATTENDENCE_LIST: 1,
};

export const CONSUMER_DETAIL_LIST_TABS = {
  DETAIL: 0,
  ACCOUNT: 1,
  PRODUCT_PURCHASE: 2,
};

export const GIFT_LIST_TAB = {
  GIFT_LISTS: 0,
  GRANT_LISTS: 1,
  CATEGORY_LISTS: 2,
};

export const TOOLS = {
  EXPERT_ADVISOR: 4,
  UTILITIES: 1,
  SCRIPTS: 2,
  INDICATOR: 3,
  CLASS: 5,
};

export const TICKET_STATUS = {
  OPEN: "open",
  CLOSE: "closed",
  INPROGRESS: "inprogress",
  REOPEN: "reopen",
  RESOLVED: "resolved",
};

export const TOOLS_TICKET = {
  EXPERT_ADVISOR: 0,
  UTILITIES: 1,
  SCRIPTS: 2,
  INDICATOR: 3,
  LIBRARIES: 4,
  CLASS: 5,
};

export const TOOLS_AD = {
  EXPERT_ADVISOR: 2,
  UTILITIES: 3,
  SCRIPTS: 1,
  INDICATOR: 4,
  CLASS: 5,
};

export const ToolTypeOptionAd: any = [
  {
    id: TOOLS_TICKET.EXPERT_ADVISOR,
    group: "Expert Advisor",
  },
  {
    id: TOOLS_TICKET.UTILITIES,
    group: "Utilities",
  },
  {
    id: TOOLS_TICKET.SCRIPTS,
    group: "Script",
  },
  {
    id: TOOLS_TICKET.INDICATOR,
    group: "Indicator",
  },
  {
    id: TOOLS_TICKET.CLASS,
    group: "Class",
  },
];

export const ToolTypeOption: any = [
  {
    id: TOOLS_TICKET.EXPERT_ADVISOR,
    group: "Expert Advisor",
  },
  {
    id: TOOLS_TICKET.UTILITIES,
    group: "Utilities",
  },
  {
    id: TOOLS_TICKET.SCRIPTS,
    group: "Script",
  },
  {
    id: TOOLS_TICKET.INDICATOR,
    group: "Indicator",
  },
  {
    id: TOOLS_TICKET.LIBRARIES,
    group: "Library",
  },
];

export const ToolTypeOptions: any = [
  {
    id: TOOLS_TICKET.EXPERT_ADVISOR,
    group_en: "Expert Advisor",
    group_zh: "專家顧問",
  },
  {
    id: TOOLS_TICKET.UTILITIES,
    group_en: "Utilities",
    group_zh: "公用事業",
  },
  {
    id: TOOLS_TICKET.SCRIPTS,
    group_en: "Script",
    group_zh: "腳本",
  },
  {
    id: TOOLS_TICKET.INDICATOR,
    group_en: "Indicator",
    group_zh: "指標",
  },
];

export const GROUP_TYPE = {
  TICKET: "Ticket",
  CLASS: "Class",
};

export enum VPSStatus {
  RUNNING = "running",
  TERMINATED = "terminated",
  STOPPED = "stopped",
  INITIALIZED = "initialized",
  REBOOT = "reboot",
}

export enum AccountInfoType {
  EA_ACCOUNT = "eaId",
  INDICATOR_ACCOUNT = "adminIndicators",
  UTILITIES_ACCOUNT = "adminUtilities",
}
