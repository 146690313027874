import { AxiosError } from "axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import {
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from "material-react-table";
import { PlanCategory } from "../interfaces";
import { customFetch } from "../../../util/axios";

interface GetAllPlanCategoriesParams {
  globalFilter: string;
  columnFilters: MRT_ColumnFiltersState;
  sorting: MRT_SortingState;
  pageIndex: MRT_PaginationState["pageIndex"];
  pageSize: MRT_PaginationState["pageSize"];
}

export interface GetAllPlanCategoriesReturn {
  planCategoriesCount: number;
  totalPages: number;
  hasNextPage: boolean;
  planCategories: PlanCategory[];
}

export const getAllPlanCategories = async (
  params: GetAllPlanCategoriesParams,
) => {
  const response = await customFetch.get("/plan-categories", { params });
  return response.data;
};

export const useGetAllPlanCategories = (params: GetAllPlanCategoriesParams) => {
  const queryClient = useQueryClient();

  const query = useQuery<GetAllPlanCategoriesReturn, AxiosError | Error>({
    queryKey: ["plan-categories", params],
    queryFn: () => getAllPlanCategories(params),
    keepPreviousData: true,
  });

  const pageIndex = params.pageIndex || 0;
  const { data, isPreviousData } = query;

  useEffect(() => {
    if (!isPreviousData && data?.hasNextPage) {
      const nextParams = { ...params, pageIndex: pageIndex + 1 };

      queryClient.prefetchQuery({
        queryKey: ["plan-categories", nextParams],
        queryFn: () => getAllPlanCategories(nextParams),
      });
    }
  }, [params, data, isPreviousData, pageIndex, queryClient]);

  return query;
};

export default useGetAllPlanCategories;
