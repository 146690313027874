import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { addAnnouncement, getTicketGroupList } from "../../redux/action";
import { Store } from "../../redux/Actions";
import { convertToRaw, EditorState } from "draft-js";
import Loader from "../common/Loader";
import EditorContainer from "../RichTextEditor/Editor";
import { _UploadMediaMutiple } from "../../service/MediaUpload/classes.service";
import FileUpload from "../common/MediaUpload/FileUpload";
import draftToHtml from "draftjs-to-html";
import Select from "react-select";
import { AddAnnouncementSchema } from "../../util/validationSchema";
import { toast } from "react-toastify";
import { Col, Row, Modal } from "react-bootstrap";
import { BsTrash } from "react-icons/bs";
import { FormattedMessage, useIntl } from "react-intl";
import {
  deleteMediaLocalInit,
  setMediaUpload,
} from "../../redux/action/mediaUploadAction";
import { getFileName, getTextFromHtmlRowText } from "../../util/utils";
import { GROUP_TYPE } from "../../Constants";
import { group } from "console";
import { Switch } from "@mui/material";
import { setgroups } from "process";
import { FaTimesCircle } from "react-icons/fa";

export const ANNOUNCEMENT_ATTACHMENT = "announcementAttachment";

export const FILE_UPLOAD_KEYS = {
  ANNOUNCEMENT_IMGS: "announcementImg",
};

const AddAnnouncementModal = ({ onModalClose, classList }: any) => {
  const dispatch = useDispatch();

  const isAPILoading = useSelector(
    (state: Store) => state.announcementsReducer.onSaveLoading,
  );
  const announcementAttachment = useSelector(
    (state: Store) => state.mediaUploadReducer.announcementAttachment,
  );

  const groupList = useSelector(
    (state: Store) => state.ticketReducer.groupList,
  );

  // const classList = useSelector(
  //   (state: Store) => state.classReducer.classes
  // );

  const local = useSelector((state: Store) => state.userDataReducer.local);

  const [SelectedClasses, setSelectedClasses] = useState<any>([]);
  const [SelectedGroup, setSelectedGroup] = useState<any>([]);
  const [classes, setClasses] = useState<any>();
  const [group, setGroup] = useState<any>();
  const [ButtonClick, setButtonClick] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      content: "",
      groupIds: [],
      isCritical: false,
      class_group: [],
      attachmentPath: [],
      sendEmail: false,
      chinese: false,
      toAll: false,
      group: false,
    },
    validationSchema: AddAnnouncementSchema,
    onSubmit: async (values) => {
      // if (values.groupIds.length <= 0 && values.toAll === false) {
      //   toast.error("class group is required");
      // } else {
      if (!ButtonClick) {
        const imgs: any = values.attachmentPath.map((i: any) => i.location);
        const all: boolean = classes.length === values.groupIds.length;
        let ids: any = []; //SelectedClasses;
        let data: any = [];
        if (values.group) {
          SelectedClasses.map((i: any) => {
            const id = i.class_group.map((ids: any) => ids.id);
            ids = [...ids, ...id];
          });
          data = {
            ...values,
            attachmentPath: imgs,
            // class_group: ids,
            groupIds: values.groupIds,
            toAll: all,
          };
          delete data.group;
        } else {
          data = {
            ...values,
            attachmentPath: imgs,
            // class_group: value,
            // groupIds: values.groupIds,
            toAll: all,
          };
          delete data.group;
          delete data.groupIds;
        }
        dispatch(addAnnouncement(true, data));
        setButtonClick(true);
        // }
      }
    },
  });

  useEffect(() => {
    dispatch(getTicketGroupList(false, GROUP_TYPE.CLASS, true));
  }, [isAPILoading]);

  useEffect(() => {
    if (!isAPILoading && ButtonClick) {
      onModalClose();
      dispatch(setMediaUpload("announcementAttachment"));
    }
  }, [isAPILoading]);

  useEffect(() => {
    const cl: any = [{ id: 0, name: "All" }, ...classList];
    setClasses(cl);
  }, [classList, formik.values.group]);

  useEffect(() => {
    const cl: any = [{ id: 0, group_name: "All" }, ...groupList];
    setGroup(cl);
  }, [groupList, formik.values.group]);

  useEffect(() => {
    formik.setFieldValue("attachmentPath", announcementAttachment);
  }, [announcementAttachment]);

  const onEditorStateChange = (editorState: EditorState) => {
    const eState = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    const text = getTextFromHtmlRowText(eState); //  eState.toString().replaceAll("&nbsp;", "");

    if (text.length > 0) {
      setEditorState(editorState);
      formik.setFieldValue(
        "content",
        draftToHtml(convertToRaw(editorState.getCurrentContent())),
      );
    } else {
      setEditorState(editorState);
      formik.setFieldValue("content", "");
    }
  };

  const onClassChange = (option: any) => {
    if (option.length === 0 || option.length !== classes.length) {
      const cl: any = [{ id: 0, name: "All" }, ...classList];
      setClasses(cl);
    }
    if (option.length > 0 && option[option.length - 1].id == 0) {
      const cl = Object.assign([], classes);
      cl.shift();
      setSelectedClasses(cl);
      setClasses(classList);

      const ids: number[] = classes.map((i: any) => i.id);
      // formik.setFieldValue("groupIds", ids);
      formik.setFieldValue("class_group", ids);
    } else {
      setSelectedClasses(option);
      const ids = option.map((i: any) => i.id);
      formik.setFieldValue("class_group", ids);
    }
  };

  const onGroupChange = (option: any) => {
    if (option.length === 0 || option.length !== groupList.length) {
      const cl: any = [{ id: 0, group_name: "All" }, ...groupList];
      setGroup(cl);
      formik.setFieldValue("class_group", []);
      // formik.setFieldTouched("class_group", true);
      setSelectedGroup(null);
    }
    if (option.length > 0 && option[option.length - 1].id == 0) {
      const cl = Object.assign([], groupList);
      // cl.shift();
      setSelectedGroup(cl);
      setGroup(groupList);
      // let cid: any = [];
      // formik.setFieldValue("class_group", cid);
      let cid: any = [];
      groupList.map((g: any) => {
        const id = g.class_group.map((a: any) => a.id);
        cid = [...cid, ...id];
        // });
      });
      formik.setFieldValue("class_group", cid);

      const ids: number[] = groupList.map((i: any) => i.id);
      // formik.setFieldValue("groupIds", ids);
      formik.setFieldValue("groupIds", ids);
    } else {
      setSelectedGroup(option);
      const ids = option.map((i: any) => i.id);
      formik.setFieldValue("groupIds", ids);
    }
    let cid: any = [];
    option.map((i: any) => {
      const id = i.class_group.map((a: any) => a.id);
      cid = [...cid, ...id];
    });
    formik.setFieldValue("class_group", cid);
  };

  const handleDrop = async (acceptedFiles: any) => {
    console.log("acceptedFiles", acceptedFiles);
    await _UploadMediaMutiple(
      acceptedFiles as File[],
      ANNOUNCEMENT_ATTACHMENT,
      // FILE_UPLOAD_KEYS.ANNOUNCEMENT_IMGS
    );
  };

  const onDeleteImage = (key: any) => {
    dispatch(deleteMediaLocalInit(ANNOUNCEMENT_ATTACHMENT, key)); //key
  };

  const { touched, errors } = formik;
  return (
    <Modal
      centered
      scrollable
      show={true}
      onHide={() => {
        onModalClose(false);
      }}
      data-modal="scroll"
    >
      <Modal.Header>
        <h4 className="modal-title">
          <FormattedMessage
            id="annousmentmodal.make"
            defaultMessage="Make an Annoucement"
          />{" "}
        </h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onModalClose}
        >
          <FaTimesCircle />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label className="control-label">
            {" "}
            <FormattedMessage
              id="annousmentmodal.Summary"
              defaultMessage="Summary"
            />
          </label>
          <input
            type="text"
            name="title"
            maxLength={15}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="form-control"
          />
          {errors.title && touched.title && (
            <div className="text-error">
              <FormattedMessage id={errors.title} />
            </div>
          )}
        </div>

        <div className="form-group">
          <label className="control-label">
            {" "}
            <FormattedMessage
              id="annousmentmodal.Description"
              defaultMessage="Description"
            />
          </label>
          <EditorContainer
            onEditorStateChange={onEditorStateChange}
            editorState={editorState}
            toolbar={true}
            editable={true}
            height={"200px"}
            overflowY={"scroll"}
            border={"1px solid #dfe7f3"}
          />
          {errors.content && touched.content && (
            <div className="text-error">
              <FormattedMessage id={errors.content} />
            </div>
          )}
        </div>

        <div className="form-group">
          <FormattedMessage id="annousmentmodal.Group" defaultMessage="Group" />
          <Switch
            checked={formik.values.group}
            onChange={() => {
              formik.setFieldValue("group", !formik.values.group);
              formik.setFieldValue("class_group", []);
              formik.setFieldValue("groupIds", []);
              formik.setFieldTouched("class_group", false);
              formik.setFieldTouched("groupIds", false);
              setSelectedGroup([]);
              setSelectedClasses([]);
            }}
          />
          {/* Class */}
        </div>

        <div className="form-group">
          {formik.values.group ? (
            <>
              <label className="control-label">
                {" "}
                <FormattedMessage
                  id="annousmentmodal.label"
                  defaultMessage="Only open to the following groups"
                />
              </label>
              <Select
                name="class_group"
                value={SelectedGroup}
                onChange={(option: any) => onGroupChange(option)}
                onBlur={() => formik.setFieldTouched("class_group", true)}
                options={group}
                menuPlacement="top"
                getOptionLabel={(option: any) => option.group_name}
                getOptionValue={(option: any) => option.id}
                isMulti
                className="custom-select-dropdown multi w-100p"
                classNamePrefix="react-select-dropdown"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />

              {/* {errors.class_group && touched.class_group && (
              <div className="text-error">
                {" "}
                <FormattedMessage
                  id={errors.class_group.toString()}
                  defaultMessage="Class is required"
                />
              </div>
            )} */}
              {/* </>} */}
            </>
          ) : (
            <>
              <label className="control-label">
                {" "}
                <FormattedMessage
                  id="annousmentmodal.labelClass"
                  defaultMessage="Only open to the following class"
                />
              </label>
              <Select
                name="class_group"
                value={SelectedClasses}
                onChange={(option: any) => onClassChange(option)}
                onBlur={() => formik.setFieldTouched("class_group", true)}
                options={classes}
                menuPlacement="top"
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.id}
                isMulti
                className="custom-select-dropdown multi w-100p"
                classNamePrefix="react-select-dropdown"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
            </>
          )}
          {errors.class_group && touched.class_group && (
            <div className="text-error">
              {" "}
              <FormattedMessage
                id={
                  formik.values.group
                    ? "activitymodal.Group.required"
                    : "activitymodal.Class.required"
                }
                defaultMessage="Class is required"
              />
            </div>
          )}

          {errors.groupIds && touched.groupIds && (
            <div className="text-error">
              {" "}
              <FormattedMessage
                id={
                  formik.values.group
                    ? "activitymodal.Group.required"
                    : "activitymodal.Class.required"
                }
                defaultMessage="Class is required"
              />
            </div>
          )}
        </div>

        <div className="form-group mt-3">
          <FileUpload optional="true" handleDrop={handleDrop} />
          <>
            {formik.values.attachmentPath.length > 0 &&
              announcementAttachment.map((i: any) => (
                <Row className="align-items-center mt-1">
                  <Col className="col-md-9">
                    {/* <img
                      style={{ width: "50%", height: "30%" }}
                      src={i.location}
                    /> */}
                    <p>{getFileName(i.location)} </p>
                  </Col>
                  <Col className="col-md-3 text-right">
                    <button
                      className="border-delete-icon delete"
                      onClick={() => {
                        onDeleteImage(i.key);
                      }}
                    >
                      <BsTrash />
                    </button>
                  </Col>
                </Row>
              ))}
          </>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <div className="mr-auto">
          <label className="control control-outline control-primary control--checkbox d-inline-block mb-0 mr-3 pointer">
            <FormattedMessage
              id="annousmentmodal.Email"
              defaultMessage="Email to groups"
            />
            <input
              type="checkbox"
              onChange={() =>
                formik.setFieldValue("sendEmail", !formik.values.sendEmail)
              }
              checked={formik.values.sendEmail}
            />
            <div className="control__indicator announc-checkbox"></div>
          </label>
          {/* <label className="control control-outline control-primary control--checkbox d-inline-block mb-0 pointer">
            <FormattedMessage
              id="announcement.Chinese"
              defaultMessage="Chinese announcement"
            />
            <input
              type="checkbox"
              onChange={() =>
                formik.setFieldValue("chinese", !formik.values.chinese)
              }
              checked={formik.values.chinese}
            />
            <div className="control__indicator"></div>
          </label> */}
          <label className="control control-outline control-primary control--checkbox d-inline-block mb-0 pointer">
            <FormattedMessage
              id="announcement.Chineses"
              defaultMessage="Critical"
            />
            <input
              type="checkbox"
              onChange={() =>
                formik.setFieldValue("isCritical", !formik.values.isCritical)
              }
              checked={formik.values.isCritical}
            />
            <div className="control__indicator announc-checkbox"></div>
          </label>
        </div>
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          onClick={onModalClose}
        >
          {" "}
          <FormattedMessage
            id="annousmentmodal.Cancel"
            defaultMessage="Cancel"
          />
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => formik.handleSubmit()}
          data-dismiss="modal"
        >
          <FormattedMessage
            id="annousmentmodal.Submit"
            defaultMessage="Submit"
          />
          {isAPILoading && <Loader />}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddAnnouncementModal;
