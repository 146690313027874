import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  addActivity,
  editActivityDetails,
  getAdminRolesList,
  getClasses,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import Loader from "../common/Loader";
import Select from "react-select";
import { Row, Col, Table, Modal } from "react-bootstrap";
import { ROLES_POINT } from "../../util/constants/UserGroup";
import {
  disablePastDate,
  formatDate,
  isPositiveNumbersOnly,
} from "../../util/utils";
import { AddActivitySchema } from "../../util/validationSchema";
import { FormattedMessage } from "react-intl";
import { FaTimesCircle } from "react-icons/fa";

const AddActivityModal = ({ onModalClose, activity }: any) => {
  const dispatch = useDispatch();
  const isAPILoading = useSelector(
    (state: Store) => state.announcementsReducer.onSaveLoading,
  );
  const [ButtonClick, setButtonClick] = useState(false);
  const [selectedClass, setselectedClass] = useState({});

  const [points, setPoints] = useState<any>([]);
  const classes = useSelector(
    (state: Store) => state.classReducer.classes,
  ) as any;
  const adminRoleList = useSelector(
    (state: Store) => state.adminRolesReducer.adminRoleList,
  ) as Array<Object>;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: Object.keys(activity).length > 0 ? activity.name : "",
      classId: Object.keys(activity).length > 0 ? activity?.class?.id : "",
      limit: Object.keys(activity).length > 0 ? activity.user_limit : "",
      closeApplicationDate:
        Object.keys(activity).length > 0
          ? formatDate(activity.closeApplicationDate, "YYYY-MM-DD")
          : "",
      startDate:
        Object.keys(activity).length > 0
          ? formatDate(activity.startDate, "YYYY-MM-DD")
          : "",
      endDate:
        Object.keys(activity).length > 0
          ? formatDate(activity.endDate, "YYYY-MM-DD")
          : "",
      needToRegister:
        Object.keys(activity).length > 0 ? activity.needToRegister : true,
      points: [],
    },
    validationSchema: AddActivitySchema,
    onSubmit: async (values) => {
      if (Object.keys(activity).length <= 0) {
        const finalPoint = points;
        finalPoint.map((i: any) => delete i.name);
        values.points = finalPoint;

        dispatch(addActivity(true, values));
      } else {
        dispatch(editActivityDetails(true, values, activity.id));
      }
      onModalClose();
    },
  });

  useEffect(() => {
    if (!isAPILoading && ButtonClick) {
      onModalClose();
      setButtonClick(false);
    }
  }, [isAPILoading]);

  useEffect(() => {
    dispatch(getClasses(false, 0, { by: "createdAt", order: "ASC" }));
    dispatch(getAdminRolesList(false));
    if (activity && Object.keys(activity).length !== 0) {
      setselectedClass(activity.class);
    }
  }, [activity]);

  useEffect(() => {
    const p: any = [];
    if (adminRoleList) {
      adminRoleList.map((i: any) => {
        const roln = i.roleName.toUpperCase();
        p.push({
          name: i.roleName,
          id: i.id,
          points: ROLES_POINT[roln],
          remarks: "default points",
        });
      });

      setPoints(p);
    }
  }, [adminRoleList]);

  const handlePointChange = (index: number, field: string, value: any) => {
    const pointChange: any = points || [];
    pointChange[index][field] = value;
    setPoints(() => [...pointChange]);
  };

  const { touched, errors } = formik;
  return (
    <Modal centered show={true} onHide={() => onModalClose(false)}>
      <Modal.Header>
        <h4 className="modal-title" id="AddCourseLabel">
          {Object.keys(activity).length <= 0 ? (
            <FormattedMessage
              id="head.AddActivity"
              defaultMessage="Add Activity"
            />
          ) : (
            <FormattedMessage
              id="head.EditActivity"
              defaultMessage="Edit Activity"
            />
          )}{" "}
        </h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onModalClose}
        >
          <FaTimesCircle />
        </button>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={6}>
            <div className="form-group">
              <label className="control-label">
                <FormattedMessage
                  id="activitymodal.Name"
                  defaultMessage="Name"
                />
                *
              </label>
              <input
                type="text"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control"
              />
              {errors.name && touched.name && (
                <div className="text-error">
                  <FormattedMessage
                    id={errors.name.toString()}
                    defaultMessage="require"
                  />
                </div>
              )}
            </div>
          </Col>
          <Col md={6}>
            <div className="form-group">
              <label className="control-label">
                <FormattedMessage
                  id="activitymodal.Class"
                  defaultMessage="Class"
                />
                *
              </label>
              <Select
                name="classId"
                value={selectedClass}
                onChange={(option: any) => {
                  formik.setFieldValue("classId", option.id);
                  setselectedClass(option);
                }}
                onBlur={() => formik.setFieldTouched("classId", true)}
                options={classes}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.id}
                className="custom-select-dropdown"
                classNamePrefix="react-select-dropdown"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
              {errors.classId && touched.classId && (
                <div className="text-error">
                  <FormattedMessage
                    id={errors.classId.toString()}
                    defaultMessage="require"
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="form-group">
              <label className="control-label">
                <FormattedMessage
                  id="activitymodal.limit"
                  defaultMessage="limit"
                />
                *
              </label>
              <input
                type="text"
                name="limit"
                value={formik.values.limit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control"
              />
              {errors.limit && touched.limit && (
                <div className="text-error">
                  {" "}
                  <FormattedMessage
                    id={errors.limit.toString()}
                    defaultMessage="require"
                  />
                </div>
              )}
            </div>
          </Col>
          <Col md={6}>
            <div className="form-group">
              <label className="control-label">
                {" "}
                <FormattedMessage
                  id="activitymodal.CloseApplication"
                  defaultMessage="Close Application Date"
                />
                *
              </label>
              <input
                type="date"
                name="closeApplicationDate"
                min={disablePastDate()}
                value={formik.values.closeApplicationDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control"
              />
              {errors.closeApplicationDate && touched.closeApplicationDate && (
                <div className="text-error">
                  {" "}
                  <FormattedMessage
                    id={errors.closeApplicationDate}
                    defaultMessage="require"
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="form-group">
              <label className="control-label">
                <FormattedMessage
                  id="activitymodal.Start"
                  defaultMessage="Start Date "
                />
                *
              </label>
              <input
                type="date"
                name="startDate"
                min={disablePastDate()}
                value={formik.values.startDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control"
              />
              {errors.startDate && touched.startDate && (
                <div className="text-error">
                  {" "}
                  <FormattedMessage
                    id={errors.startDate.toString()}
                    defaultMessage="require"
                  />
                </div>
              )}
            </div>
          </Col>
          <Col md={6}>
            <div className="form-group">
              <label className="control-label">
                {" "}
                <FormattedMessage
                  id="activitymodal.End"
                  defaultMessage="End Date"
                />
                *
              </label>
              <input
                type="date"
                name="endDate"
                min={disablePastDate()}
                value={formik.values.endDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control"
              />
              {errors.endDate && touched.endDate && (
                <div className="text-error">
                  <FormattedMessage
                    id={errors.endDate.toString()}
                    defaultMessage="require"
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>

        {/* <div className="form-group">
          <label className="control-label mr-2">
            {" "}
            <FormattedMessage
              id="activitymodal.UserRegister"
              defaultMessage="End Date"
            />
          </label>
          <input
            type="checkbox"
            name="needToRegister"
            checked={formik.values.needToRegister}
            onChange={() =>
              formik.setFieldValue(
                "needToRegister",
                !formik.values.needToRegister
              )
            }
          />
        </div> */}

        {Object.keys(activity).length <= 0 ? (
          <>
            <label className="control-label">
              {" "}
              <FormattedMessage
                id="activitymodal.Points"
                defaultMessage="Points"
              />
            </label>
            <Table responsive bordered hover size="sm">
              <thead>
                <tr>
                  <th>
                    {" "}
                    <FormattedMessage
                      id="activitymodal.Role"
                      defaultMessage="Role"
                    />
                  </th>
                  <th>
                    {" "}
                    <FormattedMessage
                      id="activitymodal.Point"
                      defaultMessage="Points"
                    />
                  </th>
                  <th>
                    {" "}
                    <FormattedMessage
                      id="activitymodal.Remark"
                      defaultMessage="Remark"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {points.map((i: any, index: number) => (
                  <tr>
                    <td>{i.name}</td>
                    <td>
                      <input
                        type="text"
                        // onChange={(e: any) =>
                        // }
                        onChange={(e: any) => {
                          if (!isPositiveNumbersOnly(e, e.target.value)) {
                            return false;
                          }
                          handlePointChange(index, "points", e.target.value);
                        }}
                        style={{ border: "none" }}
                        className="form-control"
                        value={i.points}
                        min={0}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e: any) =>
                          handlePointChange(index, "remarks", e.target.value)
                        }
                        style={{ border: "none" }}
                        value={i.remarks}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>{" "}
          </>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          onClick={onModalClose}
        >
          {" "}
          <FormattedMessage id="classmodal.Cancel" defaultMessage="Cancel" />
        </button>
        <button
          type="button"
          className="btn btn-primary"
          style={{ display: "inherit" }}
          onClick={() => formik.handleSubmit()}
          data-dismiss="modal"
        >
          {Object.keys(activity).length <= 0 ? (
            <FormattedMessage id="page.Add" defaultMessage="Add" />
          ) : (
            <FormattedMessage id="page.Edit" defaultMessage="Edit" />
          )}
          {isAPILoading && <Loader />}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddActivityModal;
