import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import CenterLoader from "../../../Components/common/CenterLoader/CenterLoader";
import NoDatFound from "../../../Components/common/NoDatFound";
import Pagination from "../../../Components/common/Paginate/Pagination";
import { dispatch } from "../../../redux";
import { getActivityAttendence } from "../../../redux/action";
import { Store } from "../../../redux/Actions";
import { formatDate } from "../../../util/utils";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

const AttendenceList = () => {
  const urlParams = useParams();

  const attendenceList = useSelector(
    (state: Store) => state.ActivityReducer.attendenceList,
  ) as Array<Object>;
  const isLoading = useSelector(
    (state: Store) => state.ActivityReducer.isLoading,
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const totalRecord = useSelector(
    (state: Store) => state.ActivityReducer.totalRecord,
  ) as any;

  const onPageChange = (pageNum: number) => {
    setPageNumber(pageNum);
  };

  useEffect(() => {
    const id: number = parseInt(urlParams.id as string);
    if (id > 0) {
      dispatch(getActivityAttendence(false, pageNumber, id, pageLimit));
    }
  }, [urlParams.id, pageNumber, pageLimit]);

  return (
    <>
      {isLoading && <CenterLoader />}

      <div className="table-responsive">
        {attendenceList && attendenceList.length > 0 ? (
          <>
            {" "}
            <table className="table table-hover m-0">
              <thead>
                <tr>
                  {/* <th>Class Name</th>
                            <th>Activity Name</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Start Date</th> */}

                  <th key="head_summry">
                    {" "}
                    <FormattedMessage
                      id="table.head.Class"
                      defaultMessage="Class"
                    />
                  </th>
                  <th key="head_summry">
                    {" "}
                    <FormattedMessage
                      id="tab.Activity"
                      defaultMessage="Activity"
                    />
                  </th>
                  <th key="head_summry">
                    {" "}
                    <FormattedMessage
                      id="table.head.Type"
                      defaultMessage="Type"
                    />
                  </th>
                  <th key="head_summry">
                    {" "}
                    <FormattedMessage
                      id="table.head.Status"
                      defaultMessage="Status"
                    />
                  </th>
                  <th key="head_summry">
                    {" "}
                    <FormattedMessage
                      id="activitymodal.Start"
                      defaultMessage="Start Date"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {attendenceList &&
                  attendenceList.length > 0 &&
                  attendenceList.map((i: any) => (
                    <tr>
                      <td>{i?.activity?.class?.name}</td>
                      <td>{i?.activity?.name}</td>
                      <td>{i?.type}</td>
                      <td>{i?.Attended}</td>
                      <td>
                        {formatDate(i?.activity?.startDate, "D MMM YYYY HH:MM")}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        ) : (
          <p>{!isLoading && <NoDatFound />}</p>
        )}
      </div>
      {totalRecord > 10 && attendenceList.length > 0 && (
        <>
          <FormControl className="pagelimit">
            <FormLabel id="demo-row-radio-buttons-group-label">
              <FormattedMessage id="page_size" defaultMessage="Page Size" />
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={pageLimit}
              onChange={(e: any) => {
                setPageLimit(e.target.value);
                setPageNumber(1);
              }}
            >
              <FormControlLabel value={10} control={<Radio />} label="10" />
              <FormControlLabel value={20} control={<Radio />} label="20" />
              <FormControlLabel value={50} control={<Radio />} label="50" />
            </RadioGroup>
          </FormControl>
          <Pagination
            itemsPerPage={pageLimit}
            totalPages={totalRecord}
            onPageChage={onPageChange}
            page={pageNumber - 1}
          />
        </>
      )}
    </>
  );
};

export default AttendenceList;
