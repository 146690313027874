/* eslint-disable no-case-declarations */
import {
  GET_ALL_BILLS_LIST,
  EDIT_BILL_LIST,
  UPDATE_BILL_LIST,
} from "../constants/action-types";
import { BillData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";

const initialState = {
  progress: false,
  isLoading: false,
  BillList: [],
  BillDetails: {},
  totalRecord: 0,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: BillData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case GET_ALL_BILLS_LIST.GET_ALL_BILLS_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_ALL_BILLS_LIST.GET_ALL_BILLS_LIST_SUCCESS:
        const allBill: any = action.payload;
        return {
          ...state,
          BillList: allBill.data,
          totalRecord: allBill.totalCount,
          isLoading: false,
        };

      case GET_ALL_BILLS_LIST.GET_ALL_BILLS_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case EDIT_BILL_LIST.EDIT_BILL_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case EDIT_BILL_LIST.EDIT_BILL_LIST_SUCCESS:
        return {
          ...state,
          BillDetails: action.payload,
          isLoading: false,
        };

      case EDIT_BILL_LIST.EDIT_BILL_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case UPDATE_BILL_LIST.UPDATE_BILL_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case UPDATE_BILL_LIST.UPDATE_BILL_LIST_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case UPDATE_BILL_LIST.UPDATE_BILL_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      default:
        return state;
    }
  },
);

export default reducer;
