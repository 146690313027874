import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ListGroup from "../../Components/Group/ListGroup";
import { dispatch } from "../../redux";
import { getTicketGroupList, getTicketGroupListInit } from "../../redux/action";
import { Store } from "../../redux/Actions";
import { Card } from "react-bootstrap";
import Box from "@mui/material/Box";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import CreateGroup from "../../Components/Group/CreateGroup";
import { GROUP_TYPE } from "../../Constants";
import { BsChevronLeft } from "../../reactIcons";

const TicketGroupSettings = () => {
  const groupList = useSelector(
    (state: Store) => state.ticketReducer.groupList || [],
  );
  const [addGroup, setAddGroup] = useState(false);

  useEffect(() => {
    if (!(groupList.length > 0)) {
      dispatch(getTicketGroupList(false, GROUP_TYPE.TICKET));
    }
  }, []);

  return (
    <div className="content-wrapper">
      {/* {fileLoading && <CenterLoader />} */}
      <div className="content">
        {/* Hello */}
        <header className="page-header">
          <div className="d-flex  align-items-center">
            <div className="mr-auto">
              <Link to="/tickets">
                {" "}
                <span style={{ verticalAlign: "middle" }}>
                  {" "}
                  <i className="back-button">
                    <BsChevronLeft /> &nbsp;
                  </i>
                  <FormattedMessage id="page.back" defaultMessage="Back" />{" "}
                </span>
              </Link>
            </div>
            <div className="m-l-10">
              <a
                href="#"
                data-toggle="modal"
                onClick={() => setAddGroup(true)}
                data-target="#AddCourse"
                type="button"
                className="btn btn-primary"
              >
                <FormattedMessage
                  id="Tickets.AddGroup"
                  defaultMessage="Add Group"
                />
              </a>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          <b>
            {" "}
            <h1 style={{ fontWeight: "500" }}>
              {" "}
              <FormattedMessage
                id="Tickets.TicketGroups"
                defaultMessage="Ticket Groups"
              />
            </h1>
          </b>
          <Card>
            <Card.Body className="p-0">
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                {groupList.length > 0 && (
                  <ListGroup GroupList={groupList} type={GROUP_TYPE.TICKET} />
                )}
              </Box>
            </Card.Body>
          </Card>
        </section>
      </div>
      {addGroup && (
        <CreateGroup
          onModalClose={() => setAddGroup(false)}
          type={GROUP_TYPE.TICKET}
        />
      )}
    </div>
  );
};

export default TicketGroupSettings;
