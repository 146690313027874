import { IoMdCloseCircle } from "react-icons/io";
import { useState } from "react";
import { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

interface ImageWithOnClickModalProps {
  src: string;
  imageContainerStyle?: React.CSSProperties;
  imageStyle?: React.CSSProperties;
  modelBoxStyle?: SxProps;
}

const ImageWithOnClickModal = (props: ImageWithOnClickModalProps) => {
  const { modelBoxStyle, imageContainerStyle, imageStyle, src } = props;

  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen((oldOpen) => !oldOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        style={{ ...defaultImageContainerStyle, ...imageContainerStyle }}
        onClick={toggleOpen}
      >
        <img
          style={{ cursor: "pointer", objectFit: "cover", ...imageStyle }}
          src={src}
        />
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...defaultModalStyle, ...modelBoxStyle }}>
          <IoMdCloseCircle
            size={24}
            style={defaultCloseButtonStyle}
            onClick={handleClose}
          />
          <a href={src} target="_blank" rel="noreferrer">
            <img src={src} />
          </a>
        </Box>
      </Modal>
    </>
  );
};

const defaultModalStyle = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
} satisfies SxProps;

const defaultImageContainerStyle: React.CSSProperties = {
  maxHeight: "256px",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const defaultCloseButtonStyle: React.CSSProperties = {
  cursor: "pointer",
  position: "absolute" as const,
  top: "1rem",
  right: "1rem",
};

export default ImageWithOnClickModal;
