import { Switch } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
import { dispatch } from "../../redux";
import {
  addClassMembershipList,
  getClasses,
  getGiftCategoryList,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import { AddAddClassMembershipSchema } from "../../util/validationSchema";
import { FormattedMessage } from "react-intl";
import Loader from "../../Components/common/Loader";
const Class = () => {
  const classes = useSelector(
    (state: Store) => state.classReducer.classes,
  ) as any;
  // const giftCatList = useSelector(
  //   (state: Store) => state.giftReducer.giftCatList
  // );
  const [selectedClass, setselectedClass] = useState([]);
  const [selectedType, setselectedType] = useState<any[]>([]);

  const isLoading = useSelector(
    (state: Store) => state.membershipReducer.isLoading,
  );
  const [buttonClick, setButtonClick] = useState({});

  useEffect(() => {
    dispatch(getClasses(false, 0, { by: "createdAt", order: "ASC" }));
    dispatch(getGiftCategoryList(false));
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      classes: [],
      types: [],
      remarks: "",
      grantTime: "",
      permanent: false,
    },
    validationSchema: AddAddClassMembershipSchema,
    onSubmit: async (values) => {
      if (!buttonClick) {
        values.remarks = values.remarks.trim();
        dispatch(addClassMembershipList(true, values));
        setButtonClick(true);
      }
    },
  });

  const onCheckboxChange = (e: any) => {
    const chk: any = [];
    if (e.target.checked) {
      chk.push(e.target.value);
      chk.push(...selectedType);
      setselectedType(chk);
      formik.setFieldValue(
        "types",
        chk.map((i: any) => i),
      );
    } else {
      const arr: any[] = selectedType;
      // const index = arr.indexOf(e.target.value);
      // const a = selectedType.splice(index, 1);
      const n: any = [];
      arr.map((i: any) => {
        if (i !== e.target.value) {
          n.push(i);
        }
      });
      setselectedType(n);
      formik.setFieldValue(
        "types",
        n.map((i: any) => i),
      );
    }
    // setButtonClick(true);
  };

  const { touched, errors, resetForm } = formik;

  useEffect(() => {
    if (!isLoading && buttonClick) {
      resetForm();
      setselectedClass([]);
      setselectedType([]);
      setButtonClick(false);
    }
  }, [isLoading]);

  const onFormReset = (e: any) => {
    e.preventDefault();
    setselectedClass([]);
    setselectedType([]);
    resetForm();
  };

  return (
    <form
      onSubmit={(e: any) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <div className="card-body">
        <Row>
          <Col xl={8}>
            <Row>
              <Col md={6}>
                <div className="form-group">
                  <label className="control-label">
                    <FormattedMessage
                      id="Membership.class"
                      defaultMessage="Class Selection"
                    />
                  </label>
                  <Select
                    name="classes"
                    value={selectedClass}
                    onChange={(option: any) => {
                      setselectedClass(option);
                      formik.setFieldValue(
                        "classes",
                        option.map((i: any) => i.id),
                      );
                    }}
                    onBlur={() => formik.setFieldTouched("classes", true)}
                    options={classes}
                    getOptionLabel={(option: any) => option.name}
                    getOptionValue={(option: any) => option.id}
                    isMulti={true}
                    className="custom-select-dropdown multi"
                    classNamePrefix="react-select-dropdown"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                  {errors.classes && touched.classes && (
                    <div className="text-error">
                      <FormattedMessage
                        id={errors.classes.toString()}
                        defaultMessage="This field is required"
                      />{" "}
                    </div>
                  )}
                </div>
              </Col>
              {!formik.values.permanent && (
                <Col md={6}>
                  <div className="form-group">
                    <label className="control-label">
                      <FormattedMessage
                        id="Membership.class.time"
                        defaultMessage="Grant Time (In Months)"
                      />{" "}
                    </label>
                    <input
                      type="number"
                      name="grantTime"
                      value={formik.values.grantTime}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="form-control"
                      min={1}
                      max={100000}
                    />
                    {errors.grantTime && touched.grantTime && (
                      <div className="text-error">
                        <FormattedMessage
                          id={errors.grantTime.toString()}
                          defaultMessage="This field is  required"
                        />
                      </div>
                    )}
                  </div>
                </Col>
              )}
              <Col md={6}>
                <div className="form-group">
                  <label className="control-label">
                    <FormattedMessage
                      id="Membership.class.permanent"
                      defaultMessage="Permanent"
                    />
                  </label>
                  <div>
                    <Switch
                      checked={formik.values.permanent}
                      onChange={() => {
                        formik.setFieldValue(
                          "permanent",
                          !formik.values.permanent,
                        );
                        formik.setFieldValue("grantTime", 0);
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="control-label d-block">
                    <FormattedMessage
                      id="Membership.class.Choice"
                      defaultMessage="Grant Choice"
                    />
                  </label>
                  <label className="control control-outline control-primary control--checkbox font-size-14 d-inline-block mb-0 mr-3 pointer">
                    <input
                      type="checkbox"
                      value={"1"}
                      checked={formik?.values?.types.includes("1" as never)}
                      onChange={(value: any) => onCheckboxChange(value)}
                    />
                    <FormattedMessage
                      id="Membership.class.Student"
                      defaultMessage="Student"
                    />
                    <div className="control__indicator"></div>
                  </label>
                  <label className="control control-outline control-primary control--checkbox font-size-14 d-inline-block mb-0 mr-3 pointer">
                    <input
                      type="checkbox"
                      value={"3"}
                      checked={
                        formik?.values?.types?.includes("3" as never)
                          ? true
                          : false
                      }
                      onChange={(value: any) => onCheckboxChange(value)}
                    />{" "}
                    <FormattedMessage
                      id="Membership.class.Coacher"
                      defaultMessage="Coacher"
                    />
                    <div className="control__indicator"></div>
                  </label>
                  {errors.types && touched.types && (
                    <div className="text-error">
                      <FormattedMessage
                        id={errors.types.toString()}
                        defaultMessage="This field is required"
                      />{" "}
                    </div>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="control-label">
                    <FormattedMessage
                      id="Membership.class.Remarks"
                      defaultMessage="Remarks"
                    />
                  </label>
                  <input
                    type="text"
                    name="remarks"
                    value={formik.values.remarks}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-control"
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="card-footer bg-light text-right">
        <button
          className="btn btn-secondary mr-3"
          onClick={(e: any) => onFormReset(e)}
        >
          <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
        </button>
        <Button type="submit">
          <FormattedMessage
            id="Membership.class.Submit"
            defaultMessage="Submit"
          />
          {isLoading && <Loader />}
        </Button>
      </div>
    </form>
  );
};

export default Class;
