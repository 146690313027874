/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/APIServices/baseApiCall";
import {
  ADD_FAQ_LIST,
  DELETE_FAQ,
  EDIT_FAQ_LIST,
  GET_FAQ_LIST,
  GET_ALL_FAQ_CATEGORY_LIST,
  ADD_FAQ_CATEGORY,
  EDIT_FAQ_CATEGORY,
  DELETE_FAQ_CATEGORY,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import {
  ADD_FAQ_LIST_URL,
  DELETE_FAQ_LIST_URL,
  EDIT_FAQ_LIST_URL,
  GET_ALL_FAQ_LIST_URL,
  GET_ALL_FAQ_CATEGORY_LIST_URL,
  ADD_FAQ_CATEGORY_LIST_URL,
  EDIT_FAQ_CATEGORY_LIST_URL,
  DELETE_FAQ_CATEGORY_LIST_URL,
} from "../../service/APIServices/apiEndPoints";

//Get Admin Roles List
export const getFAQList =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getFAQListInit(showToast));
  };

export const getFAQListInit =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_FAQ_LIST.GET_FAQ_LIST_INITIALIZATION,
    });
    apiCall(
      GET_ALL_FAQ_LIST_URL,
      {},
      (res: Object) => dispatch(getFAQListSuccess(res)),
      (err: any) => dispatch(getFAQListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getFAQListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_FAQ_LIST.GET_FAQ_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getFAQListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_FAQ_LIST.GET_FAQ_LIST_ERROR,
    });
  };

//Add Admin FAQ
export const addFaqList =
  (
    showToast = true,
    faq: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(addFaqListInit(showToast, faq));
  };

export const addFaqListInit =
  (
    showToast = true,
    faq: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_FAQ_LIST.ADD_FAQ_LIST_INITIALIZATION,
    });
    apiCall(
      ADD_FAQ_LIST_URL,
      faq,
      (res: Object) => dispatch(addFaqListSuccess(res)),
      (err: any) => dispatch(addFaqListError()),
      METHOD.POST,
      { showToast },
    );
  };

export const addFaqListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_FAQ_LIST.ADD_FAQ_LIST_SUCCESS,
      payload: res.data,
    });
    dispatch(getFAQList(false));
  };

export const addFaqListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_FAQ_LIST.ADD_FAQ_LIST_ERROR,
    });
  };

//Edit Admin FAQ
export const editFaqList =
  (
    showToast = true,
    id: any,
    faqData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(editFaqListInit(showToast, id, faqData));
  };

export const editFaqListInit =
  (
    showToast = true,
    id: any,
    faqData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_FAQ_LIST.EDIT_FAQ_LIST_INITIALIZATION,
    });
    apiCall(
      EDIT_FAQ_LIST_URL + id,
      faqData,
      (res: Object) => dispatch(editFaqListSuccess(res)),
      (err: any) => dispatch(editFaqListError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const editFaqListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_FAQ_LIST.EDIT_FAQ_LIST_SUCCESS,
      payload: res.data,
    });
    dispatch(getFAQList(false));
  };

export const editFaqListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: EDIT_FAQ_LIST.EDIT_FAQ_LIST_ERROR,
    });
  };

//Delete Admin FAQ
export const deleteFaq =
  (
    showToast = true,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(deleteFaqInit(showToast, id));
  };

export const deleteFaqInit =
  (
    showToast = true,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_FAQ.DELETE_FAQ_INITIALIZATION,
    });
    apiCall(
      DELETE_FAQ_LIST_URL + id,
      {},
      (res: Object) => dispatch(deleteFaqSuccess(res)),
      (err: any) => dispatch(deleteFaqError()),
      METHOD.DELETE,
      { showToast },
    );
  };

export const deleteFaqSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_FAQ.DELETE_FAQ_SUCCESS,
      payload: res.data,
    });
    dispatch(getFAQList(false));
  };

export const deleteFaqError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: DELETE_FAQ.DELETE_FAQ_ERROR,
    });
  };

//Get Admin All FAQCategory List
export const getFAQCategoryList =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getFAQCategoryListInit(showToast));
  };

export const getFAQCategoryListInit =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ALL_FAQ_CATEGORY_LIST.GET_ALL_FAQ_CATEGORY_LIST_INITIALIZATION,
    });
    apiCall(
      GET_ALL_FAQ_CATEGORY_LIST_URL,
      {},
      (res: Object) => dispatch(getFAQCategoryListSuccess(res)),
      (err: any) => dispatch(getFAQCategoryListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getFAQCategoryListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ALL_FAQ_CATEGORY_LIST.GET_ALL_FAQ_CATEGORY_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getFAQCategoryListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_ALL_FAQ_CATEGORY_LIST.GET_ALL_FAQ_CATEGORY_LIST_ERROR,
    });
  };

//Add Admin FAQCategory
export const addFAQCategory =
  (
    faqCategory: any,
    showToast = true,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(addFAQCategoryInit(faqCategory, showToast));
  };

export const addFAQCategoryInit =
  (
    faqCategory: any,
    showToast = true,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_FAQ_CATEGORY.ADD_FAQ_CATEGORY_INITIALIZATION,
    });
    apiCall(
      ADD_FAQ_CATEGORY_LIST_URL,
      faqCategory,
      (res: Object) => dispatch(addFAQCategorySuccess(res)),
      (err: any) => dispatch(addFAQCategoryError()),
      METHOD.POST,
      { showToast },
    );
  };

export const addFAQCategorySuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_FAQ_CATEGORY.ADD_FAQ_CATEGORY_SUCCESS,
      payload: res.data,
    });
    dispatch(getFAQCategoryList(false));
  };

export const addFAQCategoryError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_FAQ_CATEGORY.ADD_FAQ_CATEGORY_ERROR,
    });
  };

//Edit Admin FAQCategory
export const editFAQCategory =
  (
    faqCategory: any,
    id: any,
    showToast = true,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(editFAQCategoryInit(faqCategory, id, showToast));
  };

export const editFAQCategoryInit =
  (
    faqCategory: any,
    id: any,
    showToast = true,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_FAQ_CATEGORY.EDIT_FAQ_CATEGORY_INITIALIZATION,
    });
    apiCall(
      EDIT_FAQ_CATEGORY_LIST_URL + id,
      faqCategory,
      (res: Object) => dispatch(editFAQCategorySuccess(res)),
      (err: any) => dispatch(editFAQCategoryError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const editFAQCategorySuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_FAQ_CATEGORY.EDIT_FAQ_CATEGORY_SUCCESS,
      payload: res.data,
    });
    dispatch(getFAQCategoryList(false));
  };

export const editFAQCategoryError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: EDIT_FAQ_CATEGORY.EDIT_FAQ_CATEGORY_ERROR,
    });
  };

//Delete Admin FAQCategory
export const deleteFAQCategory =
  (
    id: any,
    showToast = true,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(deleteFAQCategoryInit(id, showToast));
  };

export const deleteFAQCategoryInit =
  (
    id: any,
    showToast = true,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_FAQ_CATEGORY.DELETE_FAQ_CATEGORY_INITIALIZATION,
    });
    apiCall(
      DELETE_FAQ_CATEGORY_LIST_URL + id,
      {},
      (res: Object) => dispatch(deleteFAQCategorySuccess(res)),
      (err: any) => dispatch(deleteFAQCategoryError()),
      METHOD.DELETE,
      { showToast },
    );
  };

export const deleteFAQCategorySuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_FAQ_CATEGORY.DELETE_FAQ_CATEGORY_SUCCESS,
      payload: res.data,
    });
    dispatch(getFAQCategoryList(false));
  };

export const deleteFAQCategoryError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: DELETE_FAQ_CATEGORY.DELETE_FAQ_CATEGORY_ERROR,
    });
  };
