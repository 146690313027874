import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { DeleteGemsAdvertisementByIdParams } from "../schemas/deleteGemsAdvertisementSchema";
import { customFetch } from "../../../util/axios";

interface DeleteGemsAdvertisementResponse {}

const deleteGemsAdvertisementById = async (
  deleteGemsAdvertisementByIdParams: DeleteGemsAdvertisementByIdParams,
) => {
  const { gemsAdvertisementId } = deleteGemsAdvertisementByIdParams;
  const response = await customFetch.delete(
    `/gems-advertisements/${gemsAdvertisementId}`,
  );
  return response.data;
};

export const useDeleteGemsAdvertisementById = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    DeleteGemsAdvertisementResponse,
    AxiosError | Error,
    DeleteGemsAdvertisementByIdParams
  >({
    mutationFn: deleteGemsAdvertisementById,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["gems-advertisements"] }),
  });

  return mutation;
};
