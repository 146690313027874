import {
  GemsAdvertisementsHeader,
  GemsAdvertisementsTable,
} from "../../Components/GemsAdvertisements";

const GemsAdvertisements = () => {
  return (
    <>
      <div className="content">
        <GemsAdvertisementsHeader />
        <GemsAdvertisementsTable />
      </div>
    </>
  );
};

export default GemsAdvertisements;
