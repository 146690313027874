import { TICKET_STATUS } from "../../Constants";

export const LOGIN_TYPE: any = {
  ADMIN: 0,
  USER: 1,
};
export const UserGroup: any = {
  STUDENT: "0",
  REFFRER: "1",
  COACHER: "2",
  EMPLOYEE: "3",
};

export const UserGroupOption: any = [
  {
    id: UserGroup.STUDENT,
    group: "Student",
  },
  {
    id: UserGroup.REFFRER,
    group: "Student",
  },
  {
    id: UserGroup.COACHER,
    group: "Student",
  },
  {
    id: UserGroup.EMPLOYEE,
    group: "Student",
  },
];

export const TOOLS = {
  EXPERT_ADVISOR: 0,
  UTILITIES: 1,
  SCRIPTS: 2,
  INDICATOR: 3,
  LIBRARIES: 4,
  GEMSFX: 6,
};

export const USER_VIEW = {
  PENDING: 0,
  APPROVE: 1,
  REJECTED: 2,
};
export const UserStatus = {
  PENDING: "pending",
  APPROVED: "approved",
  SIGNATURE: "signature",
};

export const UserStatusOption: any = [
  { label: "APPROVED", value: "approved" },
  { label: "SIGNATURE", value: "pending" },
];

export const TOOLSMAP: any = {
  0: "ea",
  1: "Utility",
  2: "Script",
  3: "Indicator",
  4: "Libraries",
  6: "GemsFx",
};

export const TOOLSMAP_ID: any = {
  0: "EA",
  1: "UT",
  2: "SC",
  3: "IN",
  4: "LI",
};

export const ToolTypeOption: any = [
  {
    id: TOOLS.EXPERT_ADVISOR,
    group: "Expert Advisor",
  },
  {
    id: TOOLS.UTILITIES,
    group: "Utilities",
  },
  {
    id: TOOLS.SCRIPTS,
    group: "Script",
  },
  {
    id: TOOLS.INDICATOR,
    group: "Indicator",
  },
  {
    id: TOOLS.LIBRARIES,
    group: "Library",
  },
];

export const ROLES_POINT: any = {
  STUDENT: "100",
  REPEATER: "100",
  COACHER: "100",
  "WALK-IN": "0",
  REFERRAL: "0",
  CUSTOMER: "0",
};

export const MemebershipGrantTimeOption: any = [
  {
    id: 0,
    value: "Manual",
    valueZh: "手動的",
  },
  {
    id: 1,
    value: "Select Date",
    valueZh: "選擇日期",
  },
  {
    id: 365,
    value: "365 days from now or expiry date",
    valueZh: "從現在起 365 天或到期日",
  },
  {
    id: 180,
    value: "180 days from now or expiry date",
    valueZh: "從現在起 180 天或到期日",
  },
  {
    id: 120,
    value: "120 days from now or expiry date",
    valueZh: "從現在起 120 天或到期日",
  },
  {
    id: 90,
    value: "90 days from now or expiry date",
    valueZh: "從現在起 90 天或到期日",
  },
  {
    id: 60,
    value: "60 days from now or expiry date",
    valueZh: "從現在起 60 天或到期日",
  },
  {
    id: 30,
    value: "30 days from now or expiry date",
    valueZh: "從現在起 30 天或到期日",
  },
];

export const TicketOption: any = [
  {
    id: TICKET_STATUS.OPEN,
    label: "Open",
  },
  {
    id: TICKET_STATUS.CLOSE,
    label: "Close",
  },
  {
    id: TICKET_STATUS.INPROGRESS,
    label: "Inprogress",
  },
  {
    id: TICKET_STATUS.REOPEN,
    label: "Reopen",
  },
];

export const AccountType: any = [
  {
    id: "L",
    value: "Live Account",
  },
  {
    id: "D",
    value: "Demo Account",
  },
];

export const UserType = [
  { id: "admin", name: "Admin" },
  { id: "admin_read_only", name: "Admin read only" },
];
