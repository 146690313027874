import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { FormattedMessage } from "react-intl";

const AddRemoveToolRoleModal = ({
  onClose,
  open,
  onDeleteClick,
  text,
}: any) => {
  const handleClose = () => {
    onClose(false);
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        {
          <FormattedMessage
            id={`msg.${text}`}
            defaultMessage="Do you really want to add this role to the tool?"
          />
        }
      </DialogTitle>
      <List
        sx={{ pt: 0 }}
        style={{ textAlign: "end", padding: "12px 12px 12px 12px" }}
      >
        <Button
          onClick={handleClose}
          variant="outlined"
          startIcon={<CloseIcon />}
          className="mr-2"
          color="error"
        >
          <FormattedMessage id="page.No" defaultMessage="No" />
        </Button>
        <Button
          onClick={onDeleteClick}
          variant="outlined"
          startIcon={<DoneIcon />}
          color="success"
        >
          <FormattedMessage id="page.Yes" defaultMessage="Yes" />
        </Button>
      </List>
    </Dialog>
  );
};

AddRemoveToolRoleModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  text: PropTypes.any,
};

export default AddRemoveToolRoleModal;
