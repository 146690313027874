// import { useState } from "react";
import { Card } from "react-bootstrap";
// import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// import { makeStyles } from "@mui/styles";

// import { Store } from "../../redux/Actions";
import { FormattedMessage } from "react-intl";

const Statistics = () => {
  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                <FormattedMessage
                  id="AdminStatistics"
                  defaultMessage="Admin Statistics"
                />
              </h1>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          <Card>
            <Card.Body className="p-0"></Card.Body>
          </Card>
        </section>
      </div>
    </div>
  );
};

export default Statistics;

// const useStyles = makeStyles({
//   tabs: {
//     "& .MuiTabs-indicator": {
//       backgroundColor: "#68439a",
//       height: 1,
//     },
//     "& .MuiTab-root.Mui-selected": {
//       color: "#68439a",
//       BsBorderBottom: "1px solid",
//     },
//   },
// });

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// const a11yProps = (index: any) => {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// };
