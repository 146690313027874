import { Avatar, Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BsTrash } from "react-icons/bs";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import Loader from "../../Components/common/Loader";
import { dispatch } from "../../redux";
import {
  addTicketCommentList,
  deleteMediaLocalInit,
  deleteTicketCommentListInit,
  editTicketCommentListInit,
  getQR,
  getTicketCommentList,
  setMediaUploadEmpty,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import { _UploadMediaMutiple } from "../../service/MediaUpload/classes.service";
import {
  customFormatDatetimeWithLocale,
  formatDate,
  getFileName,
  getTextFromHtmlRowText,
  get_url_extension,
  get_url_extensions,
} from "../../util/utils";
import draftToHtml from "draftjs-to-html";
import { convertToRaw, EditorState, convertFromHTML } from "draft-js";
import { ContentState } from "draft-js";
import EditorContainer from "../../Components/RichTextEditor/Editor";
import DeletePopup from "../../Components/common/Popups/DeletePopup";
import Pagination from "../../Components/common/Paginate/Pagination";
import Viewer from "react-viewer";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";
import { ToastDownload } from "../../Components/common/ToastDownload";

export const TICKET_COMMENT_ATTACHMENT = "ticketCommentAttachment";
export const EDT_TICKET_COMMENT_ATTACHMENT = "edtTicketCommentAttachment";
const IMAGE_TYPES = ["png", "jpeg", "jpg"];

const TicketComment = () => {
  const urlParams = useParams();
  const [commentText, setCommentText] = useState("");
  const [commentId, setCommentId] = useState(0);
  const [fileAdd, setFileAdd] = useState(false);
  const [deletes, setDelete] = useState(false);
  const [commentAttachment, setCommentAttachment] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [userGroup, setUserGroup] = useState<any>();
  const [Load, setLoad] = useState(false);
  const btnRef = useRef<any>();
  const [LoadToastId, setLoadToastId] = useState<any>([]);
  const [deleteAttachementOne, setDeleteAttachementOne] = useState({
    modal: false,
    id: 0,
  });
  const [deleteAttachementTwo, setDeleteAttachementTwo] = useState({
    modal: false,
    id: 0,
    comment: [],
  });

  const ticketComment: any = useSelector(
    (state: Store) => state.ticketReducer.commentList,
  ) as any;

  const ticketDetails: any = useSelector(
    (state: Store) => state.ticketReducer.ticketDetails,
  ) as any;
  const onCSaveLoading: any = useSelector(
    (state: Store) => state.ticketReducer.onCSaveLoading,
  ) as any;

  const totalRecord: any = useSelector(
    (state: Store) => state.ticketReducer.totalCommentRecords,
  ) as any;
  const uploadLoad: any = useSelector(
    (state: Store) => state.mediaUploadReducer.uploadLoad,
  ) as any;
  const userDetail = useSelector(
    (state: Store) => state.userDataReducer.myInfo,
  ) as any;

  const userIsLoading = useSelector(
    (state: Store) => state.userDataReducer.isLoading,
  ) as any;

  // const [ticketCommentText, setTicketCommentText] = useState(ticketComment);
  const [isCommentEdit, setIsCommentEdit] = useState(0);
  const commentAttchmentRef = useRef<any>();
  const ticketCommentAttachment = useSelector(
    (state: Store) => state.mediaUploadReducer.ticketCommentAttachment,
  );

  const edtTicketCommentAttachment = useSelector(
    (state: Store) => state.mediaUploadReducer.edtTicketCommentAttachment,
  );

  const [buttonClick, setButtonClick] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [edtCommentText, setEdtCommentText] = useState(
    EditorState.createEmpty(),
  );
  const [commentError, setCommentError] = useState(false);
  // const [isCommentEdit, setIsCommentEdit] = useState([]);
  const [img, setImg] = useState({ image: [], show: false, index: 0 });
  const [ucommentError, setuCommentError] = useState(false);

  const [editorConfig, setEditorConfig] = useState({
    toolbar: false,
    editable: true,
    height: "70px",
    overflowY: "unset",
    border: "1px solid #efefef",
  });

  useEffect(() => {
    const { id } = urlParams;
    if (id && parseInt(id) > 0) {
      dispatch(getTicketCommentList(false, parseInt(id), pageNumber));
      if (Object.keys(userDetail).length <= 0) {
        // dispatch(getQR());
      }
    }
  }, [pageNumber]);

  useEffect(() => {
    setPageNumber(1);
    // const { id } = urlParams;
    // if (id && parseInt(id) > 0) {
    //   dispatch(getTicketCommentList(false, parseInt(id), pageNumber));
    //   if (Object.keys(userDetail).length <= 0) {
    //     // dispatch(getQR());
    //   }
    // }
  }, [ticketDetails]);

  useEffect(() => {
    if (!onCSaveLoading && buttonClick) {
      dispatch(setMediaUploadEmpty(TICKET_COMMENT_ATTACHMENT));
      setCommentText("");
      setButtonClick(false);
    }
  }, [onCSaveLoading, buttonClick]);

  useEffect(() => {
    if (commentId > 0 && fileAdd) {
      edtAttachComment();
    }
  }, [edtTicketCommentAttachment]);

  const edtAttachComment = async () => {
    if (commentId > 0) {
      const eticketCommentAttachment: any = Object.assign(
        [],
        commentAttachment,
      );
      // eticketCommentAttachment.push([...(edtTicketCommentAttachment || []).map((i: any) => i.location)])
      // await setCommentAttachment([eticketCommentAttachment]);
      // onEditComment()
      const eState = draftToHtml(
        convertToRaw(edtCommentText.getCurrentContent()),
      );

      // const eState = draftToHtml(convertToRaw(editorState.getCurrentContent()));

      const text = getTextFromHtmlRowText(eState);
      // if (text.length > 0) {
      dispatch(
        editTicketCommentListInit(
          true,
          {
            comment: eState,
            attachmentPath: [
              ...edtTicketCommentAttachment.map((i: any) => i.location),
              ...commentAttachment,
            ],
          },
          commentId,
          ticketDetails.id,
        ),
      );
      setFileAdd(false);
      setCommentId(0);
      // }
      // else {
      //   setuCommentError(true);
      // }
    }
  };

  useEffect(() => {
    if (ticketComment) {
      // setTicketCommentText(ticketComment);
      const t: any = [];
      ticketComment.map((i: any) => {
        t.push({
          id: i.id,
          comment: false,
        });
      });

      const e: any = [];
      t.map((i: any) => {
        e[i.id] = { show: false, id: i.id, comment: i.comment };
      });

      setIsCommentEdit(e);
    }
    const estate = EditorState.createEmpty();
    setEditorState(estate);
  }, [ticketComment]);

  useEffect(() => {
    if (ticketDetails) {
      // console.log(userDetail?.ticketGroup  && userDetail?.ticketGroup?.length>0 &&userDetail?.ticketGroup?.map((data:any)=>{console.log(data)}))
      const data =
        userDetail?.ticketGroup &&
        userDetail?.ticketGroup?.length > 0 &&
        userDetail?.ticketGroup.find(
          (item: any) => item.id === ticketDetails?.ticketGroup?.id,
        );
      setUserGroup(data);
      // console.log(data)

      console.log(ticketDetails, userDetail);
    }
  }, [ticketDetails]);

  const onCommentSubmit = () => {
    if (urlParams.id) {
      const eState = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      let text = eState.toString().replaceAll("&nbsp;", "");
      text = text.replaceAll("<p>", "");
      text = text.replaceAll("</p>", "");
      text = text.replaceAll("<br>", "");
      text = text.replaceAll("\n", "");
      text = text.trim();
      // if (text.length > 0 && eState.trim() != "<p></p>") {
      if (
        text.length > 0 ||
        eState.trim() != "<p></p>" ||
        ticketCommentAttachment.length > 0
      ) {
        dispatch(
          addTicketCommentList(true, urlParams.id, {
            comment: eState,
            attachmentPath: ticketCommentAttachment.map((i: any) => i.location),
            ticketGroupId: userDetail?.ticketGroup?.[0]?.id || 0,
          }),
        );
        const estate = EditorState.createEmpty();
        setEditorState(estate);
        setCommentError(false);
        setButtonClick(true);
      } else {
        setCommentError(true);
      }
    }
  };

  // const commentClick = (id: any) => {
  //   const edit: any = isCommentEdit;
  //   // const c = edit[id];
  //   edit[id].show = true;
  //   setIsCommentEdit(edit);
  // };

  //Add comment Attachment
  const handleDrop = async (e: any) => {
    const acceptedFiles = e.target.files;
    setLoad(true);
    const files: any = [];
    for (let i = 0; i < acceptedFiles.length; i++) {
      if (acceptedFiles[i].size > 104857600) {
        toast.error(
          `${acceptedFiles[i].name} file is not allowed because it's size is more than 100MB`,
        );
      } else {
        files.push(acceptedFiles[i]);
      }
    }
    if (files.length > 0) {
      if (commentId === 0) {
        await _UploadMediaMutiple(
          [...files] as File[],
          TICKET_COMMENT_ATTACHMENT,
        );
        // await uploadFileAmazonS3(acceptedFiles[0]);
      } else {
        setFileAdd(true);
        await _UploadMediaMutiple(
          [...files] as File[],
          EDT_TICKET_COMMENT_ATTACHMENT,
        );
      }
    }
    setLoad(false);

    e.target.value = "";
  };

  const attchmentClick = () => {
    if (commentAttchmentRef.current) {
      commentAttchmentRef?.current.click();
    }
  };

  const onDelete = (key: any) => {
    dispatch(deleteMediaLocalInit(TICKET_COMMENT_ATTACHMENT, key));
    setDeleteAttachementOne({ modal: false, id: 0 });
  };

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    const eState = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const text = getTextFromHtmlRowText(eState);

    if (text.length > 0) {
      setCommentError(false);
    }
  };

  const onEditComment = () => {
    setFileAdd(false);
    const eState = draftToHtml(
      convertToRaw(edtCommentText.getCurrentContent()),
    );

    let text = eState.toString().replaceAll("&nbsp;", "");
    text = text.replaceAll("<p>", "");
    text = text.replaceAll("</p>", "");
    text = text.replaceAll("<br>", "");
    text = text.replaceAll("\n", "");
    text = text.trim();
    if (text.length > 0 && eState.trim() != "<p></p>") {
      // if (eState.trim() != "<p></p>") {
      dispatch(
        editTicketCommentListInit(
          true,
          {
            comment: eState,
            attachmentPath: commentAttachment,
          },
          commentId,
          ticketDetails.id,
        ),
      );
      const estate = EditorState.createEmpty();
      setEditorState(estate);
      setButtonClick(true);
      setIsCommentEdit(0);
    } else {
      setuCommentError(true);
    }
    // setEditorState(editorState);
  };

  const getCommentBy = (comment: any) => {
    let by = "";
    // if ((comment?.createdBy?.id || 0) !== (userDetail?.id || 0)) {
    if (comment?.commentBy?.userType !== "admin") {
      if (comment.createdBy) {
        by = comment?.createdBy?.englishFirstName || "-";
        by = by + " " + comment?.createdBy?.englishLastName || " -";
      } else {
        by = "unknown";
      }
    } else {
      by = comment?.ticketGroup?.group_name || "";
    }
    return by;
  };

  const commentClick = (id: any, comment: any, comm: any) => {
    const edit: any = isCommentEdit;
    if (comment.trim() !== "<p></p>") {
      const blocksFromHTML = convertFromHTML(comment);
      const estate = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        ),
      );
      setEdtCommentText(estate);
    }
    // const c = edit[id];
    // edit[id].show = true;
    setCommentAttachment(comm.attachmentList.map((i: any) => i.attachmentPath));
    setIsCommentEdit(id);
    setuCommentError(false);
    setCommentId(id);
  };

  const commentAttachClick = (comment: any) => {
    const edit: any = isCommentEdit;
    setCommentId(comment.id);

    if (comment.comment.trim() !== "<p></p>") {
      const blocksFromHTML = convertFromHTML(comment.comment);
      const estate = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        ),
      );
      setEdtCommentText(estate);
      // attchmentClick();
      // setCommentId(comment.id);
    }
    setCommentAttachment(
      comment.attachmentList.map((i: any) => i.attachmentPath),
    );
    attchmentClick();

    // const c = edit[id];
    // edit[id].show = true;
    // setIsCommentEdit(id);
    // setCommentId(id)
  };

  const onCommentEditorStateChange = (editorState: EditorState) => {
    setEdtCommentText(editorState);

    const eState = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    const text = getTextFromHtmlRowText(eState); //  eState.toString().replaceAll("&nbsp;", "");

    if (text.length > 0) {
      setuCommentError(false);
    }
    // setEdtCommentText(EditorState.moveFocusToEnd(editorState));
  };

  const deleteComment = () => {
    const { id } = urlParams;
    if (commentId > 0) {
      dispatch(deleteTicketCommentListInit(true, commentId, id));
      setDelete(false);
    }
  };

  const onCommentAttachmemtDelete = (comment: any, idx: any) => {
    const attchment: any = [];
    comment.attachmentList.map((i: any, index: any) => {
      if (idx !== index) {
        attchment.push(i.attachmentPath);
      }
    });

    const eState = draftToHtml(
      convertToRaw(edtCommentText.getCurrentContent()),
    );
    dispatch(
      editTicketCommentListInit(
        true,
        {
          comment: comment.comment,
          attachmentPath: [...attchment],
        },
        comment.id,
        ticketDetails.id,
      ),
    );
    setDeleteAttachementTwo({ modal: false, id: 0, comment: [] });
  };

  const onPageChange = (pageNum: number) => {
    setPageNumber(pageNum);
    // dispatch(setInCurrentPage(pageNum));
  };

  const onCommentImageClick = (imgs: any) => {
    const filesImg: any = [];
    imgs.map((i: any) => {
      if (
        IMAGE_TYPES.includes(get_url_extensions(i?.attachmentPath || "") || "")
      ) {
        filesImg.push({ src: i.attachmentPath, alt: "No Image" });
      }
    });

    setImg({ show: true, image: filesImg, index: 0 });
  };

  const locale = useSelector((state: Store) => state.userDataReducer.local);

  return (
    <div>
      {uploadLoad && <CenterLoader />}
      {userIsLoading && <CenterLoader />}
      <h2 className="mt-5">
        <FormattedMessage
          id="announcementDetail.comment"
          defaultMessage="Comment"
        />
      </h2>
      <Row>
        <Col lg={1} md={3} sm={4}>
          <Avatar sx={{ bgcolor: "gray" }} aria-label="recipe">
            {userDetail?.ticketGroup?.[0]?.group_name?.charAt(0)?.toUpperCase()}
          </Avatar>
        </Col>
        <Col lg={10} md={9} sm={8}>
          <small>
            (*{" "}
            <FormattedMessage
              id="announcementDetail.comment.subhead"
              defaultMessage="You will leave a message as "
            />
            {userDetail?.ticketGroup?.[0]?.group_name || "Administrative Team"})
          </small>
          {/* <textarea
            rows={3}
            cols={50}
            className="form-control"
            onChange={(e) => setCommentText(e.target.value)}
            value={commentText}
          > */}
          <EditorContainer
            onEditorStateChange={onEditorStateChange}
            editorState={editorState}
            toolbar={editorConfig.toolbar}
            editable={editorConfig.editable}
            height={editorConfig.height}
            overflowY={editorConfig.overflowY}
            border={editorConfig.border}
          />{" "}
          {commentText}
          {commentError && (
            <div className="text-error">
              <FormattedMessage
                id="comment.error"
                defaultMessage="Please add a comment"
              />
            </div>
          )}
          {/* </textarea> */}
          {ticketCommentAttachment.map((i: any) => (
            <div>
              <Row>
                <Col>
                  <a
                    href={i.location}
                    target="_blank"
                    style={{
                      color: "GrayText",
                      fontSize: "12px",
                      borderBottom: "1px GrayText solid",
                    }}
                  >
                    {getFileName(i.location)}
                  </a>
                </Col>
                <Col md={2} lg={1} sm={3}>
                  <Button
                    onClick={() =>
                      setDeleteAttachementOne({ modal: true, id: i.key })
                    }
                  >
                    <BsTrash />
                  </Button>
                </Col>
              </Row>
            </div>
          ))}
          <Button
            size="small"
            className="mr-2 mt-2"
            variant="contained"
            onClick={onCommentSubmit}
            style={{ textTransform: "none" }}
          >
            <FormattedMessage id="page.submit" defaultMessage="Submit" />
            {onCSaveLoading && <Loader />}
          </Button>
          <Button
            size="small"
            onClick={() => {
              attchmentClick();
              setCommentId(0);
            }}
            className="mt-2"
            variant="text"
            color="inherit"
            style={{ textTransform: "none" }}
          >
            <FormattedMessage
              id="page.AddAttachment"
              defaultMessage="Add attachment"
            />
          </Button>
        </Col>
      </Row>

      <input
        type="file"
        ref={commentAttchmentRef}
        style={{ display: "none" }}
        onChangeCapture={(e: any) => handleDrop(e)}
        multiple
      />

      {ticketComment &&
        ticketComment !== null &&
        ticketComment.map((comment: any) => (
          <>
            <Row className="mb-2 mt-4">
              <Col lg={1} md={3} sm={4}>
                <Avatar>
                  {/* {comment?.createdBy?.avatar ? (
                    <img src={comment.createdBy.avatar} />
                  ) : (
                    <>{comment?.comment?.charAt(0) || "G"} </>
                  )} */}
                  {getCommentBy(comment).charAt(0).toUpperCase()}
                </Avatar>
              </Col>
              <Col lg={10} md={9} sm={8}>
                <strong>
                  {getCommentBy(comment)}
                  {/* {comment.createdBy ? (
                    <>
                      {" "}
                      {comment?.createdBy?.englishFirstName ||
                        "-" + " " + comment?.createdBy?.englishLastName ||
                        " -"}
                    </>
                  ) : (
                    "unknown"
                  )}{" "} */}
                </strong>
                <small className="ml-2" style={{ color: "lightgray" }}>
                  {customFormatDatetimeWithLocale(
                    comment.createdAt,
                    locale,
                    true,
                  )}{" "}
                  {comment.isEdited ? "[Edited]" : ""}
                </small>
                <br />
                {isCommentEdit == comment.id ? (
                  <>
                    {" "}
                    <EditorContainer
                      onEditorStateChange={onCommentEditorStateChange}
                      editorState={edtCommentText}
                      toolbar={editorConfig.toolbar}
                      editable={editorConfig.editable}
                      height={editorConfig.height}
                      overflowY={editorConfig.overflowY}
                      border={editorConfig.border}
                    />
                    {ucommentError && (
                      <div className="text-error">
                        <FormattedMessage
                          id="comment.error"
                          defaultMessage="Please add a comment"
                        />
                      </div>
                    )}
                    <div className="mt-2 mb-2">
                      {/* <Col md={2}> */}
                      <Button
                        variant="contained"
                        className="text-capitalize"
                        size="small"
                        color="primary"
                        onClick={onEditComment}
                      >
                        <FormattedMessage id="Save" defaultMessage="Save" />
                      </Button>
                      {/* </Col> */}
                      {/* <Col> */}
                      <Button
                        className="ml-3 text-capitalize"
                        size="small"
                        onClick={() => setIsCommentEdit(0)}
                      >
                        <FormattedMessage id="Cancel" defaultMessage="Cancel" />
                      </Button>
                      {/* </Col> */}
                    </div>
                    {/* <div className="mt-2">
                      {/* <Col md={2}> */}
                    {/* <Button variant="contained" size="small" color="primary">Save</Button> */}
                    {/* </Col> */}
                    {/* <Col> */}
                    {/* <Button className="ml-3" size="small" onClick={() => setIsCommentEdit(0)} >Cancel</Button> */}
                    {/* </Col>
                  </div> * /}*/}
                  </>
                ) : (
                  <p
                    className="mt-1"
                    onClick={() =>
                      commentClick(comment.id, comment.comment, comment)
                    }
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(comment.comment),
                      }}
                    />
                  </p>
                )}
                {(comment?.attachmentList || []).map((i: any, index: any) => (
                  <div style={{ display: "inline-block" }}>
                    {IMAGE_TYPES.includes(
                      //image only
                      get_url_extensions(i?.attachmentPath) || "",
                    ) && (
                      <div className="attachment-img-container">
                        <div className="attachment-img">
                          {" "}
                          <img
                            src={i.attachmentPath}
                            width="70"
                            height="70"
                            onClick={() => {
                              // setImg({
                              //   image: (comment?.attachmentList || []).map((i: any) => { return { src: i.attachmentPath, alt: 'No Image' } }),
                              //   show: true,
                              //   index: index
                              // });
                              onCommentImageClick(comment?.attachmentList);
                            }}
                            className="c-p"
                          />
                        </div>
                        <button
                          className="btns"
                          onClick={() => {
                            setDeleteAttachementTwo({
                              modal: true,
                              id: index,
                              comment,
                            });
                          }}
                        >
                          <BsTrash />
                        </button>
                      </div>
                    )}
                  </div>
                ))}

                {(comment?.attachmentList || []).map((i: any, index: any) => (
                  <div>
                    {!IMAGE_TYPES.includes(
                      //document only ye document he to eske aage image tag kyu aata he?
                      get_url_extensions(i?.attachmentPath) || "",
                    ) && (
                      <div className="attachment-img-container">
                        <div className="attachment-img">
                          <ToastDownload
                            Load={LoadToastId}
                            setLoad={setLoadToastId}
                            ref={btnRef}
                          />
                          <a
                            target="_blank"
                            // href={i.attachmentPath}
                            onClick={() => {
                              if (btnRef.current) {
                                setLoadToastId((prv: any) => [
                                  ...prv,
                                  `TickC${index}`,
                                ]);
                                btnRef?.current.downloadFiles(
                                  i.attachmentPath,
                                  `TickC${index}`,
                                );
                              }
                            }}
                            style={{
                              fontSize: "12px",
                              color: "lightgray",
                              textDecoration: "none",
                              // border: 'solid 1px #dfe7f3'
                            }}
                          >
                            <p style={{}} className="m-0">
                              {getFileName(i.attachmentPath)}
                            </p>
                          </a>{" "}
                        </div>
                        <button
                          className="btns"
                          onClick={() => {
                            onCommentAttachmemtDelete(comment, index);
                          }}
                        >
                          <BsTrash />
                        </button>
                      </div>
                    )}
                  </div>
                ))}

                <div className="mt-2">
                  {/* <Col md={2}> */}
                  <Button
                    className="text-capitalize"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setDelete(true);
                      setCommentId(comment.id);
                    }}
                  >
                    <FormattedMessage id="Delete" defaultMessage="Delete" />
                  </Button>
                  {/* </Col> */}
                  {/* <Col> */}
                  <Button
                    color="inherit"
                    className="ml-3 text-capitalize"
                    size="small"
                    onClick={() => {
                      commentAttachClick(comment);
                      //  attchmentClick();
                      // setCommentId(comment.id);
                      // setCommentAttachment(comment.attachmentList)
                    }}
                  >
                    <FormattedMessage id="Attach" defaultMessage="Attach" />
                  </Button>
                  {/* </Col> */}
                </div>
              </Col>
            </Row>
          </>
        ))}

      {/* {ticketComment && ticketComment !== null && (
        <div className="pag">
          <Pagination
            itemsPerPage={process.env.REACT_APP_RECORDS_PER_PAGE}
            totalPages={totalRecord}
            onPageChage={onPageChange}
            page={pageNumber - 1}
          />
        </div>
      )} */}
      {deletes && (
        <DeletePopup
          onClose={() => {
            setDelete(false);
            setCommentId(0);
          }}
          onDeleteClick={() => deleteComment()}
          open={deletes}
        />
      )}

      {deleteAttachementOne && (
        <DeletePopup
          onClose={() => {
            setDeleteAttachementOne({ modal: false, id: 0 });
          }}
          onDeleteClick={() => onDelete(deleteAttachementOne.id)}
          open={deleteAttachementOne.modal}
        />
      )}

      {deleteAttachementTwo && (
        <DeletePopup
          onClose={() => {
            setDeleteAttachementTwo({ modal: false, id: 0, comment: [] });
          }}
          onDeleteClick={() =>
            onCommentAttachmemtDelete(
              deleteAttachementTwo.comment,
              deleteAttachementTwo.id,
            )
          }
          open={deleteAttachementTwo.modal}
        />
      )}

      {console.log("img.index", img.index)}
      <Viewer
        visible={img.show}
        noToolbar={true}
        drag={false}
        disableMouseZoom={true}
        onClose={() => {
          setImg({ show: false, image: [], index: 0 });
        }}
        activeIndex={img.index}
        images={img.image}
        onChange={(activeImage: any, index: number) => {
          setImg((prv: any) => ({ ...prv, index: index }));
        }}
        // [{ src: img.image, alt: "Nor FOund" }]}
      />
    </div>
  );
};

export default TicketComment;
