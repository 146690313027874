/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/APIServices/baseApiCall";
import {
  GET_INDICATORS_LIST,
  GET_USER_VPS_MACHINE_ACCESS_LIST,
  GET_VPS_MACHINE_TYPE_LIST,
  EDIT_USER_VPS_MACHINE_ACCESS_LIST,
  GET_VPS_LIST,
  UPDATE_VPS_STATUS,
  VPS_RESET_PASSWORD,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { setToken } from "../../util/utils";
import {
  GET_INDICATORS_LIST_URL,
  GET_USER_VPS_MACHINE_ACCESS_LIST_URL,
  GET_VPS_MACHINE_TYPE_LIST_URL,
  GET_VPS_LIST_URL,
  UPDATE_VPS_STATUS_URL,
  VPS_RESET_PASSWORD_URL,
} from "../../service/APIServices/apiEndPoints";
import { toast } from "react-toastify";
// import { _getExpressWayPendingList } from "../../service";
// import { AxiosResponse } from "axios";
import { getAPIEndPoint } from "../../util/utils";

export const getVPSMachineTypeList =
  (showToast = false): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getVPSMachineTypeListInit(showToast));
  };

export const getVPSMachineTypeListInit =
  (showToast: boolean): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_VPS_MACHINE_TYPE_LIST.GET_VPS_MACHINE_TYPE_LIST_INITIALIZATION,
    });
    apiCall(
      GET_VPS_MACHINE_TYPE_LIST_URL,
      {},
      (res: Object) => dispatch(getVPSMachineTypeListSuccess(res)),
      (err: any) => dispatch(getVPSMachineTypeListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getVPSMachineTypeListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_VPS_MACHINE_TYPE_LIST.GET_VPS_MACHINE_TYPE_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getVPSMachineTypeListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_VPS_MACHINE_TYPE_LIST.GET_VPS_MACHINE_TYPE_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const getUserVpsMachineAccessList =
  (
    showToast = true,
    userId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getUserVpsMachineAccessListInit(showToast, userId));
  };

export const getUserVpsMachineAccessListInit =
  (
    showToast = true,
    userId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_USER_VPS_MACHINE_ACCESS_LIST.GET_USER_VPS_MACHINE_ACCESS_LIST_INITIALIZATION,
    });
    apiCall(
      GET_USER_VPS_MACHINE_ACCESS_LIST_URL + userId,
      {},
      (res: Object) => dispatch(getUserVpsMachineAccessListSuccess(res)),
      (err: any) => dispatch(getUserVpsMachineAccessListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getUserVpsMachineAccessListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_USER_VPS_MACHINE_ACCESS_LIST.GET_USER_VPS_MACHINE_ACCESS_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getUserVpsMachineAccessListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_USER_VPS_MACHINE_ACCESS_LIST.GET_USER_VPS_MACHINE_ACCESS_LIST_ERROR,
    });
  };

export const editUserVpsMachineAccessList =
  (
    showToast = true,
    data: any,
    userId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(editUserVpsMachineAccessListInit(showToast, data, userId));
  };

export const editUserVpsMachineAccessListInit =
  (
    showToast = true,
    data: any,
    userId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_USER_VPS_MACHINE_ACCESS_LIST.EDIT_USER_VPS_MACHINE_ACCESS_LIST_INITIALIZATION,
    });
    apiCall(
      GET_USER_VPS_MACHINE_ACCESS_LIST_URL + userId,
      data,
      (res: Object) => dispatch(editUserVpsMachineAccessListSuccess(res)),
      (err: any) => dispatch(editUserVpsMachineAccessListError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const editUserVpsMachineAccessListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_USER_VPS_MACHINE_ACCESS_LIST.EDIT_USER_VPS_MACHINE_ACCESS_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const editUserVpsMachineAccessListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: EDIT_USER_VPS_MACHINE_ACCESS_LIST.EDIT_USER_VPS_MACHINE_ACCESS_LIST_ERROR,
    });
  };

export const getVPSList =
  (
    showToast = false,
    userId: number,
    pageNumber: number,
    sort: any,
    pageLimit: any,
    includeLocal: boolean = true,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      getVPSListInit(
        showToast,
        userId,
        pageNumber,
        sort,
        pageLimit,
        includeLocal,
      ),
    );
  };

export const getVPSListInit =
  (
    showToast = false,
    userId: number,
    pageNumber: number,
    sort: any,
    pageLimit: any,
    includeLocal: boolean = true,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_VPS_LIST.GET_VPS_LIST_INITIALIZATION,
    });
    let vpsListUrl =
      GET_VPS_LIST_URL +
      `?sort=${sort.order}&sortBy=${sort.by}&includeLocal=${includeLocal}`;
    if (userId != 0) {
      vpsListUrl =
        GET_VPS_LIST_URL +
        `?userId=${userId}&sort=${sort.order}&sortBy=${sort.by}&includeLocal=${includeLocal}`;
    }
    apiCall(
      getAPIEndPoint(vpsListUrl, pageNumber, true, pageLimit),
      {},
      (res: Object) => dispatch(getVPSListSuccess(res)),
      (err: any) => dispatch(getVPSListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getVPSListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_VPS_LIST.GET_VPS_LIST_SUCCESS,
      payload: res,
    });
  };

export const getVPSListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_VPS_LIST.GET_VPS_LIST_ERROR,
    });
  };

// Update VPS Status
export const updateVpsStatus =
  (
    showToast = true,
    data: Object,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(updateVpsStatusInit(showToast, data));
  };

export const updateVpsStatusInit =
  (
    showToast = false,
    data: object,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: UPDATE_VPS_STATUS.UPDATE_VPS_STATUS_INITIALIZATION,
    });
    apiCall(
      UPDATE_VPS_STATUS_URL,
      data,
      (res: Object) => dispatch(updateVpsStatusSuccess(res)),
      (err: any) => dispatch(updateVpsStatusError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const updateVpsStatusSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: UPDATE_VPS_STATUS.UPDATE_VPS_STATUS_SUCCESS,
      payload: res,
    });
  };

export const updateVpsStatusError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: UPDATE_VPS_STATUS.UPDATE_VPS_STATUS_ERROR,
    });
  };

// Reset Password
export const vpsResetPassword =
  (
    showToast = true,
    instanceId: Object,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(vpsResetPasswordInit(showToast, instanceId));
  };
export const vpsResetPasswordInit =
  (
    showToast = false,
    instanceId: object,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: VPS_RESET_PASSWORD.VPS_RESET_PASSWORD_INITIALIZATION,
    });
    apiCall(
      VPS_RESET_PASSWORD_URL,
      instanceId,
      (res: Object) => dispatch(vpsResetPasswordSuccess(res)),
      (err: any) => dispatch(vpsResetPasswordError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const vpsResetPasswordSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: VPS_RESET_PASSWORD.VPS_RESET_PASSWORD_SUCCESS,
      payload: res,
    });
  };

export const vpsResetPasswordError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: UPDATE_VPS_STATUS.UPDATE_VPS_STATUS_ERROR,
    });
  };
