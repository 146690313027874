import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Col, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { examineStudentList, getAdminRolesList } from "../../redux/action";
import { Store } from "../../redux/Actions";
import { ApproveClasSchema } from "../../util/validationSchema";
import Select from "react-select";
import Loader from "../common/Loader";
import NoImage from "../../assets/img/noImage.jpeg";
import { USER_VIEW } from "../../util/constants/UserGroup";
import { FormattedMessage } from "react-intl";
import { Buffer } from "buffer";
import { getFileName, isPositiveNumbersOnly } from "../../util/utils";
import { FaTimesCircle } from "react-icons/fa";

const UserViewModal = ({ user, onClose, modal, type }: any) => {
  const dispatch = useDispatch();
  const studentDetail = useSelector(
    (state: Store) => state.studentReducer.studentDetail,
  ) as any;
  const loading = useSelector(
    (state: Store) => state.studentReducer.Loading.onSaveLoading,
  );
  const adminRoleList = useSelector(
    (state: Store) => state.adminRolesReducer.adminRoleList,
  ) as Array<Object>;
  const [ButtonClickRj, setButtonClickRj] = useState(false);
  const [ButtonClickAp, setButtonClickAp] = useState(false);
  const [types, setType] = useState("");
  const [userType, setUserType] = useState<any>();
  const [open, setOpen] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: Object.keys(studentDetail || []).length > 0 ? user.student_id : "",
      price:
        Object.keys(studentDetail || []).length > 0 &&
        studentDetail?.payment !== null
          ? studentDetail?.payment?.price || 0
          : 0,
      type:
        Object.keys(studentDetail || []).length > 0
          ? studentDetail?.role?.id
          : 0,
      remark:
        Object.keys(studentDetail || []).length > 0
          ? studentDetail?.payment?.remark || "--"
          : "",
    },
    validationSchema: ApproveClasSchema,
    onSubmit: async (values) => {
      let t = "";
      if (USER_VIEW.PENDING === type) {
        t = "pending";
      } else if (USER_VIEW.APPROVE === type) {
        t = "approved";
      } else if (USER_VIEW.REJECTED === type) {
        t = "rejected";
      }

      if (types === "rejected") {
        dispatch(
          examineStudentList(
            true,
            { id: user.student_id, remark: values.remark },
            "reject",
            t,
          ),
        );
        setButtonClickRj(true);
      } else {
        // delete values.price;
        dispatch(
          examineStudentList(
            true,
            {
              ...values,
              price: values.price,
            },
            "approve",
            t,
          ),
        );
        setButtonClickAp(true);
      }
    },
  });

  useEffect(() => {
    if (studentDetail.role) {
      dispatch(getAdminRolesList(false));
      setUserType(studentDetail.role);
    }
  }, [studentDetail]);

  useEffect(() => {
    dispatch(getAdminRolesList(false));
  }, []);

  useEffect(() => {
    if (!loading && (ButtonClickRj || ButtonClickAp)) {
      onClose();
      setButtonClickRj(false);
      setButtonClickAp(false);
    }
  }, [loading]);

  const { errors, touched } = formik;

  const toBase64 = (arr: any) => {
    const a = btoa(
      arr.reduce(
        (data: any, byte: any) => data + String.fromCharCode(byte),
        "",
      ),
    );
    return atob(a);
  };

  const rejectStudent = () => {
    let t = "";
    if (USER_VIEW.PENDING === type) {
      t = "pending";
    } else if (USER_VIEW.APPROVE === type) {
      t = "approved";
    } else if (USER_VIEW.REJECTED === type) {
      t = "rejected";
    }
    dispatch(
      examineStudentList(
        true,
        { id: user.student_id, remark: formik.values.remark },
        "reject",
        t,
      ),
    );
    setButtonClickRj(true);
  };

  const encode = (data: any) => {
    const buf = Buffer.from(data);
    const base64 = buf.toString("base64");
    toBase64(data);
    return base64;
  };

  return (
    <Modal centered size={"lg"} show={modal} onHide={() => onClose()}>
      <Modal.Header>
        <h4 className="modal-title">
          <FormattedMessage
            id="head.ViewUserSlip"
            defaultMessage="View User Slip "
          />
        </h4>
        <button className="close" onClick={() => onClose()}>
          <FaTimesCircle />
        </button>
      </Modal.Header>
      <Modal.Body className={`${open && "scroll-select"}`}>
        {studentDetail && Object.keys(studentDetail).length > 0 ? (
          <>
            <div>
              <label>
                <FormattedMessage
                  id="head.PaymentSlip"
                  defaultMessage="Payment Slip"
                />
              </label>{" "}
              <br />
              {studentDetail?.payment &&
              studentDetail?.payment?.slip.length > 0 ? (
                <>
                  {studentDetail?.payment?.slip.map((i: any) => (
                    <>
                      {i.slipPath ? (
                        <img
                          className="mr-2"
                          style={{ height: "200px", width: "200px" }}
                          src={i.slipPath}
                        />
                      ) : (
                        <>
                          <>
                            {i?.slipBuffer?.data &&
                            i?.slipBuffer?.data.length > 0 ? (
                              <img
                                className="mr-2"
                                style={{ height: "200px", width: "200px" }}
                                src={`data:image/png;base64,${encode(
                                  i?.slipBuffer?.data,
                                )}`}
                              />
                            ) : (
                              <img
                                src={NoImage}
                                style={{
                                  height: "30%",
                                  width: "20%",
                                  opacity: ".5",
                                }}
                              />
                            )}
                          </>
                        </>
                      )}
                    </>
                  ))}
                </>
              ) : (
                <img
                  src={NoImage}
                  style={{ height: "30%", width: "20%", opacity: ".5" }}
                />
              )}
              {studentDetail && Object.keys(studentDetail).length > 0 ? (
                <>
                  <Row>
                    <Col>
                      <label>
                        <FormattedMessage
                          id="head.OriginalSignature"
                          defaultMessage="Original Signature"
                        />
                      </label>
                      <br />
                      {/* {studentDetail?.users &&
                        studentDetail?.users?.signature?.data ? (
                        <img
                          // src={
                          //   "data:image/jpg;base64," +
                          //   encode(studentDetail.users.signature.data)
                          // }
                          src={
                            studentDetail.users.signaturePath
                          }
                        />
                      ) : (
                        <img
                          src={NoImage}
                          style={{ height: "70%", width: "40%", opacity: ".5" }}
                        />
                      )} */}

                      {studentDetail?.users.signaturePath ? (
                        <img src={studentDetail.users.signaturePath} />
                      ) : (
                        <img
                          src={NoImage}
                          style={{ height: "70%", width: "40%", opacity: ".5" }}
                        />
                      )}
                    </Col>
                    <Col>
                      <label>
                        <FormattedMessage
                          id="head.RegisterSignature"
                          defaultMessage="Register Signature"
                        />
                      </label>
                      <br />
                      {/* {studentDetail?.signature &&
                      studentDetail?.signature?.data ? (
                        <img src={studentDetail.signaturePath} />
                      ) : (
                        <img
                          src={NoImage}
                          style={{ height: "70%", width: "40%", opacity: ".5" }}
                        />
                      )} */}
                      {studentDetail?.signaturePath ? (
                        <img src={studentDetail.signaturePath} />
                      ) : (
                        <img
                          src={NoImage}
                          style={{ height: "70%", width: "40%", opacity: ".5" }}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>
                        <FormattedMessage
                          id="head.Type"
                          defaultMessage="Type"
                        />
                        :{" "}
                      </label>
                      {studentDetail?.payment?.paymentType ? (
                        <label>
                          {" "}
                          {" " +
                            studentDetail?.payment?.paymentType
                              ?.paymentTypeDetails?.[0]?.name || "-"}
                        </label>
                      ) : (
                        <label>BIS</label>
                      )}
                    </Col>
                    <Col>
                      <label>
                        <FormattedMessage
                          id="head.Remark"
                          defaultMessage="Remark"
                        />
                        :{" "}
                      </label>
                      {type === USER_VIEW.PENDING ? (
                        <input
                          type="text"
                          name="remark"
                          value={formik.values.remark}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="form-control"
                        />
                      ) : (
                        <label>{formik.values.remark}</label>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>
                        <FormattedMessage
                          id="head.Price"
                          defaultMessage="Price"
                        />
                      </label>
                      <input
                        type="number"
                        name="price"
                        disabled={USER_VIEW.APPROVE === type && true}
                        // onChange={formik.handleChange}
                        onChange={(e: any) => {
                          if (!isPositiveNumbersOnly(e, e.target.value)) {
                            return false;
                          }
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        className="form-control"
                        value={formik.values.price}
                        min={0}
                      />
                      {errors.price && touched.price && (
                        <div className="text-error">
                          <FormattedMessage
                            id={errors.price.toString()}
                            defaultMessage="require"
                          />
                        </div>
                      )}
                    </Col>
                    <Col>
                      <label>
                        <FormattedMessage
                          id="head.StudentType"
                          defaultMessage="Type"
                        />
                      </label>
                      <Select
                        options={adminRoleList}
                        value={userType}
                        onChange={(option: any) => {
                          formik.setFieldValue("type", option.id);
                          setUserType(option);
                        }}
                        onMenuOpen={() => setOpen(true)}
                        onMenuClose={() => setOpen(false)}
                        onBlur={() => formik.setFieldTouched("type", true)}
                        getOptionLabel={(option: any) => option.roleName}
                        getOptionValue={(option: any) => option.id}
                        className="custom-select-dropdown"
                        classNamePrefix="react-select-dropdown"
                        menuPlacement="top"
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                      {errors.type && touched.type && (
                        <div className="text-error">
                          {errors.type.toString()}
                        </div>
                      )}
                    </Col>
                  </Row>
                  {userType?.id == 2 && (
                    <Row className="mt-3">
                      <Col md={4} lg={3} sm={6}>
                        <FormattedMessage
                          id="table.head.terms_condtion_path"
                          defaultMessage="Terms & Conditions"
                        />
                        :{" "}
                      </Col>
                      <Col>
                        {studentDetail?.class?.terms_condtion_path ? (
                          <div
                            className="details-td text-primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              window.open(
                                studentDetail?.class?.terms_condtion_path,
                                "_blank",
                              );
                            }}
                          >
                            {getFileName(
                              studentDetail?.class?.terms_condtion_path,
                            )}
                          </div>
                        ) : (
                          "-"
                        )}
                      </Col>
                    </Row>
                  )}
                </>
              ) : null}
            </div>
          </>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        {studentDetail?.users?.status === "pending" ||
        ButtonClickAp ||
        ButtonClickRj ? (
          <>
            {type === USER_VIEW.PENDING && (
              <>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onClose}
                >
                  <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                  disabled
                  color="success"
                >
                  <>
                    <FormattedMessage
                      id="page.Approve"
                      defaultMessage="APPROVE"
                    />
                    {loading && ButtonClickAp && <Loader />}
                  </>
                </button>
                <button type="button" className="btn btn-danger" disabled>
                  <FormattedMessage id="page.Reject" defaultMessage="REJECT" />{" "}
                  <>{loading && ButtonClickRj && <Loader />}</>
                </button>
              </>
            )}
            {type === USER_VIEW.REJECTED && (
              <>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onClose}
                >
                  <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
                </button>
                <button type="button" className="btn btn-success" disabled>
                  <FormattedMessage
                    id="page.Approve"
                    defaultMessage="APPROVE"
                  />
                  {loading && ButtonClickAp && <Loader />}
                </button>
              </>
            )}
            {type === USER_VIEW.APPROVE && (
              <>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onClose}
                >
                  <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
                </button>
                <button type="button" className="btn btn-danger" disabled>
                  <FormattedMessage id="page.Reject" defaultMessage="REJECT" />
                  {loading && ButtonClickRj && <Loader />}
                </button>
              </>
            )}
          </>
        ) : (
          <>
            {type === USER_VIEW.PENDING && (
              <>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onClose}
                >
                  <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    setType("approved");
                    formik.handleSubmit();
                  }}
                  color="success"
                >
                  <>
                    <FormattedMessage
                      id="page.Approve"
                      defaultMessage="APPROVE"
                    />
                    {loading && ButtonClickAp && <Loader />}
                  </>
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    setType("rejected");
                    rejectStudent();
                  }}
                >
                  <FormattedMessage id="page.Reject" defaultMessage="REJECT" />{" "}
                  <>{loading && ButtonClickRj && <Loader />}</>
                </button>
              </>
            )}
            {type === USER_VIEW.REJECTED && (
              <>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onClose}
                >
                  <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    setType("approved");
                    formik.handleSubmit();
                  }}
                >
                  <FormattedMessage
                    id="page.Approve"
                    defaultMessage="APPROVE"
                  />
                  {loading && ButtonClickAp && <Loader />}
                </button>
              </>
            )}
            {type === USER_VIEW.APPROVE && (
              <>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onClose}
                >
                  <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    setType("rejected");
                    rejectStudent();
                  }}
                >
                  <FormattedMessage id="page.Reject" defaultMessage="REJECT" />
                  {loading && ButtonClickRj && <Loader />}
                </button>
              </>
            )}{" "}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default UserViewModal;
