import React, { useEffect, useState } from "react";
import { useFormik } from "formik";

import { useSelector } from "react-redux";
import {
  addGiftCategoryList,
  editGiftCategoryList,
  getAdminRolesList,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import Loader from "../common/Loader";
import { _UploadMedia } from "../../service/MediaUpload/classes.service";
import { dispatch } from "../../redux";
// import { useParams } from "react-router";
import { Switch } from "@mui/material";
import FileUploadImage from "../common/MediaUpload/FileUploadImage";
import { Modal } from "react-bootstrap";
import { EditGiftCategorySchema } from "../../util/validationSchema";
import { FormattedMessage } from "react-intl";
import CenterLoader from "../common/CenterLoader/CenterLoader";
import { BsTrashFill } from "react-icons/bs";
import { FaTimesCircle } from "react-icons/fa";
import { toast } from "react-toastify";

const EditGiftCategoryModal = ({ onModalClose, gift }: any) => {
  const [ButtonClick, setButtonClick] = useState(false);
  const isAPILoading = useSelector(
    (state: Store) => state.giftReducer.isLoading,
  );
  const [fileUploadBeforeURL, setFileUploadBeforeURL] = useState<any>(
    gift?.beforeImage || "",
  );
  const [fileUploadAfterURL, setFileUploadAfterURL] = useState<any>(
    gift?.afterImage || "",
  );
  const [fileUploadExpiredURL, setFileUploadExpiredURL] = useState<any>(
    gift?.expiredImage || "",
  );

  const [fileLoading, setFileLoading] = useState(false);

  // const adminRoleList = useSelector(
  //   (state: Store) => state.adminRolesReducer.adminRoleList
  // ) as Array<Object>;
  // const [selectedRoles, setselectedRoles] = useState({});
  // const urlParams = useParams();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: gift && Object.keys(gift).length !== 0 ? gift.name : "",
      show: gift && Object.keys(gift).length !== 0 ? gift.show : false,
      beforeImage: gift?.beforeImage || "",
      afterImage: gift?.afterImage || "",
      expiredImage: gift?.expiredImage || "",
    },
    validationSchema: EditGiftCategorySchema,
    onSubmit: async (values) => {
      if (!ButtonClick) {
        if (Object.keys(gift).length <= 0) {
          dispatch(addGiftCategoryList(true, values));
        } else {
          dispatch(editGiftCategoryList(true, values, gift.id));
        }
        setButtonClick(true);
      }
    },
  });

  useEffect(() => {
    if (!isAPILoading && ButtonClick) {
      onModalClose();
      setButtonClick(false);
    }
  }, [isAPILoading]);

  useEffect(() => {
    dispatch(getAdminRolesList(false));
  }, []);

  const handleDrop = async (acceptedFiles: any) => {
    if (acceptedFiles[0].name) {
      const ImageTypes = ["png", "jpg", "jpeg", "bmp", "gif"];
      const extention = acceptedFiles[0].name.split(".");
      if (
        extention.length > 0 &&
        ImageTypes.includes(
          extention[extention.length - 1]?.toLocaleLowerCase(),
        )
      ) {
        setFileLoading(true);
        const { fileUploadResponse, error }: any = await _UploadMedia(
          acceptedFiles[0] as File,
        );
        if (!error) {
          setFileUploadBeforeURL(fileUploadResponse);
          formik.setFieldValue("beforeImage", fileUploadResponse);
        }
        setFileLoading(false);
      } else {
        setFileLoading(false);
        toast.error("Only image files are allowed");
      }
    }
  };

  const onDeleteImage = async () => {
    formik.setFieldValue("beforeImage", "");
    setFileUploadBeforeURL(undefined);
  };

  const handleDropAfter = async (acceptedFiles: any) => {
    if (acceptedFiles[0].name) {
      const ImageTypes = ["png", "jpg", "jpeg", "bmp", "gif"];
      const extention = acceptedFiles[0].name.split(".");
      if (
        extention.length > 0 &&
        ImageTypes.includes(
          extention[extention.length - 1]?.toLocaleLowerCase(),
        )
      ) {
        setFileLoading(true);

        const { fileUploadResponse, error }: any = await _UploadMedia(
          acceptedFiles[0] as File,
        );
        if (!error) {
          formik.setFieldValue("afterImage", fileUploadResponse);
          setFileUploadAfterURL(fileUploadResponse);
        }
        setFileLoading(false);
      } else {
        setFileLoading(false);
        toast.error("Only image files are allowed");
      }
    }
  };

  const onDeleteImageAfter = async () => {
    formik.setFieldValue("afterImage", "");
    setFileUploadAfterURL(undefined);
  };

  const handleDropExpired = async (acceptedFiles: any) => {
    if (acceptedFiles[0].name) {
      const ImageTypes = ["png", "jpg", "jpeg", "bmp", "gif"];
      const extention = acceptedFiles[0].name.split(".");
      if (
        extention.length > 0 &&
        ImageTypes.includes(
          extention[extention.length - 1]?.toLocaleLowerCase(),
        )
      ) {
        setFileLoading(true);

        const { fileUploadResponse, error }: any = await _UploadMedia(
          acceptedFiles[0] as File,
        );
        if (!error) {
          formik.setFieldValue("expiredImage", fileUploadResponse);
          setFileUploadExpiredURL(fileUploadResponse);
        }
        setFileLoading(false);
      } else {
        setFileLoading(false);
        toast.error("Only image files are allowed");
      }
    }
  };

  const onDeleteImageExpired = async () => {
    formik.setFieldValue("expiredImage", "");
    setFileUploadExpiredURL(undefined);
  };

  const { touched, errors } = formik;
  return (
    <>
      {fileLoading && <CenterLoader />}
      <Modal centered show={true} onHide={() => onModalClose(false)}>
        <Modal.Header>
          <h4 className="modal-title" id="AddCourseLabel">
            <FormattedMessage
              id="head.EditGiftCategory"
              defaultMessage="Edit Gift Category"
            />
          </h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onModalClose}
          >
            <FaTimesCircle />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="control-label">
              <FormattedMessage id="table.head.Name" defaultMessage="Name" />
            </label>
            <input
              type="text"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control"
            />
            {errors.name && touched.name && (
              <div className="text-error">
                <FormattedMessage
                  id={errors.name.toString()}
                  defaultMessage="This field is required"
                />
              </div>
            )}
          </div>

          <div className="form-group">
            <label>
              <FormattedMessage
                id="head.BeforeFile"
                defaultMessage="Before file"
              />{" "}
            </label>
            {!fileUploadBeforeURL && (
              <>
                <FileUploadImage handleDrop={handleDrop} />
              </>
            )}

            {fileUploadBeforeURL && (
              <div style={{ textAlign: "center" }}>
                {" "}
                <img
                  style={{
                    textAlign: "center",
                    border: "2px lightgrey solid",
                    height: "200px",
                    width: "250px",
                    borderRadius: "1%",
                  }}
                  src={fileUploadBeforeURL}
                  width={200}
                  alt="hsjdhj"
                />
                <button
                  style={{ backgroundColor: "transparent", border: "none" }}
                  onClick={() => onDeleteImage()}
                >
                  <i className="text-danger">
                    <BsTrashFill />{" "}
                  </i>
                </button>
              </div>
            )}
            {/* {errors.beforeImage && touched.beforeImage && (<div className="text-error"  >
                        <FormattedMessage id={errors.beforeImage.toString()} defaultMessage="This field is required" /></div>)} */}
          </div>

          <div className="form-group">
            <label>
              <FormattedMessage
                id="head.AfterFile"
                defaultMessage="After file"
              />{" "}
            </label>
            {!fileUploadAfterURL && (
              <>
                <FileUploadImage handleDrop={handleDropAfter} />
              </>
            )}

            {fileUploadAfterURL && (
              <div style={{ textAlign: "center" }}>
                {" "}
                <img
                  style={{
                    textAlign: "center",
                    border: "2px lightgrey solid",
                    height: "200px",
                    width: "250px",
                    borderRadius: "1%",
                  }}
                  src={fileUploadAfterURL}
                  width={200}
                  alt="hsjdhj"
                />
                <button
                  style={{ backgroundColor: "transparent", border: "none" }}
                  onClick={() => onDeleteImageAfter()}
                >
                  <i className="text-danger">
                    <BsTrashFill />{" "}
                  </i>
                </button>
              </div>
            )}
            {/* {errors.afterImage && touched.afterImage && (<div className="text-error"  >
                        <FormattedMessage id={errors.afterImage.toString()} defaultMessage="This field is required" /> </div>)} */}
          </div>

          <div className="form-group">
            <label>
              <FormattedMessage
                id="head.ExpiredFile"
                defaultMessage="Expired Gift file"
              />{" "}
            </label>
            {!fileUploadExpiredURL && (
              <>
                <FileUploadImage handleDrop={handleDropExpired} />
              </>
            )}

            {fileUploadExpiredURL && (
              <div style={{ textAlign: "center" }}>
                {" "}
                <img
                  style={{
                    textAlign: "center",
                    border: "2px lightgrey solid",
                    height: "200px",
                    width: "250px",
                    borderRadius: "1%",
                  }}
                  src={fileUploadExpiredURL}
                  width={200}
                  alt="hsjdhj"
                />
                <button
                  style={{ backgroundColor: "transparent", border: "none" }}
                  onClick={() => onDeleteImageExpired()}
                >
                  <i className="text-danger">
                    <BsTrashFill />{" "}
                  </i>
                </button>
              </div>
            )}
            {/* {errors.afterImage && touched.afterImage && (<div className="text-error"  >
                        <FormattedMessage id={errors.afterImage.toString()} defaultMessage="This field is required" /> </div>)} */}
          </div>

          <div className="form-group">
            <FormattedMessage id="page.Show" defaultMessage="Show" />{" "}
            <Switch
              checked={formik.values.show}
              onChange={() => {
                formik.setFieldValue("show", !formik.values.show);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={onModalClose}
          >
            <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
          </button>
          <button
            type="button"
            className="btn btn-primary"
            style={{ display: "inherit" }}
            onClick={() => formik.handleSubmit()}
            data-dismiss="modal"
          >
            <FormattedMessage id="page.submit" defaultMessage="Submit" />
            {isAPILoading && <Loader />}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditGiftCategoryModal;
