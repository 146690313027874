import { useEffect } from "react";
import { useLocation, useParams } from "react-router";
import { getCurrentDatetimeWithTimezone } from "../../util/utils";
import TopLogo from "../../assets/img/print-top-logo.png";
import StampLogo from "../../assets/img/stamp-logo.png";
import { getUserPrintDetail } from "../../redux/action";
import { useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import { dispatch } from "../../redux";
import secureLocalStorage from "react-secure-storage";

const PrintRepeaterInfo = () => {
  const myInfo: any = useSelector(
    (state: Store) => state.userDataReducer.userPrintDetail.userData,
  );
  const previousClass: any = useSelector(
    (state: Store) => state.userDataReducer.userPrintDetail.previousClass,
  );
  const authToken: any =
    secureLocalStorage.getItem("authToken")?.toString() || "";
  const urlParams: any = useParams();
  const location = useLocation();

  const a: any = new URLSearchParams(location.search);

  useEffect(() => {
    const id: string = urlParams.id?.toString();
    const classId = a.get("class");
    dispatch(getUserPrintDetail(id, classId, "Repeater", authToken));
  }, []);

  const getClassName = (arr: any) => {
    let ar: any = "";
    if (arr.length != undefined && arr.length > 0) {
      arr.map((i: any) => {
        ar += `,  ${i.class_name}`;
      });
      ar = ar.replace(",", "");
      return ar;
    } else {
      return "";
    }
  };
  return (
    <div className="" style={{ background: "white" }}>
      {myInfo && Object.keys(myInfo).length > 0 ? (
        <div className="print-container">
          <table width="100%">
            <tr>
              <td>
                <img src={TopLogo} style={{ maxWidth: "25%" }} alt="Logo" />
              </td>
            </tr>
            <tr>
              <td>
                <p style={{ textAlign: "center" }}>複讀生須知</p>
                <table width="100%">
                  <tr>
                    <td style={{ width: "49%" }} className="align-top">
                      <table width="100%" className="course-applied">
                        <tr>
                          <td style={{ width: "50%" }}>
                            課程名稱
                            <br />
                            Programme Title
                          </td>
                          <td style={{ width: "50%" }}>
                            <small>
                              Forex Forest Algorithmic Trading Course
                            </small>
                            <br /> <b> Course No.: {myInfo?.class_name}</b>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>#ID</td>
                          <td style={{ width: "50%" }}>{myInfo.id}</td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>Previous Class</td>
                          <td style={{ width: "50%" }}>
                            &nbsp;
                            {getClassName(previousClass)}
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td style={{ width: "2%" }}></td>
                    <td style={{ width: "48%" }} className="align-top">
                      <table width="100%" className="course-applied">
                        <tr>
                          <td>
                            由本公司填寫
                            <br />
                            For Forex Forest Limited use only
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <small>
                              <span className="check-line-box"></span> 獲批
                              Admitted
                            </small>{" "}
                            &nbsp; &nbsp;{" "}
                            <small>
                              <span className="check-line-box"></span> 拒絕
                              Rejected
                            </small>
                          </td>
                        </tr>
                        <tr>
                          <td>拒絕原因：____________________</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td>
                <p style={{ marginTop: "30px" }}>
                  複讀生是新一屆同學的前輩，助人者自助，為鼓勵朋輩在課堂之間的互動及互助，以提升自己及後輩的學習質素。如閣下符合基本入學條件並決定成為複讀生，必須完成下列事項：
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <ol style={{ marginTop: "10px" }}>
                  <li>出席開學第一、二、三、四堂及八字投資班。</li>
                  <li>餘下課堂只能缺席一次，並須預先向客服請假。</li>
                  <li>每4堂前要提交10個有效Demo Signals。</li>
                  <li>每個課堂若遲到十五分鐘或上以則作缺席論。</li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>
                閣下須在課程開始前繳交按金港幣貮仟元正，當完成課程及以上事項，按金將會全數退還，否則其按金將捐予慈善團體，並停止下屆複課申請，須相隔一屆才再接受申請。
                <br />
                <br />
                不便之處，敬請原諒。
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>
                <p style={{ padding: "15px 0" }}>
                  <strong>聲明</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>本人明白並同意以上安排並願意接受有關條款所約束。</td>
            </tr>
            <tr>
              <td>請以正楷填寫以下資料﹙所填寫資料必須與Gems系統內相同﹚</td>
            </tr>
            <tr>
              <td>
                <table width="100%">
                  <tr>
                    <td>
                      <table width="100%" className="line-table">
                        <tr>
                          <td style={{ width: "50%" }}>
                            中文姓名 <br /> Name in Chinese
                          </td>
                          <td style={{ width: "50%", verticalAlign: "bottom" }}>
                            <p className="name-line">
                              {myInfo?.users_chineseLastName +
                                " " +
                                myInfo?.users_chineseFirstName}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>
                            英文姓名 <br /> Name in English
                          </td>
                          <td style={{ width: "50%", verticalAlign: "bottom" }}>
                            <p className="name-line">
                              {myInfo?.users_englishFirstName +
                                " " +
                                myInfo?.users_englishLastName}
                            </p>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td style={{ width: "20%" }}></td>
                    <td>
                      <table width="100%" className="line-table">
                        <tr>
                          <td style={{ width: "50%" }}>
                            流動電話 <br /> Mobile Phone No.
                          </td>
                          <td style={{ width: "50%", verticalAlign: "bottom" }}>
                            <p className="name-line">
                              {/* {myInfo?.users_phoneNumber}{" "} */}+
                              {myInfo?.users_dialCode || ""}{" "}
                              {myInfo.users_phoneNumber}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>
                            會員編號 <br />
                            Member ID
                          </td>
                          <td style={{ width: "50%", verticalAlign: "bottom" }}>
                            <p className="name-line">{myInfo?.users_id}</p>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table width="100%">
                  <tr>
                    <td
                      style={{
                        width: "40%",
                        height: "200px",
                        verticalAlign: "bottom",
                      }}
                    >
                      {myInfo.student_signaturePath ||
                      myInfo.users_signaturePath ? (
                        <div>
                          <img
                            src={
                              myInfo.student_signaturePath ||
                              myInfo.users_signaturePath
                            }
                            alt="signature"
                            style={{
                              height: "150px",
                            }}
                          />
                        </div>
                      ) : null}
                      <p className="name-line"></p>
                      <p>
                        姓名：
                        {myInfo?.users_englishFirstName +
                          " " +
                          myInfo?.users_englishLastName}
                        <span style={{ float: "right" }}>
                          日期：{getCurrentDatetimeWithTimezone()}
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "20%" }}></td>
                    <td
                      style={{
                        width: "40%",
                        height: "200px",
                        verticalAlign: "bottom",
                      }}
                    >
                      <img
                        src={StampLogo}
                        style={{ maxWidth: "25%" }}
                        alt="Logo"
                      />
                      <p className="name-line"></p>
                      <p>
                        公司：Forex Forest Limited
                        <span style={{ float: "right" }}>
                          日期：{getCurrentDatetimeWithTimezone()}
                        </span>
                      </p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                注意:
                此課程及工具的所有內容及資料只供模擬測試及學述交流之用，並不構成任何與投資有關之邀約、招攬、邀請、誘使、申述、建議或推薦。投資涉及風險，投資者應就本身投資經驗、投資目標、財政資源、風險承擔能力及其他相關條件，小心衡量自己是否適合參與任何投資項目。
              </td>
            </tr>
          </table>
        </div>
      ) : null}
    </div>
  );
};

export default PrintRepeaterInfo;
