import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import NoDatFound from "../../Components/common/NoDatFound";
import DeletePopup from "../../Components/common/Popups/DeletePopup";
import AddLibraryModal from "../../Components/Library/AddLibraryModal";
import {
  deleteLibrary,
  getAdminRolesList,
  getlibraryList,
  setCurrentLibPage,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
// import { VerticalDots } from "../../util/iconStyle";
import { FormattedMessage } from "react-intl";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import Pagination from "../../Components/common/Paginate/Pagination";
import { Dropdown } from "react-bootstrap";
import { AppendedMyComponent } from "../../Components/append-to-body-drop-down";
// import Lib from "../../assets/img/Library.svg";
import { FaEdit, FaTrash } from "../../reactIcons";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

const Library = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [libraryModalShow, setLibraryModalShow] = useState(false);
  const [libraryDelModalShow, setLibraryDelModalShow] = useState({
    modal: false,
    id: 0,
  });
  const [edtLibrary, setEdtLibrary] = useState(null);

  const librarysList: any = useSelector(
    (state: Store) => state.libraryReducer.libraryList,
  ) as any;
  const roleList: any = useSelector(
    (state: Store) => state.adminRolesReducer.adminRoleList,
  ) as any;
  const isLoading = useSelector(
    (state: Store) => state.libraryReducer.isLoading,
  );
  const [pageNumber, setPageNumber] = useState<any>();
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const totalRecord = useSelector(
    (state: Store) => state.libraryReducer.totalRecord,
  ) as any;
  const currentPage = useSelector(
    (state: Store) => state.libraryReducer.currentPage,
  ) as any;

  useEffect(() => {
    dispatch(setCurrentLibPage(1));
  }, []);

  const onPageChange = (pageNum: number) => {
    localStorage.setItem("pageNumber", pageNum.toString());
    localStorage.setItem("pageLimit", pageLimit.toString());
    setPageNumber(pageNum);
    dispatch(setCurrentLibPage(pageNum));
  };

  // useEffect(() => {
  //   setPageNumber(currentPage);
  // }, [currentPage]);

  useEffect(() => {
    if (pageNumber && pageLimit) {
      dispatch(getlibraryList(false, pageNumber, pageLimit));
    }
  }, [pageNumber, pageLimit]);

  useEffect(() => {
    dispatch(getAdminRolesList(false));
    const storedPageNumber = localStorage.getItem("pageNumber");
    const storedPageLimit = localStorage.getItem("pageLimit");
    if (storedPageNumber && storedPageLimit) {
      setPageNumber(storedPageNumber);
      setPageLimit(storedPageLimit);
    } else {
      setPageNumber(1);
      setPageLimit(10);
    }
  }, []);

  const editLibrarySelected = (libraryDetail: any) => {
    setEdtLibrary(libraryDetail);
    setLibraryModalShow(true);
  };

  const viewLibraryDetail = (id: any) => {
    localStorage.setItem("pageNumber", pageNumber);
    localStorage.setItem("pageLimit", pageLimit);
    navigate("/library/" + id);
  };
  const deleteInd = () => {
    dispatch(deleteLibrary(true, libraryDelModalShow.id));
    setLibraryDelModalShow({ modal: false, id: 0 });
  };

  return (
    <>
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                {" "}
                <FormattedMessage
                  id="Admin.Library"
                  defaultMessage="Library "
                />
              </h1>
            </div>

            <div className="m-l-10 common-right-button">
              <a
                href="#"
                data-toggle="modal"
                data-target="#AddCourse"
                type="button"
                onClick={() => {
                  setLibraryModalShow(true);
                  setEdtLibrary(null);
                }}
                className="btn btn-primary"
              >
                <FormattedMessage
                  id="head.Library.add"
                  defaultMessage="Add Library "
                />
              </a>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          <div className="card">
            {isLoading && <CenterLoader />}
            <div className="card-body p-0">
              <div className="table-responsive">
                {librarysList && librarysList.length ? (
                  <>
                    {" "}
                    <table className="table table-hover m-0">
                      <thead>
                        <tr>
                          <th>
                            <FormattedMessage
                              id="table.head.ID"
                              defaultMessage="ID"
                            />
                          </th>
                          <th>
                            <FormattedMessage
                              id="table.head.Name"
                              defaultMessage="Name"
                            />
                          </th>
                          <th>
                            <FormattedMessage
                              id="table.head.info"
                              defaultMessage="Info"
                            />
                          </th>
                          <th className="table-field-status">
                            <FormattedMessage
                              id="table.head.Actions"
                              defaultMessage="Action"
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(librarysList || {}).length &&
                          librarysList.map((i: any) => (
                            <tr>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() => viewLibraryDetail(i.id)}
                              >
                                {i.id}
                              </td>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() => viewLibraryDetail(i.id)}
                              >
                                {i.name}
                              </td>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() => viewLibraryDetail(i.id)}
                              >
                                {i.info}
                              </td>
                              <td className="table-field-status">
                                <Dropdown className="btn-group">
                                  <Dropdown.Toggle
                                    id="dropdown-basic"
                                    className="btn btn-sm btn-icon-only"
                                  >
                                    <i className="icon dripicons-dots-3 zmdi-hc-fw"></i>
                                  </Dropdown.Toggle>
                                  <AppendedMyComponent>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        href="#"
                                        onClick={() => editLibrarySelected(i)}
                                      >
                                        <i className="text-accent-custom">
                                          <FaEdit />{" "}
                                        </i>
                                        <FormattedMessage
                                          id="page.Edit"
                                          defaultMessage="Edit"
                                        />
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        href="#"
                                        onClick={() =>
                                          setLibraryDelModalShow({
                                            modal: true,
                                            id: i.id,
                                          })
                                        }
                                      >
                                        <i className="fa-fw text-accent-custom">
                                          <FaTrash />{" "}
                                        </i>
                                        <FormattedMessage
                                          id="page.Delete"
                                          defaultMessage="Delete"
                                        />
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </AppendedMyComponent>
                                </Dropdown>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <p>{!isLoading && <NoDatFound />}</p>
                )}
              </div>
              {totalRecord > 10 && librarysList.length && (
                <>
                  <FormControl className="pagelimit">
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      <FormattedMessage
                        id="page_size"
                        defaultMessage="Page Size"
                      />
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={pageLimit}
                      onChange={(e: any) => {
                        setPageLimit(e.target.value);
                        localStorage.setItem("pageLimit", e.target.value);
                        localStorage.setItem("pageNumber", pageNumber);
                        setPageNumber(1);
                      }}
                    >
                      <FormControlLabel
                        value={10}
                        control={<Radio />}
                        label="10"
                      />
                      <FormControlLabel
                        value={20}
                        control={<Radio />}
                        label="20"
                      />
                      <FormControlLabel
                        value={50}
                        control={<Radio />}
                        label="50"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Pagination
                    itemsPerPage={pageLimit}
                    totalPages={totalRecord}
                    onPageChage={onPageChange}
                    page={pageNumber - 1}
                  />
                </>
              )}
            </div>
          </div>
        </section>
      </div>
      {libraryDelModalShow.modal && (
        <DeletePopup
          onClose={() => setLibraryDelModalShow({ modal: false, id: 0 })}
          onDeleteClick={deleteInd}
          open={libraryDelModalShow.modal}
        />
      )}
      {libraryModalShow && (
        <AddLibraryModal
          onModalClose={() => setLibraryModalShow(false)}
          editLibrary={edtLibrary}
          rolesList={roleList}
        />
      )}
    </>
  );
};

export default Library;
