import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import {
  getClassCategories,
  getClasses,
  graduateStudents,
  setCurrentPage,
} from "../../redux/action";
import { useNavigate } from "react-router";
import ClassModal from "../../Components/Class/ClassModal";
import Dropdown from "react-bootstrap/Dropdown";
import DeletePopup from "../../Components/common/Popups/DeletePopup";
import { FormattedMessage } from "react-intl";
import { AppendedMyComponent } from "../../Components/append-to-body-drop-down";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import NoDatFound from "../../Components/common/NoDatFound";
import Pagination from "../../Components/common/Paginate/Pagination";
import { formatDate } from "../../util/utils";
import { BsSortDown, BsSortUp, FaEdit, FaUserGraduate } from "../../reactIcons";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import axios from "../../service/APIServices/axiosConfig";
import { toast } from "react-toastify";
import Axios from "axios";

const Classes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const classCategories: any = useSelector(
    (state: Store) => state.classReducer.classCategories,
  ) as any;
  const isLoading = useSelector((state: Store) => state.classReducer.isLoading);
  const currentPage = useSelector(
    (state: Store) => state.classReducer.currentPage,
  );

  const [pageNumber, setPageNumber] = useState<any>();
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const totalRecord = useSelector(
    (state: Store) => state.classReducer.totalRecord,
  ) as any;

  const [lstClasses, setLstClasses] = useState<any>([]);
  const [editClass, setEdtClasses] = useState<any>([]);
  const [addClass, setAddClass] = useState(false);
  const [grdClass, setgrdClass] = useState({ id: "", class: "", modal: false });
  const lstClass =
    useSelector((state: Store) => state.classReducer.classes) || [];

  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("DESC");
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);

  // useEffect(() => {
  //   // dispatch(getEAList(false, { by: sortBy, order: sortOrder }))
  //   // dispatch(getEAUnauthorizedList(false))
  // }, [sortOrder, sortBy]);

  // const setSorting = (prop) => {
  //   if (sortBy === prop) {
  //     setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
  //   } else {
  //     setSortBy(prop);
  //     setSortOrder("ASC");
  //   }
  // };

  useEffect(() => {
    dispatch(getClassCategories(false));
    const storedPageNumber = localStorage.getItem("pageNumber");
    const storedPageLimit = localStorage.getItem("pageLimit");
    if (storedPageNumber && storedPageLimit) {
      setPageNumber(storedPageNumber);
      setPageLimit(storedPageLimit);
    } else {
      setPageNumber(1);
      setPageLimit(10);
    }
  }, []);

  useEffect(() => {
    setLstClasses(lstClass);
  }, [lstClass]);

  useEffect(() => {
    if (pageNumber && pageLimit) {
      dispatch(
        getClasses(
          false,
          pageNumber,
          { by: sortBy, order: sortOrder },
          pageLimit,
        ),
      );
    }
  }, [sortOrder, sortBy, pageNumber, pageLimit]);

  // useEffect(() => {
  //   setPageNumber(currentPage);
  // }, [currentPage]);

  const viewClassDetail = (id: any) => {
    localStorage.setItem("pageNumber", pageNumber);
    localStorage.setItem("pageLimit", pageLimit);
    navigate("/classes/" + id);
  };

  const editClassSelected = (classDetail: any) => {
    setEdtClasses(classDetail);
    setAddClass(true);
  };

  const graduateStudent = (id: any) => {
    dispatch(graduateStudents(true, id));
    setgrdClass({ modal: false, class: "", id: "" });
  };

  const onPageChange = (pageNum: number) => {
    localStorage.setItem("pageNumber", pageNum.toString());
    localStorage.setItem("pageLimit", pageLimit.toString());
    setPageNumber(pageNum);
    dispatch(setCurrentPage(pageNum));
  };

  const setSorting = (prop: any) => {
    if (sortBy === prop) {
      setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
    } else {
      setSortBy(prop);
      setSortOrder("ASC");
    }
  };

  const downloadFile = async (id: number) => {
    try {
      const res = await axios.get(
        `/temporaryFixes/export-class-freshman-data/${id}`,
        { responseType: "blob" },
      );
      const url = window.URL.createObjectURL(new Blob([res.data]));
      if (downloadLinkRef.current) {
        downloadLinkRef.current.href = url;
        downloadLinkRef.current.download = `${id}.csv`;
        downloadLinkRef.current.click();
      }
    } catch (error) {
      let message = "Something went wrong";
      if (Axios.isAxiosError(error) && error.response?.data.message) {
        message = error.response.data.message;
      }
      toast.error(message);
    }
  };
  return (
    <>
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                {" "}
                <FormattedMessage id="Classes" defaultMessage="Classes " />
              </h1>
            </div>

            <div className="m-l-10">
              <a
                href="#"
                data-toggle="modal"
                data-target="#AddCourse"
                type="button"
                onClick={() => {
                  setAddClass(true);
                  setEdtClasses(null);
                }}
                className="btn btn-primary"
              >
                <FormattedMessage
                  id="Classes.AddNew"
                  defaultMessage="Add New"
                />
              </a>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          <div className="card">
            <div className="card-body p-0">
              {isLoading && <CenterLoader />}

              <div className="table-responsive">
                {lstClasses.length ? (
                  <>
                    <table className="table table-hover m-0">
                      <thead>
                        <tr>
                          <th
                            // className="table-field-actions"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("id")}
                          >
                            <FormattedMessage
                              id="Classes.ID"
                              defaultMessage="ID"
                            />
                            {sortBy === "id" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            // className="table-field-actions"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("name")}
                          >
                            <FormattedMessage
                              id="Classes.Name"
                              defaultMessage="Name"
                            />
                            {sortBy === "name" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            className="table-field-status"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("status")}
                          >
                            <FormattedMessage
                              id="Classes.Status"
                              defaultMessage="Status "
                            />
                            {sortBy === "status" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            className="table-field-actions"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("createdAt")}
                          >
                            <FormattedMessage
                              id="table.head.CreatedAt"
                              defaultMessage="CreatedAt "
                            />
                            {sortBy === "createdAt" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th className="table-field-actions">
                            <FormattedMessage
                              id="Classes.Details"
                              defaultMessage="Details "
                            />
                          </th>
                          {/* <th className="table-field-actions">Graduate</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {lstClasses.length &&
                          lstClasses.map((i: any) => (
                            <tr>
                              <td
                                onClick={() => viewClassDetail(i.id)}
                                className="pointer"
                              >
                                {i.id}
                              </td>
                              <td
                                onClick={() => viewClassDetail(i.id)}
                                className="pointer"
                              >
                                {i.name}
                              </td>
                              <td
                                onClick={() => viewClassDetail(i.id)}
                                className="pointer table-field-status"
                              >
                                <span
                                  className={
                                    i.status === "open"
                                      ? "badge badge-pill badge-success"
                                      : "badge badge-pill badge-error"
                                  }
                                >
                                  {i.status}
                                </span>
                              </td>
                              <td
                                onClick={() => viewClassDetail(i.id)}
                                className="pointer"
                              >
                                {formatDate(i.createdAt, "DD MMM, YYYY")}
                              </td>
                              <td className="table-field-actions">
                                <Dropdown className="btn-group">
                                  <Dropdown.Toggle
                                    id="dropdown-basic"
                                    className="btn btn-sm btn-icon-only"
                                  >
                                    <i className="icon dripicons-dots-3 zmdi-hc-fw"></i>
                                  </Dropdown.Toggle>
                                  <AppendedMyComponent>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        href="#"
                                        onClick={() => editClassSelected(i)}
                                      >
                                        <i className="text-accent-custom">
                                          <FaEdit />{" "}
                                        </i>
                                        <FormattedMessage
                                          id="page.Edit"
                                          defaultMessage="Edit"
                                        />
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        href="#"
                                        onClick={() =>
                                          setgrdClass({
                                            modal: true,
                                            class: i.name,
                                            id: i.id,
                                          })
                                        }
                                      >
                                        <i className=" text-accent-custom">
                                          <FaUserGraduate />{" "}
                                        </i>
                                        <FormattedMessage
                                          id="page.Graduate"
                                          defaultMessage="Graduate"
                                        />
                                      </Dropdown.Item>
                                      {i.name.startsWith("FF") ? (
                                        <Dropdown.Item
                                          href="#"
                                          onClick={() => downloadFile(i.id)}
                                        >
                                          <FormattedMessage
                                            id="page.Export"
                                            defaultMessage="Export Students"
                                          />
                                        </Dropdown.Item>
                                      ) : null}
                                    </Dropdown.Menu>
                                  </AppendedMyComponent>
                                </Dropdown>
                              </td>
                              {/* <td>
                                                    <Button onClick={() => setgrdClass({ modal: true, class: i.name, id: i.id })} variant="outline-info">Graduate</Button>
                                                </td> */}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <p>{!isLoading && <NoDatFound />}</p>
                )}
              </div>
              {totalRecord > 10 && lstClasses.length > 0 && (
                <>
                  <FormControl className="pagelimit">
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      <FormattedMessage
                        id="page_size"
                        defaultMessage="Page Size"
                      />
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={pageLimit}
                      onChange={(e: any) => {
                        setPageLimit(e.target.value);
                        localStorage.setItem("pageLimit", e.target.value);
                        localStorage.setItem("pageNumber", pageNumber);
                        setPageNumber(1);
                      }}
                    >
                      <FormControlLabel
                        value={10}
                        control={<Radio />}
                        label="10"
                      />
                      <FormControlLabel
                        value={20}
                        control={<Radio />}
                        label="20"
                      />
                      <FormControlLabel
                        value={50}
                        control={<Radio />}
                        label="50"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Pagination
                    itemsPerPage={pageLimit}
                    totalPages={totalRecord}
                    onPageChage={onPageChange}
                    page={pageNumber - 1}
                  />
                </>
              )}
            </div>
          </div>
        </section>
        <a ref={downloadLinkRef} style={{ display: "none" }} />
      </div>
      {grdClass.modal && (
        <DeletePopup
          onClose={() => setgrdClass({ modal: false, class: "", id: "" })}
          onDeleteClick={() => graduateStudent(grdClass.id)}
          open={grdClass.modal}
          text={
            <FormattedMessage
              id="msg.classGrd"
              defaultMessage={`Are you sure want to graduate all student in class  ?`}
              values={{ value: grdClass.class }}
            />
          }
        />
      )}

      {addClass && (
        <>
          <ClassModal
            onModalClose={() => setAddClass(false)}
            classCategories={classCategories}
            editClass={editClass}
          />
        </>
      )}
    </>
  );
};

export default Classes;
