import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import NoDatFound from "../../../Components/common/NoDatFound";
import Pagination from "../../../Components/common/Paginate/Pagination";
import { dispatch } from "../../../redux";
import {
  // getActivityAttendence,
  getActivityAttendenceLog,
} from "../../../redux/action";
import { Store } from "../../../redux/Actions";
import { formatDate } from "../../../util/utils";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

const PointAttendence = () => {
  const urlParams = useParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );

  const actAttendenceLog = useSelector(
    (state: Store) => state.ActivityReducer.actAttendenceLog,
  );

  const totalRecord = useSelector(
    (state: Store) => state.ActivityReducer.totalRecord,
  ) as any;

  useEffect(() => {
    // const { id } = urlParams;
    // dispatch(getActivityAttendenceLog(false, id));
    setPageNumber(1);
  }, []);

  useEffect(() => {
    const { id } = urlParams;
    dispatch(getActivityAttendenceLog(false, id, pageNumber, pageLimit));
  }, [pageNumber, pageLimit]);

  const onPageChange = (pageNum: number) => {
    setPageNumber(pageNum);
    // dispatch(setCurrentPage(pageNum));
  };

  return (
    <div>
      <div className="table-responsive mt-3">
        {actAttendenceLog && actAttendenceLog.length ? (
          <>
            <table className="table table-hover m-0">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="table.head.ID" defaultMessage="ID" />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.Member"
                      defaultMessage="Member"
                    />
                  </th>
                  <th className="table-field-status">
                    <FormattedMessage
                      id="Admin.GrantList.Message"
                      defaultMessage="Message"
                    />
                  </th>
                  <th className="table-field-actions">
                    <FormattedMessage
                      id="table.head.Date"
                      defaultMessage="Date"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {actAttendenceLog &&
                  actAttendenceLog.length &&
                  actAttendenceLog.map((i: any) => (
                    <tr>
                      <td>{i.id}</td>
                      <td>
                        {i?.users?.englishFirstName +
                          " " +
                          i?.users?.englishLastName +
                          " (" +
                          (i?.users?.id || 0) +
                          ")"}
                      </td>
                      <td
                        className="text-center"
                        dangerouslySetInnerHTML={{
                          __html: i?.remarks?.split(",").join("<br/>") || "-",
                        }}
                      />
                      <td className="text-center">
                        {formatDate(i.createdAt, "YYYY-MM-DD HH:mm")}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        ) : (
          <NoDatFound />
        )}
      </div>
      {totalRecord > 10 && actAttendenceLog.length && (
        <>
          <FormControl className="pagelimit">
            <FormLabel id="demo-row-radio-buttons-group-label">
              <FormattedMessage id="page_size" defaultMessage="Page Size" />
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={pageLimit}
              onChange={(e: any) => {
                setPageLimit(e.target.value);
                setPageNumber(1);
              }}
            >
              <FormControlLabel value={10} control={<Radio />} label="10" />
              <FormControlLabel value={20} control={<Radio />} label="20" />
              <FormControlLabel value={50} control={<Radio />} label="50" />
            </RadioGroup>
          </FormControl>
          <Pagination
            itemsPerPage={pageLimit}
            totalPages={totalRecord}
            onPageChage={onPageChange}
            page={pageNumber - 1}
          />
        </>
      )}
    </div>
  );
};
export default PointAttendence;
