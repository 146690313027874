/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/APIServices/baseApiCall";
import {
  GET_SCRIPTS_DETAILS,
  GET_SCRIPTS_LIST,
  ADD_SCRIPTS_LIST,
  EDIT_SCRIPTS_LIST,
  GET_SCRIPT_VERSION_LIST,
  ADD_SCRIPT_VERSION,
  EDIT_SCRIPT_VERSION,
  DELETE_SCRIPT_VERSION,
  DELETE_SCRIPT,
  SET_PAGE,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { getAPIEndPoint, getPage, setToken } from "../../util/utils";
import {
  GET_SCRIPTS_LIST_URL,
  EIDT_SCRIPTS_LIST_URL,
  ADD_SCRIPTS_LIST_URL,
  GET_SCRIPT_VERSION_LIST_URL,
  ADD_SCRIPT_VERSION_URL,
  EDIT_SCRIPT_VERSION_URL,
  DELETE_SCRIPT_VERSION_URL,
  DELETE_SCRIPT_URL,
} from "../../service/APIServices/apiEndPoints";
import { toast } from "react-toastify";
import { AnyARecord } from "dns";
import {
  _UploadMedia,
  _UploadTool,
} from "../../service/MediaUpload/classes.service";
// import { _getExpressWayPendingList } from "../../service";
// import { AxiosResponse } from "axios";

export const setScriptCurrentPage =
  (pageNumber: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: SET_PAGE,
      payload: pageNumber,
    });
  };

//Get Admin Roles List
export const getScriptsList =
  (
    showToast = true,
    pageNumber = 0,
    sort = { order: "DESC", by: "createdAt" },
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getScriptsListInit(showToast, pageNumber, sort, pageLimit));
  };

export const getScriptsListInit =
  (
    showToast = true,
    pageNumber: number,
    sort: any,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_SCRIPTS_LIST.GET_SCRIPTS_LIST_INITIALIZATION,
    });
    apiCall(
      getAPIEndPoint(
        GET_SCRIPTS_LIST_URL + `?sort=${sort.order}&sortBy=${sort.by}`,
        pageNumber,
        true,
        pageLimit,
      ),
      {},
      (res: Object) => dispatch(getScriptsListSuccess(res)),
      (err: any) => dispatch(getScriptsListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getScriptsListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_SCRIPTS_LIST.GET_SCRIPTS_LIST_SUCCESS,
      payload: res,
    });
  };

export const getScriptsListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_SCRIPTS_LIST.GET_SCRIPTS_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const addScriptsList =
  (
    showToast = true,
    indicatorData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch({
      type: ADD_SCRIPTS_LIST.ADD_SCRIPTS_LIST_INITIALIZATION,
    });
    let url = "";

    const files = indicatorData.image_path;
    if (files) {
      const { fileUploadResponse, error }: any = await _UploadMedia(
        files as File,
      );
      url = fileUploadResponse;
      if (error) {
        dispatch(addScriptsListError());
        return;
      }
    }
    indicatorData.image_path = url;
    dispatch(addScriptsListInit(showToast, indicatorData));
  };

export const addScriptsListInit =
  (
    showToast = true,
    indicatorData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    apiCall(
      ADD_SCRIPTS_LIST_URL,
      indicatorData,
      (res: Object) => dispatch(addScriptsListSuccess(res)),
      (err: any) => dispatch(addScriptsListError()),
      METHOD.POST,
      { showToast },
    );
  };

export const addScriptsListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_SCRIPTS_LIST.ADD_SCRIPTS_LIST_SUCCESS,
    });
    dispatch(setScriptCurrentPage(1));
    dispatch(getScriptsList(false, 1));
    // localStorage.clear();
    // toast.success("addScriptsList successfully")
    // window.location.reload();
  };

export const addScriptsListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_SCRIPTS_LIST.ADD_SCRIPTS_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const editScriptsList =
  (
    showToast = true,
    id: any,
    indicatorData: any,
    isFile: boolean,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch({
      type: EDIT_SCRIPTS_LIST.EDIT_SCRIPTS_LIST_INITIALIZATION,
    });

    if (isFile) {
      const files = indicatorData.image_path;
      const { fileUploadResponse, error }: any = await _UploadMedia(
        files as File,
      );
      if (error) {
        dispatch(editScriptsListError());
        return;
      }
      indicatorData.image_path = fileUploadResponse;
    }
    dispatch(editScriptsListInit(showToast, id, indicatorData));
  };

export const editScriptsListInit =
  (
    showToast = true,
    id: any,
    indicatorData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    apiCall(
      EIDT_SCRIPTS_LIST_URL + id,
      indicatorData,
      (res: Object) => dispatch(editScriptsListSuccess(res)),
      (err: any) => dispatch(editScriptsListError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const editScriptsListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_SCRIPTS_LIST.EDIT_SCRIPTS_LIST_SUCCESS,
      payload: res.data,
    });
    dispatch(setScriptCurrentPage(1));
    dispatch(getScriptsList(false, 1));
    // localStorage.clear();
    // toast.success("editScriptsList successfully")
    // window.location.reload();
  };

export const editScriptsListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: EDIT_SCRIPTS_LIST.EDIT_SCRIPTS_LIST_ERROR,
    });
  };

export const getScriptsDetails =
  (
    showToast = false,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getScriptsDetailsInit(showToast, id));
  };

export const getScriptsDetailsInit =
  (
    showToast: boolean,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_SCRIPTS_DETAILS.GET_SCRIPTS_DETAILS_INITIALIZATION,
    });
    apiCall(
      GET_SCRIPTS_LIST_URL + "/" + id,
      {},
      (res: Object) => dispatch(getScriptsDetailsSuccess(res)),
      (err: any) => dispatch(getScriptsDetailsError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getScriptsDetailsSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_SCRIPTS_DETAILS.GET_SCRIPTS_DETAILS_SUCCESS,
      payload: res.data,
    });
    // localStorage.clear();
    // toast.success("getScriptsDetails successfully")
    // window.location.reload();
  };

export const getScriptsDetailsError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_SCRIPTS_DETAILS.GET_SCRIPTS_DETAILS_ERROR,
    });
  };

//Get Admin Roles
export const deleteScript =
  (
    showToast = true,
    indicator: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(deleteScriptInit(showToast, indicator));
  };

export const deleteScriptInit =
  (
    showToast = true,
    indicator: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_SCRIPT.DELETE_SCRIPT_INITIALIZATION,
    });
    apiCall(
      `${DELETE_SCRIPT_URL}${indicator}`,
      {},
      (res: Object) => dispatch(deleteScriptSuccess(res)),
      (err: any) => dispatch(deleteScriptError()),
      METHOD.DELETE,
      { showToast },
    );
  };

export const deleteScriptSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_SCRIPT.DELETE_SCRIPT_SUCCESS,
      payload: res.data,
    });
    dispatch(getScriptsList(false));
    // localStorage.clear();
    // toast.success("deleteScript successfully")
    // window.location.reload();
  };

export const deleteScriptError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: DELETE_SCRIPT.DELETE_SCRIPT_ERROR,
    });
  };

//Get Admin Roles List
export const getScriptVersionList =
  (
    showToast = true,
    indicator: string,
    pageNumber = 0,
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      getScriptVersionListInit(showToast, indicator, pageNumber, pageLimit),
    );
  };

export const getScriptVersionListInit =
  (
    showToast = true,
    indicator: string,
    pageNumber: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_SCRIPT_VERSION_LIST.GET_SCRIPT_VERSION_LIST_INITIALIZATION,
    });
    apiCall(
      getAPIEndPoint(
        `${GET_SCRIPT_VERSION_LIST_URL}${indicator}`,
        pageNumber,
        false,
        pageLimit,
      ),
      {},
      (res: Object) => dispatch(getScriptVersionListSuccess(res)),
      (err: any) => dispatch(getScriptVersionListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getScriptVersionListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_SCRIPT_VERSION_LIST.GET_SCRIPT_VERSION_LIST_SUCCESS,
      payload: res,
    });
  };

export const getScriptVersionListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_SCRIPT_VERSION_LIST.GET_SCRIPT_VERSION_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const addScriptVersion =
  (
    showToast = true,
    indicator: any,
    classId: any,
    isFile: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch({
      type: ADD_SCRIPT_VERSION.ADD_SCRIPT_VERSION_INITIALIZATION,
    });
    if (isFile.toolUpload) {
      const toolFile = indicator.download_path;
      const { fileUploadResponse, error }: any = await _UploadTool(
        toolFile as File,
      );
      if (!error) {
        indicator.download_path = fileUploadResponse;
      } else {
        dispatch(addScriptVersionError());
        return;
      }
    }

    if (isFile.manualUpload) {
      const toolFile = indicator.manual_path;
      const { fileUploadResponse, error }: any = await _UploadMedia(
        toolFile as File,
      );
      if (!error) {
        indicator.manual_path = fileUploadResponse;
      } else {
        dispatch(addScriptVersionError());
        return;
      }
    }
    dispatch(addScriptVersionInit(showToast, indicator, classId));
  };

export const addScriptVersionInit =
  (
    showToast = true,
    indicator: any,
    classId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    apiCall(
      `${ADD_SCRIPT_VERSION_URL}`,
      indicator,
      (res: Object) => dispatch(addScriptVersionSuccess(res, classId)),
      (err: any) => dispatch(addScriptVersionError()),
      METHOD.POST,
      { showToast },
    );
  };

export const addScriptVersionSuccess =
  (res: any, classId: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_SCRIPT_VERSION.ADD_SCRIPT_VERSION_SUCCESS,
      payload: res.data,
    });

    dispatch(setScriptCurrentPage(1));
    dispatch(getScriptVersionList(false, classId, 1));
  };

export const addScriptVersionError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_SCRIPT_VERSION.ADD_SCRIPT_VERSION_ERROR,
    });
  };

//Get Admin Roles
export const editScriptVersion =
  (
    showToast = true,
    id: any,
    scriptVersion: any,
    classId: any,
    isFile: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch({
      type: EDIT_SCRIPT_VERSION.EDIT_SCRIPT_VERSION_INITIALIZATION,
    });
    if (isFile.toolUpload) {
      const toolFile = scriptVersion.download_path;
      const { fileUploadResponse, error }: any = await _UploadTool(
        toolFile as File,
      );
      if (!error) {
        scriptVersion.download_path = fileUploadResponse;
      } else {
        dispatch(editScriptVersionError());
        return;
      }
    }

    if (isFile.manualUpload) {
      const toolFile = scriptVersion.manual_path;
      const { fileUploadResponse, error }: any = await _UploadMedia(
        toolFile as File,
      );
      if (!error) {
        scriptVersion.manual_path = fileUploadResponse;
      } else {
        dispatch(editScriptVersionError());
        return;
      }
    }
    dispatch(editScriptVersionInit(showToast, id, scriptVersion, classId));
  };

export const editScriptVersionInit =
  (
    showToast = true,
    id: any,
    scriptVersion: any,
    classId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    apiCall(
      `${EDIT_SCRIPT_VERSION_URL}${id}`,
      scriptVersion,
      (res: Object) => dispatch(editScriptVersionSuccess(res, classId)),
      (err: any) => dispatch(editScriptVersionError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const editScriptVersionSuccess =
  (res: any, classId: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_SCRIPT_VERSION.EDIT_SCRIPT_VERSION_SUCCESS,
      payload: res.data,
    });
    dispatch(setScriptCurrentPage(1));
    dispatch(getScriptVersionList(false, classId, 1));
  };

export const editScriptVersionError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: EDIT_SCRIPT_VERSION.EDIT_SCRIPT_VERSION_ERROR,
    });
  };

//Get Admin Roles
export const deleteScriptVersion =
  (
    showToast = true,
    indicator: any,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(deleteScriptVersionInit(showToast, indicator, id));
  };

export const deleteScriptVersionInit =
  (
    showToast = true,
    indicator: any,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_SCRIPT_VERSION.DELETE_SCRIPT_VERSION_INITIALIZATION,
    });
    apiCall(
      `${DELETE_SCRIPT_VERSION_URL}${indicator}`,
      {},
      (res: Object) => dispatch(deleteScriptVersionSuccess(res, id)),
      (err: any) => dispatch(deleteScriptVersionError()),
      METHOD.DELETE,
      { showToast },
    );
  };

export const deleteScriptVersionSuccess =
  (res: any, id: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_SCRIPT_VERSION.DELETE_SCRIPT_VERSION_SUCCESS,
      payload: res.data,
    });
    dispatch(getScriptVersionList(false, id));
  };

export const deleteScriptVersionError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: DELETE_SCRIPT_VERSION.DELETE_SCRIPT_VERSION_ERROR,
    });
  };
