import React, { useEffect, useState } from "react";
import Logo from "../../assets/img/gemsFX_white.png";
import { TranslationIcon } from "../../util/iconStyle";
import { useDispatch, useSelector } from "react-redux";
import { getQR, logout, translate } from "../../redux/action";
import { Store } from "../../redux/Actions";
import { useLocation, useNavigate } from "react-router";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Avatar } from "@mui/material";
import GemsfxChinese from "../../assets/img/Gemsfx-Version4-Logo - Chinese.png";
import GemsfxEnglish from "../../assets/img/Gemsfx-Version4-Logo - English.png";
import NoImg from "../../assets/img/no-image.png";
import { BsChevronDown } from "react-icons/bs";

export const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isLogout = useSelector(
    (state: Store) => state.userDataReducer.isLoggedout,
  );
  const isLoggedin = useSelector(
    (state: Store) => state.userDataReducer.isLoggedin,
  );
  const local = useSelector((state: Store) => state.userDataReducer.local);
  const myInfo = useSelector((state: Store) => state.userDataReducer.myInfo);

  const [OpenProfileActionMenu, setOpenProfileActionMenu] = useState(false);
  const [sidebarToggle, setsidebarToggle] = useState(false);
  const [MobileToggle, setMobileToggle] = useState(false);

  useEffect(() => {
    dispatch(getQR());
  }, []);

  useEffect(() => {
    setsidebarToggle(false);
    setOpenProfileActionMenu(false);
  }, [location.pathname]);

  useEffect(() => {
    const el = document.querySelector("body");
    if (sidebarToggle) {
      setOpenProfileActionMenu(false);
      setMobileToggle(false);
      if (el) {
        el.classList.add("aside-left-open");
      }
    } else {
      if (el) {
        el.classList.remove("aside-left-open");
      }
    }
  }, [sidebarToggle]);

  const translateLocal = (language: string) => {
    dispatch(translate(language));
  };

  const handleSidebarToggleChange = () => {
    setsidebarToggle(!sidebarToggle);
  };

  useEffect(() => {
    const el = document.getElementById("body");

    if (MobileToggle) {
      if (el) {
        el.classList.add("mobile-topbar-toggle");
      }
    } else {
      if (el) {
        el.classList.remove("mobile-topbar-toggle");
      }
    }
  }, [MobileToggle]);

  const handleMobileToggleChange = () => {
    setMobileToggle(!MobileToggle);
  };

  useEffect(() => {
    if (!isLoggedin && isLogout) {
      navigate("/login");
    }
  }, [isLogout, isLoggedin]);
  return (
    <>
      <nav className="top-toolbar navbar navbar-mobile navbar-tablet flex-nowrap">
        <ul className="navbar-nav nav-left">
          <li className="nav-item">
            <a
              onClick={() => handleSidebarToggleChange()}
              data-toggle-state="aside-left-open"
            >
              <i className="icon dripicons-align-left"></i>
            </a>
          </li>
        </ul>
        <ul className="navbar-nav nav-left site-logo">
          <li>
            <Link to="/home">
              <div className="logo-custom">
                {local == "en" ? (
                  <img
                    src={GemsfxEnglish}
                    style={{ width: "140px", maxWidth: "unset" }}
                  />
                ) : (
                  <img
                    src={GemsfxChinese}
                    style={{ width: "140px", maxWidth: "unset" }}
                  />
                )}{" "}
              </div>
            </Link>
          </li>
        </ul>
        <ul className="navbar-nav nav-right">
          <li
            className="nav-item dropdown"
            onClick={() => {
              handleMobileToggleChange();
              setsidebarToggle(false);
            }}
          >
            <a
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="icon dripicons-dots-3 rotate-90"></i>
            </a>
          </li>
        </ul>
      </nav>
      <nav className="top-toolbar navbar navbar-desktop flex-nowrap">
        <ul className="navbar-nav nav-right">
          <li className="nav-item dropdown mr-1">
            <a className="nav-link d-flex align-items-center justify-content-center">
              <div className="sl-nav">
                <ul
                  className="m-0"
                  onMouseEnter={() => {
                    setOpenProfileActionMenu(false);
                  }}
                >
                  <li>
                    <span className="d-flex align-items-center text-white">
                      <i
                        className="MuiListItemIcon-root"
                        style={{ color: "white" }}
                      >
                        {" "}
                        <TranslationIcon />
                      </i>{" "}
                      {/* <i
                        className="fa fa-angle-down font-size-14 text-white"
                        aria-hidden="true"
                      ></i>{" "} */}
                      <BsChevronDown />
                    </span>
                    <div className="triangle"></div>
                    <ul>
                      <li onClick={() => translateLocal("zh")}>
                        <i className="sl-flag flag-de">
                          <div id="germany"></div>
                        </i>{" "}
                        <span className={local === "zh" ? "active" : ""}>
                          <FormattedMessage
                            id="Chinese"
                            defaultMessage="Chinese"
                          />
                        </span>
                      </li>
                      <li onClick={() => translateLocal("en")}>
                        <i className="sl-flag flag-usa">
                          <div id="germany"></div>
                        </i>{" "}
                        <span className={local === "en" ? "active" : ""}>
                          <FormattedMessage
                            id="English"
                            defaultMessage="English"
                          />
                        </span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </a>
          </li>

          <li
            className="nav-item dropdown"
            onClick={() => setOpenProfileActionMenu(!OpenProfileActionMenu)}
          >
            <a
              className="nav-link nav-pill user-avatar"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {myInfo.avatar !== null && myInfo.avatar !== "" ? (
                <img
                  className="align-self-center w-40 h-40 o-cover rounded-circle"
                  src={myInfo.avatar}
                  alt={myInfo.englishFirstName}
                />
              ) : (
                <img
                  className="align-self-center w-40 h-40 o-cover rounded-circle"
                  src={NoImg}
                  // alt="John Smith here"
                />
              )}
              <span className="nav-item-username">
                {local === "en"
                  ? myInfo?.englishFirstName + " " + myInfo?.englishLastName
                  : myInfo?.chineseFirstName + " " + myInfo?.chineseLastName}
              </span>

              {/* <img src={myInfo.avatar} className="w-35 h-35 o-cover rounded-circle" alt="John Smith here" height="35px" /> */}
            </a>
            {OpenProfileActionMenu ? (
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-accout d-inline-block">
                <div className="dropdown-header pb-3">
                  <div className="media d-user">
                    <Avatar className="mr-3">
                      {myInfo.avatar !== null && myInfo.avatar !== "" ? (
                        <img
                          className="align-self-center w-40 h-40 o-cover rounded-circle"
                          src={myInfo.avatar}
                          alt={myInfo.englishFirstName}
                        />
                      ) : (
                        // "AD"
                        myInfo.englishFirstName.charAt(0) +
                        myInfo.englishLastName.charAt(0)
                      )}
                    </Avatar>
                    {/* <img className="align-self-center mr-3 w-40 h-40 o-cover rounded-circle" src={myInfo.avatar} alt={myInfo.englishFirstName} /> */}
                    <div className="media-body">
                      <h5 className="mt-0 mb-0">
                        {local === "en"
                          ? myInfo?.englishFirstName +
                            " " +
                            myInfo?.englishLastName
                          : myInfo?.chineseFirstName +
                            " " +
                            myInfo?.chineseLastName}
                      </h5>
                      <span>{myInfo?.email || ""}</span>
                    </div>
                  </div>
                </div>
                <Link className="dropdown-item" to="/profile">
                  <i className="icon dripicons-user"></i>
                  <FormattedMessage
                    id="head.MyProfile"
                    defaultMessage="My Profile"
                  />
                </Link>
                <Link className="dropdown-item" to="/change-password">
                  <i className="icon dripicons-gear"></i>
                  <FormattedMessage
                    id="head.ChangePassword"
                    defaultMessage="Change Password"
                  />
                </Link>
                <Link
                  className="dropdown-item"
                  to="#"
                  onClick={() => dispatch(logout())}
                >
                  <i className="icon dripicons-lock"></i>
                  <FormattedMessage id="head.Logout" defaultMessage="Logout" />
                </Link>
              </div>
            ) : null}
          </li>

          {/* <li
            className="nav-item d-flex align-items-center justify-content-center"
          >
            <div className="nav-item-username">
              {local === "en"
                ? myInfo?.englishFirstName +
                  " " +
                  myInfo?.englishLastName
                : myInfo?.chineseFirstName +
                  " " +
                  myInfo?.chineseLastName}
            </div>
          </li> */}
        </ul>
      </nav>
    </>
  );
};
