import { z } from "zod";
import { createGemsAdvertisementSchema } from "./createGemsAdvertisementSchema";

export const updateGemsAdvertisementByIdSchema = z.object({
  params: z.object({
    gemsAdvertisementId: z.coerce.number().int().safe().nonnegative(),
  }),
  body: createGemsAdvertisementSchema.shape.body,
});

export type UpdateGemsAdvertisementByIdDto = z.infer<
  typeof updateGemsAdvertisementByIdSchema
>;
