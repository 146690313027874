import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

import GrantList from "./List";
import ToolList from "./Tool";
import PointList from "./Point";
import { GRANT_LIST_TABS } from "../../Constants";
import { FormattedMessage } from "react-intl";
import Account from "./Account";

const Grants = () => {
  const [activeTab, setActiveTab] = useState(0);
  const tabsOnChangeHandler = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };

  const classes = useStyles();
  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                <FormattedMessage
                  id="Admin.Grants"
                  defaultMessage="Admin Grants"
                />
              </h1>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          <Card>
            <Card.Body className="p-0">
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={activeTab}
                  onChange={tabsOnChangeHandler}
                  className={classes.tabs}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label={
                      <FormattedMessage id="tab.List" defaultMessage="List" />
                    }
                    {...a11yProps(GRANT_LIST_TABS.GRANT_LIST)}
                  />
                  <Tab
                    label={
                      <FormattedMessage id="tab.Point" defaultMessage="Point" />
                    }
                    {...a11yProps(GRANT_LIST_TABS.POINT_RENT)}
                  />
                  <Tab
                    label={
                      <FormattedMessage id="tab.Tool" defaultMessage="Tool" />
                    }
                    {...a11yProps(GRANT_LIST_TABS.TOOL_RENT)}
                  />
                  <Tab
                    label={
                      <FormattedMessage
                        id="tab.Account"
                        defaultMessage="Account"
                      />
                    }
                    {...a11yProps(GRANT_LIST_TABS.ACCOUNT)}
                  />
                </Tabs>
              </Box>
              <TabPanel value={activeTab} index={GRANT_LIST_TABS.GRANT_LIST}>
                <GrantList />
              </TabPanel>
              <TabPanel value={activeTab} index={GRANT_LIST_TABS.TOOL_RENT}>
                <PointList setActiveTab={setActiveTab} />
              </TabPanel>
              <TabPanel value={activeTab} index={GRANT_LIST_TABS.POINT_RENT}>
                <ToolList setActiveTab={setActiveTab} />
              </TabPanel>
              <TabPanel value={activeTab} index={GRANT_LIST_TABS.ACCOUNT}>
                <Account setActiveTab={setActiveTab} />
              </TabPanel>
            </Card.Body>
          </Card>
        </section>
      </div>
    </div>
  );
};

export default Grants;

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#68439a",
      height: 1,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#68439a",
      BsBorderBottom: "1px solid",
    },
  },
});

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
