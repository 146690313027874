import React, { useState } from "react";
import {
  CustomNodeElementProps,
  RawNodeDatum,
} from "react-d3-tree/lib/types/types/common";
import { HiDotsVertical } from "react-icons/hi";

interface TreeNodeElementProps extends CustomNodeElementProps {
  foreignObjectProps?: any;
}

interface CustomClassData {
  id: number;
  status: "approved";
  class: { id: number; name: string };
}

export interface CustomStudentData {
  id: number;
  preferredNickName: string;
  englishFirstName: string;
  englishLastName: string;
  userClass: CustomClassData[];
  referer: false | CustomStudentData;
}

const CustomTreeNode: React.FC<TreeNodeElementProps> = ({
  nodeDatum,
  foreignObjectProps,
}) => {
  const x = foreignObjectProps.x || 0;
  const y = foreignObjectProps.y || 0;
  const width = foreignObjectProps.width || 200;
  const height = foreignObjectProps.height || 96;

  const memberId = nodeDatum.attributes?.id ?? "-";
  const englishFirstName = nodeDatum.attributes?.englishFirstName ?? "";
  const englishNickName = nodeDatum.attributes?.englishNickName ?? "";
  const englishLastName = nodeDatum.attributes?.englishLastName ?? "";

  const latestClass = (nodeDatum.attributes?.latestClass ?? "-") as string;
  const totalClassesCount = (nodeDatum.attributes?.totalClassesCount ??
    "-") as string;
  const userClasses = (nodeDatum.attributes?.userClasses ?? "-") as string;

  const [showDetail, setShowDetail] = useState<boolean>(false);
  const toggleDetail = () => setShowDetail((oldShowDetail) => !oldShowDetail);

  const englishFullNameTuple = [
    englishFirstName,
    englishNickName,
    englishLastName,
  ];
  const englishFullName = englishFullNameTuple.filter((s) => s).join(" ");

  return (
    <g>
      <circle r="15" /> s
      <foreignObject
        x={x}
        y={y}
        width={showDetail ? 1.5 * width : width}
        height={showDetail ? 1 * height : height}
        style={{ overflow: "visible" }}
      >
        <div
          style={{
            padding: "0.5rem",
            border: "1px solid black",
            backgroundColor: "#dedede",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            onClick={toggleDetail}
          >
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <text fill="black" strokeWidth="1" x="20">
                {nodeDatum.name}
              </text>
            </div>
            <HiDotsVertical style={{ flexShrink: 0 }} />
          </div>
          {showDetail ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <text>Full name: {`${englishFullName}`}</text>
              <text>Member Id: {memberId}</text>
              <text>Latest Class: {latestClass}</text>
              <select>
                <option>More Classes ({totalClassesCount})</option>
                {userClasses.split(", ").map((className, i) => (
                  <option key={i}>{className}</option>
                ))}
              </select>
            </div>
          ) : null}
        </div>
      </foreignObject>
    </g>
  );
};

export const generateCustomNodeData = (
  studentData: CustomStudentData,
): RawNodeDatum => {
  const {
    id,
    preferredNickName,
    englishFirstName,
    englishLastName,
    userClass,
  } = studentData;

  let totalClassesCount = 0;
  let latestClass = "-";
  let formattedUserClasses = "-";

  if (userClass && userClass.length > 0) {
    totalClassesCount = userClass.length;
    if (typeof userClass[0] === "string") {
      latestClass = userClass[userClass.length - 1] as unknown as string;
      formattedUserClasses = userClass.join(", ");
    } else {
      latestClass = userClass[userClass.length - 1].class.name;
      formattedUserClasses = userClass
        .map((classDetail) => classDetail.class.name)
        .join(", ");
    }
  }

  const nodeData: RawNodeDatum = {
    name: preferredNickName,
    attributes: {
      id,
      preferredNickName,
      englishFirstName,
      englishLastName,
      latestClass,
      totalClassesCount,
      userClasses: formattedUserClasses,
    },
    children: [],
  };

  return nodeData;
};

export default CustomTreeNode;
