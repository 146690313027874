import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  deleteEAVersion,
  getEAVersionList,
  setEACurrentPage,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import { Button } from "@mui/material";
import DeletePopup from "../../Components/common/Popups/DeletePopup";
import AddExpertAdvisorVersionModal from "../../Components/ExpertAdvisor/AddVersionModal";
import NoDatFound from "../../Components/common/NoDatFound";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import Pagination from "../../Components/common/Paginate/Pagination";
import { FormattedMessage } from "react-intl";
import { formatDate, saveFile } from "../../util/utils";
import { ProgressDownload } from "../../Components/common/ProgressDownload";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

const EAVersion = () => {
  const urlParams = useParams();
  const dispatch = useDispatch();
  const [deletePopup, setDeletePopup] = useState({ modal: false, id: 0 });
  const [selectedEAVersion, setSelectedEAVersion] = useState({
    modal: false,
    EAV: {},
  });
  const eaVersion =
    useSelector((state: Store) => state.expertAdvisorReducer.eaVersion) ||
    ([] as any);
  const isLoading = useSelector(
    (state: Store) => state.expertAdvisorReducer.isLoading,
  );
  const btnRef = useRef<any>();

  const [Load, setLoad] = useState<any>([]);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const totalRecord = useSelector(
    (state: Store) => state.expertAdvisorReducer.totalRecord,
  ) as any;
  const currentPage = useSelector(
    (state: Store) => state.expertAdvisorReducer.currentPage,
  ) as any;

  const onPageChange = (pageNum: number) => {
    setPageNumber(pageNum);
    dispatch(setEACurrentPage(pageNum));
  };

  useEffect(() => {
    dispatch(setEACurrentPage(1));
  }, []);

  useEffect(() => {
    setPageNumber(currentPage);
  }, [currentPage]);

  useEffect(() => {
    const { id } = urlParams;
    if (id && parseInt(id) > 0) {
      dispatch(getEAVersionList(false, id, pageNumber, pageLimit));
    }
  }, [pageNumber, pageLimit]);

  const deleteComment = () => {
    const { id } = urlParams;

    dispatch(deleteEAVersion(true, deletePopup.id, id));
    setDeletePopup({ modal: false, id: 0 });
  };

  const onDonwloadButtonClick = (id: any, url: any) => {
    if (!Load.includes(`${id}`)) {
      document.getElementById(`${id}1`)?.classList.remove("progress-hide");
      console.log("btnRef.current", btnRef);
      if (btnRef.current) {
        btnRef?.current.downloadFiles(url, `${id}`);
        setLoad((prv: any) => [...prv, `${id}`]);
      }
    }
  };

  return (
    <div className="content-wrapper">
      <div className="">
        <div className="d-flex justify-content-end p-3">
          <a
            href="#"
            onClick={() => {
              setSelectedEAVersion({ modal: true, EAV: {} });
            }}
            type="button"
            className="btn btn-primary"
          >
            <FormattedMessage
              id="head.addVersion"
              defaultMessage="Add Version"
            />{" "}
          </a>
        </div>
        <div className="table-responsive">
          {isLoading && <CenterLoader />}

          {eaVersion && eaVersion.length > 0 ? (
            <>
              {" "}
              <table className="table table-hover m-0">
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage
                        id="table.head.ID"
                        defaultMessage="ID"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="table.head.Version"
                        defaultMessage="Version"
                      />{" "}
                    </th>
                    <th>
                      <FormattedMessage
                        id="table.head.Date"
                        defaultMessage="Date"
                      />{" "}
                    </th>
                    <th>
                      <FormattedMessage
                        id="table.head.LibraryVersion"
                        defaultMessage="Library Version"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="table.head.Download"
                        defaultMessage="Download"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="table.head.Manual"
                        defaultMessage="Manual"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="table.head.Archive"
                        defaultMessage="Manual"
                      />
                    </th>
                    <th>
                      <FormattedMessage id="page.Edit" defaultMessage="Edit" />
                    </th>
                    <th>
                      <FormattedMessage
                        id="page.Delete"
                        defaultMessage="Delete"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {eaVersion &&
                    eaVersion.length &&
                    eaVersion.map((i: any) => (
                      <tr>
                        <td>{i.id}</td>
                        <td>{i.version}</td>
                        <td>{formatDate(i.createdAt, "DD-MM-YYYY")}</td>
                        <td>{i.libraryVersion.version}</td>
                        <td>
                          {" "}
                          {i.download_path && (
                            <ProgressDownload
                              Load={Load}
                              setLoad={setLoad}
                              elId={`p${i?.id}1`}
                              ref={btnRef}
                            >
                              <Button
                                id={`p${i.id}`}
                                onClick={() =>
                                  onDonwloadButtonClick(
                                    `p${i.id}`,
                                    i?.download_path,
                                  )
                                }
                              >
                                <FormattedMessage
                                  id="head.DownloadTool"
                                  defaultMessage="Download Tool"
                                />
                              </Button>
                            </ProgressDownload>
                          )}
                        </td>
                        <td>
                          {i.manual_path && (
                            <ProgressDownload
                              Load={Load}
                              setLoad={setLoad}
                              elId={`m${i?.id}1`}
                              ref={btnRef}
                            >
                              <Button
                                id={`m${i.id}`}
                                onClick={() =>
                                  onDonwloadButtonClick(
                                    `m${i.id}`,
                                    i?.manual_path,
                                  )
                                }
                              >
                                <FormattedMessage
                                  id="head.DownloadManual"
                                  defaultMessage="Download Manual"
                                />
                              </Button>
                            </ProgressDownload>
                          )}
                        </td>
                        <td align="center">
                          <input type="checkbox" checked={i.isArchive} />{" "}
                        </td>
                        <td>
                          <Button
                            variant="outlined"
                            color="info"
                            onClick={() =>
                              setSelectedEAVersion({ modal: true, EAV: i })
                            }
                          >
                            <FormattedMessage
                              id="page.Edit"
                              defaultMessage="Edit"
                            />
                          </Button>
                        </td>
                        <td>
                          {" "}
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() =>
                              setDeletePopup({ modal: true, id: i.id })
                            }
                          >
                            <FormattedMessage
                              id="page.Delete"
                              defaultMessage="Delete"
                            />
                          </Button>{" "}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          ) : (
            <p>{!isLoading && <NoDatFound />}</p>
          )}
        </div>
        {totalRecord > 10 && eaVersion.length > 0 && (
          <>
            <FormControl className="pagelimit">
              <FormLabel id="demo-row-radio-buttons-group-label">
                <FormattedMessage id="page_size" defaultMessage="Page Size" />
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={pageLimit}
                onChange={(e: any) => {
                  setPageLimit(e.target.value);
                  setPageNumber(1);
                }}
              >
                <FormControlLabel value={10} control={<Radio />} label="10" />
                <FormControlLabel value={20} control={<Radio />} label="20" />
                <FormControlLabel value={50} control={<Radio />} label="50" />
              </RadioGroup>
            </FormControl>
            <Pagination
              itemsPerPage={pageLimit}
              totalPages={totalRecord}
              onPageChage={onPageChange}
              page={pageNumber - 1}
            />
          </>
        )}
      </div>

      {selectedEAVersion.modal && (
        <>
          <AddExpertAdvisorVersionModal
            onModalClose={() => setSelectedEAVersion({ modal: false, EAV: {} })}
            EAId={urlParams.id}
            selectedEAV={selectedEAVersion.EAV}
          />
        </>
      )}

      {deletePopup && (
        <DeletePopup
          onClose={() => setDeletePopup({ modal: false, id: 0 })}
          onDeleteClick={() => deleteComment()}
          open={deletePopup.modal}
        />
      )}
    </div>
  );
};
export default EAVersion;
