import React, { useEffect, useState } from "react";
import User from "../assets/img/user.jpg";
import NoImage from "../assets/img/image.png";
import { dispatch } from "../redux";
import { getQR } from "../redux/action";
import { useSelector } from "react-redux";
import { Store } from "../redux/Actions";
import { formatDate } from "../util/utils";
import CenterLoader from "../Components/common/CenterLoader/CenterLoader";
import { FormattedMessage } from "react-intl";
import { BsChevronLeft } from "react-icons/bs";
import { Link } from "react-router-dom";

const Profile = () => {
  const [editProfile, setEditProfile] = useState(false);
  const myInfo = useSelector((state: Store) => state.userDataReducer.myInfo);
  const isLoading = useSelector(
    (state: Store) => state.userDataReducer.isLoading,
  );

  const local = useSelector((state: Store) => state.userDataReducer.local);

  useEffect(() => {
    dispatch(getQR());
  }, []);

  return (
    <>
      {/* Edit Profile */}
      {!editProfile ? (
        <div className="content-wrapper">
          <div className="content">
            {isLoading && <CenterLoader />}
            <header className="page-header">
              <div className="d-flex align-items-center">
                <div className="mr-auto">
                  <h1>
                    <FormattedMessage
                      id="head.Profile"
                      defaultMessage="Profile"
                    />
                  </h1>
                </div>
                {/* <div className="m-l-10">
                  <button
                    type="button"
                    onClick={() => setEditProfile(true)}
                    className="btn btn-primary"
                  >
                    Edit Profile
                  </button>
                </div> */}
                <div className="m-l-10 common-right-button">
                  <Link to="/home" className="btn btn-secondary">
                    <i className="back-button">
                      <BsChevronLeft /> &nbsp;
                    </i>
                    <FormattedMessage id="page.back" defaultMessage="Back" />
                  </Link>
                </div>
              </div>
            </header>
            <section className="page-content container-fluid">
              <div className="card">
                <div className="card-body">
                  <div className="media">
                    {myInfo?.avatar ? (
                      <img
                        style={{ height: "130px" }}
                        className="align-self-start mr-5 ml-3 rounded-circle img-thumbnail o-cover"
                        src={myInfo.avatar}
                        alt="profile-image"
                        width="130"
                        height="180"
                      />
                    ) : (
                      <img
                        src={NoImage}
                        className="align-self-start mr-5 ml-3 rounded-circle img-thumbnail o-cover"
                        alt="profile-image"
                        width="130"
                        height="180"
                      />
                    )}
                    <div className="media-body">
                      <div className="row">
                        <div className="col-lg-12 col-xl-10">
                          <h2 className="font-weight-500  mt-0 mb-3 text-info">
                            {/* {local === 'en' */}
                            {myInfo?.englishFirstName !== undefined
                              ? myInfo?.englishFirstName
                              : "" || ""}{" "}
                            {"  " + myInfo?.englishLastName !== undefined
                              ? myInfo?.englishLastName
                              : ""}
                          </h2>

                          <ul className="list-unstyled text-left row mb-0">
                            {/* <li className="mb-3 col-md-6">
                              <label className="text-muted mb-1">
                                {" "}
                                <FormattedMessage
                                  id="profile.ChineseFullName"
                                  defaultMessage="Chinese First Name"
                                />
                              </label>
                              :{" "}
                              {myInfo?.chineseFirstName ? myInfo?.chineseFirstName : '-'}
                              {myInfo?.chineseLastName ? myInfo?.chineseLastName : '-'}
                            </li> */}

                            <li className="mb-3 col-md-6">
                              <label className="text-muted mb-1">ID</label>:{" "}
                              {myInfo?.id || ""}
                            </li>
                            <li className="mb-3 col-md-6">
                              <label className="text-muted mb-1">
                                <FormattedMessage
                                  id="profile.MobileNumber"
                                  defaultMessage="Mobile Number"
                                />
                              </label>
                              : {myInfo?.dialCode ? "+" : ""}{" "}
                              {`${myInfo?.dialCode || ""} ${
                                myInfo?.phoneNumber || ""
                              }`}
                            </li>
                            <li className="mb-3 col-md-6">
                              <label className="text-muted mb-1">
                                <FormattedMessage
                                  id="profile.Email"
                                  defaultMessage="Email"
                                />
                              </label>
                              : {myInfo?.email || ""}
                            </li>
                            <li className="mb-3 col-md-6">
                              <label className="text-muted mb-1">
                                <FormattedMessage
                                  id="profile.EnglishName"
                                  defaultMessage="English Name"
                                />{" "}
                              </label>
                              :{" "}
                              {myInfo?.englishFirstName
                                ? myInfo?.englishFirstName
                                : ""}{" "}
                              {" " + myInfo?.englishLastName
                                ? myInfo?.englishLastName
                                : ""}
                            </li>
                            <li className="mb-3 col-md-6">
                              <label className="text-muted mb-1">
                                <FormattedMessage
                                  id="profile.ChineseFullName"
                                  defaultMessage="Chinese Full Name"
                                />{" "}
                              </label>
                              :{" "}
                              {myInfo.chineseFirstName
                                ? myInfo?.chineseFirstName
                                : ""}{" "}
                              {" " + myInfo.chineseLastName
                                ? myInfo?.chineseLastName
                                : ""}
                            </li>
                            <li className="mb-3 col-md-6">
                              <label className="text-muted mb-1">
                                <FormattedMessage
                                  id="profile.DOB"
                                  defaultMessage="Date of Birth"
                                />{" "}
                              </label>
                              :{" "}
                              {myInfo?.DOB
                                ? formatDate(myInfo?.DOB || "", "DD-MM-YYYY")
                                : ""}
                            </li>
                            <li className="mb-3 col-md-6">
                              <label className="text-muted mb-1">
                                <FormattedMessage
                                  id="profile.Address"
                                  defaultMessage="Address"
                                />
                              </label>
                              : {myInfo?.address || "N/A"}
                            </li>
                            <li className="mb-3 col-md-6">
                              <label className="text-muted mb-1">
                                <FormattedMessage
                                  id="profile.ReferrerCode"
                                  defaultMessage="My Referrer Code"
                                />
                              </label>
                              : {myInfo?.discountCode || "-N/A-"}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      ) : (
        <div className="content-wrapper">
          <div className="content">
            <header className="page-header">
              <div className="d-flex align-items-center">
                <div className="mr-auto">
                  <h1>Edit Profile</h1>
                </div>
                <div className="m-l-10">
                  <button
                    className="btn btn-secondary"
                    onClick={() => setEditProfile(false)}
                  >
                    <i className="">
                      <BsChevronLeft /> &nbsp;
                    </i>{" "}
                    Back
                  </button>
                </div>
              </div>
            </header>
            <section className="page-content container-fluid">
              <div className="card">
                <form className="form-horizontal">
                  <div className="card-body">
                    <div className="d-flex flex-wrap">
                      <div className="left-form-content">
                        <div
                          className="fileinput text-center fileinput-new"
                          data-provides="fileinput"
                        >
                          <div className="btn-file mt-3">
                            <div
                              className="thumbnail fileinput-new uploaded-user-image rounded-circle"
                              style={{ height: "150px", width: "150px" }}
                            >
                              {myInfo?.avatar ? (
                                <img
                                  style={{ height: "150%", width: "150%" }}
                                  src={myInfo.avatar}
                                  alt="Image"
                                />
                              ) : (
                                <img
                                  src={NoImage}
                                  alt="Image"
                                  style={{ height: "150%", width: "150%" }}
                                />
                              )}
                            </div>
                            <div className="clearfix"></div>
                            <button className="fileinput-new btn btn-primary2 btn-sm btn-file mt-3">
                              {" "}
                              Browse Image{" "}
                            </button>
                            <input type="hidden" value="" name="..." />
                            <input type="hidden" value="" name="Users[image]" />
                            <input
                              type="file"
                              file-model="myFile"
                              name=""
                              accept=""
                            />
                            <div
                              className="fileinput-preview fileinput-exists thumbnail uploaded-user-image rounded-circle"
                              style={{ height: "150px", width: "150px" }}
                            ></div>
                          </div>
                          <div className="text-center">
                            <button
                              className="btn btn-link btn-sm fileinput-exists mt-3"
                              data-dismiss="fileinput"
                            >
                              {" "}
                              Remove{" "}
                            </button>
                          </div>
                          <div className="clearfix mt-3">
                            <p className="upload-img-label text-muted">
                              *Recommended Size:
                              <br />
                              Minimum 250 * 250
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row flex">
                        <div className="col-xl-8">
                          <div className="form-group">
                            <label>Full name</label>
                            <input
                              type="text"
                              className="form-control"
                              value="John Smith"
                            />
                          </div>
                          <div className="form-row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="control-label">
                                  Mobile Number
                                </label>
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">+1</div>
                                  </div>
                                  <input
                                    type="text"
                                    className="form-control"
                                    // placeholder=""
                                    value="987 654 3210"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="control-label">Email</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  value="admin@propane-bros.com"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="control-label">Address</label>
                            <textarea
                              className="form-control"
                              value="656 Chatham Way, Washington, MD, Maryland, 20008, USA"
                              // placeholder=""
                            >
                              {" "}
                            </textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer bg-light text-right">
                    <button
                      type="button"
                      className="btn btn-secondary clear-form mr-2"
                      onClick={() => setEditProfile(false)}
                    >
                      Cancel
                    </button>
                    <button type="button" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
