import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdminRolesUserPermissionsList } from "../../redux/action";
import { Store } from "../../redux/Actions";
import { formatDate, formatDateTime } from "../../util/utils";
import NoDatFound from "../../Components/common/NoDatFound";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import { FormattedMessage } from "react-intl";
import ReactPaginate from "react-paginate";
import UserSelectionAutoComplete from "../../Components/Selection/UserSelectionAutoComplete";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

const UsersPermissionsList = () => {
  const dispatch = useDispatch();
  const adminUserPermissionList: any = useSelector(
    (state: Store) => state.adminRolesReducer.adminUserPermissionList,
  ) as any;

  const permenetEa: any = useSelector(
    (state: Store) => state.adminRolesReducer.permenetEa,
  ) as any;
  const isLoading: any = useSelector(
    (state: Store) => state.adminRolesReducer.isLoading,
  ) as any;
  const [memberId, setMemberId] = useState(0);

  const [permissionList, setPermissionList] = useState([]);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(
    parseInt(process.env.REACT_APP_RECORDS_PER_PAGE || "10", 10),
  );

  const totalPages = useSelector(
    (state: Store) => state.adminRolesReducer.totalRecord,
  ) as any;

  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    setPageNumber(1);
    getTools();
    setPageCount(Math.ceil(totalPages / pageLimit));
  }, [adminUserPermissionList, pageLimit]);

  useEffect(() => {
    getTools();
  }, [pageNumber, pageLimit]);

  useEffect(() => {
    setPermissionList([]);
  }, []);

  useEffect(() => {
    if (memberId > 0) {
      getList();
    }
  }, [memberId]);

  const getTools = () => {
    const tools = Object.assign([], adminUserPermissionList);

    if (tools.length > 0) {
      const start = pageNumber * pageLimit - pageLimit;
      const end = start + pageLimit;

      const t = tools.slice(start, end);
      setPermissionList(() => t);
    } else {
      setPermissionList([]);
    }
  };

  const getList = () => {
    if (!isNaN(memberId) && memberId > 0) {
      dispatch(getAdminRolesUserPermissionsList(false, memberId, pageNumber));
    }
  };

  const handlePageClick = (event: any) => {
    setPageCount(Math.ceil(totalPages / pageLimit));

    setPageNumber(event.selected + 1);
  };

  const getUserIssued = (arrV: any) => {
    //aa
    let str = ""; // `${arrV?.version}: Demo:${arrV?.demo_account} Live:${arrV?.live_account}`;
    (arrV || []).map((i: any) => {
      // const acType = i.accountType == "D" ? 'Demo' : 'Live';
      // const acNum = i.accountNumber;

      const version = i.version;
      const demoV = i.demo_account;
      const liveV = i.live_account;
      let d = demoV > 0 ? "demo: " + demoV : "";
      const l = liveV > 0 ? "Live: " + liveV : "";
      d = demoV > 0 && liveV > 0 ? d + "; " : d;
      if (demoV > 0 || liveV > 0) {
        str = str + "(" + version + " : " + d + l + "), ";
      }
      // str = str + ', (' + version + ': Demo:' + demoV + ' Live: ' + liveV + '), '
    });
    // str = str.slice(1, -1);
    str = str.slice(0, -1);
    str = str.slice(0, -1);
    return str;
  };

  return (
    <>
      <div className="d-flex justify-content-end p-3 member-select">
        <div className="d-flex align-items-center nowrap">
          {/* <label className="text-muted m-0">
            {" "}
            <FormattedMessage
              id="table.head.MemberID"
              defaultMessage="Member ID"
            />
            :{" "}
          </label>{" "} */}
          <UserSelectionAutoComplete
            getList={(memberId: any) => {
              setMemberId(memberId);
            }}
            users={[]}
            onClear={() => {
              setPermissionList([]);
              setMemberId(0);
            }}
            refs={() => {
              /** */
            }}
          />
          {/* <input
            type="text"
            value={memberId}
            onChange={(e: any) => setMemberId(e.target.value)}
            className="form-control m-l-10"
          />
          <Button
            onClick={getList}
            className="d-flex align-items-center m-l-10 h-35"
          >
            <>
              <FormattedMessage id="table.head.Go" defaultMessage="GO" />
              {isLoading && <Loader />}
            </>
          </Button> */}
        </div>
      </div>
      <div className="table-responsive">
        {/* Object.entries(permenetEa?.EA || {}).length > 0 && */}
        {!isNaN(memberId) && memberId > 0 && (
          <div className="p-3">
            <span>
              <FormattedMessage
                id="table.head.MemberID"
                defaultMessage="Member ID"
              />
            </span>
            : {memberId} <br />
            <span>
              <FormattedMessage
                id="head.PermanentID"
                defaultMessage="Permanent ID"
              />
            </span>
            : {permenetEa?.EA?.id} <br />
            <span>
              <FormattedMessage
                id="head.PermanentEA"
                defaultMessage="Permanent EA"
              />
            </span>
            : {permenetEa?.EA?.name} <br />
            <span>
              <FormattedMessage
                id="head.MemberExpiryDate"
                defaultMessage="Member Expiry Date"
              />
            </span>
            : {formatDate(permenetEa.expiryDate, "DD/MM/YYYY")}
          </div>
        )}
        {isLoading && <CenterLoader />}

        {permissionList.length > 0 ? (
          <>
            <table className="table table-hover m-0">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage
                      id="table.head.ToolID"
                      defaultMessage="Tool ID"
                    />{" "}
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.Class"
                      defaultMessage="Class"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.MemberType"
                      defaultMessage="Member Type"
                    />{" "}
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.Type"
                      defaultMessage="Type"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.ToolName"
                      defaultMessage="Tool Name"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.Issued"
                      defaultMessage="Issued"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.Active"
                      defaultMessage="Active"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.ExpiryDate"
                      defaultMessage="Expiry Date"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.IssuedAccount"
                      defaultMessage="Issued Account (Version:Type)"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {permissionList.length &&
                  permissionList.map((i: any) => (
                    <tr>
                      <td>{i.toolId}</td>
                      <td>{i.class.join(",")}</td>
                      <td>{i.userType}</td>
                      <td>{i.type}</td>
                      <td>{i.name}</td>
                      <td>{i.issued}</td>
                      {/* <td>{formatDateMM(i.issueDate, "YYYY-MM-DD")}</td> */}
                      <td>{i.isActive ? "Yes" : "No"}</td>
                      <td>
                        {i?.expiryDate
                          ? // ? formatDateMM(i?.expiryDate, "YYYY-MM-DD")
                            formatDateTime(i?.expiryDate)
                          : "-"}
                      </td>
                      <td>{getUserIssued(i.version)}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        ) : (
          <p>{!isLoading && <NoDatFound />}</p>
        )}
      </div>
      {totalPages > 10 && permissionList.length > 0 && (
        <>
          <FormControl className="pagelimit">
            <FormLabel id="demo-row-radio-buttons-group-label">
              <FormattedMessage id="page_size" defaultMessage="Page Size" />
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={pageLimit}
              onChange={(e: any) => {
                setPageLimit(parseInt(e.target.value || "10"));
                setPageNumber(1);
              }}
            >
              <FormControlLabel value={10} control={<Radio />} label="10" />
              <FormControlLabel value={20} control={<Radio />} label="20" />
              <FormControlLabel value={50} control={<Radio />} label="50" />
            </RadioGroup>
          </FormControl>
          <ReactPaginate
            nextLabel={
              <FormattedMessage id="pagination.Next" defaultMessage="next" />
            }
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            marginPagesDisplayed={3}
            pageCount={pageCount}
            forcePage={pageNumber - 1}
            previousLabel={
              <FormattedMessage
                id="pagination.Previous"
                defaultMessage="previous"
              />
            }
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination page-list"
            activeClassName="active"
          />
        </>
      )}
    </>
  );
};

export default UsersPermissionsList;
