import PropTypes from "prop-types";
import { Button, Dialog, DialogTitle, List } from "@mui/material";
import { FormattedMessage } from "react-intl";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";

const ChangeVpsStatusModal = ({ onClose, open, onDeleteClick, text }: any) => {
  const handleClose = () => {
    onClose(false);
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        {
          <>
            {text == "terminate" && (
              <>
                <FormattedMessage
                  id={`vps.msg.${text}1`}
                  defaultMessage="Do you really want to start the VPS machine?"
                />
                <br />
              </>
            )}
            <FormattedMessage
              id={`vps.msg.${text}`}
              defaultMessage="Do you really want to start the VPS machine?"
            />
          </>
        }
      </DialogTitle>
      <List
        sx={{ pt: 0 }}
        style={{ textAlign: "end", padding: "12px 12px 12px 12px" }}
      >
        <Button
          onClick={handleClose}
          variant="outlined"
          startIcon={<CloseIcon />}
          className="mr-2"
          color="error"
        >
          <FormattedMessage id="page.No" defaultMessage="No" />
        </Button>
        <Button
          onClick={onDeleteClick}
          variant="outlined"
          startIcon={<DoneIcon />}
          color="success"
        >
          <FormattedMessage id="page.Yes" defaultMessage="Yes" />
        </Button>
      </List>
    </Dialog>
  );
};

ChangeVpsStatusModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  text: PropTypes.any,
};

export default ChangeVpsStatusModal;
