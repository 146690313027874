/* eslint-disable no-case-declarations */
import {
  GET_RENTS_LIST,
  GET_RENTS_CATEGORY_LIST,
  ADD_RENTS_LIST,
  EDIT_RENTS_LIST,
} from "../constants/action-types";
import { RentsData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";
// import { Posts } from "../Actions";

const initialState = {
  progress: false,
  isLoading: false,
  onSaveLoading: false,
  RentsList: [],
  RentsTypeList: [],
  totalRecord: 0,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: RentsData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case GET_RENTS_LIST.GET_RENTS_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_RENTS_LIST.GET_RENTS_LIST_SUCCESS:
        const allRes: any = action.payload;
        return {
          ...state,
          RentsList: allRes.data, // action.payload,
          totalRecord: allRes.totalCount || 1, // action.payload,
          isLoading: false,
        };

      case GET_RENTS_LIST.GET_RENTS_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_RENTS_CATEGORY_LIST.GET_RENTS_CATEGORY_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_RENTS_CATEGORY_LIST.GET_RENTS_CATEGORY_LIST_SUCCESS:
        return {
          ...state,
          RentsTypeList: action.payload,
          isLoading: false,
        };

      case GET_RENTS_CATEGORY_LIST.GET_RENTS_CATEGORY_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_RENTS_LIST.ADD_RENTS_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case ADD_RENTS_LIST.ADD_RENTS_LIST_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_RENTS_LIST.ADD_RENTS_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case EDIT_RENTS_LIST.EDIT_RENTS_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case EDIT_RENTS_LIST.EDIT_RENTS_LIST_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case EDIT_RENTS_LIST.EDIT_RENTS_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      default:
        return state;
    }
  },
);

export default reducer;
