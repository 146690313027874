import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import Loader from "../common/Loader";
import draftToHtml from "draftjs-to-html";
import { dispatch } from "../../redux";
import { convertToRaw, EditorState } from "draft-js";
import { Switch } from "@mui/material";
import { Modal } from "react-bootstrap";
import { AddFaqSchema } from "../../util/validationSchema";
import { FormattedMessage } from "react-intl";
import { FaTimesCircle } from "react-icons/fa";
import EditorContainer from "../RichTextEditor/Editor";
import Select from "react-select";
import { addFaqList, getFAQCategoryList } from "../../redux/action/faqActions";

const AddFaqModal = ({ onModalClose, faq }: any) => {
  const [ButtonClick, setButtonClick] = useState<boolean>(false);
  const [editorState, setEditorState] = useState<any>(
    EditorState.createEmpty(),
  );

  const [selectedClass, setselectedClass] = useState<Record<string, any>>({});

  const isAPILoading = useSelector(
    (state: Store) => state.faqReducer.onSaveLoading,
  );

  const FaqCategoryList = useSelector(
    (state: Store) => state.faqReducer.FAQCategoryList,
  );

  const Filterfaqcategorylist = FaqCategoryList.filter(
    (data: any) => data.isActive == true,
  );

  useEffect(() => {
    dispatch(getFAQCategoryList(false));
  }, []);

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    formik.setFieldValue(
      "answer",
      draftToHtml(convertToRaw(editorState.getCurrentContent())),
    );
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      faqCategory: "",
      question: "",
      answer: "",
      code: "",
      isActive: false,
    },
    validationSchema: AddFaqSchema,
    onSubmit: (values: Record<string, any>) => {
      if (!ButtonClick) {
        if (Object.keys(faq).length <= 0) {
          dispatch(addFaqList(true, values));
        }
        setButtonClick(true);
      }
    },
  });

  useEffect(() => {
    if (!isAPILoading && ButtonClick) {
      onModalClose();
      setButtonClick(false);
    }
  }, [isAPILoading, ButtonClick]);

  const { touched, errors } = formik;
  return (
    <Modal centered show={true} onHide={() => onModalClose(false)}>
      <Modal.Header>
        <h4 className="modal-title" id="AddCourseLabel">
          <FormattedMessage id="Faq.AddFaq" defaultMessage="Add FAQ" />
        </h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onModalClose}
        >
          <FaTimesCircle />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label className="control-label">
            <FormattedMessage
              id="Faq.FaqCategory"
              defaultMessage="FaqCategory"
            />
          </label>
          <Select
            name="class_group"
            value={selectedClass}
            onChange={(option: any) => {
              formik.setFieldValue("faqCategory", option.id);
              setselectedClass(option);
            }}
            onBlur={() => formik.setFieldTouched("faqCategory", true)}
            options={Filterfaqcategorylist}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id}
            className="custom-select-dropdown multi w-100p"
            classNamePrefix="react-select-dropdown"
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />

          {errors.faqCategory && touched.faqCategory && (
            <div className="text-error">
              <FormattedMessage
                id={"script.faqCategory.required"}
                defaultMessage="This field is required"
              />
            </div>
          )}
        </div>
        <div className="form-group">
          <label className="control-label">
            <FormattedMessage id="Faq.Question" defaultMessage="Question" />
          </label>
          <input
            type="text"
            name="question"
            value={formik.values.question}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="form-control"
          />
          {errors.question && touched.question && (
            <div className="text-error">
              <FormattedMessage
                id={"script.question.required"}
                defaultMessage="This field is required"
              />
            </div>
          )}
        </div>
        <div className="form-group">
          <label className="control-label">
            <FormattedMessage id="Faq.Answer" defaultMessage="Answer" />
          </label>
          <EditorContainer
            onEditorStateChange={onEditorStateChange}
            editorState={editorState}
            toolbar={true}
            editable={true}
            height={"200px"}
            overflowY={"scroll"}
            border={"1px solid #dfe7f3"}
          />
          {errors.answer && touched.answer && (
            <div className="text-error">
              <FormattedMessage
                id={"script.answer.required"}
                defaultMessage="This field is required"
              />
            </div>
          )}
        </div>
        <div className="form-group">
          <label className="control-label">
            <FormattedMessage id="Faq.Code" defaultMessage="Code" />
          </label>
          <input
            type="text"
            name="code"
            value={formik.values.code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="form-control"
          />
          {errors.code && touched.code && (
            <div className="text-error">
              <FormattedMessage
                id={"script.code.required"}
                defaultMessage="This field is required"
              />
            </div>
          )}
        </div>

        <div className="form-group">
          <FormattedMessage id="Faq.Isactive" defaultMessage="isActive" />
          <Switch
            checked={formik.values.isActive}
            onChange={() => {
              formik.setFieldValue("isActive", !formik.values.isActive);
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          onClick={onModalClose}
        >
          <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
        </button>
        <button
          type="button"
          className="btn btn-primary"
          style={{ display: "inherit" }}
          onClick={() => formik.handleSubmit()}
          data-dismiss="modal"
        >
          <FormattedMessage id="page.submit" defaultMessage="Submit" />
          {isAPILoading && <Loader />}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddFaqModal;
