// import Select from "react-select";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  // getAdminRolesList,
  getAdminRolesUserEAAccountList,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
// import { VerticalDots } from "../../util/iconStyle";
import { Button } from "react-bootstrap";
import { formatDateMM } from "../../util/utils";
import NoDatFound from "../../Components/common/NoDatFound";
import Loader from "../../Components/common/Loader";
import { FormattedMessage } from "react-intl";
import ReactPaginate from "react-paginate";
import UserSelectionAutoComplete from "../../Components/Selection/UserSelectionAutoComplete";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import Pagination from "../../Components/common/Paginate/Pagination";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";

const UserEAAccountList = () => {
  const dispatch = useDispatch();
  const adminUserEAAccountList: any = useSelector(
    (state: Store) => state.adminRolesReducer.adminUserEAAccountList,
  ) as any;
  const isLoading: any = useSelector(
    (state: Store) => state.adminRolesReducer.isLoading,
  ) as any;
  const [memberId, setMemberId] = useState(0);

  const totalPages: any = useSelector(
    (state: Store) => state.adminRolesReducer.totalRecord,
  ) as any;
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const [eaAccountList, setEAAccountList] = useState([]);

  const getList = () => {
    dispatch(
      getAdminRolesUserEAAccountList(false, memberId, pageNumber, pageLimit),
    );
  };

  useEffect(() => {
    setPageCount(Math.ceil(totalPages / pageLimit));
  }, [pageLimit, totalPages]);

  useEffect(() => {
    setEAAccountList(adminUserEAAccountList);
  }, [adminUserEAAccountList]);

  const onPageChange = (pageNum: number) => {
    setPageNumber(pageNum);
  };

  useEffect(() => {
    setEAAccountList([]);
    if (memberId > 0) {
      getList();
    }
  }, [memberId, pageNumber, pageLimit]);

  return (
    <>
      {isLoading && <CenterLoader />}
      <div className="d-flex justify-content-end p-3 member-select">
        <div className="d-flex align-items-center nowrap">
          {/* <label className="text-muted m-0">
            {" "}
            <FormattedMessage
              id="table.head.MemberID"
              defaultMessage="Member ID"
            />
            :{" "}
          </label>{" "}
          <input
            type="text"
            onChange={(e: any) => setMemberId(e.target.value)}
            className="form-control m-l-10"
          />
          <Button
            onClick={getList}
            className="d-flex align-items-center m-l-10 h-35"
          >
            {" "}
            <FormattedMessage id="table.head.Go" defaultMessage="GO" />
            {isLoading && <Loader />}
          </Button> */}
          <UserSelectionAutoComplete
            getList={(memberId: any) => {
              setMemberId(memberId);
            }}
            users={[]}
            onClear={() => {
              setEAAccountList([]);
              setMemberId(0);
            }}
            refs={() => {
              /** */
            }}
          />
        </div>
      </div>
      <div className="table-responsive">
        {eaAccountList && eaAccountList.length > 0 ? (
          <>
            {" "}
            <table className="table table-hover m-0">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="table.head.ID" defaultMessage="ID" />{" "}
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.AccountNumber"
                      defaultMessage="Account Number"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.Type"
                      defaultMessage="Type"
                    />
                  </th>
                  <th>
                    <FormattedMessage id="table.head.EA" defaultMessage="EA" />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.Version"
                      defaultMessage="Version"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.Active"
                      defaultMessage="Active"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.CreatedDate"
                      defaultMessage="Created Date"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {eaAccountList &&
                  eaAccountList.length &&
                  eaAccountList.map((i: any) => (
                    <tr>
                      <td>{i.id}</td>
                      <td>{i.accountNumber}</td>
                      <td>
                        {i.accountType === "L"
                          ? "Live Account"
                          : "Demo Account"}
                      </td>
                      <td>
                        {i?.EA_version?.EA ? i?.EA_version?.EA?.name : ""}
                      </td>
                      <td>{i?.EA_version?.version}</td>
                      <td>{i?.isActive ? "Yes" : "No"}</td>
                      <td>{formatDateMM(i?.createdAt, "YYYY-MM-DD")}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        ) : (
          <NoDatFound />
        )}
      </div>
      {totalPages > 10 && eaAccountList.length > 0 && (
        <>
          <FormControl className="pagelimit">
            <FormLabel id="demo-row-radio-buttons-group-label">
              <FormattedMessage id="page_size" defaultMessage="Page Size" />
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={pageLimit}
              onChange={(e: any) => {
                setPageLimit(e.target.value);
                setPageNumber(1);
              }}
            >
              <FormControlLabel value={10} control={<Radio />} label="10" />
              <FormControlLabel value={20} control={<Radio />} label="20" />
              <FormControlLabel value={50} control={<Radio />} label="50" />
            </RadioGroup>
          </FormControl>
          <Pagination
            itemsPerPage={pageLimit}
            totalPages={totalPages}
            onPageChage={onPageChange}
            page={pageNumber - 1}
          />
        </>
      )}
    </>
  );
};

export default UserEAAccountList;
