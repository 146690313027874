import { combineReducers } from "redux";
import userDataReducer from "./userDataReducer";
import classReducer from "./classReducer";
import announcementsReducer from "./announcementsReducer";
import adminRolesReducer from "./adminRolesReducer";
import indicatorsReducer from "./indicatorsReducer";
import utilityReducer from "./utilityReducer";
import scriptsReducer from "./scriptsReducer";
import expertAdvisorReducer from "./expertAdvisorReducer";
import giftReducer from "./giftReducer";
import faqReducer from "./faqReducer";
import billReducer from "./billReducer";
import expressWayReducer from "./expressWayReducer";
import ticketReducer from "./ticketReducer";
import studentReducer from "./studentReducer";
import libraryReducer from "./libraryReducer";
import ActivityReducer from "./ActivityReducer";
import AdvertismentReducer from "./AdvertismentReducer";
import rentsReducer from "./rentsReducer";
import grantReducer from "./grantReducer";
import membershipReducer from "./membershipReducer";
import mediaUploadReducer from "./mediaUploadReducer";
import vpsMachineReducer from "./vpsMachineReducer";
import RolesManagementReducer from "./RolesManagementReducer";

// Combine all reducers as root reducer
export default combineReducers({
  userDataReducer,
  classReducer,
  announcementsReducer,
  adminRolesReducer,
  indicatorsReducer,
  utilityReducer,
  scriptsReducer,
  expertAdvisorReducer,
  giftReducer,
  faqReducer,
  billReducer,
  expressWayReducer,
  ticketReducer,
  studentReducer,
  libraryReducer,
  ActivityReducer,
  AdvertismentReducer,
  rentsReducer,
  grantReducer,
  membershipReducer,
  mediaUploadReducer,
  vpsMachineReducer,
  RolesManagementReducer,
});
