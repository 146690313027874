import { FormattedMessage } from "react-intl";

export const GemsAdvertisementsHeader = () => {
  return (
    <header className="page-header">
      <div className="d-flex align-items-center">
        <div className="mr-auto">
          <h1>
            <FormattedMessage
              id="Admin.Advertisements"
              defaultMessage="Admin Advertisements"
            />
          </h1>
        </div>
      </div>
    </header>
  );
};

export default GemsAdvertisementsHeader;
