import { Button, IconButton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  ExpressWayMemberAction,
  getClasses,
  getExpressWayMemberDetail,
  getIndustryTypeList,
  getJobTitleList,
  getRegistrationMaterial,
  setAlternativeNameList,
  setPasswordManual,
  UserMemberDenied,
  UserMemberModify,
} from "../../../redux/action";
import { useNavigate, useParams } from "react-router";
import { Store } from "../../../redux/Actions";
import { formatDate, isPositiveNumbersOnly } from "../../../util/utils";
import Select from "react-select";
import Loader from "../../../Components/common/Loader";
import { _UploadMedia } from "../../../service/MediaUpload/classes.service";
import noImage from "../../../assets/img/noImage.jpeg";
import { FormattedMessage, useIntl } from "react-intl";
import CenterLoader from "../../../Components/common/CenterLoader/CenterLoader";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  allAlternativeNameSchema,
  ApproveMemberSchema,
} from "../../../util/validationSchema";
import { useFormik } from "formik";
import { Cached } from "@mui/icons-material";
import Viewer from "react-viewer";
import { BsDash, BsPlus } from "react-icons/bs";
import Stack from "@mui/material/Stack";
import { toast } from "react-toastify";
import ImageWithOnClickModal from "../../../Components/common/ImageWithOnClickModal";

const indentityProof: any = [
  {
    label: "Hong Kong Identity Card",
    value: "Hong kong Id Card",
  },
  {
    label: "Passport",
    value: "Passport",
  },
];

export const CURTSYTITLE: any = {
  "0": "OTHERS",
  "1": "MR",
  "2": "MRS",
  "3": "MS",
  "4": "DR",
};

export const BUTTON_CLICK: any = {
  APPROVE: 0,
  MODIFY: 1,
  ASK_FOR_RESIGN: 2,
  REJECTED: 3,
  NONE: -1,
};

const curtsyTitle: any = [
  { title: "Mr.", value: 1 },
  { title: "Mrs.", value: 2 },
  { title: "Miss", value: 3 },
  { title: "Dr", value: 4 },
];

const MemberDetail = () => {
  const userDetails = useSelector(
    (state: Store) => state.expressWayReducer?.ewUserDetail,
  ) as any;
  const classData = useSelector(
    (state: Store) => state.expressWayReducer.ewClassDetail,
  ) as any;

  const isLoading = useSelector(
    (state: Store) => state.expressWayReducer.Loading.isLoading,
  ) as any;
  const dispatch = useDispatch();
  const urlParams = useParams();
  const [userDetail, setUserDetail] = useState<any>({});
  const [selectedClass, setSelectedClass] = useState<any>(null);
  const [passwordButton, setpasswordButton] = useState<any>(false);
  const [altButton, setAltButton] = useState<any>(false);
  const [img, setImg] = useState({ image: "", show: false });

  const industry = useSelector(
    (state: Store) => state.userDataReducer.industry,
  ) as any;
  const jobTitles = useSelector(
    (state: Store) => state.userDataReducer.jobTitles,
  ) as any;
  const paymentTypeLst = useSelector(
    (state: Store) => state.userDataReducer.paymentTypeLst,
  ) as any;
  const isApprove = useSelector(
    (state: Store) => state.expressWayReducer.isApprove,
  ) as any;
  const isLoadings = useSelector(
    (state: Store) => state.membershipReducer.isLoading,
  ) as any;
  const districtLst = useSelector(
    (state: Store) => state.userDataReducer.districtLst,
  ) as any;
  const local = useSelector((state: Store) => state.userDataReducer.local);

  const [fileLoading, setFileLoading] = useState(false);

  const attchRef = useRef<any>();
  const [selectedAttachment, setSelectedAttachment] = useState({
    avatar: "",
    idImage: "",
  });
  const [selectedAttachName, setSelectedAttachName] = useState("");
  const [buttonClick, setButtonClick] = useState(-1);
  const [action, setAction] = useState({});
  const [Loading, setLoading] = useState<any>(false);
  const [selectedCTitle, setSelectedCTitle] = useState(null);
  const [district, setDistrict] = useState({});

  const [selectedIndustry, setSelectedIndustry] = useState({});
  const [selectedJobTitle, setSelectedJobTitle] = useState({});
  const [selectedIndentityType, setSelectedIdentityType] = useState({});
  const [hide, setHide] = useState(false);
  const [accounts, setAccounts] = useState<any>({
    demoLimit: 0,
    liveLimit: 0,
  });
  const id: string = urlParams.id as string;

  const [accountsCreated, setAccountsCreated] = useState<any>({
    demoLimit: 0,
    liveLimit: 0,
  });
  const navigate = useNavigate();
  const [accountLimitError, setAccountLimitError] = useState({
    demoLimit: false,
    liveLimit: false,
  });

  useEffect(() => {
    setAccountLimitError(() => {
      const newState = { demoLimit: false, liveLimit: false };
      if (accounts.demoLimit - accountsCreated.demoLimit < 0) {
        newState.demoLimit = true;
      }
      if (accounts.liveLimit - accountsCreated.liveLimit < 0) {
        newState.liveLimit = true;
      }
      return newState;
    });
  }, [accounts, accountsCreated]);

  useEffect(() => {
    const id: string = urlParams.id as string;

    if (id && id.trim().length > 0) {
      dispatch(getExpressWayMemberDetail(false, id));
    }
    dispatch(getClasses(false, 0, { by: "createdAt", order: "ASC" }));
    dispatch(getIndustryTypeList(false, "en"));
    dispatch(getRegistrationMaterial(false, "en"));
  }, []);

  useEffect(() => {
    if (!isLoading && isApprove) {
      navigate("/users");
    }
  }, [isLoading, isApprove]);

  useEffect(() => {
    const id: string = urlParams.id as string;
    if (id.trim().length > 0) {
      if (userDetails) {
        // let mobile = userDetails.phoneNumber;
        if (userDetails.phoneNumber && userDetails.phoneNumber.length > 8) {
          // mobile = mobile.substr(-8, 8);
        }

        setUserDetail({
          ...userDetails,
          remark: "",
          price: userDetails?.payment?.price || 0,
          industry: userDetails?.industry?.id || 0,
          jobTitle: userDetails?.jobTitle?.id || 0,
          paymentType: userDetails?.payment?.id || 0,
        });

        if (userDetails.industry) {
          dispatch(
            getJobTitleList(false, "en", parseInt(userDetails.industry.id)),
          );
        }

        if (userDetails.identityType) {
          indentityProof.map((i: any) => {
            if (i.value === userDetails.identityType) {
              setSelectedIdentityType({ label: i.label, value: i.value });
            }
          });
        }
        if (userDetails.industry) {
          setSelectedIndustry({
            id: userDetails?.industry?.id || 0,
            name:
              userDetails?.industry?.industryLanguageDetails?.[0]?.name || "",
          });
        }
        if (userDetails.jobTitle) {
          setSelectedJobTitle({
            id: userDetails?.jobTitle?.id || 0,
            name: userDetails?.jobTitle?.jobTitleDetails?.[0]?.name || 0,
          });
        }

        setSelectedAttachment({
          avatar: userDetails?.avatar || "",
          idImage: userDetails?.idImage || "",
        });

        if (userDetails.title) {
          curtsyTitle.map((t: any) => {
            if (t.value === parseInt(userDetails.title)) {
              setSelectedCTitle(t);
            }
          });
        }
      }
      if (userDetails.district) {
        districtLst.map((t: any) => {
          if (t.districtId === parseInt(userDetails?.district?.id)) {
            setDistrict(t);
          }
        });
      }

      setAccountsCreated({
        liveLimit: userDetails.liveAccountCreated,
        demoLimit: userDetails.demoAccountCreated,
      });

      setAccounts({
        liveLimit: userDetails.liveLimit,
        demoLimit: userDetails.demoLimit,
      });
    }
  }, [userDetails]);

  useEffect(() => {
    if (industry) {
      //None
    }
  }, [industry]);

  useEffect(() => {
    if (!isLoadings) {
      setpasswordButton(false);
      setAltButton(false);
    }
  }, [isLoadings]);

  useEffect(() => {
    //NONE
  }, [paymentTypeLst, userDetail]);

  const onClickReject = () => {
    dispatch(UserMemberDenied(true, urlParams.id));
    setButtonClick(BUTTON_CLICK.REJECTED);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: userDetail.email,
      phoneNumber: userDetail.phoneNumber,
      dialCode: userDetail?.dialCode || "852",
      preferredNickName: userDetail.preferredNickName,
      englishNickName: userDetail.englishNickName || "",
      englishLastName: userDetail.englishLastName,
      englishFirstName: userDetail.englishFirstName,
      DOB:
        userDetail?.DOB !== null
          ? formatDate(userDetail?.DOB, "YYYY-MM-DD")
          : undefined,
      identityId: userDetail.identityId,
      identityType: userDetail.identityType,
      chineseLastName: userDetail.chineseLastName,
      chineseFirstName: userDetail.chineseFirstName,
      jobTitle: userDetail.jobTitle,
      industry: userDetail.industry,
      address: userDetail.address,
      countryCode: userDetail.countryCode || "",
      district: userDetails?.district?.id,
      emergencyContactName: userDetail.emergencyContactName,
      emergencyContactRelationship: userDetail.emergencyContactRelationship,
      emergencyContactNumber: userDetail.emergencyContactNumber,
      price: userDetail?.payment?.price,
      title: userDetail.title,
      idImage: userDetail.idImage,
      repeater: userDetail.repeater,
      remark: userDetail.remark,
      isVip: userDetail.isVip,
    },
    onSubmit: async () => {
      if (!accountLimitError.demoLimit && !accountLimitError.liveLimit) {
        if (action === "approve") {
          setButtonClick(BUTTON_CLICK.APPROVE);
          onClickApprove();
        }
        if (action === "modify") {
          setButtonClick(BUTTON_CLICK.MODIFY);
          onClickModify();
        }
      }
      setAction("");
    },
  });

  const { errors, touched, values, handleBlur, handleChange } = formik;

  useEffect(() => {
    if (userDetails && Object.keys(userDetails).length > 0 && Loading == true) {
      const user = {
        email: userDetails.email,
        phoneNumber: userDetails.phoneNumber,
        dialCode: userDetail?.dialCode || "852",
        preferredNickName: userDetails.preferredNickName,
        englishNickName: userDetails.englishNickName,
        englishLastName: userDetails.englishLastName,
        englishFirstName: userDetails.englishFirstName,
        DOB: userDetails.DOB ? formatDate(userDetails.DOB, "YYYY-MM-DD") : null,
        identityId: userDetails.identityId,
        identityType: userDetails.identityType,
        chineseLastName: userDetails.chineseLastName,
        chineseFirstName: userDetails.chineseFirstName,
        address: userDetails.address,
        district: userDetails?.district?.id,
        emergencyContactName: userDetails.emergencyContactName,
        emergencyContactRelationship: userDetails.emergencyContactRelationship,
        emergencyContactNumber: userDetails.emergencyContactNumber,
        price: userDetails?.payment?.price,
        title: userDetails.title,
        countryCode: userDetails?.countryCode || "",
        idImage: userDetails.idImage,
        repeater: userDetails.repeater,
        remark: "",
        isVip: userDetails.isVip,
      };

      if (userDetails.identityType) {
        indentityProof.map((i: any) => {
          if (i.value === userDetails.identityType) {
            setSelectedIdentityType({ label: i.label, value: i.value });
          }
        });
      }

      if (userDetails.industry) {
        setSelectedIndustry({
          id: userDetails.industry.id,
          name: userDetails.industry.industryLanguageDetails[0].name,
        });
      }

      if (userDetails.jobTitle) {
        setSelectedJobTitle({
          id: userDetails.jobTitle.id,
          name: userDetails.jobTitle.jobTitleDetails[0].name,
        });
      }

      if (userDetails.title) {
        curtsyTitle.map((t: any) => {
          if (t.value === parseInt(userDetails.title)) {
            setSelectedCTitle(t);
          }
        });
      }

      for (const [key, value] of Object.entries(user)) {
        formik.setFieldValue(key, value);
        formik.setFieldTouched(key, false);
      }

      const altName = {
        alternativeName1: userDetail?.alt_name1 || "",
        alternativeName2: userDetail?.alt_name2 || "",
        alternativeName3: userDetail?.alt_name3 || "",
        alternativeName4: userDetail?.alt_name4 || "",
      };

      for (const [key, value] of Object.entries(altName)) {
        formik1.setFieldValue(key, value);
        formik1.setFieldTouched(key, false);
      }
      setAccountsCreated({
        liveLimit: userDetails.liveAccountCreated,
        demoLimit: userDetails.demoAccountCreated,
      });

      setAccounts({
        liveLimit: userDetails.liveLimit,
        demoLimit: userDetails.demoLimit,
      });
    }
    setLoading(false);
  }, [Loading]);

  const resetFormValues = () => {
    setLoading(true);
  };

  const onClickApprove = () => {
    const details = {
      id: userDetail.id,
      email: values.email,
      identityType: values.identityType,
      identityId: values.identityId?.toString(),
      phoneNumber: values.phoneNumber,
      dialCode: values?.dialCode || "852",
      address: values.address,
      emergencyContactName: values.emergencyContactName,
      emergencyContactRelationship: values.emergencyContactRelationship,
      emergencyContactNumber: values.emergencyContactNumber,
      preferredNickName: values.preferredNickName,
      englishNickName: values.englishNickName,
      englishFirstName: values.englishFirstName,
      englishLastName: values.englishLastName,
      chineseFirstName: values.chineseFirstName,
      chineseLastName: values.chineseLastName,
      title: values.title,
      DOB: values.DOB ? formatDate(values.DOB, "YYYY-MM-DD") : null,
      jobTitle: values.jobTitle,
      industry: values.industry,
      district: values.district,
      countryCode: values.countryCode,
      idImage: values.idImage,
      repeater: values.repeater,
      studentClassChoice: userDetail.userClass[0].id,
      remark: values.remark,
      liveLimit: accounts.liveLimit,
      demoLimit: accounts.demoLimit,
      isVip: values.isVip,
    };

    dispatch(ExpressWayMemberAction(true, details, "approve"));
  };

  const onClickModify = () => {
    const details = {
      email: values.email,
      phoneNumber: values.phoneNumber,
      identityId: values.identityId.toString(),
      identityType: values.identityType,
      idImage: selectedAttachment.idImage,
      avatar: selectedAttachment.avatar,
      address: values.address,
      district: values.district,
      preferredNickName: values.preferredNickName,
      englishNickName: values.englishNickName,
      englishFirstName: values.englishFirstName,
      englishLastName: values.englishLastName,
      chineseFirstName: values.chineseFirstName,
      chineseLastName: values.chineseLastName,
      emergencyContactName: values.emergencyContactName,
      emergencyContactRelationship: values.emergencyContactRelationship,
      emergencyContactNumber: values.emergencyContactNumber,
      title: values.title,
      countryCode: values.countryCode,
      DOB: values?.DOB ? values?.DOB : null,
      jobTitle: values.jobTitle,
      industry: values.industry,
      dialCode: values.dialCode,
      liveLimit: accounts.liveLimit,
      demoLimit: accounts.demoLimit,
      isVip: values.isVip,
    };

    dispatch(UserMemberModify(true, details, urlParams.id));
  };

  const handlSubmit = (e: any, action: any) => {
    e.preventDefault();
    setAction(action);
    formik.handleSubmit();
  };

  const attchmentClick = (name: any) => {
    setSelectedAttachName(name);

    if (attchRef.current) {
      attchRef?.current.click();
    }
  };

  const handleDrop = async (e: any) => {
    const acceptedFiles = e.target.files;

    const { fileUploadResponse, error }: any = await _UploadMedia(
      acceptedFiles[0] as File,
    );

    if (!error) {
      setSelectedAttachment((prv: any) => ({
        ...prv,
        [selectedAttachName]: fileUploadResponse,
      }));
    }
    setFileLoading(false);
    e.target.value = "";
  };

  const onIndustryChange = (option: any) => {
    setSelectedIndustry(option);
    setSelectedJobTitle({});
    formik.setFieldValue("industry", option.id);
    formik.setFieldValue("jobTitle", 0);
    dispatch(getJobTitleList(false, "EN", option.id));
  };

  const formik1 = useFormik({
    enableReinitialize: true,
    initialValues: {
      alternativeName1: userDetail?.alt_name1 || "",
      alternativeName2: userDetail?.alt_name2 || "",
      alternativeName3: userDetail?.alt_name3 || "",
      alternativeName4: userDetail?.alt_name4 || "",
    },
    validationSchema: allAlternativeNameSchema,
    onSubmit: async (values) => {
      const id: string = urlParams.id as string;
      values.alternativeName1 =
        values.alternativeName1.trim() === "" ? null : values.alternativeName1;
      values.alternativeName2 =
        values.alternativeName2.trim() === "" ? null : values.alternativeName2;
      values.alternativeName3 =
        values.alternativeName3.trim() === "" ? null : values.alternativeName3;
      values.alternativeName4 =
        values.alternativeName4.trim() === "" ? null : values.alternativeName4;
      setAltButton(true);
      dispatch(
        setAlternativeNameList(
          true,
          {
            alt_name1: values.alternativeName1,
            alt_name2: values.alternativeName2,
            alt_name3: values.alternativeName3,
            alt_name4: values.alternativeName4,
          },
          id,
        ),
      );
    },
  });

  const error1 = formik1.errors;
  const touched1 = formik1.touched;
  const val = formik1.values;

  const handleOnChange = (
    value: any,
    data: any,
    event: any,
    formattedValue: any,
  ) => {
    formik.setFieldValue("dialCode", data.dialCode);
    formik.setFieldValue("phoneNumber", value.slice(data.dialCode.length));
    formik.setFieldValue("countryCode", data.countryCode);
  };

  if (isLoading || isLoadings || fileLoading || Loading) {
    return <CenterLoader />;
  }

  return (
    <div className="p-4">
      {fileLoading && <CenterLoader />}
      {Loading && <CenterLoader />}
      <Row className="mb-4 align-items-center">
        <Col>
          <h4 className="font-weight-500">Personal Information</h4>
        </Col>
        <Col className="text-end">
          <Button onClick={resetFormValues}>
            <Cached />
          </Button>
        </Col>
      </Row>
      <Card className="m-0 shadow-none border">
        <Card.Body>
          <Row>
            <Col sm={6} md={6} lg={6}>
              <table style={{ width: "100%" }}>
                <tr>
                  <th>
                    <label>
                      <FormattedMessage
                        id="table.head.Email"
                        defaultMessage="Email"
                      />
                    </label>
                  </th>
                  <td>
                    <input
                      type="text"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      className="form-control form-control-md form-group"
                    />
                    {errors.email && touched.email && (
                      <div className="text-error">
                        <FormattedMessage
                          id={errors.email.toString()}
                          defaultMessage="This field is required"
                        />
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>
                    <label>
                      <FormattedMessage
                        id="table.head.Phone"
                        defaultMessage="Phone"
                      />
                    </label>
                  </th>
                  <td className="form-group">
                    <ReactPhoneInput
                      value={values.dialCode + values.phoneNumber}
                      onChange={handleOnChange}
                      onBlur={() => formik.setFieldTouched("phoneNumber", true)}
                    />
                    {errors.phoneNumber && touched.phoneNumber && (
                      <div className="text-error">
                        <FormattedMessage
                          id={errors.phoneNumber.toString()}
                          defaultMessage="This field is required"
                        />
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>
                    <label>
                      <FormattedMessage
                        id="table.head.PreferredNickname"
                        defaultMessage="Preferred Nickname"
                      />
                    </label>
                  </th>
                  <td>
                    <input
                      type="text"
                      name="preferredNickName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.preferredNickName}
                      className="form-control form-control-md form-group"
                    />
                    {errors.preferredNickName && touched.preferredNickName && (
                      <div className="text-error">
                        <FormattedMessage
                          id={errors.preferredNickName.toString()}
                          defaultMessage="This field is required"
                        />
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>
                    <label>
                      <FormattedMessage
                        id="table.head.EnglishLastName"
                        defaultMessage="English Last Name"
                      />
                    </label>
                  </th>
                  <td>
                    <input
                      type="text"
                      name="englishLastName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.englishLastName}
                      className="form-control form-control-md form-group"
                    />
                    {errors.englishLastName && touched.englishLastName && (
                      <div className="text-error">
                        <FormattedMessage
                          id={errors.englishLastName.toString()}
                          defaultMessage="This field is required"
                        />
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>
                    <label>
                      <FormattedMessage
                        id="table.head.EnglishFirstName"
                        defaultMessage="English First Name"
                      />
                    </label>
                  </th>
                  <td>
                    <input
                      type="text"
                      name="englishFirstName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.englishFirstName}
                      className="form-control form-control-md form-group"
                    />
                    {errors.englishFirstName && touched.englishFirstName && (
                      <div className="text-error">
                        <FormattedMessage
                          id={errors.englishFirstName.toString()}
                          defaultMessage="This field is required"
                        />
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>
                    {hide && (
                      <label>
                        <FormattedMessage
                          id="table.head.DateofBirth"
                          defaultMessage="Date of Birth"
                        />
                      </label>
                    )}
                  </th>
                  <td>
                    {hide && (
                      <>
                        <input
                          type="date"
                          name="DOB"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.DOB}
                          className="form-control form-control-md form-group"
                          required
                        />
                        {errors.DOB && touched.DOB && (
                          <div className="text-error">
                            <FormattedMessage
                              id={errors.DOB.toString()}
                              defaultMessage="This field is required"
                            />
                          </div>
                        )}
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <th style={{ width: "20%" }}>
                    <label>Title</label>
                  </th>
                  <td>
                    <Select
                      options={curtsyTitle}
                      getOptionLabel={(option: any) => option.title}
                      getOptionValue={(option: any) => option.value}
                      value={selectedCTitle}
                      onChange={(option: any) => {
                        formik.setFieldValue("title", option.value);
                        setSelectedCTitle(option);
                      }}
                      onBlur={() => formik.setFieldTouched("title", true)}
                      className="form-group"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </td>
                </tr>

                <tr>
                  <th style={{ width: "20%" }}>
                    <label>ID Type</label>
                  </th>
                  <td>
                    <Select
                      name="toolType"
                      value={selectedIndentityType}
                      onChange={(option: any) => {
                        setSelectedIdentityType(option);
                        formik.setFieldValue("identityId", "");
                        formik.setFieldValue("identityType", option.value);
                      }}
                      options={indentityProof}
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.value}
                      className="form-group"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </td>
                </tr>

                <tr>
                  <th>
                    {hide && (
                      <label>
                        <FormattedMessage
                          id="table.head.HKID"
                          defaultMessage="HKID"
                        />
                      </label>
                    )}
                  </th>

                  <td>
                    {hide && (
                      <>
                        {formik.values.identityType === "Hong kong Id Card" ? (
                          <input
                            type="text"
                            name="identityId"
                            maxLength={4}
                            minLength={4}
                            onChange={(e: any) => {
                              formik.handleChange(e);
                            }}
                            onBlur={handleBlur}
                            value={values.identityId}
                            className="form-control form-control-md form-group"
                          />
                        ) : (
                          <input
                            type="text"
                            name="identityId"
                            maxLength={6}
                            minLength={6}
                            onChange={(e: any) => {
                              if (
                                e.target.value.length <= 6 ||
                                e.target.value == ""
                              ) {
                                formik.handleChange(e);
                              }
                            }}
                            onBlur={handleBlur}
                            value={values.identityId}
                            className="form-control form-control-md form-group"
                          />
                        )}
                        {errors.identityId && touched.identityId && (
                          <div className="text-error">
                            <FormattedMessage
                              id={errors.identityId.toString()}
                              defaultMessage="This field is required"
                            />
                          </div>
                        )}
                      </>
                    )}
                  </td>
                </tr>
              </table>
            </Col>
            <Col sm={6} md={6} lg={6}>
              <table>
                <tr>
                  <th style={{ width: "20%" }}>
                    <label>
                      <FormattedMessage
                        id="table.head.ChineseLastName"
                        defaultMessage="Chinese Last Name"
                      />
                    </label>
                  </th>
                  <td>
                    <input
                      type="text"
                      name="chineseLastName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.chineseLastName}
                      className="form-control form-control-md form-group"
                    />
                    {errors.chineseLastName && touched.chineseLastName && (
                      <div className="text-error">
                        <FormattedMessage
                          id={errors.chineseLastName.toString()}
                          defaultMessage="This field is required"
                        />
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>
                    <label>
                      <FormattedMessage
                        id="table.head.ChinesefirstName"
                        defaultMessage="Chinese first Name"
                      />
                    </label>
                  </th>
                  <td>
                    <input
                      type="text"
                      name="chineseFirstName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.chineseFirstName}
                      className="form-control form-control-md form-group"
                    />
                    {errors.chineseFirstName && touched.chineseFirstName && (
                      <div className="text-error">
                        <FormattedMessage
                          id={errors.chineseFirstName.toString()}
                          defaultMessage="This field is required"
                        />
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>
                    <label>
                      <FormattedMessage
                        id="table.head.EnglishNickname"
                        defaultMessage="Nick Name (Old Gems)"
                      />
                    </label>
                  </th>
                  <td>
                    <input
                      type="text"
                      name="englishNickName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.englishNickName}
                      className="form-control form-control-md form-group"
                    />
                    {errors.englishNickName && touched.englishNickName && (
                      <div className="text-error">
                        <FormattedMessage
                          id={errors.englishNickName.toString()}
                          defaultMessage="This field is required"
                        />
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>
                    <label>
                      <FormattedMessage
                        id="table.head.OccupationIndustry"
                        defaultMessage="Occupation (DISABLED)"
                      />
                    </label>
                  </th>
                  <td>
                    <Select
                      name="toolType"
                      value={selectedIndustry}
                      onChange={(option: any) => {
                        onIndustryChange(option);
                      }}
                      options={industry}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.id}
                      className="form-group"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {errors.industry && touched.industry && (
                      <div className="text-error">
                        <FormattedMessage
                          id={errors.industry.toString()}
                          defaultMessage="This field is required"
                        />
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>
                    <label>
                      <FormattedMessage
                        id="table.head.Occupation"
                        defaultMessage="Occupation"
                      />
                    </label>
                  </th>
                  <td>
                    <Select
                      name="toolType"
                      value={selectedJobTitle}
                      onChange={(option: any) => {
                        setSelectedJobTitle(option);
                        formik.setFieldValue("jobTitle", option.JobTitleId);
                      }}
                      options={jobTitles}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.JobTitleId}
                      className="form-group"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {errors.jobTitle && touched.jobTitle && (
                      <div className="text-error">
                        <FormattedMessage
                          id={errors.jobTitle.toString()}
                          defaultMessage="This field is required"
                        />
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <th style={{ width: "20%" }}>
                    <label>
                      <FormattedMessage
                        id="table.head.District"
                        defaultMessage="District"
                      />
                    </label>
                  </th>
                  <td>
                    <Select
                      options={districtLst}
                      value={district}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.districtId}
                      onChange={(option: any) => {
                        setDistrict(option);
                        formik.setFieldValue("district", option.districtId);
                      }}
                      onBlur={() => formik.setFieldTouched("district", true)}
                      className="form-group"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {errors.address && touched.address && (
                      <div className="text-error">
                        <FormattedMessage
                          id={errors.address.toString()}
                          defaultMessage="This field is required"
                        />
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <th style={{ width: "20%" }}>
                    <label>
                      <FormattedMessage
                        id="table.head.isVip"
                        defaultMessage="Is VIP"
                      />
                    </label>
                  </th>

                  <td style={{ textAlign: "left" }}>
                    <input
                      type="checkbox"
                      name="isVip"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.isVip}
                    />
                  </td>
                </tr>
              </table>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <h4 className="font-weight-400 my-4">
        <FormattedMessage
          id="head.IndentityInformation"
          defaultMessage="Identity Information"
        />
      </h4>
      <Card
        className="mt-3"
        style={{ boxShadow: "none", border: "1px solid #efefef" }}
      >
        <Card.Body>
          <Button
            variant="contained"
            size="small"
            onClick={() => setHide(!hide)}
          >
            {hide ? (
              <FormattedMessage id="page.Hide" defaultMessage="HIDE" />
            ) : (
              <FormattedMessage id="page.Show" defaultMessage="SHOW" />
            )}
          </Button>
          <Row className="mt-1">
            <Col xs={12} sm={12} md={12} lg={5}>
              {hide && (
                <table style={{ width: "100%" }} className="mt-2">
                  <tr>
                    <td>
                      <label>
                        <FormattedMessage
                          id="head.UserImage"
                          defaultMessage="User Image"
                        />
                        :
                      </label>
                      <br />
                      <div
                        style={{
                          display: "flex",
                          gap: "1rem",
                          alignItems: "flex-end",
                        }}
                      >
                        <ImageWithOnClickModal
                          imageStyle={{
                            height: "150px",
                            width: "150px",
                            borderRadius: `${userDetail.avatar ? "50%" : "0%"}`,
                            border: "1px solid #efefef",
                          }}
                          src={
                            userDetail.avatar
                              ? selectedAttachment.avatar
                              : noImage
                          }
                        />
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => attchmentClick("avatar")}
                        >
                          Click here to change
                        </Button>
                      </div>
                      <input
                        type="file"
                        ref={attchRef}
                        style={{ display: "none" }}
                        onChangeCapture={(e: any) => handleDrop(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="pt-4">
                      <label>
                        <FormattedMessage
                          id="head.IdImage"
                          defaultMessage="Id Image"
                        />
                        :
                      </label>
                      <br />
                      <div
                        style={{
                          display: "flex",
                          gap: "1rem",
                          alignItems: "flex-end",
                        }}
                      >
                        <ImageWithOnClickModal
                          imageStyle={{
                            height: "150px",
                            width: "150px",
                            border: "1px solid #efefef",
                          }}
                          src={
                            userDetail.idImage
                              ? selectedAttachment.idImage
                              : noImage
                          }
                        />
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => attchmentClick("idImage")}
                        >
                          Click here to change
                        </Button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="pt-4">
                      <label>
                        <FormattedMessage
                          id="head.Signature"
                          defaultMessage="Signature"
                        />
                      </label>
                      <br />
                      {userDetail.signaturePath ? (
                        <img
                          src={userDetail.signaturePath}
                          onClick={() =>
                            setImg({
                              image: userDetail.signaturePath,
                              show: true,
                            })
                          }
                          style={{ height: "150px" }}
                        />
                      ) : (
                        <img
                          className="image"
                          style={{
                            height: "150px",
                            width: "150px",
                            border: "1px solid #efefef",
                          }}
                          src={noImage}
                        />
                      )}
                    </td>
                  </tr>
                </table>
              )}
            </Col>
            <Col sm={12} md={12} lg={6}>
              <table style={{ width: "100%" }}>
                <tr>
                  <th>
                    <label style={{ fontWeight: "600" }}>
                      <FormattedMessage
                        id="table.head.OtherInformation"
                        defaultMessage="Other Information"
                      />
                    </label>
                  </th>
                </tr>
                <tr>
                  <th>
                    <label>
                      <FormattedMessage
                        id="table.head.DemoLimit"
                        defaultMessage="Demo Limit"
                      />
                    </label>
                  </th>
                  <td>
                    <input
                      type="text"
                      defaultValue={userDetail.demoLimit}
                      className="form-control-plaintext "
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <label>
                      <FormattedMessage
                        id="table.head.LiveLimit"
                        defaultMessage="Live Limit"
                      />
                    </label>
                  </th>
                  <td>
                    <input
                      type="text"
                      defaultValue={userDetail.liveLimit}
                      className="form-control-plaintext"
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <label>
                      <FormattedMessage
                        id="table.head.CurrentPoint"
                        defaultMessage="Current Point"
                      />
                    </label>
                  </th>
                  <td>
                    <input
                      type="text"
                      defaultValue={userDetail.currentPoint}
                      className="form-control-plaintext"
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <label>
                      <FormattedMessage
                        id="table.head.TotalPoint"
                        defaultMessage="Total Point"
                      />
                    </label>
                  </th>
                  <td>
                    <input
                      type="text"
                      defaultValue={userDetail.totalPoints}
                      className="form-control-plaintext"
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <label>
                      <FormattedMessage
                        id="table.head.DiscountCode"
                        defaultMessage="Discount Code"
                      />
                    </label>
                  </th>
                  <td>
                    <input
                      type="text"
                      defaultValue={userDetail.discountCode}
                      className="form-control-plaintext"
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <label>
                      <FormattedMessage
                        id="table.head.Membership"
                        defaultMessage="Discount Code"
                      />
                    </label>
                  </th>
                  <td>{formatDate(userDetail.expiryDate, "DD-MM-YYYY")}</td>
                </tr>
              </table>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <h4 className="font-weight-400 my-4">
        <FormattedMessage
          id="head.alternativeName"
          defaultMessage="Alternative Name"
        />
      </h4>
      <Card
        className="mt-3"
        style={{ boxShadow: "none", border: "1px solid #efefef" }}
      >
        <Card.Body>
          <Row>
            <Col sm={6} md={5} lg={5} xl={3}>
              <div className="form-group">
                <FormattedMessage
                  id="head.alternativeName1"
                  defaultMessage="Alternative Name 1"
                />
              </div>
            </Col>
            <Col sm={6} md={5} lg={5} xl={3}>
              <input
                type="text"
                name="alternativeName1"
                value={val.alternativeName1}
                className="form-control form-group"
                onChange={formik1.handleChange}
                onBlur={formik1.handleBlur}
              />
              {error1.alternativeName1 && touched1.alternativeName1 && (
                <div className="text-error">
                  <FormattedMessage
                    id={error1.alternativeName1.toString()}
                    defaultMessage="require"
                  />
                </div>
              )}
            </Col>
          </Row>

          <Row>
            <Col sm={6} md={5} lg={5} xl={3}>
              <div className="form-group">
                <FormattedMessage
                  id="head.alternativeName2"
                  defaultMessage="Please Enter Alternative Name"
                />
              </div>
            </Col>
            <Col sm={6} md={5} lg={5} xl={3}>
              <input
                type="text"
                name="alternativeName2"
                value={val.alternativeName2}
                className="form-control form-group"
                onChange={formik1.handleChange}
                onBlur={formik1.handleBlur}
              />
              {error1.alternativeName2 && touched1.alternativeName2 && (
                <div className="text-error">
                  <FormattedMessage
                    id={error1.alternativeName2.toString()}
                    defaultMessage="require"
                  />
                </div>
              )}
            </Col>
          </Row>

          <Row>
            <Col sm={6} md={5} lg={5} xl={3}>
              <div className="form-group">
                <FormattedMessage
                  id="head.alternativeName3"
                  defaultMessage="Please Enter Alternative Name"
                />
              </div>
            </Col>
            <Col sm={6} md={5} lg={5} xl={3}>
              <input
                type="text"
                name="alternativeName3"
                className="form-control form-group"
                value={val.alternativeName3}
                onChange={formik1.handleChange}
                onBlur={formik1.handleBlur}
              />
              {error1.alternativeName3 && touched1.alternativeName3 && (
                <div className="text-error">
                  <FormattedMessage
                    id={error1.alternativeName3.toString()}
                    defaultMessage="require"
                  />
                </div>
              )}
            </Col>
          </Row>

          <Row>
            <Col sm={6} md={5} lg={5} xl={3}>
              <div className="form-group">
                <FormattedMessage
                  id="head.alternativeName4"
                  defaultMessage="Please Enter Alternative Name"
                />
              </div>
            </Col>
            <Col sm={6} md={5} lg={5} xl={3}>
              <input
                type="text"
                name="alternativeName4"
                className="form-control form-group"
                value={val.alternativeName4}
                onChange={formik1.handleChange}
                onBlur={formik1.handleBlur}
              />
              {error1.alternativeName4 && touched1.alternativeName4 && (
                <div className="text-error">
                  <FormattedMessage
                    id={error1.alternativeName4.toString()}
                    defaultMessage="require"
                  />
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                color="primary"
                variant="contained"
                onClick={() => formik1.handleSubmit()}
              >
                <FormattedMessage id="page.Save" defaultMessage="Save" />
                {isLoadings && altButton && <Loader />}
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <h4 className="font-weight-400 my-4">
        <FormattedMessage
          id="head.ClassInformation"
          defaultMessage="Indentity Information"
        />
      </h4>
      <Card
        className="mt-3"
        style={{ boxShadow: "none", border: "1px solid #efefef" }}
      >
        <Card.Body>
          <Row className="mt-1">
            <Col xs={12} md={12} lg={5}>
              <Row>
                <Col xs={12} sm={12} md={6} lg={4}>
                  <label>
                    <FormattedMessage
                      id="head.ClassNameId"
                      defaultMessage="Class Name/ID "
                    />
                  </label>
                </Col>
                <Col xs={12} sm={12} md={6} lg={8}>
                  <Select
                    options={classData}
                    value={selectedClass}
                    onChange={(option: any) => setSelectedClass(option)}
                    getOptionValue={(option: any) => option?.class?.id}
                    getOptionLabel={(option: any) =>
                      option?.class?.name + "/" + option?.class?.id
                    }
                    className="form-group"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={12} lg={6}>
              <table style={{ width: "100%" }}>
                <tr>
                  <th>
                    <label style={{ fontWeight: "600" }}>
                      <FormattedMessage
                        id="head.ClassInformation"
                        defaultMessage="Other Information"
                      />
                    </label>
                  </th>
                </tr>
                <tr>
                  <th>
                    <label>
                      <FormattedMessage
                        id="table.head.Status"
                        defaultMessage="Demo Limit"
                      />
                      :
                    </label>
                  </th>
                  <td>
                    <label>
                      {selectedClass !== null ? selectedClass?.status : "-"}
                    </label>
                  </td>
                </tr>
                <tr>
                  <th>
                    <label>
                      <FormattedMessage
                        id="table.head.Role"
                        defaultMessage="Live Limit"
                      />
                      :
                    </label>
                  </th>
                  <td>
                    <label>
                      {selectedClass !== null
                        ? selectedClass?.role?.roleName || "--"
                        : "-"}
                    </label>
                  </td>
                </tr>
                <tr>
                  <th>
                    <label>
                      <FormattedMessage
                        id="table.head.StudentStatus"
                        defaultMessage="Current Point"
                      />
                      :
                    </label>
                  </th>
                  <td>
                    <label>
                      {selectedClass !== null
                        ? selectedClass?.status === "approved" &&
                          selectedClass?.isGraduated
                          ? "Graduated"
                          : null
                        : "-"}
                      {selectedClass !== null
                        ? selectedClass?.status === "approved" &&
                          !selectedClass?.isGraduated
                          ? "In Progress"
                          : null
                        : "-"}
                      {selectedClass !== null
                        ? selectedClass?.status !== "approved" &&
                          !selectedClass?.isGraduated
                          ? "Waiting for Approval"
                          : null
                        : "-"}
                    </label>
                  </td>
                </tr>
              </table>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <h4 className="font-weight-400 my-4">
        <FormattedMessage id="head.Others" defaultMessage="Others" />
      </h4>
      <Card
        className="mt-3"
        style={{ boxShadow: "none", border: "1px solid #efefef" }}
      >
        <Card.Body>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Row className="mb-2 align-items-center">
                <Col xs={12} sm={12} md={12} lg={3}></Col>
                <Col xs={12} sm={12} md={12} lg={4}>
                  <FormattedMessage id="head.limit" defaultMessage="Created" />
                </Col>
                <Col xs={12} sm={12} md={12} lg={4}>
                  <FormattedMessage
                    id="head.created"
                    defaultMessage="Created"
                  />
                </Col>
              </Row>
              <Row className="align-items-center form-group">
                <Col xs={12} sm={12} md={12} lg={3}>
                  <label className="mb-0">
                    <FormattedMessage
                      id="head.demoAccount"
                      defaultMessage="Created"
                    />
                  </label>
                </Col>
                <Col xs={12} sm={12} md={12} lg={4}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <input
                      style={{ backgroundColor: "white", width: "80%" }}
                      type="text"
                      className="form-control"
                      onChange={(e: any) => {
                        if (!isPositiveNumbersOnly(e, e.target.value)) {
                          return false;
                        }
                        // formik.handleChange(e);
                        if (e.target.value >= 0) {
                          setAccounts((prv: any) => ({
                            ...prv,
                            demoLimit: e.target.value,
                          }));
                        }
                      }}
                      value={
                        accounts.demoLimit === null
                          ? "null"
                          : accounts.demoLimit
                      }
                      min={0}
                    />
                  </Stack>
                  {accountLimitError.demoLimit ? (
                    <div className="text-error">
                      <FormattedMessage
                        id="demoAccount.invalid"
                        defaultMessage="testing"
                      />
                    </div>
                  ) : null}
                </Col>
                <Col xs={12} sm={12} md={12} lg={4}>
                  <input
                    style={{ backgroundColor: "white", width: "80%" }}
                    type="number"
                    className="form-control"
                    value={accountsCreated.demoLimit}
                    disabled
                  />
                </Col>
              </Row>
              <Row className="align-items-center form-group">
                <Col xs={12} sm={12} md={12} lg={3}>
                  <label className="mb-0">
                    <FormattedMessage
                      id="head.liveAccount"
                      defaultMessage="Created"
                    />
                  </label>
                </Col>
                <Col xs={12} sm={12} md={12} lg={4}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <input
                      style={{ backgroundColor: "white", width: "80%" }}
                      type="text"
                      className="form-control"
                      onChange={(e: any) => {
                        if (!isPositiveNumbersOnly(e, e.target.value)) {
                          return false;
                        }
                        if (e.target.value >= 0) {
                          setAccounts((prv: any) => ({
                            ...prv,
                            liveLimit: e.target.value,
                          }));
                        }
                      }}
                      value={
                        accounts.liveLimit === null
                          ? "null"
                          : accounts.liveLimit
                      }
                      min={0}
                    />
                  </Stack>
                  {accountLimitError.liveLimit ? (
                    <div className="text-error">
                      <FormattedMessage
                        id="liveAccount.invalid"
                        defaultMessage="testing"
                      />
                    </div>
                  ) : null}
                </Col>
                <Col xs={12} sm={12} md={12} lg={4}>
                  <input
                    style={{ backgroundColor: "white", width: "80%" }}
                    type="number"
                    min={0}
                    className="form-control form-group"
                    value={accountsCreated.liveLimit}
                    disabled
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Viewer
        visible={img.show}
        onClose={() => {
          setImg({ show: false, image: "" });
        }}
        images={[{ src: img.image, alt: "Nor FOund" }]}
      />

      <Row>
        <Col style={{ textAlign: "right" }}>
          <button className="btn btn-info mb-3 mb-lg-0">
            <FormattedMessage
              id="page.AskForResign"
              defaultMessage="Ask for Resign"
            />
          </button>
          <button
            className="btn btn-primary m-l-10 mb-3 mb-lg-0"
            onClick={(e: any) => handlSubmit(e, "modify")}
          >
            <FormattedMessage id="page.Modify" defaultMessage="Modify" />
            {isLoading && buttonClick === BUTTON_CLICK.MODIFY && <Loader />}
          </button>
          {!(userDetails.status === "rejected") && (
            <button
              className="btn btn-danger m-l-10 mb-3 mb-lg-0"
              onClick={() => onClickReject()}
            >
              <>
                <div className="d-flex">
                  <FormattedMessage id="page.Reject" defaultMessage="Reject" />
                  {isLoading && buttonClick === BUTTON_CLICK.REJECTED && (
                    <Loader />
                  )}
                </div>
              </>
            </button>
          )}
          {!(userDetails.status === "approved") && (
            <button
              className="btn btn-success m-l-10 mb-3 mb-lg-0"
              onClick={(e: any) => handlSubmit(e, "approve")}
            >
              <>
                <div className="d-flex">
                  <FormattedMessage
                    id="page.Approve"
                    defaultMessage="Approve"
                  />
                  {isLoading && buttonClick === BUTTON_CLICK.APPROVE && (
                    <Loader />
                  )}
                </div>
              </>
            </button>
          )}

          <button
            className="btn btn-primary m-l-10 mb-3 mb-lg-0"
            onClick={() => {
              setpasswordButton(true);
              dispatch(setPasswordManual(true, id));
            }}
          >
            <div className="d-flex">
              <FormattedMessage
                id="head.RecoverPasswordManually"
                defaultMessage="Recover Password Manually"
              />
              {isLoadings && passwordButton && <Loader />}
            </div>
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default MemberDetail;
