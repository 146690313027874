import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import NoDatFound from "../../Components/common/NoDatFound";
import DeletePopup from "../../Components/common/Popups/DeletePopup";
import AddIndicatorModal from "../../Components/Indicator/AddIndicatorModal";
import {
  deleteIndicators,
  getAdminRolesList,
  getClasses,
  getIndicatorsList,
  setInCurrentPage,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import { FormattedMessage } from "react-intl";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import Pagination from "../../Components/common/Paginate/Pagination";
import { Dropdown } from "react-bootstrap";
import { AppendedMyComponent } from "../../Components/append-to-body-drop-down";
import Indicator from "../../assets/img/Indicator.svg";
import EditToolClassModal from "../../Components/common/Popups/EditToolClassModal";
import { TOOLS, TOOLS_TICKET } from "../../Constants";
import { ClassesIcon } from "../../util/iconStyle";
import { BsSortDown, BsSortUp } from "react-icons/bs";
import { FaEdit, FaTrash } from "../../reactIcons";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

const Indicators = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [indicatorModalShow, setIndicatorModalShow] = useState(false);
  const [indicatorDelModalShow, setIndicatorDelModalShow] = useState({
    modal: false,
    id: 0,
  });
  const [edtIndicator, setEdtIndicator] = useState(null);
  const [addToAllClass, setAddToAllClass] = useState({ modal: false, id: 0 });

  const indicatorsList: any = useSelector(
    (state: Store) => state.indicatorsReducer.indicatorsList,
  ) as any;
  const rolesLists: any = useSelector(
    (state: Store) => state.adminRolesReducer.adminRoleList,
  ) as any;
  const isLoading = useSelector(
    (state: Store) => state.indicatorsReducer.isLoading,
  );
  const [pageNumber, setPageNumber] = useState<any>();
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const totalRecord = useSelector(
    (state: Store) => state.indicatorsReducer.totalRecord,
  ) as any;

  const currentPage = useSelector(
    (state: Store) => state.indicatorsReducer.currentPage,
  );

  const classes = useSelector(
    (state: Store) => state.classReducer.classes || [],
  );
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [roleList, setRoleList] = useState([]);

  useEffect(() => {
    const r: any = [];
    rolesLists.map((i: any) => {
      if (i.isEARole) {
        r.push(i);
      }
    });
    setRoleList(r);
  }, [rolesLists]);

  const setSorting = (prop: any) => {
    if (sortBy === prop) {
      setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
    } else {
      setSortBy(prop);
      setSortOrder("ASC");
    }
  };
  const onPageChange = (pageNum: number) => {
    localStorage.setItem("pageNumber", pageNum.toString());
    localStorage.setItem("pageLimit", pageLimit.toString());
    setPageNumber(pageNum);
    dispatch(setInCurrentPage(pageNum));
  };

  useEffect(() => {
    dispatch(setInCurrentPage(1));
    dispatch(getClasses(false, 0, { order: "DESC", by: "createdAt" }));
  }, []);

  useEffect(() => {
    if (pageNumber && pageLimit) {
      dispatch(
        getIndicatorsList(
          false,
          parseInt(pageNumber),
          { by: sortBy, order: sortOrder },
          parseInt(pageLimit),
        ),
      );
    }
  }, [pageNumber, pageLimit, sortOrder, sortBy]);

  useEffect(() => {
    dispatch(getAdminRolesList(false));
    const storedPageNumber = localStorage.getItem("pageNumber");
    const storedPageLimit = localStorage.getItem("pageLimit");
    if (storedPageNumber && storedPageLimit) {
      setPageNumber(storedPageNumber);
      setPageLimit(storedPageLimit);
    } else {
      setPageNumber(1);
      setPageLimit(10);
    }
  }, []);

  const editIndicatorSelected = (indicatorDetail: any) => {
    setEdtIndicator(indicatorDetail);
    setIndicatorModalShow(true);
  };

  const viewIndicatorDetail = (id: any) => {
    localStorage.setItem("pageNumber", pageNumber);
    localStorage.setItem("pageLimit", pageLimit);
    navigate("/indicators/" + id);
  };

  const deleteInd = () => {
    dispatch(deleteIndicators(true, indicatorDelModalShow.id));
    setIndicatorDelModalShow({ modal: false, id: 0 });
  };

  return (
    <>
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                {" "}
                <FormattedMessage
                  id="Admin.Indicators"
                  defaultMessage="Indicators "
                />
              </h1>
            </div>
            {/* <div className="m-l-10">
                            <div className="input-group w-250">
                                <input type="text" className="form-control" placeholder="Search" title="Search" />
                                <div className="input-group-append">
                                    <button type="button" className="input-group-text pointer"><span className="fa fa-search"></span></button>
                                </div>
                            </div>
                        </div>
                        <div className="m-l-10">
                            <select className="form-control select2 w-150">
                                <option value="">-- Status --</option>
                                <option>All</option>
                                <option>Active</option>
                                <option>Inactive</option>
                            </select>
                        </div>
                        <div className="m-l-10">
                            <button type="button" className="btn btn-primary2">
                                Submit
                            </button>
                        </div>
                        <div className="m-l-10">
                            <button type="button" className="btn btn-secondary">
                                Reset
								</button>
                        </div> */}
            <div className="m-l-10 common-right-button">
              <a
                href="#"
                data-toggle="modal"
                data-target="#AddCourse"
                type="button"
                onClick={() => {
                  setIndicatorModalShow(true);
                  setEdtIndicator(null);
                }}
                className="btn btn-primary"
              >
                <FormattedMessage
                  id="Admin.Indicator.add"
                  defaultMessage="Add Indicator "
                />
              </a>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          <div className="card">
            {isLoading && <CenterLoader />}
            <div className="card-body p-0">
              <div className="table-responsive">
                {indicatorsList && indicatorsList.length ? (
                  <>
                    {" "}
                    <table className="table table-hover m-0">
                      <thead>
                        <tr>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("id")}
                          >
                            <FormattedMessage
                              id="table.head.ID"
                              defaultMessage="ID"
                            />
                            {sortBy === "id" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("name")}
                          >
                            <FormattedMessage
                              id="table.head.Name"
                              defaultMessage="Name"
                            />{" "}
                            {sortBy === "name" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("id")}
                          >
                            <FormattedMessage
                              id="table.head.Cost"
                              defaultMessage="Cost"
                            />{" "}
                            {sortBy === "id" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("originalPrice")}
                          >
                            <FormattedMessage
                              id="table.head.originalPrice"
                              defaultMessage="Original Price"
                            />
                            {sortBy === "originalPrice" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("sellingPrice")}
                          >
                            <FormattedMessage
                              id="table.head.sellingPrice"
                              defaultMessage="Selling Price"
                            />
                            {sortBy === "sellingPrice" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th>
                            <FormattedMessage
                              id="table.head.Image"
                              defaultMessage="Image"
                            />
                          </th>
                          <th>
                            <FormattedMessage
                              id="table.head.AddAllClass"
                              defaultMessage="Image"
                            />
                          </th>
                          <th className="table-field-status">
                            <FormattedMessage
                              id="table.head.Actions"
                              defaultMessage="Action"
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* <?<5;$i++) { ?> */}
                        {Object.keys(indicatorsList || {}).length &&
                          indicatorsList.map((i: any) => (
                            <tr>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() => viewIndicatorDetail(i.id)}
                              >
                                {i.id}
                              </td>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() => viewIndicatorDetail(i.id)}
                              >
                                {i.name}
                              </td>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() => viewIndicatorDetail(i.id)}
                              >
                                {i.cost}
                              </td>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() => viewIndicatorDetail(i.id)}
                              >
                                {i.originalPrice}
                              </td>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() => viewIndicatorDetail(i.id)}
                              >
                                {i.sellingPrice}
                              </td>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() => viewIndicatorDetail(i.id)}
                              >
                                <img
                                  className="img-thumbanail"
                                  src={i?.image_path || Indicator}
                                />
                              </td>
                              <td style={{ cursor: "pointer" }}>
                                <button
                                  style={{
                                    background: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    setAddToAllClass({ modal: true, id: i.id })
                                  }
                                >
                                  <ClassesIcon />{" "}
                                </button>
                              </td>

                              <td className="table-field-status">
                                <Dropdown className="btn-group">
                                  <Dropdown.Toggle
                                    id="dropdown-basic"
                                    className="btn btn-sm btn-icon-only"
                                  >
                                    <i className="icon dripicons-dots-3 zmdi-hc-fw"></i>
                                  </Dropdown.Toggle>
                                  <AppendedMyComponent>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        href="#"
                                        onClick={() => editIndicatorSelected(i)}
                                      >
                                        <i className="text-accent-custom">
                                          <FaEdit />{" "}
                                        </i>
                                        <FormattedMessage
                                          id="page.Edit"
                                          defaultMessage="Edit"
                                        />
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        href="#"
                                        onClick={() =>
                                          setIndicatorDelModalShow({
                                            modal: true,
                                            id: i.id,
                                          })
                                        }
                                      >
                                        <i className="fa-fw text-accent-custom">
                                          <FaTrash />{" "}
                                        </i>
                                        <FormattedMessage
                                          id="page.Delete"
                                          defaultMessage="Delete"
                                        />
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </AppendedMyComponent>
                                </Dropdown>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <p>{!isLoading && <NoDatFound />}</p>
                )}
              </div>
              {totalRecord > 10 && indicatorsList.length && (
                <>
                  <FormControl className="pagelimit">
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      <FormattedMessage
                        id="page_size"
                        defaultMessage="Page Size"
                      />
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={pageLimit}
                      // defaultValue={pageLimit}
                      onChange={(e: any) => {
                        setPageLimit(e.target.value);
                        localStorage.setItem("pageLimit", e.target.value);
                        localStorage.setItem("pageNumber", pageNumber);
                        setPageNumber(1);
                      }}
                    >
                      <FormControlLabel
                        value={10}
                        control={<Radio />}
                        label="10"
                      />
                      <FormControlLabel
                        value={20}
                        control={<Radio />}
                        label="20"
                      />
                      <FormControlLabel
                        value={50}
                        control={<Radio />}
                        label="50"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Pagination
                    itemsPerPage={pageLimit}
                    totalPages={totalRecord}
                    onPageChage={onPageChange}
                    page={pageNumber - 1}
                  />
                </>
              )}
            </div>
          </div>
        </section>
      </div>
      {indicatorDelModalShow.modal && (
        <DeletePopup
          onClose={() => setIndicatorDelModalShow({ modal: false, id: 0 })}
          onDeleteClick={deleteInd}
          open={indicatorDelModalShow.modal}
        />
      )}
      {indicatorModalShow && (
        <AddIndicatorModal
          onModalClose={() => setIndicatorModalShow(false)}
          editIndicator={edtIndicator}
          rolesList={roleList}
        />
      )}
      {addToAllClass.modal && (
        <EditToolClassModal
          onModalClose={() => setAddToAllClass({ modal: false, id: 0 })}
          //  onDeleteClick={() => graduateStudent(grdClass.id)}
          // open={grdClass.modal}
          //  isAPILoading={isLoading}
          selectedClass={[]}
          classList={classes}
          toolId={addToAllClass.id}
          TOOL={TOOLS_TICKET.INDICATOR}
        />
      )}
    </>
  );
};

export default Indicators;
