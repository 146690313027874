/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/APIServices/baseApiCall";
import {
  GET_INDICATORS_LIST,
  ADD_INDICATORS_LIST,
  EDIT_INDICATORS_LIST,
  GET_INDICATORS_DETAILS,
  GET_INDICATORS_VERSION_LIST,
  ADD_INDICATORS_VERSION,
  EDIT_INDICATORS_VERSION,
  DELETE_INDICATORS_VERSION,
  DELETE_INDICATOR,
  SET_PAGE,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { getAPIEndPoint, getPage, setToken } from "../../util/utils";
import {
  GET_INDICATORS_LIST_URL,
  ADD_INDICATORS_LIST_URL,
  EIDT_INDICATORS_LIST_URL,
  GET_INDICATORS_VERSION_LIST_URL,
  ADD_INDICATORS_VERSION_URL,
  EDIT_INDICATORS_VERSION_URL,
  DELETE_INDICATORS_VERSION_URL,
  DELETE_INDICATOR_URL,
} from "../../service/APIServices/apiEndPoints";
import { toast } from "react-toastify";
import { AnyAaaaRecord } from "dns";
import {
  _UploadMedia,
  _UploadTool,
} from "../../service/MediaUpload/classes.service";
// import { _getExpressWayPendingList } from "../../service";
// import { AxiosResponse } from "axios";

export const setInCurrentPage =
  (pageNumber: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: SET_PAGE,
      payload: pageNumber,
    });
  };

//Get Admin Roles List
export const getIndicatorsList =
  (
    showToast = false,
    pageNumber = 0,
    sort = { order: "DESC", by: "createdAt" },
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getIndicatorsListInit(showToast, pageNumber, sort, pageLimit));
  };

export const getIndicatorsListInit =
  (
    showToast: boolean,
    pageNumber: number,
    sort: any,
    pageLimit: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_INDICATORS_LIST.GET_INDICATORS_LIST_INITIALIZATION,
    });
    apiCall(
      getAPIEndPoint(
        GET_INDICATORS_LIST_URL + `?sort=${sort.order}&sortBy=${sort.by}`,
        pageNumber,
        true,
        pageLimit,
      ),
      {},
      (res: Object) => dispatch(getIndicatorsListSuccess(res)),
      (err: any) => dispatch(getIndicatorsListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getIndicatorsListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_INDICATORS_LIST.GET_INDICATORS_LIST_SUCCESS,
      payload: res,
    });
  };

export const getIndicatorsListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_INDICATORS_LIST.GET_INDICATORS_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const addIndicatorsList =
  (
    showToast = true,
    indicatorData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch({
      type: ADD_INDICATORS_LIST.ADD_INDICATORS_LIST_INITIALIZATION,
    });
    let url = "";

    const files = indicatorData.image_path;
    if (files) {
      const { fileUploadResponse, error }: any = await _UploadMedia(
        files as File,
      );
      url = fileUploadResponse;
      if (error) {
        dispatch(addIndicatorsListError());
        return;
      }
    }
    indicatorData.image_path = url;
    dispatch(addIndicatorsListInit(showToast, indicatorData));
  };

export const addIndicatorsListInit =
  (
    showToast = true,
    indicatorData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    apiCall(
      ADD_INDICATORS_LIST_URL,
      indicatorData,
      (res: Object) => dispatch(addIndicatorsListSuccess(res)),
      (err: any) => dispatch(addIndicatorsListError()),
      METHOD.POST,
      { showToast },
    );
  };

export const addIndicatorsListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_INDICATORS_LIST.ADD_INDICATORS_LIST_SUCCESS,
    });

    dispatch(setInCurrentPage(1));
    dispatch(getIndicatorsList(false, 1));
    // localStorage.clear();
    // toast.success("addIndicatorsList successfully")
    // window.location.reload();
  };

export const addIndicatorsListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_INDICATORS_LIST.ADD_INDICATORS_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const editIndicatorsList =
  (
    showToast = true,
    id: any,
    indicatorData: any,
    isFile: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch({
      type: EDIT_INDICATORS_LIST.EDIT_INDICATORS_LIST_INITIALIZATION,
    });

    if (isFile) {
      const files = indicatorData.image_path;
      const { fileUploadResponse, error }: any = await _UploadMedia(
        files as File,
      );
      if (error) {
        dispatch(editIndicatorsListError());
        return;
      }
      indicatorData.image_path = fileUploadResponse;
    }
    dispatch(editIndicatorsListInit(showToast, id, indicatorData));
  };

export const editIndicatorsListInit =
  (
    showToast = true,
    id: any,
    indicatorData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    apiCall(
      EIDT_INDICATORS_LIST_URL + id,
      indicatorData,
      (res: Object) => dispatch(editIndicatorsListSuccess(res)),
      (err: any) => dispatch(editIndicatorsListError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const editIndicatorsListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_INDICATORS_LIST.EDIT_INDICATORS_LIST_SUCCESS,
      payload: res.data,
    });
    dispatch(setInCurrentPage(1));
    dispatch(getIndicatorsList(false, 1));
    // localStorage.clear();
    // toast.success("editIndicatorsList successfully")
    // window.location.reload();
  };

//Get Admin Roles
export const deleteIndicators =
  (
    showToast = true,
    indicator: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(deleteIndicatorsInit(showToast, indicator));
  };

export const deleteIndicatorsInit =
  (
    showToast = true,
    indicator: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_INDICATOR.DELETE_INDICATOR_INITIALIZATION,
    });
    apiCall(
      `${DELETE_INDICATOR_URL}${indicator}`,
      {},
      (res: Object) => dispatch(deleteIndicatorsSuccess(res)),
      (err: any) => dispatch(deleteIndicatorsError()),
      METHOD.DELETE,
      { showToast },
    );
  };

export const deleteIndicatorsSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_INDICATOR.DELETE_INDICATOR_SUCCESS,
      payload: res.data,
    });

    dispatch(getIndicatorsList(false));
    // localStorage.clear();
    // toast.success("deleteIndicators successfully")
    // window.location.reload();
  };

export const deleteIndicatorsError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: DELETE_INDICATOR.DELETE_INDICATOR_ERROR,
    });
  };

export const editIndicatorsListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: EDIT_INDICATORS_LIST.EDIT_INDICATORS_LIST_ERROR,
    });
  };

export const getIndicatorsDetails =
  (
    showToast = false,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getIndicatorsDetailsInit(showToast, id));
  };

export const getIndicatorsDetailsInit =
  (
    showToast: boolean,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_INDICATORS_DETAILS.GET_INDICATORS_DETAILS_INITIALIZATION,
    });
    apiCall(
      GET_INDICATORS_LIST_URL + "/" + id,
      {},
      (res: Object) => dispatch(getIndicatorsDetailsSuccess(res)),
      (err: any) => dispatch(getIndicatorsDetailsError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getIndicatorsDetailsSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_INDICATORS_DETAILS.GET_INDICATORS_DETAILS_SUCCESS,
      payload: res.data,
    });
    // localStorage.clear();
    // toast.success("getIndicatorsDetails successfully")
    // window.location.reload();
  };

export const getIndicatorsDetailsError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_INDICATORS_DETAILS.GET_INDICATORS_DETAILS_ERROR,
    });
  };

//Get Admin Roles List
export const getIndicatorsVersionList =
  (
    showToast = true,
    indicator: string,
    pageNumber = 0,
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      getIndicatorsVersionListInit(showToast, indicator, pageNumber, pageLimit),
    );
  };

export const getIndicatorsVersionListInit =
  (
    showToast = true,
    indicator: string,
    pageNumber: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_INDICATORS_VERSION_LIST.GET_INDICATORS_VERSION_LIST_INITIALIZATION,
    });
    apiCall(
      getAPIEndPoint(
        `${GET_INDICATORS_VERSION_LIST_URL}${indicator}`,
        pageNumber,
        false,
        pageLimit,
      ),
      {},
      (res: Object) => dispatch(getIndicatorsVersionListSuccess(res)),
      (err: any) => dispatch(getIndicatorsVersionListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getIndicatorsVersionListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_INDICATORS_VERSION_LIST.GET_INDICATORS_VERSION_LIST_SUCCESS,
      payload: res,
    });
  };

export const getIndicatorsVersionListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_INDICATORS_VERSION_LIST.GET_INDICATORS_VERSION_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const addIndicatorsVersion =
  (
    showToast = true,
    indicator: any,
    classId: any,
    isFile: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch({
      type: ADD_INDICATORS_VERSION.ADD_INDICATORS_VERSION_INITIALIZATION,
    });

    if (isFile.toolUpload) {
      const toolFile = indicator.download_path;
      const { fileUploadResponse, error }: any = await _UploadTool(
        toolFile as File,
      );
      if (!error) {
        indicator.download_path = fileUploadResponse;
      } else {
        dispatch(addIndicatorsVersionError());
        return;
      }
    }

    if (isFile.manualUpload) {
      const toolFile = indicator.manual_path;
      const { fileUploadResponse, error }: any = await _UploadMedia(
        toolFile as File,
      );
      if (!error) {
        indicator.manual_path = fileUploadResponse;
      } else {
        dispatch(addIndicatorsVersionError());
        return;
      }
    }

    dispatch(addIndicatorsVersionInit(showToast, indicator, classId));
  };

export const addIndicatorsVersionInit =
  (
    showToast = true,
    indicator: any,
    classId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_INDICATORS_VERSION.ADD_INDICATORS_VERSION_INITIALIZATION,
    });
    apiCall(
      `${ADD_INDICATORS_VERSION_URL}`,
      indicator,
      (res: Object) => dispatch(addIndicatorsVersionSuccess(res, classId)),
      (err: any) => dispatch(addIndicatorsVersionError()),
      METHOD.POST,
      { showToast },
    );
  };

export const addIndicatorsVersionSuccess =
  (res: any, classId: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_INDICATORS_VERSION.ADD_INDICATORS_VERSION_SUCCESS,
      payload: res.data,
    });

    // const page = getPage();
    dispatch(setInCurrentPage(1));

    dispatch(getIndicatorsVersionList(false, classId, 1));

    // localStorage.clear();
    // toast.success("addIndicatorsVersion successfully")
    // window.location.reload();
  };

export const addIndicatorsVersionError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_INDICATORS_VERSION.ADD_INDICATORS_VERSION_ERROR,
    });
  };

//Get Admin Roles
export const editIndicatorsVersion =
  (
    showToast = true,
    id: any,
    indicatorVersion: any,
    classId: any,
    isFile: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch({
      type: EDIT_INDICATORS_VERSION.EDIT_INDICATORS_VERSION_INITIALIZATION,
    });
    if (isFile.toolUpload) {
      const toolFile = indicatorVersion.download_path;
      const { fileUploadResponse, error }: any = await _UploadTool(
        toolFile as File,
      );
      if (!error) {
        indicatorVersion.download_path = fileUploadResponse;
      } else {
        dispatch(editIndicatorsVersionError());
        return;
      }
    }

    if (isFile.manualUpload) {
      const toolFile = indicatorVersion.manual_path;
      const { fileUploadResponse, error }: any = await _UploadMedia(
        toolFile as File,
      );
      if (!error) {
        indicatorVersion.manual_path = fileUploadResponse;
      } else {
        dispatch(editIndicatorsVersionError());
        return;
      }
    }
    dispatch(
      editIndicatorsVersionInit(showToast, id, indicatorVersion, classId),
    );
  };

export const editIndicatorsVersionInit =
  (
    showToast = true,
    id: any,
    indicatorVersion: any,
    classId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    apiCall(
      `${EDIT_INDICATORS_VERSION_URL}${id}`,
      indicatorVersion,
      (res: Object) => dispatch(editIndicatorsVersionSuccess(res, classId)),
      (err: any) => dispatch(editIndicatorsVersionError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const editIndicatorsVersionSuccess =
  (res: any, classId: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_INDICATORS_VERSION.EDIT_INDICATORS_VERSION_SUCCESS,
      payload: res.data,
    });
    dispatch(setInCurrentPage(1));
    dispatch(getIndicatorsVersionList(false, classId, 1));
  };

export const editIndicatorsVersionError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: EDIT_INDICATORS_VERSION.EDIT_INDICATORS_VERSION_ERROR,
    });
  };

//Get Admin Roles
export const deleteIndicatorsVersion =
  (
    showToast = true,
    indicator: any,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(deleteIndicatorsVersionInit(showToast, indicator, id));
  };

export const deleteIndicatorsVersionInit =
  (
    showToast = true,
    indicator: any,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_INDICATORS_VERSION.DELETE_INDICATORS_VERSION_INITIALIZATION,
    });
    apiCall(
      `${DELETE_INDICATORS_VERSION_URL}${indicator}`,
      {},
      (res: Object) => dispatch(deleteIndicatorsVersionSuccess(res, id)),
      (err: any) => dispatch(deleteIndicatorsVersionError()),
      METHOD.DELETE,
      { showToast },
    );
  };

export const deleteIndicatorsVersionSuccess =
  (res: any, id: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_INDICATORS_VERSION.DELETE_INDICATORS_VERSION_SUCCESS,
      payload: res.data,
    });
    dispatch(getIndicatorsVersionList(false, id));
    // localStorage.clear();
    // toast.success("deleteIndicatorsVersion successfully")
    // window.location.reload();
  };

export const deleteIndicatorsVersionError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: DELETE_INDICATORS_VERSION.DELETE_INDICATORS_VERSION_ERROR,
    });
  };
