import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import AddAnnouncementModal from "../../Components/Announcement/AddAnnouncementModal";
import { getAnnouncements } from "../../redux/action/announcementsActions";
import { Store } from "../../redux/Actions";
import { arrayToStr, customFormatDatetimeWithLocale } from "../../util/utils";
import { useNavigate } from "react-router";
import { getClasses, setMediaUpload } from "../../redux/action";
import { FormattedMessage } from "react-intl";
import Pagination from "../../Components/common/Paginate/Pagination";
import NoDatFound from "../../Components/common/NoDatFound";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import { Link } from "react-router-dom";
import { Settings } from "@mui/icons-material";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

const Announcement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const announcement = useSelector(
    (state: Store) => state.announcementsReducer.announcements,
  ) as any;
  const classes = useSelector(
    (state: Store) => state.classReducer.classes,
  ) as any;
  const isLoading: any = useSelector(
    (state: Store) => state.announcementsReducer.isLoading,
  ) as any;

  const [pageNumber, setPageNumber] = useState<any>();
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );

  const totalRecord = useSelector(
    (state: Store) => state.announcementsReducer.totalRecord,
  ) as any;

  const locale = useSelector((state: Store) => state.userDataReducer.local);

  useEffect(() => {
    if (pageNumber && pageLimit) {
      dispatch(getAnnouncements(false, pageNumber, pageLimit));
      dispatch(getClasses(false, 0, { by: "createdAt", order: "ASC" }));
    }
  }, [pageNumber, pageLimit]);

  useEffect(() => {
    const storedPageNumber: any = localStorage.getItem("pageNumber");
    const storedPageLimit: any = localStorage.getItem("pageLimit");
    if (storedPageNumber && storedPageLimit) {
      setPageNumber(parseInt(storedPageNumber));
      setPageLimit(parseInt(storedPageLimit));
    } else {
      setPageNumber(1);
      setPageLimit(10);
    }
  }, []);

  const [announcementModalShow, setAnnouncementModalShow] = useState(false);
  const viewClassDetail = (id: any) => {
    localStorage.setItem("pageNumber", pageNumber);
    localStorage.setItem("pageLimit", pageLimit);
    navigate("/announcements/" + id);
  };

  const onPageChange = (pageNum: number) => {
    localStorage.setItem("pageNumber", pageNum.toString());
    localStorage.setItem("pageLimit", pageLimit);
    setPageNumber(pageNum);
  };

  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                <FormattedMessage
                  id="Admin.Announcement"
                  defaultMessage="Admin Announcements "
                />
              </h1>
            </div>
            <div className="m-l-10 announcement-button">
              <Link
                className="btn btn-secondary mr-2"
                to="/announcements/group/settings"
              >
                {" "}
                <Settings />
                <span style={{ verticalAlign: "top" }}>
                  {" "}
                  <label style={{ marginTop: "1px" }}>
                    <FormattedMessage
                      id="Tickets.groupSettings"
                      defaultMessage="Group Settings"
                    />{" "}
                  </label>
                </span>
              </Link>
              <a
                href="#"
                data-toggle="modal"
                onClick={() => setAnnouncementModalShow(true)}
                data-target="#AddCourse"
                type="button"
                className="btn btn-primary"
              >
                <FormattedMessage
                  id="Admin.Make.Announcement"
                  defaultMessage=" Make an Announcement"
                />
              </a>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          <Card>
            <Card.Body className="p-0">
              {isLoading && <CenterLoader />}
              <div className="table-responsive">
                {announcement.length ? (
                  <>
                    {" "}
                    <table className="table table-hover m-0">
                      <thead>
                        <tr>
                          <th>
                            <FormattedMessage
                              id="Admin.Title.Announcement"
                              defaultMessage="Title"
                            />
                          </th>
                          <th style={{ width: `60%` }}>
                            <FormattedMessage
                              id="Admin.Class.Announcement"
                              defaultMessage="Class"
                            />
                          </th>
                          <th>
                            <FormattedMessage
                              id="Admin.Date.Announcement"
                              defaultMessage="Date"
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {announcement.length &&
                          announcement.map((i: any) => (
                            <tr
                              onClick={() => viewClassDetail(i.id)}
                              className="pointer"
                            >
                              <td>{i.title}</td>
                              <td style={{ wordBreak: `break-all` }}>
                                {arrayToStr(i.class_group, "name")}{" "}
                              </td>
                              <td style={{ whiteSpace: `nowrap` }}>
                                {customFormatDatetimeWithLocale(
                                  i.createdAt,
                                  locale,
                                )}{" "}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <p>{!isLoading && <NoDatFound />}</p>
                )}
              </div>
              {totalRecord > 10 && announcement.length > 0 && (
                <>
                  <FormControl className="pagelimit">
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      <FormattedMessage
                        id="page_size"
                        defaultMessage="Page Size"
                      />
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={pageLimit}
                      onChange={(e: any) => {
                        setPageLimit(e.target.value);
                        setPageNumber(1);
                      }}
                    >
                      <FormControlLabel
                        value={10}
                        control={<Radio />}
                        label="10"
                      />
                      <FormControlLabel
                        value={20}
                        control={<Radio />}
                        label="20"
                      />
                      <FormControlLabel
                        value={50}
                        control={<Radio />}
                        label="50"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Pagination
                    itemsPerPage={pageLimit}
                    totalPages={totalRecord}
                    onPageChage={onPageChange}
                    page={pageNumber - 1}
                  />
                </>
              )}
            </Card.Body>
          </Card>
        </section>
      </div>
      {announcementModalShow && (
        <AddAnnouncementModal
          onModalClose={() => {
            setAnnouncementModalShow(false);
            dispatch(setMediaUpload("announcementAttachment"));
          }}
          classList={classes}
        />
      )}
    </div>
  );
};

export default Announcement;
