import { memo, useState, type ReactNode, useCallback } from "react";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from "material-react-table";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useGetAllPlanCategories } from "../../features/planCategories/api";
import { PlanCategory } from "../../features/planCategories/interfaces";
import CreatePlanCategoryModal from "./CreatePlanCategoryModal";

const columnHelper = createMRTColumnHelper<PlanCategory>();
const columns = [
  columnHelper.accessor((row) => row.id, {
    header: "Id",
    size: 96,
  }),
  columnHelper.accessor((row) => row.name, {
    header: "Name",
  }),
  columnHelper.accessor((row) => new Date(row.createdAt), {
    header: "Created At",
    Cell: (props) => props.cell.getValue().toISOString(),
    sortingFn: "datetime",
    filterVariant: "date-range",
  }),
  columnHelper.accessor((row) => new Date(row.updatedAt), {
    header: "Updated At",
    Cell: (props) => props.cell.getValue().toISOString(),
    sortingFn: "datetime",
    filterVariant: "date-range",
  }),
];

export const PlanCategoriesTable = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    [],
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: "id", desc: true },
  ]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, isError, isFetching, isLoading, refetch } =
    useGetAllPlanCategories({
      globalFilter,
      columnFilters,
      sorting,
      ...pagination,
    });

  const renderTopToolbarCustomActions = useCallback((): ReactNode => {
    return (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
        <Button
          color="secondary"
          onClick={() => setCreateModalOpen(true)}
          variant="contained"
        >
          Create Plan Category
        </Button>
      </Box>
    );
  }, [refetch]);

  return (
    <>
      <PlanCategoriesListWrapper>
        <MaterialReactTable
          layoutMode="grid"
          columns={columns}
          data={data?.planCategories ?? []}
          enableColumnOrdering
          enableColumnResizing
          enableEditing
          enableGrouping
          enableColumnPinning
          enableMultiSort
          initialState={{ density: "compact", showColumnFilters: true }}
          manualFiltering
          manualPagination
          manualSorting
          muiToolbarAlertBannerProps={
            isError
              ? { color: "error", children: "Error loading data" }
              : undefined
          }
          isMultiSortEvent={() => true}
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          renderTopToolbarCustomActions={renderTopToolbarCustomActions}
          rowCount={data?.planCategoriesCount ?? 0}
          state={{
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isFetching,
            sorting,
          }}
        />
      </PlanCategoriesListWrapper>
      <CreatePlanCategoryModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
      />
    </>
  );
};

const PlanCategoriesListWrapper = memo(function PlanCategoriesListWrapper({
  children,
}: {
  children?: ReactNode;
}) {
  return (
    <section className="page-content container-fluid">
      <div className="card">
        <div className="card-body p-0">
          <div className="table-responsive">{children}</div>
        </div>
      </div>
    </section>
  );
});

export default PlanCategoriesTable;
