import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { BsDash, BsPlus } from "react-icons/bs";
import { IconButton } from "@mui/material";
import Stack from "@mui/material/Stack";
import Loader from "../common/Loader";
import { Store } from "../../redux/Actions";
import CenterLoader from "../common/CenterLoader/CenterLoader";
import { dispatch } from "../../redux";
import { setAccountLimit } from "../../redux/action";
import { isPositiveNumbersOnly } from "../../util/utils";
import { FaTimesCircle } from "react-icons/fa";

const AccountLimitSetting = ({ onClose, open, id, data }: any) => {
  const [buttonClick, setButtonClick] = useState(false);
  const [accounts, setAccounts] = useState<any>({
    demoLimit: 0,
    liveLimit: 0,
  });

  const [accountsCreated, setAccountsCreated] = useState({
    demoLimit: 0,
    liveLimit: 0,
  });

  const isLoading = useSelector(
    (state: Store) => state.expertAdvisorReducer.isLoading,
  );
  const onSaveLoading = useSelector(
    (state: Store) => state.vpsMachineReducer.onSaveLoading,
  );

  const [accountLimitError, setAccountLimitError] = useState({
    demoLimit: false,
    liveLimit: false,
  });
  useEffect(() => {
    setAccountLimitError(() => {
      const newState = { demoLimit: false, liveLimit: false };
      if (accounts.demoLimit - accountsCreated.demoLimit < 0) {
        newState.demoLimit = true;
      }
      if (accounts.liveLimit - accountsCreated.liveLimit < 0) {
        newState.liveLimit = true;
      }
      return newState;
    });
  }, [accounts, accountsCreated]);

  useEffect(() => {
    const account: any = {
      demoLimit: data.demoLimit || 0,
      liveLimit: data.liveLimit || 0,
    };

    const accountsCreated = {
      demoLimit: data.demoAccountCreated || 0,
      liveLimit: data.liveAccountCreated || 0,
    };

    setAccountsCreated(accountsCreated);
    setAccounts(account);
  }, [id]);

  // useEffect(() => {
  //   if (adminAllowed.length > 0 && (UserVPSMachineList?.length || []) > 0) {
  //     const lstAdminAllowed: any = adminAllowed;
  //     const lstUserCreated: any = userCreated;

  //     UserVPSMachineList.map((i: any) => {
  //       lstAdminAllowed.map((id: any) => {
  //         if (id.vpsTypeId === i.vpsType.id) {
  //           id.adminAllowed = i.adminAllowed;
  //         }
  //       });

  //       lstUserCreated.map((ids: any) => {
  //         if (ids.vpsTypeId === i.vpsType.id) {
  //           ids.userCreated = i.userCreated;
  //         }
  //       });
  //     });

  //     setAdminAllowed(() => [...lstAdminAllowed]);
  //     setUserCreated(() => [...lstUserCreated]);
  //   }
  // }, [UserVPSMachineList]);

  // useEffect(() => {
  //   const lstAdminAllowed: any = [];
  //   const lstUserCreated: any = [];

  //   if (VPSMachineTypeList.length > 0) {
  //     VPSMachineTypeList.map((i: any) => {
  //       lstAdminAllowed.push({
  //         vpsTypeId: i.id,
  //         adminAllowed: 0,
  //       });

  //       lstUserCreated.push({
  //         vpsTypeId: i.id,
  //         vpsTypeName: i.typeName,
  //         userCreated: 0,
  //       });
  //     });
  //   }

  //   setAdminAllowed(() => [...lstAdminAllowed]);
  //   setUserCreated(() => [...lstUserCreated]);
  //   // dispatch(getUserVpsMachineAccessList(false, id));
  // }, [VPSMachineTypeList]);

  useEffect(() => {
    if (!onSaveLoading && buttonClick) {
      handleClose();
      setButtonClick(false);
    }
  }, [onSaveLoading, buttonClick]);

  const handleClose = () => {
    onClose(false);
  };

  const handleGo = () => {
    if (accountLimitError.demoLimit || accountLimitError.liveLimit) {
      return;
    }
    if (!buttonClick) {
      dispatch(setAccountLimit(accounts, id));
      setButtonClick(true);
    }
  };

  return (
    <>
      {!isLoading ? (
        <Modal centered size={"lg"} show={open} onHide={() => onClose()}>
          <Modal.Header>
            <h4 className="modal-title">
              <FormattedMessage
                id="head.accountSetting"
                defaultMessage="View User Slip "
              />
            </h4>
            <button className="close" onClick={() => onClose()}>
              <FaTimesCircle />
            </button>
          </Modal.Header>
          <Modal.Body>
            <table
              style={{
                width: "100%",
                borderCollapse: "initial",
                borderSpacing: "1rem",
              }}
            >
              <thead>
                <tr>
                  <th></th>
                  <th>
                    {" "}
                    <FormattedMessage
                      id="head.limit"
                      defaultMessage="Created"
                    />
                  </th>
                  <th>
                    {" "}
                    <FormattedMessage
                      id="head.created"
                      defaultMessage="Created"
                    />
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td style={{ width: "25%" }}>
                    <FormattedMessage
                      id="head.demoAccount"
                      defaultMessage="Created"
                    />
                  </td>
                  <td>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <input
                        style={{ backgroundColor: "white", width: "39%" }}
                        type="text"
                        className="form-control"
                        value={
                          accounts.demoLimit === null
                            ? "null"
                            : accounts.demoLimit
                        }
                        onChange={(e: any) => {
                          if (isPositiveNumbersOnly(e, e.target.value)) {
                            if (e.target.value >= 0) {
                              setAccounts((prv: any) => ({
                                ...prv,
                                demoLimit: e.target.value,
                              }));
                            }
                          }
                          return false;
                        }}
                        min={0}
                      />
                    </Stack>
                    {accountLimitError.demoLimit ? (
                      <div className="text-error" style={{ maxWidth: "200px" }}>
                        <FormattedMessage
                          id="demoAccount.invalid"
                          defaultMessage="testing"
                        />
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      style={{ backgroundColor: "white", width: "35%" }}
                      type="number"
                      min={0}
                      className="form-control"
                      value={accountsCreated.demoLimit}
                      disabled
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <FormattedMessage
                      id="head.liveAccount"
                      defaultMessage="Created"
                    />
                  </td>
                  <td>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <input
                        style={{ backgroundColor: "white", width: "39%" }}
                        type="text"
                        min={0}
                        className="form-control"
                        value={
                          accounts.liveLimit === null
                            ? "null"
                            : accounts.liveLimit
                        }
                        onChange={(e: any) => {
                          if (isPositiveNumbersOnly(e, e.target.value)) {
                            if (e.target.value >= 0) {
                              setAccounts((prv: any) => ({
                                ...prv,
                                liveLimit: e.target.value,
                              }));
                            }
                          }
                          return false;
                        }}
                      />
                    </Stack>
                    {accountLimitError.liveLimit ? (
                      <div className="text-error" style={{ maxWidth: "200px" }}>
                        <FormattedMessage
                          id="liveAccount.invalid"
                          defaultMessage="testing"
                        />
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      style={{ backgroundColor: "white", width: "35%" }}
                      type="number"
                      min={0}
                      className="form-control"
                      value={accountsCreated.liveLimit}
                      disabled
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={() => handleGo()}>
              <FormattedMessage id="page.submit" defaultMessage="GO" />
              {onSaveLoading && <Loader />}
            </button>
          </Modal.Footer>
        </Modal>
      ) : (
        <CenterLoader />
      )}
    </>
  );
};

AccountLimitSetting.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  data: PropTypes.any,
};
export default AccountLimitSetting;
