import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { BsDash, BsPlus } from "react-icons/bs";
import { IconButton } from "@mui/material";
import Stack from "@mui/material/Stack";
import Loader from "../common/Loader";
import { Store } from "../../redux/Actions";
import CenterLoader from "../common/CenterLoader/CenterLoader";
import {
  editUserVpsMachineAccessList,
  getUserVpsMachineAccessList,
  getVPSMachineTypeList,
} from "../../redux/action/vpsMachineActions";
import { dispatch } from "../../redux";
import { FaTimesCircle } from "react-icons/fa";

const UserVpsMachineModal = ({ onClose, open, id }: any) => {
  const [userCreatedThreeMonths, setUserCreatedThreeMonths] = React.useState<
    Array<any>
  >([]);
  const [userCreatedSixMonths, setUserCreatedSixMonths] = React.useState<
    Array<any>
  >([]);
  const [userCreatedTwelveMonths, setUserCreatedTwelveMonths] = React.useState<
    Array<any>
  >([]);
  const [adminAllowedThreeMonths, setAdminAllowedThreeMonths] = React.useState<
    Array<any>
  >([]);
  const [adminAllowedSixMonths, setAdminAllowedSixMonths] = React.useState<
    Array<any>
  >([]);
  const [adminAllowedTwelveMonths, setAdminAllowedTwelveMonths] =
    React.useState<Array<any>>([]);
  const [error, setError] = React.useState<any>("");
  const [buttonClick, setButtonClick] = React.useState(false);

  const VPSMachineTypeList = useSelector(
    (state: Store) => state.vpsMachineReducer.VPSMachineTypeList,
  );
  const UserVPSMachineList = useSelector(
    (state: Store) => state.vpsMachineReducer.UserVPSMachineList,
  );
  const isLoading = useSelector(
    (state: Store) => state.vpsMachineReducer.isLoading,
  );
  const onSaveLoading = useSelector(
    (state: Store) => state.vpsMachineReducer.onSaveLoading,
  );

  useEffect(() => {
    if (parseInt(id) > 0) {
      dispatch(getVPSMachineTypeList());
    }
  }, [id]);

  useEffect(() => {
    if (
      adminAllowedThreeMonths.length > 0 &&
      (UserVPSMachineList?.length || []) > 0
    ) {
      const lstAdminAllowed: any = [...adminAllowedThreeMonths];
      const lstUserCreated: any = [...userCreatedThreeMonths];

      UserVPSMachineList.map((i: any) => {
        lstAdminAllowed.map((id: any) => {
          if (id.vpsTypeId === i.vpsType.id) {
            id.adminAllowedThreeMonths = i.threeMonthsAllow;
          }
        });

        lstUserCreated.map((ids: any) => {
          if (ids.vpsTypeId === i.vpsType.id) {
            ids.userCreatedThreeMonths = i.userCreatedThreeMonths;
          }
        });
      });

      setAdminAllowedThreeMonths(() => [...lstAdminAllowed]);
      setUserCreatedThreeMonths(() => [...lstUserCreated]);
    }
    if (
      adminAllowedSixMonths.length > 0 &&
      (UserVPSMachineList?.length || []) > 0
    ) {
      const lstAdminAllowed: any = [...adminAllowedSixMonths];
      const lstUserCreated: any = [...userCreatedSixMonths];
      UserVPSMachineList.map((i: any) => {
        lstAdminAllowed.map((id: any) => {
          if (id.vpsTypeId === i.vpsType.id) {
            id.adminAllowedSixMonths = i.sixMonthsAllow;
          }
        });

        lstUserCreated.map((ids: any) => {
          if (ids.vpsTypeId === i.vpsType.id) {
            ids.userCreatedSixMonths = i.userCreatedSixMonths;
          }
        });
      });

      setAdminAllowedSixMonths(() => [...lstAdminAllowed]);
      setUserCreatedSixMonths(() => [...lstUserCreated]);
    }
    if (
      adminAllowedTwelveMonths.length > 0 &&
      (UserVPSMachineList?.length || []) > 0
    ) {
      const lstAdminAllowed: any = adminAllowedTwelveMonths;
      const lstUserCreated: any = userCreatedTwelveMonths;
      UserVPSMachineList.map((i: any) => {
        lstAdminAllowed.map((id: any) => {
          if (id.vpsTypeId === i.vpsType.id) {
            id.adminAllowedTwelveMonths = i.twelveMonthsAllow;
          }
        });

        lstUserCreated.map((ids: any) => {
          if (ids.vpsTypeId === i.vpsType.id) {
            ids.userCreatedTwelveMonths = i.userCreatedTwelveMonths;
          }
        });
      });

      setAdminAllowedTwelveMonths(() => [...lstAdminAllowed]);
      setUserCreatedTwelveMonths(() => [...lstUserCreated]);
    }
  }, [UserVPSMachineList]);
  useEffect(() => {
    const lstAdminAllowedThreeMonths: any = [];
    const lstAdminAllowedSixMonths: any = [];
    const lstAdminAllowedTwelveMonths: any = [];
    const lstUserCreatedThreeMonths: any = [];
    const lstUserCreatedSixMonths: any = [];
    const lstUserCreatedTwelveMonths: any = [];

    if (VPSMachineTypeList.length > 0) {
      VPSMachineTypeList.map((i: any) => {
        lstAdminAllowedThreeMonths.push({
          vpsTypeId: i.id,
          adminAllowedThreeMonths: 0,
        });
        lstAdminAllowedSixMonths.push({
          vpsTypeId: i.id,
          adminAllowedSixMonths: 0,
        });
        lstAdminAllowedTwelveMonths.push({
          vpsTypeId: i.id,
          adminAllowedTwelveMonths: 0,
        });

        lstUserCreatedThreeMonths.push({
          vpsTypeId: i.id,
          vpsTypeName: i.typeName,
          userCreatedThreeMonths: 0,
        });

        lstUserCreatedSixMonths.push({
          vpsTypeId: i.id,
          vpsTypeName: i.typeName,
          userCreatedSixMonths: 0,
        });

        lstUserCreatedTwelveMonths.push({
          vpsTypeId: i.id,
          vpsTypeName: i.typeName,
          userCreatedTwelveMonths: 0,
        });
      });
    }

    setAdminAllowedThreeMonths(() => [...lstAdminAllowedThreeMonths]);
    setAdminAllowedSixMonths(() => [...lstAdminAllowedSixMonths]);
    setAdminAllowedTwelveMonths(() => [...lstAdminAllowedTwelveMonths]);

    setUserCreatedThreeMonths(() => [...lstUserCreatedThreeMonths]);
    setUserCreatedSixMonths(() => [...lstUserCreatedSixMonths]);
    setUserCreatedTwelveMonths(() => [...lstUserCreatedTwelveMonths]);

    dispatch(getUserVpsMachineAccessList(false, id));
  }, [VPSMachineTypeList]);

  useEffect(() => {
    if (!onSaveLoading && buttonClick) {
      handleClose();
      setButtonClick(false);
    }
  }, [onSaveLoading, buttonClick]);

  const handleClose = () => {
    onClose(false);
  };

  const handleGo = () => {
    if (!buttonClick) {
      const isAllCorrect: any[] = [];
      adminAllowedThreeMonths.map((i: any, index: any) => {
        if (
          i.adminAllowedThreeMonths <
          userCreatedThreeMonths[index].userCreatedThreeMonths
        ) {
          isAllCorrect.push(userCreatedThreeMonths[index].vpsTypeName);
        }
      });
      adminAllowedSixMonths.map((i: any, index: any) => {
        if (
          i.adminAllowedSixMonths <
          userCreatedSixMonths[index].userCreatedSixMonths
        ) {
          isAllCorrect.push(userCreatedSixMonths[index].vpsTypeName);
        }
      });
      adminAllowedTwelveMonths.map((i: any, index: any) => {
        if (
          i.adminAllowedTwelveMonths <
          userCreatedTwelveMonths[index].userCreatedTwelveMonths
        ) {
          isAllCorrect.push(userCreatedTwelveMonths[index].vpsTypeName);
        }
      });

      if (isAllCorrect.length > 0) {
        const a: string = isAllCorrect.join(",");
        setError(a + " user created must be smaller than admin allowed.");
        toast.error(a + " user created must be smaller than admin allowed.");
      } else {
        setError("");
        const combinedArray = [];

        for (let i = 0; i < adminAllowedThreeMonths.length; i++) {
          const vpsTypeId = adminAllowedThreeMonths[i].vpsTypeId;
          const adminAllowedThreeMonthsValue =
            adminAllowedThreeMonths[i].adminAllowedThreeMonths;
          const adminAllowedSixMonthsValue =
            adminAllowedSixMonths[i].adminAllowedSixMonths;
          const adminAllowedTwelveMonthsValue =
            adminAllowedTwelveMonths[i].adminAllowedTwelveMonths;

          const combinedObject = {
            vpsTypeId: vpsTypeId,
            adminAllowedThreeMonths: adminAllowedThreeMonthsValue,
            adminAllowedSixMonths: adminAllowedSixMonthsValue,
            adminAllowedTwelveMonths: adminAllowedTwelveMonthsValue,
          };

          combinedArray.push(combinedObject);
        }

        dispatch(
          editUserVpsMachineAccessList(
            true,
            {
              adminAllowedList: [...combinedArray],
            },
            id,
          ),
        );
      }
      setButtonClick(true);
    }
  };

  const onAdminAllowedThreeMonths = (e: any, id: any) => {
    const lstAdminAllowed = [...adminAllowedThreeMonths];
    const lst = lstAdminAllowed.map((i: any, index: any) => {
      if (i.vpsTypeId === id) {
        if (e === "+") {
          i.adminAllowedThreeMonths = parseInt(i.adminAllowedThreeMonths) + 1;
        }
        if (e === "-") {
          const valAdminAllowed = parseInt(i.adminAllowedThreeMonths) - 1;
          if (
            valAdminAllowed >= 0 &&
            valAdminAllowed >=
              userCreatedThreeMonths[index].userCreatedThreeMonths
          ) {
            i.adminAllowedThreeMonths = parseInt(i.adminAllowedThreeMonths) - 1;
          } else {
            if (valAdminAllowed >= 0) {
              const a = userCreatedThreeMonths[index].vpsTypeName;
              toast.info(
                a + " admin allowed should be greater than user created",
              );
            }
          }
        }
      }
      return i;
    });
    setAdminAllowedThreeMonths(() => [...lst]);
  };

  const onAdminAllowedSixMonths = (e: any, id: any) => {
    const lstAdminAllowed = [...adminAllowedSixMonths];
    const lst = lstAdminAllowed.map((i: any, index: any) => {
      if (i.vpsTypeId === id) {
        if (e === "+") {
          i.adminAllowedSixMonths = parseInt(i.adminAllowedSixMonths) + 1;
        }
        if (e === "-") {
          const valAdminAllowed = parseInt(i.adminAllowedSixMonths) - 1;
          if (
            valAdminAllowed >= 0 &&
            valAdminAllowed >= userCreatedSixMonths[index].userCreatedSixMonths
          ) {
            i.adminAllowedSixMonths = parseInt(i.adminAllowedSixMonths) - 1;
          } else {
            if (valAdminAllowed >= 0) {
              const a = userCreatedSixMonths[index].vpsTypeName;
              toast.info(
                a + " admin allowed should be greater than user created",
              );
            }
          }
        }
      }
      return i;
    });
    setAdminAllowedSixMonths(() => [...lst]);
  };

  const onAdminAllowedTwelveMonths = (e: any, id: any) => {
    const lstAdminAllowed = [...adminAllowedTwelveMonths];
    const lst = lstAdminAllowed.map((i: any, index: any) => {
      if (i.vpsTypeId === id) {
        if (e === "+") {
          i.adminAllowedTwelveMonths = parseInt(i.adminAllowedTwelveMonths) + 1;
        }
        if (e === "-") {
          const valAdminAllowed = parseInt(i.adminAllowedTwelveMonths) - 1;
          if (
            valAdminAllowed >= 0 &&
            valAdminAllowed >=
              userCreatedTwelveMonths[index].userCreatedTwelveMonths
          ) {
            i.adminAllowedTwelveMonths =
              parseInt(i.adminAllowedTwelveMonths) - 1;
          } else {
            if (valAdminAllowed >= 0) {
              const a = userCreatedTwelveMonths[index].vpsTypeName;
              toast.info(
                a + " admin allowed should be greater than user created",
              );
            }
          }
        }
      }
      return i;
    });
    setAdminAllowedTwelveMonths(() => [...lst]);
  };

  return (
    <>
      {!isLoading ? (
        <Modal
          centered
          size={"lg"}
          show={open}
          onHide={() => onClose()}
          className="vpsmodal"
        >
          <Modal.Header>
            <h4 className="modal-title">
              <FormattedMessage
                id="head.VPSMachineSetting"
                defaultMessage="View User Slip "
              />
            </h4>
            <button className="close" onClick={() => onClose()}>
              <FaTimesCircle />
            </button>
          </Modal.Header>
          <Modal.Body>
            <table className="vpstable">
              <thead>
                <tr>
                  <th colSpan={3}>3-months</th>

                  <th colSpan={2}>6-months</th>

                  <th colSpan={2}>12-months</th>
                </tr>
                <tr>
                  <th>
                    <FormattedMessage
                      id="modal.vps.type"
                      defaultMessage="Type Name"
                    />
                  </th>

                  <th>
                    <FormattedMessage
                      id="modal.vps.adminAllowed"
                      defaultMessage="Admin Allowed"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="modal.vps.userCreated"
                      defaultMessage="User Created"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="modal.vps.adminAllowed"
                      defaultMessage="Admin Allowed"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="modal.vps.userCreated"
                      defaultMessage="User Created"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="modal.vps.adminAllowed"
                      defaultMessage="Admin Allowed"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="modal.vps.userCreated"
                      defaultMessage="User Created"
                    />
                  </th>
                </tr>
              </thead>

              <tbody>
                {VPSMachineTypeList.map((type: any, index: any) => (
                  <tr>
                    <td>{type.typeName}</td>
                    <td align="left">
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() =>
                            onAdminAllowedThreeMonths("-", type.id)
                          }
                        >
                          <BsDash fontSize="small" />
                        </IconButton>
                        <input
                          style={{ backgroundColor: "white", width: "25%" }}
                          type="number"
                          min={0}
                          className="form-control"
                          value={
                            adminAllowedThreeMonths[index]
                              ?.adminAllowedThreeMonths
                          }
                          disabled
                        />
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() =>
                            onAdminAllowedThreeMonths("+", type.id)
                          }
                        >
                          <BsPlus fontSize="small" />
                        </IconButton>
                      </Stack>
                    </td>
                    <td align="left">
                      <input
                        type="number"
                        min={0}
                        className="form-control"
                        style={{ width: "45%" }}
                        value={
                          userCreatedThreeMonths[index]?.userCreatedThreeMonths
                        }
                        disabled
                      />
                    </td>
                    <td align="left">
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() => onAdminAllowedSixMonths("-", type.id)}
                        >
                          <BsDash fontSize="small" />
                        </IconButton>
                        <input
                          style={{ backgroundColor: "white", width: "25%" }}
                          type="number"
                          min={0}
                          className="form-control"
                          value={
                            adminAllowedSixMonths[index]?.adminAllowedSixMonths
                          }
                          disabled
                        />
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() => onAdminAllowedSixMonths("+", type.id)}
                        >
                          <BsPlus fontSize="small" />
                        </IconButton>
                      </Stack>
                    </td>
                    <td align="left">
                      <input
                        type="number"
                        min={0}
                        className="form-control"
                        style={{ width: "45%" }}
                        value={
                          userCreatedSixMonths[index]?.userCreatedSixMonths
                        }
                        disabled
                      />
                    </td>
                    <td align="left">
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() =>
                            onAdminAllowedTwelveMonths("-", type.id)
                          }
                        >
                          <BsDash fontSize="small" />
                        </IconButton>
                        <input
                          style={{ backgroundColor: "white", width: "25%" }}
                          type="number"
                          min={0}
                          className="form-control"
                          value={
                            adminAllowedTwelveMonths[index]
                              ?.adminAllowedTwelveMonths
                          }
                          disabled
                        />
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() =>
                            onAdminAllowedTwelveMonths("+", type.id)
                          }
                        >
                          <BsPlus fontSize="small" />
                        </IconButton>
                      </Stack>
                    </td>
                    <td align="left">
                      <input
                        type="number"
                        min={0}
                        className="form-control"
                        style={{ width: "45%" }}
                        value={
                          userCreatedTwelveMonths[index]
                            ?.userCreatedTwelveMonths
                        }
                        disabled
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {error.length > 0 ? (
              <label className="text-error">{error}</label>
            ) : (
              ""
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={() => handleGo()}>
              <FormattedMessage id="page.submit" defaultMessage="GO" />
              {onSaveLoading && <Loader />}
            </button>
          </Modal.Footer>
        </Modal>
      ) : (
        <CenterLoader />
      )}
    </>
  );
};

UserVpsMachineModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
};
export default UserVpsMachineModal;
