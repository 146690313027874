import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdminRolesUserLoggingsList } from "../../redux/action";
import { Store } from "../../redux/Actions";
import { Button } from "react-bootstrap";
import NoDatFound from "../../Components/common/NoDatFound";
import { formatDate } from "../../util/utils";
// import Loader from "../../Components/common/Loader";
import Pagination from "../../Components/common/Paginate/Pagination";
import { FormattedMessage } from "react-intl";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import UserSelectionAutoComplete from "../../Components/Selection/UserSelectionAutoComplete";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

const UserLoggingList = () => {
  const dispatch = useDispatch();
  const UserLoggingList: any = useSelector(
    (state: Store) => state.adminRolesReducer.UserLoggingList,
  ) as any;
  const isLoading: any = useSelector(
    (state: Store) => state.adminRolesReducer.isLoading,
  ) as any;
  const [memberId, setMemberId] = useState<any>("0");
  const [list, setList] = useState<any>([]);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const totalRecord = useSelector(
    (state: Store) => state.adminRolesReducer.totalRecord,
  ) as any;

  const getList = () => {
    dispatch(
      getAdminRolesUserLoggingsList(false, memberId, pageNumber, pageLimit),
    );
  };

  const onPageChange = (pageNum: number) => {
    setPageNumber(pageNum);
  };

  useEffect(() => {
    // getList();
    setList(UserLoggingList);
  }, [UserLoggingList]);

  useEffect(() => {
    setList([]);
    if (parseInt(memberId) > 0) {
      getList();
    }
  }, [pageNumber, memberId, pageLimit]);

  return (
    <>
      <div className="d-flex justify-content-end p-3 member-select">
        <div className="d-flex align-items-center nowrap">
          {/* <label className="text-muted m-0">
            {" "}
            <FormattedMessage
              id="table.head.MemberID"
              defaultMessage="Member ID"
            />
            :{" "}
          </label>{" "} */}
          {/* <input
            type="text"
            value={memberId}
            onChange={(e: any) => setMemberId(e.target.value)}
            className="form-control m-l-10"
          />
          <Button
            onClick={getList}
            className="d-flex align-items-center m-l-10 h-35"
          >
            <>
              <FormattedMessage id="table.head.Go" defaultMessage="GO" />
            </>
          </Button> */}
          <UserSelectionAutoComplete
            getList={(memberId: any) => {
              setMemberId(memberId.toString());
            }}
            users={[]}
            onClear={() => {
              setList([]);
              setMemberId("0");
            }}
            refs={() => {
              /** */
            }}
          />
        </div>
      </div>
      {isLoading && <CenterLoader />}
      <div className="table-responsive">
        {list && list.length > 0 ? (
          <>
            {" "}
            <table className="table table-hover m-0">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="table.head.ID" defaultMessage="ID" />{" "}
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.Message"
                      defaultMessage="Message"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.LogCategory"
                      defaultMessage="Who Created This Log "
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.Date"
                      defaultMessage="Date"
                    />{" "}
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.WhoCreatedLog"
                      defaultMessage="Who Created This Log "
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {list.length &&
                  list.map((i: any) => (
                    <tr>
                      <td>{i.id}</td>
                      <td
                        dangerouslySetInnerHTML={{
                          __html: i?.remarks?.split(",").join("<br/>"),
                        }}
                      />
                      <td>{i?.logCategory?.name}</td>
                      <td>{formatDate(i.createdAt, "DD/MM/YYYY")}</td>
                      <td>
                        {i?.createdBy?.id}(
                        {i?.createdBy?.englishFirstName || "-"})
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        ) : (
          <p>{!isLoading && <NoDatFound />}</p>
        )}{" "}
      </div>
      {totalRecord > 10 && list.length > 0 && (
        <>
          <FormControl className="pagelimit">
            <FormLabel id="demo-row-radio-buttons-group-label">
              <FormattedMessage id="page_size" defaultMessage="Page Size" />
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={pageLimit}
              onChange={(e: any) => {
                setPageLimit(e.target.value);
                setPageNumber(1);
              }}
            >
              <FormControlLabel value={10} control={<Radio />} label="10" />
              <FormControlLabel value={20} control={<Radio />} label="20" />
              <FormControlLabel value={50} control={<Radio />} label="50" />
            </RadioGroup>
          </FormControl>
          <Pagination
            itemsPerPage={pageLimit}
            totalPages={totalRecord}
            onPageChage={onPageChange}
            page={pageNumber - 1}
          />
        </>
      )}
    </>
  );
};

export default UserLoggingList;
