import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { dispatch } from "../../redux";
import {
  getActivityList,
  setCurrentActivityPage,
} from "../../redux/action/ActivityActions";
import { Store } from "../../redux/Actions";
// import { VerticalDots } from "../../util/iconStyle";
import { customFormatDatetimeWithLocale, formatDate } from "../../util/utils";
import { FormattedMessage } from "react-intl";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import Pagination from "../../Components/common/Paginate/Pagination";
import NoDatFound from "../../Components/common/NoDatFound";
import { AppendedMyComponent } from "../../Components/append-to-body-drop-down";
import {
  BsFillInfoCircleFill,
  BsSortDown,
  BsSortUp,
  MdOutlineQrCodeScanner,
  FaUserCheck,
} from "../../reactIcons";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
// import { MdOutlineQrCodeScanner  } from "react-icons/md";

const ActivityList = () => {
  const activityList = useSelector(
    (state: Store) => state.ActivityReducer.activityList,
  ) as Array<Object>;

  const isLoading = useSelector(
    (state: Store) => state.ActivityReducer.isLoading,
  );
  const [pageNumber, setPageNumber] = useState<any>();
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const totalRecord = useSelector(
    (state: Store) => state.ActivityReducer.totalRecord,
  ) as any;
  const currentPage = useSelector(
    (state: Store) => state.ActivityReducer.currentPage,
  );

  const [sortBy, setSortBy] = useState("startDate");
  const [sortOrder, setSortOrder] = useState("DESC");

  const locale = useSelector((state: Store) => state.userDataReducer.local);

  // useEffect(() => {
  //   // dispatch(getEAList(false, { by: sortBy, order: sortOrder }))
  //   // dispatch(getEAUnauthorizedList(false))
  //   dispatch(
  //     getActivityList(false, { by: sortBy, order: sortOrder }, pageNumber, pageLimit)
  //   );
  // }, [sortOrder, sortBy]);

  const setSorting = (prop: any) => {
    if (sortBy === prop) {
      setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
    } else {
      setSortBy(prop);
      setSortOrder("ASC");
    }
  };

  const onPageChange = (pageNum: number) => {
    localStorage.setItem("pageNumber", pageNum.toString());
    localStorage.setItem("pageLimit", pageLimit.toString());
    setPageNumber(pageNum);
    dispatch(setCurrentActivityPage(pageNum));
  };

  // useEffect(() => {
  //   setPageNumber(currentPage);
  // }, [currentPage]);

  const navigate = useNavigate();
  useEffect(() => {
    if (pageNumber && pageLimit) {
      dispatch(
        getActivityList(
          false,
          { by: sortBy, order: sortOrder },
          pageNumber,
          pageLimit,
        ),
      );
    }
  }, [pageNumber, pageLimit, sortOrder, sortBy]);

  useEffect(() => {
    const storedPageNumber = localStorage.getItem("pageNumber");
    const storedPageLimit = localStorage.getItem("pageLimit");
    if (storedPageNumber && storedPageLimit) {
      setPageNumber(storedPageNumber);
      setPageLimit(storedPageLimit);
    } else {
      setPageNumber(1);
      setPageLimit(10);
    }
  }, []);

  const handleClick = (id: number) => {
    localStorage.setItem("pageNumber", pageNumber);
    localStorage.setItem("pageLimit", pageLimit);
    navigate("/activities/" + id);
  };

  return (
    <div>
      <div className="table-responsive">
        {isLoading && <CenterLoader />}

        {activityList && activityList.length > 0 ? (
          <>
            {" "}
            <table className="table table-hover m-0">
              <thead>
                <tr>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => setSorting("id")}
                  >
                    {" "}
                    <FormattedMessage
                      id="Admin.Activitie.ID"
                      defaultMessage="ID"
                    />
                    {sortBy === "id" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => setSorting("class")}
                  >
                    {" "}
                    <FormattedMessage
                      id="Admin.Activitie.Class"
                      defaultMessage="Class"
                    />
                    {sortBy === "class" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => setSorting("name")}
                  >
                    {" "}
                    <FormattedMessage
                      id="Admin.Activitie.Name"
                      defaultMessage="Name"
                    />
                    {sortBy === "name" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => setSorting("startDate")}
                  >
                    <FormattedMessage
                      id="Admin.Activitie.Start"
                      defaultMessage="Start Date"
                    />
                    {sortBy === "startDate" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                  <th className="table-field-actions">
                    <FormattedMessage
                      id="Admin.Activitie.Details"
                      defaultMessage="Details"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {activityList &&
                  activityList.length &&
                  activityList.map((i: any) => (
                    <tr>
                      <td>{i.id}</td>
                      <td>{i?.class?.name}</td>
                      <td>{i.name}</td>
                      <td>
                        {customFormatDatetimeWithLocale(i.startDate, locale)}
                      </td>
                      <td className='text-center table-field-actions">'>
                        <Dropdown className="btn-group">
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            className="btn btn-sm btn-icon-only"
                          >
                            <i className="icon dripicons-dots-3 zmdi-hc-fw"></i>
                          </Dropdown.Toggle>
                          <AppendedMyComponent>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                href="#"
                                onClick={() => handleClick(i.id)}
                              >
                                <i className=" text-accent-custom">
                                  <BsFillInfoCircleFill />{" "}
                                </i>
                                <FormattedMessage
                                  id="classDetail.Details"
                                  defaultMessage="Details"
                                />
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() =>
                                  navigate("/activities/rqcode/" + i.id)
                                }
                              >
                                <i className="text-accent-custom">
                                  <MdOutlineQrCodeScanner />{" "}
                                </i>
                                <FormattedMessage
                                  id="moreMenu.QRCode"
                                  defaultMessage="QR Code"
                                />
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() =>
                                  navigate("/activities/rollcall/" + i.id)
                                }
                              >
                                <i className="text-accent-custom">
                                  <FaUserCheck />
                                </i>
                                <FormattedMessage
                                  id="moreMenu.RollCall"
                                  defaultMessage="Roll Call"
                                />
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </AppendedMyComponent>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        ) : (
          <p>{!isLoading && <NoDatFound />}</p>
        )}
      </div>
      {totalRecord > 10 && activityList.length > 0 && (
        <>
          <FormControl className="pagelimit">
            <FormLabel id="demo-row-radio-buttons-group-label">
              <FormattedMessage id="page_size" defaultMessage="Page Size" />
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={pageLimit}
              onChange={(e: any) => {
                setPageLimit(e.target.value);
                localStorage.setItem("pageLimit", e.target.value);
                localStorage.setItem("pageNumber", pageNumber);
                setPageNumber(1);
              }}
            >
              <FormControlLabel value={10} control={<Radio />} label="10" />
              <FormControlLabel value={20} control={<Radio />} label="20" />
              <FormControlLabel value={50} control={<Radio />} label="50" />
            </RadioGroup>
          </FormControl>
          <Pagination
            itemsPerPage={pageLimit}
            totalPages={totalRecord}
            onPageChage={onPageChange}
            page={pageNumber - 1}
          />
        </>
      )}
    </div>
  );
};

export default ActivityList;
