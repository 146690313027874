const REGISTER = {
  REGISTER_INITLIZATION: "REGISTER_INITLIZATION",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_ERORR: "REGISTER_ERORR",
};

const LOGIN = {
  LOGIN_INITLIZATION: "LOGIN_INITLIZATION",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERORR: "LOGIN_ERORR",
};

const LOGOUT = {
  LOGOUT_INITLIZATION: "LOGOUT_INITLIZATION",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_ERORR: "LOGOUT_ERORR",
};

const CHANGE_LOCAL = "CHANGE_LOCAL";
// const SET_PAGE = "CHANGE_LOCAL";

const FORGOT_PASSWORD = {
  FORGOT_PASSWORD_INITLIZATION: "FORGOT_PASSWORD_INITLIZATION",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_ERORR: "FORGOT_PASSWORD_ERORR",
};

const GET_CLASSES = {
  GET_CLASSES_INITIALIZATION: "GET_CLASSES_INITIALIZATION",
  GET_CLASSES_SUCCESS: "GET_CLASSES_SUCCESS",
  GET_CLASSES_ERROR: "GET_CLASSES_ERROR",
};

const ADD_CLASSES = {
  ADD_CLASSES_INITIALIZATION: "ADD_CLASSES_INITIALIZATION",
  ADD_CLASSES_SUCCESS: "ADD_CLASSES_SUCCESS",
  ADD_CLASSES_ERROR: "ADD_CLASSES_ERROR",
};

const GET_CLASSES_DETAIL = {
  GET_CLASSES_DETAIL_INITIALIZATION: "GET_CLASSES_DETAIL_INITIALIZATION",
  GET_CLASSES_DETAIL_SUCCESS: "GET_CLASSES_DETAIL_SUCCESS",
  GET_CLASSES_DETAIL_ERROR: "GET_CLASSES_DETAIL_ERROR",
};

const GET_EXPRESSWAY_PENDING_LIST = {
  GET_EXPRESSWAY_PENDING_LIST_INITIALIZATION:
    "GET_EXPRESSWAY_PENDING_LIST_INITIALIZATION",
  GET_EXPRESSWAY_PENDING_LIST_SUCCESS: "GET_EXPRESSWAY_PENDING_LIST_SUCCESS",
  GET_EXPRESSWAY_PENDING_LIST_ERROR: "GET_EXPRESSWAY_PENDING_LIST_ERROR",
};

const GET_ADMIN_LIST = {
  GET_ADMIN_LIST_INITIALIZATION: "GET_ADMIN_LIST_INITIALIZATION",
  GET_ADMIN_LIST_SUCCESS: "GET_ADMIN_LIST_SUCCESS",
  GET_ADMIN_LIST_ERROR: "GET_ADMIN_LIST_ERROR",
};

const GET_EXPRESSWAY_MEMBER_DETAIL = {
  GET_EXPRESSWAY_MEMBER_DETAIL_INITIALIZATION:
    "GET_EXPRESSWAY_MEMBER_DETAIL_INITIALIZATION",
  GET_EXPRESSWAY_MEMBER_DETAIL_SUCCESS: "GET_EXPRESSWAY_MEMBER_DETAIL_SUCCESS",
  GET_EXPRESSWAY_MEMBER_DETAIL_ERROR: "GET_EXPRESSWAY_MEMBER_DETAIL_ERROR",
};

const ACT_EXPRESSWAY_MEMBER_DETAIL = {
  ACT_EXPRESSWAY_MEMBER_DETAIL_INITIALIZATION:
    "ACT_EXPRESSWAY_MEMBER_DETAIL_INITIALIZATION",
  ACT_EXPRESSWAY_MEMBER_DETAIL_SUCCESS: "ACT_EXPRESSWAY_MEMBER_DETAIL_SUCCESS",
  ACT_EXPRESSWAY_MEMBER_DETAIL_ERROR: "ACT_EXPRESSWAY_MEMBER_DETAIL_ERROR",
};

const GET_EXPRESSWAY_RECORD_LIST = {
  GET_EXPRESSWAY_RECORD_LIST_INITIALIZATION:
    "GET_EXPRESSWAY_RECORD_LIST_INITIALIZATION",
  GET_EXPRESSWAY_RECORD_LIST_SUCCESS: "GET_EXPRESSWAY_RECORD_LIST_SUCCESS",
  GET_EXPRESSWAY_RECORD_LIST_ERROR: "GET_EXPRESSWAY_PENDING_LIST_ERROR",
};

const GET_CLASS_CATEGORY = {
  GET_CLASS_CATEGORY_INITIALIZATION: "GET_CLASS_CATEGORY_INITIALIZATION",
  GET_CLASS_CATEGORY_SUCCESS: "GET_CLASS_CATEGORY_SUCCESS",
  GET_CLASS_CATEGORY_ERROR: "GET_CLASS_CATEGORY_ERROR",
};

const EDIT_CLASSES = {
  EDIT_CLASSES_INITIALIZATION: "EDIT_CLASSES_INITIALIZATION",
  EDIT_CLASSES_SUCCESS: "EDIT_CLASSES_SUCCESS",
  EDIT_CLASSES_ERROR: "EDIT_CLASSES_ERROR",
};

const GET_PRODUCTS = {
  GET_PRODUCTS_INITIALIZATION: "GET_PRODUCTS_INITIALIZATION",
  GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
  GET_PRODUCTS_ERROR: "GET_PRODUCTS_ERROR",
};

const ADD_CLASS_TOOL_BY_TYPE = {
  ADD_CLASS_TOOL_BY_TYPE_INITIALIZATION: "ADD_CLASS_TOOL_INITIALIZATION",
  ADD_CLASS_TOOL_BY_TYPE_SUCCESS: "ADD_CLASS_TOOL_BY_TYPE_SUCCESS",
  ADD_CLASS_TOOL_BY_TYPE_ERROR: "ADD_CLASS_TOOL_BY_TYPE_ERROR",
};

const GET_CLASS_TOOL_BY_TYPE = {
  GET_CLASS_TOOL_BY_TYPE_INITIALIZATION:
    "GET_CLASS_TOOL_BY_TYPE_INITIALIZATION",
  GET_CLASS_TOOL_BY_TYPE_SUCCESS: "GET_CLASS_TOOL_BY_TYPE_SUCCESS",
  GET_CLASS_TOOL_BY_TYPE_ERROR: "GET_CLASS_TOOL_BY_TYPE_ERROR",
};

const GET_ADMIN_ROLES_LIST = {
  GET_ADMIN_ROLES_LIST_INITIALIZATION: "GET_ADMIN_ROLES_LIST_INITIALIZATION",
  GET_ADMIN_ROLES_LIST_SUCCESS: "GET_ADMIN_ROLES_LIST_SUCCESS",
  GET_ADMIN_ROLES_LIST_ERROR: "GET_ADMIN_ROLES_LIST_ERROR",
};

const GET_ADMIN_ROLES_TOOLS_LIST = {
  GET_ADMIN_ROLES_TOOLS_LIST_INITIALIZATION:
    "GET_ADMIN_ROLES_TOOLS_LIST_INITIALIZATION",
  GET_ADMIN_ROLES_TOOLS_LIST_SUCCESS: "GET_ADMIN_ROLES_TOOLS_LIST_SUCCESS",
  GET_ADMIN_ROLES_TOOLS_LIST_ERROR: "GET_ADMIN_ROLES_TOOLS_LIST_ERROR",
};

const GET_ADMIN_ROLES_USER_PERMISSIONS_LIST = {
  GET_ADMIN_ROLES_USER_PERMISSIONS_LIST_INITIALIZATION:
    "GET_ADMIN_ROLES_USER_PERMISSIONS_LIST_INITIALIZATION",
  GET_ADMIN_ROLES_USER_PERMISSIONS_LIST_SUCCESS:
    "GET_ADMIN_ROLES_USER_PERMISSIONS_LIST_SUCCESS",
  GET_ADMIN_ROLES_USER_PERMISSIONS_LIST_ERROR:
    "GET_ADMIN_ROLES_USER_PERMISSIONS_LIST_ERROR",
};

const GET_ADMIN_ROLES_USER_LOGGINGS_LIST = {
  GET_ADMIN_ROLES_USER_LOGGINGS_LIST_INITIALIZATION:
    "GET_ADMIN_ROLES_USER_LOGGINGS_LIST_INITIALIZATION",
  GET_ADMIN_ROLES_USER_LOGGINGS_LIST_SUCCESS:
    "GET_ADMIN_ROLES_USER_LOGGINGS_LIST_SUCCESS",
  GET_ADMIN_ROLES_USER_LOGGINGS_LIST_ERROR:
    "GET_ADMIN_ROLES_USER_LOGGINGS_LIST_ERROR",
};

const ADD_STUDENT_MANAUAL_LIST = {
  ADD_STUDENT_MANAUAL_LIST_INITIALIZATION:
    "ADD_STUDENT_MANAUAL_LIST_INITIALIZATION",
  ADD_STUDENT_MANAUAL_LIST_SUCCESS: "ADD_STUDENT_MANAUAL_LIST_SUCCESS",
  ADD_STUDENT_MANAUAL_LIST_ERROR: "ADD_STUDENT_MANAUAL_LIST_ERROR",
};

const GET_ADMIN_ROLES_USER_EA_ACCOUNT_LIST = {
  GET_ADMIN_ROLES_USER_EA_ACCOUNT_LIST_INITIALIZATION:
    "GET_ADMIN_ROLES_USER_EA_ACCOUNT_LIST_INITIALIZATION",
  GET_ADMIN_ROLES_USER_EA_ACCOUNT_LIST_SUCCESS:
    "GET_ADMIN_ROLES_USER_EA_ACCOUNT_LIST_SUCCESS",
  GET_ADMIN_ROLES_USER_EA_ACCOUNT_LIST_ERROR:
    "GET_ADMIN_ROLES_USER_EA_ACCOUNT_LIST_ERROR",
};

const GET_INDICATORS_LIST = {
  GET_INDICATORS_LIST_INITIALIZATION: "GET_INDICATORS_LIST_INITIALIZATION",
  GET_INDICATORS_LIST_SUCCESS: "GET_INDICATORS_LIST_SUCCESS",
  GET_INDICATORS_LIST_ERROR: "GET_INDICATORS_LIST_ERROR",
};

const GET_FAQ_LIST = {
  GET_FAQ_LIST_INITIALIZATION: "GET_FAQ_LIST_INITIALIZATION",
  GET_FAQ_LIST_SUCCESS: "GET_FAQ_LIST_SUCCESS",
  GET_FAQ_LIST_ERROR: "GET_FAQ_LIST_ERROR",
};

const GET_FAQ_CATEGORY_LIST = {
  GET_FAQ_CATEGORY_LIST_INITIALIZATION: "GET_FAQ_CATEGORY_LIST_INITIALIZATION",
  GET_FAQ_CATEGORY_LIST_SUCCESS: "GET_FAQ_CATEGORY_LIST_SUCCESS",
  GET_FAQ_CATEGORY_LIST_ERROR: "GET_FAQ_CATEGORY_LIST_ERROR",
};

const ADD_FAQ_LIST = {
  ADD_FAQ_LIST_INITIALIZATION: "ADD_FAQ_LIST_INITIALIZATION",
  ADD_FAQ_LIST_SUCCESS: "ADD_FAQ_LIST_SUCCESS",
  ADD_FAQ_LIST_ERROR: "ADD_FAQ_LIST_ERROR",
};

const EDIT_FAQ_LIST = {
  EDIT_FAQ_LIST_INITIALIZATION: "EDIT_FAQ_LIST_INITIALIZATION",
  EDIT_FAQ_LIST_SUCCESS: "EDIT_FAQ_LIST_SUCCESS",
  EDIT_FAQ_LIST_ERROR: "EDIT_FAQ_LIST_ERROR",
};

const DELETE_FAQ = {
  DELETE_FAQ_INITIALIZATION: "DELETE_FAQ_INITIALIZATION",
  DELETE_FAQ_SUCCESS: "DELETE_FAQ_SUCCESS",
  DELETE_FAQ_ERROR: "DELETE_FAQ_ERROR",
};

const GET_INDICATORS_VERSION_LIST = {
  GET_INDICATORS_VERSION_LIST_INITIALIZATION:
    "GET_INDICATORS_VERSION_LIST_INITIALIZATION",
  GET_INDICATORS_VERSION_LIST_SUCCESS: "GET_INDICATORS_VERSION_LIST_SUCCESS",
  GET_INDICATORS_VERSION_LIST_ERROR: "GET_INDICATORS_VERSION_LIST_ERROR",
};

const ADD_INDICATORS_VERSION = {
  ADD_INDICATORS_VERSION_INITIALIZATION:
    "ADD_INDICATORS_VERSION_INITIALIZATION",
  ADD_INDICATORS_VERSION_SUCCESS: "ADD_INDICATORS_VERSION_SUCCESS",
  ADD_INDICATORS_VERSION_ERROR: "ADD_INDICATORS_VERSION_ERROR",
};

const EDIT_INDICATORS_VERSION = {
  EDIT_INDICATORS_VERSION_INITIALIZATION:
    "EDIT_INDICATORS_VERSION_INITIALIZATION",
  EDIT_INDICATORS_VERSION_SUCCESS: "EDIT_INDICATORS_VERSION_SUCCESS",
  EDIT_INDICATORS_VERSION_ERROR: "EDIT_INDICATORS_VERSION_ERROR",
};

const DELETE_INDICATORS_VERSION = {
  DELETE_INDICATORS_VERSION_INITIALIZATION:
    "DELETE_INDICATORS_VERSION_INITIALIZATION",
  DELETE_INDICATORS_VERSION_SUCCESS: "DELETE_INDICATORS_VERSION_SUCCESS",
  DELETE_INDICATORS_VERSION_ERROR: "DELETE_INDICATORS_VERSION_ERROR",
};

const ATTEND_ACTIVITY = {
  ATTEND_ACTIVITY_INITIALIZATION: "ATTEND_ACTIVITY_INITIALIZATION",
  ATTEND_ACTIVITY_SUCCESS: "ATTEND_ACTIVITY_SUCCESS",
  ATTEND_ACTIVITY_ERROR: "ATTEND_ACTIVITY_ERROR",
};

const GET_INDICATORS_DETAILS = {
  GET_INDICATORS_DETAILS_INITIALIZATION:
    "GET_INDICATORS_DETAILS_INITIALIZATION",
  GET_INDICATORS_DETAILS_SUCCESS: "GET_INDICATORS_DETAILS_SUCCESS",
  GET_INDICATORS_DETAILS_ERROR: "GET_INDICATORS_LIST_ERROR",
};

const ADD_INDICATORS_LIST = {
  ADD_INDICATORS_LIST_INITIALIZATION: "ADD_INDICATORS_LIST_INITIALIZATION",
  ADD_INDICATORS_LIST_SUCCESS: "ADD_INDICATORS_LIST_SUCCESS",
  ADD_INDICATORS_LIST_ERROR: "ADD_INDICATORS_LIST_ERROR",
};

const EDIT_INDICATORS_LIST = {
  EDIT_INDICATORS_LIST_INITIALIZATION: "EDIT_INDICATORS_LIST_INITIALIZATION",
  EDIT_INDICATORS_LIST_SUCCESS: "EDIT_INDICATORS_LIST_SUCCESS",
  EDIT_INDICATORS_LIST_ERROR: "EDIT_INDICATORS_LIST_ERROR",
};

const GET_SCRIPTS_LIST = {
  GET_SCRIPTS_LIST_INITIALIZATION: "GET_SCRIPTS_LIST_INITIALIZATION",
  GET_SCRIPTS_LIST_SUCCESS: "GET_SCRIPTS_LIST_SUCCESS",
  GET_SCRIPTS_LIST_ERROR: "GET_SCRIPTS_LIST_ERROR",
};

const GET_SCRIPTS_DETAILS = {
  GET_SCRIPTS_DETAILS_INITIALIZATION: "GET_SCRIPTS_DETAILS_INITIALIZATION",
  GET_SCRIPTS_DETAILS_SUCCESS: "GET_SCRIPTS_DETAILS_SUCCESS",
  GET_SCRIPTS_DETAILS_ERROR: "GET_SCRIPTS_LIST_ERROR",
};

const ADD_SCRIPTS_LIST = {
  ADD_SCRIPTS_LIST_INITIALIZATION: "ADD_SCRIPTS_LIST_INITIALIZATION",
  ADD_SCRIPTS_LIST_SUCCESS: "ADD_SCRIPTS_LIST_SUCCESS",
  ADD_SCRIPTS_LIST_ERROR: "ADD_SCRIPTS_LIST_ERROR",
};

const EDIT_SCRIPTS_LIST = {
  EDIT_SCRIPTS_LIST_INITIALIZATION: "EDIT_SCRIPTS_LIST_INITIALIZATION",
  EDIT_SCRIPTS_LIST_SUCCESS: "EDIT_SCRIPTS_LIST_SUCCESS",
  EDIT_SCRIPTS_LIST_ERROR: "EDIT_SCRIPTS_LIST_ERROR",
};

const GET_EXPRESS_WAY_PENDING_LIST = {
  GET_EXPRESS_WAY_PENDING_LIST_INITIALIZATION:
    "GET_EXPRESS_WAY_PENDING_LIST_INITIALIZATION",
  GET_EXPRESS_WAY_PENDING_LIST_SUCCESS: "GET_EXPRESS_WAY_PENDING_LIST_SUCCESS",
  GET_EXPRESS_WAY_PENDING_LIST_ERROR: "GET_EXPRESS_WAY_PENDING_LIST_ERROR",
};

const GET_ANNOUNCEMENT_LIST = {
  GET_ANNOUNCEMENT_LIST_INITLIZATION: "GET_ANNOUNCEMENT_LIST_INITLIZATION",
  GET_ANNOUNCEMENT_LIST_SUCCESS: "GET_ANNOUNCEMENT_LIST_SUCCESS",
  GET_ANNOUNCEMENT_LIST_ERORR: "GET_ANNOUNCEMENT_LIST_ERORR",
};

const DELETE_ANNOUNCEMENT_COMMENT = {
  DELETE_ANNOUNCEMENT_COMMENT_INITLIZATION:
    "DELETE_ANNOUNCEMENT_COMMENT_INITLIZATION",
  DELETE_ANNOUNCEMENT_COMMENT_SUCCESS: "DELETE_ANNOUNCEMENT_COMMENT_SUCCESS",
  DELETE_ANNOUNCEMENT_COMMENT_ERORR: "DELETE_ANNOUNCEMENT_COMMENT_ERORR",
};

const DELETE_ANNOUNCEMENT = {
  DELETE_ANNOUNCEMENT_INITLIZATION: "DELETE_ANNOUNCEMENT_INITLIZATION",
  DELETE_ANNOUNCEMENT_SUCCESS: "DELETE_ANNOUNCEMENT_SUCCESS",
  DELETE_ANNOUNCEMENT_ERORR: "DELETE_ANNOUNCEMENT_ERORR",
};

const EDIT_ANNOUNCEMENT_LIST = {
  EDIT_ANNOUNCEMENT_LIST_INITLIZATION: "EDIT_ANNOUNCEMENT_LIST_INITLIZATION",
  EDIT_ANNOUNCEMENT_LIST_SUCCESS: "EDIT_ANNOUNCEMENT_LIST_SUCCESS",
  EDIT_ANNOUNCEMENT_LIST_ERORR: "EDIT_ANNOUNCEMENT_LIST_ERORR",
};

const GET_EXPERT_ADVISOR_LIST = {
  GET_EXPERT_ADVISOR_LIST_INITIALIZATION:
    "GET_EXPERT_ADVISOR_LIST_INITIALIZATION",
  GET_EXPERT_ADVISOR_LIST_SUCCESS: "GET_EXPERT_ADVISOR_LIST_SUCCESS",
  GET_EXPERT_ADVISOR_LIST_ERROR: "GET_EXPERT_ADVISOR_LIST_ERROR",
};

const GET_EXPERT_ADVISOR_DETAILS = {
  GET_EXPERT_ADVISOR_DETAILS_INITIALIZATION:
    "GET_EXPERT_ADVISOR_DETAILS_INITIALIZATION",
  GET_EXPERT_ADVISOR_DETAILS_SUCCESS: "GET_EXPERT_ADVISOR_DETAILS_SUCCESS",
  GET_EXPERT_ADVISOR_DETAILS_ERROR: "GET_EXPERT_ADVISOR_LIST_ERROR",
};

const ADD_EXPERT_ADVISOR_LIST = {
  ADD_EXPERT_ADVISOR_LIST_INITIALIZATION:
    "ADD_EXPERT_ADVISOR_LIST_INITIALIZATION",
  ADD_EXPERT_ADVISOR_LIST_SUCCESS: "ADD_EXPERT_ADVISOR_LIST_SUCCESS",
  ADD_EXPERT_ADVISOR_LIST_ERROR: "ADD_EXPERT_ADVISOR_LIST_ERROR",
};

const EDIT_EXPERT_ADVISOR_LIST = {
  EDIT_EXPERT_ADVISOR_LIST_INITIALIZATION:
    "EDIT_EXPERT_ADVISOR_LIST_INITIALIZATION",
  EDIT_EXPERT_ADVISOR_LIST_SUCCESS: "EDIT_EXPERT_ADVISOR_LIST_SUCCESS",
  EDIT_EXPERT_ADVISOR_LIST_ERROR: "EDIT_EXPERT_ADVISOR_LIST_ERROR",
};

const GET_UTILITY_LIST = {
  GET_UTILITY_LIST_INITIALIZATION: "GET_UTILITY_LIST_INITIALIZATION",
  GET_UTILITY_LIST_SUCCESS: "GET_UTILITY_LIST_SUCCESS",
  GET_UTILITY_LIST_ERROR: "GET_UTILITY_LIST_ERROR",
};

const GET_UTILITY_DETAILS = {
  GET_UTILITY_DETAILS_INITIALIZATION: "GET_UTILITY_DETAILS_INITIALIZATION",
  GET_UTILITY_DETAILS_SUCCESS: "GET_UTILITY_DETAILS_SUCCESS",
  GET_UTILITY_DETAILS_ERROR: "GET_UTILITY_LIST_ERROR",
};

const ADD_UTILITY_LIST = {
  ADD_UTILITY_LIST_INITIALIZATION: "ADD_UTILITY_LIST_INITIALIZATION",
  ADD_UTILITY_LIST_SUCCESS: "ADD_UTILITY_LIST_SUCCESS",
  ADD_UTILITY_LIST_ERROR: "ADD_UTILITY_LIST_ERROR",
};

const EDIT_UTILITY_LIST = {
  EDIT_UTILITY_LIST_INITIALIZATION: "EDIT_UTILITY_LIST_INITIALIZATION",
  EDIT_UTILITY_LIST_SUCCESS: "EDIT_UTILITY_LIST_SUCCESS",
  EDIT_UTILITY_LIST_ERROR: "EDIT_UTILITY_LIST_ERROR",
};

const GET_ANNOUNCEMENT_DETAILS = {
  GET_ANNOUNCEMENT_DETAILS_INITIALIZATION:
    "GET_ANNOUNCEMENT_DETAILS_INITIALIZATION",
  GET_ANNOUNCEMENT_DETAILS_SUCCESS: "GET_ANNOUNCEMENT_DETAILS_SUCCESS",
  GET_ANNOUNCEMENT_DETAILS_ERROR: "GET_ANNOUNCEMENT_LIST_ERROR",
};

const ADD_ANNOUNCEMENT = {
  ADD_ANNOUNCEMENT_INITIALIZATION: "ADD_ANNOUNCEMENT_INITIALIZATION",
  ADD_ANNOUNCEMENT_SUCCESS: "ADD_ANNOUNCEMENT_SUCCESS",
  ADD_ANNOUNCEMENT_ERROR: "ADD_ANNOUNCEMENT_ERROR",
};

const GET_GIFT_LIST = {
  GET_GIFT_LIST_INITIALIZATION: "GET_GIFT_LIST_INITIALIZATION",
  GET_GIFT_LIST_SUCCESS: "GET_GIFT_LIST_SUCCESS",
  GET_GIFT_LIST_ERROR: "GET_GIFT_LIST_ERROR",
};

const GET_GIFT_CATEGORY_LIST = {
  GET_GIFT_CATEGORY_LIST_INITIALIZATION:
    "GET_GIFT_CATEGORY_LIST_INITIALIZATION",
  GET_GIFT_CATEGORY_LIST_SUCCESS: "GET_GIFT_CATEGORY_LIST_SUCCESS",
  GET_GIFT_CATEGORY_LIST_ERROR: "GET_GIFT_CATEGORY_LIST_ERROR",
};

const ADD_GIFT_CATEGORY_LIST = {
  ADD_GIFT_CATEGORY_LIST_INITIALIZATION:
    "ADD_GIFT_CATEGORY_LIST_INITIALIZATION",
  ADD_GIFT_CATEGORY_LIST_SUCCESS: "ADD_GIFT_CATEGORY_LIST_SUCCESS",
  ADD_GIFT_CATEGORY_LIST_ERROR: "ADD_GIFT_CATEGORY_LIST_ERROR",
};

const EDIT_GIFT_CATEGORY_LIST = {
  EDIT_GIFT_CATEGORY_LIST_INITIALIZATION:
    "EDIT_GIFT_CATEGORY_LIST_INITIALIZATION",
  EDIT_GIFT_CATEGORY_LIST_SUCCESS: "EDIT_GIFT_CATEGORY_LIST_SUCCESS",
  EDIT_GIFT_CATEGORY_LIST_ERROR: "EDIT_GIFT_CATEGORY_LIST_ERROR",
};

const GET_GIFT_DETAILS = {
  GET_GIFT_DETAILS_INITIALIZATION: "GET_GIFT_DETAILS_INITIALIZATION",
  GET_GIFT_DETAILS_SUCCESS: "GET_GIFT_DETAILS_SUCCESS",
  GET_GIFT_DETAILS_ERROR: "GET_GIFT_LIST_ERROR",
};

const ADD_GIFT_LIST = {
  ADD_GIFT_LIST_INITIALIZATION: "ADD_GIFT_LIST_INITIALIZATION",
  ADD_GIFT_LIST_SUCCESS: "ADD_GIFT_LIST_SUCCESS",
  ADD_GIFT_LIST_ERROR: "ADD_GIFT_LIST_ERROR",
};

const EDIT_GIFT_LIST = {
  EDIT_GIFT_LIST_INITIALIZATION: "EDIT_GIFT_LIST_INITIALIZATION",
  EDIT_GIFT_LIST_SUCCESS: "EDIT_GIFT_LIST_SUCCESS",
  EDIT_GIFT_LIST_ERROR: "EDIT_GIFT_LIST_ERROR",
};

const UPALOD_MEDIA_LIST = {
  UPALOD_MEDIA_LIST_INITIALIZATION: "UPALOD_MEDIA_LIST_INITIALIZATION",
  UPALOD_MEDIA_LIST_SUCCESS: "UPALOD_MEDIA_LIST_SUCCESS",
  UPALOD_MEDIA_LIST_ERROR: "UPALOD_MEDIA_LIST_ERROR",
};

const DOWNLOAD_MEDIA_LIST = {
  DOWNLOAD_MEDIA_LIST_INITIALIZATION: "DOWNLOAD_MEDIA_LIST_INITIALIZATION",
  DOWNLOAD_MEDIA_LIST_SUCCESS: "DOWNLOAD_MEDIA_LIST_SUCCESS",
  DOWNLOAD_MEDIA_LIST_ERROR: "DOWNLOAD_MEDIA_LIST_ERROR",
};

const DELETE_MEDIA_LOCAL = {
  DELETE_MEDIA_LOCAL_INITIALIZATION: "DELETE_MEDIA_LOCAL_INITIALIZATION",
  DELETE_MEDIA_LOCAL_SUCCESS: "DELETE_MEDIA_LOCAL_SUCCESS",
  DELETE_MEDIA_LOCAL_ERROR: "DELETE_MEDIA_LOCAL_ERROR",
};

const DELETE_MEDIA_AMAZONS3 = {
  DELETE_MEDIA_AMAZONS3_INITIALIZATION: "DELETE_MEDIA_AMAZONS3_INITIALIZATION",
  DELETE_MEDIA_AMAZONS3_SUCCESS: "DELETE_MEDIA_AMAZONS3_SUCCESS",
  DELETE_MEDIA_AMAZONS3_ERROR: "DELETE_MEDIA_AMAZONS3_ERROR",
};

const ADD_ANNOUNCEMENT_COMMENT = {
  ADD_ANNOUNCEMENT_COMMENT_INITIALIZATION:
    "ADD_ANNOUNCEMENT_COMMENT_INITIALIZATION",
  ADD_ANNOUNCEMENT_COMMENT_SUCCESS: "ADD_ANNOUNCEMENT_COMMENT_SUCCESS",
  ADD_ANNOUNCEMENT_COMMENT_ERROR: "ADD_ANNOUNCEMENT_COMMENT_ERROR",
};

const GET_ANNOUNCEMENT_COMMENT = {
  GET_ANNOUNCEMENT_COMMENT_INITIALIZATION:
    "GET_ANNOUNCEMENT_COMMENT_INITIALIZATION",
  GET_ANNOUNCEMENT_COMMENT_SUCCESS: "GET_ANNOUNCEMENT_COMMENT_SUCCESS",
  GET_ANNOUNCEMENT_COMMENT_ERROR: "GET_ANNOUNCEMENT_COMMENT_ERROR",
};

const GET_TICKET_DETAILS = {
  GET_TICKET_DETAILS_INITIALIZATION: "GET_TICKET_DETAILS_INITIALIZATION",
  GET_TICKET_DETAILS_SUCCESS: "GET_TICKET_DETAILS_SUCCESS",
  GET_TICKET_DETAILS_ERROR: "GET_TICKET_LIST_ERROR",
};

const ADD_TICKET_LIST = {
  ADD_TICKET_LIST_INITIALIZATION: "ADD_TICKET_LIST_INITIALIZATION",
  ADD_TICKET_LIST_SUCCESS: "ADD_TICKET_LIST_SUCCESS",
  ADD_TICKET_LIST_ERROR: "ADD_TICKET_LIST_ERROR",
};

const CREATE_TICKET_GROUP_LIST = {
  CREATE_TICKET_GROUP_LIST_INITIALIZATION:
    "CREATE_TICKET_GROUP_LIST_INITIALIZATION",
  CREATE_TICKET_GROUP_LIST_SUCCESS: "CREATE_TICKET_GROUP_LIST_SUCCESS",
  CREATE_TICKET_GROUP_LIST_ERROR: "CREATE_TICKET_GROUP_LIST_ERROR",
};

const DELETE_TICKET_GROUP = {
  DELETE_TICKET_GROUP_INITIALIZATION: "DELETE_TICKET_GROUP_INITIALIZATION",
  DELETE_TICKET_GROUP_SUCCESS: "DELETE_TICKET_GROUP_SUCCESS",
  DELETE_TICKET_GROUP_ERROR: "DELETE_TICKET_GROUP_ERROR",
};

const ADD_GROUP_MEMBER_LIST = {
  ADD_GROUP_MEMBER_LIST_INITIALIZATION: "ADD_GROUP_MEMBER_LIST_INITIALIZATION",
  ADD_GROUP_MEMBER_LIST_SUCCESS: "ADD_GROUP_MEMBER_LIST_SUCCESS",
  ADD_GROUP_MEMBER_LIST_ERROR: "ADD_GROUP_MEMBER_LIST_ERROR",
};

const DELETE_GROUP_MEMBER_LIST = {
  DELETE_GROUP_MEMBER_LIST_INITIALIZATION:
    "DELETE_GROUP_MEMBER_LIST_INITIALIZATION",
  DELETE_GROUP_MEMBER_LIST_SUCCESS: "DELETE_GROUP_MEMBER_LIST_SUCCESS",
  DELETE_GROUP_MEMBER_LIST_ERROR: "ADD_GROUP_MEMBER_LIST_ERROR",
};

const GET_ADMIN_MEMBER_LIST = {
  GET_ADMIN_MEMBER_LIST_INITIALIZATION: "GET_ADMIN_MEMBER_LIST_INITIALIZATION",
  GET_ADMIN_MEMBER_LIST_SUCCESS: "GET_ADMIN_MEMBER_LIST_SUCCESS",
  GET_ADMIN_MEMBER_LIST_ERROR: "GET_ADMIN_MEMBER_LIST_ERROR",
};

const GET_TICKET_GROUP_LIST = {
  GET_TICKET_GROUP_LIST_INITIALIZATION: "GET_TICKET_GROUP_LIST_INITIALIZATION",
  GET_TICKET_GROUP_LIST_SUCCESS: "GET_TICKET_GROUP_LIST_SUCCESS",
  GET_TICKET_GROUP_LIST_ERROR: "GET_TICKET_GROUP_LIST_ERROR",
};

const EDIT_TICKET_LIST = {
  EDIT_TICKET_LIST_INITIALIZATION: "EDIT_TICKET_LIST_INITIALIZATION",
  EDIT_TICKET_LIST_SUCCESS: "EDIT_TICKET_LIST_SUCCESS",
  EDIT_TICKET_LIST_ERROR: "EDIT_TICKET_LIST_ERROR",
};

const GET_TICKET_ISSUE_TYPE_LIST = {
  GET_TICKET_ISSUE_TYPE_LIST_INITIALIZATION:
    "GET_TICKET_ISSUE_TYPE_LIST_INITIALIZATION",
  GET_TICKET_ISSUE_TYPE_LIST_SUCCESS: "GET_TICKET_ISSUE_TYPE_LIST_SUCCESS",
  GET_TICKET_ISSUE_TYPE_LIST_ERROR: "GET_TICKET_ISSUE_TYPE_LIST_ERROR",
};

const GET_TICKET_LIST = {
  GET_TICKET_LIST_INITIALIZATION: "GET_TICKET_LIST_INITIALIZATION",
  GET_TICKET_LIST_SUCCESS: "GET_TICKET_LIST_SUCCESS",
  GET_TICKET_LIST_ERROR: "GET_TICKET_LIST_ERROR",
};

const GET_TICKET_COMMENT_LIST = {
  GET_TICKET_COMMENT_LIST_INITIALIZATION:
    "GET_TICKET_COMMENT_LIST_INITIALIZATION",
  GET_TICKET_COMMENT_LIST_SUCCESS: "GET_TICKET_COMMENT_LIST_SUCCESS",
  GET_TICKET_COMMENT_LIST_ERROR: "GET_TICKET_COMMENT_LIST_ERROR",
};

const ADD_TICKET_COMMENT_LIST = {
  ADD_TICKET_COMMENT_LIST_INITIALIZATION:
    "ADD_TICKET_COMMENT_LIST_INITIALIZATION",
  ADD_TICKET_COMMENT_LIST_SUCCESS: "ADD_TICKET_COMMENT_LIST_SUCCESS",
  ADD_TICKET_COMMENT_LIST_ERROR: "ADD_TICKET_COMMENT_LIST_ERROR",
};

const EDIT_TICKET_COMMENT_LIST = {
  EDIT_TICKET_COMMENT_LIST_INITIALIZATION:
    "EDIT_TICKET_COMMENT_LIST_INITIALIZATION",
  EDIT_TICKET_COMMENT_LIST_SUCCESS: "EDIT_TICKET_COMMENT_LIST_SUCCESS",
  EDIT_TICKET_COMMENT_LIST_ERROR: "EDIT_TICKET_COMMENT_LIST_ERROR",
};

const DELETE_TICKET_COMMENT_LIST = {
  DELETE_TICKET_COMMENT_LIST_INITIALIZATION:
    "DELETE_TICKET_COMMENT_LIST_INITIALIZATION",
  DELETE_TICKET_COMMENT_LIST_SUCCESS: "DELETE_TICKET_COMMENT_LIST_SUCCESS",
  DELETE_TICKET_COMMENT_LIST_ERROR: "DELETE_TICKET_COMMENT_LIST_ERROR",
};

const GET_TICKET_TOOL_LIST = {
  GET_TICKET_TOOL_LIST_INITIALIZATION: "GET_TICKET_TOOL_LIST_INITIALIZATION",
  GET_TICKET_TOOL_LIST_SUCCESS: "GET_TICKET_TOOL_LIST_SUCCESS",
  GET_TICKET_TOOL_LIST_ERROR: "GET_TICKET_TOOL_LIST_ERROR",
};

const DELETE_TICKET_LIST = {
  DELETE_TICKET_LIST_INITIALIZATION: "DELETE_TICKET_LIST_INITIALIZATION",
  DELETE_TICKET_LIST_SUCCESS: "DELETE_TICKET_LIST_SUCCESS",
  DELETE_TICKET_LIST_ERROR: "DELETE_TICKET_LIST_ERROR",
};

const GET_TICKET_TOOL_VERSION_LIST = {
  GET_TICKET_TOOL_VERSION_LIST_INITIALIZATION:
    "GET_TICKET_TOOL_VERSION_LIST_INITIALIZATION",
  GET_TICKET_TOOL_VERSION_LIST_SUCCESS: "GET_TICKET_TOOL_VERSION_LIST_SUCCESS",
  GET_TICKET_TOOL_VERSION_LIST_ERROR: "GET_TICKET_TOOL_VERSION_LIST_ERROR",
};

const GET_STUDENT_LIST = {
  GET_STUDENT_LIST_INITIALIZATION: "GET_STUDENT_LIST_INITIALIZATION",
  GET_STUDENT_LIST_SUCCESS: "GET_STUDENT_LIST_SUCCESS",
  GET_STUDENT_LIST_ERROR: "GET_STUDENT_LIST_ERROR",
};

const GET_STUDENT_BY_CLASS_LIST = {
  GET_STUDENT_BY_CLASS_LIST_INITIALIZATION:
    "GET_STUDENT_BY_CLASS_LIST_INITIALIZATION",
  GET_STUDENT_BY_CLASS_LIST_SUCCESS: "GET_STUDENT_BY_CLASS_LIST_SUCCESS",
  GET_STUDENT_BY_CLASS_LIST_ERROR: "GET_STUDENT_BY_CLASS_LIST_ERROR",
};

const EXAMINE_STUDENT_LIST = {
  EXAMINE_STUDENT_LIST_INITIALIZATION: "EXAMINE_STUDENT_LIST_INITIALIZATION",
  EXAMINE_STUDENT_LIST_SUCCESS: "EXAMINE_STUDENT_LIST_SUCCESS",
  EXAMINE_STUDENT_LIST_ERROR: "EXAMINE_STUDENT_LIST_ERROR",
};

const GET_USER_DETAIL = {
  GET_USER_DETAIL_INITIALIZATION: "GET_USER_DETAIL_INITIALIZATION",
  GET_USER_DETAIL_SUCCESS: "GET_USER_DETAIL_SUCCESS",
  GET_USER_DETAIL_ERROR: "GET_USER_DETAIL_ERROR",
};

const EDIT_USER_DETAIL = {
  EDIT_USER_DETAIL_INITIALIZATION: "EDIT_USER_DETAIL_INITIALIZATION",
  EDIT_USER_DETAIL_SUCCESS: "EDIT_USER_DETAIL_SUCCESS",
  EDIT_USER_DETAIL_ERROR: "EDIT_USER_DETAIL_ERROR",
};

const GET_USER_ATTENDENCE = {
  GET_USER_ATTENDENCE_INITIALIZATION: "GET_USER_ATTENDENCE_INITIALIZATION",
  GET_USER_ATTENDENCE_SUCCESS: "GET_USER_ATTENDENCE_SUCCESS",
  GET_USER_ATTENDENCE_ERROR: "GET_USER_ATTENDENCE_ERROR",
};

const ADD_CLASS_TOOL_VERSION = {
  ADD_CLASS_TOOL_VERSION_INITIALIZATION:
    "ADD_CLASS_TOOL_VERSION_INITIALIZATION",
  ADD_CLASS_TOOL_VERSION_SUCCESS: "ADD_CLASS_TOOL_VERSION_SUCCESS",
  ADD_CLASS_TOOL_VERSION_ERROR: "ADD_CLASS_TOOL_VERSION_ERROR",
};

const DELETE_CLASS_TOOL_VERSION = {
  DELETE_CLASS_TOOL_VERSION_INITIALIZATION:
    "DELETE_CLASS_TOOL_VERSION_INITIALIZATION",
  DELETE_CLASS_TOOL_VERSION_SUCCESS: "DELETE_CLASS_TOOL_VERSION_SUCCESS",
  DELETE_CLASS_TOOL_VERSION_ERROR: "DELETE_CLASS_TOOL_VERSION_ERROR",
};

const DELETE_CLASS_TOOL = {
  DELETE_CLASS_TOOL_INITIALIZATION: "DELETE_CLASS_TOOL_INITIALIZATION",
  DELETE_CLASS_TOOL_SUCCESS: "DELETE_CLASS_TOOL_SUCCESS",
  DELETE_CLASS_TOOL_ERROR: "DELETE_CLASS_TOOL_ERROR",
};

const GET_CLASS_TOOL_VERSION = {
  GET_CLASS_TOOL_VERSION_INITIALIZATION:
    "GET_CLASS_TOOL_VERSION_INITIALIZATION",
  GET_CLASS_TOOL_VERSION_SUCCESS: "GET_CLASS_TOOL_VERSION_SUCCESS",
  GET_CLASS_TOOL_VERSION_ERROR: "GET_CLASS_TOOL_VERSION_ERROR",
};

const GET_EA_VERSION_LIST = {
  GET_EA_VERSION_LIST_INITIALIZATION: "GET_EA_VERSION_LIST_INITIALIZATION",
  GET_EA_VERSION_LIST_SUCCESS: "GET_EA_VERSION_LIST_SUCCESS",
  GET_EA_VERSION_LIST_ERROR: "GET_EA_VERSION_LIST_ERROR",
};

const ADD_EA_VERSION = {
  ADD_EA_VERSION_INITIALIZATION: "ADD_EA_VERSION_INITIALIZATION",
  ADD_EA_VERSION_SUCCESS: "ADD_EA_VERSION_SUCCESS",
  ADD_EA_VERSION_ERROR: "ADD_EA_VERSION_ERROR",
};

const EDIT_EA_VERSION = {
  EDIT_EA_VERSION_INITIALIZATION: "EDIT_EA_VERSION_INITIALIZATION",
  EDIT_EA_VERSION_SUCCESS: "EDIT_EA_VERSION_SUCCESS",
  EDIT_EA_VERSION_ERROR: "EDIT_EA_VERSION_ERROR",
};

const DELETE_EA_VERSION = {
  DELETE_EA_VERSION_INITIALIZATION: "DELETE_EA_VERSION_INITIALIZATION",
  DELETE_EA_VERSION_SUCCESS: "DELETE_EA_VERSION_SUCCESS",
  DELETE_EA_VERSION_ERROR: "DELETE_EA_VERSION_ERROR",
};

const ADD_EA_VERSION_TO_ALL_CLASS = {
  ADD_EA_VERSION_TO_ALL_CLASS_INITIALIZATION:
    "ADD_EA_VERSION_TO_ALL_CLASS_INITIALIZATION",
  ADD_EA_VERSION_TO_ALL_CLASS_SUCCESS: "ADD_EA_VERSION_TO_ALL_CLASS_SUCCESS",
  ADD_EA_VERSION_TO_ALL_CLASS_ERROR: "ADD_EA_VERSION_TO_ALL_CLASS_ERROR",
};

const GET_SCRIPT_VERSION_LIST = {
  GET_SCRIPT_VERSION_LIST_INITIALIZATION:
    "GET_SCRIPT_VERSION_LIST_INITIALIZATION",
  GET_SCRIPT_VERSION_LIST_SUCCESS: "GET_SCRIPT_VERSION_LIST_SUCCESS",
  GET_SCRIPT_VERSION_LIST_ERROR: "GET_SCRIPT_VERSION_LIST_ERROR",
};

const ADD_SCRIPT_VERSION = {
  ADD_SCRIPT_VERSION_INITIALIZATION: "ADD_SCRIPT_VERSION_INITIALIZATION",
  ADD_SCRIPT_VERSION_SUCCESS: "ADD_SCRIPT_VERSION_SUCCESS",
  ADD_SCRIPT_VERSION_ERROR: "ADD_SCRIPT_VERSION_ERROR",
};

const EDIT_SCRIPT_VERSION = {
  EDIT_SCRIPT_VERSION_INITIALIZATION: "EDIT_SCRIPT_VERSION_INITIALIZATION",
  EDIT_SCRIPT_VERSION_SUCCESS: "EDIT_SCRIPT_VERSION_SUCCESS",
  EDIT_SCRIPT_VERSION_ERROR: "EDIT_SCRIPT_VERSION_ERROR",
};

const DELETE_SCRIPT_VERSION = {
  DELETE_SCRIPT_VERSION_INITIALIZATION: "DELETE_SCRIPT_VERSION_INITIALIZATION",
  DELETE_SCRIPT_VERSION_SUCCESS: "DELETE_SCRIPT_VERSION_SUCCESS",
  DELETE_SCRIPT_VERSION_ERROR: "DELETE_SCRIPT_VERSION_ERROR",
};

const GET_UTILITY_VERSION_LIST = {
  GET_UTILITY_VERSION_LIST_INITIALIZATION:
    "GET_UTILITY_VERSION_LIST_INITIALIZATION",
  GET_UTILITY_VERSION_LIST_SUCCESS: "GET_UTILITY_VERSION_LIST_SUCCESS",
  GET_UTILITY_VERSION_LIST_ERROR: "GET_UTILITY_VERSION_LIST_ERROR",
};

const ADD_UTILITY_VERSION = {
  ADD_UTILITY_VERSION_INITIALIZATION: "ADD_UTILITY_VERSION_INITIALIZATION",
  ADD_UTILITY_VERSION_SUCCESS: "ADD_UTILITY_VERSION_SUCCESS",
  ADD_UTILITY_VERSION_ERROR: "ADD_UTILITY_VERSION_ERROR",
};

const EDIT_UTILITY_VERSION = {
  EDIT_UTILITY_VERSION_INITIALIZATION: "EDIT_UTILITY_VERSION_INITIALIZATION",
  EDIT_UTILITY_VERSION_SUCCESS: "EDIT_UTILITY_VERSION_SUCCESS",
  EDIT_UTILITY_VERSION_ERROR: "EDIT_UTILITY_VERSION_ERROR",
};

const DELETE_UTILITY_VERSION = {
  DELETE_UTILITY_VERSION_INITIALIZATION:
    "DELETE_UTILITY_VERSION_INITIALIZATION",
  DELETE_UTILITY_VERSION_SUCCESS: "DELETE_UTILITY_VERSION_SUCCESS",
  DELETE_UTILITY_VERSION_ERROR: "DELETE_UTILITY_VERSION_ERROR",
};

const GET_INDUSTRY_LIST = {
  GET_INDUSTRY_LIST_INITLIZATION: "GET_INDUSTRY_LIST_INITLIZATION",
  GET_INDUSTRY_LIST_SUCCESS: "GET_INDUSTRY_LIST_SUCCESS",
  GET_INDUSTRY_LIST_ERORR: "GET_INDUSTRY_LIST_ERORR",
};

const GET_JOB_LIST = {
  GET_JOB_LIST_INITLIZATION: "GET_JOB_LIST_INITLIZATION",
  GET_JOB_LIST_SUCCESS: "GET_JOB_LIST_SUCCESS",
  GET_JOB_LIST_ERORR: "GET_JOB_LIST_ERORR",
};

const GET_REGISTRATION_MATERIAL = {
  GET_REGISTRATION_MATERIAL_INITIALIZATION:
    "GET_REGISTRATION_MATERIAL_INITIALIZATION",
  GET_REGISTRATION_MATERIAL_SUCCESS: "GET_REGISTRATION_MATERIAL_SUCCESS",
  GET_REGISTRATION_MATERIAL_ERROR: "GET_REGISTRATION_MATERIAL_ERROR",
};

const GET_ACTIVITY_LIST = {
  GET_ACTIVITY_LIST_INITIALIZATION: "GET_ACTIVITY_LIST_INITIALIZATION",
  GET_ACTIVITY_LIST_SUCCESS: "GET_ACTIVITY_LIST_SUCCESS",
  GET_ACTIVITY_LIST_ERROR: "GET_ACTIVITY_LIST_ERROR",
};

const ADD_ACTIVITY_LIST = {
  ADD_ACTIVITY_LIST_INITIALIZATION: "ADD_ACTIVITY_LIST_INITIALIZATION",
  ADD_ACTIVITY_LIST_SUCCESS: "ADD_ACTIVITY_LIST_SUCCESS",
  ADD_ACTIVITY_LIST_ERROR: "ADD_ACTIVITY_LIST_ERROR",
};

const GET_ACTIVITY_ATTENDENCE_LIST = {
  GET_ACTIVITY_ATTENDENCE_LIST_INITIALIZATION:
    "GET_ACTIVITY_ATTENDENCE_LIST_INITIALIZATION",
  GET_ACTIVITY_ATTENDENCE_LIST_SUCCESS: "GET_ACTIVITY_ATTENDENCE_LIST_SUCCESS",
  GET_ACTIVITY_ATTENDENCE_LIST_ERROR: "GET_ACTIVITY_ATTENDENCE_LIST_ERROR",
};

const GET_ACTIVITY_ATTENDENCE_LOG_LIST = {
  GET_ACTIVITY_ATTENDENCE_LOG_LIST_INITIALIZATION:
    "GET_ACTIVITY_ATTENDENCE_LOG_LIST_INITIALIZATION",
  GET_ACTIVITY_ATTENDENCE_LOG_LIST_SUCCESS:
    "GET_ACTIVITY_ATTENDENCE_LOG_LIST_SUCCESS",
  GET_ACTIVITY_ATTENDENCE_LOG_LIST_ERROR:
    "GET_ACTIVITY_ATTENDENCE_LOG_LIST_ERROR",
};

const GET_ACTIVITY_DETAILS_LIST = {
  GET_ACTIVITY_DETAILS_LIST_INITIALIZATION:
    "GET_ACTIVITY_DETAILS_LIST_INITIALIZATION",
  GET_ACTIVITY_DETAILS_LIST_SUCCESS: "GET_ACTIVITY_DETAILS_LIST_SUCCESS",
  GET_ACTIVITY_DETAILS_LIST_ERROR: "GET_ACTIVITY_DETAILS_LIST_ERROR",
};

const EDIT_ACTIVITY_DETAILS_LIST = {
  EDIT_ACTIVITY_DETAILS_LIST_INITIALIZATION:
    "EDIT_ACTIVITY_DETAILS_LIST_INITIALIZATION",
  EDIT_ACTIVITY_DETAILS_LIST_SUCCESS: "EDIT_ACTIVITY_DETAILS_LIST_SUCCESS",
  EDIT_ACTIVITY_DETAILS_LIST_ERROR: "EDIT_ACTIVITY_DETAILS_LIST_ERROR",
};

const GET_ACTIVITY_POINTS_LIST = {
  GET_ACTIVITY_POINTS_LIST_INITIALIZATION:
    "GET_ACTIVITY_POINTS_LIST_INITIALIZATION",
  GET_ACTIVITY_POINTS_LIST_SUCCESS: "GET_ACTIVITY_POINTS_LIST_SUCCESS",
  GET_ACTIVITY_POINTS_LIST_ERROR: "GET_ACTIVITY_POINTS_LIST_ERROR",
};

const ADD_ACTIVITY_POINTS_LIST = {
  ADD_ACTIVITY_POINTS_LIST_INITIALIZATION:
    "ADD_ACTIVITY_POINTS_LIST_INITIALIZATION",
  ADD_ACTIVITY_POINTS_LIST_SUCCESS: "ADD_ACTIVITY_POINTS_LIST_SUCCESS",
  ADD_ACTIVITY_POINTS_LIST_ERROR: "ADD_ACTIVITY_POINTS_LIST_ERROR",
};

const REGISTER_ACTIVITY_LIST = {
  REGISTER_ACTIVITY_LIST_INITIALIZATION:
    "REGISTER_ACTIVITY_LIST_INITIALIZATION",
  REGISTER_ACTIVITY_LIST_SUCCESS: "REGISTER_ACTIVITY_LIST_SUCCESS",
  REGISTER_ACTIVITY_LIST_ERROR: "REGISTER_ACTIVITY_LIST_ERROR",
};

const ROLL_CALL_ACTIVITY_LIST = {
  ROLL_CALL_ACTIVITY_LIST_INITIALIZATION:
    "ROLL_CALL_ACTIVITY_LIST_INITIALIZATION",
  ROLL_CALL_ACTIVITY_LIST_SUCCESS: "ROLL_CALL_ACTIVITY_LIST_SUCCESS",
  ROLL_CALL_ACTIVITY_LIST_ERROR: "ROLL_CALL_ACTIVITY_LIST_ERROR",
};

const GET_LIBRARY_LIST = {
  GET_LIBRARY_LIST_INITIALIZATION: "GET_LIBRARY_LIST_INITIALIZATION",
  GET_LIBRARY_LIST_SUCCESS: "GET_LIBRARY_LIST_SUCCESS",
  GET_LIBRARY_LIST_ERROR: "GET_LIBRARY_LIST_ERROR",
};

const GET_LIBRARY_DETAIL = {
  GET_LIBRARY_DETAIL_INITIALIZATION: "GET_LIBRARY_DETAIL_INITIALIZATION",
  GET_LIBRARY_DETAIL_SUCCESS: "GET_LIBRARY_DETAIL_SUCCESS",
  GET_LIBRARY_DETAIL_ERROR: "GET_LIBRARY_DETAIL_ERROR",
};

const ADD_LIBRARY_LIST = {
  ADD_LIBRARY_LIST_INITIALIZATION: "ADD_LIBRARY_LIST_INITIALIZATION",
  ADD_LIBRARY_LIST_SUCCESS: "ADD_LIBRARY_LIST_SUCCESS",
  ADD_LIBRARY_LIST_ERROR: "ADD_LIBRARY_LIST_ERROR",
};

const EDIT_LIBRARY_LIST = {
  EDIT_LIBRARY_LIST_INITIALIZATION: "EDIT_LIBRARY_LIST_INITIALIZATION",
  EDIT_LIBRARY_LIST_SUCCESS: "EDIT_LIBRARY_LIST_SUCCESS",
  EDIT_LIBRARY_LIST_ERROR: "EDIT_LIBRARY_LIST_ERROR",
};

const DELETE_LIBRARY_LIST = {
  DELETE_LIBRARY_LIST_INITIALIZATION: "DELETE_LIBRARY_LIST_INITIALIZATION",
  DELETE_LIBRARY_LIST_SUCCESS: "DELETE_LIBRARY_LIST_SUCCESS",
  DELETE_LIBRARY_LIST_ERROR: "DELETE_LIBRARY_LIST_ERROR",
};

const GET_LIBRARY_VERSION_LIST = {
  GET_LIBRARY_VERSION_LIST_INITIALIZATION:
    "GET_LIBRARY_VERSION_LIST_INITIALIZATION",
  GET_LIBRARY_VERSION_LIST_SUCCESS: "GET_LIBRARY_VERSION_LIST_SUCCESS",
  GET_LIBRARY_VERSION_LIST_ERROR: "GET_LIBRARY_VERSION_LIST_ERROR",
};

const ADD_LIBRARY_VERSION_LIST = {
  ADD_LIBRARY_VERSION_LIST_INITIALIZATION:
    "ADD_LIBRARY_VERSION_LIST_INITIALIZATION",
  ADD_LIBRARY_VERSION_LIST_SUCCESS: "ADD_LIBRARY_VERSION_LIST_SUCCESS",
  ADD_LIBRARY_VERSION_LIST_ERROR: "ADD_LIBRARY_VERSION_LIST_ERROR",
};

const EDIT_LIBRARY_VERSION_LIST = {
  EDIT_LIBRARY_VERSION_LIST_INITIALIZATION:
    "EDIT_LIBRARY_VERSION_LIST_INITIALIZATION",
  EDIT_LIBRARY_VERSION_LIST_SUCCESS: "EDIT_LIBRARY_VERSION_LIST_SUCCESS",
  EDIT_LIBRARY_VERSION_LIST_ERROR: "EDIT_LIBRARY_VERSION_LIST_ERROR",
};

const DELETE_LIBRARY_VERSION_LIST = {
  DELETE_LIBRARY_VERSION_LIST_INITIALIZATION:
    "DELETE_LIBRARY_VERSION_LIST_INITIALIZATION",
  DELETE_LIBRARY_VERSION_LIST_SUCCESS: "DELETE_LIBRARY_VERSION_LIST_SUCCESS",
  DELETE_LIBRARY_VERSION_LIST_ERROR: "DELETE_LIBRARY_VERSION_LIST_ERROR",
};

const GET_ADVERTISEMENT_LIST = {
  GET_ADVERTISEMENT_LIST_INITIALIZATION:
    "GET_ADVERTISEMENT_LIST_INITIALIZATION",
  GET_ADVERTISEMENT_LIST_SUCCESS: "GET_ADVERTISEMENT_LIST_SUCCESS",
  GET_ADVERTISEMENT_LIST_ERROR: "GET_ADVERTISEMENT_LIST_ERROR",
};
const GET_ADVERTISEMENT_TYPE_LIST = {
  GET_ADVERTISEMENT_TYPE_LIST_INITIALIZATION:
    "GET_ADVERTISEMENT_TYPE_LIST_INITIALIZATION",
  GET_ADVERTISEMENT_TYPE_LIST_SUCCESS: "GET_ADVERTISEMENT_TYPE_LIST_SUCCESS",
  GET_ADVERTISEMENT_TYPE_LIST_ERROR: "GET_ADVERTISEMENT_TYPE_LIST_ERROR",
};
const GET_ADVERTISEMENT_TYPE_TOOL_LIST = {
  GET_ADVERTISEMENT_TYPE_TOOL_LIST_INITIALIZATION:
    "GET_ADVERTISEMENT_TYPE_TOOL_LIST_INITIALIZATION",
  GET_ADVERTISEMENT_TYPE_TOOL_LIST_SUCCESS:
    "GET_ADVERTISEMENT_TYPE_TOOL_LIST_SUCCESS",
  GET_ADVERTISEMENT_TYPE_TOOL_LIST_ERROR:
    "GET_ADVERTISEMENT_TYPE_TOOL_LIST_ERROR",
};

const ADD_ADVERTISEMENT = {
  ADD_ADVERTISEMENT_INITIALIZATION: "ADD_ADVERTISEMENT_INITIALIZATION",
  ADD_ADVERTISEMENT_SUCCESS: "ADD_ADVERTISEMENT_SUCCESS",
  ADD_ADVERTISEMENT_ERROR: "ADD_ADVERTISEMENT_ERROR",
};

const EDIT_ADVERTISEMENT = {
  EDIT_ADVERTISEMENT_INITIALIZATION: "EDIT_ADVERTISEMENT_INITIALIZATION",
  EDIT_ADVERTISEMENT_SUCCESS: "EDIT_ADVERTISEMENT_SUCCESS",
  EDIT_ADVERTISEMENT_ERROR: "EDIT_ADVERTISEMENT_ERROR",
};
const GET_RENTS_LIST = {
  GET_RENTS_LIST_INITIALIZATION: "GET_RENTS_LIST_INITIALIZATION",
  GET_RENTS_LIST_SUCCESS: "GET_RENTS_LIST_SUCCESS",
  GET_RENTS_LIST_ERROR: "GET_RENTS_LIST_ERROR",
};

const ADD_RENTS_LIST = {
  ADD_RENTS_LIST_INITIALIZATION: "ADD_RENTS_LIST_INITIALIZATION",
  ADD_RENTS_LIST_SUCCESS: "ADD_RENTS_LIST_SUCCESS",
  ADD_RENTS_LIST_ERROR: "ADD_RENTS_LIST_ERROR",
};

const EDIT_RENTS_LIST = {
  EDIT_RENTS_LIST_INITIALIZATION: "EDIT_RENTS_LIST_INITIALIZATION",
  EDIT_RENTS_LIST_SUCCESS: "EDIT_RENTS_LIST_SUCCESS",
  EDIT_RENTS_LIST_ERROR: "EDIT_RENTS_LIST_ERROR",
};

const GET_RENTS_CATEGORY_LIST = {
  GET_RENTS_CATEGORY_LIST_INITIALIZATION:
    "GET_RENTS_CATEGORY_LIST_INITIALIZATION",
  GET_RENTS_CATEGORY_LIST_SUCCESS: "GET_RENTS_CATEGORY_LIST_SUCCESS",
  GET_RENTS_CATEGORY_LIST_ERROR: "GET_RENTS_CATEGORY_LIST_ERROR",
};

const GET_GRANT_LIST = {
  GET_GRANT_LIST_INITIALIZATION: "GET_GRANT_LIST_INITIALIZATION",
  GET_GRANT_LIST_SUCCESS: "GET_GRANT_LIST_SUCCESS",
  GET_GRANT_LIST_ERROR: "GET_GRANT_LIST_ERROR",
};

const GRANT_POINT_LIST = {
  GRANT_POINT_LIST_INITIALIZATION: "GRANT_POINT_LIST_INITIALIZATION",
  GRANT_POINT_LIST_SUCCESS: "GRANT_POINT_LIST_SUCCESS",
  GRANT_POINT_LIST_ERROR: "GRANT_POINT_LIST_ERROR",
};

const GRANT_TOOL_LIST = {
  GRANT_TOOL_LIST_INITIALIZATION: "GRANT_TOOL_LIST_INITIALIZATION",
  GRANT_TOOL_LIST_SUCCESS: "GRANT_TOOL_LIST_SUCCESS",
  GRANT_TOOL_LIST_ERROR: "GRANT_TOOL_LIST_ERROR",
};

const GRANT_ACCOUNT = {
  GRANT_ACCOUNT_INITIALIZATION: "GRANT_ACCOUNT_INITIALIZATION",
  GRANT_ACCOUNT_SUCCESS: "GRANT_ACCOUNT_SUCCESS",
  GRANT_ACCOUNT_ERROR: "GRANT_TOOL_LIST_ERROR",
};

const ADD_CLASS_MEMBERSHIP = {
  ADD_CLASS_MEMBERSHIP_INITIALIZATION: "ADD_CLASS_MEMBERSHIP_INITIALIZATION",
  ADD_CLASS_MEMBERSHIP_SUCCESS: "ADD_CLASS_MEMBERSHIP_SUCCESS",
  ADD_CLASS_MEMBERSHIP_ERROR: "ADD_CLASS_MEMBERSHIP_ERROR",
};

const ADD_USER_MEMBERSHIP = {
  ADD_USER_MEMBERSHIP_INITIALIZATION: "ADD_USER_MEMBERSHIP_INITIALIZATION",
  ADD_USER_MEMBERSHIP_SUCCESS: "ADD_USER_MEMBERSHIP_SUCCESS",
  ADD_USER_MEMBERSHIP_ERROR: "ADD_USER_MEMBERSHIP_ERROR",
};

const GRADUATE_ALL_STUDENT_CLASS = {
  GRADUATE_ALL_STUDENT_CLASS_INITIALIZATION:
    "GRADUATE_ALL_STUDENT_CLASS_INITIALIZATION",
  GRADUATE_ALL_STUDENT_CLASS_SUCCESS: "GRADUATE_ALL_STUDENT_CLASS_SUCCESS",
  GRADUATE_ALL_STUDENT_CLASS_ERROR: "GRADUATE_ALL_STUDENT_CLASS_ERROR",
};

const GET_QR_CODE = {
  GET_QR_CODE_INITIALIZATION: "GET_QR_CODE_INITIALIZATION",
  GET_QR_CODE_SUCCESS: "GET_QR_CODE_SUCCESS",
  GET_QR_CODE_ERROR: "GET_QR_CODE_ERROR",
};

const GET_DASHBOARD_COUNT = {
  GET_DASHBOARD_COUNT_INITIALIZATION: "GET_DASHBOARD_COUNT_INITIALIZATION",
  GET_DASHBOARD_COUNT_SUCCESS: "GET_DASHBOARD_COUNT_SUCCESS",
  GET_DASHBOARD_COUNT_ERROR: "GET_DASHBOARD_COUNT_ERROR",
};

const GET_DASHBOARD_MEMBERSHIP_EXPIRED_USER_LIST = {
  GET_DASHBOARD_MEMBERSHIP_EXPIRED_USER_LIST_INITIALIZATION:
    "GET_DASHBOARD_MEMBERSHIP_EXPIRED_USER_LIST_INITIALIZATION",
  GET_DASHBOARD_MEMBERSHIP_EXPIRED_USER_LIST_SUCCESS:
    "GET_DASHBOARD_MEMBERSHIP_EXPIRED_USER_LIST_SUCCESS",
  GET_DASHBOARD_MEMBERSHIP_EXPIRED_USER_LIST_ERROR:
    "GET_DASHBOARD_MEMBERSHIP_EXPIRED_USER_LIST_ERROR",
};

const GET_STUDENT_BY_ID_LIST = {
  GET_STUDENT_BY_ID_LIST_INITIALIZATION:
    "GET_STUDENT_BY_ID_LIST_INITIALIZATION",
  GET_STUDENT_BY_ID_LIST_SUCCESS: "GET_STUDENT_BY_ID_LIST_SUCCESS",
  GET_STUDENT_BY_ID_LIST_ERROR: "GET_STUDENT_BY_ID_LIST_ERROR",
};

const DELETE_EA = {
  DELETE_EA_INITIALIZATION: "DELETE_EA_INITIALIZATION",
  DELETE_EA_SUCCESS: "DELETE_EA_SUCCESS",
  DELETE_EA_ERROR: "DELETE_EA_ERROR",
};

const DELETE_SCRIPT = {
  DELETE_SCRIPT_INITIALIZATION: "DELETE_SCRIPT_INITIALIZATION",
  DELETE_SCRIPT_SUCCESS: "DELETE_SCRIPT_SUCCESS",
  DELETE_SCRIPT_ERROR: "DELETE_SCRIPT_ERROR",
};

const DELETE_INDICATOR = {
  DELETE_INDICATOR_INITIALIZATION: "DELETE_INDICATOR_INITIALIZATION",
  DELETE_INDICATOR_SUCCESS: "DELETE_INDICATOR_SUCCESS",
  DELETE_INDICATOR_ERROR: "DELETE_INDICATOR_ERROR",
};

const DELETE_UTILITY = {
  DELETE_UTILITY_INITIALIZATION: "DELETE_UTILITY_INITIALIZATION",
  DELETE_UTILITY_SUCCESS: "DELETE_UTILITY_SUCCESS",
  DELETE_UTILITY_ERROR: "DELETE_UTILITY_ERROR",
};

const DENIED_MEMEBER_DETAIL = {
  DENIED_MEMEBER_DETAIL_INITIALIZATION: "DENIED_MEMEBER_DETAIL_INITIALIZATION",
  DENIED_MEMEBER_DETAIL_SUCCESS: "DENIED_MEMEBER_DETAIL_SUCCESS",
  DENIED_MEMEBER_DETAIL_ERROR: "DENIED_MEMEBER_DETAIL_ERROR",
};

const MODIFY_MEMBER_DETAIL = {
  MODIFY_MEMBER_DETAIL_INITIALIZATION: "MODIFY_MEMBER_DETAIL_INITIALIZATION",
  MODIFY_MEMBER_DETAIL_SUCCESS: "MODIFY_MEMBER_DETAIL_SUCCESS",
  MODIFY_MEMBER_DETAIL_ERROR: "MODIFY_MEMBER_DETAIL_ERROR",
};

const GET_EMAIL_VERFICATION_LIST = {
  GET_EMAIL_VERFICATION_LIST_INITIALIZATION:
    "GET_EMAIL_VERFICATION_LIST_INITIALIZATION",
  GET_EMAIL_VERFICATION_LIST_SUCCESS: "GET_EMAIL_VERFICATION_LIST_SUCCESS",
  GET_EMAIL_VERFICATION_LIST_ERROR: "GET_EMAIL_VERFICATION_LIST_ERROR",
};

const CHANGE_PASSWORD = {
  CHANGE_PASSWORD_INITLIZATION: "CHANGE_PASSWORD_INITLIZATION",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_ERORR: "CHANGE_PASSWORD_ERORR",
};

const SET_MEDIA_UPLOAD_PROGRESS = "SET_MEDIA_UPLOAD_PROGRESS";
const SET_MEDIA_UPLOAD = "SET_MEDIA_UPLOAD";
const SET_MEDIA_UPLOAD_EMPTY = "SET_MEDIA_UPLOAD_EMPTY";

const GET_USER_CLASS = {
  GET_USER_CLASS_INITIALIZATION: "GET_USER_CLASS_INITIALIZATION",
  GET_USER_CLASS_SUCCESS: "GET_USER_CLASS_SUCCESS",
  GET_USER_CLASS_ERROR: "GET_USER_CLASS_ERROR",
};

const GET_USER_CLASS_ROLE = {
  GET_USER_CLASS_ROLE_INITIALIZATION: "GET_USER_CLASS_ROLE_INITIALIZATION",
  GET_USER_CLASS_ROLE_SUCCESS: "GET_USER_CLASS_ROLE_SUCCESS",
  GET_USER_CLASS_ROLE_ERROR: "GET_USER_CLASS_ERROR",
};

const GET_TICKET_ASSIGNEE_LIST = {
  GET_TICKET_ASSIGNEE_LIST_INITIALIZATION:
    "GET_TICKET_ASSIGNEE_LIST_INITIALIZATION",
  GET_TICKET_ASSIGNEE_LIST_SUCCESS: "GET_TICKET_ASSIGNEE_LIST_SUCCESS",
  GET_TICKET_ASSIGNEE_LIST_ERROR: "GET_TICKET_ASSIGNEE_LIST_ERROR",
};

const UPDATE_TICKET_ASSIGNEE_LIST = {
  UPDATE_TICKET_ASSIGNEE_LIST_INITIALIZATION:
    "UPDATE_TICKET_ASSIGNEE_LIST_INITIALIZATION",
  UPDATE_TICKET_ASSIGNEE_LIST_SUCCESS: "UPDATE_TICKET_ASSIGNEE_LIST_SUCCESS",
  UPDATE_TICKET_ASSIGNEE_LIST_ERROR: "UPDATE_TICKET_ASSIGNEE_LIST_ERROR",
};

const GET_USER_PRINT_DETAIL = {
  GET_USER_PRINT_DETAIL_INITIALIZATION: "GET_USER_PRINT_DETAIL_INITIALIZATION",
  GET_USER_PRINT_DETAIL_SUCCESS: "GET_USER_PRINT_DETAIL_SUCCESS",
  GET_USER_PRINT_DETAIL_ERROR: "GET_USER_PRINT_DETAIL_ERROR",
};

const RESEND_OTP = {
  RESEND_OTP_INITIALIZATION: "RESEND_OTP_INITIALIZATION",
  RESEND_OTP_SUCCESS: "RESEND_OTP_SUCCESS",
  RESEND_OTP_ERROR: "RESEND_OTP_ERROR",
};

const MAKE_ADMIN = {
  MAKE_ADMIN_INITIALIZATION: "MAKE_ADMIN_INITIALIZATION",
  MAKE_ADMIN_SUCCESS: "MAKE_ADMIN_SUCCESS",
  MAKE_ADMIN_ERROR: "MAKE_ADMIN_ERROR",
};

const SET_PAGE = "SET_PAGE";
const CHECK_STUDENT_ACTIVITY = {
  CHECK_STUDENT_ACTIVITY_INITIALIZATION:
    "CHECK_STUDENT_ACTIVITY_INITIALIZATION",
  CHECK_STUDENT_ACTIVITY_SUCCESS: "CHECK_STUDENT_ACTIVITY_SUCCESS",
  CHECK_STUDENT_ACTIVITY_ERROR: "CHECK_STUDENT_ACTIVITY_ERROR",
};

const GET_USER_VPS_MACHINE_ACCESS_LIST = {
  GET_USER_VPS_MACHINE_ACCESS_LIST_INITIALIZATION:
    "GET_USER_VPS_MACHINE_ACCESS_LIST_INITIALIZATION",
  GET_USER_VPS_MACHINE_ACCESS_LIST_SUCCESS:
    "GET_USER_VPS_MACHINE_ACCESS_LIST_SUCCESS",
  GET_USER_VPS_MACHINE_ACCESS_LIST_ERROR:
    "GET_USER_VPS_MACHINE_ACCESS_LIST_ERROR",
};

const EDIT_USER_VPS_MACHINE_ACCESS_LIST = {
  EDIT_USER_VPS_MACHINE_ACCESS_LIST_INITIALIZATION:
    "EDIT_USER_VPS_MACHINE_ACCESS_LIST_INITIALIZATION",
  EDIT_USER_VPS_MACHINE_ACCESS_LIST_SUCCESS:
    "EDIT_USER_VPS_MACHINE_ACCESS_LIST_SUCCESS",
  EDIT_USER_VPS_MACHINE_ACCESS_LIST_ERROR:
    "EDIT_USER_VPS_MACHINE_ACCESS_LIST_ERROR",
};

const GET_VPS_LIST = {
  GET_VPS_LIST_INITIALIZATION: "GET_VPS_LIST_INITIALIZATION",
  GET_VPS_LIST_SUCCESS: "GET_VPS_LIST_SUCCESS",
  GET_VPS_LIST_ERROR: "GET_VPS_LIST_ERROR",
};

const UPDATE_VPS_STATUS = {
  UPDATE_VPS_STATUS_INITIALIZATION: "UPDATE_VPS_STATUS_INITIALIZATION",
  UPDATE_VPS_STATUS_SUCCESS: "UPDATE_VPS_STATUS_SUCCESS",
  UPDATE_VPS_STATUS_ERROR: "UPDATE_VPS_STATUS_ERROR",
};

const VPS_RESET_PASSWORD = {
  VPS_RESET_PASSWORD_INITIALIZATION: "VPS_RESET_PASSWORD_INITIALIZATION",
  VPS_RESET_PASSWORD_SUCCESS: "VPS_RESET_PASSWORD_SUCCESS",
  VPS_RESET_PASSWORD_ERROR: "VPS_RESET_PASSWORD_ERROR",
};

const GET_VPS_MACHINE_TYPE_LIST = {
  GET_VPS_MACHINE_TYPE_LIST_INITIALIZATION:
    "GET_VPS_MACHINE_TYPE_LIST_INITIALIZATION",
  GET_VPS_MACHINE_TYPE_LIST_SUCCESS: "GET_VPS_MACHINE_TYPE_LIST_SUCCESS",
  GET_VPS_MACHINE_TYPE_LIST_ERROR: "GET_VPS_MACHINE_TYPE_LIST_ERROR",
};

const ADD_REFERAL = {
  ADD_REFERAL_INITIALIZATION: "ADD_REFERAL_INITIALIZATION",
  ADD_REFERAL_SUCCESS: "ADD_REFERAL_SUCCESS",
  ADD_REFERAL_ERROR: "ADD_REFERAL_ERROR",
};

const GET_REFERAL = {
  GET_REFERAL_INITIALIZATION: "GET_REFERAL_INITIALIZATION",
  GET_REFERAL_SUCCESS: "GET_REFERAL_SUCCESS",
  GET_REFERAL_ERROR: "GET_REFERAL_ERROR",
};

const SET_ACCOUNT_LIMIT = {
  SET_ACCOUNT_LIMIT_INITIALIZATION: "SET_ACCOUNT_LIMIT_INITIALIZATION",
  SET_ACCOUNT_LIMIT_SUCCESS: "SET_ACCOUNT_LIMIT_SUCCESS",
  SET_ACCOUNT_LIMIT_ERROR: "SET_ACCOUNT_LIMIT_ERROR",
};

const SET_ALTERNAITVE_NAMES = {
  SET_ALTERNAITVE_NAMES_INITIALIZATION: "SET_ALTERNAITVE_NAMES_INITIALIZATION",
  SET_ALTERNAITVE_NAMES_SUCCESS: "SET_ALTERNAITVE_NAMES_SUCCESS",
  SET_ALTERNAITVE_NAMES_ERROR: "SET_ALTERNAITVE_NAMES_ERROR",
};

const SET_MANUAL_PASSWORD_LIST = {
  SET_MANUAL_PASSWORD_LIST_INITIALIZATION:
    "SET_MANUAL_PASSWORD_LIST_INITIALIZATION",
  SET_MANUAL_PASSWORD_LIST_SUCCESS: "SET_MANUAL_PASSWORD_LIST_SUCCESS",
  SET_MANUAL_PASSWORD_LIST_ERROR: "SET_MANUAL_PASSWORD_LIST_ERROR",
};
const UPDATE_ADMIN_STATUS = {
  UPDATE_ADMIN_STATUS_INITIALIZATION: "UPDATE_ADMIN_STATUS_INITIALIZATION",
  UPDATE_ADMIN_STATUS_SUCCESS: "UPDATE_ADMIN_STATUS_SUCCESS",
  UPDATE_ADMIN_STATUS_ERROR: "UPDATE_ADMIN_STATUS_ERROR",
};
const GET_ALL_USERS_LIST = {
  GET_ALL_USERS_LIST_INITIALIZATION: "GET_ALL_USERS_LIST_INITIALIZATION",
  GET_ALL_USERS_LIST_SUCCESS: "GET_ALL_USERS_LIST_SUCCESS",
  GET_ALL_USERS_LIST_ERROR: "GET_ALL_USERS_LIST_ERROR",
};
const ADD_ADMIN_USER = {
  ADD_ADMIN_USER_INITIALIZATION: "ADD_ADMIN_USER_INITIALIZATION",
  ADD_ADMIN_USER_SUCCESS: "ADD_ADMIN_USER_SUCCESS",
  ADD_ADMIN_USER_ERROR: "ADD_ADMIN_USER_ERROR",
};
const DELETE_ADVERTISEMENT = {
  DELETE_ADVERTISEMENT_INITIALIZATION: "DELETE_ADVERTISEMENT_INITIALIZATION",
  DELETE_ADVERTISEMENT_SUCCESS: "DELETE_ADVERTISEMENT_SUCCESS",
  DELETE_ADVERTISEMENT_ERROR: "DELETE_ADVERTISEMENT_ERROR",
};
const SET_STATUS_CODE = "SET_STATUS_CODE";
const SET_DOWNLOAD_MEDIA_PROGRESS_SUCCESS =
  "SET_DOWNLOAD_MEDIA_PROGRESS_SUCCESS";
const COMPLETE_DOWNLOAD_MEDIA = "COMPLETE_DOWNLOAD_MEDIA";

const GET_ALL_FAQ_CATEGORY_LIST = {
  GET_ALL_FAQ_CATEGORY_LIST_INITIALIZATION:
    "GET_ALL_FAQ_CATEGORY_LIST_INITIALIZATION",
  GET_ALL_FAQ_CATEGORY_LIST_SUCCESS: "GET_ALL_FAQ_CATEGORY_LIST_SUCCESS",
  GET_ALL_FAQ_CATEGORY_LIST_ERROR: "GET_ALL_FAQ_CATEGORY_LIST_ERROR",
};

const ADD_FAQ_CATEGORY = {
  ADD_FAQ_CATEGORY_INITIALIZATION: "ADD_FAQ_CATEGORY_INITIALIZATION",
  ADD_FAQ_CATEGORY_SUCCESS: "ADD_FAQ_CATEGORY_SUCCESS",
  ADD_FAQ_CATEGORY_ERROR: "ADD_FAQ_CATEGORY_ERROR",
};

const EDIT_FAQ_CATEGORY = {
  EDIT_FAQ_CATEGORY_INITIALIZATION: "EDIT_FAQ_CATEGORY_INITIALIZATION",
  EDIT_FAQ_CATEGORY_SUCCESS: "EDIT_FAQ_CATEGORY_SUCCESS",
  EDIT_FAQ_CATEGORY_ERROR: "EDIT_FAQ_CATEGORY_ERROR",
};

const DELETE_FAQ_CATEGORY = {
  DELETE_FAQ_CATEGORY_INITIALIZATION: "DELETE_FAQ_CATEGORY_INITIALIZATION",
  DELETE_FAQ_CATEGORY_SUCCESS: "DELETE_FAQ_CATEGORY_SUCCESS",
  DELETE_FAQ_CATEGORY_ERROR: "DELETE_FAQ_CATEGORY_ERROR",
};

const GET_ALL_BILLS_LIST = {
  GET_ALL_BILLS_LIST_INITIALIZATION: "GET_ALL_BILLS_LIST_INITIALIZATION",
  GET_ALL_BILLS_LIST_SUCCESS: "GET_ALL_BILLS_LIST_SUCCESS",
  GET_ALL_BILLS_LIST_ERROR: "GET_ALL_BILLS_LIST_ERROR",
};

const EDIT_BILL_LIST = {
  EDIT_BILL_LIST_INITIALIZATION: "EDIT_BILL_LIST_INITIALIZATION",
  EDIT_BILL_LIST_SUCCESS: "EDIT_BILL_LIST_SUCCESS",
  EDIT_BILL_LIST_ERROR: "EDIT_BILL_LIST_ERROR",
};

const UPDATE_BILL_LIST = {
  UPDATE_BILL_LIST_INITIALIZATION: "UPDATE_BILL_LIST_INITIALIZATION",
  UPDATE_BILL_LIST_SUCCESS: "UPDATE_BILL_LIST_SUCCESS",
  UPDATE_BILL_LIST_ERROR: "UPDATE_BILL_LIST_ERROR",
};

const ADD_ACCOUNT_INFO_LIST = {
  ADD_ACCOUNT_INFO_LIST_INITIALIZATION: "ADD_ACCOUNT_INFO_LIST_INITIALIZATION",
  ADD_ACCOUNT_INFO_LIST_SUCCESS: "ADD_ACCOUNT_INFO_LIST_SUCCESS",
  ADD_ACCOUNT_INFO_LIST_ERROR: "ADD_ACCOUNT_INFO_LIST_ERROR",
};

const GET_ACCOUNT_INFO_LIST = {
  GET_ACCOUNT_INFO_LIST_INITIALIZATION: "GET_ACCOUNT_INFO_LIST_INITIALIZATION",
  GET_ACCOUNT_INFO_LIST_SUCCESS: "GET_ACCOUNT_INFO_LIST_SUCCESS",
  GET_ACCOUNT_INFO_LIST_ERROR: "GET_ACCOUNT_INFO_LIST_ERROR",
  GET_ACCOUNT_INFO_LIST_DISCRAED: "GET_ACCOUNT_INFO_LIST_DISCRAED",
};

const GET_ALL_CONSUMER = {
  GET_ALL_CONSUMER_INITIALIZATION: "GET_ALL_CONSUMER_INITIALIZATION",
  GET_ALL_CONSUMER_SUCCESS: "GET_ALL_CONSUMER_SUCCESS",
  GET_ALL_CONSUMER_ERROR: "GET_ALL_CONSUMER_ERROR",
};

const GET_CONSUMER_ACCOUNTS_BY_USER = {
  GET_CONSUMER_ACCOUNTS_BY_USER_INITIALIZATION:
    "GET_CONSUMER_ACCOUNTS_BY_USER_INITIALIZATION",
  GET_CONSUMER_ACCOUNTS_BY_USER_SUCCESS:
    "GET_CONSUMER_ACCOUNTS_BY_USER_SUCCESS",
  GET_CONSUMER_ACCOUNTS_BY_USER_ERROR: "GET_CONSUMER_ACCOUNTS_BY_USER_ERROR",
};

const GET_CONSUMER_PRODUCT_PURCHASE_BY_USER = {
  GET_CONSUMER_PRODUCT_PURCHASE_BY_USER_INITIALIZATION:
    "GET_CONSUMER_PRODUCT_PURCHASE_BY_USER_INITIALIZATION",
  GET_CONSUMER_PRODUCT_PURCHASE_BY_USER_SUCCESS:
    "GET_CONSUMER_PRODUCT_PURCHASE_BY_USER_SUCCESS",
  GET_CONSUMER_PRODUCT_PURCHASE_BY_USER_ERROR:
    "GET_CONSUMER_PRODUCT_PURCHASE_BY_USER_ERROR",
};

export {
  SET_STATUS_CODE,
  ADD_CLASSES,
  CHECK_STUDENT_ACTIVITY,
  ADD_CLASS_TOOL_BY_TYPE,
  REGISTER,
  LOGIN,
  LOGOUT,
  FORGOT_PASSWORD,
  GET_CLASSES,
  GET_CLASSES_DETAIL,
  GET_EXPRESSWAY_PENDING_LIST,
  GET_EXPRESSWAY_RECORD_LIST,
  GET_CLASS_CATEGORY,
  EDIT_CLASSES,
  GET_PRODUCTS,
  GET_ADMIN_ROLES_LIST,
  GET_ADMIN_ROLES_TOOLS_LIST,
  GET_ADMIN_ROLES_USER_PERMISSIONS_LIST,
  GET_ADMIN_ROLES_USER_LOGGINGS_LIST,
  GET_ADMIN_ROLES_USER_EA_ACCOUNT_LIST,
  GET_INDICATORS_LIST,
  GET_FAQ_LIST,
  GET_FAQ_CATEGORY_LIST,
  ADD_FAQ_LIST,
  EDIT_FAQ_LIST,
  DELETE_FAQ,
  GET_EXPRESS_WAY_PENDING_LIST,
  GET_ANNOUNCEMENT_LIST,
  EDIT_INDICATORS_LIST,
  ADD_INDICATORS_LIST,
  GET_INDICATORS_DETAILS,
  GET_SCRIPTS_LIST,
  GET_SCRIPTS_DETAILS,
  ADD_SCRIPTS_LIST,
  EDIT_SCRIPTS_LIST,
  GET_UTILITY_LIST,
  GET_UTILITY_DETAILS,
  ADD_UTILITY_LIST,
  EDIT_UTILITY_LIST,
  GET_ANNOUNCEMENT_DETAILS,
  ADD_ANNOUNCEMENT,
  GET_EXPERT_ADVISOR_LIST,
  GET_EXPERT_ADVISOR_DETAILS,
  ADD_EXPERT_ADVISOR_LIST,
  EDIT_EXPERT_ADVISOR_LIST,
  GET_GIFT_DETAILS,
  GET_GIFT_LIST,
  EDIT_GIFT_LIST,
  ADD_GIFT_LIST,
  UPALOD_MEDIA_LIST,
  ADD_ANNOUNCEMENT_COMMENT,
  GET_ANNOUNCEMENT_COMMENT,
  EDIT_ANNOUNCEMENT_LIST,
  DELETE_ANNOUNCEMENT_COMMENT,
  CHANGE_LOCAL,
  GET_TICKET_DETAILS,
  GET_TICKET_LIST,
  ADD_TICKET_LIST,
  EDIT_TICKET_LIST,
  GET_TICKET_ISSUE_TYPE_LIST,
  GET_TICKET_COMMENT_LIST,
  ADD_TICKET_COMMENT_LIST,
  GET_CLASS_TOOL_BY_TYPE,
  GET_STUDENT_LIST,
  GET_ADMIN_LIST,
  GET_EXPRESSWAY_MEMBER_DETAIL,
  ACT_EXPRESSWAY_MEMBER_DETAIL,
  GET_USER_DETAIL,
  EDIT_USER_DETAIL,
  GET_USER_ATTENDENCE,
  GET_TICKET_TOOL_VERSION_LIST,
  GET_TICKET_TOOL_LIST,
  ADD_CLASS_TOOL_VERSION,
  GET_CLASS_TOOL_VERSION,
  DELETE_CLASS_TOOL_VERSION,
  DELETE_CLASS_TOOL,
  GET_INDICATORS_VERSION_LIST,
  ADD_INDICATORS_VERSION,
  EDIT_INDICATORS_VERSION,
  DELETE_INDICATORS_VERSION,
  GET_EA_VERSION_LIST,
  ADD_EA_VERSION,
  EDIT_EA_VERSION,
  DELETE_EA_VERSION,
  GET_SCRIPT_VERSION_LIST,
  ADD_SCRIPT_VERSION,
  EDIT_SCRIPT_VERSION,
  DELETE_SCRIPT_VERSION,
  GET_UTILITY_VERSION_LIST,
  ADD_UTILITY_VERSION,
  EDIT_UTILITY_VERSION,
  DELETE_UTILITY_VERSION,
  GET_INDUSTRY_LIST,
  GET_JOB_LIST,
  GET_REGISTRATION_MATERIAL,
  DELETE_TICKET_LIST,
  GET_ACTIVITY_POINTS_LIST,
  EDIT_ACTIVITY_DETAILS_LIST,
  GET_ACTIVITY_DETAILS_LIST,
  GET_ACTIVITY_ATTENDENCE_LIST,
  ADD_ACTIVITY_LIST,
  GET_ACTIVITY_LIST,
  GET_LIBRARY_LIST,
  GET_LIBRARY_VERSION_LIST,
  GET_ACTIVITY_ATTENDENCE_LOG_LIST,
  ADD_ACTIVITY_POINTS_LIST,
  GET_GIFT_CATEGORY_LIST,
  ADD_GIFT_CATEGORY_LIST,
  EDIT_GIFT_CATEGORY_LIST,
  GET_STUDENT_BY_CLASS_LIST,
  GET_ADVERTISEMENT_LIST,
  GET_ADVERTISEMENT_TYPE_LIST,
  GET_ADVERTISEMENT_TYPE_TOOL_LIST,
  ADD_ADVERTISEMENT,
  GET_RENTS_CATEGORY_LIST,
  GET_RENTS_LIST,
  ADD_RENTS_LIST,
  EDIT_RENTS_LIST,
  GET_GRANT_LIST,
  GRANT_POINT_LIST,
  GRANT_TOOL_LIST,
  GRANT_ACCOUNT,
  ADD_CLASS_MEMBERSHIP,
  ADD_USER_MEMBERSHIP,
  GRADUATE_ALL_STUDENT_CLASS,
  GET_QR_CODE,
  EXAMINE_STUDENT_LIST,
  GET_STUDENT_BY_ID_LIST,
  DELETE_ANNOUNCEMENT,
  DELETE_EA,
  DELETE_INDICATOR,
  DELETE_SCRIPT,
  DELETE_UTILITY,
  ADD_STUDENT_MANAUAL_LIST,
  MODIFY_MEMBER_DETAIL,
  DENIED_MEMEBER_DETAIL,
  REGISTER_ACTIVITY_LIST,
  ROLL_CALL_ACTIVITY_LIST,
  ATTEND_ACTIVITY,
  EDIT_TICKET_COMMENT_LIST,
  GET_EMAIL_VERFICATION_LIST,
  CHANGE_PASSWORD,
  SET_MEDIA_UPLOAD_PROGRESS,
  DELETE_MEDIA_AMAZONS3,
  DELETE_MEDIA_LOCAL,
  GET_LIBRARY_DETAIL,
  ADD_LIBRARY_LIST,
  EDIT_LIBRARY_LIST,
  DELETE_LIBRARY_LIST,
  ADD_LIBRARY_VERSION_LIST,
  EDIT_LIBRARY_VERSION_LIST,
  DELETE_LIBRARY_VERSION_LIST,
  GET_DASHBOARD_COUNT,
  GET_DASHBOARD_MEMBERSHIP_EXPIRED_USER_LIST,
  GET_USER_CLASS,
  GET_USER_CLASS_ROLE,
  GET_TICKET_ASSIGNEE_LIST,
  UPDATE_TICKET_ASSIGNEE_LIST,
  GET_USER_PRINT_DETAIL,
  RESEND_OTP,
  MAKE_ADMIN,
  SET_PAGE,
  SET_MEDIA_UPLOAD,
  SET_MEDIA_UPLOAD_EMPTY,
  GET_USER_VPS_MACHINE_ACCESS_LIST,
  GET_VPS_LIST,
  UPDATE_VPS_STATUS,
  GET_VPS_MACHINE_TYPE_LIST,
  EDIT_USER_VPS_MACHINE_ACCESS_LIST,
  ADD_EA_VERSION_TO_ALL_CLASS,
  ADD_REFERAL,
  GET_REFERAL,
  SET_ACCOUNT_LIMIT,
  SET_ALTERNAITVE_NAMES,
  SET_MANUAL_PASSWORD_LIST,
  CREATE_TICKET_GROUP_LIST,
  DELETE_TICKET_GROUP,
  GET_TICKET_GROUP_LIST,
  ADD_GROUP_MEMBER_LIST,
  GET_ADMIN_MEMBER_LIST,
  DELETE_GROUP_MEMBER_LIST,
  DELETE_TICKET_COMMENT_LIST,
  UPDATE_ADMIN_STATUS,
  VPS_RESET_PASSWORD,
  GET_ALL_USERS_LIST,
  ADD_ADMIN_USER,
  EDIT_ADVERTISEMENT,
  DELETE_ADVERTISEMENT,
  DOWNLOAD_MEDIA_LIST,
  SET_DOWNLOAD_MEDIA_PROGRESS_SUCCESS,
  COMPLETE_DOWNLOAD_MEDIA,
  GET_ALL_FAQ_CATEGORY_LIST,
  ADD_FAQ_CATEGORY,
  EDIT_FAQ_CATEGORY,
  DELETE_FAQ_CATEGORY,
  GET_ALL_BILLS_LIST,
  EDIT_BILL_LIST,
  UPDATE_BILL_LIST,
  ADD_ACCOUNT_INFO_LIST,
  GET_ACCOUNT_INFO_LIST,
  GET_ALL_CONSUMER,
  GET_CONSUMER_ACCOUNTS_BY_USER,
  GET_CONSUMER_PRODUCT_PURCHASE_BY_USER,
};
