/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/APIServices/baseApiCall";
import {
  GET_TICKET_LIST,
  ADD_TICKET_LIST,
  EDIT_TICKET_LIST,
  GET_TICKET_DETAILS,
  GET_TICKET_ISSUE_TYPE_LIST,
  ADD_TICKET_COMMENT_LIST,
  GET_TICKET_COMMENT_LIST,
  GET_TICKET_TOOL_VERSION_LIST,
  GET_TICKET_TOOL_LIST,
  DELETE_TICKET_LIST,
  EDIT_TICKET_COMMENT_LIST,
  GET_TICKET_ASSIGNEE_LIST,
  UPDATE_TICKET_ASSIGNEE_LIST,
  GET_TICKET_GROUP_LIST,
  CREATE_TICKET_GROUP_LIST,
  DELETE_GROUP_MEMBER_LIST,
  GET_ADMIN_MEMBER_LIST,
  DELETE_TICKET_COMMENT_LIST,
  DELETE_TICKET_GROUP,
  SET_STATUS_CODE,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { getAPIEndPoint, setToken } from "../../util/utils";
import {
  GET_TICKET_LIST_URL,
  ADD_TICKET_LIST_URL,
  EIDT_TICKET_LIST_URL,
  GET_TICKET_ISSUE_TYPE_LIST_URL,
  ADD_TICKET_COMMENT_LIST_URL,
  GET_TICKET_COMMENT_LIST_URL,
  GET_TICKET_DETAIL_URL,
  GET_TICKET_TOOL_LIST_URL,
  GET_TICKET_TOOL_VERSION_LIST_URL,
  DELETE_TICKET_LIST_URL,
  EDIT_TICKET_COMMENT_LIST_URL,
  GET_TICKET_ASSIGNEE_LIST_URL,
  UPDATE_TICKET_ASSIGNEE_LIST_URL,
  GET_TICKET_GROUP_LIST_URL,
  CREATE_TICKET_GROUP_LIST_URL,
  ADD_GROUP_MEMBER_LIST_URL,
  DELETE_GROUP_MEMBER_LIST_URL,
  GET_ADMIN_MEMBER_LIST_URL,
  DELETE_TICKET_COMMENT_LIST_URL,
  DELETE_TICKET_GROUP_URL,
} from "../../service/APIServices/apiEndPoints";
import { toast } from "react-toastify";
import store from "..";
import { setMediaUploadEmpty } from "./mediaUploadAction";
import { GROUP_TYPE } from "../../Constants";
// import { _getExpressWayPendingList } from "../../service";
// import { AxiosResponse } from "axios";

//Get Admin Roles List
export const getTicketList =
  (
    showToast = false,
    pageNumber = 0,
    sort: any,
    filter = "",
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getTicketListInit(showToast, pageNumber, sort, filter, pageLimit));
  };

export const getTicketListInit =
  (
    showToast: boolean,
    pageNumber: number,
    sort: any,
    filter: any,
    pageLimit: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TICKET_LIST.GET_TICKET_LIST_INITIALIZATION,
    });
    apiCall(
      getAPIEndPoint(
        GET_TICKET_LIST_URL + `?sort=${sort.order}&sortBy=${sort.by}${filter}`,
        pageNumber,
        true,
        pageLimit,
      ),
      {},
      (res: Object) => dispatch(getTicketListSuccess(res)),
      (err: any) => dispatch(getTicketListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getTicketListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TICKET_LIST.GET_TICKET_LIST_SUCCESS,
      payload: res,
    });
  };

export const getTicketListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_TICKET_LIST.GET_TICKET_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const addTicketList =
  (
    showToast = true,
    indicatorData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(addTicketListInit(showToast, indicatorData));
  };

export const addTicketListInit =
  (
    showToast = true,
    indicatorData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_TICKET_LIST.ADD_TICKET_LIST_INITIALIZATION,
    });
    apiCall(
      ADD_TICKET_LIST_URL,
      indicatorData,
      (res: Object) => dispatch(addTicketListSuccess(res)),
      (err: any) => dispatch(addTicketListError()),
      METHOD.POST,
      { showToast },
    );
  };

export const addTicketListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_TICKET_LIST.ADD_TICKET_LIST_SUCCESS,
    });
    // const state: Store = store.getState();
    // const pageNumber = state.userDataReducer.pageNumber;
    dispatch(getTicketList(false, 1, { by: "updatedAt", order: "DESC" }));
  };

export const addTicketListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_TICKET_LIST.ADD_TICKET_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const editTicketList =
  (
    showToast = true,
    id: any,
    indicatorData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(editTicketListInit(showToast, id, indicatorData));
  };

export const editTicketListInit =
  (
    showToast = true,
    id: any,
    indicatorData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_TICKET_LIST.EDIT_TICKET_LIST_INITIALIZATION,
    });
    apiCall(
      EIDT_TICKET_LIST_URL + id,
      indicatorData,
      (res: Object) => dispatch(editTicketListSuccess(res, id)),
      (err: any) => dispatch(editTicketListError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const editTicketListSuccess =
  (res: any, id: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_TICKET_LIST.EDIT_TICKET_LIST_SUCCESS,
      payload: res.data,
    });

    // dispatch(getTicketDetail(false, id));
    // const state: Store = store.getState();
    // const pageNumber = state.userDataReducer.pageNumber;

    dispatch(getTicketDetail(false, id));
    dispatch(getTicketList(false, 1, { by: "updatedAt", order: "DESC" }));
    dispatch(getTicketCommentList(false, id));
  };

export const editTicketListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: EDIT_TICKET_LIST.EDIT_TICKET_LIST_ERROR,
    });
  };

//Get Admin Roles Detail
export const getTicketDetail =
  (
    showToast = false,
    id: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getTicketDetailInit(showToast, id));
  };

export const getTicketDetailInit =
  (
    showToast: boolean,
    id: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TICKET_DETAILS.GET_TICKET_DETAILS_INITIALIZATION,
    });
    apiCall(
      GET_TICKET_DETAIL_URL + id,
      {},
      (res: Object) => dispatch(getTicketDetailSuccess(res)),
      (err: any) => dispatch(getTicketDetailError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getTicketDetailSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    // dispatch({
    //   type: SET_STATUS_CODE,
    //   payload: res.status
    // })
    dispatch({
      type: GET_TICKET_DETAILS.GET_TICKET_DETAILS_SUCCESS,
      payload: res.data,
    });
  };

export const getTicketDetailError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: SET_STATUS_CODE,
      payload: 404,
    });
    dispatch({
      type: GET_TICKET_DETAILS.GET_TICKET_DETAILS_ERROR,
    });
  };

export const getTicketIssueTypeList =
  (showToast = false): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getTicketIssueTypeListInit(showToast));
  };

export const getTicketIssueTypeListInit =
  (showToast: boolean): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TICKET_ISSUE_TYPE_LIST.GET_TICKET_ISSUE_TYPE_LIST_INITIALIZATION,
    });
    apiCall(
      GET_TICKET_ISSUE_TYPE_LIST_URL,
      {},
      (res: Object) => dispatch(getTicketIssueTypeListSuccess(res)),
      (err: any) => dispatch(getTicketIssueTypeListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getTicketIssueTypeListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TICKET_ISSUE_TYPE_LIST.GET_TICKET_ISSUE_TYPE_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getTicketIssueTypeListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_TICKET_ISSUE_TYPE_LIST.GET_TICKET_ISSUE_TYPE_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const getTicketCommentList =
  (
    showToast = false,
    id: number,
    pageNumber = 1,
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getTicketCommentListInit(showToast, id, pageNumber, pageLimit));
  };

export const getTicketCommentListInit =
  (
    showToast: boolean,
    id: number,
    pageNumber: any,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TICKET_COMMENT_LIST.GET_TICKET_COMMENT_LIST_INITIALIZATION,
    });
    apiCall(
      GET_TICKET_COMMENT_LIST_URL +
        id +
        `?sortBy=createdAt&sort=DESC&page=${pageNumber}&perPage=${pageLimit}`,
      {},
      (res: Object) => dispatch(getTicketCommentListSuccess(res)),
      (err: any) => dispatch(getTicketCommentListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getTicketCommentListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TICKET_COMMENT_LIST.GET_TICKET_COMMENT_LIST_SUCCESS,
      payload: res,
    });
  };

export const getTicketCommentListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_TICKET_COMMENT_LIST.GET_TICKET_COMMENT_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const addTicketCommentList =
  (
    showToast = true,
    id: any,
    comment: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(addTicketCommentListInit(showToast, id, comment));
  };

export const addTicketCommentListInit =
  (
    showToast = true,
    id: any,
    comment: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_TICKET_COMMENT_LIST.ADD_TICKET_COMMENT_LIST_INITIALIZATION,
    });
    apiCall(
      ADD_TICKET_COMMENT_LIST_URL + id,
      comment,
      (res: Object) => dispatch(addTicketCommentListSuccess(res, id)),
      (err: any) => dispatch(addTicketCommentListError()),
      METHOD.POST,
      { showToast },
    );
  };

export const addTicketCommentListSuccess =
  (res: any, id: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_TICKET_COMMENT_LIST.ADD_TICKET_COMMENT_LIST_SUCCESS,
    });
    dispatch(getTicketCommentList(false, id));
    dispatch(getTicketDetail(false, id));
  };

export const addTicketCommentListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_TICKET_COMMENT_LIST.ADD_TICKET_COMMENT_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const getTicketToolList =
  (
    showToast = true,
    toolType: number,
    classId = 0,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getTicketToolListInit(showToast, toolType, classId));
  };

export const getTicketToolListInit =
  (
    showToast = true,
    toolType: number,
    classId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TICKET_TOOL_LIST.GET_TICKET_TOOL_LIST_INITIALIZATION,
    });
    let url = GET_TICKET_TOOL_LIST_URL;
    if (classId > 0) {
      url = `/class/tool/` + classId;
    }
    apiCall(
      // `${GET_TICKET_TOOL_LIST_URL}`,
      url,
      {},
      (res: Object) => dispatch(getTicketToolListSuccess(res)),
      (err: any) => dispatch(getTicketToolListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getTicketToolListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TICKET_TOOL_LIST.GET_TICKET_TOOL_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getTicketToolListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_TICKET_TOOL_LIST.GET_TICKET_TOOL_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const getTicketToolVersionList =
  (
    showToast = true,
    toolId: any,
    tool: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getTicketToolVersionListInit(showToast, toolId, tool));
  };

export const getTicketToolVersionListInit =
  (
    showToast = true,
    toolId: any,
    tool: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TICKET_TOOL_VERSION_LIST.GET_TICKET_TOOL_VERSION_LIST_INITIALIZATION,
    });
    apiCall(
      `${GET_TICKET_TOOL_VERSION_LIST_URL}${tool}/${toolId}`,
      {},
      (res: Object) => dispatch(getTicketToolVersionListSuccess(res)),
      (err: any) => dispatch(getTicketToolVersionListError()),
      METHOD.GET,
      {
        showToast: showToast,
      },
    );
  };

export const getTicketToolVersionListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TICKET_TOOL_VERSION_LIST.GET_TICKET_TOOL_VERSION_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getTicketToolVersionListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_TICKET_TOOL_VERSION_LIST.GET_TICKET_TOOL_VERSION_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const deleteTicketList =
  (
    showToast = true,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(deleteTicketListInit(showToast, id));
  };

export const deleteTicketListInit =
  (
    showToast = true,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_TICKET_LIST.DELETE_TICKET_LIST_INITIALIZATION,
    });
    apiCall(
      DELETE_TICKET_LIST_URL + id,
      {},
      (res: Object) => dispatch(deleteTicketListSuccess(res)),
      (err: any) => dispatch(deleteTicketListError()),
      METHOD.DELETE,
      { showToast },
    );
  };

export const deleteTicketListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_TICKET_LIST.DELETE_TICKET_LIST_SUCCESS,
      payload: res.data,
    });

    dispatch(getTicketList(false, 0, { by: "updatedAt", order: "DESC" }));
  };

export const deleteTicketListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: DELETE_TICKET_LIST.DELETE_TICKET_LIST_ERROR,
    });
  };

export const getTicketAssigneeList =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getTicketAssigneeListInit(showToast));
  };

export const getTicketAssigneeListInit =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TICKET_ASSIGNEE_LIST.GET_TICKET_ASSIGNEE_LIST_INITIALIZATION,
    });
    apiCall(
      GET_TICKET_ASSIGNEE_LIST_URL,
      {},
      (res: Object) => dispatch(getTicketAssigneeListSuccess(res)),
      (err: any) => dispatch(getTicketAssigneeListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getTicketAssigneeListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TICKET_ASSIGNEE_LIST.GET_TICKET_ASSIGNEE_LIST_SUCCESS,
      payload: res.data,
    });

    // dispatch(getTicketList(false));
  };

export const getTicketAssigneeListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_TICKET_ASSIGNEE_LIST.GET_TICKET_ASSIGNEE_LIST_ERROR,
    });
  };

export const updateTicketAssigneeList =
  (
    showToast = true,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(updateTicketAssigneeListInit(showToast, id));
  };

export const updateTicketAssigneeListInit =
  (
    showToast = true,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: UPDATE_TICKET_ASSIGNEE_LIST.UPDATE_TICKET_ASSIGNEE_LIST_INITIALIZATION,
    });
    apiCall(
      UPDATE_TICKET_ASSIGNEE_LIST_URL + id,
      {},
      (res: Object) => dispatch(updateTicketAssigneeListSuccess(res)),
      (err: any) => dispatch(updateTicketAssigneeListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const updateTicketAssigneeListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: UPDATE_TICKET_ASSIGNEE_LIST.UPDATE_TICKET_ASSIGNEE_LIST_SUCCESS,
      payload: res.data,
    });

    dispatch(getTicketList(false, 0, { by: "updatedAt", order: "DESC" }));
  };

export const updateTicketAssigneeListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: UPDATE_TICKET_ASSIGNEE_LIST.UPDATE_TICKET_ASSIGNEE_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const getTicketGroupList =
  (
    showToast = false,
    type: any,
    g = false,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getTicketGroupListInit(showToast, type, g));
  };

// GET_TICKET_GROUP_LIST_URL + 'groupType=' + type, // + "?sort=DESC&sortBy=updatedAt",

export const getTicketGroupListInit =
  (
    showToast: boolean,
    type: any,
    g: any,
    // pageNumber: number
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TICKET_GROUP_LIST.GET_TICKET_GROUP_LIST_INITIALIZATION,
    });
    const stores: Store = store.getState();
    apiCall(
      GET_TICKET_GROUP_LIST_URL +
        "?groupType=" +
        type +
        "&groupHavingClass=" +
        g, // + "?sort=DESC&sortBy=updatedAt",
      // getAPIEndPoint(
      //   // pageNumber,
      //   true
      // ),
      {},
      (res: Object) => dispatch(getTicketGroupListSuccess(res)),
      (err: any) => dispatch(getTicketGroupListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getTicketGroupListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TICKET_GROUP_LIST.GET_TICKET_GROUP_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getTicketGroupListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_TICKET_GROUP_LIST.GET_TICKET_GROUP_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const createTicketGroupList =
  (
    data: any,
    showToast = false,
    type = GROUP_TYPE.TICKET,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(createTicketGroupListInit(showToast, data, type));
  };

export const createTicketGroupListInit =
  (
    showToast: boolean,
    data: any,
    type: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: CREATE_TICKET_GROUP_LIST.CREATE_TICKET_GROUP_LIST_INITIALIZATION,
    });
    const stores: Store = store.getState();
    apiCall(
      CREATE_TICKET_GROUP_LIST_URL, // + "?sort=DESC&sortBy=updatedAt",
      // getAPIEndPoint(
      //   // data,
      //   true
      // ),
      { ...data, groupType: type },
      (res: Object) => dispatch(createTicketGroupListSuccess(res, type)),
      (err: any) => dispatch(createTicketGroupListError()),
      METHOD.POST,
      { showToast },
    );
  };

export const createTicketGroupListSuccess =
  (res: any, type: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: CREATE_TICKET_GROUP_LIST.CREATE_TICKET_GROUP_LIST_SUCCESS,
      payload: res.data,
    });
    dispatch(getTicketGroupList(false, type));
  };

export const createTicketGroupListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: CREATE_TICKET_GROUP_LIST.CREATE_TICKET_GROUP_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const AddGroupMemberList =
  (
    data: any,
    showToast = false,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(AddGroupMemberListInit(showToast, data));
  };

export const AddGroupMemberListInit =
  (
    showToast: boolean,
    data: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: CREATE_TICKET_GROUP_LIST.CREATE_TICKET_GROUP_LIST_INITIALIZATION,
    });
    const stores: Store = store.getState();
    apiCall(
      ADD_GROUP_MEMBER_LIST_URL, // + "?sort=DESC&sortBy=updatedAt",
      // getAPIEndPoint(
      //   // data,
      //   true
      // ),
      data,
      (res: Object) => dispatch(AddGroupMemberListSuccess(res, data.groupType)),
      (err: any) => dispatch(AddGroupMemberListError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const AddGroupMemberListSuccess =
  (res: any, type: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: CREATE_TICKET_GROUP_LIST.CREATE_TICKET_GROUP_LIST_SUCCESS,
      payload: res.data,
    });
    dispatch(getTicketGroupList(false, type));
  };

export const AddGroupMemberListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: CREATE_TICKET_GROUP_LIST.CREATE_TICKET_GROUP_LIST_ERROR,
    });
  };

export const DeleteTicketGroupInit =
  (
    showToast: boolean,
    data: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_TICKET_GROUP.DELETE_TICKET_GROUP_INITIALIZATION,
    });
    apiCall(
      DELETE_TICKET_GROUP_URL + "/" + data?.id + "/" + data?.groupType,
      {},
      (res: Object) => dispatch(DeleteTicketGroupSuccess(res, data.groupType)),
      (err: any) => dispatch(DeleteTicketGroupError()),
      METHOD.DELETE,
      { showToast },
    );
  };

export const DeleteTicketGroupSuccess =
  (res: any, type: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_TICKET_GROUP.DELETE_TICKET_GROUP_SUCCESS,
      payload: res.data,
    });
    dispatch(getTicketGroupList(false, type));
  };

export const DeleteTicketGroupError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: DELETE_TICKET_GROUP.DELETE_TICKET_GROUP_ERROR,
    });
  };

//Get Admin Roles List
export const DeleteGroupMemberList =
  (
    data: any,
    showToast = false,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(DeleteGroupMemberListInit(showToast, data));
  };

export const DeleteGroupMemberListInit =
  (
    showToast: boolean,
    data: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_GROUP_MEMBER_LIST.DELETE_GROUP_MEMBER_LIST_INITIALIZATION,
    });
    const stores: Store = store.getState();
    apiCall(
      DELETE_GROUP_MEMBER_LIST_URL, // + "?sort=DESC&sortBy=updatedAt",
      // getAPIEndPoint(
      //   // data,
      //   true
      // ),
      data,
      (res: Object) =>
        dispatch(DeleteGroupMemberListSuccess(res, data.groupType)),
      (err: any) => dispatch(DeleteGroupMemberListError()),
      METHOD.POST,
      { showToast },
    );
  };

export const DeleteGroupMemberListSuccess =
  (res: any, type: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_GROUP_MEMBER_LIST.DELETE_GROUP_MEMBER_LIST_SUCCESS,
      payload: res.data,
    });
    dispatch(getTicketGroupList(false, type));
  };

export const DeleteGroupMemberListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: DELETE_GROUP_MEMBER_LIST.DELETE_GROUP_MEMBER_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const GetAdminMemberList =
  (
    // data: any,
    showToast = false,
    type: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(GetAdminMemberListInit(showToast, type));
  };

export const GetAdminMemberListInit =
  (
    showToast: boolean,
    type: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ADMIN_MEMBER_LIST.GET_ADMIN_MEMBER_LIST_INITIALIZATION,
    });
    const stores: Store = store.getState();
    apiCall(
      GET_ADMIN_MEMBER_LIST_URL, // + "?sort=DESC&sortBy=updatedAt",
      // getAPIEndPoint(
      //   // data,
      //   true
      // ),
      {},
      (res: Object) => dispatch(GetAdminMemberListSuccess(res, type)),
      (err: any) => dispatch(GetAdminMemberListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const GetAdminMemberListSuccess =
  (res: any, type: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ADMIN_MEMBER_LIST.GET_ADMIN_MEMBER_LIST_SUCCESS,
      payload: res.data,
    });
    dispatch(getTicketGroupList(false, type));
  };

export const GetAdminMemberListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_ADMIN_MEMBER_LIST.GET_ADMIN_MEMBER_LIST_ERROR,
    });
  };

export const editTicketCommentListInit =
  (
    showToast: boolean,
    data: any,
    id: any,
    ticketId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_TICKET_COMMENT_LIST.EDIT_TICKET_COMMENT_LIST_INITIALIZATION,
    });
    const stores: Store = store.getState();
    apiCall(
      EDIT_TICKET_COMMENT_LIST_URL + id, // + "?sort=DESC&sortBy=updatedAt",
      // getAPIEndPoint(
      //   // data,
      //   true
      // ),
      data,
      (res: Object) => dispatch(editTicketCommentListSuccess(ticketId)),
      (err: any) => dispatch(editTicketCommentListError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const editTicketCommentListSuccess =
  (id: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_TICKET_COMMENT_LIST.EDIT_TICKET_COMMENT_LIST_SUCCESS,
    });
    dispatch(getTicketCommentList(false, id));
    dispatch(setMediaUploadEmpty("edtTicketCommentAttachment"));
  };

export const editTicketCommentListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: EDIT_TICKET_COMMENT_LIST.EDIT_TICKET_COMMENT_LIST_ERROR,
    });
  };

export const deleteTicketCommentListInit =
  (
    showToast: boolean,
    id: any,
    tid: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_TICKET_COMMENT_LIST.DELETE_TICKET_COMMENT_LIST_INITIALIZATION,
    });
    const stores: Store = store.getState();
    apiCall(
      DELETE_TICKET_COMMENT_LIST_URL + id, // + "?sort=DESC&sortBy=updatedAt",
      // getAPIEndPoint(
      //   // data,
      //   true
      // ),
      {},
      (res: Object) => dispatch(deleteTicketCommentListSuccess(tid)),
      (err: any) => dispatch(deleteTicketCommentListError()),
      METHOD.DELETE,
      { showToast },
    );
  };

export const deleteTicketCommentListSuccess =
  (id: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_TICKET_COMMENT_LIST.DELETE_TICKET_COMMENT_LIST_SUCCESS,
    });
    dispatch(getTicketCommentList(false, id));
  };

export const deleteTicketCommentListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: DELETE_TICKET_COMMENT_LIST.DELETE_TICKET_COMMENT_LIST_ERROR,
    });
  };
