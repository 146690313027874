import { memo, useState, type ReactNode, useCallback, useEffect } from "react";
import {
  MRT_Row,
  MaterialReactTable,
  createMRTColumnHelper,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from "material-react-table";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  IconButton,
  Tooltip,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

import CreateGemsAdvertisementModal from "./CreateGemsAdvertisementModal";
import {
  GetAllGemsAdvertisementsResponse,
  useGetAllGemsAdvertisements,
} from "../../features/gemsAdvertisements/api";
import { useSearchParams } from "react-router-dom";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { GemsAdvertisementResponse } from "../../features/gemsAdvertisements/types";
import AlertDialog from "../common/AlertDialog";
import { useDeleteGemsAdvertisementById } from "../../features/gemsAdvertisements/api/useDeleteGemsAdvertisementById";
import { toast } from "react-toastify";
import dayjs from "dayjs";

const columnHelper =
  createMRTColumnHelper<
    GetAllGemsAdvertisementsResponse["gemsAdvertisements"][number]
  >();
const columns = [
  columnHelper.accessor((row) => row.id, {
    id: "id",
    header: "Id",
    minSize: 64,
    maxSize: 128,
  }),
  columnHelper.accessor((row) => row.title, {
    id: "title",
    header: "Title",
  }),
  columnHelper.accessor((row) => new Date(row.startDate), {
    id: "startDate",
    header: "Start Date",
    Cell: (props) =>
      dayjs(props.cell.getValue()).format("YYYY-MM-DD HH:mm:ss (ZZ[Z])"),
    sortingFn: "datetime",
    filterVariant: "date-range",
    minSize: 360,
  }),
  columnHelper.accessor((row) => new Date(row.endDate), {
    id: "endDate",
    header: "End Date (Inclusive)",
    Cell: (props) =>
      dayjs(props.cell.getValue()).format("YYYY-MM-DD HH:mm:ss (ZZ[Z])"),
    sortingFn: "datetime",
    filterVariant: "date-range",
    minSize: 360,
  }),
  columnHelper.accessor((row) => new Date(row.createdAt), {
    id: "createdAt",
    header: "Created At",
    Cell: (props) =>
      dayjs(props.cell.getValue()).format("YYYY-MM-DD HH:mm:ss (ZZ[Z])"),
    sortingFn: "datetime",
    filterVariant: "date-range",
    minSize: 360,
  }),
  columnHelper.accessor((row) => new Date(row.updatedAt), {
    id: "updatedAt",
    header: "Updated At",
    Cell: (props) =>
      dayjs(props.cell.getValue()).format("YYYY-MM-DD HH:mm:ss (ZZ[Z])"),
    sortingFn: "datetime",
    filterVariant: "date-range",
    minSize: 360,
  }),
];

export const GemsAdvertisementsTable = () => {
  const [modalOpen, setModalOpen] = useState<{
    open: boolean;
    dataEditing: MRT_Row<GemsAdvertisementResponse> | undefined;
  }>({
    open: false,
    dataEditing: undefined,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    searchParams.getAll("columnFilters").map((s) => JSON.parse(s)),
  );
  const [globalFilter, setGlobalFilter] = useState("");

  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: "id", desc: true },
    ...searchParams.getAll("sorting").map((s) => JSON.parse(s)),
  ]);

  const [pagination, setPagination] = useState<MRT_PaginationState>(
    JSON.parse(
      searchParams.get("pagination") || '{"pageIndex":0,"pageSize":10}',
    ),
  );

  const { data, isError, isFetching, isLoading, refetch } =
    useGetAllGemsAdvertisements({
      globalFilter,
      columnFilters,
      sorting,
      ...pagination,
    });

  const { mutate } = useDeleteGemsAdvertisementById();

  const handleDeleteRow = (row: MRT_Row<GemsAdvertisementResponse>) => {
    const gemsAdvertisementId = row.getValue<number>("id") || 0;
    mutate(
      { gemsAdvertisementId },
      {
        onSuccess: () => {
          toast.success(
            `Gems advertisement with id: ${gemsAdvertisementId} has been deleted successfully.`,
          );
        },
      },
    );
  };

  const renderTopToolbarCustomActions = useCallback((): ReactNode => {
    return (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => refetch()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
        <Button
          color="secondary"
          onClick={() => setModalOpen({ open: true, dataEditing: undefined })}
          variant="contained"
        >
          Create Advertisement
        </Button>
      </Box>
    );
  }, [refetch]);

  useEffect(() => {
    setSearchParams(
      (params) => {
        params.delete("columnFilters");
        columnFilters.forEach((columnFilter) =>
          params.append("columnFilters", JSON.stringify(columnFilter)),
        );
        return params;
      },
      { replace: true },
    );
  }, [columnFilters, setSearchParams]);

  useEffect(() => {
    setSearchParams(
      (params) => {
        params.set("globalFilter", globalFilter);
        return params;
      },
      { replace: true },
    );
  }, [globalFilter, setSearchParams]);

  useEffect(() => {
    setSearchParams(
      (params) => {
        params.delete("sorting");
        sorting.forEach((sort) =>
          params.append("sorting", JSON.stringify(sort)),
        );
        return params;
      },
      { replace: true },
    );
  }, [sorting, setSearchParams]);

  useEffect(() => {
    setSearchParams(
      (params) => {
        params.set("pagination", JSON.stringify(pagination));
        return params;
      },
      { replace: true },
    );
  }, [pagination, setSearchParams]);

  return (
    <>
      <GemsAdvertisementsListWrapper>
        <MaterialReactTable
          layoutMode="grid"
          columns={columns}
          data={data?.gemsAdvertisements ?? []}
          enableColumnOrdering
          enableColumnResizing
          enableGrouping
          enableColumnPinning
          enableMultiSort
          enableRowActions
          displayColumnDefOptions={{
            "mrt-row-actions": { size: 100, grow: false },
          }}
          renderRowActions={({ row }) => (
            <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
              <IconButton
                color="secondary"
                onClick={() => setModalOpen({ open: true, dataEditing: row })}
              >
                <EditIcon />
              </IconButton>
              <AlertDialog
                onClick={() => handleDeleteRow(row)}
                dialogTitle="Are you sure to delete the following advertisement?"
                dialogContent={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Card sx={{ maxWidth: 345, margin: 2 }}>
                      <CardActionArea
                        href={row.original.promotionUrl}
                        target="_blank"
                        rel="noopener"
                      >
                        <CardMedia
                          component="img"
                          height="256"
                          image={row.original.imageUrl}
                          alt={row.original.title}
                        />
                      </CardActionArea>
                    </Card>
                    <div>
                      {`[id: ${row.original.id}] [title: ${row.original.title}]`}
                    </div>
                  </div>
                }
                closeButtonText="close"
                submitButtonText="submit"
              >
                <IconButton color="error">
                  <DeleteIcon />
                </IconButton>
              </AlertDialog>
            </Box>
          )}
          renderDetailPanel={({ row }) => (
            <p>{JSON.stringify(row.original.targetClasses)}</p>
          )}
          initialState={{
            density: "comfortable",
            showColumnFilters: true,
            columnVisibility: {
              createdAt: false,
              updatedAt: false,
            },
          }}
          manualFiltering
          manualPagination
          manualSorting
          muiToolbarAlertBannerProps={
            isError
              ? { color: "error", children: "Error loading data" }
              : undefined
          }
          isMultiSortEvent={() => true}
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          renderTopToolbarCustomActions={renderTopToolbarCustomActions}
          rowCount={data?.gemsAdvertisementsCount ?? 0}
          state={{
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isFetching,
            sorting,
          }}
        />
      </GemsAdvertisementsListWrapper>
      <CreateGemsAdvertisementModal
        {...modalOpen}
        onClose={() => setModalOpen({ open: false, dataEditing: undefined })}
      />
    </>
  );
};

const GemsAdvertisementsListWrapper = memo(
  function GemsAdvertisementsListWrapper({
    children,
  }: {
    children?: ReactNode;
  }) {
    return (
      <section className="page-content container-fluid">
        <div className="card">
          <div className="card-body p-0">
            <div className="table-responsive">{children}</div>
          </div>
        </div>
      </section>
    );
  },
);

export default GemsAdvertisementsTable;
