import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import { AddClassToolSchema } from "../../util/validationSchema";
import { useDispatch, useSelector } from "react-redux";
import { addClassTool, getTicketToolList } from "../../redux/action";
import { Store } from "../../redux/Actions";
import Loader from "../common/Loader";
import { TOOLS, ToolTypeOption } from "../../util/constants/UserGroup";
import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { FaTimesCircle } from "react-icons/fa";

const ClassModalTool = ({ onModalClose, classId }: any) => {
  const dispatch = useDispatch();
  const tools = useSelector(
    (state: Store) => state.ticketReducer.toolList,
  ) as any;
  const [ButtonClick, setButtonClick] = useState(false);

  // const [selectedToolType, setSelectedToolType] = useState({});
  const [selectedTool, setSelectedTool] = useState(null);

  const isAPILoading = useSelector(
    (state: Store) => state.classReducer.onSaveLoading,
  );
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      tool: -1,
      toolId: 0,
    },
    validationSchema: AddClassToolSchema,
    onSubmit: (values) => {
      if (!ButtonClick) {
        const to = { tool: values.tool, toolId: values.toolId };
        dispatch(addClassTool(true, classId, to));

        setButtonClick(true);
      }
    },
  });

  const { touched, errors } = formik;

  useEffect(() => {
    dispatch(getTicketToolList(false, 0));
    if (!isAPILoading && ButtonClick) {
      onModalClose();
      setButtonClick(false);
    }
  }, [isAPILoading]);

  const onToolTypeChange = (option: any) => {
    dispatch(getTicketToolList(false, parseInt(option.id)));
    formik.setFieldValue("tool", option.id);
    // setSelectedToolType(option);
    if (option.id !== formik.values.tool) {
      setSelectedTool(null);
    }
  };

  return (
    <Modal centered show={true} onHide={() => onModalClose(false)}>
      <Modal.Header>
        <h4 className="modal-title" id="AddCourseLabel">
          <FormattedMessage
            id="head.AddClassTool"
            defaultMessage="Add Class Tool"
          />
        </h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onModalClose}
        >
          <FaTimesCircle />
        </button>
      </Modal.Header>
      <Modal.Body style={{ minHeight: "170px" }}>
        {/* <div className="form-group">
          <label className="control-label">
            <FormattedMessage
              id="Admin.GrantTool.Tools"
              defaultMessage="Tool Type"
            />
          </label>
          <Select
            name="tool"
            onChange={(option: any) => onToolTypeChange(option)}
            onBlur={() => formik.setFieldTouched("tool", true)}
            options={ToolTypeOption}
            getOptionLabel={(option: any) => option.group}
            getOptionValue={(option: any) => option.id}
            className="custom-select-dropdown"
            classNamePrefix="react-select-dropdown"
          />
          {errors.tool && touched.tool && (
            <div className="text-error">
              <FormattedMessage
                id={errors.tool.toString()}
                defaultMessage="require"
              />
            </div>
          )}
        </div> */}

        <div className="form-group">
          <label className="control-label">
            {" "}
            <FormattedMessage id="head.tool" defaultMessage="Tool Id" />
          </label>
          <Select
            name="toolId"
            value={selectedTool}
            onChange={(option: any) => {
              formik.setFieldValue("toolId", option.id);
              setSelectedTool(option);
              formik.setFieldValue("tool", option.type);
            }}
            onBlur={() => formik.setFieldTouched("toolId", true)}
            options={tools.filter(
              (i: any) => ![TOOLS.LIBRARIES, TOOLS.GEMSFX].includes(i.type),
            )}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id}
            className="custom-select-dropdown"
            classNamePrefix="react-select-dropdown"
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />

          {errors.toolId && touched.toolId && (
            <div className="text-error">
              <FormattedMessage
                id={errors.toolId.toString()}
                defaultMessage="require"
              />
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          onClick={onModalClose}
        >
          <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
        </button>
        <button
          type="button"
          className="btn btn-primary"
          style={{ display: "inherit" }}
          onClick={() => formik.handleSubmit()}
          data-dismiss="modal"
        >
          <FormattedMessage id="page.Save" defaultMessage="Save" />
          {isAPILoading && <Loader />}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ClassModalTool;
