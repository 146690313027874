/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/APIServices/baseApiCall";
import {
  GET_ADMIN_ROLES_LIST,
  GET_ADMIN_ROLES_TOOLS_LIST,
  GET_ADMIN_ROLES_USER_PERMISSIONS_LIST,
  GET_ADMIN_ROLES_USER_LOGGINGS_LIST,
  GET_ADMIN_ROLES_USER_EA_ACCOUNT_LIST,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { getAPIEndPoint, setToken } from "../../util/utils";
import {
  GET_ADMIN_ROLE_LIST_URL,
  GET_CLASS_CATEGORY_URL,
  USER_SIGNIN,
  EDIT_CLASS_URL,
  GET_PRODUCTS_URL,
  GET_ADMIN_ROLE_TOOL_LIST_URL,
  GET_ADMIN_ROLE_USER_PERMISSION_LIST_URL,
  GET_ADMIN_ROLE_EA_ACCOUNT_LIST_URL,
  GET_ADMIN_ROLE_LOGGING_ACCOUNT_LIST_URL,
} from "../../service/APIServices/apiEndPoints";

export const getAdminRolesList =
  (
    showToast = false,
    pageNumber = 0,
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getAdminRolesListInit(showToast, pageNumber, pageLimit));
  };

export const getAdminRolesListInit =
  (
    showToast = false,
    pageNumber: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ADMIN_ROLES_LIST.GET_ADMIN_ROLES_LIST_INITIALIZATION,
    });
    let url = GET_ADMIN_ROLE_LIST_URL;
    if (pageNumber > 0) {
      url = `${GET_ADMIN_ROLE_LIST_URL}?page=${pageNumber}&perPage=${pageLimit}&sort=DESC&sortBy=createdAt`;
    }

    apiCall(
      url,
      {},
      (res: Object) => dispatch(getAdminRolesListSuccess(res)),
      (err: any) => dispatch(getAdminRolesListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getAdminRolesListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ADMIN_ROLES_LIST.GET_ADMIN_ROLES_LIST_SUCCESS,
      payload: res,
    });
  };

export const getAdminRolesListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_ADMIN_ROLES_LIST.GET_ADMIN_ROLES_LIST_ERROR,
    });
  };

export const getAdminRolesToolList =
  (
    showToast = true,
    selectedToolType = 0,
    role: number,
    pageNumber: any,
    sort = { by: "createdAt", order: "DESC" },
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      getAdminRolesToolListInit(
        showToast,
        selectedToolType,
        role,
        pageNumber,
        sort,
        pageLimit,
      ),
    );
  };

export const getAdminRolesToolListInit =
  (
    showToast = true,
    selectedToolType: any,
    role: number,
    pageNumber: any,
    sort: any,
    pageLimit: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ADMIN_ROLES_TOOLS_LIST.GET_ADMIN_ROLES_TOOLS_LIST_INITIALIZATION,
    });

    let url = `${GET_ADMIN_ROLE_TOOL_LIST_URL}/${selectedToolType}?sort=${sort.order}&sortBy=${sort.by}&page=${pageNumber}&perPage=${pageLimit}`;
    if (role > 0) {
      url = `${GET_ADMIN_ROLE_TOOL_LIST_URL}/${selectedToolType}?role=${role}&sort=${sort.order}&sortBy=${sort.by}&page=${pageNumber}&perPage=${pageLimit}`;
    }
    apiCall(
      `${GET_ADMIN_ROLE_TOOL_LIST_URL}/${selectedToolType}?role=${role}&sort=${sort.order}&sortBy=${sort.by}&page=${pageNumber}&perPage=${pageLimit}`,
      {},
      (res: Object) => dispatch(getAdminRolesToolListSuccess(res)),
      (err: any) => dispatch(getAdminRolesToolListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getAdminRolesToolListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ADMIN_ROLES_TOOLS_LIST.GET_ADMIN_ROLES_TOOLS_LIST_SUCCESS,
      payload: res,
    });
  };

export const getAdminRolesToolListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_ADMIN_ROLES_TOOLS_LIST.GET_ADMIN_ROLES_TOOLS_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const getAdminRolesUserPermissionsList =
  (
    showToast = true,
    id: any,
    pageNumber = 0,
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      getAdminRolesUserPermissionsListInit(
        showToast,
        id,
        pageNumber,
        pageLimit,
      ),
    );
  };

export const getAdminRolesUserPermissionsListInit =
  (
    showToast = true,
    id: any,
    pageNumber: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ADMIN_ROLES_USER_PERMISSIONS_LIST.GET_ADMIN_ROLES_USER_PERMISSIONS_LIST_INITIALIZATION,
    });
    apiCall(
      getAPIEndPoint(
        `${GET_ADMIN_ROLE_USER_PERMISSION_LIST_URL}${id}?sort=DESC&sortBy=createdAt`,
        pageNumber,
        true,
        pageLimit,
      ),
      {},
      (res: Object) => dispatch(getAdminRolesUserPermissionsListSuccess(res)),
      (err: any) => dispatch(getAdminRolesUserPermissionsListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getAdminRolesUserPermissionsListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ADMIN_ROLES_USER_PERMISSIONS_LIST.GET_ADMIN_ROLES_USER_PERMISSIONS_LIST_SUCCESS,
      payload: res,
    });
  };

export const getAdminRolesUserPermissionsListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_ADMIN_ROLES_USER_PERMISSIONS_LIST.GET_ADMIN_ROLES_USER_PERMISSIONS_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const getAdminRolesUserLoggingsList =
  (
    showToast = true,
    id: any,
    pageNumber: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      getAdminRolesUserLoggingsListInit(showToast, id, pageNumber, pageLimit),
    );
  };

export const getAdminRolesUserLoggingsListInit =
  (
    showToast = true,
    id: any,
    pageNumber: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ADMIN_ROLES_USER_LOGGINGS_LIST.GET_ADMIN_ROLES_USER_LOGGINGS_LIST_INITIALIZATION,
    });
    let url = getAPIEndPoint(
      `${GET_ADMIN_ROLE_LOGGING_ACCOUNT_LIST_URL}?q=${id}&sort=DESC&sortBy=createdAt`,
      pageNumber,
      true,
      pageLimit,
    );

    if (id === "" || id.trim() == null) {
      url = getAPIEndPoint(
        `${GET_ADMIN_ROLE_LOGGING_ACCOUNT_LIST_URL}?sort=DESC&sortBy=createdAt`,
        pageNumber,
        true,
        pageLimit,
      );
    }
    apiCall(
      url,
      {},
      (res: Object) => dispatch(getAdminRolesUserLoggingsListSuccess(res)),
      (err: any) => dispatch(getAdminRolesUserLoggingsListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getAdminRolesUserLoggingsListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ADMIN_ROLES_USER_LOGGINGS_LIST.GET_ADMIN_ROLES_USER_LOGGINGS_LIST_SUCCESS,
      payload: res,
    });
  };

export const getAdminRolesUserLoggingsListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_ADMIN_ROLES_USER_LOGGINGS_LIST.GET_ADMIN_ROLES_USER_LOGGINGS_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const getAdminRolesUserEAAccountList =
  (
    showToast = true,
    id: any,
    pageNumber = 1,
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      getAdminRolesUserEAAccountListInit(showToast, id, pageNumber, pageLimit),
    );
  };

export const getAdminRolesUserEAAccountListInit =
  (
    showToast = true,
    id: any,
    pageNumber: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ADMIN_ROLES_USER_EA_ACCOUNT_LIST.GET_ADMIN_ROLES_USER_EA_ACCOUNT_LIST_INITIALIZATION,
    });
    apiCall(
      getAPIEndPoint(
        `${GET_ADMIN_ROLE_EA_ACCOUNT_LIST_URL}${id}?sort=DESC&sortBy=createdAt`,
        pageNumber,
        true,
        pageLimit,
      ),
      {},
      (res: Object) => dispatch(getAdminRolesUserEAAccountListSuccess(res)),
      (err: any) => dispatch(getAdminRolesUserEAAccountListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getAdminRolesUserEAAccountListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ADMIN_ROLES_USER_EA_ACCOUNT_LIST.GET_ADMIN_ROLES_USER_EA_ACCOUNT_LIST_SUCCESS,
      payload: res,
    });
  };

export const getAdminRolesUserEAAccountListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_ADMIN_ROLES_USER_EA_ACCOUNT_LIST.GET_ADMIN_ROLES_USER_EA_ACCOUNT_LIST_ERROR,
    });
  };
