import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
// import { Card, Button } from "react-bootstrap";
// import { BsChevronRight, BsSortDown, BsSortUp } from "react-icons/bs";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router";
// import { toast } from "react-toastify";
// import { Store } from "../../redux/Actions";
// import { DownloadsIcon } from "../../util/iconStyle";
// import { FormattedDate, FormattedMessage } from "react-intl";
import { formatDateMM, saveFile, getFileName, sort } from "../../util/utils";
import * as _ from "lodash";
import { ProgressBar } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

export const ProgressDownload = forwardRef((props: any, ref: any) => {
  const [Prog, setProg] = useState<any>("0");
  const toastId = React.useRef(null);

  useImperativeHandle(ref, () => ({
    downloadFiles(url: any, elId: any) {
      downloadFile(url, elId);
    },
  }));

  const downloadFile = async (url: any, id: any) => {
    const fileName = getFileName(url);
    const str = fileName;
    console.log("event", id);
    await axios({
      url: url,
      method: "GET",
      responseType: "blob", // important
      onDownloadProgress: (progressEvent) => {
        const buttonElement = document.getElementById(
          `${id}`,
        ) as HTMLInputElement;
        const element = document.getElementById(`${id}1`) as HTMLInputElement;
        if (
          Math.round(
            (progressEvent.loaded * 100) / (progressEvent.total || 1),
          ) < 100
        ) {
          if ((document.getElementById(`${id}1`) as HTMLInputElement) != null) {
            (document.getElementById(`${id}1`) as HTMLInputElement).value =
              Math.round(
                (progressEvent.loaded * 100) / (progressEvent.total || 1),
              ).toString();
            element.classList.remove("progress-hide");
          }
          if (buttonElement != null) {
            buttonElement.disabled = true;
          }
          //   $(`#${id}`).off('click')
        } else {
          if (element != null) {
            element.classList.add("progress-hide");
          }
          if (buttonElement != null) {
            buttonElement.disabled = false;
          }
          let arr = props.Load;
          console.log("arr", arr);
          arr = arr.filter(function (item: any) {
            return item !== id;
          });
          console.log("arr", arr);
          props.setLoad(arr);
        }

        // setPorgress(Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1))); // you can use this to show user percentage of file downloaded
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", str);
        document.body.appendChild(link);
        link.click();
        //   setLoad(false);
      })
      .catch(() => {
        //   setLoad(false);
      });
  };

  return (
    <>
      {props?.children}
      {/* <div className="custom-progress progress-hide" id={props?.elId}> */}
      {/* <ProgressBar animated now={Prog} className="mb-2" /> */}
      {/* </div> */}
      <progress
        className="custom-progress progress-hide"
        style={{ width: "100%" }}
        value="0"
        max="100"
        id={props?.elId}
      ></progress>
    </>
  );
});
