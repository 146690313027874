import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
// import { BsChevronLeft, BsTrash } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";

import "../Classes/classes.css";
import {
  addAnnouncementComment,
  deleteAnnouncement,
  deleteAnnouncementComment,
  editAnnouncements,
  getAnnouncementComment,
  getAnnouncementDetails,
  getClasses,
  getQR,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import EditorContainer from "../../Components/RichTextEditor/Editor";
// import { Description } from "@mui/icons-material";
import draftToHtml from "draftjs-to-html";
import { convertToRaw, EditorState, convertFromHTML } from "draft-js";
import { ContentState } from "draft-js";
import {
  customFormatDatetimeWithLocale,
  getFileDetail,
  getTextFromHtmlRowText,
} from "../../util/utils";
import DeletePopup from "../../Components/common/Popups/DeletePopup";
import Viewer from "react-viewer";
import MoreHorizIcon from "@mui/icons-material/MoreHorizOutlined";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { AttachmentIcon } from "../../util/iconStyle";
import { FormattedMessage } from "react-intl";
import { _UploadMedia } from "../../service/MediaUpload/classes.service";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import EditAnnouncementClassModal from "../../Components/Announcement/EditAnnouncementClassModal";
import Pagination from "../../Components/common/Paginate/Pagination";
import { BsTrashFill } from "react-icons/bs";
import DOMPurify from "dompurify";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

const StyledMenu = styled((props: any) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }: any) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 120,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));
const IMAGE_TYPES = ["png", "jpeg", "jpg"];

const AnnouncementComments = ({
  announcementsDetails,
  announcementComments,
  isLoading,
}: any) => {
  const urlParams = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [decription, setDescription] = useState("Inital Test");
  const [deleteCall, setDeleteCall] = useState(false);
  const [comment, setComment] = useState("");
  const [commentDelete, setCommentDelete] = useState({ modal: false, id: 0 });
  const [img, setImg] = useState({ image: "", show: false });
  const attchRef = useRef<any>();
  const [announcementAttachment, setAnnouncemenAttachment] = useState<any>([]);
  const [commentError, setCommentError] = useState(false);
  const [userGroup, setUserGroup] = useState<any>();

  // const announcementsDetails: any = useSelector(
  //   (state: Store) => state.announcementsReducer.announcementsDetails
  // ) as any;
  // const announcementComments: any = useSelector(
  //   (state: Store) => state.announcementsReducer.announcementComments
  // ) as any;
  const userDetail: any = useSelector(
    (state: Store) => state.userDataReducer.myInfo,
  ) as any;
  const totalRecord: any = useSelector(
    (state: Store) => state.announcementsReducer.totalCommentRecords,
  ) as any;

  // const isLoading: any = useSelector(
  //   (state: Store) => state.announcementsReducer.isLoading
  // ) as any;
  const onSaveLoading: any = useSelector(
    (state: Store) => state.announcementsReducer.onSaveLoading,
  ) as any;

  const [fileLoading, setFileLoading] = useState(false);
  const [editedClass, setEditedClass] = useState(false);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [classEdit, setClassEdit] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );

  const [editorConfig, setEditorConfig] = useState({
    toolbar: false,
    editable: true,
    height: "70px",
    overflowY: "unset",
    border: "1px solid #efefef",
  });

  const locale = useSelector((state: Store) => state.userDataReducer.local);

  useEffect(() => {
    const { id } = urlParams;
    if (id && parseInt(id) > 0 && classEdit) {
      dispatch(getAnnouncementDetails(false, parseInt(id)));
    }
  }, [classEdit]);

  useEffect(() => {
    const { id } = urlParams;
    if (id && parseInt(id) > 0) {
      // dispatch(getAnnouncementDetails(false, parseInt(id)));
      dispatch(getAnnouncementComment(false, id, pageNumber, pageLimit));
      dispatch(getClasses(false, 0, { by: "createdAt", order: "DESC" }));
      dispatch(getQR());
    }
  }, []);

  useEffect(() => {
    const { id } = urlParams;
    if (id && parseInt(id) > 0) {
      // dispatch(getAnnouncementDetails(false, parseInt(id)));
      dispatch(getAnnouncementComment(false, id, pageNumber, pageLimit));
      // dispatch(getClasses(false, 0, { by: "createdAt", order: "DESC" }));
      // dispatch(getQR());
    }
  }, [pageNumber, pageLimit]);

  useEffect(() => {
    if (!isLoading && deleteCall) {
      navigate(-1);
      setDeleteCall(false);
    }
  }, [isLoading]);

  useEffect(() => {
    const estate = EditorState.createEmpty();
    setEditorState(estate);
  }, [announcementsDetails]);

  // const isEditComment = () => {
  //   const enableEditing = {
  //     toolbar: true,
  //     editable: true,
  //     height: "200px",
  //     overflowY: "scroll",
  //     border: "1px solid #efefef",
  //   };
  //   setEditorConfig(() => enableEditing);
  // };

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    const eState = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const text = getTextFromHtmlRowText(eState); //  eState.toString().replaceAll("&nbsp;", "");

    if (text.length > 0) {
      setCommentError(false);
    }
  };

  const onPageChange = (pageNum: number) => {
    setPageNumber(pageNum);
    // dispatch(setInCurrentPage(pageNum));
  };

  // const onCancelEditClick = () => {
  //   const enableEditing = {
  //     toolbar: false,
  //     editable: false,
  //     height: "200px",
  //     overflowY: "unset",
  //     border: "1px solid transparent",
  //   };
  //   setEditorConfig(() => ({ ...enableEditing }));

  //   const blocksFromHTML = convertFromHTML(decription);
  //   const editorsState = EditorState.createWithContent(
  //     ContentState.createFromBlockArray(
  //       blocksFromHTML.contentBlocks,
  //       blocksFromHTML.entityMap
  //     )
  //   );

  //   onEditorStateChange(editorsState);
  // };

  const onAddCommnent = () => {
    const { id } = urlParams;
    const eState = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    let text = eState.toString().replaceAll("&nbsp;", "");
    text = text.replaceAll("<p>", "");
    text = text.replaceAll("</p>", "");
    text = text.replaceAll("<br>", "");
    text = text.replaceAll("\n", "");
    // text = text.replaceAll("\n", "");

    text = text.trim();
    if (text.length > 0 && eState.trim() != "<p></p>") {
      const groupId = userDetail?.ticketGroup?.[0]?.id || 17;

      dispatch(
        addAnnouncementComment(true, {
          comment: eState,
          announcement: id,
          groupId: groupId,
        }),
      );
      setComment("");
      setCommentError(false);
      const estate = EditorState.createEmpty();
      setEditorState(estate);
    } else {
      setCommentError(true);
    }
  };

  const onEditClick = () => {
    const eState = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    const enableEditing = {
      toolbar: false,
      editable: false,
      height: "200px",
      overflowY: "unset",
      border: "1px solid transparent",
    };
    setEditorConfig(() => ({ ...enableEditing }));
    const { id } = urlParams;

    let text = eState.toString().replaceAll("&nbsp;", "");
    text = text.replaceAll("<p>", "");
    text = text.replaceAll("</p>", "");
    text = text.replaceAll("<br>", "");
    text = text.replaceAll("\n", "");
    text = text.trim();
    if (text.length > 0 && eState.trim() != "<p></p>") {
      announcementsDetails.content = eState;
      const temp = {
        title: announcementsDetails.title,
        content: announcementsDetails.content,
        class_group: editedClass,
        attachmentPath: announcementsDetails.attechment.map(
          (i: any) => i.attachmentPath,
        ), //??
      };

      dispatch(editAnnouncements(true, id, temp));
    } else {
      const blocksFromHTML = convertFromHTML(decription);
      const editorsState = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        ),
      );
      onEditorStateChange(editorsState);
    }
  };

  const deleteComment = (commentId: any) => {
    const { id } = urlParams;

    dispatch(deleteAnnouncementComment(true, commentId, id));
    setCommentDelete({ modal: false, id: 0 });
  };

  const deleteCommentModal = (id: any) => {
    setCommentDelete({ modal: true, id: id });
    console.log("setCommentDelete");
  };

  const attchmentClick = () => {
    if (attchRef.current) {
      attchRef?.current.click();
    }
  };

  const handleDrop = async (acceptedFiles: any) => {
    setFileLoading(true);
    const { fileUploadResponse, error }: any = await _UploadMedia(
      acceptedFiles[0] as File,
    );
    if (!error) {
      const files = getFileDetail([fileUploadResponse]);
      const announcementAttachments = announcementAttachment;
      announcementAttachments.push(...files);

      setAnnouncemenAttachment(() => [...announcementAttachments]);

      const Detail = {
        title: announcementsDetails.title,
        content: announcementsDetails.content,
        class_group: announcementsDetails.class_group,
        attachmentPath: [
          ...announcementsDetails.attechment.map((i: any) => i.attachmentPath),
          fileUploadResponse,
        ], //??
      };
      const { id } = urlParams;

      dispatch(editAnnouncements(true, id, Detail));
    }
    setFileLoading(false);
  };

  const onImageDelete = (index: any) => {
    // const ticketAttachments = announcementAttachment;
    const a = announcementAttachment.filter(
      (i: any, ind: any) => ind !== index,
    );
    setAnnouncemenAttachment(() => [...a]);
    const { id } = urlParams;
    const announcementDetail = {
      title: announcementsDetails.title,
      content: announcementsDetails.content,
      class_group: announcementsDetails.class_group,
      attachmentPath: a.map((i: any) => i.path), //??
    };

    dispatch(editAnnouncements(true, id, announcementDetail));
  };

  const getCommentBy = (comment: any) => {
    let by = "";
    // if ((comment?.createdBy?.id || 0) !== (userDetail?.id || 0)) {
    if (comment?.commentBy?.userType !== "admin") {
      if (comment.createdBy) {
        by = comment?.createdBy?.englishFirstName || "-";
        by = by + " " + comment?.createdBy?.englishLastName || " -";
      } else {
        by = "unknown";
      }
    } else {
      by = comment?.group?.group_name || "unkown";
    }
    return by;
  };

  // const sanitizedData = (data: any) => ( {
  //   __html: DOMPurify.sanitize(data)
  // })
  return (
    <>
      {fileLoading && <CenterLoader />}
      {isLoading && <CenterLoader />}

      <h4 className="font-weight-500 my-4">
        <FormattedMessage
          id="announcementDetail.comment"
          defaultMessage="Comment"
        />{" "}
        <small className="font-size-12 ml-2">
          (*{" "}
          {
            <FormattedMessage
              id="announcementDetail.comment.subhead"
              defaultMessage="You will leave a message as "
            />
          }
          {userDetail?.ticketGroup?.[0]?.group_name || "Administrative Team"})
        </small>
      </h4>
      <div className="comments-media">
        <div className="media">
          <Avatar sx={{ bgcolor: "gray" }} aria-label="recipe" className="mb-3">
            {userDetail?.ticketGroup?.[0]?.group_name?.charAt(0)?.toUpperCase()}
          </Avatar>
          <div className="media-body ml-3 editor-state-change">
            <EditorContainer
              onEditorStateChange={onEditorStateChange}
              editorState={editorState}
              toolbar={editorConfig.toolbar}
              editable={editorConfig.editable}
              height={editorConfig.height}
              overflowY={editorConfig.overflowY}
              border={editorConfig.border}
            />{" "}
            {/* </textarea> */}
            {commentError && (
              <div className="text-error">
                <FormattedMessage
                  id="comment.error"
                  defaultMessage="Please add a comment"
                />
              </div>
            )}
            <button
              className="btn btn-primary btn-sm mt-2"
              onClick={() => onAddCommnent()}
            >
              <FormattedMessage id="page.submit" defaultMessage="Submit" />
            </button>
          </div>
        </div>
        {announcementComments &&
          announcementComments.map((comment: any) => (
            <>
              <div className="media">
                <Avatar>
                  {/* {comment?.createdBy?.avatar ? (
                    <img src={comment.createdBy.avatar} />
                  ) : (
                    <>{comment.comment.charAt(0)} </>
                  )} */}
                  {getCommentBy(comment)?.charAt(0)?.toUpperCase()}
                </Avatar>
                <div className="media-body ml-3">
                  <div className="d-flex align-items-center justify-content-between mb-1">
                    <p className="font-size-14 font-weight-600 m-0">
                      {getCommentBy(comment)}

                      {/* {comment.createdBy.userType == "admin" ? (
                        "GemsFX Development Team"
                      ) : (
                        <>
                          {comment.createdBy ? (
                            <>
                              {comment?.createdBy?.englishFirstName +
                                " " +
                                comment.createdBy.englishLastName}
                            </>
                          ) : (
                            "unknown"
                          )}{" "}
                        </>
                      )} */}
                      <small className="text-muted ml-1">
                        {customFormatDatetimeWithLocale(
                          comment.createdAt,
                          locale,
                          true,
                        )}{" "}
                      </small>
                    </p>
                    <button
                      className="no-back-red "
                      onClick={() => {
                        deleteCommentModal(comment?.id);
                      }}
                      style={{
                        fontSize: "12px",
                        color: "lightskyblue",
                        cursor: "pointer",
                        padding: "0",
                      }}
                    >
                      <i className="">
                        <BsTrashFill />{" "}
                      </i>
                    </button>
                  </div>
                  <p className="mb-1">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(comment.comment),
                      }}
                    ></div>
                  </p>
                </div>
              </div>
            </>
          ))}
      </div>
      {announcementComments.length > 0 && totalRecord > 10 && (
        <>
          <FormControl className="pagelimit">
            <FormLabel id="demo-row-radio-buttons-group-label">
              <FormattedMessage id="page_size" defaultMessage="Page Size" />
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={pageLimit}
              onChange={(e: any) => {
                setPageLimit(e.target.value);
                setPageNumber(1);
              }}
            >
              <FormControlLabel value={10} control={<Radio />} label="10" />
              <FormControlLabel value={20} control={<Radio />} label="20" />
              <FormControlLabel value={50} control={<Radio />} label="50" />
            </RadioGroup>
          </FormControl>
          <div className="pag">
            <Pagination
              itemsPerPage={pageLimit}
              totalPages={totalRecord}
              onPageChage={onPageChange}
              page={pageNumber - 1}
            />
          </div>
        </>
      )}
      {/* </Card.Body> */}
      {/* </Card> */}
      {/* </section> */}
      {/* </div> */}
      {commentDelete && (
        <DeletePopup
          onClose={() => setCommentDelete({ modal: false, id: 0 })}
          onDeleteClick={() => deleteComment(commentDelete.id)}
          open={commentDelete.modal}
          // text={
          //   <FormattedMessage
          //     id="msg.general"
          //     defaultMessage="Are you sure to delete?"
          //   />
          // }
        />
      )}

      {/* {
        classEdit && !isLoading && (
          <EditAnnouncementClassModal
            onModalClose={() => setClassEdit(false)}
            classList={classes}
            selectedClass={announcementsDetails.class_group}
            isAPILoading={onSaveLoading}
            isLoading={isLoading}
            setEditedClass={(classes: any) => {
              setEditedClass(classes);
              onEditClick();
            }}
            announcementsDetails={announcementsDetails}
          />
        )
      } */}
    </>
  );
};
export default AnnouncementComments;
