/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/APIServices/baseApiCall";
import {
  GET_UTILITY_DETAILS,
  GET_UTILITY_LIST,
  ADD_UTILITY_LIST,
  EDIT_UTILITY_LIST,
  GET_UTILITY_VERSION_LIST,
  ADD_UTILITY_VERSION,
  EDIT_UTILITY_VERSION,
  DELETE_UTILITY_VERSION,
  DELETE_UTILITY,
  SET_PAGE,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { getAPIEndPoint, getPage, setToken } from "../../util/utils";
import {
  GET_UTILITY_LIST_URL,
  EIDT_UTILITY_LIST_URL,
  ADD_UTILITY_LIST_URL,
  GET_UTILITY_VERSION_LIST_URL,
  ADD_UTILITY_VERSION_URL,
  EDIT_UTILITY_VERSION_URL,
  DELETE_UTILITY_VERSION_URL,
  DELETE_UTILITY_URL,
} from "../../service/APIServices/apiEndPoints";
import { toast } from "react-toastify";
import {
  _UploadMedia,
  _UploadTool,
} from "../../service/MediaUpload/classes.service";
// import { _getExpressWayPendingList } from "../../service";
// import { AxiosResponse } from "axios";

export const setUtilityCurrentPage =
  (pageNumber: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: SET_PAGE,
      payload: pageNumber,
    });
  };

//Get Admin Roles List
export const getUtilityList =
  (
    showToast = true,
    pageNumber = 0,
    sort = { order: "DESC", by: "createdAt" },
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getUtilityListInit(showToast, pageNumber, sort, pageLimit));
  };

export const getUtilityListInit =
  (
    showToast = true,
    pageNumber: number,
    sort: any,
    pageLimit: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_UTILITY_LIST.GET_UTILITY_LIST_INITIALIZATION,
    });
    apiCall(
      getAPIEndPoint(
        GET_UTILITY_LIST_URL + `?sort=${sort.order}&sortBy=${sort.by}`,
        pageNumber,
        true,
        pageLimit,
      ),
      {},
      (res: Object) => dispatch(getUtilityListSuccess(res)),
      (err: any) => dispatch(getUtilityListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getUtilityListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_UTILITY_LIST.GET_UTILITY_LIST_SUCCESS,
      payload: res,
    });
  };

export const getUtilityListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_UTILITY_LIST.GET_UTILITY_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const addUtilityList =
  (
    showToast = true,
    indicatorData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch({
      type: ADD_UTILITY_LIST.ADD_UTILITY_LIST_INITIALIZATION,
    });
    let url = "";

    const files = indicatorData.image_path;
    if (files) {
      const { fileUploadResponse, error }: any = await _UploadMedia(
        files as File,
      );
      url = fileUploadResponse;
      if (error) {
        dispatch(addUtilityListError());
        return;
      }
    }
    indicatorData.image_path = url;
    // dispatch(addExpertAdvisorListInit(showToast, indicatorData));
    dispatch(addUtilityListInit(showToast, indicatorData));
  };

export const addUtilityListInit =
  (
    showToast = true,
    indicatorData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    apiCall(
      ADD_UTILITY_LIST_URL,
      indicatorData,
      (res: Object) => dispatch(addUtilityListSuccess(res)),
      (err: any) => dispatch(addUtilityListError()),
      METHOD.POST,
      { showToast },
    );
  };

export const addUtilityListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_UTILITY_LIST.ADD_UTILITY_LIST_SUCCESS,
    });
    dispatch(setUtilityCurrentPage(1));
    dispatch(getUtilityList(false, 1));
    // localStorage.clear();
    // toast.success("addUtilityList successfully")
    // window.location.reload();
  };

export const addUtilityListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_UTILITY_LIST.ADD_UTILITY_LIST_ERROR,
    });
  };

//Get Admin Roles
export const deleteUtility =
  (
    showToast = true,
    indicator: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(deleteUtilityInit(showToast, indicator));
  };

export const deleteUtilityInit =
  (
    showToast = true,
    indicator: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_UTILITY.DELETE_UTILITY_INITIALIZATION,
    });
    apiCall(
      `${DELETE_UTILITY_URL}${indicator}`,
      {},
      (res: Object) => dispatch(deleteUtilitySuccess(res)),
      (err: any) => dispatch(deleteUtilityError()),
      METHOD.DELETE,
      { showToast },
    );
  };

export const deleteUtilitySuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_UTILITY.DELETE_UTILITY_SUCCESS,
      payload: res.data,
    });
    dispatch(getUtilityList(false));

    // localStorage.clear();
    // toast.success("deleteUtility successfully")
    // window.location.reload();
  };

export const deleteUtilityError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: DELETE_UTILITY.DELETE_UTILITY_ERROR,
    });
  };

//Get Admin Roles List
export const editUtilityList =
  (
    showToast = true,
    id: any,
    indicatorData: any,
    isFile: boolean,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch({
      type: EDIT_UTILITY_LIST.EDIT_UTILITY_LIST_INITIALIZATION,
    });

    if (isFile) {
      const files = indicatorData.image_path;
      const { fileUploadResponse, error }: any = await _UploadMedia(
        files as File,
      );
      if (error) {
        dispatch(editUtilityListError());
        return;
      }
      indicatorData.image_path = fileUploadResponse;
    }
    dispatch(editUtilityListInit(showToast, id, indicatorData));
  };

export const editUtilityListInit =
  (
    showToast = true,
    id: any,
    indicatorData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    apiCall(
      EIDT_UTILITY_LIST_URL + id,
      indicatorData,
      (res: Object) => dispatch(editUtilityListSuccess(res)),
      (err: any) => dispatch(editUtilityListError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const editUtilityListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_UTILITY_LIST.EDIT_UTILITY_LIST_SUCCESS,
      payload: res.data,
    });

    dispatch(setUtilityCurrentPage(1));
    dispatch(getUtilityList(false, 1));
    // localStorage.clear();
    // toast.success("editUtilityList successfully")
    // window.location.reload();
  };

export const editUtilityListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: EDIT_UTILITY_LIST.EDIT_UTILITY_LIST_ERROR,
    });
  };

export const getUtilityDetails =
  (
    showToast = false,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getUtilityDetailsInit(showToast, id));
  };

export const getUtilityDetailsInit =
  (
    showToast: boolean,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_UTILITY_DETAILS.GET_UTILITY_DETAILS_INITIALIZATION,
    });
    apiCall(
      GET_UTILITY_LIST_URL + "/" + id,
      {},
      (res: Object) => dispatch(getUtilityDetailsSuccess(res)),
      (err: any) => dispatch(getUtilityDetailsError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getUtilityDetailsSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_UTILITY_DETAILS.GET_UTILITY_DETAILS_SUCCESS,
      payload: res.data,
    });
    // localStorage.clear();
    // toast.success("getUtilityDetails successfully")
    // window.location.reload();
  };

export const getUtilityDetailsError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_UTILITY_DETAILS.GET_UTILITY_DETAILS_ERROR,
    });
  };

//Get Admin Roles List
export const getUtilityVersionList =
  (
    showToast = true,
    indicator: string,
    pageNumber = 0,
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      getUtilityVersionListInit(showToast, indicator, pageNumber, pageLimit),
    );
  };

export const getUtilityVersionListInit =
  (
    showToast = true,
    indicator: string,
    pageNumber: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_UTILITY_VERSION_LIST.GET_UTILITY_VERSION_LIST_INITIALIZATION,
    });
    apiCall(
      getAPIEndPoint(
        `${GET_UTILITY_VERSION_LIST_URL}${indicator}`,
        pageNumber,
        false,
        pageLimit,
      ),
      {},
      (res: Object) => dispatch(getUtilityVersionListSuccess(res)),
      (err: any) => dispatch(getUtilityVersionListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getUtilityVersionListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_UTILITY_VERSION_LIST.GET_UTILITY_VERSION_LIST_SUCCESS,
      payload: res,
    });
  };

export const getUtilityVersionListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_UTILITY_VERSION_LIST.GET_UTILITY_VERSION_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const addUtilityVersion =
  (
    showToast = true,
    indicator: any,
    uid: any,
    isFile: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch({
      type: ADD_UTILITY_VERSION.ADD_UTILITY_VERSION_INITIALIZATION,
    });
    if (isFile.toolUpload) {
      const toolFile = indicator.download_path;
      const { fileUploadResponse, error }: any = await _UploadTool(
        toolFile as File,
      );
      if (!error) {
        indicator.download_path = fileUploadResponse;
      } else {
        dispatch(addUtilityVersionError());
        return;
      }
    }

    if (isFile.manualUpload) {
      const toolFile = indicator.manual_path;
      const { fileUploadResponse, error }: any = await _UploadMedia(
        toolFile as File,
      );
      if (!error) {
        indicator.manual_path = fileUploadResponse;
      } else {
        dispatch(addUtilityVersionError());
        return;
      }
    }
    dispatch(addUtilityVersionInit(showToast, indicator, uid));
  };

export const addUtilityVersionInit =
  (
    showToast = true,
    indicator: any,
    uid: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    apiCall(
      `${ADD_UTILITY_VERSION_URL}`,
      indicator,
      (res: Object) => dispatch(addUtilityVersionSuccess(res, uid)),
      (err: any) => dispatch(addUtilityVersionError()),
      METHOD.POST,
      { showToast },
    );
  };

export const addUtilityVersionSuccess =
  (res: any, uid: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_UTILITY_VERSION.ADD_UTILITY_VERSION_SUCCESS,
      payload: res.data,
    });
    dispatch(setUtilityCurrentPage(1));
    dispatch(getUtilityVersionList(false, uid, 1));
  };

export const addUtilityVersionError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_UTILITY_VERSION.ADD_UTILITY_VERSION_ERROR,
    });
  };

//Get Admin Roles
export const editUtilityVersion =
  (
    showToast = true,
    id: any,
    utility: any,
    uid: any,
    isFile: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch({
      type: EDIT_UTILITY_VERSION.EDIT_UTILITY_VERSION_INITIALIZATION,
    });

    if (isFile.toolUpload) {
      const toolFile = utility.download_path;
      const { fileUploadResponse, error }: any = await _UploadTool(
        toolFile as File,
      );
      if (!error) {
        utility.download_path = fileUploadResponse;
      } else {
        dispatch(editUtilityVersionError());
        return;
      }
    }

    if (isFile.manualUpload) {
      const toolFile = utility.manual_path;
      const { fileUploadResponse, error }: any = await _UploadMedia(
        toolFile as File,
      );
      if (!error) {
        utility.manual_path = fileUploadResponse;
      } else {
        dispatch(editUtilityVersionError());
        return;
      }
    }

    dispatch(editUtilityVersionInit(showToast, id, utility, uid));
  };

export const editUtilityVersionInit =
  (
    showToast = true,
    id: any,
    utility: any,
    uid: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    apiCall(
      `${EDIT_UTILITY_VERSION_URL}${id}`,
      utility,
      (res: Object) => dispatch(editUtilityVersionSuccess(res, uid)),
      (err: any) => dispatch(editUtilityVersionError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const editUtilityVersionSuccess =
  (res: any, uid: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_UTILITY_VERSION.EDIT_UTILITY_VERSION_SUCCESS,
      payload: res.data,
    });

    dispatch(setUtilityCurrentPage(1));
    dispatch(getUtilityVersionList(false, uid, 1));
  };

export const editUtilityVersionError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: EDIT_UTILITY_VERSION.EDIT_UTILITY_VERSION_ERROR,
    });
  };

//Get Admin Roles
export const deleteUtilityVersion =
  (
    showToast = true,
    indicator: any,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(deleteUtilityVersionInit(showToast, indicator, id));
  };

export const deleteUtilityVersionInit =
  (
    showToast = true,
    indicator: any,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_UTILITY_VERSION.DELETE_UTILITY_VERSION_INITIALIZATION,
    });
    apiCall(
      `${DELETE_UTILITY_VERSION_URL}${indicator}`,
      {},
      (res: Object) => dispatch(deleteUtilityVersionSuccess(res, id)),
      (err: any) => dispatch(deleteUtilityVersionError()),
      METHOD.DELETE,
      { showToast },
    );
  };

export const deleteUtilityVersionSuccess =
  (res: any, id: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_UTILITY_VERSION.DELETE_UTILITY_VERSION_SUCCESS,
      payload: res.data,
    });
    dispatch(getUtilityVersionList(false, id));
  };

export const deleteUtilityVersionError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: DELETE_UTILITY_VERSION.DELETE_UTILITY_VERSION_ERROR,
    });
  };
