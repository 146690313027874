/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/APIServices/baseApiCall";
import {
  GET_ALL_BILLS_LIST,
  EDIT_BILL_LIST,
  UPDATE_BILL_LIST,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import {
  GET_ALL_BILLS_URL,
  EDIT_BILL_URL,
  UPDATE_BILL_URL,
} from "../../service/APIServices/apiEndPoints";

//Get Admin Roles List
export const getBillsList =
  (
    showToast = false,
    pageNumber = 1,
    filter = "",
    pageLimit = 10,
    sortOrder = "DESC",
    sortBy = "CreatedAt",
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      getBillsListInit(
        showToast,
        pageNumber,
        filter,
        pageLimit,
        sortOrder,
        sortBy,
      ),
    );
  };

export const getBillsListInit =
  (
    showToast: boolean,
    pageNumber: number,
    filter: any,
    pageLimit: any,
    sortOrder: any,
    sortBy: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ALL_BILLS_LIST.GET_ALL_BILLS_LIST_INITIALIZATION,
    });
    let url;
    if (filter) {
      url = `${GET_ALL_BILLS_URL}?page=${pageNumber}&perPage=${pageLimit}&sort=${sortOrder}&sortBy=${sortBy}${filter}`;
    } else {
      url = `${GET_ALL_BILLS_URL}?page=${pageNumber}&perPage=${pageLimit}&sort=${sortOrder}&sortBy=${sortBy}`;
    }
    apiCall(
      url,
      {},
      (res: Object) => dispatch(getBillsListSuccess(res)),
      (err: any) => dispatch(getBillsListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getBillsListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ALL_BILLS_LIST.GET_ALL_BILLS_LIST_SUCCESS,
      payload: res,
    });
  };

export const getBillsListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_ALL_BILLS_LIST.GET_ALL_BILLS_LIST_ERROR,
    });
  };

//Edit Admin Bill
export const editBillList =
  (
    showToast = true,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(editBillListInit(showToast, id));
  };

export const editBillListInit =
  (
    showToast = true,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_BILL_LIST.EDIT_BILL_LIST_INITIALIZATION,
    });
    apiCall(
      EDIT_BILL_URL + id,
      {},
      (res: Object) => dispatch(editBillListSuccess(res)),
      (err: any) => dispatch(editBillListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const editBillListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_BILL_LIST.EDIT_BILL_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const editBillListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: EDIT_BILL_LIST.EDIT_BILL_LIST_ERROR,
    });
  };

//Edit Admin Bill
export const updateBillList =
  (
    billData: any,
    id: any,
    showToast = true,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(updateBillListInit(billData, id, showToast));
  };

export const updateBillListInit =
  (
    billData: any,
    id: any,
    showToast = true,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: UPDATE_BILL_LIST.UPDATE_BILL_LIST_INITIALIZATION,
    });
    apiCall(
      UPDATE_BILL_URL + id,
      billData,
      (res: Object) => dispatch(updateBillSuccess(res)),
      (err: any) => dispatch(updateBillError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const updateBillSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: UPDATE_BILL_LIST.UPDATE_BILL_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const updateBillError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: UPDATE_BILL_LIST.UPDATE_BILL_LIST_ERROR,
    });
  };
