import { useMutation } from "@tanstack/react-query";
import { PlanCategory } from "../interfaces/planCategory";
import { AxiosError } from "axios";
import { CreatePlanCategoryDto } from "../schemas";
import { customFetch } from "../../../util/axios";

interface CreatedPlanCategory extends PlanCategory {
  updatedBy: { id: number };
  createdBy: { id: number };
}

interface CreatePlanCategoryRo {
  createdPlanCategory: CreatedPlanCategory;
}

const createPlanCategory = async (body: CreatePlanCategoryDto) => {
  const response = await customFetch.post("/plan-categories", body);
  return response.data;
};

export const useCreatePlanCategory = () => {
  const mutation = useMutation<
    CreatePlanCategoryRo,
    AxiosError | Error,
    CreatePlanCategoryDto
  >({
    mutationFn: createPlanCategory,
  });

  return mutation;
};

export default useCreatePlanCategory;
