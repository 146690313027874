import {
  PlanCategoriesHeader,
  PlanCategoriesTable,
} from "../../Components/PlanCategories";

const PlanCategories = () => {
  return (
    <>
      <div className="content">
        <PlanCategoriesHeader />
        <PlanCategoriesTable />
      </div>
    </>
  );
};

export default PlanCategories;
