/* eslint-disable no-case-declarations */
import {
  GET_GIFT_LIST,
  GET_GIFT_DETAILS,
  ADD_GIFT_LIST,
  EDIT_GIFT_LIST,
  ADD_GIFT_CATEGORY_LIST,
  EDIT_GIFT_CATEGORY_LIST,
  GET_GIFT_CATEGORY_LIST,
} from "../constants/action-types";
import { GiftData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";
// import { Posts } from "../Actions";

const initialState = {
  progress: false,
  isLoading: false,
  onSaveLoading: false,
  giftList: {},
  giftCatList: {},
  giftDetails: {},
  totalRecord: 0,
  totalRecordCat: 0,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: GiftData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case GET_GIFT_LIST.GET_GIFT_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_GIFT_LIST.GET_GIFT_LIST_SUCCESS:
        const allPost: any = action.payload; // [{ id: 2, body: "Abcd", title: "title", userId: 3 }, { id: 2, body: "Abcd", title: "title", userId: 3 }];
        return {
          ...state,
          giftList: allPost.data,
          totalRecord: allPost.totalCount,
          isLoading: false,
        };

      case GET_GIFT_LIST.GET_GIFT_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_GIFT_LIST.ADD_GIFT_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case ADD_GIFT_LIST.ADD_GIFT_LIST_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_GIFT_LIST.ADD_GIFT_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case EDIT_GIFT_LIST.EDIT_GIFT_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case EDIT_GIFT_LIST.EDIT_GIFT_LIST_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case EDIT_GIFT_LIST.EDIT_GIFT_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_GIFT_DETAILS.GET_GIFT_DETAILS_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_GIFT_DETAILS.GET_GIFT_DETAILS_SUCCESS:
        return {
          ...state,
          gfitDetails: action.payload,
          isLoading: false,
        };

      case GET_GIFT_DETAILS.GET_GIFT_DETAILS_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_GIFT_CATEGORY_LIST.GET_GIFT_CATEGORY_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_GIFT_CATEGORY_LIST.GET_GIFT_CATEGORY_LIST_SUCCESS:
        const catLst: any = action.payload;
        return {
          ...state,
          giftCatList: catLst.data,
          totalRecordCat: catLst.totalCount,
          isLoading: false,
        };

      case GET_GIFT_CATEGORY_LIST.GET_GIFT_CATEGORY_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_GIFT_CATEGORY_LIST.ADD_GIFT_CATEGORY_LIST_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case ADD_GIFT_CATEGORY_LIST.ADD_GIFT_CATEGORY_LIST_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case ADD_GIFT_CATEGORY_LIST.ADD_GIFT_CATEGORY_LIST_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case EDIT_GIFT_CATEGORY_LIST.EDIT_GIFT_CATEGORY_LIST_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case EDIT_GIFT_CATEGORY_LIST.EDIT_GIFT_CATEGORY_LIST_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case EDIT_GIFT_CATEGORY_LIST.EDIT_GIFT_CATEGORY_LIST_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      default:
        return state;
    }
  },
);

export default reducer;
