import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { dispatch } from "../../redux";
import { getClasses, getStudentByClassList } from "../../redux/action";
import { Store } from "../../redux/Actions";
import { Col, Row } from "react-bootstrap";
import { Chip } from "@mui/material";
import CenterLoader from "../common/CenterLoader/CenterLoader";
import { FormattedMessage } from "react-intl";
import { formatDate } from "../../util/utils";

const UserSelectionByClass = ({ setFinalUsers, users, refs = null }: any) => {
  const classes = useSelector(
    (state: Store) => state.classReducer.classes || [],
  ) as any;

  const classStudentsList: any = useSelector(
    (state: Store) => state.studentReducer.classStudentsList,
  ) as any;

  const isLoading = useSelector(
    (state: Store) => state.studentReducer.Loading.isLoading,
  ) as any;

  const [selectedClass, setSelectedClass] = useState<any>({});
  const [selectedStudent, setSelectedStudent] = useState<any>([]);
  const [selectedClassStudent, setSelectedClassStudent] = useState<any>([]);
  const [selectedFinalStudent, setSelectedFinalStudent] = useState<Array<any>>(
    [],
  );

  useEffect(() => {
    dispatch(getClasses(false, 0, { by: "createdAt", order: "ASC" }));
  }, []);

  useEffect(() => {
    const student: any = [];
    if (classStudentsList?.length > 0) {
      const id: any = "All" + selectedClass?.id;
      student.push({
        id: id,
        englishFirstName: "All",
        englishLastName: "",
        class: selectedClass,
        preferredNickName: "",
      });
      student.push(...classStudentsList);
    }
    setSelectedStudent(student);
  }, [classStudentsList]);

  useEffect(() => {
    if (refs) {
      refs.current = onClearAll;
    }
  }, []);

  const onClassChange = (option: any) => {
    dispatch(getStudentByClassList(false, option.id));
  };

  const onClearAll = () => {
    setSelectedClass([]);
    setSelectedClassStudent(() => []);
    setSelectedFinalStudent(() => []);
    setSelectedStudent(() => []);
  };

  useEffect(() => {
    const allStudent: Array<string> = selectedFinalStudent.map(
      (item: any) => item.id,
    );
    const newSelected: any = [];
    selectedClassStudent.map((i: any) => {
      if (!allStudent.includes(i.id)) {
        newSelected.push(i);
      }
    });

    const unique: Array<any> = [...selectedFinalStudent, ...newSelected];
    setSelectedFinalStudent(unique);
    const u: any = [];
    unique.map((i: any) => {
      if (i.englishFirstName != "All") {
        u.push(i);
      }
    });
    setFinalUsers(u.map((item: any) => item.id));
  }, [selectedClassStudent]);

  const onDeleteUser = (id: any) => {
    const a = selectedFinalStudent.filter((i: any) => i.id !== id);
    const b = selectedClassStudent.filter((i: any) => i.id !== id);

    setSelectedFinalStudent(a);
    setSelectedClassStudent(b);
  };

  const onCheckboxChange = (i: any, e: any, classes: any) => {
    if (i.target.checked) {
      if (e.id.toString().slice(0, 3) == "All") {
        setSelectedClassStudent([...classStudentsList, e]);
      } else {
        const stud: any = [...selectedClassStudent, e];
        const classId = selectedClass?.id;
        const id: any = "All" + selectedClass?.id;
        const classS = stud.filter((i: any) => i?.class?.id == classId);
        if (classS.length == classStudentsList.length) {
          stud.push({
            id: id,
            englishFirstName: "All",
            englishLastName: "",
            class: selectedClass,
            preferredNickName: "",
          });
        }
        setSelectedClassStudent(stud);
      }
    } else {
      if (e.id.toString().slice(0, 3) == "All") {
        const a: any = [];
        selectedFinalStudent.map((i: any) => {
          if (i.class.id != classes.id) {
            a.push(i);
          }
        });
        setSelectedClassStudent(a);
        setSelectedFinalStudent(a);
      } else {
        const a: any = selectedFinalStudent.filter((i: any) => i.id !== e.id);
        const b: any = [];
        const id = "All" + classes.id;
        a.map((i: any) => {
          if (i.id !== id) {
            b.push(i);
          }
        });
        setSelectedClassStudent(b);
        setSelectedFinalStudent(b);
      }
    }
  };

  return (
    <>
      {isLoading && <CenterLoader />}
      <Row>
        <Col md={6}>
          <div style={{ display: "flex", gap: "1rem" }}>
            <div className="form-group" style={{ flex: 1 }}>
              <label className="control-label">
                <FormattedMessage
                  id="head.SelectClass"
                  defaultMessage="Select Class"
                />
              </label>
              <div
                style={{
                  border: "1px solid #dfe7f3",
                  borderRadius: "5px",
                  minHeight: "400px",
                  maxHeight: "400px",
                  overflow: "hidden",
                  overflowY: "scroll",
                }}
              >
                {classes.length > 0 &&
                  classes.map((i: any) => (
                    <div
                      style={{ padding: "7px", paddingLeft: "11px" }}
                      className={
                        i.id === selectedClass.id ? "userSelection-bg" : ""
                      }
                      onClick={() => {
                        onClassChange(i);
                        setSelectedClass(i);
                      }}
                    >
                      {i.name}
                    </div>
                  ))}
              </div>
            </div>
            <div className="form-group" style={{ flex: 2 }}>
              <label className="control-label">
                <FormattedMessage
                  id="head.SelectStudents"
                  defaultMessage="Select Students"
                />
              </label>
              <div
                style={{
                  border: "1px solid #dfe7f3",
                  borderRadius: "5px",
                  minHeight: "400px",
                  maxHeight: "400px",
                  overflow: "hidden",
                  overflowY: "scroll",
                }}
              >
                {selectedStudent.length > 0 &&
                  selectedStudent.map((i: any) => (
                    <div style={{ padding: "7px", paddingLeft: "11px" }}>
                      <label className="control control-outline control-primary control--checkbox font-size-14 d-inline-block mb-0 mr-3 pointer">
                        <input
                          type="checkbox"
                          checked={selectedFinalStudent
                            .map((i: any) => i.id)
                            .includes(i.id)}
                          onClick={(e: any) => {
                            onCheckboxChange(e, i, i.class);
                          }}
                        />
                        {i.id.toString().slice(0, 3) == "All" ? (
                          <>{`${i.englishFirstName} ${i.englishLastName}`}</>
                        ) : (
                          <>
                            <b>
                              {i?.expiryDate &&
                                `Expiry Day: ${formatDate(
                                  i?.expiryDate,
                                  "DD-MM-YYYY",
                                )} `}
                            </b>
                            ({i.id})
                            {` ${i.englishFirstName} ${i.englishLastName} (${i.preferredNickName})`}
                          </>
                        )}
                        <div className="control__indicator"></div>
                      </label>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <div className="d-flex justify-content-between">
              <label className="control-label">
                <FormattedMessage
                  id="head.FinalList"
                  defaultMessage="Final List"
                />
              </label>
              <label className="control-label">
                Total <strong>{(users || []).length}</strong> user(s) selected
              </label>
            </div>

            <div
              style={{
                border: "1px solid #dfe7f3",
                borderRadius: "5px",
                padding: "7px",
                height: "400px",
                overflow: "hidden",
                overflowY: "scroll",
              }}
            >
              {selectedFinalStudent.map((i: any) => (
                <>
                  {(i?.id || 0).toString().slice(0, 3) !== "All" && (
                    <Chip
                      label={`${i.englishFirstName} ${i.englishLastName}`}
                      variant="outlined"
                      size="small"
                      className="mr-1 mb-2"
                      onDelete={() => onDeleteUser(i.id)}
                    />
                  )}
                </>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default UserSelectionByClass;
