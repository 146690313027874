import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import { AddClassToolVersionSchema } from "../../util/validationSchema";
import { useDispatch, useSelector } from "react-redux";
import {
  // addClasses,
  addClassToolVersion,
  getTicketToolList,
  getTicketToolVersionList,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import Loader from "../common/Loader";
import { TOOLS, ToolTypeOption } from "../../util/constants/UserGroup";
import { Modal } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { FaTimesCircle } from "react-icons/fa";

const ClassModalVersion = ({ onModalClose, classId }: any) => {
  const dispatch = useDispatch();
  const [ButtonClick, setButtonClick] = useState(false);
  const [toolVersionList, setToolVersionList] = useState([]);
  const [selectedTool, setSelectedTool] = useState(null);
  const [selectedToolVersion, setSelectedToolVersion] = useState(null);
  const isAPILoading = useSelector(
    (state: Store) => state.classReducer.onSaveLoading,
  );

  const tools = useSelector(
    (state: Store) => state.ticketReducer.toolList,
  ) as any;
  // const toolVersionList = useSelector(
  //   (state: Store) => state.ticketReducer.toolVersionList
  // ) as any;
  // const [fileUploadURL, setFileUploadURL] = useState("");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      tool: -1,
      toolId: 0,
      versionId: 0,
    },
    validationSchema: AddClassToolVersionSchema,
    onSubmit: (values) => {
      if (!ButtonClick) {
        setButtonClick(true);
        const to = { tool: values.tool, versionId: values.versionId };
        dispatch(addClassToolVersion(true, classId, to));
      }
    },
  });

  const { touched, errors } = formik;

  useEffect(() => {
    dispatch(getTicketToolList(false, 0, classId));

    if (!isAPILoading && ButtonClick) {
      onModalClose();
      setButtonClick(false);
    }
  }, [isAPILoading]);

  const onToolTypeChange = (option: any) => {
    formik.setFieldValue("tool", option.id);
    dispatch(getTicketToolList(false, parseInt(option.id)));
  };

  const onToolChange = (option: any) => {
    formik.setFieldValue("toolId", option.id);
    formik.setFieldValue("tool", option.type);
    setToolVersionList(option.versionList);
    setSelectedTool(option);
    setSelectedToolVersion(null);
    dispatch(
      getTicketToolVersionList(false, parseInt(option.id), formik.values.tool),
    );
  };

  return (
    <Modal centered show={true} onHide={() => onModalClose(false)}>
      <Modal.Header>
        <h4 className="modal-title" id="AddCourseLabel">
          <FormattedMessage
            id="head.AddClassToolVersion"
            defaultMessage="Add Class Tool Version"
          />
        </h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onModalClose}
        >
          <FaTimesCircle />
        </button>
      </Modal.Header>
      <Modal.Body>
        {/* <div className="form-group">
          <label className="control-label">
            <FormattedMessage
              id="Admin.GrantTool.Tools"
              defaultMessage="Tool Type"
            />
          </label>
          <Select
            onChange={(option: any) => onToolTypeChange(option)}
            onBlur={() => formik.setFieldTouched("tool", true)}
            options={ToolTypeOption}
            getOptionLabel={(option: any) => option.group}
            getOptionValue={(option: any) => option.id}
            className="custom-select-dropdown"
            classNamePrefix="react-select-dropdown"
          />
          {errors.tool && touched.tool && (
            <div className="text-error">
              <FormattedMessage
                id={errors.tool.toString()}
                defaultMessage="require"
              />
            </div>
          )}
        </div> */}
        <div className="form-group">
          <label className="control-label">
            <FormattedMessage id="table.head.ToolID" defaultMessage="Tool Id" />
          </label>
          <Select
            name="ToolId"
            onChange={(option: any) => onToolChange(option)}
            onBlur={() => formik.setFieldTouched("tool", true)}
            // options={tools}
            options={tools.filter(
              (i: any) => ![TOOLS.LIBRARIES, TOOLS.GEMSFX].includes(i.type),
            )}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id}
            value={selectedTool}
            className="custom-select-dropdown"
            classNamePrefix="react-select-dropdown"
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
          {errors.toolId && touched.toolId && (
            <div className="text-error">
              <FormattedMessage
                id={errors.toolId.toString()}
                defaultMessage="require"
              />
            </div>
          )}
        </div>

        <div className="form-group">
          <FormattedMessage id="table.head.Version" defaultMessage="Version" />
          <Select
            onBlur={() => formik.setFieldTouched("versionId", true)}
            onChange={(option: any) => {
              formik.setFieldValue("versionId", option.id);
              setSelectedToolVersion(option);
            }}
            value={selectedToolVersion}
            options={toolVersionList}
            getOptionLabel={(option: any) => option.version}
            getOptionValue={(option: any) => option.id}
            className="custom-select-dropdown"
            classNamePrefix="react-select-dropdown"
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
          {errors.versionId && touched.versionId && (
            <div className="text-error">
              <FormattedMessage
                id={errors.versionId.toString()}
                defaultMessage="require"
              />
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          onClick={onModalClose}
        >
          <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
        </button>
        <button
          type="button"
          className="btn btn-primary"
          style={{ display: "inherit" }}
          onClick={() => formik.handleSubmit()}
          data-dismiss="modal"
        >
          <FormattedMessage id="page.Save" defaultMessage="Save" />
          {isAPILoading && <Loader />}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ClassModalVersion;
