import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

import { Store } from "../../redux/Actions";

import { ADMINUSERS_LIST_TABS } from "../../Constants";
import ActiveUsers from "./Active";
import { dispatch } from "../../redux";
import {
  getAdminList,
  getAllConsumer,
  getClasses,
  getExpressWayPendingList,
  pendingEmailVerificationList,
} from "../../redux/action";
import DeniedUsers from "./Denied";
import PendinUsers from "./Pending";
import EmailVerificationUsers from "./EmailVerification";
import NoDatFound from "../../Components/common/NoDatFound";
import Pagination from "../../Components/common/Paginate/Pagination";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "react-select";
import RecordsList from "../ExpressWay/RecordsList";
import AdminList from "./AdminList";
import AddAdminModal from "../../Components/User/AddAdminModel";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import ConsumerList from "./ConsumerList";

const AdminUsers = () => {
  const [activeTab, setActiveTab] = useState<any>();
  const [status, setStatus] = useState("approved");
  const isLoading = useSelector(
    (state: Store) => state.expressWayReducer.Loading.isLoading,
  );
  const [pageNumber, setPageNumber] = useState<any>();
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const totalRecord = useSelector(
    (state: Store) => state.expressWayReducer.totalRecord,
  ) as any;

  const userList: any = useSelector(
    (state: Store) => state.expressWayReducer.PendingList,
  ) as any;

  const adminList: any = useSelector(
    (state: Store) => state.expressWayReducer.AdminList,
  ) as any;

  const consumerList = useSelector(
    (state: Store) => state.expressWayReducer.consumerList,
  );

  const isAdminUpdated: any = useSelector(
    (state: Store) => state.expressWayReducer.isAdminUpdated,
  ) as any;

  const emailVerificationPendingLst: any = useSelector(
    (state: Store) => state.expressWayReducer.emailVerificationPendingLst,
  ) as any;
  const isAdminUserAdded = useSelector(
    (state: Store) => state.userDataReducer.isAdminUserAdded,
  );
  const [selectedClass, setselectedClass] = useState<any>(null);
  const classess = useSelector(
    (state: Store) => state.classReducer.classes,
  ) as any;
  const [filterQ, setFilterQ] = useState("");
  const [text, settext] = useState<any>();
  const [Alltext, setAllText] = useState<any>();
  const [adminModal, setAdminModal] = useState({
    modal: false,
    admin: {},
  });

  useEffect(() => {
    if (activeTab < 3) {
      // dispatch(getExpressWayPendingList(false, status, pageNumber, true));
    }
  }, [status]);
  useEffect(() => {
    if (isAdminUpdated) {
      dispatch(getAdminList(false, status, pageNumber, pageLimit));
    }
  }, [isAdminUpdated]);
  useEffect(() => {
    if (isAdminUserAdded) {
      dispatch(getAdminList(false, status, 1, pageLimit));
    }
  }, [isAdminUserAdded]);

  useEffect(() => {
    const tid = setTimeout(() => {
      setAllText(text);
    }, 1000);
    return () => {
      clearTimeout(tid);
    };
  }, [text]);

  useEffect(() => {
    setFilter(text);
  }, [Alltext]);

  useEffect(() => {
    dispatch(
      getClasses(false, 0, { by: "createdAt", order: "ASC" }, pageLimit),
    );
  }, [pageLimit]);

  useEffect(() => {
    if (pageNumber && pageLimit) {
      if ([0, 1, 3].includes(parseInt(activeTab))) {
        dispatch(
          getExpressWayPendingList(
            false,
            status,
            parseInt(pageNumber),
            true,
            filterQ,
            parseInt(pageLimit),
          ),
        );
      }
      if (activeTab === 2) {
        dispatch(
          pendingEmailVerificationList(
            false,
            parseInt(pageNumber),
            parseInt(pageLimit),
          ),
        );
      }
      if (activeTab === 5) {
        dispatch(
          getAdminList(
            false,
            status,
            parseInt(pageNumber),
            parseInt(pageLimit),
          ),
        );
      }
      if (activeTab === 6) {
        dispatch(
          getAllConsumer(false, parseInt(pageNumber), parseInt(pageLimit)),
        );
      }
    }
  }, [filterQ, status, pageLimit, pageNumber, activeTab]);

  useEffect(() => {
    const storedPageNumber: any = localStorage.getItem("pageNumber");
    const storedPageLimit: any = localStorage.getItem("pageLimit");
    const storePageTabs: any = localStorage.getItem("userTab");
    const storePageStatus: any = localStorage.getItem("userStatus");
    if (storedPageNumber && storedPageLimit) {
      setPageNumber(parseInt(storedPageNumber));
      setPageLimit(parseInt(storedPageLimit));
      setActiveTab(parseInt(storePageTabs));
      setStatus(storePageStatus);
    } else {
      setPageNumber(1);
      setPageLimit(10);
      setActiveTab(0);
      setStatus("approved");
    }
  }, []);

  const setFilter = (text: string) => {
    let q = "";
    const cl: any = parseInt(selectedClass?.id || "0");
    if (text?.length > 0) q = q + `&q=${encodeURIComponent(text)}`;
    if (cl > 0) {
      q = q + `&classId=${cl}`;
    }
    setFilterQ(q);
    if (text || selectedClass) {
      setPageNumber(1);
      setPageLimit(10);
      localStorage.setItem("pageNumber", "1");
      localStorage.setItem("pageLimit", "10");
      sessionStorage.setItem("filterE", q);
    }

    return 0;
  };

  useEffect(() => {
    setFilter("");
    settext("");
  }, [selectedClass]);

  const onPageChange = (pageNum: number) => {
    sessionStorage.setItem("pageE", pageNum.toString());
    localStorage.setItem("pageNumber", pageNum.toString());
    localStorage.setItem("pageLimit", pageLimit);
    localStorage.setItem("userTab", activeTab);
    setPageNumber(pageNum);
    // dispatch(getExpressWayPendingList(false, status, pageNum, true, filterQ, pageLimit));
  };

  const onPageChangeEv = (pageNum: number) => {
    sessionStorage.setItem("pageE", pageNum.toString());
    localStorage.setItem("pageNumber", pageNum.toString());
    localStorage.setItem("pageLimit", pageLimit);
    localStorage.setItem("userTab", activeTab);
    setPageNumber(pageNum);
  };

  const onPageChangeAdmin = (pageNum: number) => {
    sessionStorage.setItem("pageE", pageNum.toString());
    localStorage.setItem("pageNumber", pageNum.toString());
    localStorage.setItem("pageLimit", pageLimit);
    localStorage.setItem("userTab", activeTab);
    setPageNumber(pageNum);
  };

  const setAciveTab = (event: any, newValue: any) => {
    localStorage.setItem("userTab", newValue);
    localStorage.setItem("pageNumber", "1");
    localStorage.setItem("pageLimit", "10");
    setActiveTab(newValue);
    setPageLimit(10);
    setselectedClass(null);
    if (newValue === 0) {
      setStatus("approved");
      localStorage.setItem("userStatus", "approved");
    } else if (newValue === 1) {
      setStatus("rejected");
      localStorage.setItem("userStatus", "rejected");
    } else if (newValue === 3) {
      setStatus("pending");
      localStorage.setItem("userStatus", "pending");
    } else if (newValue === 2) {
      setStatus("email");
      localStorage.setItem("userStatus", "email");
    } else if (newValue === 4) {
      setStatus("record");
      localStorage.setItem("userStatus", "record");
      // dispatch(pendingEmailVerificationList(false, 1));
    } else if (newValue === 5) {
      setStatus("admin");
      localStorage.setItem("userStatus", "admin");
    } else if (newValue === 6) {
      setStatus("consumer");
      localStorage.setItem("userStatus", "consumer");
    }
    settext("");
    setPageNumber(1);
  };

  const classes = useStyles();

  return (
    <div className="content-wrapper">
      <div className="content">
        {isLoading && <CenterLoader />}
        <header className="page-header">
          <div className="d-flex flex-wrap align-items-center">
            <div className="mr-auto">
              <h1>
                {" "}
                <FormattedMessage
                  id="Admin.Users"
                  defaultMessage="Admin Users"
                />
              </h1>
            </div>
            {status == "admin" && (
              <div className="m-l-10 common-right-button">
                <a
                  href="#"
                  data-toggle="modal"
                  onClick={() => setAdminModal({ modal: true, admin: {} })}
                  data-target="#AddCourse"
                  type="button"
                  className="btn btn-primary"
                >
                  <FormattedMessage
                    id="Admin.AddAdmin"
                    defaultMessage="Add Admin "
                  />
                </a>
              </div>
            )}
          </div>
        </header>
        <section className="page-content container-fluid">
          <Card>
            <Card.Body className="p-0">
              <Box
                sx={{ borderBottom: 1, borderColor: "divider", padding: "0px" }}
                style={{ padding: "0px" }}
                className={classes.tabs}
              >
                <Tabs
                  value={activeTab}
                  onChange={setAciveTab}
                  className={classes.tabs}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label={
                      <FormattedMessage
                        id="tab.Active"
                        defaultMessage="Active"
                      />
                    }
                    {...a11yProps(ADMINUSERS_LIST_TABS.ACTIVE_LIST)}
                  />
                  <Tab
                    label={
                      <FormattedMessage
                        id="tab.Denied"
                        defaultMessage="Denied"
                      />
                    }
                    {...a11yProps(ADMINUSERS_LIST_TABS.DENIED_LIST)}
                  />
                  <Tab
                    label={
                      <FormattedMessage
                        id="tab.EmailVerification"
                        defaultMessage="Email Verification"
                      />
                    }
                    {...a11yProps(ADMINUSERS_LIST_TABS.EMAIL_VERFICATION_LIST)}
                  />
                  <Tab
                    label={
                      <FormattedMessage
                        id="tab.Pending"
                        defaultMessage="Pending"
                      />
                    }
                    {...a11yProps(ADMINUSERS_LIST_TABS.PENDING_LIST)}
                  />
                  <Tab
                    label={
                      <FormattedMessage
                        id="tab.RecordList"
                        defaultMessage="Pending List"
                      />
                    }
                    {...a11yProps(ADMINUSERS_LIST_TABS.RECORD_LIST)}
                  />
                  <Tab
                    label={
                      <FormattedMessage
                        id="tab.AdminList"
                        defaultMessage="Admin List"
                      />
                    }
                    {...a11yProps(ADMINUSERS_LIST_TABS.ADMIN_LIST)}
                  />
                  <Tab
                    label={
                      <FormattedMessage
                        id="tab.consumerList"
                        defaultMessage="Consumer List"
                      />
                    }
                    {...a11yProps(ADMINUSERS_LIST_TABS.CONSUMER_LIST)}
                  />
                </Tabs>
              </Box>
              <TabPanel
                value={activeTab}
                index={ADMINUSERS_LIST_TABS.ACTIVE_LIST}
              >
                {isLoading && <CenterLoader />}

                <Row className="p-20">
                  <Col
                    style={{ display: "inline-flex" }}
                    className="col-sm-6 col-12"
                  >
                    <label style={{ position: "absolute", bottom: "0" }}>
                      <FormattedMessage id="Filter" defaultMessage="Filter" />:
                    </label>
                    <input
                      onChange={(e) => settext(e.target.value)}
                      value={text}
                      type="text"
                      style={{
                        maxWidth: "220px",
                        paddingLeft: "39px",
                        borderRadius: "0px",
                        border: "none",
                        borderBottom: "1px solid",
                      }}
                      className="form-control"
                    />
                  </Col>
                  <Col className="col-sm-6 col-12 mt-4 mt-sm-0 active-user">
                    <label className="control-label mb-0 d-flex nowrap">
                      <FormattedMessage
                        id="Admin.GrantTool.Classes"
                        defaultMessage="Class Type"
                      />
                    </label>
                    <Select
                      name="classId"
                      value={selectedClass}
                      onChange={(option: any) => {
                        setselectedClass(option);
                      }}
                      options={classess}
                      isClearable
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.id}
                      className="custom-select-dropdown w-200"
                      classNamePrefix="react-select-dropdown"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      // placeholder={useIntl().formatMessage({
                      //   id: "head.SelectClass",
                      //   defaultMessage: "Select class",
                      // })}
                    />
                  </Col>
                </Row>
                {userList.length > 0 ? (
                  <>
                    <ActiveUsers
                      users={userList}
                      activeTab={activeTab}
                      status={status}
                    />
                    {totalRecord > 10 && (
                      <>
                        <FormControl className="pagelimit">
                          <FormLabel id="demo-row-radio-buttons-group-label">
                            <FormattedMessage
                              id="page_size"
                              defaultMessage="Page Size"
                            />
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={pageLimit}
                            onChange={(e: any) => {
                              setPageLimit(e.target.value);
                              localStorage.setItem("pageLimit", e.target.value);
                              localStorage.setItem(
                                "pageNumber",
                                pageNumber.toString(),
                              );
                              setPageNumber(1);
                            }}
                          >
                            <FormControlLabel
                              value={10}
                              control={<Radio />}
                              label="10"
                            />
                            <FormControlLabel
                              value={20}
                              control={<Radio />}
                              label="20"
                            />
                            <FormControlLabel
                              value={50}
                              control={<Radio />}
                              label="50"
                            />
                          </RadioGroup>
                        </FormControl>
                        <Pagination
                          itemsPerPage={pageLimit}
                          totalPages={totalRecord}
                          onPageChage={onPageChange}
                          page={pageNumber - 1}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <p>{!isLoading && <NoDatFound />}</p>
                )}
              </TabPanel>
              <TabPanel
                value={activeTab}
                index={ADMINUSERS_LIST_TABS.DENIED_LIST}
              >
                {isLoading && <CenterLoader />}
                {userList.length > 0 ? (
                  <>
                    {" "}
                    <DeniedUsers users={userList} />
                    {totalRecord > 10 && (
                      <>
                        <FormControl className="pagelimit">
                          <FormLabel id="demo-row-radio-buttons-group-label">
                            <FormattedMessage
                              id="page_size"
                              defaultMessage="Page Size"
                            />
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={pageLimit}
                            onChange={(e: any) => {
                              setPageLimit(e.target.value);
                              localStorage.setItem("pageLimit", e.target.value);
                              localStorage.setItem(
                                "pageNumber",
                                pageNumber.toString(),
                              );
                              setPageNumber(1);
                            }}
                          >
                            <FormControlLabel
                              value={10}
                              control={<Radio />}
                              label="10"
                            />
                            <FormControlLabel
                              value={20}
                              control={<Radio />}
                              label="20"
                            />
                            <FormControlLabel
                              value={50}
                              control={<Radio />}
                              label="50"
                            />
                          </RadioGroup>
                        </FormControl>
                        <Pagination
                          itemsPerPage={pageLimit}
                          totalPages={totalRecord}
                          onPageChage={onPageChange}
                          page={pageNumber - 1}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <p>{!isLoading && <NoDatFound />}</p>
                )}
              </TabPanel>
              <TabPanel
                value={activeTab}
                index={ADMINUSERS_LIST_TABS.EMAIL_VERFICATION_LIST}
              >
                {isLoading && <CenterLoader />}
                {emailVerificationPendingLst.length > 0 ? (
                  <>
                    {" "}
                    <EmailVerificationUsers
                      users={emailVerificationPendingLst}
                    />
                    {totalRecord > 10 && (
                      <>
                        <FormControl className="pagelimit">
                          <FormLabel id="demo-row-radio-buttons-group-label">
                            <FormattedMessage
                              id="page_size"
                              defaultMessage="Page Size"
                            />
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={pageLimit}
                            onChange={(e: any) => {
                              setPageLimit(e.target.value);
                              localStorage.setItem("pageLimit", e.target.value);
                              localStorage.setItem(
                                "pageNumber",
                                pageNumber.toString(),
                              );
                              setPageNumber(1);
                            }}
                          >
                            <FormControlLabel
                              value={10}
                              control={<Radio />}
                              label="10"
                            />
                            <FormControlLabel
                              value={20}
                              control={<Radio />}
                              label="20"
                            />
                            <FormControlLabel
                              value={50}
                              control={<Radio />}
                              label="50"
                            />
                          </RadioGroup>
                        </FormControl>
                        <Pagination
                          itemsPerPage={pageLimit}
                          totalPages={totalRecord}
                          onPageChage={onPageChangeEv}
                          page={pageNumber - 1}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <p>{!isLoading && <NoDatFound />}</p>
                )}
              </TabPanel>

              <TabPanel
                value={activeTab}
                index={ADMINUSERS_LIST_TABS.PENDING_LIST}
              >
                {isLoading && <CenterLoader />}

                {userList.length > 0 ? (
                  <>
                    {" "}
                    <PendinUsers users={userList} />
                    {totalRecord > 10 && (
                      <>
                        <FormControl className="pagelimit">
                          <FormLabel id="demo-row-radio-buttons-group-label">
                            <FormattedMessage
                              id="page_size"
                              defaultMessage="Page Size"
                            />
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={pageLimit}
                            onChange={(e: any) => {
                              setPageLimit(e.target.value);
                              localStorage.setItem("pageLimit", e.target.value);
                              localStorage.setItem(
                                "pageNumber",
                                pageNumber.toString(),
                              );
                              setPageNumber(1);
                            }}
                          >
                            <FormControlLabel
                              value={10}
                              control={<Radio />}
                              label="10"
                            />
                            <FormControlLabel
                              value={20}
                              control={<Radio />}
                              label="20"
                            />
                            <FormControlLabel
                              value={50}
                              control={<Radio />}
                              label="50"
                            />
                          </RadioGroup>
                        </FormControl>
                        <Pagination
                          itemsPerPage={pageLimit}
                          totalPages={totalRecord}
                          onPageChage={onPageChange}
                          page={pageNumber - 1}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <p>{!isLoading && <NoDatFound />}</p>
                )}
              </TabPanel>
              <TabPanel
                value={activeTab}
                index={ADMINUSERS_LIST_TABS.RECORD_LIST}
              >
                <RecordsList />
              </TabPanel>
              <TabPanel
                value={activeTab}
                index={ADMINUSERS_LIST_TABS.ADMIN_LIST}
              >
                {adminList.length > 0 ? (
                  <>
                    <AdminList users={adminList} pageNumber={pageNumber} />
                    {totalRecord > 10 && (
                      <>
                        <FormControl className="pagelimit">
                          <FormLabel id="demo-row-radio-buttons-group-label">
                            <FormattedMessage
                              id="page_size"
                              defaultMessage="Page Size"
                            />
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={pageLimit}
                            onChange={(e: any) => {
                              setPageLimit(e.target.value);
                              localStorage.setItem("pageLimit", e.target.value);
                              localStorage.setItem(
                                "pageNumber",
                                pageNumber.toString(),
                              );
                              setPageNumber(1);
                            }}
                          >
                            <FormControlLabel
                              value={10}
                              control={<Radio />}
                              label="10"
                            />
                            <FormControlLabel
                              value={20}
                              control={<Radio />}
                              label="20"
                            />
                            <FormControlLabel
                              value={50}
                              control={<Radio />}
                              label="50"
                            />
                          </RadioGroup>
                        </FormControl>
                        <Pagination
                          itemsPerPage={pageLimit}
                          totalPages={totalRecord}
                          onPageChage={onPageChangeAdmin}
                          page={pageNumber - 1}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <p>{!isLoading && <NoDatFound />}</p>
                )}
              </TabPanel>
              <TabPanel
                value={activeTab}
                index={ADMINUSERS_LIST_TABS.CONSUMER_LIST}
              >
                {consumerList.length > 0 ? (
                  <>
                    <ConsumerList users={consumerList} />
                    {totalRecord > 10 && (
                      <>
                        <FormControl className="pagelimit">
                          <FormLabel id="demo-row-radio-buttons-group-label">
                            <FormattedMessage
                              id="page_size"
                              defaultMessage="Page Size"
                            />
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={pageLimit}
                            onChange={(e: any) => {
                              setPageLimit(e.target.value);
                              localStorage.setItem("pageLimit", e.target.value);
                              localStorage.setItem(
                                "pageNumber",
                                pageNumber.toString(),
                              );
                              setPageNumber(1);
                            }}
                          >
                            <FormControlLabel
                              value={10}
                              control={<Radio />}
                              label="10"
                            />
                            <FormControlLabel
                              value={20}
                              control={<Radio />}
                              label="20"
                            />
                            <FormControlLabel
                              value={50}
                              control={<Radio />}
                              label="50"
                            />
                          </RadioGroup>
                        </FormControl>
                        <Pagination
                          itemsPerPage={pageLimit}
                          totalPages={totalRecord}
                          onPageChage={onPageChangeAdmin}
                          page={pageNumber - 1}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <p>{!isLoading && <NoDatFound />}</p>
                )}
              </TabPanel>
            </Card.Body>
          </Card>
        </section>
      </div>
      {adminModal.modal && (
        <AddAdminModal
          onModalClose={() => setAdminModal({ modal: false, admin: {} })}
          activity={adminModal.admin}
        />
      )}
    </div>
  );
};

export default AdminUsers;

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#68439a",
      height: 1,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#68439a",
      BsBorderBottom: "1px solid",
    },
  },
});

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
