// import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
// import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemAvatar from "@mui/material/ListItemAvatar";
// import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
// import Typography from "@mui/material/Typography";
// import { blue } from "@mui/material/colors";
import { FormattedMessage } from "react-intl";
import { useEffect } from "react";

const DeletePopup = ({ onClose, open, onDeleteClick, text }: any) => {
  const handleClose = () => {
    onClose(false);
  };

  useEffect(() => {
    console.log("useEffect");
  }, []);

  // const handleListItemClick = (value: any) => {
  //   onClose(value);
  // };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        {" "}
        {text || (
          <FormattedMessage
            id="msg.general"
            defaultMessage="Are you sure to delete?"
          />
        )}{" "}
      </DialogTitle>
      <List
        sx={{ pt: 0 }}
        style={{ textAlign: "end", padding: "12px 12px 12px 12px" }}
      >
        <Button
          onClick={handleClose}
          variant="outlined"
          startIcon={<CloseIcon />}
          className="mr-2"
          color="error"
        >
          <FormattedMessage id="page.No" defaultMessage="No" />
        </Button>
        <Button
          onClick={onDeleteClick}
          variant="outlined"
          startIcon={<DoneIcon />}
          color="success"
        >
          <FormattedMessage id="page.Yes" defaultMessage="Yes" />
        </Button>
      </List>
    </Dialog>
  );
};

DeletePopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  text: PropTypes.any,
};

export default DeletePopup;
