import React, { useEffect, useState } from "react";
import { useFormik } from "formik";

import { useDispatch, useSelector } from "react-redux";
import { createTicketGroupList, editAnnouncements } from "../../redux/action";
import Loader from "../common/Loader";
import Select from "react-select";
import {
  AddAnnouncementSchema,
  AddGroupSchema,
} from "../../util/validationSchema";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import { Store } from "../../redux/Actions";
import { GROUP_TYPE } from "../../Constants";
import { FaTimesCircle } from "react-icons/fa";

const CreateGroup = ({ onModalClose, isAPILoading, type }: any) => {
  const dispatch = useDispatch();
  const urlParams = useParams();

  const isLoading = useSelector(
    (store: Store) => store.ticketReducer.onSaveLoading,
  );
  const [SelectedClasses, setSelectedClasses] = useState<File>();
  const [classes, setClasses] = useState<any>();
  const [ButtonClick, setButtonClick] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      group_name: "",
      description: "",
    },
    validationSchema: AddGroupSchema,
    onSubmit: async (values) => {
      if (!ButtonClick) {
        const { id } = urlParams;

        dispatch(createTicketGroupList(values, true, type));
        setButtonClick(true);
      } // if (values.class_group.length <= 0 && values.toAll === false) {
      //     toast.error("class group is required");
      // } else {
      //     const imgs: any = announcementsDetails.attechment.map(
      //         (i: any) => i.attachmentPath
      //     );
      //     const all: boolean = classes.length === values.class_group.length;
      //     const temp = {
      //         title: announcementsDetails.title,
      //         content: announcementsDetails.content,
      //         class_group: values.class_group,
      //         attachmentPath: imgs,
      //         toAll: all,
      //     };
      //     dispatch(editAnnouncements(true, id, temp));
      //     setButtonClick(true);
      // }
    },
  });

  useEffect(() => {
    if (!isLoading && ButtonClick) {
      onModalClose();
      setButtonClick(false);
    }
  }, [isLoading]);

  const onClassChange = (option: any) => {
    // if (option.length > 0 && option[option.length - 1].id == 0) {
    //     const cl = classes;
    //     setSelectedClasses(cl);
    //     const ids: number[] = classes.map((i: any) => i.id);
    //     formik.setFieldValue("class_group", ids);
    // } else {
    //     setSelectedClasses(option);
    //     const ids = option.map((i: any) => i.id);
    //     formik.setFieldValue("class_group", ids);
    // }
  };

  const { touched, errors } = formik;
  return (
    <Modal
      centered
      scrollable
      show={true}
      onHide={() => {
        onModalClose(false);
      }}
      data-modal="scroll"
    >
      <Modal.Header>
        <h4 className="modal-title">
          <FormattedMessage
            id="Tickets.createGroup"
            defaultMessage="Create Group"
          />{" "}
        </h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onModalClose}
        >
          <FaTimesCircle />
        </button>
      </Modal.Header>
      <Modal.Body style={{ minHeight: "200px" }}>
        <div className="form-group">
          <label className="control-label">
            {" "}
            <FormattedMessage
              id="Tickets.GroupName"
              defaultMessage="Group Name"
            />
          </label>
          <input
            type="text"
            className="form-control"
            name="group_name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {/* <Select
                        name="class_group"
                        value={SelectedClasses}
                        onChange={(option: any) => onClassChange(option)}
                        onBlur={() => formik.setFieldTouched("class_group", true)}
                        options={classes}
                        getOptionLabel={(option: any) => option.name}
                        getOptionValue={(option: any) => option.id}
                        isMulti
                        className="custom-select-dropdown multi w-100p"
                        classNamePrefix="react-select-dropdown"
                    /> */}
          {errors.group_name && touched.group_name && (
            <div className="text-error">
              {" "}
              <FormattedMessage
                id={errors.group_name.toString()}
                defaultMessage="require feild"
              />
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        {isLoading ? (
          <button
            disabled
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            {" "}
            <FormattedMessage
              id="annousmentmodal.Cancel"
              defaultMessage="Cancel"
            />
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={onModalClose}
          >
            {" "}
            <FormattedMessage
              id="annousmentmodal.Cancel"
              defaultMessage="Cancel"
            />
          </button>
        )}

        {isLoading ? (
          <button
            type="button"
            className="btn btn-primary"
            disabled
            // data-dismiss="modal"
          >
            <FormattedMessage
              id="annousmentmodal.Submit"
              defaultMessage="Submit"
            />
            {isLoading && <Loader />}
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => formik.handleSubmit()}
            data-dismiss="modal"
          >
            <FormattedMessage
              id="annousmentmodal.Submit"
              defaultMessage="Submit"
            />
            {isLoading && <Loader />}
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CreateGroup;
