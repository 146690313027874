import { RolesManagementData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";
import { GET_VPS_LIST } from "../constants/action-types";

const initialState = {
  isLoading: false,
  UserVPSMachineList: [],
  isVPSstatusUpdated: false,
  totalRecord: 0,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: RolesManagementData = initialState, action: ActionTypes) => {
    const data: any = action.payload;
    switch (action.type) {
      case GET_VPS_LIST.GET_VPS_LIST_INITIALIZATION:
        return {
          ...state,
          UserVPSMachineList: [],
          isLoading: true,
        };

      case GET_VPS_LIST.GET_VPS_LIST_SUCCESS:
        return {
          ...state,
          UserVPSMachineList: data.data,
          totalRecord: data.totalCount,
          isLoading: false,
        };

      case GET_VPS_LIST.GET_VPS_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
          totalRecord: 0,
        };

      default:
        return state;
    }
  },
);

export default reducer;
