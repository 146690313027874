/* eslint-disable no-case-declarations */
import {
  GET_STUDENT_LIST,
  GET_STUDENT_BY_CLASS_LIST,
  EXAMINE_STUDENT_LIST,
  GET_STUDENT_BY_ID_LIST,
  ADD_STUDENT_MANAUAL_LIST,
} from "../constants/action-types";
import { StudentData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";

const initialState = {
  Loading: {
    isLoading: false,
    onSaveLoading: false,
  },
  studentList: [],
  studentDetail: [],
  classStudentsList: [],
  isApprove: false,
  totalRecord: 0,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: StudentData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case "CLEAR_DATA":
        return {
          ...state,
          classStudentsList: [],
        };
      case GET_STUDENT_LIST.GET_STUDENT_LIST_INITIALIZATION:
        return {
          ...state,
          // studentList: [],
          totalRecord: 0,
          Loading: {
            ...state.Loading,
            isLoading: true,
          },
        };

      case GET_STUDENT_LIST.GET_STUDENT_LIST_SUCCESS:
        const allPost: any = action.payload;
        return {
          ...state,
          totalRecord: allPost.totalCount,
          studentList: allPost.data,
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };

      case GET_STUDENT_LIST.GET_STUDENT_LIST_ERROR:
        return {
          ...state,
          studentList: [],
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };

      case GET_STUDENT_BY_CLASS_LIST.GET_STUDENT_BY_CLASS_LIST_INITIALIZATION:
        return {
          ...state,
          Loading: {
            ...state.Loading,
            isLoading: true,
          },
        };

      case GET_STUDENT_BY_CLASS_LIST.GET_STUDENT_BY_CLASS_LIST_SUCCESS:
        return {
          ...state,
          classStudentsList: action.payload,
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };

      case GET_STUDENT_BY_CLASS_LIST.GET_STUDENT_BY_CLASS_LIST_ERROR:
        return {
          ...state,
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };

      case EXAMINE_STUDENT_LIST.EXAMINE_STUDENT_LIST_INITIALIZATION:
        return {
          ...state,
          Loading: {
            ...state.Loading,
            onSaveLoading: true,
          },
        };

      case EXAMINE_STUDENT_LIST.EXAMINE_STUDENT_LIST_SUCCESS:
        return {
          ...state,
          classStudentsList: action.payload,
          Loading: {
            ...state.Loading,
            onSaveLoading: false,
          },
        };

      case EXAMINE_STUDENT_LIST.EXAMINE_STUDENT_LIST_ERROR:
        return {
          ...state,
          Loading: {
            ...state.Loading,
            onSaveLoading: false,
          },
        };

      case GET_STUDENT_BY_ID_LIST.GET_STUDENT_BY_ID_LIST_INITIALIZATION:
        return {
          ...state,
          Loading: {
            ...state.Loading,
            isLoading: true,
          },
        };

      case GET_STUDENT_BY_ID_LIST.GET_STUDENT_BY_ID_LIST_SUCCESS:
        return {
          ...state,
          studentDetail: action.payload,
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };

      case GET_STUDENT_BY_ID_LIST.GET_STUDENT_BY_ID_LIST_ERROR:
        return {
          ...state,
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };

      case ADD_STUDENT_MANAUAL_LIST.ADD_STUDENT_MANAUAL_LIST_INITIALIZATION:
        return {
          ...state,
          Loading: {
            ...state.Loading,
            isLoading: true,
          },
        };

      case ADD_STUDENT_MANAUAL_LIST.ADD_STUDENT_MANAUAL_LIST_SUCCESS:
        return {
          ...state,
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };

      case ADD_STUDENT_MANAUAL_LIST.ADD_STUDENT_MANAUAL_LIST_ERROR:
        return {
          ...state,
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };

      default:
        return state;
    }
  },
);

export default reducer;
