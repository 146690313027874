import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
// import Select from "react-select";
// import { BsTrash } from "react-icons/bs";
import { FormattedMessage } from "react-intl";
import { addLibraryList, editLibraryList } from "../../redux/action";
import { AddLibrarySchema } from "../../util/validationSchema";
// import FileUpload from "../common/MediaUpload/FileUpload";
import { Store } from "../../redux/Actions";
import Loader from "../common/Loader";
import { FaTimesCircle } from "react-icons/fa";
// import { _UploadMedia } from "../../service/MediaUpload/classes.service";

const AddLibraryModal = ({ onModalClose, editLibrary }: any) => {
  const dispatch = useDispatch();
  const [ButtonClick, setButtonClick] = useState(false);
  // const [selectedRoles, setSelectedRoles] = useState([]);
  // const [fileUploadURL, setFileUploadURL] = useState("");

  const isAPILoading = useSelector(
    (state: Store) => state.libraryReducer.onSaveLoading,
  );

  const formik = useFormik({
    initialValues: {
      name: editLibrary !== null ? editLibrary.name : "",
      info: editLibrary !== null ? editLibrary.info : "",
    },
    validationSchema: AddLibrarySchema,
    onSubmit: (values) => {
      if (!ButtonClick) {
        if (editLibrary === null) {
          dispatch(addLibraryList(true, values));
        } else {
          dispatch(editLibraryList(true, editLibrary.id, values));
        }
        setButtonClick(true);
      }
    },
  });

  useEffect(() => {
    if (!isAPILoading && ButtonClick) {
      onModalClose();
      setButtonClick(false);
    }
  }, [isAPILoading]);

  const { touched, errors } = formik;
  return (
    <Modal centered show={true} onHide={() => onModalClose(false)}>
      <Modal.Header>
        <h4 className="modal-title">
          {editLibrary !== null ? (
            <FormattedMessage
              id="head.EditLibrary"
              defaultMessage="Edit Library"
            />
          ) : (
            <FormattedMessage
              id="head.AddLibrary"
              defaultMessage="Add new Library"
            />
          )}
        </h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onModalClose}
        >
          <FaTimesCircle />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label className="control-label">
            {" "}
            <FormattedMessage id="indicator.Name" defaultMessage="Name" />{" "}
          </label>
          <input
            type="text"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="form-control"
          />
          {errors.name && touched.name && (
            <div className="text-error">
              <FormattedMessage
                id={errors.name.toString()}
                defaultMessage="require"
              />{" "}
            </div>
          )}
        </div>

        <div className="form-group">
          <label className="control-label">
            {" "}
            <FormattedMessage id="indicator.Info" defaultMessage="info" />
          </label>
          <textarea
            rows={2}
            cols={50}
            name="info"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.info}
            className="form-control"
          />
          {errors.info && touched.info && (
            <div className="text-error">
              <FormattedMessage
                id={errors.info.toString()}
                defaultMessage="require"
              />{" "}
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {" "}
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          onClick={onModalClose}
        >
          <FormattedMessage id="indicator.Cancel" defaultMessage="Cancel" />
        </button>
        <button
          type="button"
          className="btn btn-primary"
          style={{ display: "inherit" }}
          onClick={() => formik.handleSubmit()}
          data-dismiss="modal"
        >
          {editLibrary !== null ? (
            <FormattedMessage id="page.Edit" defaultMessage="Edit" />
          ) : (
            <FormattedMessage id="Admin.GrantPoint.ADD" defaultMessage="Add" />
          )}
          {isAPILoading && <Loader />}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddLibraryModal;
