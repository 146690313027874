import React, { useEffect, useState } from "react";
import { useFormik } from "formik";

import { useDispatch, useSelector } from "react-redux";
import {
  createTicketGroupList,
  editAnnouncements,
  GetAdminMemberList,
} from "../../redux/action";
import Loader from "../common/Loader";
import Select from "react-select";
import { AddMemberSchema } from "../../util/validationSchema";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import { Store } from "../../redux/Actions";
import { GROUP_TYPE } from "../../Constants";
import { FaTimesCircle } from "react-icons/fa";

const AddMemberinGroup = ({
  onModalClose,
  isAPILoading,
  users,
  allUser,
  onSave,
  type,
}: any) => {
  const dispatch = useDispatch();
  const urlParams = useParams();

  const isLoading = useSelector(
    (store: Store) => store.ticketReducer.onSaveLoading,
  );
  // const adminMember = useSelector(
  //   (store: Store) => store.ticketReducer.adminMember
  // );

  const [SelectedClasses, setSelectedClasses] = useState<File>();
  const [classes, setClasses] = useState<any>();
  const [ButtonClick, setButtonClick] = useState(false);

  useEffect(() => {
    dispatch(GetAdminMemberList(false, type));
  }, []);

  useEffect(() => {
    // dispatch(GetAdminMemberList(false));
    setSelectedClasses(users);
    formik.setFieldValue(
      "members",
      users.map((i: any) => i.id),
    );
  }, [users]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      members: "",
    },
    // validationSchema: AddMemberSchema,
    onSubmit: async (values) => {
      if (!ButtonClick) {
        const { id } = urlParams;
        onSave(values.members);

        // dispatch(createTicketGroupList(values, false));
        setButtonClick(true);
      } // if (values.class_group.length <= 0 && values.toAll === false) {
      //     toast.error("class group is required");
      // } else {
      //     const imgs: any = announcementsDetails.attechment.map(
      //         (i: any) => i.attachmentPath
      //     );
      //     const all: boolean = classes.length === values.class_group.length;
      //     const temp = {
      //         title: announcementsDetails.title,
      //         content: announcementsDetails.content,
      //         class_group: values.class_group,
      //         attachmentPath: imgs,
      //         toAll: all,
      //     };
      //     dispatch(editAnnouncements(true, id, temp));
      //     setButtonClick(true);
      // }
    },
  });

  useEffect(() => {
    if (!isLoading && ButtonClick) {
      onModalClose();
      setButtonClick(false);
    }
  }, [isLoading]);

  const onClassChange = (option: any) => {
    setSelectedClasses(option);
    formik.setFieldValue(
      "members",
      option.map((i: any) => i.id),
    );

    // if (option.length > 0 && option[option.length - 1].id == 0) {
    //     const cl = classes;
    //     setSelectedClasses(cl);
    //     const ids: number[] = classes.map((i: any) => i.id);
    //     formik.setFieldValue("class_group", ids);
    // } else {
    //     setSelectedClasses(option);
    //     const ids = option.map((i: any) => i.id);
    //     formik.setFieldValue("class_group", ids);
    // }
  };

  const { touched, errors } = formik;
  return (
    <Modal
      centered
      scrollable
      show={true}
      onHide={() => {
        onModalClose(false);
      }}
      data-modal="scroll"
    >
      <Modal.Header>
        <h4 className="modal-title">
          <FormattedMessage
            id="Tickets.AddMemberInGroup"
            defaultMessage="Add Member in Group"
          />{" "}
        </h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onModalClose}
        >
          <FaTimesCircle />
        </button>
      </Modal.Header>
      <Modal.Body style={{ minHeight: "200px" }}>
        <div className="form-group">
          <label className="control-label">
            {" "}
            <FormattedMessage
              id="Tickets.GroupMember"
              defaultMessage="Group Member"
            />
          </label>
          {/* <input type="text" className="form-control"
                        name="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    /> */}
          {type === GROUP_TYPE.CLASS && (
            <Select
              name="class_group"
              value={SelectedClasses}
              onChange={(option: any) => onClassChange(option)}
              onBlur={() => formik.setFieldTouched("class_group", true)}
              options={allUser}
              getOptionLabel={(option: any) => option?.name}
              getOptionValue={(option: any) => option.id}
              isMulti
              className="custom-select-dropdown multi w-100p"
              classNamePrefix="react-select-dropdown"
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
          )}
          {type === GROUP_TYPE.TICKET && (
            <Select
              name="class_group"
              value={SelectedClasses}
              onChange={(option: any) => onClassChange(option)}
              onBlur={() => formik.setFieldTouched("class_group", true)}
              options={allUser}
              getOptionLabel={(option: any) =>
                option.englishFirstName + "" + option.englishLastName
              }
              getOptionValue={(option: any) => option.id}
              isMulti
              className="custom-select-dropdown multi w-100p"
              classNamePrefix="react-select-dropdown"
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
          )}
          {/* {errors.class_group && touched.class_group && (
                        <div className="text-error">
                            {" "}
                            <FormattedMessage
                                id={errors.class_group.toString()}
                                defaultMessage="require feild"
                            />
                        </div>
                    )} */}
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        {isLoading ? (
          <button
            disabled
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            {" "}
            <FormattedMessage
              id="annousmentmodal.Cancel"
              defaultMessage="Cancel"
            />
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={onModalClose}
          >
            {" "}
            <FormattedMessage
              id="annousmentmodal.Cancel"
              defaultMessage="Cancel"
            />
          </button>
        )}

        {isLoading ? (
          <button
            type="button"
            className="btn btn-primary"
            disabled
            data-dismiss="modal"
          >
            <FormattedMessage
              id="annousmentmodal.Submit"
              defaultMessage="Submit"
            />
            {isLoading && <Loader />}
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => formik.handleSubmit()}
            data-dismiss="modal"
          >
            <FormattedMessage
              id="annousmentmodal.Submit"
              defaultMessage="Submit"
            />
            {isLoading && <Loader />}
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AddMemberinGroup;
