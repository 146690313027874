import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import noImage from "../../assets/img/noImage.jpeg";
// import logoImg from "../../assets/img/Static.jpeg";
import "./classes.css";
import { getClassDetail } from "../../redux/action";
import { Store } from "../../redux/Actions";
// import Products from "./Product";
import Tools from "./Tools";
import Version from "./Version";
import { formatDate, getFileName } from "../../util/utils";
import { FormattedMessage } from "react-intl";

const ClassDetail = () => {
  const urlParams = useParams();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(1);

  const classDetail: any = useSelector(
    (state: Store) => state.classReducer.classDetail,
  ) as any;

  useEffect(() => {
    const { id } = urlParams;
    if (id && parseInt(id) > 0) {
      dispatch(getClassDetail(parseInt(id)));
    }
  }, []);

  const setAciveTab = (tab: number) => {
    setActiveTab(tab);
  };

  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                <FormattedMessage
                  id="classDetail.AdminClassesDetails"
                  defaultMessage="Admin Classes Details"
                />
              </h1>
            </div>
            <div className="m-l-10 common-right-button">
              <Link className="btn btn-secondary" to="/classes">
                <span style={{ verticalAlign: "middle" }}>
                  <i className="back-button">
                    <BsChevronLeft /> &nbsp;
                  </i>
                  <FormattedMessage id="page.back" defaultMessage="Back" />
                </span>
              </Link>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          <Card style={{ width: "100%" }}>
            <Card.Body className="p-0">
              <ul className="nav nav-tabs primary-tabs m-0">
                <li
                  className="nav-item"
                  role="presentation"
                  onClick={() => setAciveTab(1)}
                >
                  <a
                    href="#tab-1"
                    className={
                      activeTab === 1 ? "nav-link active show" : "nav-link"
                    }
                    data-toggle="tab"
                    aria-expanded="true"
                  >
                    <FormattedMessage id="tab.Detail" defaultMessage="Detail" />
                  </a>
                </li>
                <li
                  className="nav-item"
                  role="presentation"
                  onClick={() => setAciveTab(3)}
                >
                  <a
                    href="#tab-3"
                    className={
                      activeTab === 3 ? "nav-link active show" : "nav-link"
                    }
                    data-toggle="tab"
                    aria-expanded="true"
                  >
                    <FormattedMessage id="tab.tool" defaultMessage="Tools" />
                  </a>
                </li>
                <li
                  className="nav-item"
                  role="presentation"
                  onClick={() => setAciveTab(4)}
                >
                  <a
                    href="#tab-3"
                    className={
                      activeTab === 4 ? "nav-link active show" : "nav-link"
                    }
                    data-toggle="tab"
                    aria-expanded="true"
                  >
                    <FormattedMessage
                      id="tab.version"
                      defaultMessage="Version"
                    />
                  </a>
                </li>
              </ul>

              <div className="mt-0">
                <Card.Text>
                  {activeTab === 1 && (
                    <div
                      className={
                        activeTab === 1
                          ? "tab-pane fadeIn active"
                          : "tab-pane fadeIn"
                      }
                      id="tab-1"
                    >
                      <div className="p-4">
                        <Row>
                          <Col sm={6} md={6} lg={3}>
                            {classDetail.image_path ? (
                              <img
                                style={{ minWidth: "100%" }}
                                src={classDetail.image_path}
                              />
                            ) : (
                              <i>
                                <img
                                  src={noImage}
                                  style={{ opacity: ".3", height: "190px" }}
                                />
                              </i>
                            )}
                          </Col>
                          <Col sm={7} md={7} lg={9}>
                            {Object.keys(classDetail).length > 0 && (
                              <Table borderless className="ml-3">
                                <tbody>
                                  <tr>
                                    <td
                                      className="details-td head-td"
                                      style={{ width: "35%" }}
                                    >
                                      <FormattedMessage
                                        id="table.head.ID"
                                        defaultMessage="ID"
                                      />
                                      :
                                    </td>
                                    <td
                                      className="details-td"
                                      style={{ textAlign: "left" }}
                                    >
                                      {classDetail.id}
                                    </td>
                                  </tr>
                                  <tr style={{ height: "20px" }}></tr>
                                  <tr>
                                    <td
                                      className="details-td head-td"
                                      style={{ width: "35%" }}
                                    >
                                      <FormattedMessage
                                        id="classmodal.courseId"
                                        defaultMessage="Course Id"
                                      />
                                      :
                                    </td>
                                    <td
                                      className="details-td"
                                      style={{ textAlign: "left" }}
                                    >
                                      {classDetail.courseId || "N/A"}
                                    </td>
                                  </tr>
                                  <tr style={{ height: "20px" }}></tr>
                                  <tr>
                                    <td className="head-td details-td">
                                      <FormattedMessage
                                        id="table.head.Category"
                                        defaultMessage="Category"
                                      />
                                      :
                                    </td>
                                    <td className="details-td">
                                      {classDetail?.classCategory?.name ||
                                        "N/A"}
                                    </td>
                                  </tr>
                                  <tr style={{ height: "20px" }}></tr>
                                  <tr>
                                    <td className="head-td details-td">
                                      <FormattedMessage
                                        id="table.head.Name"
                                        defaultMessage="Name"
                                      />
                                      :
                                    </td>
                                    <td className="details-td">
                                      {classDetail.name}
                                    </td>
                                  </tr>
                                  <tr style={{ height: "20px" }}></tr>

                                  <tr>
                                    <td className="head-td details-td">
                                      <FormattedMessage
                                        id="table.head.Status"
                                        defaultMessage="Status"
                                      />
                                      :
                                    </td>
                                    <td className="details-td">
                                      {classDetail.status}
                                    </td>
                                  </tr>
                                  <tr style={{ height: "20px" }}></tr>

                                  <tr>
                                    <td className="head-td details-td">
                                      <FormattedMessage
                                        id="table.head.initDemoLimit"
                                        defaultMessage="init Demo Limit"
                                      />
                                      :
                                    </td>
                                    <td className="details-td">
                                      {classDetail.initDemoLimit}
                                    </td>
                                  </tr>
                                  <tr style={{ height: "20px" }}></tr>

                                  <tr>
                                    <td className="head-td details-td">
                                      <FormattedMessage
                                        id="table.head.initExpiryDays"
                                        defaultMessage="init Expiry Days"
                                      />
                                      :
                                    </td>
                                    <td className="details-td">
                                      {classDetail.initExpiryDays}
                                    </td>
                                  </tr>
                                  <tr style={{ height: "20px" }}></tr>

                                  <tr>
                                    <td className="head-td details-td">
                                      <FormattedMessage
                                        id="table.head.initLiveLimit"
                                        defaultMessage="init Live Limit"
                                      />
                                      :
                                    </td>
                                    <td className="details-td">
                                      {classDetail.initLiveLimit}
                                    </td>
                                  </tr>
                                  <tr style={{ height: "20px" }}></tr>

                                  <tr>
                                    <td className="head-td details-td">
                                      <FormattedMessage
                                        id="table.head.initRepeaterExpiryDays"
                                        defaultMessage="init Repeater Expiry Days"
                                      />
                                      :
                                    </td>
                                    <td className="details-td">
                                      {classDetail.initRepeaterExpiryDays}
                                    </td>
                                  </tr>
                                  <tr style={{ height: "20px" }}></tr>

                                  <tr>
                                    <td className="head-td details-td">
                                      <FormattedMessage
                                        id="table.head.limit"
                                        defaultMessage="limit"
                                      />
                                      :
                                    </td>
                                    <td className="details-td">
                                      {classDetail.registerCount}/
                                      {classDetail.user_limit}
                                    </td>
                                  </tr>

                                  <tr style={{ height: "20px" }}></tr>
                                  <tr>
                                    <td className="head-td details-td">
                                      <FormattedMessage
                                        id="table.head.originalPrice"
                                        defaultMessage="original Price"
                                      />
                                      :
                                    </td>
                                    <td className="details-td">
                                      HKD$ {classDetail.originalPrice}.00
                                    </td>
                                  </tr>
                                  <tr style={{ height: "20px" }}></tr>

                                  <tr>
                                    <td className="head-td details-td">
                                      <FormattedMessage
                                        id="table.head.sellingPrice"
                                        defaultMessage="selling Price"
                                      />
                                      :
                                    </td>
                                    <td className="details-td">
                                      HKD$ {classDetail.sellingPrice}.00
                                    </td>
                                  </tr>
                                  <tr style={{ height: "20px" }}></tr>

                                  <tr>
                                    <td className="head-td details-td">
                                      <FormattedMessage
                                        id="table.head.closeApplicationDate"
                                        defaultMessage="close Application Date"
                                      />
                                      :
                                    </td>
                                    <td className="details-td">
                                      {classDetail.closeApplicationDate
                                        ? formatDate(
                                            classDetail.closeApplicationDate,
                                            "DD-MM-YYYY",
                                          )
                                        : "N/A"}
                                    </td>
                                  </tr>
                                  <tr style={{ height: "20px" }}></tr>

                                  <tr>
                                    <td className="head-td details-td">
                                      <FormattedMessage
                                        id="table.head.terms_condtion_path"
                                        defaultMessage="Terms & Conditions"
                                      />
                                      :
                                    </td>
                                    {classDetail.terms_condtion_path ? (
                                      <td
                                        className="details-td text-primary"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          window.open(
                                            classDetail.terms_condtion_path,
                                            "_blank",
                                          )
                                        }
                                      >
                                        {getFileName(
                                          classDetail.terms_condtion_path,
                                        )}
                                      </td>
                                    ) : (
                                      <td className="details-td">N/A</td>
                                    )}
                                  </tr>
                                </tbody>
                                {/* Candidate */}
                              </Table>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}

                  {activeTab === 3 && (
                    <div
                      className={
                        activeTab === 3
                          ? "tab-pane fadeIn active"
                          : "tab-pane fadeIn"
                      }
                      id="tab-3"
                    >
                      <Row>
                        <Col>
                          <Tools />
                        </Col>
                      </Row>
                    </div>
                  )}
                  {activeTab === 4 && (
                    <div
                      className={
                        activeTab === 4
                          ? "tab-pane fadeIn active"
                          : "tab-pane fadeIn"
                      }
                      id="tab-3"
                    >
                      <Row>
                        <Col>
                          <Version />
                        </Col>
                      </Row>
                    </div>
                  )}
                </Card.Text>
              </div>
            </Card.Body>
          </Card>
        </section>
      </div>
    </div>
  );
};
export default ClassDetail;
