import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import CenterLoader from "../Components/common/CenterLoader/CenterLoader";
import { dispatch } from "../redux";
import {
  getDashboardCount,
  getMembershipExpireUserList,
} from "../redux/action";
import { Store } from "../redux/Actions";

const Dashboard = () => {
  const dashboardCount: any = useSelector(
    (state: Store) => state.userDataReducer.dashboardCount,
  ) as Array<Object>;
  const isLoading = useSelector(
    (state: Store) => state.userDataReducer.isLoading,
  );

  useEffect(() => {
    dispatch(getDashboardCount());
  }, []);

  return (
    <>
      <div className="content-wrapper">
        {isLoading && <CenterLoader />}
        <div className="content">
          <header className="page-header">
            <div className="d-flex flex-wrap align-items-center">
              <div className="mr-auto">
                <h1>
                  {" "}
                  <FormattedMessage id="Dashboard" defaultMessage="Dashboard" />
                </h1>
              </div>
            </div>
          </header>
          <section className="page-content container-fluid">
            <div className="form-row dashboard-block">
              <div className="col-md-6 col-xl-2">
                <div className="card equal-height mb-0">
                  <div className="card-body equal-height">
                    <h2 className="card-title m-b-5 text-success font-weight-500">
                      {" "}
                      {dashboardCount?.totalUser}{" "}
                    </h2>
                    <h6 className="text-default m-t-10 mb-1">
                      <FormattedMessage
                        id="Dashboard.h6"
                        defaultMessage="Total Users"
                      />
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-2">
                <div className="card equal-height mb-0">
                  <div className="card-body equal-height">
                    <h2 className="card-title m-b-5 text-accent font-weight-500">
                      {" "}
                      {dashboardCount?.totalPendingUser}
                    </h2>
                    <h6 className="text-default m-t-10 mb-1">
                      <FormattedMessage
                        id="Dashboard.h22"
                        defaultMessage="  "
                      />
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-2">
                <div className="card equal-height mb-0">
                  <div className="card-body equal-height">
                    <h2 className="card-title m-b-5 text-warning font-weight-500">
                      {" "}
                      {dashboardCount?.totalClass}{" "}
                    </h2>
                    <h6 className="text-default m-t-10 mb-1">
                      <FormattedMessage
                        id="Dashboard.h62"
                        defaultMessage="Total Classes"
                      />
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-2">
                <div className="card equal-height mb-0">
                  <div className="card-body equal-height">
                    <h2 className="card-title m-b-5 text-info font-weight-500">
                      {" "}
                      {dashboardCount?.totalTools}
                    </h2>
                    <h6 className="text-default m-t-10 mb-1">
                      <FormattedMessage
                        id="Dashboard.h63"
                        defaultMessage="Total Tools"
                      />
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-2">
                <div className="card equal-height mb-0">
                  <div className="card-body equal-height">
                    <h2 className="card-title m-b-5 text-info font-weight-500">
                      {" "}
                      {dashboardCount?.totalMembershipExpiredUser}
                    </h2>
                    <h6 className="text-default m-t-10 mb-1">
                      <FormattedMessage
                        id="Dashboard.h64"
                        defaultMessage="Total Membership Expired Users"
                      />
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
