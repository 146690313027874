import React, { useEffect, useState } from "react";
// import { Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./classes.css";
import { deleteClassTool, getClassTool } from "../../redux/action";
import { Store } from "../../redux/Actions";
import ClassModalTool from "../../Components/Class/ClassModalTool";
// import { Button } from "@mui/material";
import { TOOLSMAP } from "../../util/constants/UserGroup";
import DeletePopup from "../../Components/common/Popups/DeletePopup";
import NoDatFound from "../../Components/common/NoDatFound";
import Pagination from "../../Components/common/Paginate/Pagination";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import { FormattedMessage } from "react-intl";
import { FaTrashAlt } from "../../reactIcons";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

const Tools = () => {
  const urlParams = useParams();
  const dispatch = useDispatch();
  const [addProduct, setAddProduct] = useState(false);
  const [deletePopup, setDeletePopup] = useState({ modal: false, id: 0 });

  const classTool: any = useSelector(
    (state: Store) => state.classReducer.classTool,
  ) as any;

  const isLoading = useSelector((state: Store) => state.classReducer.isLoading);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const totalRecord = useSelector(
    (state: Store) => state.classReducer.totalRecord,
  ) as any;

  const onPageChange = (pageNum: number) => {
    setPageNumber(pageNum);
  };

  useEffect(() => {
    const { id } = urlParams;
    if (id && parseInt(id) > 0) {
      dispatch(getClassTool(false, parseInt(id), pageNumber, pageLimit));
    }
  }, [pageNumber, pageLimit]);

  const deleteComment = (commentId: any) => {
    const { id } = urlParams;

    dispatch(deleteClassTool(true, commentId, id));
    setDeletePopup({ modal: false, id: 0 });
  };

  return (
    <div className="content-wrapper">
      <div className="">
        <div className="d-flex justify-content-end p-4">
          <a
            href="#"
            data-toggle="modal"
            data-target="#AddCourse"
            onClick={() => {
              setAddProduct(true);
            }}
            type="button"
            className="btn btn-primary"
          >
            <FormattedMessage id="head.addTool" defaultMessage="Add Tools" />{" "}
          </a>
        </div>
        <div className="table-responsive">
          {isLoading && <CenterLoader />}

          {classTool && classTool.length > 0 ? (
            <>
              {" "}
              <table className="table table-hover m-0">
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage
                        id="table.head.ID"
                        defaultMessage="ID"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="table.head.ToolID"
                        defaultMessage="Tool ID"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="table.head.Name"
                        defaultMessage="Name"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="Student.Manual.Type"
                        defaultMessage="Type"
                      />
                    </th>
                    <th className="text-center" style={{ width: "100px" }}>
                      <FormattedMessage
                        id="page.Delete"
                        defaultMessage="Delete"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {classTool &&
                    classTool.map((i: any) => (
                      <tr>
                        <td>{i.id}</td>
                        <td>{i.tool.id}</td>
                        <td>{i.tool.name}</td>
                        <td>{TOOLSMAP[i.type]}</td>
                        <td className="text-center">
                          <a
                            onClick={() =>
                              setDeletePopup({ modal: true, id: i.id })
                            }
                            className="btn btn-sm btn-icon-only"
                            title=""
                          >
                            <i className="fa-lg">
                              {" "}
                              <FaTrashAlt />{" "}
                            </i>
                          </a>
                          {/* <Button variant="outlined" color="error"  >Delete</Button> */}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          ) : (
            <p>{!isLoading && <NoDatFound />}</p>
          )}
        </div>
        {totalRecord > 10 && classTool.length > 0 && (
          <>
            <FormControl className="pagelimit">
              <FormLabel id="demo-row-radio-buttons-group-label">
                <FormattedMessage id="page_size" defaultMessage="Page Size" />
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={pageLimit}
                onChange={(e: any) => {
                  setPageLimit(e.target.value);
                  setPageNumber(1);
                }}
              >
                <FormControlLabel value={10} control={<Radio />} label="10" />
                <FormControlLabel value={20} control={<Radio />} label="20" />
                <FormControlLabel value={50} control={<Radio />} label="50" />
              </RadioGroup>
            </FormControl>
            <Pagination
              itemsPerPage={pageLimit}
              totalPages={totalRecord}
              onPageChage={onPageChange}
              page={pageNumber - 1}
            />
          </>
        )}
      </div>

      {addProduct && (
        <ClassModalTool
          onModalClose={() => setAddProduct(false)}
          classId={urlParams.id}
        />
      )}

      {deletePopup && (
        <DeletePopup
          onClose={() => setDeletePopup({ modal: false, id: 0 })}
          onDeleteClick={() => deleteComment(deletePopup.id)}
          open={deletePopup.modal}
        />
      )}
    </div>
  );
};
export default Tools;
