export const USER_SIGNIN = "auth/signin";

export const GET_CLASS_CATEGORY_URL = "class/category";
export const EDIT_CLASS_URL = "class/";
export const GET_PRODUCTS_URL = "product";
export const ADD_CLASS_TOOL_URL = "tickets/tools/list";
export const GET_ANNOUNCEMENT_LIST_URL = "announcement";
export const DELETE_ANNOUNCEMENT_URL = "announcement/";
export const GET_ADMIN_ROLE_LIST_URL = "roles";
export const GET_ADMIN_ROLE_TOOL_LIST_URL = "class/role/tools";
export const GET_ADMIN_ROLE_USER_PERMISSION_LIST_URL = "userPermission/tools/";
export const GET_ADMIN_ROLE_EA_ACCOUNT_LIST_URL = "accounts/admin/";
export const GET_ADMIN_ROLE_LOGGING_ACCOUNT_LIST_URL = "log/user";
export const ADD_ANNOUNCEMENT_COMMENT_LIST_URL = "announcement/admin/comment";
export const EDIT_ANNOUNCEMENT_LIST_URL = "announcement/";
export const GET_ANNOUNCEMENT_COMMENT_LIST_URL = "announcement/comments/";
export const DELETE_ANNOUNCEMENT_COMMENT_URL = "announcement/comment/";
export const RESEND_OTP_URL = "/users/admin/email-verification";
export const MAKE_ADMIN_URL = "";

export const GET_INDICATORS_LIST_URL = "indicator";
export const ADD_INDICATORS_LIST_URL = "indicator";
export const EIDT_INDICATORS_LIST_URL = "indicator/";

export const GET_GIFT_LIST_URL = "gift";
export const ADD_GIFT_LIST_URL = "gift";
export const EIDT_GIFT_LIST_URL = "gift/";
export const ADD_GIFT_CATEGORY_LIST_URL = "gift/category";
export const EDIT_GIFT_CATEGORY_LIST_URL = "gift/category/";
export const GET_GIFT_CATEGORY_LIST_URL = "gift/category";

export const GET_ALL_FAQ_CATEGORY_LIST_URL = "faq/category";
export const ADD_FAQ_CATEGORY_LIST_URL = "faq/category";
export const EDIT_FAQ_CATEGORY_LIST_URL = "faq/category/";
export const DELETE_FAQ_CATEGORY_LIST_URL = "faq/category/";
export const GET_FAQ_CATEGORY_LIST_URL = "faq/category/";

export const GET_ALL_FAQ_LIST_URL = "faq/";
export const ADD_FAQ_LIST_URL = "faq/";
export const EDIT_FAQ_LIST_URL = "faq/";
export const DELETE_FAQ_LIST_URL = "faq/";
export const GET_FAQ_LIST_URL = "faq/";

export const GET_SCRIPTS_LIST_URL = "scripts";
export const ADD_SCRIPTS_LIST_URL = "scripts";
export const EIDT_SCRIPTS_LIST_URL = "scripts/";

export const GET_UTILITY_LIST_URL = "utility";
export const ADD_UTILITY_LIST_URL = "utility";
export const EIDT_UTILITY_LIST_URL = "utility/";

export const GET_EXPERT_ADVISOR_LIST_URL = "EA";
export const ADD_EXPERT_ADVISOR_LIST_URL = "EA";
export const EIDT_EXPERT_ADVISOR_LIST_URL = "EA/";
export const ADD_ACCOUNT_INFO_LIST_URL = "eaUserAccounts";
export const GET_ACCOUNT_INFO_LIST_URL = "eaUserAccounts";

export const GET_EXPRESSWAY_PENDING_LIST_URL = "users";
export const GET_ALL_USERS_LIST_URL = "users/admin/all/list";
export const GET_USERS_LIST_URL = "users/admin/list";
export const GET_EXPRESSWAY_RECORDS_LIST_URL = "users/records";
export const ADD_ADMIN_USER_URL = "users/";
export const GET_ADMIN_LIST_URL = "/users/admin-user/list";
export const GET_ALL_CONSUMER_URL = "/users/consumer";
export const UPDATE_ADMIN_STATUS_URL = "/users/admin/status-update/";

export const UPALOD_MEDIA_LIST_URL = "EA/";

export const GET_TICKET_LIST_URL = "tickets";
export const ADD_TICKET_LIST_URL = "tickets";
export const EIDT_TICKET_LIST_URL = "tickets/";

export const GET_TICKET_ISSUE_TYPE_LIST_URL = "tickets/types/list";

export const GET_TICKET_COMMENT_LIST_URL = "tickets/comments/";
export const ADD_TICKET_COMMENT_LIST_URL = "tickets/admin/comment/";
export const GET_TICKET_DETAIL_URL = "tickets/";
export const EDIT_TICKET_COMMENT_LIST_URL = "tickets/comment/";
export const EDIT_ANNOUNCEMENT_COMMENT_LIST_URL = "";

export const GET_CLASS_TOOL_BY_TYPE_URL = "class/tools/";
export const ADD_CLASS_TOOL_BY_TYPE_URL = "class/tools/";
export const GET_STUDENT_LIST_URL = "students";
export const GET_TICKET_GROUP_LIST_URL = "tickets/group/list";

export const GET_STUDENT_BY_CLASS_LIST_URL = "rents/student/";
export const EXAMINE_STUDENT_LIST_URL = "students/examine/";

export const GET_EXPRESSWAY_MEMBER_DETAIL_URL = "users/admin/";
export const ACT_EXPRESSWAY_MEMBER_DETAIL_URL = "express/";

export const GET_USER_DETAIL_URL = "auth/signin/";
export const EDIT_USER_DETAIL_URL = "";
export const GET_USER_ATTENDENCE_URL = "";

export const GET_TICKET_TOOL_VERSION_LIST_URL = "tickets/tools/versionList/";
export const GET_TICKET_TOOL_LIST_URL = "tickets/tools/list";

export const ADD_CLASS_TOOL_VERSION_URL = "class/version/";
export const GET_CLASS_TOOL_VERSION_URL = "/class/version/";
export const DELETE_CLASS_TOOL_VERSION_URL = "class/tool/";
export const DELETE_CLASS_TOOL_URL = "class/tool/";

export const GET_INDICATORS_VERSION_LIST_URL = "indicator/version/list/";
export const ADD_INDICATORS_VERSION_URL = "indicator/version/";
export const EDIT_INDICATORS_VERSION_URL = "indicator/version/";
export const DELETE_INDICATORS_VERSION_URL = "indicator/version/";

export const GET_EA_VERSION_LIST_URL = "EA/version/list/";
export const ADD_EA_VERSION_URL = "EA/version/";
export const EDIT_EA_VERSION_URL = "EA/version/";
export const DELETE_EA_VERSION_URL = "EA/version/";

export const GET_SCRIPT_VERSION_LIST_URL = "scripts/version/list/";
export const ADD_SCRIPT_VERSION_URL = "scripts/version/";
export const EDIT_SCRIPT_VERSION_URL = "scripts/version/";
export const DELETE_SCRIPT_VERSION_URL = "scripts/version/";
export const CHANGE_PASSWORD_URL = "users/change-password";

export const GET_UTILITY_VERSION_LIST_URL = "utility/version/list/";
export const ADD_UTILITY_VERSION_URL = "utility/version/";
export const EDIT_UTILITY_VERSION_URL = "utility/version/";
export const DELETE_UTILITY_VERSION_URL = "utility/version/";

export const GET_INDUSTRY_LIST_URL = "industry/";
export const GET_JOB_LIST_URL = "industry/jobTitle/";
export const GET_REGISTRATION_MATERIAL_URL = "users/register/details/";

export const DELETE_TICKET_LIST_URL = "tickets/";
export const DELETE_TICKET_COMMENT_LIST_URL = "tickets/comment/";

export const GET_ACTIVITY_LIST_URL = "activities";
export const ADD_ACTIVITY_LIST_URL = "activities";
export const GET_ACTIVITY_ATTENDENCE_LIST_URL = "log/activity/attendance";
export const GET_USER_ATTENDENCE_LIST_URL = "users/attendance/";
export const GET_ACTIVITY_DETAILS_URL = "activities/";
export const EDIT_ACTIVITY_DETAILS_URL = "activities/";
export const GET_ACTIVITY_POINTS_URL = "activities/point/";
export const ADD_ACTIVITY_POINTS_URL = "activities/points/add";
export const REGISTER_ACTIVITY_URL = "activities/points/add";

export const CHECK_STUDENT_ACTIVITY_URL = "activities/";

export const GET_LIBRARY_LIST_URL = "library";
export const ADD_LIBRARY_LIST_URL = "library";
export const EDIT_LIBRARY_LIST_URL = "library/";
export const DELETE_LIBRARY_LIST_URL = "library/";

export const GET_LIBRARY_VERSION_URL = "library/version/admin/";
export const ADD_LIBRARY_VERSION_URL = "library/version";
export const EDIT_LIBRARY_VERSION_URL = "library/version/";
export const DELETE_LIBRARY_VERSION_URL = "library/version/";

export const ROLL_CALL_ACTIVITY_URL = "activities/rollcall";
export const GET_ADVERTISMENT_LIST_URL = "/advertisement";
export const GET_ADVERTISMENT_TYPE_LIST_URL = "/advertisement/type";
export const GET_ADVERTISMENT_TYPE_TOOL_LIST_URL = "/tickets/tools/list/";
export const ADD_ADVERTISEMENT_URL = "/advertisement";
export const EDIT_ADVERTISEMENT_URL = "/advertisement";
export const GET_ACTIVITY_ATTENDENCE_LOG_LIST_URL =
  "activities/attendance/list/";

export const GET_RENTS_LIST_URL = "rents";
export const GET_RENTS_CATEGORY_LIST_URL = "rents/type";
export const ADD_RENTS_LIST_URL = "rents";

export const GET_DASHBOARD_COUNT_URL = "users/dashboard/data";
export const GET_DASHBOARD_MEMBERSHIP_EXPIRED_USER_LIST_URL =
  "users/admin/expired/list";

export const GET_GRANTS_LIST_URL = "grants";
export const GRANT_POINT_LIST_URL = "grants/points";
export const GRANT_TOOL_LIST_URL = "grants/tool";
export const GRANT_ACCOUNT_URL = "grants/account";

export const ADD_CLASS_MEMBERSHIP_URL = "membership/classes";
export const ADD_USER_MEMBERSHIP_URL = "membership/users";
export const GRADUATE_ALL_STUDENT_CLASS_URL = "class/graduate/";

export const GET_QR_CODE_URL = "users/me";
export const GET_STUDENT_BY_ID_LIST_URL = "students/info/";
export const ADD_STUDENT_MANAUAL_URL = "students/manual";
export const DELETE_EA_URL = "EA/";
export const DELETE_INDICATOR_URL = "indicator/";
export const DELETE_SCRIPT_URL = "scripts/";
export const DELETE_UTILITY_URL = "utility/";

export const DENIED_MEMEBER_DETAIL_URL = "users/";
export const MODIFY_MEMBER_DETAIL_URL = "users/modify/";
export const ATTENND_ACTIVITY_URL = "students/activity/attendence";

export const GET_USER_CLASS_URL = "students/user/class/list/";
export const GET_USER_CLASS_ROLE_URL = "students/role/";
export const GET_TICKET_ASSIGNEE_LIST_URL = "/tickets/group/list";
export const UPDATE_TICKET_ASSIGNEE_LIST_URL = "/tickets/group/list";
export const GET_USER_PRINT_DETAIL_URL = "users/print/";
export const SET_ALTERNAITVE_NAMES_URL = "users/alt/";
export const SET_MANUAL_PASSWORD_LIST_URL = "users/recovery/password/";

// export const GET_ALERT_COUNT_URL = "v2/patient/alertCounts";

// export const GET_PATIENT_DEVICE_URL = "v2/clinics/patientDevice/devices";

// export const GET_PATIENT_VIEW = "/v2/dashboard/";

// export const GET_PATIENT_VIEW_DASHBOARD = "/v2/patient/";

// // https://test.kencorhealth.com/rx/api/v1/careplan/d2fc855a-8e61-43df-ba8d-4bdc89d7debe
// export const ADD_MANAGE_DEVICE_URL = "v2/user/patientDevice/devices";

// export const DEACTIVATE_PATIENT_URL = "v1/user/activation";

// export const SEND_MESSAGE_URL = "v2/patient/patientID/messageLink";
// export const DELETE_PATIENT_URL = "v2/user/";
// export const GET_DIAGNOSIS_TYPE_URL = "v2/patient/diagnosisTypes";
// export const ADD_APPOINTMENT_URL = "v2/video/appointment";
// export const GET_APPOINTMENT_URL = "v2/video/patient/ID/appointment?filter=upcoming";
// export const GET_VISIT_REASON_URL = "v2/video/visitReason";

// export const GET_ALL_APPOINTMENT_URL = "v2/video/patient?appointmentFilter=TIME&limit=500&pageNumber=1"
// export const EDIT_APPOINTMENT_URL = "v2/video/patient?appointmentFilter=TIME&limit=500&pageNumber=1"
// export const DELETE_APPOINTMENT_URL = "v2/video/patient?appointmentFilter=TIME&limit=500&pageNumber=1"

// // Billing
// export const BILLING = "v2/billingSummary?month=";
// export const TRACKERURL = "v1/timetracking/billingTracker?clinicId=";
// export const BILLINGDETAILS = "v1/timetracking/report?";
// //Message
// export const MESSAGEURL = "v1/user/voicemails";
// export const BROADCASTURL = "v2/sms/mass";
// export const PRE_CALL_TEST_URL = "v2/video/patient/ID/preCallTest";
// export const RESET_PASSWORD_URL = "v2/user/ID/changePassword";
// //vitals

// export const VITAL_URL = "v1/vitals/asMap?userId=";
// export const VITAL_QLIST_URL = "v1/careplan/alert/";
// export const VITAL_COMMENT_ADD_URL = "v1/vitals/";
// // NOTES
// export const NOTE_LIST_URL = "v1/note/"
// export const POST_NOTE_URL = "v1/note"

// export const VITAL_DELETE_URL = "v1/note";
// export const VITAL_ADD_URL = "v1/vitals";
// export const VITAL_COMMENT_URL = "v1/vitals/";
// export const VITAL_COMMENT_RESOLVE_URL = "v1/vitals/resolveComment";
// export const VITAL_BASELINE = "v1/careplan/";
// export const BASELINE_ADD_URL = "v1/careplan/";
// export const VITAL_GRAPH_URL = "v1/vitals/stats?start=";

// //Timeline
// export const PATIENT_TIMELINE_URL = "v1/timeline/summary?patientId=ID&pageNumber=pages&limit=20";

// //Care plan
// export const MEDICATION_CAREPLAN_URL = "v1/medication";
// export const VITAL_ACTIVITIES_CAREPLAN_URL = "v1/careplan/pID";
// export const EDIT_VITAL_ACTIVITIES_CAREPLAN_URL = "v1/careplan/pID/activity";
// export const DELETE_VITAL_ACTIVITIES_CAREPLAN_URL = "v1/careplan/pID";

// export const EDIT_PHYSICAL_ACTIVITIES_CAREPLAN_URL = "v1/careplan/pID/activity";
// export const DELETE_PHYSICAL_ACTIVITIES_CAREPLAN_URL = "v1/careplan/pID/activity";
// export const ADD_PHYSICAL_ACTIVITIES_CAREPLAN_URL = "v1/careplan/pID/activity";
// export const ADD_VITAL_ACTIVITIES_CAREPLAN_URL = "v1/careplan/";
// // https://rpm.kencorhealth.com/rx/api/v1/careplan/48323d07-e661-4c2a-8602-5a6a9955804f/activity
// // https://test.kencorhealth.com/rx/api/v1/careplan/fc3ab5e0-7c90-48e7-9048-ba8cb3fa3504/activity
// //https://test.kencorhealth.com/rx/api/v1/careplan/fc3ab5e0-7c90-48e7-9048-ba8cb3fa3504/activity
// export const ADD_MEDICATION_ACTIVITIES_CAREPLAN_URL = "";
// export const EDIT_MEDICATION_ACTIVITIES_CAREPLAN_URL = "v1/careplan/pID/activity";
// export const DELETE_MEDICATION_ACTIVITIES_CAREPLAN_URL = "v1/careplan/pID/activity";

// export const GET_PATIENT_PRO_URL = "v1/careplan/alert/pid";
// export const CHANGE_PATIENT_STATUS_URL = 'v1/careplan/alert/changeStatus?'

// //https://rpm.kencorhealth.com/rx/api/v1/careplan
// //Billing Details
// export const BILLING_NOTE_URL = "v1/note";
// export const BILLING_ADD_MINUTES_URL = "v1/timetracking/manual";
// export const BILLING_DETAILS_3_URL = "v1/timetracking/trackingVitals?"
// export const BILLING_DETAILS_2_URL = "v1/careplan?"
// //Billing Details In Patient

// export const PATIENT_BILLING_URL = "v2/billingSummary?";
// export const PATIENT_BILLING_DETAILS_1_URL = "v1/patientTimeline?";
// export const PATIENT_BILLING_DETAILS_1_T1_URL = "v1/patientReport?";

// export const PATIENT_BILLING_DETAILS_1_T2_URL = "v1/note/";
// export const PATIENT_BILLING_DETAILS_1_T3_URL = "v2/report/vitals/pdf?";
// export const PATIENT_BILLING_DETAILS_2_URL = "v1/careplan/";
// export const PATIENT_BILLING_DETAILS_3_URL = "v1/timetracking/vitals?";
// export const PATIENT_BILLING_DETAILS_4_URL = "v1/patientReport?";
// export const PATIENT_BILLING_DETAILS_4_T1_URL = "v1/patientTimeline?";
// export const PATIENT_BILLING_DETAILS_5_URL = "v1/patientTimeline?";
// export const PATIENT_BILLING_DETAILS_5_T1_URL = "v1/patientReport?";

// //chat
// export const CHAT_PATIENT_LIST_URL = "v1/dynamicGroup/pid/participants";
// export const CHAT_PATIENT_URL = "v1/dynamicGroup/pid";
// export const CHAT_UPDATE_USER_URL = "v1/user/voicemails/";

export const GET_CLASSES_DETAIL_URL = "v1/user/voicemails/";
export const GET_EMAIL_VERFICATION_LIST_URL = "users/email-verified/list";

export const GET_USER_VPS_MACHINE_ACCESS_LIST_URL = "users/vps/permisson/";
export const GET_VPS_LIST_URL = "winPanel/vps";
export const UPDATE_VPS_STATUS_URL = "vps/vps-machine-status";
export const VPS_RESET_PASSWORD_URL = "vps/vps-machine-password";
export const GET_VPS_MACHINE_TYPE_LIST_URL = "vps/type";
export const ADD_EA_VERSION_TO_ALL_CLASS_URL = "class/tools/allow/all";

export const ADD_REFERAL_URL = "membership/referral";
export const GET_REFERAL_URL = "membership/referral-tree/";
export const SET_ACCOUNT_LIMIT_URL = "users/update/accountLimit/";
export const CREATE_TICKET_GROUP_LIST_URL = "/tickets/group/create";
export const GET_ADMIN_MEMBER_LIST_URL = "/users/admin-user/list?type=admin";
export const ADD_GROUP_MEMBER_LIST_URL = "/users/ticket-group/add";
export const DELETE_TICKET_GROUP_URL = "/tickets/group/delete";
export const DELETE_GROUP_MEMBER_LIST_URL = "/group";
// export const DELETE_TICKET_COMMENT_LIST_URL = ""

export const GET_ALL_BILLS_URL = "/bills/";
export const EDIT_BILL_URL = "/bills/";
export const UPDATE_BILL_URL = "/bills/";
