import { z } from "zod";

export const deleteGemsAdvertisementByIdSchema = z.object({
  params: z.object({
    gemsAdvertisementId: z.coerce.number().int().safe().nonnegative(),
  }),
});

export type DeleteGemsAdvertisementByIdParams = z.infer<
  typeof deleteGemsAdvertisementByIdSchema.shape.params
>;
