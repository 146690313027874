import React from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { FaTimesCircle } from "react-icons/fa";

const ViewFaqCategoryModal = ({ onModalClose, faq }: any) => {
  return (
    <Modal centered show={true} onHide={() => onModalClose(false)}>
      <Modal.Header>
        <h4 className="modal-title" id="AddCourseLabel">
          <FormattedMessage
            id="FAQ.DetailsCategory"
            defaultMessage="Faq Category Details"
          />
        </h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onModalClose}
        >
          <FaTimesCircle />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div>
          {Object.keys(faq).length && (
            <div className="table-responsive">
              <table
                style={{ border: "1px solid #f0f6ff", width: "100%" }}
                className="table table-hover table-striped m-0"
              >
                <tbody>
                  <tr>
                    <td style={{ width: "33%" }}>
                      <label className="ml-3">
                        <FormattedMessage
                          id="table.head.ID"
                          defaultMessage="ID"
                        />
                        :
                      </label>
                    </td>
                    <td style={{ textAlign: "left" }}>{faq.id}</td>
                  </tr>
                  <tr>
                    <td>
                      <label className="ml-3">
                        <FormattedMessage id="Faq.Name" defaultMessage="Name" />
                        :
                      </label>
                    </td>
                    <td style={{ textAlign: "left" }}>{faq.name}</td>
                  </tr>
                  <tr>
                    <td>
                      <label className="ml-3">
                        <FormattedMessage
                          id="Faq.Isactive"
                          defaultMessage="Isactive"
                        />
                      </label>
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {faq?.isActive ? "active" : "inactive"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary"
          style={{ display: "inherit" }}
          onClick={() => onModalClose()}
          data-dismiss="modal"
        >
          <FormattedMessage id="page.close" defaultMessage="Close" />
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewFaqCategoryModal;
