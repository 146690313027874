import React, { useEffect, useState } from "react";
import { useFormik } from "formik";

import { useDispatch, useSelector } from "react-redux";
import {
  AddEAVersionToAllClass,
  editAnnouncements,
} from "../../../redux/action";
import Loader from "../../common/Loader";
import Select from "react-select";
import { EditToolClassSchema } from "../../../util/validationSchema";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import { Store } from "../../../redux/Actions";
import { FaTimesCircle } from "react-icons/fa";

const EditToolClassModal = ({
  onModalClose,
  classList,
  // isAPILoading,
  selectedClass,
  toolId,
  TOOL,
}: any) => {
  const dispatch = useDispatch();
  const urlParams = useParams();

  const [SelectedClasses, setSelectedClasses] = useState<File>();
  const [classes, setClasses] = useState<any>();
  const [ButtonClick, setButtonClick] = useState(false);
  const isAPILoading = useSelector(
    (state: Store) => state.expertAdvisorReducer.isLoading,
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      class_group: [],
      toAll: false,
    },
    validationSchema: EditToolClassSchema,
    onSubmit: async (values) => {
      if (!ButtonClick) {
        const { id } = urlParams;

        if (values.class_group.length <= 0 && values.toAll === false) {
          toast.error("class group is required");
        } else {
          // const imgs: any = announcementsDetails.attechment.map(
          //   (i: any) => i.attachmentPath
          // );
          const all: boolean = classes.length === values.class_group.length;
          const temp = {
            class_group: values.class_group,
            toAll: all,
            toolId: toolId,
            tool: TOOL,
          };

          dispatch(AddEAVersionToAllClass(true, temp));

          // dispatch(editAnnouncements(true, id, temp));
          setButtonClick(true);
        }
      }
    },
  });

  useEffect(() => {
    if (!isAPILoading && ButtonClick) {
      onModalClose();
    }
  }, [isAPILoading]);

  useEffect(() => {
    const cl: any = [{ id: 0, name: "All" }, ...classList];
    setClasses(cl);
  }, [classList]);

  useEffect(() => {
    setSelectedClasses(selectedClass);
  }, [selectedClass]);

  const onClassChange = (option: any) => {
    if (option.length > 0 && option[option.length - 1].id == 0) {
      const cl = Object.assign([], classes);
      cl.shift();
      setSelectedClasses(cl);
      const ids: number[] = classes.map((i: any) => i.id);
      formik.setFieldValue("class_group", ids);
    } else {
      setSelectedClasses(option);
      const ids = option.map((i: any) => i.id);
      formik.setFieldValue("class_group", ids);
    }
  };

  const { touched, errors } = formik;
  return (
    <Modal
      centered
      scrollable
      show={true}
      onHide={() => {
        onModalClose(false);
      }}
      data-modal="scroll"
    >
      <Modal.Header>
        <h4 className="modal-title">
          <FormattedMessage
            id="modal.head.AddToolClass"
            defaultMessage="Make an Annoucement"
          />{" "}
        </h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onModalClose}
        >
          <FaTimesCircle />
        </button>
      </Modal.Header>
      <Modal.Body style={{ minHeight: "200px" }}>
        <div className="form-group">
          <label className="control-label">
            {" "}
            <FormattedMessage
              id="modal.head.selectClass"
              defaultMessage="Select class"
            />
          </label>
          <Select
            name="class_group"
            value={SelectedClasses}
            onChange={(option: any) => onClassChange(option)}
            onBlur={() => formik.setFieldTouched("class_group", true)}
            options={classes}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id}
            isMulti
            className="custom-select-dropdown multi w-100p"
            classNamePrefix="react-select-dropdown"
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
          {errors.class_group && touched.class_group && (
            <div className="text-error">
              {" "}
              <FormattedMessage
                id={errors.class_group.toString()}
                defaultMessage="require feild"
              />
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        {isAPILoading ? (
          <button
            disabled
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            {" "}
            <FormattedMessage
              id="annousmentmodal.Cancel"
              defaultMessage="Cancel"
            />
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={onModalClose}
          >
            {" "}
            <FormattedMessage
              id="annousmentmodal.Cancel"
              defaultMessage="Cancel"
            />
          </button>
        )}

        {isAPILoading ? (
          <button
            type="button"
            className="btn btn-primary"
            disabled
            data-dismiss="modal"
          >
            <FormattedMessage
              id="annousmentmodal.Submit"
              defaultMessage="Submit"
            />
            {isAPILoading && <Loader />}
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => formik.handleSubmit()}
            data-dismiss="modal"
          >
            <FormattedMessage
              id="annousmentmodal.Submit"
              defaultMessage="Submit"
            />
            {isAPILoading && <Loader />}
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default EditToolClassModal;
