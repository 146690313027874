import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import NoDatFound from "../../Components/common/NoDatFound";
import DeletePopup from "../../Components/common/Popups/DeletePopup";
import AddScriptsModal from "../../Components/Scripts/AddScriptsModal";
import { getAdminRolesList, getClasses } from "../../redux/action";
import {
  deleteScript,
  getScriptsList,
  setScriptCurrentPage,
} from "../../redux/action/scriptsActions";
import { Store } from "../../redux/Actions";
import { FormattedMessage } from "react-intl";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import Pagination from "../../Components/common/Paginate/Pagination";
import { Dropdown } from "react-bootstrap";
import { AppendedMyComponent } from "../../Components/append-to-body-drop-down";
import Script from "../../assets/img/Script.svg";
import EditToolClassModal from "../../Components/common/Popups/EditToolClassModal";
import { TOOLS, TOOLS_TICKET } from "../../Constants";
import { ClassesIcon } from "../../util/iconStyle";
import { BsSortDown, BsSortUp } from "react-icons/bs";
import { FaEdit, FaTrash } from "../../reactIcons";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

const Scripts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [scriptModalShow, setScriptsModalShow] = useState(false);
  const [edtScripts, setEdtScripts] = useState(null);
  const [delModalShow, setDelModalShow] = useState({ modal: false, id: 0 });
  const [addToAllClass, setAddToAllClass] = useState({ modal: false, id: 0 });

  const scriptsList: any = useSelector(
    (state: Store) => state.scriptsReducer.scriptsList,
  ) as any;
  const rolesLists: any = useSelector(
    (state: Store) => state.adminRolesReducer.adminRoleList,
  ) as any;

  const isLoading = useSelector(
    (state: Store) => state.scriptsReducer.isLoading,
  );
  const [pageNumber, setPageNumber] = useState<any>();
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const totalRecord = useSelector(
    (state: Store) => state.scriptsReducer.totalRecord,
  ) as any;
  const currentPage = useSelector(
    (state: Store) => state.scriptsReducer.currentPage,
  );

  const classes = useSelector(
    (state: Store) => state.classReducer.classes || [],
  );

  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [rolesList, setRoleList] = useState([]);

  useEffect(() => {
    const r: any = [];
    rolesLists.map((i: any) => {
      if (i.isEARole) {
        r.push(i);
      }
    });
    setRoleList(r);
  }, [rolesLists]);
  // useEffect(() => {
  //   // dispatch(getEAList(false, { by: sortBy, order: sortOrder }))
  //   // dispatch(getEAUnauthorizedList(false))
  //   // dispatch(getActivityList(false, { by: sortBy, order: sortOrder }, pageNumber));
  //   dispatch(
  //     getScriptsList(false, pageNumber, { by: sortBy, order: sortOrder }, pageLimit)
  //   );
  // }, [sortOrder, sortBy]);

  const setSorting = (prop: any) => {
    if (sortBy === prop) {
      setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
    } else {
      setSortBy(prop);
      setSortOrder("ASC");
    }
  };

  useEffect(() => {
    dispatch(setScriptCurrentPage(1));
    dispatch(getClasses(false, 0, { order: "DESC", by: "createdAt" }));
  }, []);

  const onPageChange = (pageNum: number) => {
    localStorage.setItem("pageNumber", pageNum.toString());
    localStorage.setItem("pageLimit", pageLimit.toString());
    setPageNumber(pageNum);
    dispatch(setScriptCurrentPage(pageNum));
  };

  // useEffect(() => {
  //   setPageNumber(currentPage);
  // }, [currentPage]);

  useEffect(() => {
    dispatch(
      getScriptsList(
        false,
        pageNumber,
        { order: "DESC", by: "createdAt" },
        pageLimit,
      ),
    );
  }, [pageNumber, pageLimit, sortOrder, sortBy]);

  useEffect(() => {
    dispatch(getAdminRolesList(false));
    const storedPageNumber = localStorage.getItem("pageNumber");
    const storedPageLimit = localStorage.getItem("pageLimit");
    if (storedPageNumber && storedPageLimit) {
      setPageNumber(storedPageNumber);
      setPageLimit(storedPageLimit);
    } else {
      setPageNumber(1);
      setPageLimit(10);
    }
  }, []);

  const editScriptsSelected = (scriptDetail: any) => {
    setEdtScripts(scriptDetail);
    setScriptsModalShow(true);
  };

  const viewScriptsDetail = (id: any) => {
    localStorage.setItem("pageNumber", pageNumber);
    localStorage.setItem("pageLimit", pageLimit);
    navigate("/scripts/" + id);
  };

  const deleteScr = () => {
    dispatch(deleteScript(true, delModalShow.id));
    setDelModalShow({ modal: false, id: 0 });
  };
  return (
    <>
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                <FormattedMessage id="Admin.Script" defaultMessage="Scripts" />
              </h1>
            </div>
            {/* <div className="m-l-10">
                            <div className="input-group w-250">
                                <input type="text" className="form-control" placeholder="Search" title="Search" />
                                <div className="input-group-append">
                                    <button type="button" className="input-group-text pointer"><span className="fa fa-search"></span></button>
                                </div>
                            </div>
                        </div> */}

            {/* <div className="m-l-10">
                            <select className="form-control select2 w-150">
                                <option value="">-- Status --</option>
                                <option>All</option>
                                <option>Active</option>
                                <option>Inactive</option>
                            </select>
                        </div>
                        <div className="m-l-10">
                            <button type="button" className="btn btn-primary2">
                                Submit
                                    </button>
                        </div>
                        <div className="m-l-10">
                            <button type="button" className="btn btn-secondary">
                                Reset
                                    </button>
                        </div> */}
            <div className="m-l-10 common-right-button">
              <a
                href="#"
                data-toggle="modal"
                data-target="#AddCourse"
                type="button"
                onClick={() => {
                  setScriptsModalShow(true);
                  setEdtScripts(null);
                }}
                className="btn btn-primary"
              >
                <FormattedMessage
                  id="Scripts.Addscript"
                  defaultMessage="Add Script "
                />
              </a>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          <div className="card m-0">
            <div className="card-body p-0">
              {isLoading && <CenterLoader />}

              <div className="table-responsive">
                {scriptsList && scriptsList.length ? (
                  <>
                    {" "}
                    <table className="table table-hover m-0">
                      <thead>
                        <tr>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("id")}
                          >
                            <FormattedMessage
                              id="table.head.ID"
                              defaultMessage="ID"
                            />
                            {sortBy === "id" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("name")}
                          >
                            <FormattedMessage
                              id="table.head.Name"
                              defaultMessage="Name"
                            />
                            {sortBy === "name" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("cost")}
                          >
                            <FormattedMessage
                              id="table.head.Cost"
                              defaultMessage="Cost"
                            />
                            {sortBy === "cost" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("originalPrice")}
                          >
                            <FormattedMessage
                              id="table.head.originalPrice"
                              defaultMessage="Original Price"
                            />
                            {sortBy === "originalPrice" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("sellingPrice")}
                          >
                            <FormattedMessage
                              id="table.head.sellingPrice"
                              defaultMessage="Selling Price"
                            />
                            {sortBy === "sellingPrice" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th>
                            <FormattedMessage
                              id="table.head.Image"
                              defaultMessage="Image"
                            />
                          </th>
                          <th>
                            <FormattedMessage
                              id="table.head.AddAllClass"
                              defaultMessage="Image"
                            />
                          </th>
                          <th className="table-field-status">
                            <FormattedMessage
                              id="table.head.Actions"
                              defaultMessage="Action"
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* <?<5;$i++) { ?> */}
                        {Object.keys(scriptsList || {}).length &&
                          scriptsList.map((i: any) => (
                            <tr>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() => viewScriptsDetail(i.id)}
                              >
                                {i.id}
                              </td>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() => viewScriptsDetail(i.id)}
                              >
                                {i.name}
                              </td>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() => viewScriptsDetail(i.id)}
                              >
                                {i.cost}
                              </td>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() => viewScriptsDetail(i.id)}
                              >
                                {i.originalPrice}
                              </td>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() => viewScriptsDetail(i.id)}
                              >
                                {i.sellingPrice}
                              </td>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() => viewScriptsDetail(i.id)}
                              >
                                <img
                                  className="img-thumbanail"
                                  src={i?.image_path || Script}
                                />
                              </td>
                              <td style={{ cursor: "pointer" }}>
                                <button
                                  style={{
                                    background: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    setAddToAllClass({ modal: true, id: i.id })
                                  }
                                >
                                  <ClassesIcon />{" "}
                                </button>
                              </td>

                              <td className="table-field-status">
                                <Dropdown className="btn-group">
                                  <Dropdown.Toggle
                                    id="dropdown-basic"
                                    className="btn btn-sm btn-icon-only"
                                  >
                                    <i className="icon dripicons-dots-3 zmdi-hc-fw"></i>
                                  </Dropdown.Toggle>
                                  <AppendedMyComponent>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        href="#"
                                        onClick={() => editScriptsSelected(i)}
                                      >
                                        <i className="text-accent-custom">
                                          <FaEdit />{" "}
                                        </i>
                                        <FormattedMessage
                                          id="page.Edit"
                                          defaultMessage="Edit"
                                        />
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        href="#"
                                        onClick={() =>
                                          setDelModalShow({
                                            modal: true,
                                            id: i.id,
                                          })
                                        }
                                      >
                                        <i className="fa-fw text-accent-custom">
                                          <FaTrash />{" "}
                                        </i>
                                        <FormattedMessage
                                          id="page.Delete"
                                          defaultMessage="Delete"
                                        />
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </AppendedMyComponent>
                                </Dropdown>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <p>{!isLoading && <NoDatFound />}</p>
                )}
              </div>
              {totalRecord > 10 && scriptsList.length && (
                <>
                  <FormControl className="pagelimit">
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      <FormattedMessage
                        id="page_size"
                        defaultMessage="Page Size"
                      />
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={pageLimit}
                      onChange={(e: any) => {
                        setPageLimit(e.target.value);
                        localStorage.setItem("pageLimit", e.target.value);
                        localStorage.setItem("pageNumber", pageNumber);
                        setPageNumber(1);
                      }}
                    >
                      <FormControlLabel
                        value={10}
                        control={<Radio />}
                        label="10"
                      />
                      <FormControlLabel
                        value={20}
                        control={<Radio />}
                        label="20"
                      />
                      <FormControlLabel
                        value={50}
                        control={<Radio />}
                        label="50"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Pagination
                    itemsPerPage={pageLimit}
                    totalPages={totalRecord}
                    onPageChage={onPageChange}
                    page={pageNumber - 1}
                  />
                </>
              )}
            </div>
          </div>
        </section>
      </div>

      {delModalShow.modal && (
        <DeletePopup
          onClose={() => setDelModalShow({ modal: false, id: 0 })}
          onDeleteClick={deleteScr}
          open={delModalShow.modal}
        />
      )}
      {scriptModalShow && (
        <AddScriptsModal
          onModalClose={() => setScriptsModalShow(false)}
          editScripts={edtScripts}
          rolesList={rolesList}
        />
      )}
      {addToAllClass.modal && (
        <EditToolClassModal
          onModalClose={() => setAddToAllClass({ modal: false, id: 0 })}
          //  onDeleteClick={() => graduateStudent(grdClass.id)}
          // open={grdClass.modal}
          //  isAPILoading={isLoading}
          selectedClass={[]}
          classList={classes}
          toolId={addToAllClass.id}
          TOOL={TOOLS_TICKET.SCRIPTS}
        />
      )}
    </>
  );
};

export default Scripts;
