import { RawCreateParams, z, ZodEffects, ZodTypeAny } from "zod";

export const parseStringToJson = <I extends ZodTypeAny>(
  schema: I,
  params?: RawCreateParams,
): ZodEffects<I, I["_output"], unknown> =>
  z.preprocess(
    (val, ctx) => {
      try {
        if (typeof val === "string") {
          return JSON.parse(val);
        }
        return {};
      } catch (e) {
        ctx.addIssue({ code: "custom", message: "Invalid JSON" });
        return z.NEVER;
      }
    },
    schema,
    params,
  );
