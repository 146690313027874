/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/APIServices/baseApiCall";
import {
  ADD_CLASS_MEMBERSHIP,
  ADD_REFERAL,
  ADD_USER_MEMBERSHIP,
  GET_REFERAL,
  SET_ALTERNAITVE_NAMES,
  SET_MANUAL_PASSWORD_LIST,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { setToken } from "../../util/utils";
import {
  ADD_CLASS_MEMBERSHIP_URL,
  ADD_REFERAL_URL,
  ADD_USER_MEMBERSHIP_URL,
  GET_REFERAL_URL,
  SET_ALTERNAITVE_NAMES_URL,
  SET_MANUAL_PASSWORD_LIST_URL,
} from "../../service/APIServices/apiEndPoints";
import { toast } from "react-toastify";
// import { _getExpressWayPendingList } from "../../service";
// import { AxiosResponse } from "axios";
//Get Admin Roles List

//Get Admin Roles List
export const addClassMembershipList =
  (
    showToast = true,
    rent: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(addClassMembershipListInit(showToast, rent));
  };

export const addClassMembershipListInit =
  (
    showToast = true,
    rent: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_CLASS_MEMBERSHIP.ADD_CLASS_MEMBERSHIP_INITIALIZATION,
    });
    apiCall(
      ADD_CLASS_MEMBERSHIP_URL,
      rent,
      (res: Object) => dispatch(addClassMembershipListSuccess(res)),
      (err: any) => dispatch(addClassMembershipListError()),
      METHOD.POST,
      { showToast },
    );
  };

export const addClassMembershipListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_CLASS_MEMBERSHIP.ADD_CLASS_MEMBERSHIP_SUCCESS,
      payload: res.data,
    });
  };

export const addClassMembershipListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_CLASS_MEMBERSHIP.ADD_CLASS_MEMBERSHIP_ERROR,
    });
  };

//Get Admin Roles List
export const addUserRefaralList =
  (
    showToast = true,
    rent: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(addUserRefaralListInit(showToast, rent));
  };

export const addUserRefaralListInit =
  (
    showToast = true,
    rent: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_REFERAL.ADD_REFERAL_INITIALIZATION,
    });
    apiCall(
      ADD_REFERAL_URL,
      rent,
      (res: Object) => dispatch(addUserRefaralListSuccess(res)),
      (err: any) => dispatch(addUserRefaralListError()),
      METHOD.POST,
      { showToast },
    );
  };

export const addUserRefaralListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_REFERAL.ADD_REFERAL_SUCCESS,
      payload: res.data,
    });
  };

export const addUserRefaralListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_REFERAL.ADD_REFERAL_ERROR,
    });
  };

//Get Admin Roles List
export const getUserRefaralList =
  (
    showToast = true,
    userId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getUserRefaralListInit(showToast, userId));
  };

export const getUserRefaralListInit =
  (
    showToast = true,
    userId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_REFERAL.GET_REFERAL_INITIALIZATION,
    });
    apiCall(
      GET_REFERAL_URL + userId,
      {},
      (res: Object) => dispatch(getUserRefaralListSuccess(res)),
      (err: any) => dispatch(getUserRefaralListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getUserRefaralListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_REFERAL.GET_REFERAL_SUCCESS,
      payload: res.count,
    });
  };

export const getUserRefaralListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_REFERAL.GET_REFERAL_ERROR,
    });
  };

//Get Admin Roles List
export const addUserMembershipList =
  (
    showToast = true,
    rent: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(addUserMembershipListInit(showToast, rent));
  };

export const addUserMembershipListInit =
  (
    showToast = true,
    rent: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_USER_MEMBERSHIP.ADD_USER_MEMBERSHIP_INITIALIZATION,
    });
    apiCall(
      ADD_USER_MEMBERSHIP_URL,
      rent,
      (res: Object) => dispatch(addUserMembershipListSuccess(res)),
      (err: any) => dispatch(addUserMembershipListError()),
      METHOD.POST,
      { showToast },
    );
  };

export const addUserMembershipListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_USER_MEMBERSHIP.ADD_USER_MEMBERSHIP_SUCCESS,
      payload: res.data,
    });
  };

export const addUserMembershipListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_USER_MEMBERSHIP.ADD_USER_MEMBERSHIP_ERROR,
    });
  };

//Get Admin Roles List
export const setAlternativeNameList =
  (
    showToast = true,
    rent: any,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(setAlternativeNameListInit(showToast, rent, id));
  };

export const setAlternativeNameListInit =
  (
    showToast = true,
    rent: any,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: SET_ALTERNAITVE_NAMES.SET_ALTERNAITVE_NAMES_INITIALIZATION,
    });
    apiCall(
      SET_ALTERNAITVE_NAMES_URL + id,
      rent,
      (res: Object) => dispatch(setAlternativeNameListSuccess(res)),
      (err: any) => dispatch(setAlternativeNameListError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const setAlternativeNameListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: SET_ALTERNAITVE_NAMES.SET_ALTERNAITVE_NAMES_SUCCESS,
      payload: res.data,
    });
  };

export const setAlternativeNameListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: SET_ALTERNAITVE_NAMES.SET_ALTERNAITVE_NAMES_ERROR,
    });
  };

export const setPasswordManual =
  (
    showToast = true,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(setPasswordManualInit(showToast, id));
  };

export const setPasswordManualInit =
  (
    showToast = true,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: SET_MANUAL_PASSWORD_LIST.SET_MANUAL_PASSWORD_LIST_INITIALIZATION,
    });
    apiCall(
      SET_MANUAL_PASSWORD_LIST_URL + id,
      {},
      (res: Object) => dispatch(setPasswordManualSuccess(res)),
      (err: any) => dispatch(setPasswordManualError()),
      METHOD.GET,
      { showToast },
    );
  };

export const setPasswordManualSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: SET_MANUAL_PASSWORD_LIST.SET_MANUAL_PASSWORD_LIST_SUCCESS,
      payload: res.data,
    });

    // dispatch(getTicketList(false));
  };

export const setPasswordManualError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: SET_MANUAL_PASSWORD_LIST.SET_MANUAL_PASSWORD_LIST_ERROR,
    });
  };
