import { ReactNode } from "react";
import { componentWillAppendToBody } from "react-append-to-body";

const MyComponent = ({ children }: { children: ReactNode }) => {
  return (
    <div className="table-field-actions">
      <div className="btn-group dropdown">{children}</div>
    </div>
  );
};

export const AppendedMyComponent = componentWillAppendToBody(MyComponent);
