import { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { BsChevronLeft } from "../../reactIcons";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import { Button } from "@mui/material";
import { Store } from "../../redux/Actions";
import { useFormik } from "formik";
import { updateBillList } from "../../redux/action";
import { dispatch } from "../../redux";
import { editBillList } from "../../redux/action";
import moment from "moment";

const BillDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const isLoading = useSelector(
    (state: Store) => state.billReducer.isLoading,
  ) as any;

  const BillDetails: any = useSelector(
    (state: Store) => state.billReducer.BillDetails,
  ) as any;

  useEffect(() => {
    dispatch(editBillList(false, id));
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      paymentIntentId: BillDetails?.paymentIntentId,
      paymentId: BillDetails?.paymentId,
      processed: BillDetails?.processed,
      userInputPeriodInMonths: BillDetails?.userInputPeriodInMonths,
      userInputVpsTypeId: BillDetails?.userInputVpsTypeId,
      userInputEmail: BillDetails?.userInputEmail,
      userInputVpsId: BillDetails?.userInputVpsId,
      vpsExpiryDateBefore: BillDetails?.vpsExpiryDateBefore
        ? moment(BillDetails?.vpsExpiryDateBefore).format("YYYY-MM-DD HH:mm:ss")
        : "",
    },
    onSubmit: async (values) => {
      const processed = { processed: values.processed };
      dispatch(updateBillList(processed, BillDetails?.id, true));
    },
  });

  const { errors, touched, values, handleBlur, handleChange } = formik;

  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                <FormattedMessage
                  id="head.AdminBillsDetails"
                  defaultMessage="Admin Bills Details"
                />
              </h1>
            </div>
            <div className="m-l-10 common-right-button">
              <button
                onClick={() => {
                  navigate(-1);
                }}
                className="btn btn-secondary"
              >
                <i>
                  <BsChevronLeft /> &nbsp;
                </i>
                <FormattedMessage id="page.back" defaultMessage="Back" />
              </button>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          <Card className="m-0">
            <Card.Body className="p-0">
              <div className="p-4">
                {isLoading && <CenterLoader />}
                <Row className="mb-4 align-items-center">
                  <Col>
                    <h4 className="font-weight-500">
                      <FormattedMessage
                        id="head.BillInformation"
                        defaultMessage="Bill Information"
                      />
                    </h4>
                  </Col>
                </Row>
                <Card className="m-0 shadow-none border">
                  <Card.Body>
                    <Row>
                      <Col md={6} className="col-12">
                        <div className="form-group bill-form-style">
                          <label>
                            {" "}
                            <FormattedMessage
                              id="table.head.PaymentIntentId"
                              defaultMessage="Payment Intent ID"
                            />
                          </label>{" "}
                          <input
                            type="text"
                            name="paymentIntentId"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.paymentIntentId}
                            className="form-control form-control-md form-group"
                            readOnly
                          />
                          {errors.paymentIntentId &&
                            touched.paymentIntentId && (
                              <div className="text-error">
                                <FormattedMessage
                                  id={errors.paymentIntentId.toString()}
                                  defaultMessage="This field is required"
                                />
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col md={6} className="col-12">
                        <div className="form-group bill-form-style">
                          <label>
                            {" "}
                            <FormattedMessage
                              id="table.head.PaymentId"
                              defaultMessage="Payment ID"
                            />
                          </label>{" "}
                          <input
                            type="text"
                            name="paymentIntentId"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.paymentId}
                            className="form-control form-control-md form-group"
                            readOnly
                          />
                          {errors.paymentId && touched.paymentId && (
                            <div className="text-error">
                              <FormattedMessage
                                id={errors.paymentId.toString()}
                                defaultMessage="This field is required"
                              />
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col md={6} className="col-12">
                        <div className="form-group bill-form-style">
                          <label>
                            {" "}
                            <FormattedMessage
                              id="table.head.UserInputVpsTypeId"
                              defaultMessage="User Input VPS Type ID"
                            />
                          </label>{" "}
                          <input
                            type="text"
                            name="userInputVpsTypeId"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.userInputVpsTypeId}
                            className="form-control form-control-md form-group"
                            readOnly
                          />
                          {errors.userInputVpsTypeId &&
                            touched.userInputVpsTypeId && (
                              <div className="text-error">
                                <FormattedMessage
                                  id={errors.userInputVpsTypeId.toString()}
                                  defaultMessage="This field is required"
                                />
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col md={6} className="col-12">
                        <div className="form-group bill-form-style">
                          <label>
                            <FormattedMessage
                              id="table.head.UserInputPeriodInMonths"
                              defaultMessage="User Input Period In Months"
                            />
                          </label>{" "}
                          <input
                            type="text"
                            name="userInputPeriodInMonths"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.userInputPeriodInMonths}
                            className="form-control form-control-md form-group"
                            readOnly
                          />
                          {errors.userInputPeriodInMonths &&
                            touched.userInputPeriodInMonths && (
                              <div className="text-error">
                                <FormattedMessage
                                  id={errors.userInputPeriodInMonths.toString()}
                                  defaultMessage="This field is required"
                                />
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col md={6} className="col-12">
                        <div className="form-group">
                          <label>
                            {" "}
                            <FormattedMessage
                              id="table.head.UserInputEmail"
                              defaultMessage="User Input Email"
                            />
                          </label>{" "}
                          <input
                            type="text"
                            name="userInputEmail"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.userInputEmail}
                            className="form-control form-control-md form-group"
                            readOnly
                          />
                          {errors.userInputEmail && touched.userInputEmail && (
                            <div className="text-error">
                              <FormattedMessage
                                id={errors.userInputEmail.toString()}
                                defaultMessage="This field is required"
                              />
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col md={6} className="col-12">
                        <div className="form-group">
                          <label>
                            {" "}
                            <FormattedMessage
                              id="table.head.UserInputVpsId"
                              defaultMessage="User Input VPS ID"
                            />
                          </label>{" "}
                          <input
                            type="text"
                            name="userInputVpsId"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.userInputVpsId}
                            className="form-control form-control-md form-group"
                            readOnly
                          />
                          {errors.userInputVpsId && touched.userInputVpsId && (
                            <div className="text-error">
                              <FormattedMessage
                                id={errors.userInputVpsId.toString()}
                                defaultMessage="This field is required"
                              />
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col md={6} className="col-12">
                        <div className="form-group">
                          <label>
                            <FormattedMessage
                              id="table.head.VpsExpiryDateBefore"
                              defaultMessage="Vps Expiry Date Before"
                            />
                          </label>{" "}
                          <input
                            type="text"
                            name="vpsExpiryDateBefore"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.vpsExpiryDateBefore}
                            className="form-control form-control-md form-group"
                            readOnly
                          />
                          {errors.vpsExpiryDateBefore &&
                            touched.vpsExpiryDateBefore && (
                              <div className="text-error">
                                <FormattedMessage
                                  id={errors.vpsExpiryDateBefore.toString()}
                                  defaultMessage="This field is required"
                                />
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col md={6} className="col-12">
                        <div className="form-group">
                          <label>
                            {" "}
                            <FormattedMessage
                              id="table.head.Processed"
                              defaultMessage="Processed"
                            />
                          </label>{" "}
                          <input
                            type="checkbox"
                            className="form-control form-control-md form-group input-checkbox"
                            checked={values.processed}
                            name="processed"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "processed",
                                e.target.checked,
                              );
                            }}
                          />
                          {errors.processed && touched.processed && (
                            <div className="text-error">
                              <FormattedMessage
                                id={errors.processed.toString()}
                                defaultMessage="This field is required"
                              />
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => formik.handleSubmit()}
                        >
                          <FormattedMessage
                            id="page.Save"
                            defaultMessage="Save"
                          />
                        </Button>{" "}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            </Card.Body>
          </Card>
        </section>
      </div>
    </div>
  );
};

export default BillDetails;
