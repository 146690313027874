import moment from "moment";
import * as Yup from "yup";
import { UserType } from "./constants/UserGroup";

export const loginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const AddClassSchema = Yup.object().shape({
  name: Yup.string().required("classmodal.name.required"),
  courseId: Yup.string(),
  // info: Yup.string(),//.required("classmodal.names.required"),
  classCategory: Yup.number().integer().moreThan(0, "classmodal.type.required"),
  initDemoLimit: Yup.number()
    .integer()
    .moreThan(-1, "classmodal.inlimit.required")
    .default(0)
    .max(9999999999, "values.number.length"),
  // initDemoLimit: Yup.string()
  //   .trim()
  //   .matches(positiveNumberRegExp, "number.positive")
  //   .required("classmodal.inlimit.required"),
  closeApplicationDate: Yup.date().required("grantTime.required"),
  initLiveLimit: Yup.number()
    .integer()
    .moreThan(-1, "classmodal.demo.required")
    .max(9999999999, "values.number.length"),
  // initLiveLimit: Yup.string()
  //   .trim()
  //   .matches(positiveNumberRegExp, "number.positive")
  //   .required("classmodal.demo.required"),
  initExpiryDays: Yup.number()
    .integer()
    .moreThan(-1, "classmodal.days.required")
    .max(9999999999, "values.number.length"),
  // initExpiryDays: Yup.string()
  //   .trim()
  //   .matches(positiveNumberRegExp, "number.positive")
  //   .required("classmodal.days.required"),
  initRepeaterExpiryDays: Yup.number()
    .integer()
    .moreThan(-1, "classmodal.expiryday.required")
    .max(9999999999, "values.number.length"),
  // initRepeaterExpiryDays: Yup.string()
  //   .trim()
  //   .matches(positiveNumberRegExp, "number.positive")
  //   .required("classmodal.expiryday.required"),
  limit: Yup.number()
    .integer()
    .moreThan(0, "classmodal.Limit.required")
    .max(9999999999, "values.number.length"),
  // limit: Yup.string()
  //   .trim()
  //   .matches(graterThanZeroRegExp, "number.positive")
  //   .required("classmodal.Limit.required"),
  cost: Yup.number()
    .integer()
    .moreThan(-1, "classmodal.Cost.required")
    .max(9999999999, "values.number.length"),
  // cost: Yup.string()
  //   .trim()
  //   .matches(positiveNumberRegExp, "number.positive")
  //   .required("classmodal.Cost.required"),
  originalPrice: Yup.number()
    .integer()
    .moreThan(-1, "classmodal.Originl.required")
    .max(9999999999, "values.number.length"),
  // originalPrice: Yup.string()
  //   .trim()
  //   .matches(positiveNumberRegExp, "number.positive")
  //   .required("classmodal.Originl.required"),
  sellingPrice: Yup.number()
    .integer()
    .moreThan(-1, "classmodal.Selling.required")
    .max(9999999999, "values.number.length"),
  // sellingPrice: Yup.string()
  //   .trim()
  //   .matches(positiveNumberRegExp, "number.positive")
  //   .required("classmodal.Selling.required"),
});

export const AddAdvertisementSchema = Yup.object().shape({
  name: Yup.string().required("ad.name.required").max(255, "head.maxLength"),
  details: Yup.string().required("ad.detail.required"),
  tool: Yup.number().integer().moreThan(0, "ad.tool.required"),
  type: Yup.number().integer().moreThan(0, "ad.type.required"),
  advertisementImage: Yup.mixed().required("ad.image.required"),
});

const altName = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;

export const AddGroupSchema = Yup.object().shape({
  group_name: Yup.string()
    .required("msg.required")
    .matches(altName, "onlyAlphabet"),
});

export const AddAnnouncementSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required("announcementModal.Title.required")
    .max(255, "head.maxLength"),
  content: Yup.string().trim().required("announcementModal.Content.required"),
  group: Yup.boolean().default(false),
  class_group: Yup.array().when("group", {
    is: false,
    then: Yup.array().min(1, "activitymodal.Class.required"),
  }),
  groupIds: Yup.array().when("group", {
    is: true,
    then: Yup.array().min(1, "activitymodal.Class.required"),
  }),
  // .min(1, "activitymodal.Class.required"),
});

export const EditToolClassSchema = Yup.object().shape({
  class_group: Yup.array().min(1, "msg.required").required("msg.required"),
});

export const AddMemberSchema = Yup.object().shape({
  members: Yup.array().min(1, "msg.required").required("msg.required"),
});

export const AddProductSchema = Yup.object().shape({
  name: Yup.string().required("Class name is required"),
  productType: Yup.number()
    .integer()
    .moreThan(0, "Init demo limit is required"),
  cost: Yup.number().integer().moreThan(0, "Init demo limit is required"),
  sellingPrice: Yup.number()
    .integer()
    .moreThan(0, "Init demo limit is required"),
  originalPrice: Yup.number()
    .integer()
    .moreThan(0, "Init demo limit is required"),
  productInfo: Yup.number()
    .integer()
    .moreThan(0, "Init demo limit is required"),
  grantTime: Yup.number().integer().moreThan(0, "Init demo limit is required"),
});

export const AddClassToolSchema = Yup.object().shape({
  tool: Yup.number().integer().moreThan(-1, "ad.type.required"),
  toolId: Yup.number().integer().moreThan(0, "ad.tool.required"),
});

export const AddClassToolVersionSchema = Yup.object().shape({
  tool: Yup.number().integer().moreThan(-1, "ad.type.required"),
  toolId: Yup.number().integer().moreThan(0, "ad.tool.required"),
  versionId: Yup.number().integer().moreThan(0, "version.required"),
});

export const AddEASchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required("expertAdvi.Name.required")
    .max(255, "head.maxLength"),
  info: Yup.string().trim().required("expertAdvi.Info.required"),
  cost: Yup.number()
    .min(0, "value.positive")
    .max(9999999999, "values.number.length")
    .required("expertAdvi.Cost.required"),
  sellingPrice: Yup.number()
    .min(0, "value.positive")
    .required("expertAdvi.Selling.required")
    .max(9999999999, "values.number.length"),
  originalPrice: Yup.number()
    .min(0, "value.positive")
    .required("expertAdvi.Original.required")
    .max(9999999999, "values.number.length"),
  belong: Yup.array()
    .min(1, "expertAdvi.onerole.require")
    .required("expertAdvi.onerole.require"),
  indicatorLikeValidation: Yup.boolean(),
  wooCommerceProductId: Yup.string().trim().min(0).max(255, "head.maxLength"),
});

export const AddIndicaorSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required("indicator.Name.required")
    .max(255, "head.maxLength"),
  info: Yup.string().trim().required("indicator.Info.required"),
  cost: Yup.number()
    .min(0, "value.positive")
    .required("indicator.Cost.required")
    .max(9999999999, "values.number.length"),
  sellingPrice: Yup.number()
    .min(0, "value.positive")
    .required("indicator.Selling.required")
    .max(9999999999, "values.number.length"),
  originalPrice: Yup.number()
    .min(0, "value.positive")
    .required("indicator.Original.required")
    .max(9999999999, "values.number.length"),
  belong: Yup.array()
    .min(1, "indicator.onerole.require")
    .required("indicator.onerole.require"),
  wooCommerceProductId: Yup.string().trim().min(0).max(255, "head.maxLength"),
});

export const AddLibrarySchema = Yup.object().shape({
  name: Yup.string()
    .required("indicator.Name.required")
    .max(255, "head.maxLength"),
  info: Yup.string().required("indicator.Info.required"),
});

export const AddScriptsSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required("script.Name.required")
    .max(255, "head.maxLength"),
  info: Yup.string().trim().required("script.Info.required"),
  cost: Yup.number()
    .min(0, "value.positive")
    .required("script.Cost.required")
    .max(9999999999, "values.number.length"),
  sellingPrice: Yup.number()
    .min(0, "value.positive")
    .required("script.Selling.required")
    .max(9999999999, "values.number.length"),
  originalPrice: Yup.number()
    .min(0, "value.positive")
    .required("script.Original.required")
    .max(9999999999, "values.number.length"),
  belong: Yup.array()
    .min(1, "script.onerole.require")
    .required("script.onerole.require"),
  wooCommerceProductId: Yup.string().trim().min(0).max(255, "head.maxLength"),
});

export const AddUtilitySchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required("utilitymodal.Name.required")
    .max(255, "head.maxLength"),
  info: Yup.string().trim().required("utilitymodal.Info.required"),
  cost: Yup.number()
    .min(0, "value.positive")
    .required("utilitymodal.Cost.required")
    .max(9999999999, "values.number.length"),
  sellingPrice: Yup.number()
    .min(0, "value.positive")
    .required("utilitymodal.Selling.required")
    .max(9999999999, "values.number.length"),
  originalPrice: Yup.number()
    .min(0, "value.positive")
    .required("utilitymodal.Original.required")
    .max(9999999999, "values.number.length"),
  belong: Yup.array()
    .min(1, "utilitymodal.onerole.require")
    .required("utilitymodal.onerole.require"),
  wooCommerceProductId: Yup.string().trim().min(0).max(255, "head.maxLength"),
});

export const AddVersionSchema = Yup.object().shape({
  version: Yup.string()
    .trim()
    .required("version.required")
    .max(255, "head.maxLength"),
  info: Yup.string().required("info.required"),
  library: Yup.number().min(1).required("library.required"),
  libraryVersion: Yup.number().min(1).required("libraryVersion.required"),
  isActive: Yup.boolean().default(true),
});

export const AddLibVersionSchema = Yup.object().shape({
  version: Yup.string()
    .trim()
    .required("version is required")
    .max(255, "head.maxLength"),
  info: Yup.string().required("info is required"),
  download_path: Yup.string().required("download_path is required"),
});

export const AddRentSchema = Yup.object().shape({
  type: Yup.number()
    .integer()
    .min(1, "Rents.Type.required")
    .required("Rents.Type.required"),
  quantity: Yup.number()
    .integer()
    .min(1, "Rents.Quantity.required")
    .required("Rents.Quantity.required"),
  remark: Yup.string()
    .max(255, "Rents.Remarks.required")
    .required("Rents.Remarks.required"),
  valid: Yup.date().required("Rents.Date.required"),
  users: Yup.array()
    .min(1, "Rents.user.required")
    .required("Rents.user.required"),
});

export const EditRentSchema = Yup.object().shape({
  quantity: Yup.number()
    .integer()
    .min(1, "Quantity is required")
    .required("Quantity is required"),
  remark: Yup.string().max(255).required("Remarks is required"),
  valid: Yup.date().required("Date required"),
});

export const AddGrantToolSchema = Yup.object()
  .shape({
    // remarks: Yup.string().max(255, "Remark.required").required("Remark.required"),
    permanent: Yup.boolean().default(false),
    // grantTime: Yup.date()
    //   .min(moment().subtract(1, "day"), "grantTime.required.valid")
    //   // Yup.number()
    //   //   .integer()
    //   //   .min(1, "grantTime.required")
    //   .required("grantTime.required"),
    grantTime: Yup.date().when("permanent", {
      is: false,
      then: Yup.date()
        .min(moment().subtract(1, "day"), "grantTime.required.valid")
        .required("grantTime.required"),
      otherwise: Yup.date().optional(),
    }),
    tool: Yup.number().required("Tool.type.required"),
    toolId: Yup.number().min(1, "Tool.required").required("Tool.required"),
    usersId: Yup.array(),
    parsedAdditionalUserIds: Yup.array(),
  })
  .test("atLeastOneUserIsSelected", function (values) {
    const { usersId, parsedAdditionalUserIds } = values;
    if ((usersId || []).length + (parsedAdditionalUserIds || []).length > 0) {
      return true;
    }

    const errors = ["usersId", "parsedAdditionalUserIds"].map((s) => {
      return new Yup.ValidationError(
        "At least one user should be selected",
        [],
        s,
      );
    });

    return this.createError({
      message: () => errors,
    });
  });

export const AddGrantAccountSchema = Yup.object()
  .shape({
    usersId: Yup.array(),
    parsedAdditionalUserIds: Yup.array(),
    accType: Yup.string().required("AccountType.required"),
    // remarks: Yup.string().required("Remark.required"),
    amount: Yup.number()
      .min(1, "Amount.greaterThanZero.required")
      .required("Amount.required"),
  })
  .test("atLeastOneUserIsSelected", function (values) {
    const { usersId, parsedAdditionalUserIds } = values;
    if ((usersId || []).length + (parsedAdditionalUserIds || []).length > 0) {
      return true;
    }

    const errors = ["usersId", "parsedAdditionalUserIds"].map((s) => {
      return new Yup.ValidationError(
        "At least one user should be selected",
        [],
        s,
      );
    });

    return this.createError({
      message: () => errors,
    });
  });

export const AddGrantPointSchema = Yup.object()
  .shape({
    points: Yup.number()
      .integer()
      .min(1, "value.positive")
      .required("point.Type.required")
      .max(9999999999, "values.number.length"),
    usersId: Yup.array(),
    parsedAdditionalUserIds: Yup.array(),
  })
  .test("atLeastOneUserIsSelected", function (values) {
    const { usersId, parsedAdditionalUserIds } = values;
    if ((usersId || []).length + (parsedAdditionalUserIds || []).length > 0) {
      return true;
    }

    const errors = ["usersId", "parsedAdditionalUserIds"].map((s) => {
      return new Yup.ValidationError(
        "At least one user should be selected",
        [],
        s,
      );
    });

    return this.createError({
      message: () => errors,
    });
  });

export const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required("changePassword.oldPassword"),
  password: Yup.string()
    .min(9, "password.invalid")
    .required("changePassword.newPassword")
    .test("isValidPass", "password.invalid", (value: any) => {
      const hasUpperCase = /[A-Z]/.test(value);
      const hasNumber = /[0-9]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasSymbole = /["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/.test(value);
      let validConditions = 0;
      const numberOfMustBeValidConditions = 4;
      const conditions = [hasUpperCase, hasLowerCase, hasNumber, hasSymbole];
      conditions.forEach((condition) => (condition ? validConditions++ : null));
      if (validConditions >= numberOfMustBeValidConditions) {
        return true;
      }
      return false;
    }),
  // Yup.string().required("changePassword.newPassword"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "changePassword.confirmNewPassword")
    .required("changePassword.confirmNewPassword.req"),
});

export const ActivityRollCallSchema = Yup.object()
  .shape({
    usersId: Yup.array(),
    parsedAdditionalUserIds: Yup.array(),
  })
  .test("atLeastOneUserIsSelected", function (values) {
    const { usersId, parsedAdditionalUserIds } = values;
    if ((usersId || []).length + (parsedAdditionalUserIds || []).length > 0) {
      return true;
    }

    const errors = ["usersId", "parsedAdditionalUserIds"].map((s) => {
      return new Yup.ValidationError(
        "At least one user should be selected",
        [],
        s,
      );
    });

    return this.createError({
      message: () => errors,
    });
  });

export const AddUserMembershipSchema = Yup.object()
  .shape({
    usersId: Yup.array(),
    grantTime: Yup.boolean().default(false),
    // .min(0, "grantDay.range")
    // .required("grantTime.required.valid").nullable(),
    grantDate: Yup.date()
      // required("grantTime.required.valid").
      .when("grantTime", {
        is: true, // [1].includes(1),
        then: Yup.date()
          .nullable()
          .required("grantTime.required")
          .min(new Date(), "grantTime.required.valid"),
      }),
    //min(moment().subtract(1, "day"), "grantTime.required.valid").
    days: Yup.number().when("grantTime", {
      is: false,
      then: Yup.number()
        .min(1, "Days.Valid")
        .required("Days.Required")
        .nullable(),
    }),
    remarks: Yup.string().trim(), //.max(255, "head.maxLength"),
    parsedAdditionalUserIds: Yup.array(),
  })
  .test("atLeastOneUserIsSelected", function (values) {
    const { usersId, parsedAdditionalUserIds } = values;
    if ((usersId || []).length + (parsedAdditionalUserIds || []).length > 0) {
      return true;
    }

    const errors = ["usersId", "parsedAdditionalUserIds"].map((s) => {
      return new Yup.ValidationError(
        "At least one user should be selected",
        [],
        s,
      );
    });

    return this.createError({
      message: () => errors,
    });
  });

export const AddAddClassMembershipSchema = Yup.object().shape({
  permanent: Yup.boolean().default(false),
  grantTime: Yup.number().when("permanent", {
    is: false,
    then: Yup.number()
      .integer()
      .min(1, "grantTime.range")
      .max(99, "grantTime.range")
      .required("grantTime.required"),
  }),
  classes: Yup.array()
    .min(1, "one.classselected")
    .required("one.classselected"),
  types: Yup.array()
    .min(1, "one.grant.selected")
    .required("one.grant.selected"),
});

export const softwareUpdateSchema = Yup.object().shape({
  toolType: Yup.number().integer().moreThan(-1, "ad.tool.required"),
  type: Yup.number().integer().moreThan(0, "ad.type.required"),
  // version: Yup. //integer().moreThan(0, "version.required"),
});

export const AddGiftSchema = Yup.object()
  .shape({
    details: Yup.string()
      .trim()
      .required("detail.required")
      .max(255, "head.maxLength"),
    reason: Yup.string()
      .trim()
      .required("reason.required")
      .max(255, "head.maxLength"),
    // amount: Yup.number().min(1, "amount.greater").required("amount.required"),
    amount: Yup.number().when("giftCategory", {
      is: (a: any) => [1, 5, 6, 7, 8].includes(a), //5,
      then: Yup.number()
        .min(1, "value.moreThanZero")
        .required("vps.type.membership"),
      // otherwise:Yup.string().required("amount.required"),
    }),
    code: Yup.string()
      .when("giftCategory", {
        is: 2,
        then: Yup.string()
          .trim()
          .required("tickdata.type.required")
          .max(255, "head.maxLength"), //.required("amount.required"),
        // otherwise:Yup.string().required("amount.required"),
      })
      .when("giftCategory", {
        is: 12,
        then: Yup.string().trim().required("VPSType.required"),
      })
      .when("giftCategory", {
        is: 13,
        then: Yup.string().trim().required("VPSType.required"),
      })
      .when("giftCategory", {
        is: 14,
        then: Yup.string().trim().required("VPSType.required"),
      }),
    giftAttachmentPath: Yup.string().when("giftCategory", {
      is: (value: any) => value === 11,
      then: Yup.string().required("tickdata.type.required"), //.required("amount.required"),
      // otherwise:Yup.string().required("amount.required"),
    }),
    pointsRequired: Yup.number()
      .integer()
      .min(0, "Point.range")
      .max(100000, "Point.range")
      .required("points.required"),
    // pointsRequired: Yup.number().integer().min(0).required("points.required"),
    exipryDate: Yup.date().required("Expiry.required"),
    giftCategory: Yup.number().min(1).required("gift.required"),
    userIds: Yup.array(),
    parsedAdditionalUserIds: Yup.array(),
  })
  .test("atLeastOneUserIsSelected", function (values) {
    const { userIds, parsedAdditionalUserIds } = values;
    if ((userIds || []).length + (parsedAdditionalUserIds || []).length > 0) {
      return true;
    }

    const errors = ["userIds", "parsedAdditionalUserIds"].map((s) => {
      return new Yup.ValidationError(
        "At least one user should be selected",
        [],
        s,
      );
    });

    return this.createError({
      message: () => errors,
    });
  });

export const AddGiftCategorySchema = Yup.object().shape({
  name: Yup.string().required("script.Name.required"),
});

export const AddFaqSchema = Yup.object().shape({
  faqCategory: Yup.string().required("script.faqCategory.required"),
  question: Yup.string().required("script.question.required"),
  answer: Yup.string().required("script.answer.required"),
  code: Yup.string().required("script.code.required"),
});

export const EditFaqSchema = Yup.object().shape({
  faqCategory: Yup.string().required("script.faqCategory.required"),
  question: Yup.string().required("script.question.required"),
  answer: Yup.string().required("script.answer.required"),
  code: Yup.string().required("script.code.required"),
});

export const EditGiftCategorySchema = Yup.object().shape({
  name: Yup.string().required("script.Name.required"),
});

export const EditGiftSchema = Yup.object().shape({
  details: Yup.string().required("detail.required"),
  reason: Yup.string().required("reason.required"),
  code: Yup.string(),
  pointsRequired: Yup.number()
    .integer()
    .min(0, "Points.required")
    .required("Points.required"),
  exipryDate: Yup.date().required("Expiry.required"),
  giftCategory: Yup.number().min(1, "gift.required").required("gift.required"),
});

export const AddFaqCategorySchema = Yup.object().shape({
  name: Yup.string().required("script.Name.required"),
});

export const EditFaqCategorySchema = Yup.object().shape({
  name: Yup.string().required("script.Name.required"),
});

export const ApproveClasSchema = Yup.object().shape({
  id: Yup.string().required("details is required"),
  type: Yup.number().integer().min(1).required("type is required"),
  price: Yup.number()
    .min(0, "Price.greaterThanZero.required")
    .required("student.price.required"),
});

export const AddUserManualSchema = Yup.object()
  .shape({
    oldClass: Yup.number().integer(),
    userId: Yup.number().integer(),
    classId: Yup.number()
      .integer()
      .min(1, "Class is required")
      .required("Class is required"),
    role: Yup.number().integer().min(1, "Role is required"),
    status: Yup.string().required("Status is required"),
    parsedAdditionalUserIds: Yup.array(),
  })
  .test("atLeastOneUserIsSelected", function (values) {
    const { userId, parsedAdditionalUserIds } = values;
    if ((userId || 0) > 0 || (parsedAdditionalUserIds || []).length > 0) {
      return true;
    }

    const errors = ["userId", "parsedAdditionalUserIds"].map((s) => {
      return new Yup.ValidationError(
        "At least one user should be selected",
        [],
        s,
      );
    });

    return this.createError({
      message: () => errors,
    });
  });

export const AddActivitySchema = Yup.object().shape({
  name: Yup.string().required("activitymodal.Name.required"),
  classId: Yup.number()
    .min(1, "activitymodal.Class.required")
    .required("activitymodal.Class.required"),
  limit: Yup.number()
    .integer()
    .min(1, "activitymodal.Limit.required")
    .required("activitymodal.Limit.required"),
  // limit: Yup.string()
  //   .trim()
  //   .matches(positiveNumberRegExp, "number.positive")
  //   .required("activitymodal.Limit.required"),
  closeApplicationDate: Yup.date().required("activitymodal.Close.required"),
  startDate: Yup.date().required("activitymodal.startdate.required"),
  endDate: Yup.date()
    .required("activitymodal.Enddate.required")
    .when("startDate", (startDate: Date) => {
      if (startDate) {
        return Yup.date()
          .min(startDate, "activitymodal.enddate.min")
          .typeError("activitymodal.startdate.required");
      }
      return Yup.date();
    }),
  // endDate: Yup.date().required("activitymodal.Enddate.required"),
});

export const AddPointSchema = Yup.object().shape({
  role: Yup.number().min(1, "Role is required").required("Role is required"),
  remark: Yup.string().required("Remark is required"),
  point: Yup.number().integer().min(0).required("Point are required"),
});

export const CURTSYTITLE: any = {
  "0": "OTHERS",
  "1": "MR",
  "2": "MRS",
  "3": "MS",
  "4": "DR",
};

export const IdentityType = {
  HONK_KONG_ID_CARD: "Hong kong Id Card",
  PASSPORT: "Passport",
};

export const UserTitle = {
  OTHERS: 0,
  MR: 1,
  MRS: 2,
  MS: 3,
  DR: 4,
};

const namePattern = "^[A-za-z]";

const mobileRegx = /^[0-9]{8}$/;

export const ApproveMemberSchema = Yup.object().shape({
  email: Yup.string().email("msg.invalid").required("msg.required"),
  phoneNumber: Yup.string()
    .matches(mobileRegx, "msg.invalid")
    .required("msg.required"),
  identityId: Yup.number()
    .integer("msg.invalid")
    .min(1, "msg.required")
    .required("msg.required")
    .when("identityType", {
      is: "Hong kong Id Card",
      then: Yup.number()
        .integer("msg.invalid")
        .min(1, "msg.required")
        .test(
          "maxdigit",
          "msg.invalid",
          (number) => String(number).length === 4,
        ),
    })
    .when("identityType", {
      is: "Passport",
      then: Yup.number()
        .integer("msg.invalid")
        .min(1, "msg.required")
        .test(
          "maxdigit",
          "msg.invalid",
          (number) => String(number).length === 6,
        ),
    }),
  identityType: Yup.string()
    .oneOf([...Object.values(IdentityType)])
    .default(null),

  idImage: Yup.string().required("msg.required"),
  address: Yup.string().required("msg.required"),
  officeNumber: Yup.string(),
  jobTitle: Yup.number().integer().min(1),
  industry: Yup.number().integer().min(1),
  englishFirstName: Yup.string()
    .matches(new RegExp(namePattern), "msg.invalid")
    .required("msg.required"),
  preferredNickName: Yup.string().required("msg.required"),
  englishLastName: Yup.string()
    .matches(new RegExp(namePattern), "msg.invalid")
    .required("msg.required"),
  chineseFirstName: Yup.string().required("msg.required"),
  chineseLastName: Yup.string().required("msg.required"),
  emergencyContactName: Yup.string().required("msg.required"),
  emergencyContactRelationship: Yup.string().required("msg.required"),
  emergencyContactNumber: Yup.string()
    .matches(mobileRegx, "msg.invalid")
    .required("msg.required"),
  title: Yup.number().oneOf([0, 1, 2, 3, 4]).required("msg.required"),
  DOB: Yup.string()
    .required("msg.required")
    .test("DOB", "msg.invalid", (value) => {
      return (
        moment().diff(moment(value), "years") > 16 &&
        moment().diff(moment(value), "years") < 90
      );
    }),
  studentRepeaterClass: Yup.number().integer("msg.required"),
  repeater: Yup.boolean().required("msg.required"),
});

export const addReferalSchema = Yup.object().shape({
  referer: Yup.number()
    .min(1, "msg.Referral.student")
    .required("msg.Referral.student"),
  refree: Yup.number()
    .min(1, "msg.Referee.student")
    .required("msg.Referee.student"),
  refreeClass: Yup.number()
    .min(1, "msg.Referee.class")
    .required("msg.Referee.class"),
  refralClass: Yup.number()
    .min(1, "msg.Referral.class")
    .required("msg.Referral.class"),
  rewardType: Yup.string().required("reward.type.required"),
  rewardPoint: Yup.number()
    .integer()
    .min(0, "value.minZero")
    .max(9999999999, "values.number.length")
    .required("reward.point.required")
    .test("len", "msg.invalid", (val: any) => {
      // val !==null ?
      // const isnum = /^[0-9]*$/.test(val);
      // console.log("val", val);
      if (val !== null && val !== undefined && val.toString().length <= 15) {
        return true;
      }

      return false;
    }),
});
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// const alternativeName = /^[0-9]{1,2}[A-Z,a-z]{0,500}[A-Z,a-z]$/;
const alternativeName = /^[0-9]+\w* ?\w*[^\s]?$/;

// const alternativeName = /^[0-9]{1,2}[A-Z,a-z]{0,500}[A-Z,a-z]$/;
export const allAlternativeNameSchema = Yup.object().shape({
  alternativeName1: Yup.string()
    .matches(alternativeName, "altName.name.valid")
    .max(255, "head.maxLength")
    .nullable(),
  alternativeName2: Yup.string()
    .matches(alternativeName, "altName.name.valid")
    .max(255, "head.maxLength")
    .nullable(),
  alternativeName3: Yup.string()
    .matches(alternativeName, "altName.name.valid")
    .max(255, "head.maxLength")
    .nullable(),
  alternativeName4: Yup.string()
    .matches(alternativeName, "altName.name.valid")
    .max(255, "head.maxLength")
    .nullable(),
});

export const getAddAdminProfileSchema = (mobileLength: any) => {
  const editProfileSchema = Yup.object().shape({
    email: Yup.string().email("addadmin.email").required("addadmin.email2"),
    phoneNumber: Yup.string()
      .matches(phoneRegExp, "addadmin.phoneNumber")
      .min(mobileLength, "addadmin.phoneNumber")
      .max(mobileLength, "addadmin.phoneNumber")
      .required("addadmin.phoneNumber2"),
    englishFirstName: Yup.string()
      .max(255)
      .required("addadmin.englishFirstName"),
    // preferredNickName: Yup.string().max(255).required(),
    englishLastName: Yup.string().max(255).required("addadmin.englishLastName"),
    chineseFirstName: Yup.string().max(255),
    chineseLastName: Yup.string().max(255),
    address: Yup.string().max(255).required("addadmin.address"),
    avatar: Yup.string(),
    //  DOB: Yup.date().required("addadmin.DOB"),
    DOB: Yup.string()
      .required("addadmin.DOB")
      .test("DOB", "register.step3.Dob.valid", (value) => {
        return (
          moment().diff(moment(value), "years") > 16 &&
          moment().diff(moment(value), "years") < 90
        );
      }),
    password: Yup.string()
      .required("addadmin.password")
      .test("isValidPass", "addadmin.password2", (value: any) => {
        const hasUpperCase = /[A-Z]/.test(value);
        const hasNumber = /[0-9]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasSymbole = /["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/.test(value);
        let validConditions = 0;
        const numberOfMustBeValidConditions = 4;
        const conditions = [hasUpperCase, hasLowerCase, hasNumber, hasSymbole];
        conditions.forEach((condition) =>
          condition ? validConditions++ : null,
        );

        if (validConditions >= numberOfMustBeValidConditions) {
          return true;
        }
        return false;
      }),
    userType: Yup.mixed()
      .oneOf(UserType.map((type) => type.id))
      .required("addadmin.userType"),
  });
  return editProfileSchema;
};

export const setNewVPSPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(9, "password.invalid")
    .required("changePassword.newPassword")
    .test("isValidPass", "password.invalid", (value: any) => {
      const hasUpperCase = /[A-Z]/.test(value);
      const hasNumber = /[0-9]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasSymbole = /["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/.test(value);
      let validConditions = 0;
      const numberOfMustBeValidConditions = 4;
      const conditions = [hasUpperCase, hasLowerCase, hasNumber, hasSymbole];
      conditions.forEach((condition) => (condition ? validConditions++ : null));
      if (validConditions >= numberOfMustBeValidConditions) {
        return true;
      }
      return false;
    }),
  // Yup.string().required("changePassword.newPassword"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "changePassword.confirmNewPassword")
    .required("changePassword.confirmNewPassword.req"),
});

export const AccountInfoValidationSchema = Yup.object({
  demoAccountsPaid: Yup.number()
    .min(0, "value.positive")
    .integer("Value.integer")
    .required("AccountInfo.demoAccountsPaid.required"),
  realAccountsPaid: Yup.number()
    .min(0, "value.positive")
    .integer("Value.integer")
    .required("AccountInfo.realAccountsPaid.required"),
  totalAccountsPaid: Yup.number()
    .min(0, "value.positive")
    .integer("Value.integer")
    .required("AccountInfo.totalAccountsPaid.required"),
  demoAccountsFree: Yup.number()
    .min(0, "value.positive")
    .integer("Value.integer")
    .required("AccountInfo.demoAccountsFree.required"),
  realAccountsFree: Yup.number()
    .min(0, "value.positive")
    .integer("Value.integer")
    .required("AccountInfo.realAccountsFree.required"),
  totalAccountsFree: Yup.number()
    .min(0, "value.positive")
    .integer("Value.integer")
    .required("AccountInfo.totalAccountsFree.required"),
});
