// import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
  Navigate as Redirect,
} from "react-router-dom";
import { publicRoutes, privateRoutes } from "./routeConstants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useMemo, useState } from "react";
import { Sidebar } from "../Components/common/Sidebar";
import { Header } from "../Components/common/Header";
import { useSelector } from "react-redux";
import { Store } from "../redux/Actions";
import messages_zh from "../translations/zh.json";
import messages_en from "../translations/en.json";
import { IntlProvider } from "react-intl";
import { ToastDownload } from "../Components/common/ToastDownload";

export default function Routes() {
  const isLoggedout = useSelector(
    (state: Store) => state.userDataReducer.isLoggedout,
  );
  const pageNumber = useSelector(
    (state: Store) => state.userDataReducer.pageNumber || 1,
  ) as any;
  const language: string = useSelector(
    (state: Store) => state.userDataReducer.local,
  );

  const show: any = useSelector(
    (state: Store) => state.mediaUploadReducer.show,
  ) as any;

  const messages = useMemo(
    () => ({
      en: messages_en,
      zh: messages_zh,
    }),
    [],
  );
  const [message, setMessage] = useState(messages["en"]);

  useEffect(() => {
    if (language === "en") {
      setMessage(messages["en"]);
    } else {
      setMessage(messages["zh"]);
    }
  }, [messages, language]);

  return (
    <>
      <IntlProvider messages={message} locale={language}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          closeButton={false}
          pauseOnHover
        />
        {/* {isLoggedin && <Header /> } */}
        <Router basename={process.env.PUBLIC_URL}>
          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>
            {publicRoutes.map(
              ({ path, element: Element, sideBar, navBar }, index) => (
                <Route
                  key={index}
                  path={path}
                  element={
                    <>
                      {sideBar && <Sidebar />}
                      {navBar && <Header />}
                      <Element />
                    </>
                  }
                />
              ),
            )}
            {/* <Route element={() => <Redirect to="/login" />} /> */}

            {isLoggedout ? (
              <Route path="/*" element={<Redirect to="/login" />} />
            ) : (
              <>
                {privateRoutes.map(
                  ({ path, element: Element, sideBar, navBar }, index) => (
                    <Route
                      key={index}
                      path={path}
                      element={
                        <div id="app">
                          {sideBar && <Sidebar />}
                          <div className="content-wrapper">
                            {navBar && <Header />}
                            <Element {...{ pageNumber: pageNumber }} />
                            {show && <ToastDownload />}
                          </div>
                        </div>
                      }
                    />
                  ),
                )}
                {/* <Route path="/*" element={<Redirect to="/login" />} /> */}
              </>
            )}
          </Switch>
        </Router>
      </IntlProvider>
    </>
  );
}
