import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import RecordsList from "./RecordsList";
import { Store } from "../../redux/Actions";

import { EXPRESSWAY_LIST_TABS } from "../../Constants";
import PendingList from "./PendingList";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import { FormattedMessage } from "react-intl";

const ExpressWay = () => {
  const [activeTab, setActiveTab] = useState(0);

  // const classDetail: any = useSelector(
  //   (state: Store) => state.classReducer.classDetail
  // ) as any;
  const isLoading = useSelector(
    (state: Store) => state.expressWayReducer.Loading.isLoading,
  );

  const classes = useStyles();

  const setAciveTab = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };

  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex flex-wrap align-items-center">
            <div className="mr-auto">
              <h1>
                {" "}
                <FormattedMessage
                  id="Express.Way.beta"
                  defaultMessage="Express Way (Beta) "
                />
              </h1>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          {isLoading && <CenterLoader />}
          <Card>
            <Card.Body className="p-0">
              <Box
                sx={{ borderBottom: 1, borderColor: "divider", padding: "0px" }}
                style={{ padding: "0px" }}
                className={classes.tabs}
              >
                <Tabs
                  value={activeTab}
                  onChange={setAciveTab}
                  className={classes.tabs}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label={
                      <FormattedMessage
                        id="tab.PendingList"
                        defaultMessage="Pending List"
                      />
                    }
                    {...a11yProps(EXPRESSWAY_LIST_TABS.PENDING_LIST)}
                  />
                  <Tab
                    label={
                      <FormattedMessage
                        id="tab.RecordList"
                        defaultMessage="Pending List"
                      />
                    }
                    {...a11yProps(EXPRESSWAY_LIST_TABS.RECORDS_LIST)}
                  />
                </Tabs>
              </Box>
              <TabPanel
                value={activeTab}
                index={EXPRESSWAY_LIST_TABS.PENDING_LIST}
              >
                <Card.Body className="p-0">
                  <PendingList />
                </Card.Body>
              </TabPanel>
              <TabPanel
                value={activeTab}
                index={EXPRESSWAY_LIST_TABS.RECORDS_LIST}
              >
                <Card.Body className="p-0">
                  <RecordsList />
                </Card.Body>
              </TabPanel>
            </Card.Body>
          </Card>
        </section>
      </div>
    </div>
  );
};

export default ExpressWay;

const useStyles = makeStyles({
  tabs: {
    "& .MuiBox-root": {
      padding: "0px",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#68439a",
      height: 1,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#68439a",
      BsBorderBottom: "1px solid",
    },
    "& .MuiTypography-root": {
      lineHeight: "1.5",
    },
  },
});

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
