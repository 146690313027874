import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { dispatch } from "../../redux";
import { attendActivity, getActivityDetails } from "../../redux/action";
import { Store } from "../../redux/Actions";
import { Card } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import { BsChevronLeft } from "../../reactIcons";
import MyQrCodeScanner from "../../Components/Activity/MyQrCodeScanner";

const ActivityQRCode = () => {
  const urlParams = useParams();
  const activityDetails: any = useSelector(
    (state: Store) => state.ActivityReducer.activityDetails,
  ) as any;

  const [legacyMode, setLegacyMode] = useState(true);
  const [attendanceOpen, setAttendanceOpen] = useState(true);
  const [attendanceData, setAttendanceData] = useState<any>({});
  const qrReaderRef = useRef<any>(null);
  const checkActivity = useSelector(
    (state: Store) => state.ActivityReducer.checkActivity,
  ) as any;

  const isLoading = useSelector(
    (state: Store) => state.ActivityReducer.isLoading,
  ) as any;

  const handleScan = useCallback(
    (result: any) => {
      try {
        if (result === null || result === undefined) {
          throw new Error("Invaild QRCode");
        }

        const data = JSON.parse(result.data);

        if (data && data.id && data.QR && !isLoading) {
          const attendence = {
            activityId: activityDetails.id,
            userId: data.id,
            QR: data.QR,
            status: "ATTENDED",
          };

          dispatch(attendActivity(true, attendence));
          handleAttendanceClose();
        } else {
          throw new Error("Invaild QRCode");
        }
      } catch (error: any) {
        toast.error(error);
      }
    },
    [isLoading, activityDetails.id],
  );

  const handleAttendanceClose = () => {
    setAttendanceOpen(false);
  };

  useEffect(() => {
    const { id } = urlParams;
    dispatch(getActivityDetails(false, id));
    handleAttendanceClose();
  }, []);

  return (
    <div className="content-wrapper">
      {isLoading && <CenterLoader />}
      <div className="content">
        <header className="page-header">
          <div className="d-flex flex-wrap align-items-center">
            <div className="mr-auto">
              <h1>
                <FormattedMessage
                  id="head.AdminActivityQRCode"
                  defaultMessage="Admin Activity QR Code"
                />
              </h1>
            </div>
            <div className="m-l-10 common-right-button">
              <Link to="/activities" className="btn btn-secondary">
                <i className="back-button">
                  <BsChevronLeft /> &nbsp;
                </i>
                <FormattedMessage id="page.back" defaultMessage="Back" />
              </Link>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          <Card className="m-0">
            <Card.Body>
              <h4 className="mb-3 font-weight-400">
                <FormattedMessage
                  id="moreMenu.QRCode"
                  defaultMessage="QR code"
                />
              </h4>
              <h4 className="mb-3">
                <FormattedMessage
                  id="modal.head.ActivityName"
                  defaultMessage="Activity Name"
                />
                : {activityDetails.name}
              </h4>
              <h4 className="mb-3">
                <FormattedMessage
                  id="head.ActivityID"
                  defaultMessage="Activity ID"
                />
                : {activityDetails.id}
              </h4>
              <h4 className="mb-3">
                <FormattedMessage
                  id="classmodal.name"
                  defaultMessage="Class name"
                />
                : {activityDetails?.class?.name || "s"}
              </h4>
              {activityDetails.id && (
                <MyQrCodeScanner handleScan={handleScan} />
              )}
            </Card.Body>
          </Card>
        </section>
      </div>
    </div>
  );
};

export default ActivityQRCode;
