// import React from "react";
// import { apiCall, METHOD } from "../../service/APIServices/baseApiCall";
import {
  DELETE_MEDIA_AMAZONS3,
  DELETE_MEDIA_LOCAL,
  SET_MEDIA_UPLOAD_PROGRESS,
  UPALOD_MEDIA_LIST,
  SET_MEDIA_UPLOAD,
  SET_MEDIA_UPLOAD_EMPTY,
  DOWNLOAD_MEDIA_LIST,
  SET_DOWNLOAD_MEDIA_PROGRESS_SUCCESS,
  COMPLETE_DOWNLOAD_MEDIA,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { toast } from "react-toastify";
// import { setToken } from "../../util/utils";
// import { UPALOD_MEDIA_LIST_URL } from "../../service/APIServices/apiEndPoints";
// import { toast } from "react-toastify";

//Get Admin Roles List

export const setMediaUploadEmpty =
  (Key: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: SET_MEDIA_UPLOAD_EMPTY,
      payload: Key,
    });
  };

export const setMediaUploadProgress =
  (count: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: SET_MEDIA_UPLOAD_PROGRESS,
      payload: count,
    });
  };

export const setMediaUpload =
  (Key: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: SET_MEDIA_UPLOAD,
      payload: Key,
    });
  };

export const uploadMediaSingleList =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch(uploadMediaSingleListInit());
  };

export const uploadMediaSingleListInit =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: UPALOD_MEDIA_LIST.UPALOD_MEDIA_LIST_INITIALIZATION,
    });
  };

export const uploadMediaSingleListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: UPALOD_MEDIA_LIST.UPALOD_MEDIA_LIST_SUCCESS,
      payload: { ...res, isMulti: false },
    });
  };

export const uploadMediaSingleListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: UPALOD_MEDIA_LIST.UPALOD_MEDIA_LIST_ERROR,
    });
  };

export const uploadMediaList =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch(uploadMediaListInit());
  };

export const uploadMediaListInit =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: UPALOD_MEDIA_LIST.UPALOD_MEDIA_LIST_INITIALIZATION,
    });
  };

export const uploadMediaListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: UPALOD_MEDIA_LIST.UPALOD_MEDIA_LIST_SUCCESS,
      payload: { ...res, isMulti: true },
    });
  };

export const uploadMediaListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: UPALOD_MEDIA_LIST.UPALOD_MEDIA_LIST_ERROR,
    });
  };

export const deleteMediaLocalInit =
  (
    // showToast = true,
    stateKey: any,
    key: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_MEDIA_LOCAL.DELETE_MEDIA_LOCAL_INITIALIZATION,
    });
    dispatch(deleteMediaLocalSuccess(stateKey, key));
  };

export const deleteMediaLocalSuccess =
  (
    stateKey: any,
    key: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_MEDIA_LOCAL.DELETE_MEDIA_LOCAL_SUCCESS,
      payload: { stateKeys: stateKey, key: key },
    });
    toast.success("File deleted successfully");
  };

export const deleteMediaLocalError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: DELETE_MEDIA_LOCAL.DELETE_MEDIA_LOCAL_ERROR,
    });
  };

export const deleteMediaS3Init =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: DELETE_MEDIA_AMAZONS3.DELETE_MEDIA_AMAZONS3_INITIALIZATION,
    });
  };

export const deleteMediaS3Success =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_MEDIA_AMAZONS3.DELETE_MEDIA_AMAZONS3_SUCCESS,
      payload: { ...res },
    });
  };

export const deleteMediaS3Error =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: DELETE_MEDIA_AMAZONS3.DELETE_MEDIA_AMAZONS3_ERROR,
    });
  };

export const downloadMediaList =
  (file: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(downloadMediaListInit(file));
  };

export const downloadMediaListInit =
  (file: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(downloadMediaListSuccess(file));
  };

export const downloadMediaListSuccess =
  (file: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DOWNLOAD_MEDIA_LIST.DOWNLOAD_MEDIA_LIST_SUCCESS,
      payload: file,
    });
  };

export const setDownloadMediaListProgress =
  (id: any, progress: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: SET_DOWNLOAD_MEDIA_PROGRESS_SUCCESS,
      payload: { id: id, progress: progress },
    });
  };

export const setCompleteMediaDownload =
  (id: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: COMPLETE_DOWNLOAD_MEDIA,
      payload: id,
    });
  };

export const setSneekBarShow =
  (id: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: "SET_TOAST_SHOW",
      payload: id,
    });
  };
