import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import {
  AnnouncementsIcon,
  TicketIcon,
  UsersPlusIcon,
  HomeIcon,
  UsersIcon,
  ClassesIcon,
  ActivityIcon,
  DownloadIcon,
  IndicatorsIcon,
  ScriptIcon,
  UtilitesIcon,
  GiftIcon,
  FAQIcon,
  MemberShipIcon,
  LibararyIcon,
} from "../../util/iconStyle";
import { IoIosPeople } from "react-icons/io";
import { FaMoneyBillAlt } from "react-icons/fa";
import GemsfxChinese from "../../assets/img/Gemsfx-Version4-Logo - Chinese.png";
import GemsfxEnglish from "../../assets/img/Gemsfx-Version4-Logo - English.png";
import { useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import { HiOutlineDesktopComputer } from "react-icons/hi";
import { MdCardMembership } from "react-icons/md";

export const Sidebar = () => {
  const location = useLocation();

  const activeClass = (path: string) => {
    const startPath = `/${location.pathname.split("/")[1]}`;
    if (path === startPath) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    sessionStorage.setItem("page", "1");
  }, [location.pathname]);
  const local = useSelector((state: Store) => state.userDataReducer.local);

  const [openGift, SetOpenGift] = useState<boolean>(false);
  const openSubmenu = () => {
    SetOpenGift(!openGift);
  };

  const handleClickChange = () => {
    localStorage.removeItem("pageNumber");
    localStorage.removeItem("pageLimit");
    localStorage.removeItem("userTab");
    localStorage.removeItem("userStatus");
  };

  const [openFaq, SetOpenFaq] = useState<boolean>(false);
  const openSubmenuFaq = () => {
    SetOpenFaq(!openFaq);
  };

  return (
    <aside className="sidebar sidebar-left">
      <div className="sidebar-content" style={{ overflowY: "auto" }}>
        <div className="aside-toolbar">
          <ul className="site-logo">
            <li>
              <a href="/home">
                <div className="logo">
                  {local == "en" ? (
                    <img src={GemsfxEnglish} style={{ width: "140px" }} />
                  ) : (
                    <img src={GemsfxChinese} style={{ width: "140px" }} />
                  )}
                </div>
              </a>
            </li>
          </ul>
        </div>
        <nav className="main-menu">
          <ul className="nav metismenu">
            <li
              className="sidebar-header font-weight-normal"
              style={{ color: "#57769b" }}
            >
              <FormattedMessage id="General" defaultMessage="General" />
            </li>

            <li className={activeClass("/home") ? "active mb-2" : "mb-2"}>
              <Link
                to="/home"
                onClick={() => {
                  handleClickChange();
                }}
              >
                <i className="MuiListItemIcon-root">
                  <HomeIcon />
                </i>
                <span>
                  <FormattedMessage id="Dashboard" defaultMessage="Dashboard" />
                </span>
              </Link>
            </li>

            <li
              className="sidebar-header font-weight-normal"
              style={{ color: "#57769b" }}
            >
              <FormattedMessage id="Management" defaultMessage="" />
            </li>

            <li className={activeClass("/adminroles") ? "active" : ""}>
              <Link
                to="/adminroles"
                onClick={() => {
                  handleClickChange();
                }}
              >
                <i className="MuiListItemIcon-root">
                  <UsersPlusIcon />
                </i>
                <span>
                  <FormattedMessage
                    id="Admin.Roles.(Beta)"
                    defaultMessage="Admin Roles (Beta)"
                  />
                </span>
              </Link>
            </li>

            <li className={activeClass("/vpsManagement") ? "active" : ""}>
              <Link
                to="/vpsManagement"
                onClick={() => {
                  handleClickChange();
                }}
              >
                <i className="MuiListItemIcon-root">
                  <HiOutlineDesktopComputer size={23} />
                </i>
                <span>
                  <FormattedMessage
                    id="Admin.Vps.Management"
                    defaultMessage="Vps Management"
                  />
                </span>
              </Link>
            </li>

            <li className={activeClass("/announcements") ? "active" : ""}>
              <Link to="/announcements">
                <i className="MuiListItemIcon-root">
                  <AnnouncementsIcon />
                </i>
                <span>
                  <FormattedMessage
                    id="Admin.Announcement"
                    defaultMessage="Admin Announcements"
                  />
                </span>
              </Link>
            </li>

            <li className={activeClass("/tickets") ? "active" : ""}>
              <Link
                to="/tickets"
                onClick={() => {
                  handleClickChange();
                }}
              >
                <i className="MuiListItemIcon-rooÎt">
                  <TicketIcon />
                </i>
                <span>
                  <FormattedMessage
                    id="Admin.Tickets"
                    defaultMessage="Admin Tickets"
                  />
                </span>
              </Link>
            </li>

            <li className={activeClass("/users") ? "active" : ""}>
              <Link
                to="/users"
                onClick={() => {
                  handleClickChange();
                }}
              >
                <i className="MuiListItemIcon-root">
                  <UsersIcon />
                </i>
                <span>
                  <FormattedMessage
                    id="Admin.Users"
                    defaultMessage="Admin Users"
                  />
                </span>
              </Link>
            </li>

            <li className={activeClass("/plan-categories") ? "active" : ""}>
              <Link to="/plan-categories">
                <i className="MuiListItemIcon-root">
                  <MdCardMembership />
                </i>
                <span>
                  <FormattedMessage
                    id="Admin.PlanCategories"
                    defaultMessage="Admin Plan Categories"
                  />
                </span>
              </Link>
            </li>

            <li className={activeClass("/plans") ? "active" : ""}>
              <Link to="/plans">
                <i className="MuiListItemIcon-root">
                  <MdCardMembership />
                </i>
                <span>
                  <FormattedMessage
                    id="Admin.Plans"
                    defaultMessage="Admin Plans"
                  />
                </span>
              </Link>
            </li>

            <li className={activeClass("/classes") ? "active" : ""}>
              <Link
                to="/classes"
                onClick={() => {
                  handleClickChange();
                }}
              >
                <i className="MuiListItemIcon-root">
                  <ClassesIcon />
                </i>
                <span>
                  <FormattedMessage
                    id="Admin.Classes"
                    defaultMessage="Admin.Classes"
                  />
                </span>
              </Link>
            </li>

            <li className={activeClass("/students") ? "active" : ""}>
              <Link
                to="/students"
                onClick={() => {
                  handleClickChange();
                }}
              >
                <i className="MuiListItemIcon-root">
                  <UsersIcon />
                </i>
                <span>
                  <FormattedMessage
                    id="Admin.Student"
                    defaultMessage="Admin Student"
                  />
                </span>
              </Link>
            </li>

            <li className={activeClass("/activities") ? "active" : ""}>
              <Link
                to="/activities"
                onClick={() => {
                  handleClickChange();
                }}
              >
                <i className="MuiListItemIcon-root">
                  <ActivityIcon />
                </i>
                <span>
                  <FormattedMessage
                    id="Admin.Activity"
                    defaultMessage="Admin Activity"
                  />
                </span>
              </Link>
            </li>

            <li className={activeClass("/expert-advisors") ? "active" : ""}>
              <Link
                to="/expert-advisors"
                onClick={() => {
                  handleClickChange();
                }}
              >
                <i className="MuiListItemIcon-root">
                  <DownloadIcon />
                </i>
                <span>
                  <FormattedMessage
                    id="Admin.Expert.Advisors"
                    defaultMessage="Admin Expert Advisors"
                  />
                </span>
              </Link>
            </li>

            <li className={activeClass("/indicators") ? "active" : ""}>
              <Link
                to="/indicators"
                onClick={() => {
                  handleClickChange();
                }}
              >
                <i className="MuiListItemIcon-root">
                  <IndicatorsIcon />
                </i>
                <span>
                  <FormattedMessage
                    id="Admin.Indicators"
                    defaultMessage="Admin Indicators"
                  />
                </span>
              </Link>
            </li>

            <li className={activeClass("/utilities") ? "active" : ""}>
              <Link
                to="/utilities"
                onClick={() => {
                  handleClickChange();
                }}
              >
                <i className="MuiListItemIcon-root">
                  <UtilitesIcon />
                </i>
                <span>
                  <FormattedMessage
                    id="Admin.Utility"
                    defaultMessage="Admin Utilities"
                  />
                </span>
              </Link>
            </li>

            <li className={activeClass("/scripts") ? "active" : ""}>
              <Link
                to="/scripts"
                onClick={() => {
                  handleClickChange();
                }}
              >
                <i className="MuiListItemIcon-root">
                  <ScriptIcon />
                </i>
                <span>
                  <FormattedMessage
                    id="Admin.Script"
                    defaultMessage="Admin Scripts"
                  />
                </span>
              </Link>
            </li>

            <li className={activeClass("/library") ? "active" : ""}>
              <Link
                to="/library"
                onClick={() => {
                  handleClickChange();
                }}
              >
                <i className="MuiListItemIcon-root">
                  <LibararyIcon />
                </i>
                <span>
                  <FormattedMessage
                    id="Admin.Library"
                    defaultMessage="Library"
                  />
                </span>
              </Link>
            </li>

            <li className={activeClass("/grants") ? "active" : ""}>
              <Link
                to="/grants"
                onClick={() => {
                  handleClickChange();
                }}
              >
                <i className="MuiListItemIcon-root">
                  <UsersIcon />
                </i>
                <span>
                  <FormattedMessage
                    id="Admin.Grants"
                    defaultMessage="Admin Grants"
                  />
                </span>
              </Link>
            </li>

            <li className={activeClass("/membership") ? "active" : ""}>
              <Link
                to="/membership"
                onClick={() => {
                  handleClickChange();
                }}
              >
                <i className="MuiListItemIcon-root">
                  <MemberShipIcon />
                </i>
                <span>
                  <FormattedMessage
                    id="Admin.Membership"
                    defaultMessage="Admin Membership"
                  />
                </span>
              </Link>
            </li>

            <li className={activeClass("/referal") ? "active" : ""}>
              <Link
                to="/referal"
                onClick={() => {
                  handleClickChange();
                }}
              >
                <i className="MuiListItemIcon-root">
                  <IoIosPeople size={24} />
                </i>
                <span>
                  <FormattedMessage
                    id="Admin.Referral"
                    defaultMessage="Admin Referral"
                  />
                </span>
              </Link>
            </li>
            <li
              className={
                activeClass("/gifts")
                  ? `${
                      !openGift
                        ? "active nav-dropdown"
                        : "open active nav-dropdown"
                    }`
                  : `${!openGift ? "nav-dropdown" : "open nav-dropdown"}`
              }
            >
              <Link
                className="has-arrow"
                onClick={openSubmenu}
                to="#"
                aria-expanded="false"
              >
                <i className="MuiListItemIcon-root">
                  <GiftIcon />
                </i>
                <span>
                  <FormattedMessage
                    id="Admin.Gifts"
                    defaultMessage="Admin Gift"
                  />
                </span>
              </Link>
              <ul
                className={`nav-sub ${!openGift ? "collapse" : ""}`}
                id="admin-gift"
                aria-expanded="true"
              >
                <li>
                  <Link
                    to="/gifts"
                    style={{
                      color:
                        location.pathname === "/gifts" ? "#68439a" : "#718EB1",
                    }}
                  >
                    <i
                      className="MuiListItemIcon-root"
                      style={{
                        color:
                          location.pathname === "/gifts"
                            ? "#68439a"
                            : "#718EB1",
                      }}
                    >
                      <GiftIcon />
                    </i>
                    <span>
                      <FormattedMessage
                        id="head.Gifts"
                        defaultMessage="Gifts"
                      />
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/gifts/category"
                    style={{
                      color:
                        location.pathname === "/gifts/category"
                          ? "#68439a"
                          : "#718EB1",
                    }}
                  >
                    <i
                      className="MuiListItemIcon-root"
                      style={{
                        color:
                          location.pathname === "/gifts/category"
                            ? "#68439a"
                            : "#718EB1",
                      }}
                    >
                      <GiftIcon />
                    </i>
                    <span>
                      <FormattedMessage
                        id="head.Category"
                        defaultMessage="Category"
                      />
                    </span>
                  </Link>
                </li>
              </ul>
            </li>

            <li
              className={
                activeClass("/faqs")
                  ? `${
                      !openFaq
                        ? "active nav-dropdown"
                        : "open active nav-dropdown"
                    }`
                  : `${!openFaq ? "nav-dropdown" : "open nav-dropdown"}`
              }
            >
              <Link
                className="has-arrow"
                onClick={openSubmenuFaq}
                to="#"
                aria-expanded="false"
              >
                <i className="MuiListItemIcon-root">
                  <FAQIcon />
                </i>
                <span>
                  <FormattedMessage id="Admin.FAQ" defaultMessage="Admin FAQ" />
                </span>
              </Link>
              <ul
                className={`nav-sub ${!openFaq ? "collapse" : ""}`}
                id="admin-faq"
                aria-expanded="true"
              >
                <li>
                  <Link
                    to="/faqs"
                    style={{
                      color:
                        location.pathname === "/faqs" ? "#68439a" : "#718EB1",
                    }}
                  >
                    <i
                      className="MuiListItemIcon-root"
                      style={{
                        color:
                          location.pathname === "/faqs" ? "#68439a" : "#718EB1",
                      }}
                    >
                      <FAQIcon />
                    </i>
                    <span>
                      <FormattedMessage id="head.Faqs" defaultMessage="Faqs" />
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/faqs/category"
                    style={{
                      color:
                        location.pathname === "/faqs/category"
                          ? "#68439a"
                          : "#718EB1",
                    }}
                  >
                    <i
                      className="MuiListItemIcon-root"
                      style={{
                        color:
                          location.pathname === "/faqs/category"
                            ? "#68439a"
                            : "#718EB1",
                      }}
                    >
                      <FAQIcon />
                    </i>
                    <span>
                      <FormattedMessage
                        id="head.Category"
                        defaultMessage="Category"
                      />
                    </span>
                  </Link>
                </li>
              </ul>
            </li>

            <li className={activeClass("/gems-advertisements") ? "active" : ""}>
              <Link
                to="/gems-advertisements"
                onClick={() => {
                  handleClickChange();
                }}
              >
                <i className="MuiListItemIcon-root">
                  <UsersIcon />
                </i>
                <span>
                  <FormattedMessage
                    id="Admin.Advertisements"
                    defaultMessage="Admin Advertisements"
                  />
                </span>
              </Link>
            </li>
            <li className={activeClass("/bills") ? "active" : ""}>
              <Link
                to="/bills"
                onClick={() => {
                  handleClickChange();
                }}
              >
                <i className="MuiListItemIcon-root">
                  <FaMoneyBillAlt />
                </i>
                <span>
                  <FormattedMessage id="Admin.Bills" defaultMessage="Bills" />
                </span>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
};
