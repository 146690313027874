import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

import { MEMBERSHIP_LIST_TABS } from "../../Constants";
import User from "./User";
import Referal from "./Referal";
import Class from "./Class";
import ReferalTree from "./ReferalTree";
import { FormattedMessage } from "react-intl";
import { dispatch } from "../../redux";
import { getUserRefaralList } from "../../redux/action";
import MembershipExpiredUserLIst from "./MembershipExpiredUserLIst";
import { useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";

const Membership = () => {
  const [activeTab, setActiveTab] = useState(0);

  const isLoading = useSelector(
    (state: Store) => state.userDataReducer.isLoading,
  );
  // useEffect(() => {
  //   if (activeTab === MEMBERSHIP_LIST_TABS.REFERAL_TREE) {
  //     dispatch(getUserRefaralList(false))
  //   }
  // }, [activeTab])

  const setAciveTab = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };
  const classes = useStyles();

  return (
    <div className="content-wrapper">
      <div className="content">
        {isLoading && <CenterLoader />}
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                <FormattedMessage
                  id="Admin.Membership"
                  defaultMessage="Admin Membership"
                />
              </h1>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          <Card>
            <Card.Body className="p-0">
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={activeTab}
                  onChange={setAciveTab}
                  className={classes.tabs}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label={
                      <FormattedMessage
                        id="tab.MembershipExpired"
                        defaultMessage="Membership Expired Users"
                      />
                    }
                    {...a11yProps(MEMBERSHIP_LIST_TABS.MEMBERSHIP_EXPIRED)}
                  />
                  <Tab
                    label={
                      <FormattedMessage id="tab.User" defaultMessage="User" />
                    }
                    {...a11yProps(MEMBERSHIP_LIST_TABS.USER_LIST)}
                  />
                  <Tab
                    label={
                      <FormattedMessage
                        id="Admin.Activitie.Class"
                        defaultMessage="Class"
                      />
                    }
                    {...a11yProps(MEMBERSHIP_LIST_TABS.CLASS_LIST)}
                  />
                </Tabs>
              </Box>
              <TabPanel
                value={activeTab}
                index={MEMBERSHIP_LIST_TABS.MEMBERSHIP_EXPIRED}
              >
                <MembershipExpiredUserLIst />
              </TabPanel>
              <TabPanel
                value={activeTab}
                index={MEMBERSHIP_LIST_TABS.USER_LIST}
              >
                <User />
              </TabPanel>
              <TabPanel
                value={activeTab}
                index={MEMBERSHIP_LIST_TABS.CLASS_LIST}
              >
                <Class />
              </TabPanel>
            </Card.Body>
          </Card>
        </section>
      </div>
    </div>
  );
};

export default Membership;

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#68439a",
      height: 1,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#68439a",
      BsBorderBottom: "1px solid",
    },
  },
});

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
