import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import EditGiftModal from "../../Components/Gift/EditGiftModal";
import { dispatch } from "../../redux";
import { getGiftList } from "../../redux/action";
import { Store } from "../../redux/Actions";
import { FormattedMessage } from "react-intl";
import Pagination from "../../Components/common/Paginate/Pagination";
import NoDatFound from "../../Components/common/NoDatFound";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import { FaEdit } from "../../reactIcons";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

const GiftLits = () => {
  const [selectedGift, setSelectedGift] = useState({ modal: false, gift: {} });
  const giftList = useSelector((state: Store) => state.giftReducer.giftList);

  const isLoading = useSelector((state: Store) => state.giftReducer.isLoading);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const totalRecord = useSelector(
    (state: Store) => state.giftReducer.totalRecord,
  ) as any;

  const onPageChange = (pageNum: number) => {
    setPageNumber(pageNum);
  };

  useEffect(() => {
    dispatch(getGiftList(false, pageNumber, pageLimit));
  }, [pageNumber, pageLimit]);

  const getGiftValue = (i: any) => {
    if (i?.giftCategory?.id === 1) {
      return i.code;
    }
    if (i?.giftCategory?.id === 2) {
      return i.code;
    }
    if (i?.giftCategory?.id === 5) {
      return i.amount;
    }
    if (i?.giftCategory?.id === 6) {
      return i.amount;
    }
    if (i?.giftCategory?.id === 7) {
      return i.amount;
    }
    if (i?.giftCategory?.id === 8) {
      return i.amount;
    }
    if (i?.giftCategory?.name === "VIP Free Course Code") {
      return i.code;
    }
  };

  return (
    <div>
      <div className="table-responsive">
        {isLoading && <CenterLoader />}

        {giftList && giftList.length > 0 ? (
          <>
            {" "}
            <table className="table table-hover m-0">
              <thead>
                <tr>
                  <th>
                    {" "}
                    <FormattedMessage
                      id="Admin.GiftsList.Id"
                      defaultMessage="Id "
                    />
                  </th>
                  <th>
                    {" "}
                    <FormattedMessage
                      id="Admin.GiftsList.Mid"
                      defaultMessage="Mid "
                    />
                  </th>
                  <th>
                    {" "}
                    <FormattedMessage
                      id="Admin.GiftsList.Name"
                      defaultMessage="Name "
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="Admin.GiftsList.Detail"
                      defaultMessage="Details "
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="Admin.GiftsList.Code"
                      defaultMessage="Code"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="Admin.GiftsList.Reason"
                      defaultMessage="Reason "
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="Admin.GiftsList.redeemedBy"
                      defaultMessage="Redeemed By"
                    />
                  </th>
                  <th>
                    {" "}
                    <FormattedMessage
                      id="Admin.GiftsList.Redeemed"
                      defaultMessage="Redeemed"
                    />
                  </th>
                  <th>
                    <FormattedMessage id="page.Edit" defaultMessage="Edit" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {giftList &&
                  giftList.length > 0 &&
                  giftList.map((i: any) => (
                    <tr>
                      <td>{i.id}</td>
                      <td>{i?.users?.id || "-"}</td>
                      <td>{i?.giftCategory?.name}</td>
                      <td>{i.details}</td>
                      <td> {getGiftValue(i)}</td>
                      <td>{i.reason}</td>
                      <td>
                        {i?.redeemedBy?.id || i?.unRegisteredRedeemerName}
                      </td>
                      <td>{i.redeemed ? "YES" : "NO"}</td>
                      <td>
                        {" "}
                        <Button
                          onClick={() =>
                            setSelectedGift({
                              modal: true,
                              gift: { ...i, giftCategory: i.giftCategory },
                            })
                          }
                          type="button"
                          className="btn btn-primary px-2"
                        >
                          <i className="" style={{ color: "white  " }}>
                            <FaEdit />{" "}
                          </i>
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        ) : (
          <p>{!isLoading && <NoDatFound />}</p>
        )}
      </div>
      {totalRecord > 10 && giftList.length > 0 && (
        <>
          <FormControl className="pagelimit">
            <FormLabel id="demo-row-radio-buttons-group-label">
              Page Size
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={pageLimit}
              onChange={(e: any) => {
                setPageLimit(e.target.value);
                setPageNumber(1);
              }}
            >
              <FormControlLabel value={10} control={<Radio />} label="10" />
              <FormControlLabel value={20} control={<Radio />} label="20" />
              <FormControlLabel value={50} control={<Radio />} label="50" />
            </RadioGroup>
          </FormControl>
          <Pagination
            itemsPerPage={pageLimit}
            totalPages={totalRecord}
            onPageChage={onPageChange}
            page={pageNumber - 1}
          />
        </>
      )}
      {selectedGift.modal && selectedGift.gift && (
        <>
          <EditGiftModal
            onModalClose={() => setSelectedGift({ modal: false, gift: {} })}
            gift={selectedGift.gift}
          />
        </>
      )}
    </div>
  );
};

export default GiftLits;
