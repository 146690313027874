import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { useParams } from "react-router";
// import AddPointModal from "../../Components/Activity/AddPointModal";
import NoDatFound from "../../Components/common/NoDatFound";
import { dispatch } from "../../redux";
import { Store } from "../../redux/Actions";
import { formatDate } from "../../util/utils";
import { getRentsList } from "../../redux/action";
import { Button } from "react-bootstrap";
// import Rent from "./Rent";
import EditGrantModal from "../../Components/Grant/EditGrantModal";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import Pagination from "../../Components/common/Paginate/Pagination";
import { FormattedMessage } from "react-intl";
import { FaEdit } from "../../reactIcons";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

const RentList = () => {
  const RentsList = useSelector((state: Store) => state.rentsReducer.RentsList);
  const [modal, setModal] = useState({ show: false, rent: {} });
  const isLoading = useSelector((state: Store) => state.rentsReducer.isLoading);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const totalRecord = useSelector(
    (state: Store) => state.rentsReducer.totalRecord,
  ) as any;

  const onPageChange = (pageNum: number) => {
    setPageNumber(pageNum);
  };

  useEffect(() => {
    dispatch(getRentsList(false, pageNumber, pageLimit));
  }, [pageNumber, pageLimit]);

  return (
    <div>
      <div className="table-responsive">
        {isLoading && <CenterLoader />}
        {RentsList && RentsList.length ? (
          <>
            {" "}
            <table className="table table-hover m-0">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="table.head.ID" defaultMessage="ID" />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.Type"
                      defaultMessage="Type"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.Quantity"
                      defaultMessage="Quantity"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.Member"
                      defaultMessage="Member"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.ValidUntil"
                      defaultMessage="Valid Until"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.Remarks"
                      defaultMessage="Remarks"
                    />
                  </th>
                  <th className="table-field-actions">
                    <FormattedMessage id="page.Edit" defaultMessage="Edit" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {RentsList &&
                  RentsList.length &&
                  RentsList.map((i: any) => (
                    <tr>
                      <td>{i.id}</td>
                      <td>{i?.type?.type}</td>
                      <td>{i.quantity}</td>
                      <td>{i.users.id}</td>
                      <td>{formatDate(i.valid, "YYYY-MM-DD    ")}</td>
                      <td>{i.remark}</td>
                      <td className="table-field-actions">
                        <Button
                          className="px-3"
                          onClick={() => setModal({ show: true, rent: i })}
                        >
                          <i className="text-accent-custom">
                            <FaEdit />{" "}
                          </i>
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        ) : (
          <p>{!isLoading && <NoDatFound />}</p>
        )}
      </div>
      {totalRecord > 10 && RentsList.length && (
        <>
          <FormControl className="pagelimit">
            <FormLabel id="demo-row-radio-buttons-group-label">
              <FormattedMessage id="page_size" defaultMessage="Page Size" />
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={pageLimit}
              onChange={(e: any) => {
                setPageLimit(e.target.value);
                setPageNumber(1);
              }}
            >
              <FormControlLabel value={10} control={<Radio />} label="10" />
              <FormControlLabel value={20} control={<Radio />} label="20" />
              <FormControlLabel value={50} control={<Radio />} label="50" />
            </RadioGroup>
          </FormControl>
          <Pagination
            itemsPerPage={pageLimit}
            totalPages={totalRecord}
            onPageChage={onPageChange}
            page={pageNumber - 1}
          />
        </>
      )}
      {/* Point */}
      {modal.show && (
        <EditGrantModal
          onModalClose={() => setModal({ show: false, rent: {} })}
          rent={modal.rent}
        />
      )}
    </div>
  );
};

export default RentList;
