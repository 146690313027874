/* eslint-disable no-case-declarations */
import {
  GET_CLASSES,
  ADD_CLASSES,
  GET_CLASSES_DETAIL,
  GET_CLASS_CATEGORY,
  EDIT_CLASSES,
  GET_PRODUCTS,
  GET_CLASS_TOOL_BY_TYPE,
  ADD_CLASS_TOOL_BY_TYPE,
  ADD_CLASS_TOOL_VERSION,
  GET_CLASS_TOOL_VERSION,
  SET_PAGE,
} from "../constants/action-types";
import { ClassData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { getToken } from "../../util/utils";
import { ActionTypes } from "../action";
// import { Posts } from "../Actions";

const initialState = {
  progress: false,
  isLoading: false,
  onSaveLoading: false,
  isLoggedin: JSON.parse(getToken("isLoggedIn") || "false"),
  isLoggedout: false,
  isKencorLoggedin: false,
  isRegisterd: false,
  isForgotpasswordSuccess: false,
  authToken: "",
  classes: {},
  classDetail: {},
  posts: [],
  classCategories: [],
  products: [],
  classTool: [],
  classVersion: [],
  addTools: [],
  totalRecord: 0,
  currentPage: 1,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: ClassData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case SET_PAGE:
        return {
          ...state,
          currentPage: action.payload,
        };

      case GET_CLASSES.GET_CLASSES_INITIALIZATION:
        return {
          ...state,
          totalRecord: 0,
          isLoading: true,
        };

      case GET_CLASSES.GET_CLASSES_SUCCESS:
        const allPost: any = action.payload; // [{ id: 2, body: "Abcd", title: "title", userId: 3 }, { id: 2, body: "Abcd", title: "title", userId: 3 }];
        return {
          ...state,
          classes: allPost.data,
          totalRecord: allPost.totalCount,
          isLoading: false,
        };

      case GET_CLASSES.GET_CLASSES_ERROR:
        return {
          ...state,
          totalRecord: 0,
          isLoading: false,
        };
      case ADD_CLASSES.ADD_CLASSES_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case ADD_CLASSES.ADD_CLASSES_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case ADD_CLASSES.ADD_CLASSES_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };
      case GET_CLASSES_DETAIL.GET_CLASSES_DETAIL_INITIALIZATION:
        return {
          ...state,
          classDetail: {},
          isLoading: true,
        };

      case GET_CLASSES_DETAIL.GET_CLASSES_DETAIL_SUCCESS:
        const classDetail = action.payload; // [{ id: 2, body: "Abcd", title: "title", userId: 3 }, { id: 2, body: "Abcd", title: "title", userId: 3 }];
        return {
          ...state,
          classDetail: classDetail,
          isLoading: false,
        };

      case GET_CLASSES_DETAIL.GET_CLASSES_DETAIL_ERROR:
        return {
          ...state,
          isLoading: false,
        };
      case GET_CLASS_CATEGORY.GET_CLASS_CATEGORY_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_CLASS_CATEGORY.GET_CLASS_CATEGORY_SUCCESS:
        return {
          ...state,
          classCategories: action.payload,
          isLoading: false,
        };

      case GET_CLASS_CATEGORY.GET_CLASS_CATEGORY_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case EDIT_CLASSES.EDIT_CLASSES_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case EDIT_CLASSES.EDIT_CLASSES_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case EDIT_CLASSES.EDIT_CLASSES_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case GET_PRODUCTS.GET_PRODUCTS_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_PRODUCTS.GET_PRODUCTS_SUCCESS:
        return {
          ...state,
          products: action.payload,
          isLoading: false,
        };

      case GET_PRODUCTS.GET_PRODUCTS_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_CLASS_TOOL_BY_TYPE.GET_CLASS_TOOL_BY_TYPE_INITIALIZATION:
        return {
          ...state,
          totalRecord: 0,
          isLoading: true,
        };
      case GET_CLASS_TOOL_BY_TYPE.GET_CLASS_TOOL_BY_TYPE_SUCCESS:
        const classTool: any = action.payload;
        return {
          ...state,
          isLoading: false,
          totalRecord: classTool.totalCounts,
          classTool: classTool.data,
        };

      case GET_CLASS_TOOL_BY_TYPE.GET_CLASS_TOOL_BY_TYPE_ERROR:
        return {
          ...state,
          totalRecord: 0,
          isLoading: false,
        };

      case ADD_CLASS_TOOL_BY_TYPE.ADD_CLASS_TOOL_BY_TYPE_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case ADD_CLASS_TOOL_BY_TYPE.ADD_CLASS_TOOL_BY_TYPE_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };
      case ADD_CLASS_TOOL_BY_TYPE.ADD_CLASS_TOOL_BY_TYPE_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case GET_CLASS_TOOL_VERSION.GET_CLASS_TOOL_VERSION_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };
      case GET_CLASS_TOOL_VERSION.GET_CLASS_TOOL_VERSION_SUCCESS:
        const classVersion: any = action.payload;
        return {
          ...state,
          isLoading: false,
          totalRecord: classVersion.totalCount || classVersion.data.length,
          classVersion: classVersion.data,
        };

      case GET_CLASS_TOOL_VERSION.GET_CLASS_TOOL_VERSION_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_CLASS_TOOL_VERSION.ADD_CLASS_TOOL_VERSION_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case ADD_CLASS_TOOL_VERSION.ADD_CLASS_TOOL_VERSION_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };
      case ADD_CLASS_TOOL_VERSION.ADD_CLASS_TOOL_VERSION_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      default:
        return state;
    }
  },
);

export default reducer;
