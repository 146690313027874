import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import ReactPaginate from "react-paginate";

const Pagination = ({ itemsPerPage, totalPages, onPageChage, page }: any) => {
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    setPageCount(Math.ceil(totalPages / itemsPerPage));
  }, [itemsPerPage, totalPages]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    setPageCount(Math.ceil(totalPages / itemsPerPage));

    onPageChage(event.selected + 1);
    sessionStorage.setItem("page", (event.selected + 1).toString());
    if (process.env.REACT_APP_RECORDS_PER_PAGE !== undefined) {
      let page = event.selected;
      if (page === 0) {
        page = 1;
      }
    }
  };

  return (
    <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
      {/* <Col
        style={{
          padding: "1rem",
          paddingLeft: "2rem",
          fontSize: "14px",
          color: "grey",
        }}
      ></Col> */}
      <Col>
        <ReactPaginate
          nextLabel={
            <FormattedMessage id="pagination.Next" defaultMessage="next" />
          }
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          marginPagesDisplayed={3}
          pageCount={pageCount}
          forcePage={page}
          previousLabel={
            <FormattedMessage
              id="pagination.Previous"
              defaultMessage="previous"
            />
          }
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination page-list"
          activeClassName="active"
        />
      </Col>
    </Row>
  );
};

export default Pagination;
