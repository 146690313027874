import React from "react";
import { FormattedMessage } from "react-intl";

export const ManageUserPermission = () => {
  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                <FormattedMessage
                  id="Admin.Referral"
                  defaultMessage="Admin Referral"
                />
              </h1>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid"> User</section>
      </div>
    </div>
  );
};
