/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/APIServices/baseApiCall";
import {
  GET_ANNOUNCEMENT_LIST,
  DELETE_ANNOUNCEMENT_COMMENT,
  EDIT_ANNOUNCEMENT_LIST,
  GET_ANNOUNCEMENT_DETAILS,
  ADD_ANNOUNCEMENT,
  ADD_ANNOUNCEMENT_COMMENT,
  GET_ANNOUNCEMENT_COMMENT,
  DELETE_ANNOUNCEMENT,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { getAPIEndPoint, setToken } from "../../util/utils";
import {
  GET_ANNOUNCEMENT_LIST_URL,
  DELETE_ANNOUNCEMENT_COMMENT_URL,
  EDIT_ANNOUNCEMENT_LIST_URL,
  ADD_ANNOUNCEMENT_COMMENT_LIST_URL,
  GET_ANNOUNCEMENT_COMMENT_LIST_URL,
  DELETE_ANNOUNCEMENT_URL,
} from "../../service/APIServices/apiEndPoints";
import { toast } from "react-toastify";

export const getAnnouncements =
  (
    showToast: any = false,
    pageNumber = 0,
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getAnnouncementsInit(showToast, pageNumber, pageLimit));
  };

export const getAnnouncementsInit =
  (
    showToast: any = true,
    pageNumber: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ANNOUNCEMENT_LIST.GET_ANNOUNCEMENT_LIST_INITLIZATION,
    });

    apiCall(
      getAPIEndPoint(
        GET_ANNOUNCEMENT_LIST_URL + "?sort=DESC&sortBy=createdAt",
        pageNumber,
        true,
        pageLimit,
      ),
      {},
      (res: Object) => dispatch(getAnnouncementsSuccess(res)),
      (err: any) => {
        dispatch(getAnnouncementsError());
      },
      METHOD.GET,
      {
        showToast: showToast,
      },
    );
  };

export const getAnnouncementsSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ANNOUNCEMENT_LIST.GET_ANNOUNCEMENT_LIST_SUCCESS,
      payload: res,
    });
  };

export const getAnnouncementsError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_ANNOUNCEMENT_LIST.GET_ANNOUNCEMENT_LIST_ERORR,
    });
  };

export const editAnnouncements =
  (
    showToast: any = true,
    id: any,
    announcementsDetails: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(editAnnouncementsInit(showToast, id, announcementsDetails));
  };

export const editAnnouncementsInit =
  (
    showToast: any = true,
    id: any,
    announcementsDetails: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_ANNOUNCEMENT_LIST.EDIT_ANNOUNCEMENT_LIST_INITLIZATION,
    });

    apiCall(
      EDIT_ANNOUNCEMENT_LIST_URL + id,
      announcementsDetails,
      (res: Object) => dispatch(editAnnouncementsSuccess(res, id)),
      (err: any) => {
        dispatch(editAnnouncementsError());
      },
      METHOD.PUT,
      {
        showToast: true,
      },
    );
  };

export const editAnnouncementsSuccess =
  (res: any, id: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_ANNOUNCEMENT_LIST.EDIT_ANNOUNCEMENT_LIST_SUCCESS,
      payload: res.data,
    });
    dispatch(getAnnouncementDetails(false, id));
  };

export const editAnnouncementsError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: EDIT_ANNOUNCEMENT_LIST.EDIT_ANNOUNCEMENT_LIST_ERORR,
    });
  };

export const getAnnouncementDetails =
  (
    showToast = true,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getAnnouncementDetailsInit(showToast, id));
  };

export const getAnnouncementDetailsInit =
  (
    showToast = true,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ANNOUNCEMENT_DETAILS.GET_ANNOUNCEMENT_DETAILS_INITIALIZATION,
    });
    apiCall(
      GET_ANNOUNCEMENT_LIST_URL + "/" + id,
      {},
      (res: Object) => dispatch(getAnnouncementDetailsSuccess(res)),
      (err: any) => dispatch(getAnnouncementDetailsError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getAnnouncementDetailsSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ANNOUNCEMENT_DETAILS.GET_ANNOUNCEMENT_DETAILS_SUCCESS,
      payload: res.data,
    });
  };

export const getAnnouncementDetailsError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_ANNOUNCEMENT_DETAILS.GET_ANNOUNCEMENT_DETAILS_ERROR,
    });
  };

export const addAnnouncement =
  (
    showToast = true,
    announcementData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(addAnnouncementInit(showToast, announcementData));
  };

export const addAnnouncementInit =
  (
    showToast = true,
    announcementData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_ANNOUNCEMENT.ADD_ANNOUNCEMENT_INITIALIZATION,
    });
    apiCall(
      GET_ANNOUNCEMENT_LIST_URL,
      announcementData,
      (res: Object) => dispatch(addAnnouncementSuccess(res)),
      (err: any) => dispatch(addAnnouncementError()),
      METHOD.POST,
      { showToast },
    );
  };

export const addAnnouncementSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_ANNOUNCEMENT.ADD_ANNOUNCEMENT_SUCCESS,
    });
    dispatch(getAnnouncements(false, 1));
  };

export const addAnnouncementError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_ANNOUNCEMENT.ADD_ANNOUNCEMENT_ERROR,
    });
  };

export const deleteAnnouncement =
  (
    showToast = true,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(deleteAnnouncementInit(showToast, id));
  };

export const deleteAnnouncementInit =
  (
    showToast = true,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_ANNOUNCEMENT.DELETE_ANNOUNCEMENT_INITLIZATION,
    });
    apiCall(
      DELETE_ANNOUNCEMENT_URL + id,
      {},
      (res: Object) => dispatch(deleteAnnouncementSuccess(res)),
      (err: any) => dispatch(deleteAnnouncementError()),
      METHOD.DELETE,
      { showToast },
    );
  };

export const deleteAnnouncementSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_ANNOUNCEMENT.DELETE_ANNOUNCEMENT_SUCCESS,
    });
    dispatch(getAnnouncements(false));
  };

export const deleteAnnouncementError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: DELETE_ANNOUNCEMENT.DELETE_ANNOUNCEMENT_ERORR,
    });
  };

export const addAnnouncementComment =
  (
    showToast = true,
    announcementData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(addAnnouncementCommentInit(showToast, announcementData));
  };

export const addAnnouncementCommentInit =
  (
    showToast = true,
    announcementData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_ANNOUNCEMENT_COMMENT.ADD_ANNOUNCEMENT_COMMENT_INITIALIZATION,
    });
    apiCall(
      ADD_ANNOUNCEMENT_COMMENT_LIST_URL,
      announcementData,
      (res: Object) =>
        dispatch(
          addAnnouncementCommentSuccess(res, announcementData.announcement),
        ),
      (err: any) => dispatch(addAnnouncementCommentError()),
      METHOD.POST,
      { showToast },
    );
  };

export const addAnnouncementCommentSuccess =
  (res: any, id: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_ANNOUNCEMENT_COMMENT.ADD_ANNOUNCEMENT_COMMENT_SUCCESS,
    });
    dispatch(getAnnouncementComment(false, id));
  };

export const addAnnouncementCommentError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_ANNOUNCEMENT_COMMENT.ADD_ANNOUNCEMENT_COMMENT_ERROR,
    });
  };

export const getAnnouncementComment =
  (
    showToast = true,
    id: any,
    pageNumber = 1,
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getAnnouncementCommentInit(showToast, id, pageNumber, pageLimit));
  };

export const getAnnouncementCommentInit =
  (
    showToast = true,
    id: any,
    pageNumber: any,
    pageLimit: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ANNOUNCEMENT_COMMENT.GET_ANNOUNCEMENT_COMMENT_INITIALIZATION,
    });
    apiCall(
      GET_ANNOUNCEMENT_COMMENT_LIST_URL +
        id +
        `?page=${pageNumber}&perPage=${pageLimit}`,
      {},
      (res: Object) => dispatch(getAnnouncementCommentSuccess(res)),
      (err: any) => dispatch(getAnnouncementCommentError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getAnnouncementCommentSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ANNOUNCEMENT_COMMENT.GET_ANNOUNCEMENT_COMMENT_SUCCESS,
      payload: res,
    });
  };

export const getAnnouncementCommentError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_ANNOUNCEMENT_COMMENT.GET_ANNOUNCEMENT_COMMENT_ERROR,
    });
  };

export const deleteAnnouncementComment =
  (
    showToast = true,
    id: any,
    announcementId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(deleteAnnouncementCommentInit(showToast, id, announcementId));
  };

export const deleteAnnouncementCommentInit =
  (
    showToast = true,
    id: any,
    announcementId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_ANNOUNCEMENT_COMMENT.DELETE_ANNOUNCEMENT_COMMENT_INITLIZATION,
    });
    apiCall(
      DELETE_ANNOUNCEMENT_COMMENT_URL + id,
      {},
      (res: Object) =>
        dispatch(deleteAnnouncementCommentSuccess(res, announcementId)),
      (err: any) => dispatch(deleteAnnouncementCommentError()),
      METHOD.DELETE,
      { showToast },
    );
  };

export const deleteAnnouncementCommentSuccess =
  (
    res: any,
    announcementId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_ANNOUNCEMENT_COMMENT.DELETE_ANNOUNCEMENT_COMMENT_SUCCESS,
      payload: res.data,
    });
    dispatch(getAnnouncementComment(false, announcementId));
  };

export const deleteAnnouncementCommentError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: DELETE_ANNOUNCEMENT_COMMENT.DELETE_ANNOUNCEMENT_COMMENT_ERORR,
    });
  };
