/* eslint-disable no-case-declarations */
import {
  GET_ADMIN_ROLES_LIST,
  GET_ADMIN_ROLES_TOOLS_LIST,
  GET_ADMIN_ROLES_USER_EA_ACCOUNT_LIST,
  GET_ADMIN_ROLES_USER_LOGGINGS_LIST,
  GET_ADMIN_ROLES_USER_PERMISSIONS_LIST,
} from "../constants/action-types";
import { AdminRolesData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";
// import { Posts } from "../Actions";

const initialState = {
  progress: false,
  isLoading: false,
  onSaveLoading: false,
  adminRoleList: [],
  adminUserPermissionList: [],
  permenetEa: {},
  adminUserEAAccountList: [],
  toolList: {},
  UserLoggingList: [],
  totalRecord: 0,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: AdminRolesData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case GET_ADMIN_ROLES_LIST.GET_ADMIN_ROLES_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
          totalRecord: 0,
        };

      case GET_ADMIN_ROLES_LIST.GET_ADMIN_ROLES_LIST_SUCCESS:
        const allPost: any = action.payload; // [{ id: 2, body: "Abcd", title: "title", userId: 3 }, { id: 2, body: "Abcd", title: "title", userId: 3 }];
        return {
          ...state,
          adminRoleList: allPost.data,
          isLoading: false,
          totalRecord: allPost.totalCount,
        };

      case GET_ADMIN_ROLES_LIST.GET_ADMIN_ROLES_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_ADMIN_ROLES_TOOLS_LIST.GET_ADMIN_ROLES_TOOLS_LIST_INITIALIZATION:
        return {
          ...state,
          toolList: [], // action.payload,
          totalRecord: 0, // action.payload,
          isLoading: true,
        };

      case GET_ADMIN_ROLES_TOOLS_LIST.GET_ADMIN_ROLES_TOOLS_LIST_SUCCESS:
        const act: any = action.payload;
        return {
          ...state,
          toolList: act.data, // action.payload,
          totalRecord: act.totalCount, // action.payload,
          isLoading: false,
        };

      case GET_ADMIN_ROLES_TOOLS_LIST.GET_ADMIN_ROLES_TOOLS_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_ADMIN_ROLES_USER_PERMISSIONS_LIST.GET_ADMIN_ROLES_USER_PERMISSIONS_LIST_INITIALIZATION:
        return {
          ...state,
          adminUserPermissionList: [],
          permenetEa: {},
          isLoading: true,
        };

      case GET_ADMIN_ROLES_USER_PERMISSIONS_LIST.GET_ADMIN_ROLES_USER_PERMISSIONS_LIST_SUCCESS:
        const permissions: any = action.payload;
        return {
          ...state,
          adminUserPermissionList: permissions.data,
          permenetEa: permissions.permenetEa,
          totalRecord: permissions.count,
          isLoading: false,
        };

      case GET_ADMIN_ROLES_USER_PERMISSIONS_LIST.GET_ADMIN_ROLES_USER_PERMISSIONS_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };
      case GET_ADMIN_ROLES_USER_EA_ACCOUNT_LIST.GET_ADMIN_ROLES_USER_EA_ACCOUNT_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_ADMIN_ROLES_USER_EA_ACCOUNT_LIST.GET_ADMIN_ROLES_USER_EA_ACCOUNT_LIST_SUCCESS:
        const a: any = action.payload;
        return {
          ...state,
          adminUserEAAccountList: a.data,
          totalRecord: a.totalCount,
          isLoading: false,
        };

      case GET_ADMIN_ROLES_USER_EA_ACCOUNT_LIST.GET_ADMIN_ROLES_USER_EA_ACCOUNT_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_ADMIN_ROLES_USER_LOGGINGS_LIST.GET_ADMIN_ROLES_USER_LOGGINGS_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_ADMIN_ROLES_USER_LOGGINGS_LIST.GET_ADMIN_ROLES_USER_LOGGINGS_LIST_SUCCESS:
        const userLogging: any = action.payload;
        return {
          ...state,
          UserLoggingList: userLogging.data, //action.payload,
          totalRecord: userLogging.totalCount,
          isLoading: false,
        };

      case GET_ADMIN_ROLES_USER_LOGGINGS_LIST.GET_ADMIN_ROLES_USER_LOGGINGS_LIST_ERROR:
        return {
          ...state,
          UserLoggingList: [], //action.payload,
          isLoading: false,
        };

      default:
        return state;
    }
  },
);

export default reducer;
