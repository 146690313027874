/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/APIServices/baseApiCall";
import {
  GET_GRANT_LIST,
  GRANT_TOOL_LIST,
  GRANT_POINT_LIST,
  GRANT_ACCOUNT,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { getAPIEndPoint, setToken } from "../../util/utils";
import {
  GET_GRANTS_LIST_URL,
  GRANT_ACCOUNT_URL,
  GRANT_POINT_LIST_URL,
  GRANT_TOOL_LIST_URL,
} from "../../service/APIServices/apiEndPoints";
import { toast } from "react-toastify";
// import { _getExpressWayPendingList } from "../../service";
// import { AxiosResponse } from "axios";

//Get Admin Roles List
export const getGrantList =
  (
    showToast = true,
    pageNumber = 0,
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getGrantListInit(showToast, pageNumber, pageLimit));
  };

export const getGrantListInit =
  (
    showToast = true,
    pageNumber: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_GRANT_LIST.GET_GRANT_LIST_INITIALIZATION,
    });
    apiCall(
      getAPIEndPoint(GET_GRANTS_LIST_URL, pageNumber, false, pageLimit) +
        "&sort=DESC&sortBy=createdAt",
      {},
      (res: Object) => dispatch(getGrantListSuccess(res)),
      (err: any) => dispatch(getGrantListError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getGrantListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_GRANT_LIST.GET_GRANT_LIST_SUCCESS,
      payload: res,
    });
  };

export const getGrantListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_GRANT_LIST.GET_GRANT_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const GrantPoints =
  (
    showToast = true,
    values: any,
    type: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(GrantPointsInit(showToast, values, type));
  };

export const GrantPointsInit =
  (
    showToast = true,
    values: any,
    type: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GRANT_POINT_LIST.GRANT_POINT_LIST_INITIALIZATION,
    });
    apiCall(
      GRANT_POINT_LIST_URL + "/" + type,
      values,
      (res: Object) => dispatch(GrantPointsSuccess(res)),
      (err: any) => dispatch(GrantPointsError()),
      METHOD.POST,
      { showToast },
    );
  };

export const GrantPointsSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GRANT_POINT_LIST.GRANT_POINT_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const GrantPointsError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GRANT_POINT_LIST.GRANT_POINT_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const GrantTools =
  (
    showToast = true,
    tool: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(GrantToolsInit(showToast, tool));
  };

export const GrantToolsInit =
  (
    showToast = true,
    tool: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GRANT_TOOL_LIST.GRANT_TOOL_LIST_INITIALIZATION,
    });
    apiCall(
      GRANT_TOOL_LIST_URL,
      tool,
      (res: Object) => dispatch(GrantToolsSuccess(res)),
      (err: any) => dispatch(GrantToolsError()),
      METHOD.POST,
      { showToast },
    );
  };

export const GrantToolsSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GRANT_TOOL_LIST.GRANT_TOOL_LIST_SUCCESS,
      payload: res.data,
    });
    // dispatch(getGrantList(false));
  };

export const GrantToolsError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GRANT_TOOL_LIST.GRANT_TOOL_LIST_ERROR,
    });
  };

// Grant Account

//Get Admin Roles List
export const GrantAccount =
  (
    showToast = true,
    account: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(GrantAccountInit(showToast, account));
  };

export const GrantAccountInit =
  (
    showToast = true,
    account: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GRANT_ACCOUNT.GRANT_ACCOUNT_INITIALIZATION,
    });
    apiCall(
      GRANT_ACCOUNT_URL,
      account,
      (res: Object) => dispatch(GrantAccountSuccess(res)),
      (err: any) => dispatch(GrantAccountError()),
      METHOD.POST,
      { showToast },
    );
  };

export const GrantAccountSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GRANT_ACCOUNT.GRANT_ACCOUNT_SUCCESS,
      payload: res.data,
    });
    // dispatch(getGrantList(false));
  };

export const GrantAccountError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GRANT_ACCOUNT.GRANT_ACCOUNT_ERROR,
    });
  };
