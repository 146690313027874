import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { UpdateGemsAdvertisementByIdDto } from "../schemas/updateGemsAdvertisementSchema";
import { GemsAdvertisementResponse } from "../types";
import { customFetch } from "../../../util/axios";

interface UpdateGemsAdvertisementResponse {
  gemsAdvertisement: GemsAdvertisementResponse;
}

const updateGemsAdvertisementById = async (
  updateGemsAdvertisementByIdDto: UpdateGemsAdvertisementByIdDto,
) => {
  const {
    params: { gemsAdvertisementId },
    body,
  } = updateGemsAdvertisementByIdDto;
  const response = await customFetch.patch(
    `/gems-advertisements/${gemsAdvertisementId}`,
    body,
  );
  return response.data;
};

export const useUpdateGemsAdvertisementById = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<
    UpdateGemsAdvertisementResponse,
    AxiosError | Error,
    UpdateGemsAdvertisementByIdDto
  >({
    mutationFn: updateGemsAdvertisementById,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["gems-advertisements"],
      }),
  });

  return mutation;
};
