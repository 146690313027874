/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/APIServices/baseApiCall";
import {
  GET_CLASSES,
  ADD_CLASSES,
  GET_CLASSES_DETAIL,
  GET_CLASS_CATEGORY,
  EDIT_CLASSES,
  GET_PRODUCTS,
  ADD_CLASS_TOOL_BY_TYPE,
  GET_CLASS_TOOL_BY_TYPE,
  ADD_CLASS_TOOL_VERSION,
  GET_CLASS_TOOL_VERSION,
  DELETE_CLASS_TOOL_VERSION,
  DELETE_CLASS_TOOL,
  GRADUATE_ALL_STUDENT_CLASS,
  SET_PAGE,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { getAPIEndPoint } from "../../util/utils";
import {
  GET_CLASS_CATEGORY_URL,
  USER_SIGNIN,
  EDIT_CLASS_URL,
  GET_PRODUCTS_URL,
  ADD_CLASS_TOOL_BY_TYPE_URL,
  GET_CLASS_TOOL_BY_TYPE_URL,
  ADD_CLASS_TOOL_VERSION_URL,
  GET_CLASS_TOOL_VERSION_URL,
  DELETE_CLASS_TOOL_VERSION_URL,
  DELETE_CLASS_TOOL_URL,
  GRADUATE_ALL_STUDENT_CLASS_URL,
} from "../../service/APIServices/apiEndPoints";
import { _getClasses } from "../../service";

export const setCurrentPage =
  (pageNumber: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: SET_PAGE,
      payload: pageNumber,
    });
  };

//Get classes
export const getClasses =
  (
    showToast = true,
    pageNumber = 1,
    sort: any,
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getClassesInit(showToast, pageNumber, sort, pageLimit));
  };

export const getClassesInit =
  (
    showToast = true,
    pageNumber: number,
    sort: any,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_CLASSES.GET_CLASSES_INITIALIZATION,
    });
    apiCall(
      getAPIEndPoint(
        `class?sort=${sort.order}&sortBy=${sort.by}`,
        pageNumber,
        true,
        pageLimit,
      ),
      {},
      (res: Object) => dispatch(getClassesSuccess(res)),
      (err: any) => dispatch(getClassesError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getClassesSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_CLASSES.GET_CLASSES_SUCCESS,
      payload: res,
    });
  };

export const getClassesError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_CLASSES.GET_CLASSES_ERROR,
    });
  };

//Get classes
export const addClasses =
  (
    classdata: any,
    showToast = true,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(addClassesInit(classdata, showToast));
  };

export const addClassesInit =
  (
    classdata: any,
    showToast: boolean,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_CLASSES.ADD_CLASSES_INITIALIZATION,
    });
    apiCall(
      `class`,
      classdata,
      (res: Object) => dispatch(addClassesSuccess(res)),
      (err: any) => dispatch(addClassesError()),
      METHOD.POST,
      { showToast },
    );
  };

export const addClassesSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_CLASSES.ADD_CLASSES_SUCCESS,
      payload: res,
    });
    dispatch(setCurrentPage(1));
    dispatch(getClasses(false, 1, { by: "createdAt", order: "DESC" }));
  };

export const addClassesError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_CLASSES.ADD_CLASSES_ERROR,
    });
  };

//Get classes
export const getClassDetail =
  (classId: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getClassDetailInit(classId));
  };

export const getClassDetailInit =
  (classId: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_CLASSES_DETAIL.GET_CLASSES_DETAIL_INITIALIZATION,
    });
    apiCall(
      `class/${classId}`,
      {},
      (res: Object) => dispatch(getClassDetailSuccess(res)),
      (err: any) => dispatch(getClassDetailError()),
      METHOD.GET,
      {},
    );
  };

export const getClassDetailSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_CLASSES_DETAIL.GET_CLASSES_DETAIL_SUCCESS,
      payload: res.data,
    });
  };

export const getClassDetailError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_CLASSES_DETAIL.GET_CLASSES_DETAIL_ERROR,
    });
  };

//Get classes
export const getClassCategories =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getClassCategoriesInit(showToast));
  };

export const getClassCategoriesInit =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_CLASS_CATEGORY.GET_CLASS_CATEGORY_INITIALIZATION,
    });
    apiCall(
      GET_CLASS_CATEGORY_URL,
      {},
      (res: Object) => dispatch(getClassCategoriesSuccess(res)),
      (err: any) => dispatch(getClassCategoriesError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getClassCategoriesSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_CLASS_CATEGORY.GET_CLASS_CATEGORY_SUCCESS,
      payload: res.data,
    });
  };

export const getClassCategoriesError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_CLASS_CATEGORY.GET_CLASS_CATEGORY_ERROR,
    });
  };

//Edit classes
export const editClasses =
  (
    classdata: any,
    classId: any,
    showToast = true,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(editClassesInit(classdata, classId, showToast));
  };

export const editClassesInit =
  (
    classdata: any,
    classId: any,
    showToast = true,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_CLASSES.EDIT_CLASSES_INITIALIZATION,
    });
    apiCall(
      EDIT_CLASS_URL + classId,
      { ...classdata },
      (res: Object) => dispatch(editClassesSuccess(res)),
      (err: any) => dispatch(editClassesError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const editClassesSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_CLASSES.EDIT_CLASSES_SUCCESS,
      payload: res,
    });
    const page = sessionStorage.getItem("page") || "1";
    dispatch(
      getClasses(false, parseInt(page), { by: "createdAt", order: "DESC" }),
    );
  };

export const editClassesError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: EDIT_CLASSES.EDIT_CLASSES_ERROR,
    });
  };

//Get Products
export const getProducts =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getProductsInit(showToast));
  };

export const getProductsInit =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_PRODUCTS.GET_PRODUCTS_INITIALIZATION,
    });
    apiCall(
      GET_PRODUCTS_URL,
      {},
      (res: Object) => dispatch(getProductsSuccess(res)),
      (err: any) => dispatch(getProductsError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getProductsSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_PRODUCTS.GET_PRODUCTS_SUCCESS,
      payload: res.data,
    });
  };

export const getProductsError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_PRODUCTS.GET_PRODUCTS_ERROR,
    });
  };

//get AddClass Tool
export const getClassTool =
  (
    showToast = true,
    classId: number,
    pageNumber = 0,
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getClassToolInit(showToast, classId, pageNumber, pageLimit));
  };

export const getClassToolInit =
  (
    showToast = true,
    classId: number,
    pageNumber: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_CLASS_TOOL_BY_TYPE.GET_CLASS_TOOL_BY_TYPE_INITIALIZATION,
    });
    apiCall(
      getAPIEndPoint(
        `${GET_CLASS_TOOL_BY_TYPE_URL}${classId}`,
        pageNumber,
        false,
        pageLimit,
      ),
      {},
      (res: Object) => dispatch(getClassToolSuccess(res)),
      (err: any) => dispatch(getClassToolError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getClassToolSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_CLASS_TOOL_BY_TYPE.GET_CLASS_TOOL_BY_TYPE_SUCCESS,
      payload: res,
    });
  };

export const getClassToolError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_CLASS_TOOL_BY_TYPE.GET_CLASS_TOOL_BY_TYPE_ERROR,
    });
  };

export const addClassTool =
  (
    showToast = true,
    classId: any,
    tool: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(addClassToolInit(showToast, classId, tool));
  };

export const addClassToolInit =
  (
    showToast = true,
    classId: any,
    tool: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_CLASS_TOOL_BY_TYPE.ADD_CLASS_TOOL_BY_TYPE_INITIALIZATION,
    });
    apiCall(
      `${ADD_CLASS_TOOL_BY_TYPE_URL}${classId}`,
      tool,
      (res: Object) => dispatch(addClassToolSuccess(res, classId)),
      (err: any) => dispatch(addClassToolError()),
      METHOD.POST,
      { showToast },
    );
  };

export const addClassToolSuccess =
  (res: any, classId: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_CLASS_TOOL_BY_TYPE.ADD_CLASS_TOOL_BY_TYPE_SUCCESS,
    });
    dispatch(getClassTool(false, classId));
  };

export const addClassToolError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_CLASS_TOOL_BY_TYPE.ADD_CLASS_TOOL_BY_TYPE_ERROR,
    });
  };

//get AddClass Tool
export const getClassToolVersion =
  (
    showToast = true,
    classId: number,
    pageNumber = 0,
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      getClassToolVersionInit(showToast, classId, pageNumber, pageLimit),
    );
  };

export const getClassToolVersionInit =
  (
    showToast = true,
    classId: number,
    pageNumber: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_CLASS_TOOL_VERSION.GET_CLASS_TOOL_VERSION_INITIALIZATION,
    });
    apiCall(
      getAPIEndPoint(
        `${GET_CLASS_TOOL_VERSION_URL}${classId}`,
        pageNumber,
        false,
        pageLimit,
      ),
      {},
      (res: Object) => dispatch(getClassToolVersionSuccess(res)),
      (err: any) => dispatch(getClassToolVersionError()),
      METHOD.GET,
      { showToast },
    );
  };

export const getClassToolVersionSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_CLASS_TOOL_VERSION.GET_CLASS_TOOL_VERSION_SUCCESS,
      payload: res,
    });
  };

export const getClassToolVersionError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_CLASS_TOOL_VERSION.GET_CLASS_TOOL_VERSION_ERROR,
    });
  };

// Addclasstool
export const addClassToolVersion =
  (
    showToast = true,
    classId: any,
    tool: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(addClassToolVersionInit(showToast, classId, tool));
  };

export const addClassToolVersionInit =
  (
    showToast = true,
    classId: any,
    tool: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_CLASS_TOOL_VERSION.ADD_CLASS_TOOL_VERSION_INITIALIZATION,
    });
    apiCall(
      `${ADD_CLASS_TOOL_VERSION_URL}${classId}`,
      tool,
      (res: Object) => dispatch(addClassToolVersionSuccess(res, classId)),
      (err: any) => dispatch(addClassToolVersionError()),
      METHOD.POST,
      { showToast },
    );
  };

export const addClassToolVersionSuccess =
  (res: any, classId: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_CLASS_TOOL_VERSION.ADD_CLASS_TOOL_VERSION_SUCCESS,
    });
    dispatch(getClassToolVersion(false, classId));
  };

export const addClassToolVersionError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_CLASS_TOOL_VERSION.ADD_CLASS_TOOL_VERSION_ERROR,
    });
  };

export const deleteClassToolVersion =
  (
    showToast = true,
    classId: any,
    tool: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(deleteClassToolVersionInit(showToast, classId, tool));
  };

export const deleteClassToolVersionInit =
  (
    showToast = true,
    classId: any,
    tool: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_CLASS_TOOL_VERSION.DELETE_CLASS_TOOL_VERSION_INITIALIZATION,
    });
    apiCall(
      `${DELETE_CLASS_TOOL_VERSION_URL}${classId}`,
      tool,
      (res: Object) => dispatch(deleteClassToolVersionSuccess(res, tool)),
      (err: any) => dispatch(deleteClassToolVersionError()),
      METHOD.DELETE,
      { showToast },
    );
  };

export const deleteClassToolVersionSuccess =
  (res: any, classId: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_CLASS_TOOL_VERSION.DELETE_CLASS_TOOL_VERSION_SUCCESS,
    });
    dispatch(getClassToolVersion(false, classId));
  };

export const deleteClassToolVersionError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: DELETE_CLASS_TOOL_VERSION.DELETE_CLASS_TOOL_VERSION_ERROR,
    });
  };

export const deleteClassTool =
  (
    showToast = true,
    classId: any,
    tool: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(deleteClassToolInit(showToast, classId, tool));
  };

export const deleteClassToolInit =
  (
    showToast = true,
    classId: any,
    tool: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_CLASS_TOOL.DELETE_CLASS_TOOL_INITIALIZATION,
    });
    apiCall(
      `${DELETE_CLASS_TOOL_URL}${classId}`,
      tool,
      (res: Object) => dispatch(deleteClassToolSuccess(res, tool)),
      (err: any) => dispatch(deleteClassToolError()),
      METHOD.DELETE,
      { showToast },
    );
  };

export const deleteClassToolSuccess =
  (res: any, classId: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_CLASS_TOOL.DELETE_CLASS_TOOL_SUCCESS,
    });
    dispatch(getClassTool(false, classId));
  };

export const deleteClassToolError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: DELETE_CLASS_TOOL.DELETE_CLASS_TOOL_ERROR,
    });
  };

export const graduateStudents =
  (
    showToast = true,
    classId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(graduateStudentsInit(showToast, classId));
  };

export const graduateStudentsInit =
  (
    showToast = true,
    classId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GRADUATE_ALL_STUDENT_CLASS.GRADUATE_ALL_STUDENT_CLASS_INITIALIZATION,
    });
    apiCall(
      `${GRADUATE_ALL_STUDENT_CLASS_URL}${classId}`,
      {},
      (res: Object) => dispatch(graduateStudentsSuccess(res)),
      (err: any) => dispatch(graduateStudentsError()),
      METHOD.PUT,
      { showToast },
    );
  };

export const graduateStudentsSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GRADUATE_ALL_STUDENT_CLASS.GRADUATE_ALL_STUDENT_CLASS_SUCCESS,
    });
    dispatch(getClasses(false, 1, { by: "createdAt", order: "DESC" }));
  };

export const graduateStudentsError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GRADUATE_ALL_STUDENT_CLASS.GRADUATE_ALL_STUDENT_CLASS_ERROR,
    });
  };
