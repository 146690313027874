/* eslint-disable no-case-declarations */
import {
  GET_EXPRESSWAY_PENDING_LIST,
  GET_EXPRESSWAY_RECORD_LIST,
  GET_EXPRESSWAY_MEMBER_DETAIL,
  ACT_EXPRESSWAY_MEMBER_DETAIL,
  MODIFY_MEMBER_DETAIL,
  DENIED_MEMEBER_DETAIL,
  GET_EMAIL_VERFICATION_LIST,
  GET_ADMIN_LIST,
  UPDATE_ADMIN_STATUS,
  GET_ALL_USERS_LIST,
  GET_ALL_CONSUMER,
  GET_CONSUMER_ACCOUNTS_BY_USER,
  GET_CONSUMER_PRODUCT_PURCHASE_BY_USER,
} from "../constants/action-types";
import { ExpressWay } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";

const initialState = {
  Loading: {
    isLoading: false, //--> All Listing
    onSaveLoading: false,
  },
  PendingList: [],
  RecordList: [],
  AdminList: [],
  consumerList: [],
  consumerAccountList: [],
  consumerProductList: [],
  ewUserDetail: [],
  ewClassDetail: [],
  isApprove: false,
  totalRecord: 0,
  emailVerificationPendingLst: [],
  isAdminUpdated: false,
  allUserList: [],
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: ExpressWay = initialState, action: ActionTypes) => {
    switch (action.type) {
      case "SET_PENDING_USER_EMPTY":
        return {
          ...state,
          PendingList: [],
        };
      case GET_EXPRESSWAY_PENDING_LIST.GET_EXPRESSWAY_PENDING_LIST_INITIALIZATION:
        return {
          ...state,
          PendingList: [],
          Loading: {
            ...state.Loading,
            isLoading: true,
          },
        };

      case GET_EXPRESSWAY_PENDING_LIST.GET_EXPRESSWAY_PENDING_LIST_SUCCESS:
        const allPost: any = action.payload; // [{ id: 2, body: "Abcd", title: "title", userId: 3 }, { id: 2, body: "Abcd", title: "title", userId: 3 }];
        return {
          ...state,
          totalRecord: allPost.totalCount,
          PendingList: allPost.data,
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };

      case GET_EXPRESSWAY_PENDING_LIST.GET_EXPRESSWAY_PENDING_LIST_ERROR:
        return {
          ...state,
          PendingList: [], // action.payload,
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };

      case GET_EXPRESSWAY_RECORD_LIST.GET_EXPRESSWAY_RECORD_LIST_INITIALIZATION:
        return {
          ...state,
          RecordList: [],
          totalRecord: 0,
          Loading: {
            ...state.Loading,
            isLoading: true,
          },
        };
      case GET_EXPRESSWAY_RECORD_LIST.GET_EXPRESSWAY_RECORD_LIST_SUCCESS:
        const lst: any = action.payload;
        return {
          ...state,
          RecordList: lst.users,
          totalRecord: lst.totalCount,
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };
      case GET_EXPRESSWAY_RECORD_LIST.GET_EXPRESSWAY_RECORD_LIST_ERROR:
        return {
          ...state,
          RecordList: [],
          totalRecord: 0,
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };

      case GET_EXPRESSWAY_MEMBER_DETAIL.GET_EXPRESSWAY_MEMBER_DETAIL_INITIALIZATION:
        return {
          ...state,
          ewUserDetail: {},
          isApprove: false,
          Loading: {
            ...state.Loading,
            isLoading: true,
          },
        };
      case GET_EXPRESSWAY_MEMBER_DETAIL.GET_EXPRESSWAY_MEMBER_DETAIL_SUCCESS:
        const allDetail: any = action.payload;
        return {
          ...state,
          ewUserDetail: allDetail.user,
          ewClassDetail: allDetail.classData,
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };
      case GET_EXPRESSWAY_MEMBER_DETAIL.GET_EXPRESSWAY_MEMBER_DETAIL_ERROR:
        return {
          ...state,
          ewUserDetail: {},
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };

      case ACT_EXPRESSWAY_MEMBER_DETAIL.ACT_EXPRESSWAY_MEMBER_DETAIL_INITIALIZATION:
        return {
          ...state,
          isApprove: false,
          Loading: {
            ...state.Loading,
            isLoading: true,
          },
        };
      case ACT_EXPRESSWAY_MEMBER_DETAIL.ACT_EXPRESSWAY_MEMBER_DETAIL_SUCCESS:
        return {
          ...state,
          isApprove: true,
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };
      case ACT_EXPRESSWAY_MEMBER_DETAIL.ACT_EXPRESSWAY_MEMBER_DETAIL_ERROR:
        return {
          ...state,
          isApprove: false,
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };

      case MODIFY_MEMBER_DETAIL.MODIFY_MEMBER_DETAIL_INITIALIZATION:
        return {
          ...state,
          isApprove: false,
          Loading: {
            ...state.Loading,
            isLoading: true,
          },
        };
      case MODIFY_MEMBER_DETAIL.MODIFY_MEMBER_DETAIL_SUCCESS:
        return {
          ...state,
          isApprove: true,
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };
      case MODIFY_MEMBER_DETAIL.MODIFY_MEMBER_DETAIL_ERROR:
        return {
          ...state,
          isApprove: false,
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };

      case DENIED_MEMEBER_DETAIL.DENIED_MEMEBER_DETAIL_INITIALIZATION:
        return {
          ...state,
          isApprove: false,
          Loading: {
            ...state.Loading,
            isLoading: true,
          },
        };
      case DENIED_MEMEBER_DETAIL.DENIED_MEMEBER_DETAIL_SUCCESS:
        return {
          ...state,
          isApprove: true,
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };
      case DENIED_MEMEBER_DETAIL.DENIED_MEMEBER_DETAIL_ERROR:
        return {
          ...state,
          isApprove: false,
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };

      case GET_EMAIL_VERFICATION_LIST.GET_EMAIL_VERFICATION_LIST_INITIALIZATION:
        return {
          ...state,
          emailVerificationPendingLst: [],
          Loading: {
            ...state.Loading,
            isLoading: true,
          },
        };

      case GET_EMAIL_VERFICATION_LIST.GET_EMAIL_VERFICATION_LIST_SUCCESS:
        const allPosts: any = action.payload; // [{ id: 2, body: "Abcd", title: "title", userId: 3 }, { id: 2, body: "Abcd", title: "title", userId: 3 }];
        return {
          ...state,
          totalRecord: allPosts.count,
          emailVerificationPendingLst: allPosts.data,
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };

      case GET_EMAIL_VERFICATION_LIST.GET_EMAIL_VERFICATION_LIST_ERROR:
        return {
          ...state,
          PendingList: [], // action.payload,
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };

      case GET_ADMIN_LIST.GET_ADMIN_LIST_INITIALIZATION:
        return {
          ...state,
          AdminList: [],
          Loading: {
            ...state.Loading,
            isLoading: true,
          },
        };

      case GET_ADMIN_LIST.GET_ADMIN_LIST_SUCCESS:
        const allAdmin: any = action.payload;
        return {
          ...state,
          totalRecord: allAdmin.totalCount,
          AdminList: allAdmin.data,
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };

      case GET_ADMIN_LIST.GET_ADMIN_LIST_ERROR:
        return {
          ...state,
          AdminList: [], // action.payload,
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };
      case UPDATE_ADMIN_STATUS.UPDATE_ADMIN_STATUS_INITIALIZATION:
        return {
          ...state,
          isAdminUpdated: false,
          Loading: {
            ...state.Loading,
            isLoading: true,
          },
        };
      case UPDATE_ADMIN_STATUS.UPDATE_ADMIN_STATUS_SUCCESS:
        return {
          ...state,
          isAdminUpdated: true,
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };
      case UPDATE_ADMIN_STATUS.UPDATE_ADMIN_STATUS_ERROR:
        return {
          ...state,
          isAdminUpdated: false,
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };
      case GET_ALL_USERS_LIST.GET_ALL_USERS_LIST_INITIALIZATION:
        return {
          ...state,
          allUserList: [],
          Loading: {
            ...state.Loading,
            isLoading: true,
          },
        };

      case GET_ALL_USERS_LIST.GET_ALL_USERS_LIST_SUCCESS:
        const allUser: any = action.payload; // [{ id: 2, body: "Abcd", title: "title", userId: 3 }, { id: 2, body: "Abcd", title: "title", userId: 3 }];
        return {
          ...state,
          // totalRecord: allUser.count,
          allUserList: allUser.data,
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };

      case GET_ALL_USERS_LIST.GET_ALL_USERS_LIST_ERROR:
        return {
          ...state,
          PendingList: [], // action.payload,
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };

      case GET_ALL_CONSUMER.GET_ALL_CONSUMER_INITIALIZATION:
        return {
          ...state,
          consumerList: [],
          Loading: {
            ...state.Loading,
            isLoading: true,
          },
        };

      case GET_ALL_CONSUMER.GET_ALL_CONSUMER_SUCCESS:
        const data: any = action.payload;
        return {
          ...state,
          consumerList: data.users,
          totalRecord: data.totalCount,
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };

      case GET_ALL_CONSUMER.GET_ALL_CONSUMER_ERROR:
        return {
          ...state,
          consumerList: [],
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };

      case GET_CONSUMER_ACCOUNTS_BY_USER.GET_CONSUMER_ACCOUNTS_BY_USER_INITIALIZATION:
        return {
          ...state,
          consumerAccountList: [],
          Loading: {
            ...state.Loading,
            isLoading: true,
          },
        };

      case GET_CONSUMER_ACCOUNTS_BY_USER.GET_CONSUMER_ACCOUNTS_BY_USER_SUCCESS:
        const accountsData: any = action.payload;
        return {
          ...state,
          consumerAccountList: accountsData.consumerAccounts,
          totalRecord: accountsData.totalCount,
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };

      case GET_CONSUMER_ACCOUNTS_BY_USER.GET_CONSUMER_ACCOUNTS_BY_USER_ERROR:
        return {
          ...state,
          consumerAccountList: [],
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };

      case GET_CONSUMER_PRODUCT_PURCHASE_BY_USER.GET_CONSUMER_PRODUCT_PURCHASE_BY_USER_INITIALIZATION:
        return {
          ...state,
          consumerProductList: [],
          Loading: {
            ...state.Loading,
            isLoading: true,
          },
        };

      case GET_CONSUMER_PRODUCT_PURCHASE_BY_USER.GET_CONSUMER_PRODUCT_PURCHASE_BY_USER_SUCCESS:
        const productsData: any = action.payload;
        return {
          ...state,
          consumerProductList: productsData.userProducts,
          totalRecord: productsData.totalCount,
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };

      case GET_CONSUMER_PRODUCT_PURCHASE_BY_USER.GET_CONSUMER_PRODUCT_PURCHASE_BY_USER_ERROR:
        return {
          ...state,
          consumerProductList: [],
          Loading: {
            ...state.Loading,
            isLoading: false,
          },
        };

      default:
        return state;
    }
  },
);

export default reducer;
