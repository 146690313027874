/* eslint-disable no-case-declarations */
import {
  LOGIN,
  REGISTER,
  FORGOT_PASSWORD,
  LOGOUT,
  CHANGE_LOCAL,
  GET_USER_DETAIL,
  EDIT_USER_DETAIL,
  GET_USER_ATTENDENCE,
  GET_INDUSTRY_LIST,
  GET_JOB_LIST,
  GET_REGISTRATION_MATERIAL,
  GET_QR_CODE,
  CHANGE_PASSWORD,
  GET_DASHBOARD_COUNT,
  GET_USER_CLASS,
  GET_USER_CLASS_ROLE,
  GET_USER_PRINT_DETAIL,
  RESEND_OTP,
  SET_PAGE,
  GET_DASHBOARD_MEMBERSHIP_EXPIRED_USER_LIST,
  MAKE_ADMIN,
  SET_STATUS_CODE,
  ADD_ADMIN_USER,
} from "../constants/action-types";
import { UserData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { valiadteToken } from "../../util/utils";
import { ActionTypes } from "../action";
import secureLocalStorage from "react-secure-storage";

const initialState = {
  progress: false,
  isLoading: false,
  onSaveLoading: false,
  isLoggedin: valiadteToken(), // JSON.parse(getToken("isLoggedIn") || "false"),
  isLoggedout: false,
  isKencorLoggedin: false,
  isRegisterd: false,
  isForgotpasswordSuccess: false,
  authToken: "",
  errorMsg: "",
  classes: {},
  posts: [],
  userDetail: {},
  userAttendence: {},
  paymentTypeLst: [],
  classChoice: [],
  repeaterClass: [],
  industry: [],
  jobTitles: [],
  myInfo: {},
  totalRecord: 0,
  dashboardCount: [],
  membershipExpireUserList: [],
  local: secureLocalStorage.getItem("local")?.toString() || "en",
  userClass: [],
  userClassRole: [],
  userPrintDetail: [],
  pageNumber: 1,
  districtLst: [],
  statusCode: "",
  isAdminUserAdded: false,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: UserData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case CHANGE_LOCAL:
        secureLocalStorage.setItem("local", action.payload.toString());
        return {
          ...state,
          local: action.payload.toString(),
        };

      case SET_STATUS_CODE:
        // const pageNumber: any = action.payload || 0;
        return {
          ...state,
          statusCode: action.payload,
        };

      case SET_PAGE:
        const pageNumber: any = action.payload || 0;
        return {
          ...state,
          pageNumber: parseInt(pageNumber.pageNumber),
        };

      case LOGIN.LOGIN_INITLIZATION:
        return {
          ...state,
          ...action.payload,
          isLoading: true,
          progress: true,
          isLoggedout: false,
        };
      case LOGIN.LOGIN_SUCCESS:
        // eslint-disable-next-line no-case-declarations
        const user: any = action.payload;
        return {
          ...state,
          user: user,
          ...action.payload,
          isLoggedin: true,
          authToken: user.response.data.access_token,
          isLoading: false,
          isLoggedout: false,
          progress: true,
        };

      case LOGIN.LOGIN_ERORR:
        return {
          ...state,
          ...action.payload,
          isLoggedin: false,
          isLoading: false,
          progress: false,
        };
      case REGISTER.REGISTER_INITLIZATION:
        return {
          ...state,
          ...action.payload,
          isLoading: true,
        };
      case REGISTER.REGISTER_SUCCESS:
        return {
          ...state,
          ...action.payload,
          isRegisterd: true,
          isLoading: false,
        };
      case REGISTER.REGISTER_ERORR:
        return {
          ...state,
          ...action.payload,
          isRegisterd: false,
          isLoading: false,
        };

      case LOGOUT.LOGOUT_INITLIZATION:
        return {
          ...state,
          ...action.payload,
          isLoading: true,
        };
      case LOGOUT.LOGOUT_SUCCESS:
        return {
          ...state,
          ...action.payload,
          isLoggedout: true,
          isLoggedin: false,
          isLoading: false,
        };
      case LOGOUT.LOGOUT_ERORR:
        return {
          ...state,
          ...action.payload,
          isLoggedout: false,
          isLoading: false,
        };

      case FORGOT_PASSWORD.FORGOT_PASSWORD_INITLIZATION:
        return {
          ...state,
          ...action.payload,
          isLoading: true,
        };
      case FORGOT_PASSWORD.FORGOT_PASSWORD_SUCCESS:
        return {
          ...state,
          ...action.payload,
          isForgotpasswordSuccess: true,
          isLoading: false,
        };
      case FORGOT_PASSWORD.FORGOT_PASSWORD_ERORR:
        return {
          ...state,
          ...action.payload,
          isForgotpasswordSuccess: false,
          isLoading: false,
        };

      case GET_USER_DETAIL.GET_USER_DETAIL_INITIALIZATION:
        return {
          ...state,
          userDetail: {},
          Loading: true,
        };
      case GET_USER_DETAIL.GET_USER_DETAIL_SUCCESS:
        return {
          ...state,
          userDetail: action.payload,
          Loading: false,
        };
      case GET_USER_DETAIL.GET_USER_DETAIL_ERROR:
        return {
          ...state,
          userDetail: {},
          Loading: false,
        };

      case EDIT_USER_DETAIL.EDIT_USER_DETAIL_INITIALIZATION:
        return {
          ...state,
          ewUserDetail: {},
          Loading: true,
        };
      case EDIT_USER_DETAIL.EDIT_USER_DETAIL_SUCCESS:
        return {
          ...state,
          Loading: false,
        };
      case EDIT_USER_DETAIL.EDIT_USER_DETAIL_ERROR:
        return {
          ...state,
          ewUserDetail: {},
          Loading: false,
        };

      case GET_USER_ATTENDENCE.GET_USER_ATTENDENCE_INITIALIZATION:
        return {
          ...state,
          userAttendence: {},
          Loading: true,
        };
      case GET_USER_ATTENDENCE.GET_USER_ATTENDENCE_SUCCESS:
        return {
          ...state,
          userAttendence: action.payload,
          Loading: false,
        };
      case GET_USER_ATTENDENCE.GET_USER_ATTENDENCE_ERROR:
        return {
          ...state,
          userAttendence: {},
          Loading: false,
        };

      case GET_INDUSTRY_LIST.GET_INDUSTRY_LIST_INITLIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_INDUSTRY_LIST.GET_INDUSTRY_LIST_SUCCESS:
        return {
          ...state,
          industry: action.payload,
          isLoading: false,
        };

      case GET_INDUSTRY_LIST.GET_INDUSTRY_LIST_ERORR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_JOB_LIST.GET_JOB_LIST_INITLIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_JOB_LIST.GET_JOB_LIST_SUCCESS:
        return {
          ...state,
          jobTitles: action.payload,
          isLoading: false,
        };

      case GET_JOB_LIST.GET_JOB_LIST_ERORR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_REGISTRATION_MATERIAL.GET_REGISTRATION_MATERIAL_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_REGISTRATION_MATERIAL.GET_REGISTRATION_MATERIAL_SUCCESS:
        const datas: any = action.payload;
        return {
          ...state,
          paymentTypeLst: datas.paymentTypeData,
          repeaterClass: datas.repeaterClass,
          classChoice: datas.classChoice,
          districtLst: datas.districtData,
          isLoading: false,
        };

      case GET_REGISTRATION_MATERIAL.GET_REGISTRATION_MATERIAL_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_QR_CODE.GET_QR_CODE_INITIALIZATION:
        return {
          ...state,
          myInfo: {},
          isLoading: true,
        };
      case GET_QR_CODE.GET_QR_CODE_SUCCESS:
        return {
          ...state,
          myInfo: action.payload,
          isLoading: false,
        };
      case GET_QR_CODE.GET_QR_CODE_ERROR:
        return {
          ...state,
          myInfo: {},
          isLoading: false,
        };

      case CHANGE_PASSWORD.CHANGE_PASSWORD_INITLIZATION:
        return {
          ...state,
          errorMsg: "",
          onSaveLoading: true,
        };
      case CHANGE_PASSWORD.CHANGE_PASSWORD_SUCCESS:
        return {
          ...state,
          errorMsg: "",
          onSaveLoading: false,
        };
      case CHANGE_PASSWORD.CHANGE_PASSWORD_ERORR:
        return {
          ...state,
          errorMsg: action.payload,
          onSaveLoading: false,
        };

      case GET_DASHBOARD_COUNT.GET_DASHBOARD_COUNT_INITIALIZATION:
        return {
          ...state,
          dashboardCount: [],
          isLoading: true,
        };
      case GET_DASHBOARD_COUNT.GET_DASHBOARD_COUNT_SUCCESS:
        return {
          ...state,
          dashboardCount: action.payload,
          isLoading: false,
        };
      case GET_DASHBOARD_COUNT.GET_DASHBOARD_COUNT_ERROR:
        return {
          ...state,
          dashboardCount: [],
          isLoading: false,
        };

      case GET_DASHBOARD_MEMBERSHIP_EXPIRED_USER_LIST.GET_DASHBOARD_MEMBERSHIP_EXPIRED_USER_LIST_INITIALIZATION:
        return {
          ...state,
          membershipExpireUserList: [],
          totalRecord: 0,
          isLoading: true,
        };
      case GET_DASHBOARD_MEMBERSHIP_EXPIRED_USER_LIST.GET_DASHBOARD_MEMBERSHIP_EXPIRED_USER_LIST_SUCCESS:
        const allUser: any = action.payload;
        return {
          ...state,
          membershipExpireUserList: allUser,
          totalRecord: allUser.totalCount,
          isLoading: false,
        };
      case GET_DASHBOARD_MEMBERSHIP_EXPIRED_USER_LIST.GET_DASHBOARD_MEMBERSHIP_EXPIRED_USER_LIST_ERROR:
        return {
          ...state,
          membershipExpireUserList: [],
          totalRecord: 0,
          isLoading: false,
        };

      case GET_USER_CLASS.GET_USER_CLASS_INITIALIZATION:
        return {
          ...state,
          userClass: [],
          isLoading: true,
        };
      case GET_USER_CLASS.GET_USER_CLASS_SUCCESS:
        return {
          ...state,
          userClass: action.payload,
          isLoading: false,
        };
      case GET_USER_CLASS.GET_USER_CLASS_ERROR:
        return {
          ...state,
          userClass: [],
          isLoading: false,
        };

      case GET_USER_CLASS_ROLE.GET_USER_CLASS_ROLE_INITIALIZATION:
        return {
          ...state,
          userClassRole: [],
          isLoading: true,
        };
      case GET_USER_CLASS_ROLE.GET_USER_CLASS_ROLE_SUCCESS:
        return {
          ...state,
          userClassRole: action.payload,
          isLoading: false,
        };
      case GET_USER_CLASS_ROLE.GET_USER_CLASS_ROLE_ERROR:
        return {
          ...state,
          userClassRole: [],
          isLoading: false,
        };

      case GET_USER_PRINT_DETAIL.GET_USER_PRINT_DETAIL_INITIALIZATION:
        return {
          ...state,
          userPrintDetail: [],
          isLoading: true,
        };
      case GET_USER_PRINT_DETAIL.GET_USER_PRINT_DETAIL_SUCCESS:
        return {
          ...state,
          userPrintDetail: action.payload,
          isLoading: false,
        };
      case GET_USER_PRINT_DETAIL.GET_USER_PRINT_DETAIL_ERROR:
        return {
          ...state,
          userPrintDetail: [],
          isLoading: false,
        };

      case RESEND_OTP.RESEND_OTP_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };
      case RESEND_OTP.RESEND_OTP_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };
      case RESEND_OTP.RESEND_OTP_ERROR:
        return {
          ...state,
          isLoading: false,
        };
      case MAKE_ADMIN.MAKE_ADMIN_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };
      case MAKE_ADMIN.MAKE_ADMIN_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };
      case MAKE_ADMIN.MAKE_ADMIN_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_ADMIN_USER.ADD_ADMIN_USER_INITIALIZATION:
        return {
          ...state,
          ...action.payload,
          isLoading: true,
          progress: true,
          // isLoggedout: false,
        };
      case ADD_ADMIN_USER.ADD_ADMIN_USER_SUCCESS:
        // eslint-disable-next-line no-case-declarations
        // const D: any = action.payload;
        return {
          ...state,
          ...action.payload,
          isAdminUserAdded: true,
          isLoading: false,
          isLoggedout: false,
        };

      case ADD_ADMIN_USER.ADD_ADMIN_USER_ERROR:
        return {
          ...state,
          ...action.payload,
          isLoading: false,
        };
      default:
        return state;
    }
  },
);

export default reducer;
