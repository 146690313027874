import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";

export default function ConsumerList({ users }: any) {
  const navigate = useNavigate();
  return (
    <div>
      <section>
        <div className="table-responsive">
          <table className="table table-hover m-0">
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="table.head.MID" defaultMessage="MID" />
                </th>
                <th>
                  <FormattedMessage
                    id="table.head.Nickname"
                    defaultMessage="Nickname"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="table.head.Email"
                    defaultMessage="Email"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="table.head.MobileNumber"
                    defaultMessage="Mobile Number"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {users.length &&
                users.map((user: any) => (
                  <tr onClick={() => navigate(`/users/consumer/${user.id}`)}>
                    <td className="pointer">{user.id}</td>
                    <td className="pointer">{user?.preferredNickName}</td>
                    <td className="pointer">{user.email}</td>
                    <td className="pointer">
                      +{user?.dialCode || ""} {user.phoneNumber}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
}
