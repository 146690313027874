// import {
//   _UploadMedia,
//   _DeleteMedia,
// } from "../../../service/MediaUpload/classes.service";
import Dropzone from "react-dropzone";
import { BsUpload } from "react-icons/bs";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Store } from "../../../redux/Actions";
import CenterLoader from "../CenterLoader/CenterLoader";

const FileUpload = ({ handleDrop, optional = true, accept = null }: any) => {
  const isLoading = useSelector(
    (state: Store) => state.mediaUploadReducer.uploadLoad,
  );
  const handle = (acceptedFiles: any) => {
    const files: any = [];
    for (let i = 0; i < acceptedFiles.length; i++) {
      if (acceptedFiles[i].size > 104857600) {
        toast.error(
          `${acceptedFiles[i].name} file is not allowed because it's size is more than 100MB`,
        );
      } else {
        files.push(acceptedFiles[i]);
      }
    }
    if (files.length > 0) {
      handleDrop(files);
    }
  };

  return (
    <div>
      <div className="fileUpload">
        {accept !== null && accept !== "*" && accept !== undefined ? (
          <Dropzone
            onDrop={handle}
            accept={accept}
            // minSize={1024}
            maxSize={104857600}
            onDropRejected={(rejectedFiles: any) => {
              for (let i = 0; i < rejectedFiles.length; i++) {
                if (rejectedFiles[i].file.size > 104857600) {
                  toast.error(
                    `${rejectedFiles[i].file.name} file is not allowed because it's size is more than 100MB`,
                  );
                } else {
                  handleDrop([rejectedFiles[i].file]);
                }
                // if (rejectedFiles[0].file.size > 104857600) {
                //   toast.error("File size should not be more than 10MB")
                // }
              }
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <>
                <div className=" d-flex align-items-center justify-content-center">
                  {isLoading && <CenterLoader />}
                </div>
                <div
                  style={{ textAlign: "center" }}
                  {...getRootProps({
                    className:
                      "dropzone d-flex align-items-center justify-content-center",
                  })}
                >
                  {/* <br /> */}
                  <input {...getInputProps()} />
                  <p className="d-flex align-items-center justify-content-center m-0">
                    {" "}
                    <BsUpload size={25} className="mr-2" />
                    {optional ? (
                      <FormattedMessage
                        id="file.upload"
                        defaultMessage="Drag'n'drop files, or click to select files "
                      />
                    ) : (
                      <FormattedMessage
                        id="file.uploadCom"
                        defaultMessage="Drag'n'drop files, or click to select files "
                      />
                    )}{" "}
                  </p>
                </div>
              </>
            )}
          </Dropzone>
        ) : (
          <Dropzone
            onDrop={handle}
            accept={accept}
            // minSize={1024}
            maxSize={104857600}
            onDropRejected={(rejectedFiles: any) => {
              for (let i = 0; i < rejectedFiles.length; i++) {
                if (rejectedFiles[i].file.size > 104857600) {
                  toast.error(
                    `${rejectedFiles[i].file.name} file is not allowed because it's size is more than 100MB`,
                  );
                } else {
                  handleDrop([rejectedFiles[i].file]);
                }
                // if (rejectedFiles[0].file.size > 104857600) {
                //   toast.error("File size should not be more than 10MB")
                // }
              }
            }}
            // accept={accept}
            // minSize={1024}
            // maxSize={3072000}
          >
            {({ getRootProps, getInputProps }) => (
              <>
                <div className=" d-flex align-items-center justify-content-center">
                  {isLoading && <CenterLoader />}
                </div>
                <div
                  style={{ textAlign: "center" }}
                  {...getRootProps({
                    className:
                      "dropzone d-flex align-items-center justify-content-center",
                  })}
                >
                  {/* <br /> */}
                  <input {...getInputProps()} />
                  <p className="d-flex align-items-center justify-content-center m-0">
                    {" "}
                    <BsUpload size={25} className="mr-2" />
                    {optional ? (
                      <FormattedMessage
                        id="file.upload"
                        defaultMessage="Drag'n'drop files, or click to select files "
                      />
                    ) : (
                      <FormattedMessage
                        id="file.uploadCom"
                        defaultMessage="Drag'n'drop files, or click to select files "
                      />
                    )}{" "}
                  </p>
                </div>
              </>
            )}
          </Dropzone>
        )}
      </div>
    </div>
  );
};

export default FileUpload;
