import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
// import Dropzone from "react-dropzone";

import { useSelector } from "react-redux";
import {
  // addActivity,
  // addActivityPoints,
  // addAnnouncement,
  // addRentsList,
  editRentsList,
  getAdminRolesList,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
// import { convertToRaw, EditorState, convertFromHTML } from "draft-js";
import Loader from "../common/Loader";
// import EditorContainer from "../RichTextEditor/Editor";
// import { _UploadMedia } from "../../service/MediaUpload/classes.service";
// import FileUpload from "../common/MediaUpload/FileUpload";
// import draftToHtml from "draftjs-to-html";
// import Select from "react-select";
import { dispatch } from "../../redux";
// import { useParams } from "react-router";
import { Table, Modal } from "react-bootstrap";
import { formatDate, isPositiveNumbersOnly } from "../../util/utils";
import { EditRentSchema } from "../../util/validationSchema";
import { FaTimesCircle } from "react-icons/fa";

const EditGrantModal = ({ onModalClose, rent }: any) => {
  const [ButtonClick, setButtonClick] = useState(false);
  const isAPILoading = useSelector(
    (state: Store) => state.rentsReducer.isLoading,
  );
  // const adminRoleList = useSelector(
  //   (state: Store) => state.adminRolesReducer.adminRoleList
  // ) as Array<Object>;
  // const [selectedRoles, setselectedRoles] = useState({});
  // const urlParams = useParams();

  const formik = useFormik({
    initialValues: {
      users: rent.users.id,
      type: rent.type.id,
      quantity: rent.quantity || 0,
      remark: rent.remark || "",
      valid: rent.valid ? formatDate(rent.valid, "YYYY-MM-DD") : "",
    },
    validationSchema: EditRentSchema,
    onSubmit: async (values) => {
      dispatch(editRentsList(false, values, rent.id));
      setButtonClick(true);
    },
  });

  useEffect(() => {
    if (!isAPILoading && ButtonClick) {
      onModalClose();
    }
  }, [isAPILoading]);

  useEffect(() => {
    dispatch(getAdminRolesList(false));
  }, []);

  const { touched, errors } = formik;
  return (
    <Modal centered show={true} onHide={() => onModalClose(false)}>
      <Modal.Header>
        <h4 className="modal-title" id="AddCourseLabel">
          Edit Rents #{rent.id}
        </h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onModalClose}
        >
          <FaTimesCircle />
        </button>
      </Modal.Header>
      <Modal.Body>
        <Table bordered>
          <thead>
            <tr>
              <th>Type</th>
              <th>{rent.type.type}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Created Date (by#) </td>
              <td>
                {formatDate(rent.createdAt)}({rent.createdBy.id}){" "}
              </td>
            </tr>
            <tr>
              <td>Last Modified Date (by#) </td>
              <td>
                {formatDate(rent.updatedAt)}({rent.updatedBy?.id || ""}){" "}
              </td>
            </tr>
          </tbody>
        </Table>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label col-form-label-sm">
            Quantity
          </label>
          <div className="col-sm-10">
            <input
              type="text"
              name="quantity"
              value={formik.values.quantity}
              // onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control"
              min={0}
              onChange={(e: any) => {
                if (!isPositiveNumbersOnly(e, e.target.value)) {
                  return false;
                }
                formik.handleChange(e);
              }}
            />
            {errors.quantity && touched.quantity && (
              <div className="text-error">{errors.quantity.toString()}</div>
            )}
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label col-form-label-sm">
            Remarks
          </label>
          <div className="col-sm-10">
            <input
              type="text"
              name="remark"
              value={formik.values.remark}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control"
            />
            {errors.remark && touched.remark && (
              <div className="text-error">{errors.remark.toString()}</div>
            )}
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label col-form-label-sm">
            Valid until
          </label>
          <div className="col-sm-10">
            <input
              type="date"
              name="valid"
              value={formik.values.valid}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control"
            />
            {errors.valid && touched.valid && (
              <div className="text-error">{errors.valid}</div>
            )}{" "}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-error"
          data-dismiss="modal"
          onClick={onModalClose}
          color="error"
        >
          Invalid
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          onClick={onModalClose}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary"
          style={{ display: "inherit" }}
          onClick={() => formik.handleSubmit()}
          data-dismiss="modal"
        >
          Submit
          {isAPILoading && (
            <div style={{ marginTop: "3px" }} className="ml-2">
              <Loader />
            </div>
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditGrantModal;
