import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Card, Dropdown } from "react-bootstrap";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import UserSelectionAutoComplete from "../../Components/Selection/UserSelectionAutoComplete";
import { AppendedMyComponent } from "../../Components/append-to-body-drop-down";
import { VscDebugStart } from "react-icons/vsc";
import { ImCross } from "react-icons/im";
import { BsStopCircle } from "react-icons/bs";
import { FaPowerOff } from "react-icons/fa";
import { dispatch } from "../../redux";
import {
  getVPSList,
  updateVpsStatus,
  vpsResetPassword,
} from "../../redux/action";
import { useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import Pagination from "../../Components/common/Paginate/Pagination";
import NoDatFound from "../../Components/common/NoDatFound";
import { VPSStatus } from "../../Constants";
import ChangeVpsStatusModal from "../../Components/common/Popups/ChangeVpsStatusModal";
import { Modal } from "react-bootstrap";
import { setNewVPSPasswordSchema } from "../../util/validationSchema";
import { useFormik } from "formik";
import { FaLock, FaTimesCircle } from "react-icons/fa";
import moment from "moment";
import {
  Radio,
  RadioGroup,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Switch,
} from "@mui/material";

const VpsManagement = () => {
  const [userId, setUserId] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const [changeVpsStatus, setChangeVpsStatus] = useState({
    modal: false,
    instanceId: "",
    vpsStatus: "",
    type: 0,
    text: "start",
  });
  const [includeLocal, setIncludeLocal] = useState(false);

  const userVpsList = useSelector(
    (state: Store) => state.vpsMachineReducer.UserVPSMachineList,
  ) as any;

  const isLoading = useSelector(
    (state: Store) => state.vpsMachineReducer.isLoading,
  ) as boolean;

  const totalRecord = useSelector(
    (state: Store) => state.vpsMachineReducer.totalRecord,
  ) as any;

  const isVpsStatusUpdated = useSelector(
    (state: Store) => state.vpsMachineReducer.isVPSstatusUpdated,
  ) as any;

  const sortBy = "updatedAt";
  const sortOrder = "DESC";

  useEffect(() => {
    dispatch(
      getVPSList(
        false,
        userId,
        pageNumber,
        { by: sortBy, order: sortOrder },
        pageLimit,
        includeLocal,
      ),
    );
  }, [userId, pageNumber, isVpsStatusUpdated, pageLimit, includeLocal]);

  useEffect(() => {
    dispatch(
      getVPSList(
        false,
        userId,
        pageNumber,
        { by: sortBy, order: sortOrder },
        pageLimit,
        includeLocal,
      ),
    );
  }, [includeLocal, pageLimit, pageNumber, userId]);

  const onPageChange = (pageNum: number) => {
    setPageNumber(pageNum);
  };

  const onChangeStatus = async (key: any) => {
    if (key.status === "resetPassword") {
      dispatch(vpsResetPassword(true, { instanceid: key?.instance_id }));
    } else {
      dispatch(updateVpsStatus(true, key));
    }
    setChangeVpsStatus({
      modal: false,
      instanceId: "",
      vpsStatus: "",
      type: 0,
      text: "start",
    });
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: setNewVPSPasswordSchema,
    onSubmit: (values: any) => {
      dispatch(
        vpsResetPassword(true, {
          instanceid: changeVpsStatus?.instanceId,
          password: values.password,
        }),
      );
      resetForm();
      setChangeVpsStatus({
        modal: false,
        instanceId: "",
        vpsStatus: "",
        type: 0,
        text: "start",
      });
    },
  });

  const { errors, touched, values, resetForm } = formik;

  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-right">
            <div className="mr-auto">
              <h1>
                {" "}
                <FormattedMessage
                  id="VpsManagement"
                  defaultMessage="VPS Management "
                />
              </h1>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          <Card>
            <Card.Body className="p-0">
              <div className="table-responsive">
                {isLoading && <CenterLoader />}
                <div className="d-flex justify-content-end p-3 member-select">
                  <FormGroup style={{ display: "block" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={includeLocal}
                          onChange={() => setIncludeLocal((prev) => !prev)}
                        />
                      }
                      style={{ padding: "1rem" }}
                      label="Include non-aws VPS"
                    />
                  </FormGroup>
                  <div className="d-flex align-items-center nowrap">
                    <UserSelectionAutoComplete
                      getList={(memberId: any) => {
                        setUserId(memberId);
                        setPageNumber(1);
                      }}
                      users={[]}
                      onClear={() => {
                        setUserId(0);
                      }}
                      refs={() => {
                        /** */
                      }}
                    />
                  </div>
                </div>
                {userVpsList && userVpsList.length ? (
                  <>
                    <table className="table table-hover m-0">
                      <thead>
                        <tr>
                          <th key="vps_id">
                            <FormattedMessage
                              id="Vps.Id"
                              defaultMessage="VPS Id "
                            />
                          </th>
                          <th key="vps_type">
                            <FormattedMessage
                              id="Vps.Type"
                              defaultMessage="VPS Type "
                            />
                          </th>
                          <th key="public_ip">
                            <FormattedMessage
                              id="Vps.PublicIp"
                              defaultMessage="Public IP"
                            />
                          </th>
                          <th key="instance_id">
                            <FormattedMessage
                              id="Vps.Instance"
                              defaultMessage="Instance"
                            />
                          </th>
                          <th key="mt4_count">
                            <FormattedMessage
                              id="Vps.MT4Count"
                              defaultMessage="MT4 Count"
                            />
                          </th>
                          <th key="status">
                            <FormattedMessage
                              id="Vps.Status"
                              defaultMessage="Status"
                            />
                          </th>
                          <th key="mid">
                            <FormattedMessage
                              id="Vps.MID"
                              defaultMessage="MID"
                            />
                          </th>
                          <th key="name">
                            <FormattedMessage
                              id="activitymodal.Name"
                              defaultMessage="Name"
                            />
                          </th>
                          <th key="nick_name">
                            <FormattedMessage
                              id="table.head.Nickname"
                              defaultMessage="Nickname"
                            />
                          </th>
                          <th key="email">
                            <FormattedMessage
                              id="profile.Email"
                              defaultMessage="Email"
                            />
                          </th>
                          <th key="expiry_date">
                            <FormattedMessage
                              id="profile.ExpiryDate"
                              defaultMessage="Expiry Date"
                            />
                          </th>
                          <th key="action">
                            <FormattedMessage
                              id="table.head.Actions"
                              defaultMessage="Action"
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {userVpsList &&
                          userVpsList.map((i: any, index: number) => (
                            <tr key={index}>
                              <td>{i?.id}</td>
                              <td>{i?.vpsType?.typeName}</td>
                              <td>{i?.public_ip}</td>
                              <td>{i?.instance}</td>
                              <td>{i?.mt4Count}</td>
                              <td>{i?.VPS_status}</td>
                              <td>{i?.User?.id}</td>
                              <td>
                                {i?.User?.englishFirstName}
                                {i?.User?.englishNickName
                                  ? ` ${i?.User?.englishNickName} `
                                  : " "}
                                {i?.User?.englishLastName}
                              </td>
                              <td>{i?.User?.preferredNickName}</td>
                              <td>{i?.User?.email}</td>
                              <td>
                                {i?.expiryDate
                                  ? moment(i?.expiryDate).format("YYYY-MM-DD")
                                  : "-"}
                              </td>
                              <td className="table-field-actions">
                                {i?.isVpsCreated && (
                                  <Dropdown className="btn-group">
                                    <Dropdown.Toggle
                                      id="dropdown-basic"
                                      className="btn btn-sm btn-icon-only"
                                    >
                                      <i className="icon dripicons-dots-3 zmdi-hc-fw"></i>
                                    </Dropdown.Toggle>
                                    <AppendedMyComponent>
                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={() =>
                                            setChangeVpsStatus({
                                              modal: true,
                                              instanceId: i?.instance,
                                              vpsStatus: VPSStatus.RUNNING,
                                              type: i?.vpsType?.id || 1,
                                              text: "start",
                                            })
                                          }
                                        >
                                          <i className="text-success">
                                            <VscDebugStart size={17} />
                                          </i>{" "}
                                          <FormattedMessage
                                            id="Vps.Action.Start"
                                            defaultMessage="Start"
                                          />
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={() =>
                                            setChangeVpsStatus({
                                              modal: true,
                                              instanceId: i?.instance,
                                              vpsStatus: VPSStatus.STOPPED,
                                              type: i?.vpsType?.id || 1,
                                              text: "stop",
                                            })
                                          }
                                        >
                                          <i className="text-accent-custom">
                                            <BsStopCircle />
                                          </i>{" "}
                                          <FormattedMessage
                                            id="Vps.Action.Stop"
                                            defaultMessage="Stop"
                                          />
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={() =>
                                            setChangeVpsStatus({
                                              modal: true,
                                              instanceId: i?.instance,
                                              vpsStatus: VPSStatus.REBOOT,
                                              type: i?.vpsType?.id || 1,
                                              text: "reboot",
                                            })
                                          }
                                        >
                                          <i className="text-accent-custom">
                                            <FaPowerOff />
                                          </i>{" "}
                                          <FormattedMessage
                                            id="Vps.Action.Restart"
                                            defaultMessage="Restart"
                                          />
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={() =>
                                            setChangeVpsStatus({
                                              modal: true,
                                              instanceId: i?.instance,
                                              vpsStatus: VPSStatus.TERMINATED,
                                              type: i?.vpsType?.id || 1,
                                              text: "terminate",
                                            })
                                          }
                                        >
                                          <i className="text-danger">
                                            <ImCross />
                                          </i>{" "}
                                          <FormattedMessage
                                            id="Vps.Action.Terminate"
                                            defaultMessage="Terminate"
                                          />
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={() =>
                                            setChangeVpsStatus({
                                              modal: true,
                                              instanceId: i?.instance,
                                              vpsStatus: "resetPassword",
                                              type: i?.vpsType?.id || 1,
                                              text: "resetPassword",
                                            })
                                          }
                                        >
                                          <i className="text-accent-custom">
                                            {" "}
                                            <FaLock />{" "}
                                          </i>{" "}
                                          <FormattedMessage
                                            id="Vps.Action.ResetPassword"
                                            defaultMessage="Reset Password"
                                          />
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </AppendedMyComponent>
                                  </Dropdown>
                                )}{" "}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <p>{!isLoading && <NoDatFound />}</p>
                )}
              </div>
              {userVpsList.length && totalRecord > 10 && (
                <>
                  <FormControl className="pagelimit">
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      <FormattedMessage
                        id="page_size"
                        defaultMessage="Page Size"
                      />
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={pageLimit}
                      onChange={(e: any) => {
                        setPageLimit(e.target.value);
                        setPageNumber(1);
                      }}
                    >
                      <FormControlLabel
                        value={10}
                        control={<Radio />}
                        label="10"
                      />
                      <FormControlLabel
                        value={20}
                        control={<Radio />}
                        label="20"
                      />
                      <FormControlLabel
                        value={50}
                        control={<Radio />}
                        label="50"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Pagination
                    itemsPerPage={pageLimit}
                    totalPages={totalRecord}
                    onPageChage={onPageChange}
                    page={pageNumber - 1}
                  />
                </>
              )}
            </Card.Body>
          </Card>
        </section>
      </div>
      {changeVpsStatus.modal && changeVpsStatus.text != "resetPassword" && (
        <ChangeVpsStatusModal
          onClose={() => {
            setChangeVpsStatus({
              modal: false,
              instanceId: "",
              vpsStatus: "",
              type: 0,
              text: "start",
            });
          }}
          onDeleteClick={() =>
            onChangeStatus({
              instance_id: changeVpsStatus?.instanceId,
              status: changeVpsStatus?.vpsStatus,
              type: changeVpsStatus?.type || 1,
            })
          }
          open={changeVpsStatus.modal}
          text={changeVpsStatus.text}
        />
      )}

      {changeVpsStatus.modal && changeVpsStatus.text == "resetPassword" && (
        <Modal
          centered
          size={"lg"}
          show={changeVpsStatus.modal}
          className="modal-content-md"
        >
          <form
            onSubmit={(e: any) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          >
            <Modal.Header>
              <h4 className="modal-title">
                <FormattedMessage
                  id="vpsMachine.GemsTeam"
                  defaultMessage="Reset Password "
                />
              </h4>
              <button
                className="close"
                onClick={() => {
                  setChangeVpsStatus({
                    modal: false,
                    instanceId: "",
                    vpsStatus: "",
                    type: 0,
                    text: "start",
                  });
                  resetForm();
                }}
              >
                <FaTimesCircle />
              </button>
            </Modal.Header>
            <Modal.Body>
              {/* <b>
              <FormattedMessage
                id="vpsMachine.send.email"
                defaultMessage="Set  ? "
              />
            </b> */}
              <div className="form-group">
                <FormattedMessage
                  id="select.newPassword"
                  defaultMessage="Password"
                />
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {errors.password && touched.password && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.password.toString()}
                      defaultMessage="This field is required"
                    />
                  </div>
                )}
              </div>

              <div className="form-group">
                {/* <label className="control-label text-md-right col-md-3"> */}
                <FormattedMessage
                  id="head.RetypePassword"
                  defaultMessage="Retype New Password"
                />
                {/* </label> */}
                {/* <div className="col-md-5"> */}
                <input
                  type="password"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  className="form-control"
                />
                {errors.confirmPassword && touched.confirmPassword && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.confirmPassword.toString()}
                      defaultMessage="This field is required"
                    />
                  </div>
                )}
              </div>
              {/* </div> */}
            </Modal.Body>

            <Modal.Footer>
              <button
                className="btn btn-secondary"
                type="button"
                onClick={() => {
                  setChangeVpsStatus({
                    modal: false,
                    instanceId: "",
                    vpsStatus: "",
                    type: 0,
                    text: "start",
                  });
                  resetForm();
                }}
              >
                {" "}
                <FormattedMessage
                  id="vpsMachine.Cancle"
                  defaultMessage=" Cancel"
                />
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                // onClick={() => formik.handleSubmit}
              >
                <FormattedMessage id="page.submit" defaultMessage=" Send... " />
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      )}
    </div>
  );
};

VpsManagement.propTypes = {};

export default VpsManagement;
