import Select from "react-select";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminRolesList,
  getAdminRolesToolList,
  setInCurrentPage,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import NoDatFound from "../../Components/common/NoDatFound";
import { FormattedMessage, useIntl } from "react-intl";
import { TOOLSMAP } from "../../util/constants/UserGroup";
import ReactPaginate from "react-paginate";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import { sort } from "../../util/utils";
import { BsSortDown, BsSortUp } from "react-icons/bs";
import Pagination from "../../Components/common/Paginate/Pagination";
import { ToolTypeOptions } from "../../Constants";
import { Col, Row } from "react-bootstrap";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

// const ToolList = ({ pageNumber, setPageNumber }: any) => {
const ToolList = () => {
  const dispatch = useDispatch();
  const [selectedRole, setSelectedRole] = useState<any>(0);
  const toolsLists: any = useSelector(
    (state: Store) => state.adminRolesReducer.toolList,
  ) as any;

  const totalRecord: any = useSelector(
    (state: Store) => state.adminRolesReducer.totalRecord,
  ) as any;

  const isLoading: any = useSelector(
    (state: Store) => state.adminRolesReducer.isLoading,
  ) as any;
  const adminRolesList: any = useSelector(
    (state: Store) => state.adminRolesReducer.adminRoleList,
  ) as any;
  const totalPages: any = useSelector(
    (state: Store) => state.adminRolesReducer.totalRecord,
  ) as any;
  // const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [toolList, setToolList] = useState([]);
  const local = useSelector((state: Store) => state.userDataReducer.local);
  const [selectedToolType, setSelectedToolType] = useState<any>({
    id: 0,
    group_en: ToolTypeOptions[0].group_en,
    group_zh: ToolTypeOptions[0].group_zh,
  });

  const [toolsList, setToolsList] = useState([]);
  const [sortBy, setSortBy] = useState("updatedAt");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );

  const currentPage = useSelector(
    (state: Store) => state.indicatorsReducer.currentPage,
  );

  const [roleList, setRoleList] = useState([]);

  useEffect(() => {
    const r: any = [];
    adminRolesList.map((i: any) => {
      if (i.isEARole) {
        r.push(i);
      }
    });
    setRoleList(r);
  }, [adminRolesList]);

  useEffect(() => {
    dispatch(getAdminRolesList(false));
    setPageNumber(1);
  }, []);

  useEffect(() => {
    // dispatch(getEAList(false, { by: sortBy, order: sortOrder }))
    // dispatch(getEAUnauthorizedList(false))
    const t: any = sort(toolList, sortBy, sortOrder.toLowerCase());
    dispatch(
      getAdminRolesToolList(
        false,
        parseInt(selectedToolType?.id),
        selectedRole,
        pageNumber,
        { by: sortBy, order: sortOrder },
        pageLimit,
      ),
    );

    // setToolList(t);
  }, [sortOrder, sortBy]);

  const setSorting = (prop: any) => {
    if (sortBy === prop) {
      setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
    } else {
      setSortBy(prop);
      setSortOrder("ASC");
    }
  };

  useEffect(() => {
    const tools: any = [];
    setToolList([]);

    if (toolsLists.length) {
      toolsLists.map((i: any) => {
        tools.push({
          ...i,
          type: TOOLSMAP[i.type],
          // className: i.classList.join(' ')
        });
      });
      setToolList(tools);
    }
  }, [toolsLists]);

  useEffect(() => {
    setPageCount(Math.ceil(totalPages / pageLimit));
  }, [pageLimit, totalPages]);

  useEffect(() => {
    getTools();
    setPageNumber(1);
  }, [toolsList]);

  const getTools = () => {
    const tools = Object.assign([], toolsList);
    if (tools.length > 0) {
      const start = pageNumber * pageLimit - pageLimit;
      const end = start + pageLimit;

      const t = tools.slice(start, end);
      setToolList(() => t);
    }
  };

  useEffect(() => {
    getTools();
    dispatch(
      getAdminRolesToolList(
        false,
        parseInt(selectedToolType?.id),
        selectedRole,
        pageNumber,
        { by: sortBy, order: sortOrder },
        pageLimit,
      ),
    );
  }, [pageNumber, pageLimit]);

  useEffect(() => {
    dispatch(
      getAdminRolesToolList(
        false,
        parseInt(selectedToolType?.id),
        selectedRole,
        pageNumber,
        { by: "createdAt", order: "DESC" },
        pageLimit,
      ),
    );
  }, [selectedRole, selectedToolType]);

  useEffect(() => {
    // setPageNumber(currentPage);
  }, [currentPage]);

  const onPageChange = (pageNum: number) => {
    setPageNumber(pageNum);
    dispatch(setInCurrentPage(pageNum));
  };

  const handlePageClick = (event: any) => {
    // setPageCount(Math.ceil(totalPages / pageLimit));
    setPageNumber(event.selected + 1);
    dispatch(setInCurrentPage(event.selected + 1));
  };

  return (
    <>
      {isLoading && <CenterLoader />}
      <Row className="py-20 toollist-select">
        <Col className="col-sm-6 col-12 mt-sm-0">
          <div className="justify-content-start p-2 active-user">
            <label className="control-label mb-0">
              <FormattedMessage
                id="Admin.GrantTool.Tools"
                defaultMessage="Tool Type"
              />
            </label>
            <Select
              options={ToolTypeOptions}
              getOptionLabel={(option: any) =>
                local === "en" ? option.group_en : option.group_zh
              }
              getOptionValue={(option: any) => option.id}
              onChange={(option: any) => {
                setSelectedToolType(option);
                setPageNumber(1);
              }}
              value={selectedToolType}
              // placeholder={useIntl().formatMessage({
              //   id: "select.ToolType",
              //   defaultMessage: "Select Tool Type",
              // })}
              className="custom-select-dropdown w-200"
              classNamePrefix="react-select-dropdown"
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
          </div>
        </Col>
        <Col className="col-sm-6 col-12 mt-sm-0 ">
          <div className="justify-content-end p-2 active-user">
            <label className="control-label mb-0">
              <FormattedMessage
                id="Admin.GrantTool.Roles"
                defaultMessage="Role Type"
              />
            </label>
            <Select
              options={roleList}
              getOptionLabel={(option: any) => option.roleName}
              getOptionValue={(option: any) => option.id}
              onChange={(option: any) => {
                setSelectedRole(option.id);
                setPageNumber(1);
              }}
              // placeholder={useIntl().formatMessage({
              //   id: "select.Role",
              //   defaultMessage: "Select Role",
              // })}
              className="custom-select-dropdown w-200"
              classNamePrefix="react-select-dropdown"
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
          </div>
        </Col>
      </Row>
      <div className="table-responsive">
        {toolList.length > 0 ? (
          <>
            {" "}
            <table className="table table-hover m-0">
              <thead>
                <tr>
                  <th
                    style={{ width: "20%" }}
                    // onClick={() => setSorting("toolId")}
                    // className="c-p"
                  >
                    <FormattedMessage
                      id="table.head.ToolID"
                      defaultMessage="Tool ID"
                    />{" "}
                    {sortBy === "toolId" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                  {/* <th><FormattedMessage id="table.head.ID" defaultMessage="ID" /> </th> */}
                  <th
                    style={{ width: "20%" }}
                    // onClick={() => setSorting("toolType")}
                    // className="c-p"
                  >
                    <FormattedMessage
                      id="table.head.Type"
                      defaultMessage="Type"
                    />
                    {/* {sortBy === "toolType" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null} */}
                  </th>
                  <th
                    style={{ width: "20%" }}

                    //  onClick={() => setSorting("name")}
                    // className="c-p"
                  >
                    <FormattedMessage
                      id="table.head.ToolName"
                      defaultMessage="Tool Name"
                    />{" "}
                    {sortBy === "name" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                  <th
                    style={{
                      width: "40%",
                      wordBreak: "break-all",
                      overflow: "hidden",
                    }}
                    //  onClick={() => setSorting("className")}
                    //  className="c-p"
                  >
                    <FormattedMessage
                      id="table.head.BelongClass"
                      defaultMessage="Belong Class"
                    />
                    {sortBy === "className" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                </tr>
              </thead>
              <tbody>
                {toolList.length &&
                  toolList.map((i: any) => (
                    <tr>
                      <td>{i.toolId}</td>
                      {/* + '-' + TOOLSMAP_ID[i.type]} */}
                      {/* <td>{index + 1}</td> */}
                      <td>{i.type}</td>
                      <td>{i.name}</td>
                      <td className="td-overflow">{i.classList}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        ) : (
          <NoDatFound />
        )}
      </div>
      {totalRecord > 10 && toolList.length > 0 && (
        <>
          <FormControl className="pagelimit">
            <FormLabel id="demo-row-radio-buttons-group-label">
              <FormattedMessage id="page_size" defaultMessage="Page Size" />
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={pageLimit}
              onChange={(e: any) => {
                setPageLimit(e.target.value);
                setPageNumber(1);
              }}
            >
              <FormControlLabel value={10} control={<Radio />} label="10" />
              <FormControlLabel value={20} control={<Radio />} label="20" />
              <FormControlLabel value={50} control={<Radio />} label="50" />
            </RadioGroup>
          </FormControl>
          {/* <ReactPaginate
            nextLabel={
              <FormattedMessage id="pagination.Next" defaultMessage="next" />
            }
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            marginPagesDisplayed={3}
            pageCount={pageCount}
            forcePage={pageNumber - 1}
            previousLabel={
              <FormattedMessage
                id="pagination.Previous"
                defaultMessage="previous"
              />
            }
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
          /> */}
          <Pagination
            itemsPerPage={pageLimit}
            totalPages={totalRecord}
            onPageChage={onPageChange}
            page={pageNumber - 1}
          />
        </>
      )}
    </>
  );
};

export default ToolList;
