import { useEffect, useState } from "react";
import { useFormik } from "formik";

import { useSelector } from "react-redux";
import {
  editGiftList,
  getAdminRolesList,
  getClasses,
  getGiftCategoryList,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import Loader from "../common/Loader";
import Select from "react-select";
import { dispatch } from "../../redux";
import { formatDate } from "../../util/utils";
import { Modal } from "react-bootstrap";
import { EditGiftSchema } from "../../util/validationSchema";
import { FormattedMessage, useIntl } from "react-intl";
import { FaTimesCircle } from "react-icons/fa";

const EditGiftModal = ({ onModalClose, gift }: any) => {
  const [ButtonClick, setButtonClick] = useState(false);
  const isAPILoading = useSelector(
    (state: Store) => state.giftReducer.isLoading,
  );

  const giftCatList = useSelector(
    (state: Store) => state.giftReducer.giftCatList,
  );
  const [selectedGiftCat, setselectedGiftCat] = useState({});

  useEffect(() => {
    dispatch(getClasses(false, 0, { by: "createdAt", order: "ASC" }));
    dispatch(getGiftCategoryList(false));
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      details: Object.keys(gift).length != 0 ? gift.details : "",
      code: Object.keys(gift).length != 0 ? gift.code : 0,
      reason: Object.keys(gift).length != 0 ? gift.reason : "",
      pointsRequired: Object.keys(gift).length != 0 ? gift.pointsRequired : 0,
      exipryDate:
        Object.keys(gift).length != 0
          ? formatDate(gift.exipryDate, "YYYY-MM-DD")
          : "",
      giftCategory: Object.keys(gift).length != 0 ? gift?.giftCategory?.id : "",
    },
    validationSchema: EditGiftSchema,
    onSubmit: async (values) => {
      if (!ButtonClick) {
        dispatch(editGiftList(false, gift.id, values));
        setButtonClick(true);
      } //asa
    },
  });

  useEffect(() => {
    if (!isAPILoading && ButtonClick) {
      onModalClose();
      setButtonClick(false);
    }
  }, [isAPILoading]);

  useEffect(() => {
    dispatch(getAdminRolesList(false));
    if (gift.giftCategory) {
      setselectedGiftCat(gift.giftCategory);
    }
  }, [gift]);

  const { touched, errors } = formik;
  return (
    <Modal centered show={true} onHide={() => onModalClose(false)}>
      <Modal.Header>
        <h4 className="modal-title">
          <FormattedMessage id="head.EditGift" defaultMessage="Edit Gift" />
        </h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onModalClose}
        >
          <FaTimesCircle />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group row">
          <label className="col-sm-2 col-form-label col-form-label-sm">
            {" "}
            <FormattedMessage id="head.Category" defaultMessage="Category" />
          </label>
          <div className="col-sm-10">
            <Select
              name="giftCategory"
              value={selectedGiftCat}
              onChange={(option: any) => {
                formik.setFieldValue("giftCategory", option.id);
                setselectedGiftCat(option);
              }}
              onBlur={() => formik.setFieldTouched("giftCategory", true)}
              options={giftCatList}
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.id}
              className="custom-select-dropdown"
              classNamePrefix="react-select-dropdown"
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
            {errors.giftCategory && touched.giftCategory && (
              <div className="text-error">
                <FormattedMessage
                  id={errors.giftCategory.toString()}
                  defaultMessage="This field is required"
                />
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-2 col-form-label col-form-label-sm">
            <FormattedMessage id="tab.Detail" defaultMessage="Detail" />
          </label>
          <div className="col-sm-10">
            <input
              type="text"
              name="details"
              value={formik.values.details}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control form-control-md"
              id="colFormLabelSm"
              // placeholder={useIntl().formatMessage({
              //   id: "select.Detail",
              //   defaultMessage: "Detail",
              // })}
            />
            {errors.details && touched.details && (
              <div className="text-error">
                <FormattedMessage
                  id={errors.details.toString()}
                  defaultMessage="This field is required"
                />
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-2 col-form-label col-form-label-lg">
            <FormattedMessage id="Admin.GiftsList.Code" defaultMessage="Code" />
          </label>
          <div className="col-sm-10">
            <input
              type="text"
              name="code"
              value={formik.values.code}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control form-control-md"
              id="colFormLabelSm"
              // placeholder={useIntl().formatMessage({
              //   id: "select.Code",
              //   defaultMessage: "Code",
              // })}
            />
          </div>
        </div>

        {/* <div className="form-group row">
                            <label className="col-sm-2 col-form-label col-form-label-lg">amount</label>
                            <div className="col-sm-10">
                                <input type="text"
                                    name="code"
                                    value={formik.values.code}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}

                                    className="form-control form-control-md" id="colFormLabelSm" placeholder="Code" />
                            </div>
                        </div> */}

        <div className="form-group row">
          <label className="col-sm-2 col-form-label col-form-label-sm">
            <FormattedMessage id="Admin.Grant.Reason" defaultMessage="Reason" />
          </label>
          <div className="col-sm-10">
            <input
              type="Status"
              name="reason"
              value={formik.values.reason}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control form-control-md"
              id="colFormLabelSm"
              // placeholder={useIntl().formatMessage({
              //   id: "select.Reason",
              //   defaultMessage: "Reason",
              // })}
            />
            {errors.reason && touched.reason && (
              <div className="text-error">
                <FormattedMessage
                  id={errors.reason.toString()}
                  defaultMessage="This field is required"
                />
              </div>
            )}
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label col-form-label-sm">
            <FormattedMessage
              id="Admin.Grant.Ponits"
              defaultMessage="Ponits Required"
            />{" "}
          </label>
          <div className="col-sm-10">
            <input
              type="Status"
              name="pointsRequired"
              value={formik.values.pointsRequired}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control form-control-md"
              id="colFormLabelSm"
              // placeholder={useIntl().formatMessage({
              //   id: "select.Status",
              //   defaultMessage: "Status",
              // })}
            />
            {errors.pointsRequired && touched.pointsRequired && (
              <div className="text-error">
                <FormattedMessage
                  id={errors.pointsRequired.toString()}
                  defaultMessage="This field is required"
                />
              </div>
            )}
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label col-form-label-sm">
            <FormattedMessage
              id="Admin.Grant.Expiry"
              defaultMessage="Expiry Date"
            />{" "}
          </label>
          <div className="col-sm-10">
            <input
              type="date"
              name="exipryDate"
              value={formik.values.exipryDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control form-control-md"
              id="colFormLabelSm"
              // placeholder="Reason"
            />
            {errors.exipryDate && touched.exipryDate && (
              <div className="text-error">
                <FormattedMessage
                  id={errors.exipryDate.toString()}
                  defaultMessage="This field is required"
                />
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          onClick={onModalClose}
        >
          <FormattedMessage id="page.Cancel" defaultMessage=" Cancel" />
        </button>
        <button
          type="button"
          className="btn btn-primary"
          style={{ display: "inherit" }}
          onClick={() => formik.handleSubmit()}
          data-dismiss="modal"
        >
          <FormattedMessage id="page.submit" defaultMessage="Submit" />
          {isAPILoading && <Loader />}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditGiftModal;
