/* eslint-disable no-case-declarations */
import {
  GET_INDICATORS_LIST,
  ADD_INDICATORS_LIST,
  EDIT_INDICATORS_LIST,
  GET_INDICATORS_DETAILS,
  GET_INDICATORS_VERSION_LIST,
  ADD_INDICATORS_VERSION,
  EDIT_INDICATORS_VERSION,
  DELETE_INDICATORS_VERSION,
  DELETE_INDICATOR,
  SET_PAGE,
} from "../constants/action-types";
import { IndicatorsData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";
// import { Posts } from "../Actions";

const initialState = {
  progress: false,
  isLoading: false,
  onSaveLoading: false,
  indicatorsList: {},
  indicatorsDetails: {},
  indicatorVersion: [],
  totalRecord: 0,
  currentPage: 1,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: IndicatorsData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case SET_PAGE:
        return {
          ...state,
          currentPage: action.payload,
        };

      case GET_INDICATORS_LIST.GET_INDICATORS_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_INDICATORS_LIST.GET_INDICATORS_LIST_SUCCESS:
        const allPost: any = action.payload; // [{ id: 2, body: "Abcd", title: "title", userId: 3 }, { id: 2, body: "Abcd", title: "title", userId: 3 }];
        return {
          ...state,
          totalRecord: allPost.totalCount,
          indicatorsList: allPost.data,
          isLoading: false,
        };

      case GET_INDICATORS_LIST.GET_INDICATORS_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_INDICATORS_LIST.ADD_INDICATORS_LIST_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case ADD_INDICATORS_LIST.ADD_INDICATORS_LIST_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case ADD_INDICATORS_LIST.ADD_INDICATORS_LIST_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case EDIT_INDICATORS_LIST.EDIT_INDICATORS_LIST_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case EDIT_INDICATORS_LIST.EDIT_INDICATORS_LIST_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case EDIT_INDICATORS_LIST.EDIT_INDICATORS_LIST_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case GET_INDICATORS_DETAILS.GET_INDICATORS_DETAILS_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_INDICATORS_DETAILS.GET_INDICATORS_DETAILS_SUCCESS:
        return {
          ...state,
          indicatorsDetails: action.payload,
          isLoading: false,
        };

      case GET_INDICATORS_DETAILS.GET_INDICATORS_DETAILS_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_INDICATORS_VERSION_LIST.GET_INDICATORS_VERSION_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_INDICATORS_VERSION_LIST.GET_INDICATORS_VERSION_LIST_SUCCESS:
        const indicatorVersion: any = action.payload;
        return {
          ...state,
          indicatorVersion: indicatorVersion.data, // action.payload,
          totalRecord: indicatorVersion.totalCount || 1,
          isLoading: false,
        };

      case GET_INDICATORS_VERSION_LIST.GET_INDICATORS_VERSION_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_INDICATORS_VERSION.ADD_INDICATORS_VERSION_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case ADD_INDICATORS_VERSION.ADD_INDICATORS_VERSION_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case ADD_INDICATORS_VERSION.ADD_INDICATORS_VERSION_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case EDIT_INDICATORS_VERSION.EDIT_INDICATORS_VERSION_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case EDIT_INDICATORS_VERSION.EDIT_INDICATORS_VERSION_SUCCESS:
        return {
          ...state,
          indicatorVersion: action.payload,
          onSaveLoading: false,
        };

      case EDIT_INDICATORS_VERSION.EDIT_INDICATORS_VERSION_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case DELETE_INDICATORS_VERSION.DELETE_INDICATORS_VERSION_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case DELETE_INDICATORS_VERSION.DELETE_INDICATORS_VERSION_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case DELETE_INDICATORS_VERSION.DELETE_INDICATORS_VERSION_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case DELETE_INDICATOR.DELETE_INDICATOR_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case DELETE_INDICATOR.DELETE_INDICATOR_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case DELETE_INDICATOR.DELETE_INDICATOR_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      default:
        return state;
    }
  },
);

export default reducer;
