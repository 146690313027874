/* eslint-disable no-case-declarations */
import {
  GET_USER_VPS_MACHINE_ACCESS_LIST,
  EDIT_USER_VPS_MACHINE_ACCESS_LIST,
  GET_VPS_MACHINE_TYPE_LIST,
  GET_VPS_LIST,
  UPDATE_VPS_STATUS,
  VPS_RESET_PASSWORD,
} from "../constants/action-types";
import { VpsMachineData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";
// import { Posts } from "../Actions";

const initialState = {
  progress: false,
  isLoading: false,
  onSaveLoading: false,
  UserVPSMachineList: [],
  VPSMachineTypeList: [],
  totalRecord: 0,
  isVPSstatusUpdated: false,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: VpsMachineData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case GET_VPS_MACHINE_TYPE_LIST.GET_VPS_MACHINE_TYPE_LIST_INITIALIZATION:
        return {
          ...state,
          VPSMachineTypeList: [],
          isLoading: true,
        };

      case GET_VPS_MACHINE_TYPE_LIST.GET_VPS_MACHINE_TYPE_LIST_SUCCESS:
        return {
          ...state,
          VPSMachineTypeList: action.payload,
          isLoading: false,
        };

      case GET_VPS_MACHINE_TYPE_LIST.GET_VPS_MACHINE_TYPE_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_USER_VPS_MACHINE_ACCESS_LIST.GET_USER_VPS_MACHINE_ACCESS_LIST_INITIALIZATION:
        return {
          ...state,
          UserVPSMachineList: [],
          isLoading: true,
        };

      case GET_USER_VPS_MACHINE_ACCESS_LIST.GET_USER_VPS_MACHINE_ACCESS_LIST_SUCCESS:
        const res: any = action.payload;
        return {
          ...state,
          UserVPSMachineList: res.adminAllowed,
          isLoading: false,
        };

      case GET_USER_VPS_MACHINE_ACCESS_LIST.GET_USER_VPS_MACHINE_ACCESS_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case EDIT_USER_VPS_MACHINE_ACCESS_LIST.EDIT_USER_VPS_MACHINE_ACCESS_LIST_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case EDIT_USER_VPS_MACHINE_ACCESS_LIST.EDIT_USER_VPS_MACHINE_ACCESS_LIST_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case EDIT_USER_VPS_MACHINE_ACCESS_LIST.EDIT_USER_VPS_MACHINE_ACCESS_LIST_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };
      case GET_VPS_LIST.GET_VPS_LIST_INITIALIZATION:
        return {
          ...state,
          UserVPSMachineList: [],
          isLoading: true,
        };

      case GET_VPS_LIST.GET_VPS_LIST_SUCCESS:
        const data: any = action.payload;
        return {
          ...state,
          UserVPSMachineList: data.data,
          totalRecord: data.totalCount,
          isLoading: false,
        };

      case GET_VPS_LIST.GET_VPS_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
          totalRecord: 0,
        };

      case UPDATE_VPS_STATUS.UPDATE_VPS_STATUS_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
          isVPSstatusUpdated: true,
        };

      case UPDATE_VPS_STATUS.UPDATE_VPS_STATUS_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isVPSstatusUpdated: false,
        };

      case UPDATE_VPS_STATUS.UPDATE_VPS_STATUS_ERROR:
        return {
          ...state,
          isLoading: false,
          isVPSstatusUpdated: false,
        };

      case VPS_RESET_PASSWORD.VPS_RESET_PASSWORD_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case VPS_RESET_PASSWORD.VPS_RESET_PASSWORD_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case VPS_RESET_PASSWORD.VPS_RESET_PASSWORD_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      default:
        return state;
    }
  },
);

export default reducer;
