import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import { useNavigate } from "react-router";
import { dispatch } from "../../redux";
import { getBillsList } from "../../redux/action";
import NoDatFound from "../../Components/common/NoDatFound";
import Pagination from "../../Components/common/Paginate/Pagination";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import { FormattedMessage } from "react-intl";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { BsSortDown, BsSortUp } from "react-icons/bs";
import { formatDate } from "../../util/utils";

const Bills = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<any>();
  const [status, setStatus] = useState("approved");
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("DESC");

  const setSorting = (prop: string) => {
    if (sortBy === prop) {
      setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
    } else {
      setSortBy(prop);
      setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
    }
  };

  const isLoading = useSelector((state: Store) => state.billReducer.isLoading);

  const [pageNumber, setPageNumber] = useState<any>();
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const totalRecord = useSelector(
    (state: Store) => state.billReducer.totalRecord,
  ) as any;

  const BillList: any = useSelector(
    (state: Store) => state.billReducer.BillList,
  ) as any;

  const [filterQ, setFilterQ] = useState("");
  const [text, settext] = useState<any>();
  const [Alltext, setAllText] = useState<any>();

  useEffect(() => {
    const tid = setTimeout(() => {
      setAllText(text);
    }, 1000);
    return () => {
      clearTimeout(tid);
    };
  }, [text]);

  useEffect(() => {
    setFilter(text);
  }, [Alltext]);

  useEffect(() => {
    if (pageNumber && pageLimit) {
      dispatch(
        getBillsList(
          false,
          pageNumber,
          filterQ,
          parseInt(pageLimit),
          sortOrder,
          sortBy,
        ),
      );
    }
  }, [filterQ, pageLimit, pageNumber, sortOrder, sortBy]);

  useEffect(() => {
    const storedPageNumber: any = localStorage.getItem("pageNumber");
    const storedPageLimit: any = localStorage.getItem("pageLimit");
    const storePageTabs: any = localStorage.getItem("userTab");
    const storePageStatus: any = localStorage.getItem("userStatus");
    if (storedPageNumber && storedPageLimit) {
      setPageNumber(parseInt(storedPageNumber));
      setPageLimit(parseInt(storedPageLimit));
      setActiveTab(parseInt(storePageTabs));
      setStatus(storePageStatus);
    } else {
      setPageNumber(1);
      setPageLimit(10);
      setActiveTab(0);
      setStatus("approved");
    }
  }, []);

  const setFilter = (text: string) => {
    let q = "";
    if (text?.length > 0) q = q + `&q=${encodeURIComponent(text)}`;
    setFilterQ(q);
    if (text) {
      setPageNumber(1);
      setPageLimit(10);
      localStorage.setItem("pageNumber", "1");
      localStorage.setItem("pageLimit", "10");
      sessionStorage.setItem("filterE", q);
    }

    return 0;
  };

  const onPageChange = (pageNum: number) => {
    sessionStorage.setItem("pageE", pageNum.toString());
    localStorage.setItem("pageNumber", pageNum.toString());
    localStorage.setItem("pageLimit", pageLimit);
    localStorage.setItem("userTab", activeTab);
    setPageNumber(pageNum);
  };

  const viewDetail = (id: any) => {
    localStorage.setItem("userTab", activeTab);
    localStorage.setItem("userStatus", status);
    navigate("/bills/" + id);
  };

  return (
    <div className="content-wrapper">
      <div className="content">
        {isLoading && <CenterLoader />}
        <header className="page-header">
          <div className="d-flex flex-wrap align-items-center">
            <div className="mr-auto">
              <h1>
                {" "}
                <FormattedMessage
                  id="Admin.Bills"
                  defaultMessage="Admin Bills"
                />
              </h1>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          <Card>
            <Card.Body className="p-0">
              {isLoading && <CenterLoader />}

              <Row className="p-20">
                <Col
                  style={{ display: "inline-flex" }}
                  className="col-sm-6 col-12"
                >
                  <label style={{ position: "absolute", bottom: "0" }}>
                    <FormattedMessage id="Filter" defaultMessage="Filter" />:
                  </label>
                  <input
                    onChange={(e) => settext(e.target.value)}
                    value={text}
                    type="text"
                    style={{
                      maxWidth: "220px",
                      paddingLeft: "39px",
                      borderRadius: "0px",
                      border: "none",
                      borderBottom: "1px solid",
                    }}
                    className="form-control"
                  />
                </Col>
              </Row>
              {BillList?.length > 0 ? (
                <>
                  <div className="">
                    <section className="">
                      <div className="table-responsive">
                        <table className="table table-hover m-0">
                          <thead>
                            <tr>
                              <th
                                style={{ cursor: "pointer" }}
                                onClick={() => setSorting("id")}
                              >
                                <FormattedMessage
                                  id="table.head.ID"
                                  defaultMessage="ID"
                                />
                                {sortBy === "id" &&
                                  (sortOrder === "ASC" ? (
                                    <BsSortDown />
                                  ) : (
                                    <BsSortUp />
                                  ))}
                              </th>
                              <th
                                style={{ cursor: "pointer" }}
                                onClick={() => setSorting("paymentIntentId")}
                              >
                                <FormattedMessage
                                  id="table.head.PaymentIntentId"
                                  defaultMessage="PaymentIntentId"
                                />
                                {sortBy === "paymentIntentId" &&
                                  (sortOrder === "ASC" ? (
                                    <BsSortDown />
                                  ) : (
                                    <BsSortUp />
                                  ))}
                              </th>
                              <th>
                                <FormattedMessage
                                  id="table.head.PaymentId"
                                  defaultMessage="PaymentId"
                                />
                              </th>
                              <th>
                                <FormattedMessage
                                  id="table.head.Processed"
                                  defaultMessage="Processed"
                                />
                              </th>
                              <th>
                                <FormattedMessage
                                  id="table.head.UserId"
                                  defaultMessage="UserId"
                                />
                              </th>
                              <th
                                style={{ cursor: "pointer" }}
                                onClick={() => setSorting("createdAt")}
                              >
                                <FormattedMessage
                                  id="table.head.CreatedAt"
                                  defaultMessage="CreatedAt"
                                />
                                {sortBy === "createdAt" &&
                                  (sortOrder === "ASC" ? (
                                    <BsSortDown />
                                  ) : (
                                    <BsSortUp />
                                  ))}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {BillList.length &&
                              BillList.map((i: any) => (
                                <tr>
                                  <td
                                    onClick={() => viewDetail(i.id)}
                                    className="pointer"
                                  >
                                    {i.id}
                                  </td>
                                  <td
                                    onClick={() => viewDetail(i.id)}
                                    className="pointer"
                                  >
                                    {i.paymentIntentId}
                                  </td>
                                  <td
                                    onClick={() => viewDetail(i.id)}
                                    className="pointer"
                                  >
                                    {i?.paymentId}
                                  </td>
                                  <td
                                    onClick={() => viewDetail(i.id)}
                                    className="pointer"
                                  >
                                    {i.processed ? "true" : "false"}
                                  </td>
                                  <td
                                    onClick={() => viewDetail(i.id)}
                                    className="pointer"
                                  >
                                    {i?.user?.id ? i?.user?.id : "-"}
                                  </td>
                                  <td
                                    onClick={() => viewDetail(i.id)}
                                    className="pointer"
                                  >
                                    {formatDate(i.createdAt, "DD MMM, YYYY")}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </section>
                  </div>
                  {totalRecord > 10 && (
                    <>
                      <FormControl className="pagelimit">
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          <FormattedMessage
                            id="page_size"
                            defaultMessage="Page Size"
                          />
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={pageLimit}
                          onChange={(e: any) => {
                            setPageLimit(e.target.value);
                            localStorage.setItem("pageLimit", e.target.value);
                            localStorage.setItem(
                              "pageNumber",
                              pageNumber.toString(),
                            );
                            setPageNumber(1);
                          }}
                        >
                          <FormControlLabel
                            value={10}
                            control={<Radio />}
                            label="10"
                          />
                          <FormControlLabel
                            value={20}
                            control={<Radio />}
                            label="20"
                          />
                          <FormControlLabel
                            value={50}
                            control={<Radio />}
                            label="50"
                          />
                        </RadioGroup>
                      </FormControl>
                      <Pagination
                        itemsPerPage={pageLimit}
                        totalPages={totalRecord}
                        onPageChage={onPageChange}
                        page={pageNumber - 1}
                      />
                    </>
                  )}
                </>
              ) : (
                <p>{!isLoading && <NoDatFound />}</p>
              )}
            </Card.Body>
          </Card>
        </section>
      </div>
    </div>
  );
};

export default Bills;
