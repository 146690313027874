import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
// import Dropzone from "react-dropzone";

import { useSelector } from "react-redux";
import {
  // addActivity,
  // addActivityPoints,
  // addAnnouncement,
  addGiftCategoryList,
  // editGiftCategoryList,
  getAdminRolesList,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
// import { convertToRaw, EditorState, convertFromHTML } from "draft-js";
import Loader from "../common/Loader";
// import EditorContainer from "../RichTextEditor/Editor";
// import { _UploadMedia } from "../../service/MediaUpload/classes.service";
// import FileUpload from "../common/MediaUpload/FileUpload";
// import draftToHtml from "draftjs-to-html";
// import Select from "react-select";
import { dispatch } from "../../redux";
// import { useParams } from "react-router";
import { Switch } from "@mui/material";
import { Modal } from "react-bootstrap";
import { AddGiftCategorySchema } from "../../util/validationSchema";
import { FormattedMessage } from "react-intl";
import { FaTimesCircle } from "react-icons/fa";

const AddGiftCategoryModal = ({ onModalClose, gift }: any) => {
  const [ButtonClick, setButtonClick] = useState(false);
  const isAPILoading = useSelector(
    (state: Store) => state.giftReducer.onSaveLoading,
  );
  // const adminRoleList = useSelector(
  //   (state: Store) => state.adminRolesReducer.adminRoleList
  // ) as Array<Object>;
  // const [selectedRoles, setselectedRoles] = useState({});
  // const urlParams = useParams();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      show: false,
    },
    validationSchema: AddGiftCategorySchema,
    onSubmit: async (values) => {
      if (!ButtonClick) {
        if (Object.keys(gift).length <= 0) {
          dispatch(addGiftCategoryList(true, values));
        } else {
          //None
        }
        setButtonClick(true);
      }
    },
  });

  useEffect(() => {
    if (!isAPILoading && ButtonClick) {
      onModalClose();
      setButtonClick(false);
    }
  }, [isAPILoading, ButtonClick]);

  useEffect(() => {
    dispatch(getAdminRolesList(false));
  }, []);

  const { touched, errors } = formik;
  return (
    <Modal centered show={true} onHide={() => onModalClose(false)}>
      <Modal.Header>
        <h4 className="modal-title" id="AddCourseLabel">
          <FormattedMessage
            id="Gift.AddCategory"
            defaultMessage="Add Gift Category"
          />
        </h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onModalClose}
        >
          <FaTimesCircle />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label className="control-label">
            <FormattedMessage id="Gift.Name" defaultMessage="Name" />
          </label>
          <input
            type="text"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="form-control"
          />
          {errors.name && touched.name && (
            <div className="text-error">
              <FormattedMessage
                id={errors.name.toString()}
                defaultMessage="This field is required"
              />
            </div>
          )}
        </div>

        {/* <div className="form-group">
                            <label className="control-label">Info</label>
                            <input type="text" name="info"
                                value={formik.values.info}

                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className="form-control" />
                            {errors.info && touched.info && (<div className="text-error"  >{errors.info}</div>)}
                        </div> */}

        <div className="form-group">
          <FormattedMessage id="Gift.Show" defaultMessage="Show" />
          <Switch
            checked={formik.values.show}
            onChange={() => {
              formik.setFieldValue("show", !formik.values.show);
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          onClick={onModalClose}
        >
          <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
        </button>
        <button
          type="button"
          className="btn btn-primary"
          style={{ display: "inherit" }}
          onClick={() => formik.handleSubmit()}
          data-dismiss="modal"
        >
          <FormattedMessage id="page.submit" defaultMessage="Submit" />
          {isAPILoading && <Loader />}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddGiftCategoryModal;
