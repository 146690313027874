import React, { useEffect, useState } from "react";
import { useFormik } from "formik";

import { Row, Col, Modal } from "react-bootstrap";
import { AddClassSchema } from "../../util/validationSchema";
import { useDispatch, useSelector } from "react-redux";
import { addClasses, editClasses } from "../../redux/action";
import { Store } from "../../redux/Actions";
import Loader from "../common/Loader";
import { _UploadMedia } from "../../service/MediaUpload/classes.service";
import { BsTrash } from "react-icons/bs";
import Select from "react-select";
import FileUpload from "../common/MediaUpload/FileUpload";
import {
  disablePastDate,
  formatDate,
  getFileName,
  isPositiveNumbersOnly,
} from "../../util/utils";
import { FormattedMessage } from "react-intl";
import CenterLoader from "../common/CenterLoader/CenterLoader";
import { FaTimesCircle } from "react-icons/fa";
import { toast } from "react-toastify";

const ClassModal = ({ onModalClose, classCategories, editClass }: any) => {
  const dispatch = useDispatch();

  const isAPILoading = useSelector(
    (state: Store) => state.classReducer.onSaveLoading,
  );

  const [ButtonClick, setButtonClick] = useState(false);
  const [SelectedClassCat, setSelectedClassCat] = useState<File>();
  const [fileUploadURL, setFileUploadURL] = useState<any>(null);
  const [termsConditionURL, setTermConditionsURL] = useState<any>(null);
  const [termsConditionFileName, setTermsConditionsFileName] =
    useState<any>(null);
  const [fileLoading, setFileLoading] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: editClass != null ? editClass?.name : "",
      courseId: editClass != null ? editClass?.courseId : "",
      classCategory: editClass != null ? editClass?.classCategory?.id : 0,
      initDemoLimit: editClass != null ? editClass?.initDemoLimit : "",
      initLiveLimit: editClass != null ? editClass?.initLiveLimit : "",
      initExpiryDays: editClass != null ? editClass?.initExpiryDays : "",
      initRepeaterExpiryDays:
        editClass != null ? editClass?.initRepeaterExpiryDays : 0,
      limit: editClass != null ? editClass?.user_limit : 0,
      cost: editClass != null ? editClass?.cost : 0,
      originalPrice: editClass != null ? editClass?.originalPrice : 0,
      sellingPrice: editClass != null ? editClass?.sellingPrice : 0,
      image_path: editClass != null ? editClass?.image_path : "",
      terms_condtion_path:
        editClass !== null ? editClass?.terms_condtion_path : "",
      info: editClass != null ? editClass?.info || "" : "",
      isActive:
        editClass != null
          ? editClass?.status === "close"
            ? false
            : true || true
          : true,
      closeApplicationDate:
        editClass != null
          ? formatDate(editClass?.closeApplicationDate, "YYYY-MM-DD")
          : formatDate(new Date(), "YYYY-MM-DD"),
    },
    validationSchema: AddClassSchema,
    onSubmit: async (values) => {
      if (!ButtonClick) {
        values.initDemoLimit =
          values.initDemoLimit.toString() == "" ? 0 : values.initDemoLimit;
        values.initLiveLimit =
          values.initLiveLimit.toString() == "" ? 0 : values.initLiveLimit;
        values.initExpiryDays =
          values.initExpiryDays.toString() == "" ? 0 : values.initExpiryDays;
        values.initRepeaterExpiryDays =
          values.initRepeaterExpiryDays.toString() == ""
            ? 0
            : values.initRepeaterExpiryDays;
        values.cost = values.cost.toString() == "" ? 0 : values.cost;
        values.limit = values.limit.toString() == "" ? 0 : values.limit;
        values.originalPrice =
          values.originalPrice.toString() == "" ? 0 : values.originalPrice;
        values.sellingPrice =
          values.sellingPrice.toString() == "" ? 0 : values.sellingPrice;
        if (editClass === null) {
          const vals = values;
          delete vals.info;

          dispatch(addClasses(values));
        } else {
          const editClassValues = {
            name: values.name,
            courseId: values.courseId,
            info: values.info,
            limit: values.limit.toString() == "" ? 0 : values.limit,
            image_path: values.image_path,
            terms_condtion_path: values.terms_condtion_path,
            initDemoLimit:
              values.initDemoLimit.toString() == "" ? 0 : values.initDemoLimit,
            initLiveLimit:
              values.initLiveLimit.toString() == "" ? 0 : values.initLiveLimit,
            initExpiryDays:
              values.initExpiryDays.toString() == ""
                ? 0
                : values.initExpiryDays,
            initRepeaterExpiryDays:
              values.initRepeaterExpiryDays.toString() == ""
                ? 0
                : values.initRepeaterExpiryDays,
            classCategory: values.classCategory,
            closeApplicationDate: values.closeApplicationDate,
            cost: values.cost.toString() == "" ? 0 : values.cost,
            originalPrice:
              values.originalPrice.toString() == "" ? 0 : values.originalPrice,
            sellingPrice:
              values.sellingPrice.toString() == "" ? 0 : values.sellingPrice,
            isActive: values.isActive,
          };
          dispatch(editClasses(editClassValues, editClass.id, true));
        }
        setButtonClick(true);
      }
    },
  });

  useEffect(() => {
    setFileUploadURL(null);
    if (editClass !== null) {
      setSelectedClassCat(editClass.classCategory);
      setFileUploadURL(editClass.image_path);
      setTermConditionsURL(editClass.terms_condtion_path);
      setTermsConditionsFileName(getFileName(editClass.terms_condtion_path));
    }
  }, [editClass]);
  const { touched, errors } = formik;

  useEffect(() => {
    if (!isAPILoading && ButtonClick) {
      onModalClose();
    }
  }, [isAPILoading]);

  const handleDrop = async (acceptedFiles: any) => {
    setFileLoading(true);
    if (acceptedFiles[0].name) {
      const ImageTypes = ["png", "jpg", "jpeg", "bmp", "gif"];
      const extention = acceptedFiles[0].name.split(".");
      if (
        extention.length > 0 &&
        ImageTypes.includes(
          extention[extention.length - 1]?.toLocaleLowerCase(),
        )
      ) {
        const { fileUploadResponse, error }: any = await _UploadMedia(
          acceptedFiles[0] as File,
        );
        if (!error) {
          formik.setFieldValue("image_path", fileUploadResponse);
          setFileUploadURL(fileUploadResponse);
        }
        setFileLoading(false);
      } else {
        setFileLoading(false);
        toast.error("Only image files are allowed");
      }
    }
  };

  const onDeleteImage = () => {
    formik.setFieldValue("image_path", "");
    setFileUploadURL(null);
  };
  const handleDropTermsConditions = async (acceptedFiles: any) => {
    setFileLoading(true);
    if (acceptedFiles[0].name) {
      const ImageTypes = ["pdf"];
      const extention = acceptedFiles[0].name.split(".");
      if (
        extention.length > 0 &&
        ImageTypes.includes(
          extention[extention.length - 1]?.toLocaleLowerCase(),
        )
      ) {
        const { fileUploadResponse, error, get }: any = await _UploadMedia(
          acceptedFiles[0] as File,
        );
        if (!error) {
          formik.setFieldValue("terms_condtion_path", fileUploadResponse);
          setTermConditionsURL(fileUploadResponse);
          setTermsConditionsFileName(getFileName(fileUploadResponse));
        }
        setFileLoading(false);
      } else {
        setFileLoading(false);
        toast.error("Only pdf files are allowed");
      }
    }
  };
  const onDeleteTermsConditionsPDF = () => {
    formik.setFieldValue("terms_condtion_path", "");
    setTermConditionsURL(null);
    setTermsConditionsFileName(null);
  };
  const onCheckboxChange = (e: any) => {
    const chk: any = [];
    if (e.target.checked) {
      formik.setFieldValue("isActive", true);
    } else {
      formik.setFieldValue("isActive", false);
    }
  };

  return (
    <>
      {fileLoading && <CenterLoader />}
      <Modal
        centered
        size={"lg"}
        show={true}
        onHide={() => {
          onModalClose(false);
        }}
      >
        <Modal.Header>
          <h4 className="modal-title" id="AddCourseLabel">
            {editClass === null ? (
              <FormattedMessage id="head.AddClass" defaultMessage="Add Class" />
            ) : (
              <FormattedMessage
                id="head.EditClass"
                defaultMessage="Edit Class"
              />
            )}
          </h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onModalClose}
          >
            <FaTimesCircle />
          </button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="classmodal.name"
                    defaultMessage="Class Name "
                  />
                  *
                </label>
                <input
                  type="text"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                />
                {errors.name && touched.name && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.name.toString()}
                      defaultMessage="require"
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="classmodal.courseId"
                    defaultMessage="Course Id"
                  />
                </label>
                <input
                  type="text"
                  name="courseId"
                  value={formik.values.courseId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                />
                {errors.courseId && touched.courseId && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.courseId.toString()}
                      defaultMessage="require"
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="classmodal.Type"
                    defaultMessage="Type"
                  />
                  *
                </label>
                <Select
                  name="belong"
                  value={SelectedClassCat}
                  onChange={(option: any) => {
                    formik.setFieldValue("classCategory", option.id);
                    setSelectedClassCat(option);
                  }}
                  onBlur={() => formik.setFieldTouched("classCategory", true)}
                  options={classCategories}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.id}
                  className="custom-select-dropdown"
                  classNamePrefix="react-select-dropdown"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
                {errors.classCategory && touched.classCategory && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.classCategory.toString()}
                      defaultMessage="require"
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="classmodal.Demo"
                    defaultMessage="Init Demo limit"
                  />
                  *
                </label>
                <input
                  type="number"
                  name="initDemoLimit"
                  value={formik.values.initDemoLimit}
                  onChange={(e: any) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  className="form-control"
                  min={0}
                />
                {errors.initDemoLimit && touched.initDemoLimit && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.initDemoLimit.toString()}
                      defaultMessage="require"
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="classmodal.Live"
                    defaultMessage="Initial Live Limit"
                  />
                  *
                </label>
                <input
                  type="text"
                  name="initLiveLimit"
                  value={formik.values.initLiveLimit}
                  onChange={(e: any) => {
                    if (!isPositiveNumbersOnly(e, e.target.value)) {
                      return false;
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  className="form-control"
                  min={0}
                />
                {errors.initLiveLimit && touched.initLiveLimit && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.initLiveLimit.toString()}
                      defaultMessage="require"
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="classmodal.Expiry"
                    defaultMessage="Initial Expiry Days"
                  />
                  <small>
                    <FormattedMessage
                      id="classmodal.smalltag"
                      defaultMessage="(only for new student)"
                    />
                  </small>
                  *
                </label>
                <input
                  type="text"
                  name="initExpiryDays"
                  value={formik.values.initExpiryDays}
                  // onChange={formik.handleChange}
                  onChange={(e: any) => {
                    if (!isPositiveNumbersOnly(e, e.target.value)) {
                      return false;
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  className="form-control"
                  min={0}
                />
                {errors.initExpiryDays && touched.initExpiryDays && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.initExpiryDays.toString()}
                      defaultMessage="require"
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="classmodal.Expiry"
                    defaultMessage="Initial Expiry Days"
                  />
                  <small>
                    <FormattedMessage
                      id="classmodal.smalltag1"
                      defaultMessage="(only for W repeater, and new Account)"
                    />
                  </small>
                  *
                </label>
                <input
                  type="text"
                  name="initRepeaterExpiryDays"
                  value={formik.values.initRepeaterExpiryDays}
                  onChange={(e: any) => {
                    if (!isPositiveNumbersOnly(e, e.target.value)) {
                      return false;
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  className="form-control"
                  min={0}
                />
                {errors.initRepeaterExpiryDays &&
                  touched.initRepeaterExpiryDays && (
                    <div className="text-error">
                      <FormattedMessage
                        id={errors.initRepeaterExpiryDays.toString()}
                        defaultMessage="require"
                      />
                    </div>
                  )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="classmodal.Limit"
                    defaultMessage="Class Limit"
                  />
                  *
                </label>
                <input
                  type="text"
                  name="limit"
                  min={0}
                  value={formik.values.limit}
                  onChange={(e: any) => {
                    if (!isPositiveNumbersOnly(e, e.target.value)) {
                      return false;
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  className="form-control"
                />
                {errors.limit && touched.limit && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.limit.toString()}
                      defaultMessage="require"
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="classmodal.Cost"
                    defaultMessage="Cost"
                  />
                  *
                </label>
                <input
                  type="text"
                  name="cost"
                  value={formik.values.cost}
                  onBlur={formik.handleBlur}
                  className="form-control"
                  min={0}
                  onChange={(e: any) => {
                    if (!isPositiveNumbersOnly(e, e.target.value)) {
                      return false;
                    }
                    formik.handleChange(e);
                  }}
                />
                {errors.cost && touched.cost && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.cost.toString()}
                      defaultMessage="require"
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="classmodal.Original"
                    defaultMessage="Original Price"
                  />
                  *
                </label>
                <input
                  type="text"
                  name="originalPrice"
                  value={formik.values.originalPrice}
                  onChange={(e: any) => {
                    if (!isPositiveNumbersOnly(e, e.target.value)) {
                      return false;
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  className="form-control"
                />
                {errors.originalPrice && touched.originalPrice && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.originalPrice.toString()}
                      defaultMessage={errors.originalPrice.toString()}
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="classmodal.Selling"
                    defaultMessage="Selling Price"
                  />
                  *
                </label>
                <input
                  type="text"
                  name="sellingPrice"
                  value={formik.values.sellingPrice}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                />
                {errors.sellingPrice && touched.sellingPrice && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.sellingPrice.toString()}
                      defaultMessage="required"
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="classmodal.Application"
                    defaultMessage="Close Application"
                  />
                </label>
                <input
                  type="date"
                  name="closeApplicationDate"
                  min={editClass ? undefined : disablePastDate()}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.closeApplicationDate}
                  className="form-control"
                />
                {errors.closeApplicationDate &&
                  touched.closeApplicationDate && (
                    <div className="text-error">
                      <FormattedMessage
                        id={errors.closeApplicationDate.toString()}
                        defaultMessage="required"
                      />
                    </div>
                  )}
              </div>
            </Col>
          </Row>

          {editClass != null && (
            <Row>
              <Col>
                <div className="form-group">
                  <label className="control-label">
                    <FormattedMessage
                      id="classmodal.info"
                      defaultMessage="Info"
                    />
                  </label>
                  <textarea
                    rows={3}
                    name="info"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.info}
                    className="form-control"
                  />
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <div className="form-group mt-3">
                <FormattedMessage
                  id="classmodal.SelectFile"
                  defaultMessage="Select File"
                />
                {fileUploadURL == null || formik.values.image_path === "" ? (
                  <FileUpload handleDrop={handleDrop} accept="image/*" />
                ) : (
                  <div>
                    <Row className="align-items-center">
                      <Col className="col-md-9">
                        <img
                          style={{ width: "50%", height: "30%" }}
                          src={fileUploadURL}
                        />
                      </Col>
                      <Col className="col-md-3 text-right">
                        <button
                          className="border-delete-icon delete"
                          onClick={() => {
                            onDeleteImage();
                          }}
                        >
                          <BsTrash />
                        </button>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            </Col>

            <Col>
              <div className="form-group mt-3">
                <FormattedMessage
                  id="classmodal.SelectTermsAndConditionPDF"
                  defaultMessage="Select Terms and Condition PDF"
                />
                {termsConditionURL == null ||
                formik.values.terms_condtion_path === "" ? (
                  <FileUpload
                    handleDrop={handleDropTermsConditions}
                    accept="application/pdf"
                  />
                ) : (
                  <div>
                    <Row className="align-items-center">
                      <Col className="col-md-9">
                        <div>{termsConditionFileName}</div>
                      </Col>
                      <Col className="col-md-3 text-right">
                        <button
                          className="border-delete-icon delete"
                          onClick={() => {
                            onDeleteTermsConditionsPDF();
                          }}
                        >
                          <BsTrash />
                        </button>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div>
            {editClass != null && (
              <label className="control control-outline control-primary control--checkbox font-size-14 d-inline-block mb-0 mr-3 pointer">
                <input
                  type="checkbox"
                  value={"0"}
                  checked={formik?.values?.isActive}
                  onChange={(value: any) => onCheckboxChange(value)}
                />
                <FormattedMessage
                  id="Closestudentregistertion"
                  defaultMessage="Close student registration"
                />
                <div className="control__indicator"></div>
              </label>
            )}
          </div>
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={onModalClose}
          >
            <FormattedMessage id="classmodal.Cancel" defaultMessage="Cancel" />
          </button>
          <button
            type="button"
            className="btn btn-primary"
            style={{ display: "inherit" }}
            onClick={() => formik.handleSubmit()}
            data-dismiss="modal"
          >
            <FormattedMessage id="classmodal.Save" defaultMessage="Save" />
            {isAPILoading && <Loader />}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClassModal;
