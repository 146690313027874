import { AxiosError } from "axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { customFetch } from "../../../util/axios";
import { GemsAdvertisementResponse } from "../types";
import { GetAllGemsAdvertisementsQueryDto } from "../schemas";

export interface GetAllGemsAdvertisementsResponse {
  gemsAdvertisementsCount: number;
  gemsAdvertisementsTotalPages: number;
  gemsAdvertisementsHasNextPage: boolean;
  gemsAdvertisements: GemsAdvertisementResponse[];
}

export const getAllGemsAdvertisements = async (
  getAllGemsAdvertisementsQuery: GetAllGemsAdvertisementsQueryDto,
) => {
  const response = await customFetch.get("/gems-advertisements", {
    params: getAllGemsAdvertisementsQuery,
  });
  return response.data;
};

export const useGetAllGemsAdvertisements = (
  getAllGemsAdvertisementsQuery: GetAllGemsAdvertisementsQueryDto,
) => {
  const queryClient = useQueryClient();

  const query = useQuery<GetAllGemsAdvertisementsResponse, AxiosError | Error>({
    queryKey: ["gems-advertisements", getAllGemsAdvertisementsQuery],
    queryFn: () => getAllGemsAdvertisements(getAllGemsAdvertisementsQuery),
    keepPreviousData: true,
  });

  const pageIndex = getAllGemsAdvertisementsQuery.pageIndex || 0;
  const { data, isPreviousData } = query;

  useEffect(() => {
    if (!isPreviousData && data?.gemsAdvertisementsHasNextPage) {
      const nextParams = {
        ...getAllGemsAdvertisementsQuery,
        pageIndex: pageIndex + 1,
      };

      queryClient.prefetchQuery({
        queryKey: ["gems-advertisements", nextParams],
        queryFn: () => getAllGemsAdvertisements(nextParams),
      });
    }
  }, [
    getAllGemsAdvertisementsQuery,
    data,
    isPreviousData,
    pageIndex,
    queryClient,
  ]);

  return query;
};
