import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import NoDatFound from "../../Components/common/NoDatFound";
import DeletePopup from "../../Components/common/Popups/DeletePopup";
import AddExpertAdvisorModal from "../../Components/ExpertAdvisor/AddExpertAdvisorModal";
import {
  AddEAVersionToAllClass,
  deleteEA,
  getAdminRolesList,
  getClasses,
  getExpertAdvisorList,
  setEACurrentPage,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
// import { VerticalDots } from "../../util/iconStyle";
import { FormattedMessage } from "react-intl";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import Pagination from "../../Components/common/Paginate/Pagination";
import { Dropdown } from "react-bootstrap";
import { AppendedMyComponent } from "../../Components/append-to-body-drop-down";
import EA from "../../assets/img/EA.svg";
import { ClassesIcon } from "../../util/iconStyle";
import { TOOLS, TOOLS_TICKET } from "../../Constants";
import EditToolClassModal from "../../Components/common/Popups/EditToolClassModal";
import { BsSortDown, BsSortUp, FaEdit, FaTrash } from "../../reactIcons";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

const ExpertAdvisor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [utilityModalShow, setExpertAdvisorModalShow] = useState(false);
  const [edtExpertAdvisor, setEdtExpertAdvisor] = useState(null);
  const [delModalShow, setDelModalShow] = useState({ modal: false, id: 0 });
  const [addToAllClass, setAddToAllClass] = useState({ modal: false, id: 0 });

  const expertAdvisorsList: any = useSelector(
    (state: Store) => state.expertAdvisorReducer.expertAdvisorsList,
  ) as any;
  const rolesLists: any = useSelector(
    (state: Store) => state.adminRolesReducer.adminRoleList,
  ) as any;

  const isLoading = useSelector(
    (state: Store) => state.expertAdvisorReducer.isLoading,
  );
  const [pageNumber, setPageNumber] = useState<any>();
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const totalRecord = useSelector(
    (state: Store) => state.expertAdvisorReducer.totalRecord,
  ) as any;
  const currentPage = useSelector(
    (state: Store) => state.expertAdvisorReducer.currentPage,
  );

  const classes = useSelector(
    (state: Store) => state.classReducer.classes || [],
  );

  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [rolesList, setRoleList] = useState([]);

  useEffect(() => {
    const r: any = [];
    rolesLists.map((i: any) => {
      if (i.isEARole) {
        r.push(i);
      }
    });
    setRoleList(r);
  }, [rolesLists]);

  // useEffect(() => {
  //   // dispatch(getEAList(false, { by: sortBy, order: sortOrder }))
  //   // dispatch(getEAUnauthorizedList(false))
  //   // dispatch(getActivityList(false, { by: sortBy, order: sortOrder }, pageNumber));
  //   dispatch(
  //     getExpertAdvisorList(false, pageNumber, { by: sortBy, order: sortOrder }, pageLimit)
  //   );
  // }, [sortOrder, sortBy]);

  const setSorting = (prop: any) => {
    if (sortBy === prop) {
      setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
    } else {
      setSortBy(prop);
      setSortOrder("ASC");
    }
  };

  const onPageChange = (pageNum: number) => {
    localStorage.setItem("pageNumber", pageNum.toString());
    localStorage.setItem("pageLimit", pageLimit);
    setPageNumber(pageNum);
    dispatch(setEACurrentPage(pageNum));
  };

  useEffect(() => {
    dispatch(setEACurrentPage(1));
  }, []);

  // useEffect(() => {
  //   setPageNumber(currentPage);
  // }, [currentPage]);

  useEffect(() => {
    if (pageNumber && pageLimit) {
      dispatch(
        getExpertAdvisorList(
          false,
          pageNumber,
          { by: sortBy, order: sortOrder },
          pageLimit,
        ),
      );
    }
  }, [pageNumber, pageLimit, sortOrder, sortBy]);

  useEffect(() => {
    dispatch(getAdminRolesList(false));
    dispatch(getClasses(false, 0, { order: "DESC", by: "createdAt" }));
    const storedPageNumber = localStorage.getItem("pageNumber");
    const storedPageLimit = localStorage.getItem("pageLimit");
    if (storedPageNumber && storedPageLimit) {
      setPageNumber(storedPageNumber);
      setPageLimit(storedPageLimit);
    } else {
      setPageNumber(1);
      setPageLimit(10);
    }
  }, []);

  const editExpertAdvisorSelected = (utilityDetail: any) => {
    setEdtExpertAdvisor(utilityDetail);
    setExpertAdvisorModalShow(true);
  };

  const viewExpertAdvisorDetail = (id: any) => {
    localStorage.setItem("pageNumber", pageNumber);
    localStorage.setItem("pageLimit", pageLimit);
    navigate("/expert-advisors/" + id);
  };
  const deleteExp = () => {
    dispatch(deleteEA(true, delModalShow.id));
    setDelModalShow({ modal: false, id: 0 });
  };

  useEffect(() => {
    if (isLoading && addToAllClass.modal) {
      setAddToAllClass({ modal: false, id: 0 });
    }
  }, [isLoading]);

  const addAllClass = (id: any) => {
    //jdsk
    dispatch(
      AddEAVersionToAllClass(true, {
        tool: TOOLS.EXPERT_ADVISOR,
        toolId: id,
        class_group: [],
        toAll: true,
      }),
    );
  };

  return (
    <>
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                <FormattedMessage
                  id="Admin.Expert.Advisors"
                  defaultMessage="Expert Advisors "
                />
              </h1>
            </div>
            <div className="m-l-10 common-right-button">
              <a
                href="#"
                data-toggle="modal"
                data-target="#AddCourse"
                type="button"
                onClick={() => {
                  setExpertAdvisorModalShow(true);
                  setEdtExpertAdvisor(null);
                }}
                className="btn btn-primary"
              >
                <FormattedMessage
                  id="Expert.Add.ExpertAdvisor"
                  defaultMessage=" Add ExpertAdvisor"
                />
              </a>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          {isLoading && <CenterLoader />}
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive">
                {expertAdvisorsList && expertAdvisorsList.length ? (
                  <>
                    {" "}
                    <table className="table table-hover m-0">
                      <thead>
                        <tr>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("id")}
                          >
                            <FormattedMessage
                              id="table.head.ID"
                              defaultMessage="ID"
                            />
                            {sortBy === "id" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("name")}
                          >
                            <FormattedMessage
                              id="table.head.Name"
                              defaultMessage="Name"
                            />
                            {sortBy === "name" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("cost")}
                          >
                            <FormattedMessage
                              id="table.head.Cost"
                              defaultMessage="Cost"
                            />
                            {sortBy === "cost" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("originalPrice")}
                          >
                            <FormattedMessage
                              id="table.head.originalPrice"
                              defaultMessage="Original Price"
                            />
                            {sortBy === "originalPrice" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("sellingPrice")}
                          >
                            <FormattedMessage
                              id="table.head.sellingPrice"
                              defaultMessage="Selling Price"
                            />
                            {sortBy === "sellingPrice" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th>
                            <FormattedMessage
                              id="table.head.Image"
                              defaultMessage="Image"
                            />
                          </th>
                          <th>
                            <FormattedMessage
                              id="table.head.AddAllClass"
                              defaultMessage="Image"
                            />
                          </th>
                          <th className="table-field-status">
                            <FormattedMessage
                              id="table.head.Actions"
                              defaultMessage="Action"
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* <?<5;$i++) { ?> */}
                        {Object.keys(expertAdvisorsList || {}).length &&
                          expertAdvisorsList.map((i: any) => (
                            <tr>
                              <td onClick={() => viewExpertAdvisorDetail(i.id)}>
                                {i.id}
                              </td>
                              <td onClick={() => viewExpertAdvisorDetail(i.id)}>
                                {i.name}
                              </td>
                              <td onClick={() => viewExpertAdvisorDetail(i.id)}>
                                {i.cost}
                              </td>
                              <td onClick={() => viewExpertAdvisorDetail(i.id)}>
                                {i.originalPrice}
                              </td>
                              <td onClick={() => viewExpertAdvisorDetail(i.id)}>
                                {i.sellingPrice}
                              </td>
                              <td onClick={() => viewExpertAdvisorDetail(i.id)}>
                                <img
                                  className="img-thumbanail"
                                  src={i?.image_path || EA}
                                />
                              </td>
                              <td style={{ cursor: "pointer" }}>
                                <button
                                  style={{
                                    background: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    setAddToAllClass({ modal: true, id: i.id })
                                  }
                                >
                                  <ClassesIcon />{" "}
                                </button>
                              </td>
                              <td className="table-field-status">
                                <Dropdown className="btn-group">
                                  <Dropdown.Toggle
                                    id="dropdown-basic"
                                    className="btn btn-sm btn-icon-only"
                                  >
                                    <i className="icon dripicons-dots-3 zmdi-hc-fw"></i>
                                  </Dropdown.Toggle>
                                  <AppendedMyComponent>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        href="#"
                                        onClick={() =>
                                          editExpertAdvisorSelected(i)
                                        }
                                      >
                                        <i className="text-accent-custom">
                                          <FaEdit />{" "}
                                        </i>
                                        <FormattedMessage
                                          id="page.Edit"
                                          defaultMessage="Edit"
                                        />
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        href="#"
                                        onClick={() =>
                                          setDelModalShow({
                                            modal: true,
                                            id: i.id,
                                          })
                                        }
                                      >
                                        <i className="fa-fw text-accent-custom">
                                          <FaTrash />{" "}
                                        </i>
                                        <FormattedMessage
                                          id="page.Delete"
                                          defaultMessage="Delete"
                                        />
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </AppendedMyComponent>
                                </Dropdown>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <p>{!isLoading && <NoDatFound />}</p>
                )}
              </div>
              {totalRecord > 10 && expertAdvisorsList.length && (
                <>
                  <FormControl className="pagelimit">
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      <FormattedMessage
                        id="page_size"
                        defaultMessage="Page Size"
                      />
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={pageLimit}
                      onChange={(e: any) => {
                        setPageLimit(e.target.value);
                        localStorage.setItem("pageLimit", e.target.value);
                        localStorage.setItem("pageNumber", pageNumber);
                        setPageNumber(1);
                      }}
                    >
                      <FormControlLabel
                        value={10}
                        control={<Radio />}
                        label="10"
                      />
                      <FormControlLabel
                        value={20}
                        control={<Radio />}
                        label="20"
                      />
                      <FormControlLabel
                        value={50}
                        control={<Radio />}
                        label="50"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Pagination
                    itemsPerPage={pageLimit}
                    totalPages={totalRecord}
                    onPageChage={onPageChange}
                    page={pageNumber - 1}
                  />
                </>
              )}
            </div>
          </div>
        </section>
      </div>
      {delModalShow.modal && (
        <DeletePopup
          onClose={() => setDelModalShow({ modal: false, id: 0 })}
          onDeleteClick={deleteExp}
          open={delModalShow.modal}
        />
      )}
      {utilityModalShow && (
        <AddExpertAdvisorModal
          onModalClose={() => setExpertAdvisorModalShow(false)}
          editExpertAdvisor={edtExpertAdvisor}
          rolesList={rolesList}
        />
      )}

      {addToAllClass.modal && (
        <EditToolClassModal
          onModalClose={() => setAddToAllClass({ modal: false, id: 0 })}
          //  onDeleteClick={() => graduateStudent(grdClass.id)}
          // open={grdClass.modal}
          //  isAPILoading={isLoading}
          selectedClass={[]}
          classList={classes}
          toolId={addToAllClass.id}
          TOOL={TOOLS_TICKET.EXPERT_ADVISOR}
        />
      )}
    </>
  );
};

export default ExpertAdvisor;
