import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Tree from "react-d3-tree";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import Select from "react-select";
import { dispatch } from "../../redux";
import {
  getStudentByClassList,
  getUserRefaralList as getUserReferralList,
} from "../../redux/action";
import { Switch } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useCenteredTree } from "./customTree";
import CustomTreeNode, {
  CustomStudentData,
  generateCustomNodeData,
} from "./CustomTreeNode";
import { RawNodeDatum } from "react-d3-tree/lib/types/types/common";

export interface CustomReferralData {
  id: number;
  referer: { id: number };
  refree: CustomStudentData;
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    backgroundColor: "black",
  },
  treeWrapper: {
    width: "95%",
    height: "600px",
  },
}));

const ReferralTree = () => {
  const classNames = useStyles();
  // const [translate, containerRef] = useCenteredTree();
  const containerElement = document.getElementById("containerTree");
  const clientHeight = containerElement ? containerElement.clientHeight / 2 : 0;
  const clientWidth = containerElement ? containerElement.clientWidth / 2 : 0;
  const [translate, setTranslate] = useState<any>();

  const nodeSize = { x: 250, y: 250 };
  const foreignObjectProps = {
    width: nodeSize.x / 2,
    height: nodeSize.y / 2,
    x: 16,
  };

  const classes = useSelector(
    (state: Store) => state.classReducer.classes,
  ) as any;

  const classStudentsLists = useSelector(
    (state: Store) => state.studentReducer.classStudentsList,
  );

  const referralList = useSelector(
    (state: Store) => state.membershipReducer.referalList,
  ) as CustomReferralData[];

  const [orientationV, setOrientationV] = useState(false);

  const [selectedClassReferralStudent, setSelectedReferralClassStudent] =
    useState<CustomStudentData | null>(null);
  const [selectedReferralClass, setSelectedReferralClass] = useState(null);
  const [classStudentsList, setClassStudentsList] = useState<any>([]);

  const [treeRoot, setTreeRoot] = useState<RawNodeDatum | null>(null);

  useEffect(() => {
    setClassStudentsList(classStudentsLists);
  }, [classStudentsLists]);

  const onClassReferralChange = (inputClass: any) => {
    dispatch(getStudentByClassList(false, inputClass.id, 1));
    setSelectedReferralClass(inputClass);
    setSelectedReferralClassStudent(null);
  };

  const onClassStudentChange = (referrer: any) => {
    dispatch(getUserReferralList(false, referrer.id));
    setTreeRoot(null);
  };

  const updateTreeRoot = () => {
    let treeRoot = null;
    if (selectedClassReferralStudent) {
      const selectedStudentNodeData = generateCustomNodeData(
        selectedClassReferralStudent,
      );
      selectedStudentNodeData.children = referralList.map((referral) =>
        generateCustomNodeData(referral.refree),
      );

      treeRoot = selectedStudentNodeData;
      if (selectedClassReferralStudent.referer) {
        treeRoot = generateCustomNodeData(selectedClassReferralStudent.referer);
        if (!treeRoot.children) {
          treeRoot.children = [];
        }
        treeRoot.children.push(selectedStudentNodeData);
      }
    }

    setTreeRoot(treeRoot);
  };

  useEffect(() => {
    updateTreeRoot();
  }, [referralList]);

  return (
    <section className="page-content container-fluid">
      <Row>
        <Col>
          <div className="form-group">
            <label className="control-label">
              <FormattedMessage
                id="head.SelectClass"
                defaultMessage="Select Class"
              />
            </label>
            <Select
              name="refralClass"
              value={selectedReferralClass}
              onChange={(referralClass: any) => {
                onClassReferralChange(referralClass);
                setTreeRoot(null);
                setOrientationV(false);
              }}
              options={classes}
              getOptionLabel={(referralClass: any) => referralClass.name}
              getOptionValue={(referralClass: any) => referralClass.id}
              className="custom-select-dropdown"
              classNamePrefix="react-select-dropdown"
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
          </div>
        </Col>
        <Col>
          <div className="form-group">
            <label className="control-label">
              <FormattedMessage
                id="head.SelectStudents"
                defaultMessage="Select Students"
              />
            </label>
            <Select
              name="referer"
              value={selectedClassReferralStudent}
              onChange={(referrer) => {
                setSelectedReferralClassStudent(referrer);
                onClassStudentChange(referrer);
                setTreeRoot(null);
                setOrientationV(false);
                setTranslate({ x: 50, y: clientHeight });
              }}
              options={classStudentsList}
              getOptionLabel={(referrer: any) =>
                `(${referrer.id}) ${referrer.englishFirstName} ${referrer.englishLastName} (${referrer.preferredNickName})`
              }
              getOptionValue={(referrer: any) => referrer.id}
              className="custom-select-dropdown multi"
              classNamePrefix="react-select-dropdown"
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <label>
            <FormattedMessage id="Horizontal" defaultMessage="Horizontal" />{" "}
            <Switch
              onChange={() => {
                setOrientationV(!orientationV);
                if (!orientationV) {
                  setTranslate({ x: clientWidth, y: 50 });
                } else {
                  setTranslate({ x: 50, y: clientHeight });
                }
              }}
              checked={orientationV}
              value={orientationV}
            />{" "}
            <FormattedMessage id="Vertical" defaultMessage="Vertical" />
          </label>
        </Col>
        <Col>
          {selectedClassReferralStudent !== null &&
            Object.keys(selectedClassReferralStudent).length > 0 && (
              <div style={{ textAlign: "end" }}>
                <div>
                  <p style={{ margin: "0px" }}>
                    <FormattedMessage
                      id="member.Id"
                      defaultMessage="Member Id"
                    />
                    : {selectedClassReferralStudent?.id}
                  </p>
                  <p style={{ margin: "0px" }}>
                    <FormattedMessage id="member.Name" defaultMessage="Name" />:{" "}
                    {selectedClassReferralStudent?.englishFirstName +
                      " " +
                      selectedClassReferralStudent?.englishLastName}
                  </p>
                  <p style={{ margin: "0px" }}>
                    <FormattedMessage
                      id="member.Class"
                      defaultMessage="Student Class"
                    />
                    :{" "}
                    {selectedClassReferralStudent?.userClass
                      ? selectedClassReferralStudent?.userClass.join(", ")
                      : "-"}
                  </p>
                </div>
              </div>
            )}
        </Col>
      </Row>
      <div
        // ref={containerRef}
        id="containerTree"
        style={{ minHeight: "200px" }}
        className={classNames.treeWrapper}
      >
        {treeRoot !== null && (
          <Tree
            nodeSize={nodeSize}
            data={treeRoot}
            translate={translate}
            orientation={!orientationV ? "horizontal" : "vertical"}
            renderCustomNodeElement={(rd3tProps) => (
              <CustomTreeNode
                {...rd3tProps}
                foreignObjectProps={foreignObjectProps}
              />
            )}
          />
        )}
      </div>
    </section>
  );
};

export default ReferralTree;
