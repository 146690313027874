import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import UserPrintModal from "../../Components/User/UserPrintModal";
import UserVpsMachineModal from "../../Components/User/UserVpsMachineModal";
import { dispatch } from "../../redux";
import { getExpressWayMemberDetail, makeAdminInit } from "../../redux/action";
import { formatDateMM } from "../../util/utils";
import Dropdown from "react-bootstrap/Dropdown";
import { AppendedMyComponent } from "../../Components/append-to-body-drop-down";
import AccountLimitSetting from "../../Components/Account/AccountLimitSetting";
import MakeAdminModal from "../../Components/common/Popups/MakeAdminModal";
import { toast } from "react-toastify";

const ActiveUsers = ({ users, activeTab, status }: any) => {
  const navigate = useNavigate();
  const [show, setShow] = useState({ modal: false, id: 0 });
  const [showVps, setShowVps] = useState({ modal: false, id: 0 });
  const [makeAdminModal, setMakeAdminModal] = useState({ modal: false, id: 0 });
  const [limitAccount, setLimitAccount] = useState({
    modal: false,
    id: 0,
    data: null,
  });

  const viewDetail = (id: any) => {
    dispatch(getExpressWayMemberDetail(false, id));
    localStorage.setItem("userTab", activeTab);
    localStorage.setItem("userStatus", status);
    navigate("/users/" + id);
  };

  const ApproveAdmin = (id: any) => {
    // dispatch(makeAdminInit(id));
    toast.success("Successfully approve");
    setMakeAdminModal({ modal: false, id: 0 });
  };

  const getClassName = (arr: any) => {
    let ar: any = "";
    if (arr.length != undefined && arr.length > 0) {
      arr.map((i: any) => {
        ar += `,  ${i?.name}`;
      });
      ar = ar.replace(",", "");
      return ar;
    }
  };

  useEffect(() => {
    return () => {
      setShow({ modal: false, id: 0 });
    };
  }, []);

  return (
    <div className="">
      <section className="">
        <div className="table-responsive">
          <table className="table table-hover m-0">
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="table.head.MID" defaultMessage="MID" />
                </th>
                <th>
                  <FormattedMessage
                    id="table.head.Name"
                    defaultMessage="Name"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="table.head.Nickname"
                    defaultMessage="Nickname"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="table.head.Email"
                    defaultMessage="Email"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="table.head.MobileNumber"
                    defaultMessage="Mobile Number"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="table.head.CurrentPoint"
                    defaultMessage="Mobile Number"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="table.head.Class"
                    defaultMessage="Class"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="table.head.PermanentEA"
                    defaultMessage="Permanent EA"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="table.head.ExpiryDate"
                    defaultMessage="Register Date"
                  />
                </th>
                <th className="text-center">
                  <FormattedMessage
                    id="table.head.Actions"
                    defaultMessage="Print"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {users.length &&
                users.map((i: any) => (
                  <tr>
                    <td onClick={() => viewDetail(i.id)} className="pointer">
                      {i.id}
                    </td>
                    <td onClick={() => viewDetail(i.id)} className="pointer">
                      {i.englishFirstName}
                      {i.englishNickName ? ` ${i.englishNickName} ` : " "}
                      {i.englishLastName}
                    </td>
                    <td onClick={() => viewDetail(i.id)} className="pointer">
                      {i?.preferredNickName}
                    </td>
                    <td onClick={() => viewDetail(i.id)} className="pointer">
                      {i.email}
                    </td>
                    <td onClick={() => viewDetail(i.id)} className="pointer">
                      +{i?.dialCode || ""} {i.phoneNumber}
                    </td>
                    <td onClick={() => viewDetail(i.id)} className="pointer">
                      {i.currentPoint}
                    </td>
                    <td onClick={() => viewDetail(i.id)} className="pointer">
                      {Object.entries(i?.approvedClassList || []).length > 0
                        ? getClassName(i?.approvedClassList)
                        : ""}
                    </td>
                    <td onClick={() => viewDetail(i.id)} className="pointer">
                      {i?.permEaName?.EA_Name !== null
                        ? i?.permEaName?.EA_Name
                        : "-"}
                    </td>
                    <td onClick={() => viewDetail(i.id)} className="pointer">
                      {formatDateMM(i.expiryDate, "YYYY/MM/DD")}
                    </td>
                    <td className="table-field-actions">
                      <Dropdown className="btn-group">
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="btn btn-sm btn-icon-only"
                        >
                          <i className="icon dripicons-dots-3 zmdi-hc-fw"></i>
                        </Dropdown.Toggle>
                        <AppendedMyComponent>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              href="#"
                              onClick={() =>
                                setShow({ modal: true, id: parseInt(i.id) })
                              }
                            >
                              <i className="fa fa-print fa-fw text-accent-custom"></i>
                              <FormattedMessage
                                id="table.head.Print"
                                defaultMessage="Print"
                              />
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#"
                              onClick={() =>
                                setLimitAccount({
                                  modal: true,
                                  id: parseInt(i.id),
                                  data: i,
                                })
                              }
                            >
                              <i className="fa fa-cog fa-fw text-accent-custom"></i>
                              <FormattedMessage
                                id="page.Accounts"
                                defaultMessage="Graduate"
                              />
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </AppendedMyComponent>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </section>
      {show.modal && (
        <UserPrintModal
          open={show.modal}
          onClose={() => setShow({ modal: false, id: 0 })}
          id={show.id}
        />
      )}

      {showVps.modal && (
        <UserVpsMachineModal
          open={showVps.modal}
          onClose={() => setShowVps({ modal: false, id: 0 })}
          id={showVps.id}
        />
      )}

      {limitAccount.modal && (
        <AccountLimitSetting
          open={limitAccount.modal}
          onClose={() => setLimitAccount({ modal: false, id: 0, data: null })}
          id={limitAccount.id}
          data={limitAccount.data}
        />
      )}

      {makeAdminModal && (
        <MakeAdminModal
          onClose={() => setMakeAdminModal({ modal: false, id: 0 })}
          onApproveClick={() => ApproveAdmin(makeAdminModal.id)}
          open={makeAdminModal.modal}
        />
      )}
    </div>
  );
};

export default ActiveUsers;
