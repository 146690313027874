import { Switch } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
import UserSelectionByClass from "../../Components/Selection/UserSelectionByClass";
import { dispatch } from "../../redux";
import { GrantTools, getTicketToolList } from "../../redux/action";
import { Store } from "../../redux/Actions";
import { ToolTypeOption } from "../../util/constants/UserGroup";
import { AddGrantToolSchema } from "../../util/validationSchema";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "../../Components/common/Loader";
import { TOOLS_TICKET } from "../../Constants";
import { union } from "lodash";
import Loading from "../../Components/common/Loading";

const GrantTool = ({ setActiveTab }: any) => {
  const userSelectionRef = useRef<any>();

  const classes = useSelector((state: Store) => state.classReducer.classes);
  const [selectedTool, setselectedTool] = useState({
    id: 0,
    group: "Expert Advisor",
  });

  const [allTools, setAllTools] = useState<any>();
  const { isLoading, isToolGrantsSuccess } = useSelector(
    (state: Store) => state.grantReducer,
  );
  const tools = useSelector(
    (state: Store) => state.ticketReducer.toolList,
  ) as any;

  const local = useSelector((state: Store) => state.userDataReducer.local);

  const [buttonClick, setButtonClick] = useState({});

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      grantTime: 0,
      permanent: false,
      tool: 0,
      toolId: 0,
      remarks: "",
      usersId: [],
      additionalUserIds: "",
      parsedAdditionalUserIds: [],
    },
    validationSchema: AddGrantToolSchema,
    onSubmit: async (values) => {
      if (!buttonClick) {
        values.remarks = values.remarks.trim();
        values.usersId = union(values.usersId, values.parsedAdditionalUserIds);
        const { tool, toolId, permanent, grantTime, remarks, usersId } = values;
        dispatch(
          GrantTools(true, {
            tool,
            toolId,
            permanent,
            grantTime,
            remarks,
            usersId,
          }),
        );
        setButtonClick(true);
      }
    },
  });

  useEffect(() => {
    const t: any = [];
    tools.map((i: any) => {
      if (i.type != TOOLS_TICKET.LIBRARIES && i.type != 6) {
        //6 For GemsFx
        t.push(i);
      }
    });
    setAllTools(t);
  }, [tools]);

  const onToolTypeChange = (option: any) => {
    formik.setFieldValue("tool", option.id);
    setselectedTool(option);
    dispatch(getTicketToolList(false, parseInt(option.id)));
  };
  const { errors, touched, resetForm, setFieldError, setFieldValue } = formik;

  useEffect(() => {
    dispatch(getTicketToolList(false, parseInt("0")));

    if (!isLoading && buttonClick) {
      resetForm();
      setselectedTool({
        id: 0,
        group: "Expert Advisor",
      });
      userSelectionRef?.current();
      setButtonClick(false);
      if (isToolGrantsSuccess) {
        setActiveTab(0);
      }
    }
  }, [isLoading, isToolGrantsSuccess]);

  const onFormReset = (e: any) => {
    e.preventDefault();
    setselectedTool({
      id: 0,
      group: "Expert Advisor",
    });
    resetForm();
    userSelectionRef?.current();
  };

  const handleParsedAdditionalUserIds = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const s = e.target.value.replace(/\n+$/, "");
    if (s === "") {
      setFieldError("parsedAdditionalUserIds", undefined);
      setFieldValue("parsedAdditionalUserIds", []);
      return;
    }

    const parsedAdditionalUserIds = s.split("\n").map((c) => parseInt(c));
    if (parsedAdditionalUserIds.some((d) => isNaN(d))) {
      setFieldError("parsedAdditionalUserIds", "Check you input format");
      setFieldValue("parsedAdditionalUserIds", []);
    } else {
      setFieldError("parsedAdditionalUserIds", undefined);
      setFieldValue("parsedAdditionalUserIds", parsedAdditionalUserIds);
    }
  };

  return (
    <form
      onSubmit={(e: any) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <div className="card-body">
        {isLoading && <Loader />}
        <Row>
          <Col xl={8}>
            <Row>
              <Col md={12}>
                <UserSelectionByClass
                  refs={userSelectionRef}
                  setFinalUsers={(users: any) =>
                    formik.setFieldValue("usersId", users)
                  }
                  users={formik.values.usersId}
                />
                {errors.usersId && touched.usersId && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.usersId.toString()}
                      defaultMessage="At least one user should be selected"
                    />
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="form-group">
                  <label className="control-label">Additional User Ids</label>
                  <Row>
                    <Col>
                      <textarea
                        className="form-control"
                        name="additionalUserIds"
                        id="additionalUserIds"
                        style={{
                          width: "100%",
                          height: "100%",
                          boxSizing: "border-box",
                        }}
                        value={formik.values.additionalUserIds}
                        onChange={(e) => {
                          formik.handleChange(e);
                          handleParsedAdditionalUserIds(e);
                        }}
                        onBlur={formik.handleBlur}
                        rows={8}
                      />
                      {touched.additionalUserIds &&
                        errors.parsedAdditionalUserIds && (
                          <div className="text-error">
                            <FormattedMessage
                              id={errors.parsedAdditionalUserIds.toString()}
                              defaultMessage="At least one user should be selected"
                            />
                          </div>
                        )}
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="control-label">
                    Result Of Parsing (
                    {`${formik.values.parsedAdditionalUserIds.length} ids are parsed`}
                    )
                  </label>
                  <textarea
                    className="form-control"
                    name="additionalUserIds"
                    id="additionalUserIds"
                    style={{
                      width: "100%",
                      height: "100%",
                      boxSizing: "border-box",
                    }}
                    value={formik.values.parsedAdditionalUserIds.join("\n")}
                    disabled={true}
                    rows={8}
                  ></textarea>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="control-label">
                    <FormattedMessage
                      id="Rents.rent.Tool"
                      defaultMessage="Tool Id"
                    />
                  </label>
                  <Select
                    name="ToolId"
                    onChange={(option: any) => {
                      formik.setFieldValue("toolId", option.id);
                      formik.setFieldValue("tool", option.type);
                    }}
                    onBlur={() => formik.setFieldTouched("toolId", true)}
                    options={allTools}
                    getOptionLabel={(option: any) => option.name}
                    getOptionValue={(option: any) => option.id}
                    className="custom-select-dropdown"
                    classNamePrefix="react-select-dropdown"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                  {errors.toolId && touched.toolId && (
                    <div className="text-error">
                      <FormattedMessage
                        id={errors.toolId.toString()}
                        defaultMessage="This field is required"
                      />
                    </div>
                  )}
                </div>
              </Col>
              <Col md={6}>
                {!formik.values.permanent && (
                  <div className="form-group">
                    <label className="control-label">
                      <FormattedMessage
                        id="Admin.GrantTool.GTime"
                        defaultMessage="Grant Time"
                      />{" "}
                    </label>
                    <input
                      type="date"
                      name="grantTime"
                      value={formik.values.grantTime}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="form-control form-control-md"
                      id="colFormLabelSm"
                      // placeholder={useIntl().formatMessage({
                      //   id: "Classes.Details",
                      //   defaultMessage: "Detail",
                      // })}
                    />
                    {errors.grantTime && touched.grantTime && (
                      <div className="text-error">
                        <FormattedMessage
                          id={errors.grantTime.toString()}
                          defaultMessage="This field is required"
                        />
                      </div>
                    )}
                  </div>
                )}
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="control-label">
                    <FormattedMessage
                      id="Admin.GrantTool.Permanent"
                      defaultMessage="Permanent"
                    />{" "}
                  </label>
                  <div>
                    <Switch
                      checked={formik.values.permanent}
                      onChange={() => {
                        formik.setFieldValue(
                          "permanent",
                          !formik.values.permanent,
                        );
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="control-label">
                    <FormattedMessage
                      id="table.head.Remarks"
                      defaultMessage="Remarks"
                    />
                  </label>
                  <input
                    type="text"
                    name="remarks"
                    value={formik.values.remarks}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-control"
                  />
                  {errors.remarks && touched.remarks && (
                    <div className="text-error">
                      <FormattedMessage
                        id={errors.remarks.toString()}
                        defaultMessage="This field is required"
                      />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="card-footer bg-light text-right">
        <button
          className="btn btn-secondary mr-3"
          onClick={(e: any) => onFormReset(e)}
        >
          <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
        </button>

        <Button type="submit">
          {buttonClick ? (
            <Loading />
          ) : (
            <FormattedMessage
              id="Admin.GrantTool.Submit"
              defaultMessage="Submit"
            />
          )}
        </Button>
      </div>
    </form>
  );
};

export default GrantTool;
