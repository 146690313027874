/* eslint-disable no-case-declarations */
import {
  ADD_CLASS_MEMBERSHIP,
  ADD_REFERAL,
  ADD_USER_MEMBERSHIP,
  GET_REFERAL,
  SET_ALTERNAITVE_NAMES,
  SET_MANUAL_PASSWORD_LIST,
} from "../constants/action-types";
import { MembershipData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";
// import { Posts } from "../Actions";

const initialState = {
  progress: false,
  isLoading: false,
  onSaveLoading: false,
  referalList: [],
  totalRecord: 0,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: MembershipData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case ADD_CLASS_MEMBERSHIP.ADD_CLASS_MEMBERSHIP_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case ADD_CLASS_MEMBERSHIP.ADD_CLASS_MEMBERSHIP_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_CLASS_MEMBERSHIP.ADD_CLASS_MEMBERSHIP_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_USER_MEMBERSHIP.ADD_USER_MEMBERSHIP_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case ADD_USER_MEMBERSHIP.ADD_USER_MEMBERSHIP_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_USER_MEMBERSHIP.ADD_USER_MEMBERSHIP_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_REFERAL.GET_REFERAL_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_REFERAL.GET_REFERAL_SUCCESS:
        return {
          ...state,
          referalList: action.payload,
          isLoading: false,
        };

      case GET_REFERAL.GET_REFERAL_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case SET_ALTERNAITVE_NAMES.SET_ALTERNAITVE_NAMES_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case SET_ALTERNAITVE_NAMES.SET_ALTERNAITVE_NAMES_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case SET_ALTERNAITVE_NAMES.SET_ALTERNAITVE_NAMES_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case SET_MANUAL_PASSWORD_LIST.SET_MANUAL_PASSWORD_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case SET_MANUAL_PASSWORD_LIST.SET_MANUAL_PASSWORD_LIST_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case SET_MANUAL_PASSWORD_LIST.SET_MANUAL_PASSWORD_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_REFERAL.ADD_REFERAL_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case ADD_REFERAL.ADD_REFERAL_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_REFERAL.ADD_REFERAL_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      default:
        return state;
    }
  },
);

export default reducer;
