/* eslint-disable no-case-declarations */
import {
  GET_SCRIPTS_LIST,
  ADD_SCRIPTS_LIST,
  EDIT_SCRIPTS_LIST,
  GET_SCRIPTS_DETAILS,
  GET_SCRIPT_VERSION_LIST,
  ADD_SCRIPT_VERSION,
  EDIT_SCRIPT_VERSION,
  DELETE_SCRIPT_VERSION,
  DELETE_SCRIPT,
  SET_PAGE,
} from "../constants/action-types";
import { ScriptsData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";
// import { Posts } from "../Actions";

const initialState = {
  progress: false,
  isLoading: false,
  onSaveLoading: false,
  scriptsList: {},
  scriptsDetails: {},
  scriptVersion: {},
  totalRecord: 0,
  currentPage: 1,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: ScriptsData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case SET_PAGE:
        return {
          ...state,
          currentPage: action.payload,
        };

      case GET_SCRIPTS_LIST.GET_SCRIPTS_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_SCRIPTS_LIST.GET_SCRIPTS_LIST_SUCCESS:
        const allPost: any = action.payload; // [{ id: 2, body: "Abcd", title: "title", userId: 3 }, { id: 2, body: "Abcd", title: "title", userId: 3 }];
        return {
          ...state,
          scriptsList: allPost.data,
          totalRecord: allPost.totalCount,
          isLoading: false,
        };

      case GET_SCRIPTS_LIST.GET_SCRIPTS_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_SCRIPTS_LIST.ADD_SCRIPTS_LIST_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case ADD_SCRIPTS_LIST.ADD_SCRIPTS_LIST_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case ADD_SCRIPTS_LIST.ADD_SCRIPTS_LIST_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case EDIT_SCRIPTS_LIST.EDIT_SCRIPTS_LIST_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case EDIT_SCRIPTS_LIST.EDIT_SCRIPTS_LIST_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case EDIT_SCRIPTS_LIST.EDIT_SCRIPTS_LIST_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case GET_SCRIPTS_DETAILS.GET_SCRIPTS_DETAILS_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_SCRIPTS_DETAILS.GET_SCRIPTS_DETAILS_SUCCESS:
        return {
          ...state,
          scriptsDetails: action.payload,
          isLoading: false,
        };

      case GET_SCRIPTS_DETAILS.GET_SCRIPTS_DETAILS_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_SCRIPT_VERSION_LIST.GET_SCRIPT_VERSION_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_SCRIPT_VERSION_LIST.GET_SCRIPT_VERSION_LIST_SUCCESS:
        const scriptVersion: any = action.payload;
        return {
          ...state,
          scriptVersion: scriptVersion.data, // action.payload,
          totalRecord: scriptVersion.totalCount || 1, // action.payload,
          isLoading: false,
        };

      case GET_SCRIPT_VERSION_LIST.GET_SCRIPT_VERSION_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_SCRIPT_VERSION.ADD_SCRIPT_VERSION_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case ADD_SCRIPT_VERSION.ADD_SCRIPT_VERSION_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case ADD_SCRIPT_VERSION.ADD_SCRIPT_VERSION_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case EDIT_SCRIPT_VERSION.EDIT_SCRIPT_VERSION_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case EDIT_SCRIPT_VERSION.EDIT_SCRIPT_VERSION_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case EDIT_SCRIPT_VERSION.EDIT_SCRIPT_VERSION_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case DELETE_SCRIPT_VERSION.DELETE_SCRIPT_VERSION_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case DELETE_SCRIPT_VERSION.DELETE_SCRIPT_VERSION_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case DELETE_SCRIPT_VERSION.DELETE_SCRIPT_VERSION_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case DELETE_SCRIPT.DELETE_SCRIPT_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case DELETE_SCRIPT.DELETE_SCRIPT_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case DELETE_SCRIPT.DELETE_SCRIPT_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      default:
        return state;
    }
  },
);

export default reducer;
