import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
// import Dropzone from "react-dropzone";

import { useSelector } from "react-redux";
import {
  // addActivity,
  addActivityPoints,
  getActivityPoints,
  // addAnnouncement,
  getAdminRolesList,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
// import { convertToRaw, EditorState, convertFromHTML } from "draft-js";
import Loader from "../common/Loader";
// import EditorContainer from "../RichTextEditor/Editor";
// import { _UploadMedia } from "../../service/MediaUpload/classes.service";
// import FileUpload from "../common/MediaUpload/FileUpload";
// import draftToHtml from "draftjs-to-html";
import Select from "react-select";
import { dispatch } from "../../redux";
import { useParams } from "react-router";
import { Col, Modal, Row } from "react-bootstrap";
import { AddPointSchema } from "../../util/validationSchema";
import { FormattedMessage } from "react-intl";
import { isPositiveNumbersOnly } from "../../util/utils";
import { FaTimesCircle } from "react-icons/fa";

const AddPointModal = ({ onModalClose }: any) => {
  const [ButtonClick, setButtonClick] = useState(false);
  const isAPILoading = useSelector(
    (state: Store) => state.ActivityReducer.isLoading,
  );
  const adminRoleList = useSelector(
    (state: Store) => state.adminRolesReducer.adminRoleList,
  ) as Array<Object>;
  const [selectedRoles, setselectedRoles] = useState({});
  const urlParams = useParams();

  const formik = useFormik({
    initialValues: {
      role: "",
      point: "",
      remark: "",
      activityId: urlParams.id,
    },
    validationSchema: AddPointSchema,
    onSubmit: async (values) => {
      if (!ButtonClick) {
        dispatch(
          addActivityPoints(true, {
            activityId: values.activityId,
            roleId: values.role,
            points: values.point,
            remarks: values.remark.trim(),
          }),
        );
        setButtonClick(true);
      }
    },
  });

  useEffect(() => {
    if (!isAPILoading && ButtonClick) {
      onModalClose();
      const { id } = urlParams;
      // dispatch(getActivityPoints(false, id));
      dispatch(getActivityPoints(false, formik?.values?.activityId));
    }
  }, [isAPILoading]);

  useEffect(() => {
    dispatch(getAdminRolesList(false));
  }, []);

  const { touched, errors } = formik;
  return (
    <Modal centered show={true} onHide={() => onModalClose(false)}>
      <Modal.Header>
        <h4 className="modal-title">
          <FormattedMessage id="head.AddPoint" defaultMessage="Add Point" />
        </h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onModalClose}
        >
          <FaTimesCircle />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label className="control-label">
            <FormattedMessage id="activitymodal.Role" defaultMessage="Role" />
          </label>
          <Select
            name="role"
            value={selectedRoles}
            onChange={(option: any) => {
              formik.setFieldValue("role", option.id);
              setselectedRoles(option);
            }}
            onBlur={() => formik.setFieldTouched("role", true)}
            options={adminRoleList}
            getOptionLabel={(option: any) => option.roleName}
            getOptionValue={(option: any) => option.id}
            className="custom-select-dropdown"
            classNamePrefix="react-select-dropdown"
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
          {errors.role && touched.role && (
            <div className="text-error">{errors.role}</div>
          )}
        </div>

        <Row>
          <Col md={6}>
            <div className="form-group">
              <label className="control-label">
                <FormattedMessage id="tab.Point" defaultMessage="Point" />
              </label>
              <input
                type="text"
                name="point"
                // onChange={(e: any) => {
                //   if (e.target.value < 0) {
                //     return false;
                //   } else {
                //     formik.handleChange(e);
                //   }
                // }}
                onChange={(e: any) => {
                  if (!isPositiveNumbersOnly(e, e.target.value)) {
                    return false;
                  }
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                className="form-control"
                min={0}
              />
              {errors.point && touched.point && (
                <div className="text-error">{errors.point}</div>
              )}
            </div>
          </Col>
          <Col md={6}>
            <div className="form-group">
              <label className="control-label">
                <FormattedMessage
                  id="table.head.Remark"
                  defaultMessage="Remark"
                />
              </label>
              <input
                type="text"
                name="remark"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control"
              />
              {errors.remark && touched.remark && (
                <div className="text-error">{errors.remark.toString()}</div>
              )}
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          onClick={onModalClose}
        >
          <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
        </button>
        <button
          type="button"
          className="btn btn-primary"
          style={{ display: "inherit" }}
          onClick={() => formik.handleSubmit()}
          data-dismiss="modal"
        >
          <FormattedMessage id="page.submit" defaultMessage="Submit" />

          {isAPILoading && <Loader />}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddPointModal;
