import React, { useEffect, useState } from "react";
import { useFormik } from "formik";

import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import Loader from "../common/Loader";
import { addUtilityList, editUtilityList } from "../../redux/action";
import { AddUtilitySchema } from "../../util/validationSchema";
import { Row, Col, Modal } from "react-bootstrap";
import Select from "react-select";
import FileUpload from "../common/MediaUpload/FileUpload";
import { BsTrash } from "react-icons/bs";
import { _UploadMedia } from "../../service/MediaUpload/classes.service";
import { FormattedMessage, useIntl } from "react-intl";
import {
  getFileName,
  get_url_extension,
  isPositiveNumbersOnly,
} from "../../util/utils";
import CenterLoader from "../common/CenterLoader/CenterLoader";
import { FaTimesCircle } from "react-icons/fa";
import { toast } from "react-toastify";

const IMAGE_TYPES = ["png", "jpeg", "jpg"];
const AddUtilityModal = ({ onModalClose, editUtility, rolesList }: any) => {
  const dispatch = useDispatch();
  const [ButtonClick, setButtonClick] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [fileLoading, setFileLoading] = useState(false);
  const [fileUploadURL, setFileUploadURL] = useState<any>({
    extention: "",
    name: null,
  });
  const [deletedFilesUrl, setDeletedFilesUrl] = useState<any>(null);
  const [files, setFiles] = useState<any>(null);

  const isAPILoading = useSelector(
    (state: Store) => state.utilityReducer.onSaveLoading,
  );
  const formik = useFormik({
    initialValues: {
      name: editUtility !== null ? editUtility.name : "",
      info: editUtility !== null ? editUtility.info : "",
      cost: editUtility !== null ? editUtility.cost : 0,
      originalPrice: editUtility !== null ? editUtility.originalPrice : 0,
      sellingPrice: editUtility !== null ? editUtility.sellingPrice : 0,
      belong: editUtility !== null ? editUtility.belong : [],
      image_path: editUtility !== null ? editUtility.image_path : null,
      wooCommerceProductId:
        editUtility !== null ? editUtility.wooCommerceProductId : "",
    },
    validationSchema: AddUtilitySchema,
    onSubmit: (values) => {
      if (!ButtonClick) {
        let isUpload = false;
        if (files) {
          values.image_path = files;
          isUpload = true;
        }
        if (editUtility === null) {
          dispatch(addUtilityList(true, values));
        } else {
          dispatch(editUtilityList(true, editUtility.id, values, isUpload));
        }
        setButtonClick(true);
      }
    },
  });

  useEffect(() => {
    if (editUtility !== null) {
      setSelectedRoles(editUtility.belong);
      const ind = editUtility.belong.map((i: any) => i.id.toString());
      formik.setFieldValue("belong", ind);
      // setFiles(editUtility.image_path)
      //Images
      const imgs: any = {
        name: getFileName(editUtility.image_path),
        extention: get_url_extension(getFileName(editUtility.image_path)),
      };
      setFileUploadURL(imgs);
    }
  }, [editUtility]);

  const handleChange = (selectedOption: any) => {
    setSelectedRoles(selectedOption);
    const belong = selectedOption.map((i: any) => i.id);
    formik.setFieldValue("belong", belong);
  };

  useEffect(() => {
    if (!isAPILoading && ButtonClick) {
      onModalClose();
      setButtonClick(false);
    }
  }, [isAPILoading]);

  const handleDrop = async (acceptedFiles: any) => {
    // setFileLoading(true);
    // const { fileUploadResponse, error }: any = await _UploadMedia(acceptedFiles[0] as File);
    // if (!error) {

    //     const imgs: any = {
    //         name: getFileName(fileUploadResponse),
    //         extention: get_url_extension(getFileName(fileUploadResponse))
    //     }
    //     formik.setFieldValue("image_path", fileUploadResponse)
    //     setFileUploadURL(imgs)
    // }
    // setFileLoading(false);
    // formik.setFieldValue("image_path", acceptedFiles[0]);
    if (acceptedFiles[0].name) {
      const ImageTypes = ["png", "jpg", "jpeg", "bmp", "gif"];
      const extention = acceptedFiles[0].name.split(".");
      if (
        extention.length > 0 &&
        ImageTypes.includes(
          extention[extention.length - 1]?.toLocaleLowerCase(),
        )
      ) {
        const imgs: any = {
          name: acceptedFiles[0].name, //getFileName(fileUploadResponse),
          extention: get_url_extension(acceptedFiles[0].name),
        };
        setFiles(acceptedFiles[0]);
        setFileUploadURL(imgs);
        formik.setFieldValue(
          "image_path",
          URL.createObjectURL(acceptedFiles[0]),
        );

        // setFileUploadURL(URL.createObjectURL(acceptedFiles[0]));
        // setFiles(acceptedFiles[0])
      } else {
        setFileLoading(false);
        toast.error("Only image files are allowed");
      }
    }
  };

  const onDeleteImage = () => {
    // setFiles(null);
    formik.setFieldValue("image_path", null);
    setFileUploadURL({
      extention: "",
      name: null,
    });
  };

  const { touched, errors } = formik;
  return (
    <>
      {fileLoading && <CenterLoader />}
      <Modal centered show={true} onHide={() => onModalClose(false)}>
        <Modal.Header>
          <h4 className="modal-title">
            {editUtility !== null ? (
              <FormattedMessage
                id="head.EditUtility"
                defaultMessage="Edit Utility"
              />
            ) : (
              <FormattedMessage
                id="head.AddNewUtility"
                defaultMessage="Add New Utility"
              />
            )}
          </h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onModalClose}
          >
            <FaTimesCircle />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="control-label">
              {" "}
              <FormattedMessage
                id="utilitymodal.Name"
                defaultMessage="Name"
              />{" "}
            </label>
            <input
              type="text"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control"
            />
            {errors.name && touched.name && (
              <div className="text-error">
                <FormattedMessage
                  id={errors.name.toString()}
                  defaultMessage="require"
                />
              </div>
            )}
          </div>
          <div className="form-group">
            <label className="control-label">
              {" "}
              <FormattedMessage id="utilitymodal.Info" defaultMessage="info" />
            </label>
            <textarea
              rows={2}
              cols={50}
              name="info"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.info}
              className="form-control"
            />
            {errors.info && touched.info && (
              <div className="text-error">
                <FormattedMessage
                  id={errors.info.toString()}
                  defaultMessage="require"
                />
              </div>
            )}
          </div>
          <Row>
            <Col md={6}>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="utilitymodal.Belong"
                    defaultMessage="Belong to"
                  />
                </label>
                <Select
                  name="belong"
                  value={selectedRoles}
                  onChange={handleChange}
                  options={rolesList}
                  getOptionLabel={(option: any) => option.roleName}
                  getOptionValue={(option: any) => option.id}
                  isMulti={true}
                  className="custom-select-dropdown multi"
                  classNamePrefix="react-select-dropdown"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  // placeholder={useIntl().formatMessage({
                  //   id: "select.Role",
                  //   defaultMessage: "Select Role",
                  // })}
                />
                {errors.belong && touched.belong && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.belong.toString()}
                      defaultMessage="require"
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="utilitymodal.Cost"
                    defaultMessage="Cost"
                  />
                </label>
                <input
                  type="number"
                  value={formik.values.cost}
                  // onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                  name="cost"
                  min={0}
                  onChange={(e: any) => {
                    if (!isPositiveNumbersOnly(e, e.target.value)) {
                      return false;
                    }
                    formik.handleChange(e);
                  }}
                  // onKeyPress={(e: any) => {
                  //   if (!isPositiveNumbersOnly(e.code,e.target.value)) {
                  //     e.preventDefault();
                  //   }
                  // }}
                />
                {errors.cost && touched.cost && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.cost.toString()}
                      defaultMessage="require"
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="utilitymodal.Original"
                    defaultMessage="Original Price"
                  />
                </label>
                <input
                  type="number"
                  value={formik.values.originalPrice}
                  // onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                  name="originalPrice"
                  min={0}
                  onChange={(e: any) => {
                    if (!isPositiveNumbersOnly(e, e.target.value)) {
                      return false;
                    }
                    formik.handleChange(e);
                  }}
                  // onKeyPress={(e: any) => {
                  //   if (!isPositiveNumbersOnly(e.code,e.target.value)) {
                  //     e.preventDefault();
                  //   }
                  // }}
                />
                {errors.originalPrice && touched.originalPrice && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.originalPrice.toString()}
                      defaultMessage="require"
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="utilitymodal.Selling"
                    defaultMessage="Selling Price"
                  />
                </label>
                <input
                  type="number"
                  value={formik.values.sellingPrice}
                  // onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                  name="sellingPrice"
                  min={0}
                  // onKeyPress={(e: any) => {
                  //   if (!isPositiveNumbersOnly(e.code,e.target.value)) {
                  //     e.preventDefault();
                  //   }
                  // }}
                  onChange={(e: any) => {
                    if (!isPositiveNumbersOnly(e, e.target.value)) {
                      return false;
                    }
                    formik.handleChange(e);
                  }}
                />
                {errors.sellingPrice && touched.sellingPrice && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.sellingPrice.toString()}
                      defaultMessage="require"
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="wooCommerce.product.id"
                    defaultMessage="WooCommerce Product Id"
                  />
                </label>
                <input
                  value={formik.values.wooCommerceProductId}
                  onChange={(e: any) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  className="form-control"
                  name="wooCommerceProductId"
                />
                {errors.wooCommerceProductId &&
                  touched.wooCommerceProductId && (
                    <div className="text-error">
                      <FormattedMessage
                        id={errors.wooCommerceProductId.toString()}
                        defaultMessage="require"
                      />
                    </div>
                  )}
              </div>
            </Col>
          </Row>
          <div className="form-group mt-2">
            {/* {formik.values.image_path.trim().length <= 0 ? */}
            {formik.values.image_path == null ||
            formik.values.image_path == "" ? (
              <FileUpload handleDrop={handleDrop} />
            ) : (
              <Row className="align-items-center">
                <Col md={9}>
                  <>
                    {IMAGE_TYPES.includes(fileUploadURL?.extention || "") ? (
                      <>
                        {" "}
                        <img width={100} src={formik.values.image_path} />
                      </>
                    ) : (
                      <a>{fileUploadURL?.name || ""} </a>
                    )}
                  </>
                </Col>
                <Col md={3} className="text-right">
                  <button
                    className="border-delete-icon delete"
                    onClick={() => {
                      onDeleteImage();
                    }}
                  >
                    <BsTrash />
                  </button>
                </Col>
              </Row>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={onModalClose}
          >
            <FormattedMessage
              id="utilitymodal.Cancel"
              defaultMessage="Cancel"
            />
          </button>
          <button
            type="button"
            disabled={isAPILoading}
            className="btn btn-primary"
            style={{ display: "inherit" }}
            onClick={() => formik.handleSubmit()}
            data-dismiss="modal"
          >
            {editUtility !== null ? (
              <FormattedMessage id="page.Edit" defaultMessage="Edit" />
            ) : (
              <FormattedMessage id="page.Add" defaultMessage="Add" />
            )}
            {isAPILoading && <Loader />}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddUtilityModal;
