/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { dispatch } from "../../redux";
import { logout } from "../../redux/action";
import axios from "./axiosConfig";
import { GET_USER_PRINT_DETAIL_URL } from "./apiEndPoints";

export const METHOD = {
  POST: "post",
  GET: "get",
  PUT: "put",
  DELETE: "delete",
  PATCH: "patch",
};

export const apiCall = (
  endpoint: string,
  params = {},
  onSuccess: Function,
  onFailure: Function,
  method = METHOD.GET,
  DyanamicConfig: any,
) => {
  const request = (): Promise<any> => {
    switch (method) {
      case METHOD.POST:
        return axios.post(endpoint, params, { ...DyanamicConfig });
      case METHOD.GET:
        return axios.get(endpoint, { ...DyanamicConfig });
      case METHOD.DELETE:
        return axios.delete(endpoint, { ...DyanamicConfig });
      case METHOD.PUT:
        return axios.put(endpoint, params, { ...DyanamicConfig });
      case METHOD.PATCH:
        return axios.patch(endpoint, params, { ...DyanamicConfig });
    }
    return new Promise((resolve, reject) => resolve({})); // axios.post<User[]>(endpoint, params, { ...DyanamicConfig });
  };

  const req = request();

  req
    .then((response) => {
      if (
        (response.status === 200 ||
          response.status === 201 ||
          response.status === 204 ||
          response.data) &&
        response.data !== ""
      ) {
        if (DyanamicConfig.showToast && response.data.message) {
          toast.success(response.data.message);
        }
        onSuccess(response.data);
      } else if (response.status === 200 || response.status === 204) {
        onSuccess("success");
      } else {
        onFailure("Something went wrong");
      }
    })
    .catch((error) => {
      if (error && error.response) {
        if (DyanamicConfig.showToast && error.response.data.message) {
          toast.error(error.response.data.message);
        }

        switch (error.response.status) {
          case 401:
            onFailure(
              error.response.data && typeof error.response.data.detail
                ? error.response.data.detail
                : "Session expired",
            );
            dispatch(logout(false));
            break;

          case 403:
            if (endpoint.startsWith(GET_USER_PRINT_DETAIL_URL)) {
              window.location.replace("/forbidden");
            }
            onFailure(error.response.data ? error.response.data : "Forbidden");
            break;

          case 404:
            window.location.replace("/pageNotFound");
            break;

          default:
            // if (error.response.status === 403) {
            //   toast.error(
            //     error.response.data.message ||
            //       "User is not allowed to perform this action",
            //   );
            // }

            onFailure(
              error.response.data
                ? error.response.data
                : "Something went wrong",
            );
            break;
        }
      } else onFailure && onFailure("Something went wrong");
    });
};
