import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from "draft-js";

interface Props {
  onEditorStateChange: (rowText: any) => void;
  editorState: EditorState;
  toolbar: boolean;
  editable: boolean;
  height: string;
  overflowY: string;
  border: string;
}

interface State {
  editMode: boolean;
}

class EditorContainer extends Component<Props, State> {
  private editorRef: React.RefObject<Editor>;
  constructor(props: any) {
    super(props);
    this.state = {
      editMode: true,
    };
    this.editorRef = React.createRef<Editor>();
  }

  focus = () => {
    const editor = this.editorRef.current;
    if (editor) {
      editor.focusEditor();
    }
  };

  componentDidMount = () => {
    if (this.state.editMode) {
      this.focus();
    }
  };

  render() {
    return (
      <div
        className="editor"
        style={{
          border: this.props.border,
          minHeight: this.props.height,
          overflowY: this.props.overflowY === "scroll" ? "scroll" : "unset",
        }}
      >
        <Editor
          editorState={this.props.editorState}
          onEditorStateChange={this.props.onEditorStateChange}
          ref={this.editorRef}
          readOnly={!this.props.editable}
          editorStyle={{ padding: "0px 7px 0px 7px" }}
          toolbarHidden={!this.props.toolbar}
          toolbar={{
            fontFamily: { inDropdown: true },
            head: { inDropdown: true },
            options: [
              "inline",
              "link",
              "list",
              "fontFamily",
              "colorPicker",
              "emoji",
              "textAlign",
            ],
          }}
        />
      </div>
    );
  }
}

export default EditorContainer;
