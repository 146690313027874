import React, { useEffect, useState } from "react";
import { useFormik } from "formik";

import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import Loader from "../common/Loader";
import { addScriptsList, editScriptsList } from "../../redux/action";
import { AddScriptsSchema } from "../../util/validationSchema";
import { Row, Col, Modal } from "react-bootstrap";
import Select from "react-select";
import FileUpload from "../common/MediaUpload/FileUpload";
import {
  _DeleteMedia,
  _UploadMedia,
} from "../../service/MediaUpload/classes.service";
import { BsTrash } from "react-icons/bs";
import { FormattedMessage, useIntl } from "react-intl";
import CenterLoader from "../common/CenterLoader/CenterLoader";
import { isPositiveNumbersOnly } from "../../util/utils";
import { FaTimesCircle } from "react-icons/fa";
import { toast } from "react-toastify";

const AddScriptsModal = ({ onModalClose, editScripts, rolesList }: any) => {
  const dispatch = useDispatch();
  const [ButtonClick, setButtonClick] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [fileUploadURL, setFileUploadURL] = useState<any>(
    editScripts?.image_path || null,
  );
  const [fileLoading, setFileLoading] = useState(false);

  const [deletedFilesUrl, setDeletedFilesUrl] = useState<any>(null);
  const [files, setFiles] = useState<any>(null);

  const isAPILoading = useSelector(
    (state: Store) => state.scriptsReducer.onSaveLoading,
  );
  const formik = useFormik({
    initialValues: {
      name: editScripts !== null ? editScripts.name : "",
      info: editScripts !== null ? editScripts.info : "",
      cost: editScripts !== null ? editScripts.cost : 0,
      originalPrice: editScripts !== null ? editScripts.originalPrice : 0,
      sellingPrice: editScripts !== null ? editScripts.sellingPrice : 0,
      belong: editScripts !== null ? editScripts.belong : [],
      image_path: editScripts !== null ? editScripts.image_path : "",
      wooCommerceProductId:
        editScripts !== null ? editScripts.wooCommerceProductId : "",
    },
    validationSchema: AddScriptsSchema,
    onSubmit: async (values) => {
      if (!ButtonClick) {
        if (deletedFilesUrl !== null) {
          // deleteFromS3();
        }

        // if (files) {
        //     const { fileUploadResponse, error }: any = await _UploadMedia(files as File);
        //     if (!error) {
        //         values.image_path = fileUploadResponse;
        //     }
        // }

        let isUpload = false;
        if (files) {
          values.image_path = files;
          isUpload = true;
        }

        if (editScripts === null) {
          dispatch(addScriptsList(true, values));
        } else {
          dispatch(editScriptsList(true, editScripts.id, values, isUpload));
        }
        setButtonClick(true);
      }
    },
  });

  const handleChange = (selectedOption: any) => {
    setSelectedRoles(selectedOption);
    const belong = selectedOption.map((i: any) => i.id.toString());

    formik.setFieldValue("belong", belong);
  };
  useEffect(() => {
    if (editScripts !== null) {
      setSelectedRoles(editScripts.belong);
      const ind = editScripts.belong.map((i: any) => i.id.toString());
      formik.setFieldValue("belong", ind);
    }
  }, [editScripts]);

  useEffect(() => {
    if (!isAPILoading && ButtonClick) {
      onModalClose();
      setButtonClick(false);
    }
  }, [isAPILoading]);

  const handleDrop = async (acceptedFiles: any) => {
    if (acceptedFiles[0].name) {
      const ImageTypes = ["png", "jpg", "jpeg", "bmp", "gif"];
      const extention = acceptedFiles[0].name.split(".");
      if (
        extention.length > 0 &&
        ImageTypes.includes(
          extention[extention.length - 1]?.toLocaleLowerCase(),
        )
      ) {
        formik.setFieldValue("image_path", acceptedFiles[0]);
        setFileUploadURL(URL.createObjectURL(acceptedFiles[0]));
        setFiles(acceptedFiles[0]);
      } else {
        setFileLoading(false);
        toast.error("Only image files are allowed");
      }
    }
  };

  const onDeleteImage = (imagePath: any) => {
    if (
      typeof imagePath === "string" &&
      editScripts?.image_path === imagePath
    ) {
      setDeletedFilesUrl(imagePath);
    }

    formik.setFieldValue("image_path", "");
    setFileUploadURL(null);
  };

  const deleteFromS3 = async () => {
    const { response, error }: any = await _DeleteMedia(
      "d218c993bb14dc0ab29e-image-12.png",
    );
  };

  const { touched, errors } = formik;
  return (
    <>
      {fileLoading && <CenterLoader />}

      <Modal centered show={true} onHide={() => onModalClose(false)}>
        <Modal.Header>
          <h4 className="modal-title">
            {editScripts !== null ? (
              <FormattedMessage
                id="head.EditScript"
                defaultMessage="Edit Script"
              />
            ) : (
              <FormattedMessage
                id="head.AddScript"
                defaultMessage="Add new Script"
              />
            )}
          </h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onModalClose}
          >
            <FaTimesCircle />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="control-label">
              {" "}
              <FormattedMessage id="script.Name" defaultMessage="Name" />{" "}
            </label>
            <input
              type="text"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control"
            />
            {errors.name && touched.name && (
              <div className="text-error">
                <FormattedMessage
                  id={errors.name.toString()}
                  defaultMessage="require"
                />
              </div>
            )}
          </div>
          <div className="form-group">
            <label className="control-label">
              {" "}
              <FormattedMessage id="script.Info" defaultMessage="info" />
            </label>
            <textarea
              rows={2}
              cols={50}
              name="info"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.info}
              className="form-control"
            />
            {errors.info && touched.info && (
              <div className="text-error">
                <FormattedMessage
                  id={errors.info.toString()}
                  defaultMessage="require"
                />
              </div>
            )}
          </div>
          <Row>
            <Col md={6}>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="script.Belong"
                    defaultMessage="Belong to"
                  />
                </label>
                <Select
                  name="belong"
                  value={selectedRoles}
                  onChange={handleChange}
                  options={rolesList}
                  getOptionLabel={(option: any) => option.roleName}
                  getOptionValue={(option: any) => option.id}
                  isMulti={true}
                  className="custom-select-dropdown multi"
                  classNamePrefix="react-select-dropdown"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  // placeholder={useIntl().formatMessage({
                  //   id: "select.Role",
                  //   defaultMessage: "Select Role",
                  // })}
                />
                {errors.belong && touched.belong && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.belong.toString()}
                      defaultMessage="require"
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage id="script.Cost" defaultMessage="Cost" />
                </label>
                <input
                  type="number"
                  value={formik.values.cost}
                  // onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                  name="cost"
                  min={0}
                  onChange={(e: any) => {
                    if (!isPositiveNumbersOnly(e, e.target.value)) {
                      return false;
                    }
                    formik.handleChange(e);
                  }}
                  // onKeyPress={(e: any) => {
                  //   if (!isPositiveNumbersOnly(e.code,e.target.value)) {
                  //     e.preventDefault();
                  //   }
                  // }}
                />
                {errors.cost && touched.cost && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.cost.toString()}
                      defaultMessage="require"
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="script.Original"
                    defaultMessage="Original Price"
                  />
                </label>
                <input
                  type="number"
                  value={formik.values.originalPrice}
                  // onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                  name="originalPrice"
                  min={0}
                  onChange={(e: any) => {
                    if (!isPositiveNumbersOnly(e, e.target.value)) {
                      return false;
                    }
                    formik.handleChange(e);
                  }}
                  // onKeyPress={(e: any) => {
                  //   if (!isPositiveNumbersOnly(e.code,e.target.value)) {
                  //     e.preventDefault();
                  //   }
                  // }}
                />
                {errors.originalPrice && touched.originalPrice && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.originalPrice.toString()}
                      defaultMessage="require"
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="script.Selling"
                    defaultMessage="Selling Price"
                  />
                </label>
                <input
                  type="number"
                  value={formik.values.sellingPrice}
                  // onChange={formik.handleChange}
                  onChange={(e: any) => {
                    if (!isPositiveNumbersOnly(e, e.target.value)) {
                      return false;
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  className="form-control"
                  name="sellingPrice"
                  min={0}
                  // onKeyPress={(e: any) => {
                  //   if (!isPositiveNumbersOnly(e.code,e.target.value)) {
                  //     e.preventDefault();
                  //   }
                  // }}
                />
                {errors.sellingPrice && touched.sellingPrice && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.sellingPrice.toString()}
                      defaultMessage="require"
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label className="control-label">
                  <FormattedMessage
                    id="wooCommerce.product.id"
                    defaultMessage="WooCommerce Product Id"
                  />
                </label>
                <input
                  value={formik.values.wooCommerceProductId}
                  onChange={(e: any) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  className="form-control"
                  name="wooCommerceProductId"
                />
                {errors.wooCommerceProductId &&
                  touched.wooCommerceProductId && (
                    <div className="text-error">
                      <FormattedMessage
                        id={errors.wooCommerceProductId.toString()}
                        defaultMessage="require"
                      />
                    </div>
                  )}
              </div>
            </Col>
          </Row>
          <div className="form-group">
            {fileUploadURL == null ? (
              <FileUpload handleDrop={handleDrop} />
            ) : (
              <Row className="align-items-center">
                <Col md={9}>
                  <img style={{ width: "80%" }} src={fileUploadURL} />
                </Col>
                <Col md={3} className="text-right">
                  <button
                    className="border-delete-icon delete"
                    onClick={() => {
                      onDeleteImage(formik.values.image_path);
                    }}
                  >
                    <BsTrash />
                  </button>
                </Col>
              </Row>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={onModalClose}
          >
            <FormattedMessage id="script.Cancel" defaultMessage="Cancel" />
          </button>
          <button
            type="button"
            disabled={isAPILoading}
            className="btn btn-primary"
            style={{ display: "inherit" }}
            onClick={() => formik.handleSubmit()}
            data-dismiss="modal"
          >
            {editScripts !== null ? (
              <FormattedMessage id="page.Edit" defaultMessage="Edit" />
            ) : (
              <FormattedMessage id="page.Add" defaultMessage="Add" />
            )}
            {isAPILoading && <Loader />}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddScriptsModal;
