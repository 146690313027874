import { useEffect, useRef, useState } from "react";
import QrScanner from "qr-scanner";
import { Button } from "react-bootstrap";

interface MyQrCodeScannerProps {
  handleScan: (result: Record<string, any>) => void;
}

const MyQrCodeScanner = (props: MyQrCodeScannerProps) => {
  const { handleScan } = props;

  const video = useRef<HTMLVideoElement>(null);
  const [qrScanner, setQrScanner] = useState<QrScanner | undefined>(undefined);
  const [facingMode, setFacingMode] = useState<
    "environment" | "user" | undefined
  >("environment");

  const toggleFacingMode = () => {
    setFacingMode((oldFacingMode) => {
      const newFacingMode =
        oldFacingMode === "environment" ? "user" : "environment";
      if (qrScanner) {
        qrScanner.setCamera(newFacingMode);
      }
      return newFacingMode;
    });
  };

  useEffect(() => {
    if (video.current && !qrScanner) {
      const qrScanner = new QrScanner(
        video.current,
        (result) => {
          qrScanner.stop();
          handleScan(result);
          setTimeout(() => {
            qrScanner.start();
          }, 5000);
        },
        {
          highlightScanRegion: true,
          preferredCamera: facingMode,
        },
      );
      qrScanner.start();
      setQrScanner(qrScanner);
    }
  }, [facingMode, handleScan, qrScanner]);

  return (
    <>
      <div className="mb-3">
        <Button color="primary" onClick={toggleFacingMode}>
          Toggle Camera
        </Button>
      </div>
      <video ref={video} style={{ width: "100%" }}></video>
    </>
  );
};

export default MyQrCodeScanner;
