import React from "react";
import Dropzone from "react-dropzone";
import { BsUpload } from "react-icons/bs";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";

const FileUploadSmall = ({ handleDrop, accept }: any) => {
  const handle = (acceptedFiles: any) => {
    const files: any = [];
    for (let i = 0; i < acceptedFiles.length; i++) {
      if (acceptedFiles[i].size > 104857600) {
        toast.error(
          `${acceptedFiles[i].name} file is not allowed because it's size is more than 100MB`,
        );
      } else {
        files.push(acceptedFiles[i]);
      }
    }
    if (files.length > 0) {
      handleDrop(files);
    }
  };

  return (
    <div>
      <div className="fileUploadSmall">
        <Dropzone
          onDrop={handle}
          onDropAccepted={handle}
          accept={accept}
          // minSize={1024}
          maxSize={104857600}
          onDropRejected={(rejectedFiles: any) => {
            for (let i = 0; i < rejectedFiles.length; i++) {
              if (rejectedFiles[i].file.size > 104857600) {
                toast.error(
                  `${rejectedFiles[i].file.name} file is not allowed because it's size is more than 100MB`,
                );
              } else {
                handleDrop([rejectedFiles[i].file]);
              }
              // if (rejectedFiles[0].file.size > 104857600) {
              //   toast.error("File size should not be more than 10MB")
              // }
            }
          }}
        >
          {({ getRootProps, getInputProps, isDragReject }) => {
            if (isDragReject) {
              toast.error("File is not allowed");
            }
            return (
              <div
                style={{ textAlign: "center" }}
                {...getRootProps({
                  className:
                    "dropzone align-items-center d-flex justify-content-center",
                })}
              >
                <input {...getInputProps()} />
                <p className="align-items-center d-flex justify-content-center m-0">
                  {" "}
                  <BsUpload size={25} className="mr-2" />
                  <FormattedMessage
                    id="file.upload"
                    defaultMessage="Drag'n'drop files, or click to select files (optional)"
                  />
                </p>
              </div>
            );
          }}
        </Dropzone>
      </div>
    </div>
  );
};

export default FileUploadSmall;
