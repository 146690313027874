import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import { useDispatch, useSelector } from "react-redux";
import Logo from "../assets/img/gemsFX_white.png";
import { loginSchema } from "../util/validationSchema";
import { login } from "../redux/action";
import { Store } from "../redux/Actions";
import Loader from "../Components/common/Loader";
import { FormattedMessage, useIntl } from "react-intl";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { isEdge } from "react-device-detect";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoggedin = useSelector(
    (state: Store) => state.userDataReducer.isLoggedin,
  );
  const isLoading = useSelector(
    (state: Store) => state.userDataReducer.isLoading,
  );

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((oldShowPassword) => !oldShowPassword);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      dispatch(login(values.email, values.password));
    },
  });

  const { touched, errors } = formik;

  useEffect(() => {
    if (isLoggedin) {
      navigate("/home");
    }
  }, [isLoggedin]);

  return (
    <div>
      <div className="login-box">
        <div className="login-logo">
          <img src={Logo} style={{ maxWidth: "37%" }} alt="Logo" />
          {/* <Logo /> */}
        </div>
        <div className="login-box-body">
          <h1 className="text-center mb-3 font-weight-500">
            <FormattedMessage id="Login" defaultMessage="Login " />
          </h1>
          <p className="text-center mb-4">
            <FormattedMessage
              id="Login.credentials"
              defaultMessage="Enter your credentials to access the panel"
            />
          </p>
          <form
            onSubmit={(e: any) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          >
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Email"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {errors.email && touched.email && (
                <div className="text-error ml-21">{errors.email}</div>
              )}
            </div>
            <div className="form-group">
              <div style={{ position: "relative" }}>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  className="form-control"
                  placeholder="Password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {!isEdge &&
                  (showPassword ? (
                    <AiFillEyeInvisible
                      size="1.5rem"
                      className="show-password-icon"
                      onClick={toggleShowPassword}
                    />
                  ) : (
                    <AiFillEye
                      size="1.5rem"
                      className="show-password-icon"
                      onClick={toggleShowPassword}
                    />
                  ))}
              </div>
              {errors.password && touched.password && (
                <div className="text-error ml-21">{errors.password}</div>
              )}
            </div>
            <button
              type="submit"
              className="btn btn-login-dark btn-block btn-lg"
            >
              <div className="d-flex align-items-center justify-content-center">
                <FormattedMessage id="Login" defaultMessage="Login " />
                {isLoading && (
                  <div className="ml-2">
                    <Loader />
                  </div>
                )}
              </div>
            </button>
          </form>
        </div>
        <div
          className="login-box-footer d-flex justify-content-between"
          style={{ background: "white" }}
        >
          {/* <div className="form-group">
                        <div className="custom-control custom-checkbox checkbox-primary mb-0">
                            <input type="checkbox" className="custom-control-input" id="customCheck2" />
                            <label className="custom-control-label" htmlFor="customCheck2"> <FormattedMessage id="Login.Remember" defaultMessage="Remember Me" /></label>
                        </div>
                    </div>
                    <div className="password-reset-link">
                        <a className="text-primary" href="forgot-password.php"><FormattedMessage id="Login.Forgot" defaultMessage=" Forgot Password?" /></a>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
