/* eslint-disable no-case-declarations */
import {
  GET_EXPERT_ADVISOR_LIST,
  ADD_EXPERT_ADVISOR_LIST,
  EDIT_EXPERT_ADVISOR_LIST,
  GET_EXPERT_ADVISOR_DETAILS,
  GET_EA_VERSION_LIST,
  ADD_EA_VERSION,
  EDIT_EA_VERSION,
  DELETE_EA_VERSION,
  DELETE_EA,
  SET_PAGE,
  ADD_EA_VERSION_TO_ALL_CLASS,
  SET_ACCOUNT_LIMIT,
  ADD_ACCOUNT_INFO_LIST,
  GET_ACCOUNT_INFO_LIST,
} from "../constants/action-types";
import { ExpertAdvisorsData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";
// import { Posts } from "../Actions";

const initialState = {
  progress: false,
  isLoading: false,
  onSaveLoading: false,
  expertAdvisorsList: {},
  expertAdvisorsDetails: {},
  eaVersion: {},
  eaAccountInfo: {
    free_user_account_combned: 1,
    free_user_num_of_demo_account: 0,
    free_user_num_of_real_account: 0,
    free_user_total_account: 0,
    paid_user_account_combned: 0,
    paid_user_num_of_demo_account: 0,
    paid_user_num_of_real_account: 0,
    paid_user_total_account: 0,
  },
  totalRecord: 0,
  currentPage: 1,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: ExpertAdvisorsData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case SET_PAGE:
        return {
          ...state,
          currentPage: action.payload,
        };

      case GET_EXPERT_ADVISOR_LIST.GET_EXPERT_ADVISOR_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_EXPERT_ADVISOR_LIST.GET_EXPERT_ADVISOR_LIST_SUCCESS:
        const allPost: any = action.payload; // [{ id: 2, body: "Abcd", title: "title", userId: 3 }, { id: 2, body: "Abcd", title: "title", userId: 3 }];
        return {
          ...state,
          expertAdvisorsList: allPost.data,
          totalRecord: allPost.totalCount,
          isLoading: false,
        };

      case GET_EXPERT_ADVISOR_LIST.GET_EXPERT_ADVISOR_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_EXPERT_ADVISOR_LIST.ADD_EXPERT_ADVISOR_LIST_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case ADD_EXPERT_ADVISOR_LIST.ADD_EXPERT_ADVISOR_LIST_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case ADD_EXPERT_ADVISOR_LIST.ADD_EXPERT_ADVISOR_LIST_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case EDIT_EXPERT_ADVISOR_LIST.EDIT_EXPERT_ADVISOR_LIST_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case EDIT_EXPERT_ADVISOR_LIST.EDIT_EXPERT_ADVISOR_LIST_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case EDIT_EXPERT_ADVISOR_LIST.EDIT_EXPERT_ADVISOR_LIST_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case GET_EXPERT_ADVISOR_DETAILS.GET_EXPERT_ADVISOR_DETAILS_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_EXPERT_ADVISOR_DETAILS.GET_EXPERT_ADVISOR_DETAILS_SUCCESS:
        return {
          ...state,
          expertAdvisorsDetails: action.payload,
          isLoading: false,
        };

      case GET_EXPERT_ADVISOR_DETAILS.GET_EXPERT_ADVISOR_DETAILS_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_EA_VERSION_LIST.GET_EA_VERSION_LIST_INITIALIZATION:
        return {
          ...state,
          totalRecord: 0,
          isLoading: true,
        };

      case GET_EA_VERSION_LIST.GET_EA_VERSION_LIST_SUCCESS:
        const eaVersion: any = action.payload;
        return {
          ...state,
          eaVersion: eaVersion.data,
          totalRecord: eaVersion.totalCount || 1,
          isLoading: false,
        };

      case GET_EA_VERSION_LIST.GET_EA_VERSION_LIST_ERROR:
        return {
          ...state,
          totalRecord: 0,
          isLoading: false,
        };

      case ADD_EA_VERSION.ADD_EA_VERSION_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case ADD_EA_VERSION.ADD_EA_VERSION_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case ADD_EA_VERSION.ADD_EA_VERSION_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case EDIT_EA_VERSION.EDIT_EA_VERSION_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case EDIT_EA_VERSION.EDIT_EA_VERSION_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case EDIT_EA_VERSION.EDIT_EA_VERSION_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case DELETE_EA_VERSION.DELETE_EA_VERSION_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case DELETE_EA_VERSION.DELETE_EA_VERSION_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case DELETE_EA_VERSION.DELETE_EA_VERSION_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case DELETE_EA.DELETE_EA_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case DELETE_EA.DELETE_EA_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case DELETE_EA.DELETE_EA_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_EA_VERSION_TO_ALL_CLASS.ADD_EA_VERSION_TO_ALL_CLASS_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case ADD_EA_VERSION_TO_ALL_CLASS.ADD_EA_VERSION_TO_ALL_CLASS_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_EA_VERSION_TO_ALL_CLASS.ADD_EA_VERSION_TO_ALL_CLASS_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case SET_ACCOUNT_LIMIT.SET_ACCOUNT_LIMIT_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case SET_ACCOUNT_LIMIT.SET_ACCOUNT_LIMIT_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case SET_ACCOUNT_LIMIT.SET_ACCOUNT_LIMIT_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_ACCOUNT_INFO_LIST.ADD_ACCOUNT_INFO_LIST_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case ADD_ACCOUNT_INFO_LIST.ADD_ACCOUNT_INFO_LIST_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case ADD_ACCOUNT_INFO_LIST.ADD_ACCOUNT_INFO_LIST_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case GET_ACCOUNT_INFO_LIST.GET_ACCOUNT_INFO_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_ACCOUNT_INFO_LIST.GET_ACCOUNT_INFO_LIST_SUCCESS:
        const accountInfo: any = action.payload;
        return {
          ...state,
          isLoading: false,
          eaAccountInfo: {
            free_user_account_combned:
              accountInfo?.data?.free_user_account_combned,
            free_user_num_of_demo_account:
              accountInfo?.data?.free_user_num_of_demo_account,
            free_user_num_of_real_account:
              accountInfo?.data?.free_user_num_of_real_account,
            free_user_total_account: accountInfo?.data?.free_user_total_account,
            paid_user_account_combned:
              accountInfo?.data?.paid_user_account_combned,
            paid_user_num_of_demo_account:
              accountInfo?.data?.paid_user_num_of_demo_account,
            paid_user_num_of_real_account:
              accountInfo?.data?.paid_user_num_of_real_account,
            paid_user_total_account: accountInfo?.data?.paid_user_total_account,
          },
        };

      case GET_ACCOUNT_INFO_LIST.GET_ACCOUNT_INFO_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
          eaAccountInfo: {
            free_user_account_combned: 1,
            free_user_num_of_demo_account: 0,
            free_user_num_of_real_account: 0,
            free_user_total_account: 0,
            paid_user_account_combned: 0,
            paid_user_num_of_demo_account: 0,
            paid_user_num_of_real_account: 0,
            paid_user_total_account: 0,
          },
        };

      case GET_ACCOUNT_INFO_LIST.GET_ACCOUNT_INFO_LIST_DISCRAED:
        return {
          ...state,
          eaAccountInfo: {
            free_user_account_combned: 0,
            free_user_num_of_demo_account: 0,
            free_user_num_of_real_account: 0,
            free_user_total_account: 0,
            paid_user_account_combned: 0,
            paid_user_num_of_demo_account: 0,
            paid_user_num_of_real_account: 0,
            paid_user_total_account: 0,
          },
        };

      default:
        return state;
    }
  },
);

export default reducer;
