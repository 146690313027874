import { Avatar } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import Select from "react-select";
import { GROUP_TYPE, TICKET_STATUS } from "../../Constants/TabConstants";
import { dispatch } from "../../redux";
import {
  editTicketList,
  getTicketAssigneeList,
  getTicketGroupList,
  getTicketIssueTypeList,
  getTicketToolList,
  getTicketToolVersionList,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import { TicketOption, ToolTypeOption } from "../../util/constants/UserGroup";
import {
  customFormatDatetimeWithLocale,
  formatDate,
  formatDateDiff,
  formatDateMM,
} from "../../util/utils";
import { softwareUpdateSchema } from "../../util/validationSchema";

const TicketAssignee = ({ ticketDetail, id }: any) => {
  const IssueType = useSelector(
    (state: Store) => state.ticketReducer.issueList,
  ) as any;
  const toolList = useSelector(
    (state: Store) => state.ticketReducer.toolList,
  ) as any;
  const toolVersionList = useSelector(
    (state: Store) => state.ticketReducer.toolVersionList,
  ) as any;
  const ticketAsignee = useSelector(
    (state: Store) => state.ticketReducer.ticketAsignee,
  ) as any;
  const groupList = useSelector(
    (state: Store) => state.ticketReducer.groupList,
  ) as any;

  const myInfo = useSelector(
    (state: Store) => state.userDataReducer.myInfo,
  ) as any;

  const [load, setLoad] = useState<any>({});
  const [selectedSoftwareType, setSelectedSoftwareType] = useState<any>({});
  const [selectedSoftware, setSelectedSoftware] = useState<any>({});
  const [selectedSoftwareVersion, setSelectedSoftwareVersion] = useState<any>(
    {},
  );
  const [softwareVersion, setSoftwareVersion] = useState<any>([]);

  const [selectedGroup, setSelectedGroup] = useState<any>({});
  const [selectedStatus, setSelectedStatus] = useState<any>(null);
  const [selectedType, setSelectedType] = useState<any>({});
  const [selectedChange, setChange] = useState<any>(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      toolType: ticketDetail ? ticketDetail?.tool : -1,
      tool: ticketDetail ? ticketDetail?.software?.id : -1,
      version: ticketDetail ? ticketDetail?.version?.id : null,
    },
    validationSchema: softwareUpdateSchema,
    onSubmit: () => {
      editTicket();
    },
  });
  const { errors, touched } = formik;

  useEffect(() => {
    if (IssueType.length <= 0) {
      dispatch(getTicketIssueTypeList());
    }

    // if (groupList.length <= 0) {
    dispatch(getTicketGroupList(false, GROUP_TYPE.TICKET));
    // }

    if (toolList.length <= 0) {
      dispatch(getTicketToolList(false, 0));
    }
  }, []);

  useEffect(() => {
    // if (Object.keys(ticketDetail).length > 0) {
    //   dispatch(getTicketIssueTypeList());
    // dispatch(getTicketAssigneeList(false));
    //   dispatch(getTicketToolList(false, 0));

    if (ticketDetail.status) {
      TicketOption.map((i: any) => {
        if (i.id === ticketDetail.status) {
          setSelectedStatus(i);
        }
      });
    }

    if (ticketDetail.type) {
      setSelectedType(ticketDetail.type);
      formik.setFieldValue("type", ticketDetail?.type?.id);
    }
    // if (ticketDetail.tool > -1) {
    //   ToolTypeOption.map((i: any) => {
    //     if (i.id === ticketDetail.tool) {
    //       setSelectedSoftwareType(i);
    //       formik.setFieldValue("type", i.tool);
    //       // dispatch(getTicketToolList(false, ticketDetail.tool));
    //     }
    //   });
    // }

    if (ticketDetail.tool > -1) {
      formik.setFieldValue("toolType", ticketDetail?.tool);
      ToolTypeOption.map((i: any) => {
        if (i.id === ticketDetail.tool) {
          setSelectedSoftwareType(i);
          // formik.setFieldValue("type", i.tool);
          // dispatch(getTicketToolList(false, ticketDetail.tool));
        }
      });
    }

    // if (ticketDetail?.software?.id) {
    //   toolList.map((i: any) => {
    //     if (i.id === ticketDetail.software.id) {
    //       setSoftwareVersion(i.versionList)
    //     }
    //   })
    // }

    // if (ticketDetail.software) {
    //   toolList.map((i: any) => {
    //     if (parseInt(i.id) === parseInt(ticketDetail.software)) {
    //       setSelectedSoftware(i);
    //       (i.versionList || []).map((iv: any) => {
    //         if (parseInt(iv.id) === parseInt(ticketDetail.version)) {
    //           setSelectedSoftwareVersion(iv);
    //         }
    //       })

    //     }
    //   })
    // setSelectedSoftware(ticketDetail.software);
    // }
    if (ticketDetail.version) {
      setSelectedSoftwareVersion(ticketDetail?.version);
    }

    if (ticketDetail.ticketGroup) {
      setSelectedGroup(ticketDetail?.ticketGroup);
    }
    // }
  }, [ticketDetail]);

  useEffect(() => {
    if (ticketDetail.software) {
      setSelectedSoftware(ticketDetail.software);
      formik.setFieldValue("tool", ticketDetail?.software?.id);
      dispatch(
        getTicketToolVersionList(
          false,
          ticketDetail.software.id,
          ticketDetail.tool,
        ),
      );

      // setSelectedSoftwareVersion(ticketDetail.version);
      // dispatch(getTicketToolVersionList(false, ticketDetail.id, ticketDetail.tool))
      // toolList.map((i: any) => {
      //   if (parseInt(i.id) === parseInt(ticketDetail.software)) {
      //     setSelectedSoftware(i);
      //     (i.versionList || []).map((iv: any) => {
      //       if (parseInt(iv.id) === parseInt(ticketDetail.version)) {
      //         setSelectedSoftwareVersion(iv);
      //       }
      //     })

      //   }
      // });
    }
  }, [ticketDetail, toolList]);

  const onToolChange = async (option: any) => {
    setSoftwareVersion([]);
    setSelectedSoftwareVersion(null);
    dispatch(getTicketToolVersionList(false, option.id, option.type));

    await setSelectedSoftware(option);
    // await setSoftwareVersion(option.versionList);
    await formik.setFieldValue("version", null);
    await formik.setFieldValue("tool", option.id);
    await formik.setFieldValue("toolType", option.type);
    formik.handleSubmit();

    // dispatch(getTicketToolVersionList(false, parseInt(option.id), 0));
  };

  const onTicketGroupChange = (option: any) => {
    setSelectedGroup(option);
    //     editTicket();
    const tcdetail = ticketDetail;
    delete tcdetail.id;
    delete tcdetail.commentList;
    delete tcdetail.ticketGroup;
    delete tcdetail.createdAt;
    delete tcdetail.updatedAt;
    delete tcdetail.reporter;

    const ticketDetails = {
      content: tcdetail.content,
      title: tcdetail.title,
      isActive: tcdetail.isActive,
      isPublic: tcdetail.isPublic,
      software: formik.values.tool,
      tool: formik.values.toolType,
      version: formik.values.version,
      type: selectedType.id,
      status: selectedStatus.id,
      ticketGroupId: option.id,
      attachmentList: tcdetail.attachmentList.map((i: any) => i.attachmentPath),
    };
    dispatch(editTicketList(true, id, ticketDetails));
  };

  // useEffect(() => {
  //   if (selectedGroup != {} && selectedGroup?.id > 0) {
  //   }
  // }, [selectedGroup])

  // const onToolTypeChange = (option: any) => {
  //   setSelectedSoftwareType(option);

  //   formik.setFieldValue("toolType", option.id);
  //   formik.setFieldValue("tool", -1);
  //   formik.setFieldValue("version", -1);

  //   dispatch(getTicketToolList(false, option.id));
  //   setSelectedSoftware({});
  //   setSelectedSoftwareVersion({});
  // };

  const onToolVersionChange = async (option: any) => {
    await setSelectedSoftwareVersion(option);
    await formik.setFieldValue("version", option.id);
    // submit();
    formik.handleSubmit();
    // formik.handleSubmit();
  };

  // const submit = () => {

  // }

  const editTicket = () => {
    const tcdetail = ticketDetail;
    delete tcdetail.id;
    delete tcdetail.commentList;
    delete tcdetail.ticketGroup;
    delete tcdetail.createdAt;
    delete tcdetail.updatedAt;
    delete tcdetail.reporter;

    const ticketDetails = {
      content: tcdetail.content,
      title: tcdetail.title,
      isActive: tcdetail.isActive,
      isPublic: tcdetail.isPublic,
      software: formik.values.tool,
      tool: formik.values.toolType,
      version: formik.values.version,
      type: selectedType.id,
      status: selectedStatus.id,
      ticketGroupId: selectedGroup.id,
      attachmentList: tcdetail.attachmentList.map((i: any) => i.attachmentPath),
    };
    dispatch(editTicketList(true, id, ticketDetails));
  };

  const checkStatus = () => {
    const open = [TICKET_STATUS.CLOSE];
    if (selectedStatus?.id) return open.includes(selectedStatus?.id);
    else return false;
  };

  const locale = useSelector((state: Store) => state.userDataReducer.local);

  useEffect(() => {
    if (selectedChange) {
      editTicket();
    }
    setChange(false);
  }, [selectedChange]);

  return (
    <>
      <br />
      <div className="mb-3">
        <label>
          <FormattedMessage id="Tickets.Status" defaultMessage="Status" />
        </label>
        <Select
          options={TicketOption}
          value={selectedStatus}
          onChange={(option: any) => {
            setSelectedStatus(option);
            setChange(true);
          }}
          getOptionLabel={(option: any) => option.label}
          getOptionValue={(option: any) => option.id}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
      </div>

      <div className="mb-3">
        <label>
          <FormattedMessage id="Tickets.Type" defaultMessage="Type" />
        </label>
        <Select
          options={IssueType}
          value={selectedType}
          isDisabled={checkStatus()}
          onChange={(option: any) => {
            setSelectedType(() => option);
            setChange(true);
          }}
          getOptionLabel={(option: any) => option.name}
          getOptionValue={(option: any) => option.id}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
      </div>

      <div className="mb-3">
        <label>
          <FormattedMessage id="Tickets.Assignee" defaultMessage="Assignee" />
        </label>
        <Select
          options={groupList}
          value={selectedGroup}
          isDisabled={checkStatus()}
          onChange={(option: any) => onTicketGroupChange(option)}
          getOptionLabel={(option: any) => option.group_name}
          getOptionValue={(option: any) => option.id}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
      </div>

      <div className="mb-3">
        <label>
          <FormattedMessage id="Tickets.Reporter" defaultMessage="Reporter" />
        </label>
        <Row className="ml-2">
          <div className="mr-3">
            <Avatar>
              {ticketDetail?.reporter?.englishFirstName &&
                ticketDetail?.reporter?.englishFirstName
                  ?.charAt(0)
                  ?.toUpperCase()}
            </Avatar>
          </div>
          <label>
            {ticketDetail?.reporter?.englishFirstName &&
              ticketDetail?.reporter?.englishFirstName}{" "}
            {ticketDetail?.reporter?.englishLastName &&
              ticketDetail?.reporter?.englishLastName}
            <div style={{ fontSize: 12 }}>
              {ticketDetail?.reporter?.userClass
                .map(
                  (userClass: { class: { name: string } }) =>
                    userClass.class.name,
                )
                .join(",")}
            </div>
          </label>
        </Row>
      </div>
      <label>
        <FormattedMessage
          id="Tickets.SoftwareChanges"
          defaultMessage="Software Changes"
        />
      </label>
      {/* <div className="mb-2">
        <FormattedMessage id="ticketmodal.Tool" defaultMessage="Tool Type" />
        <Select
          onChange={onToolTypeChange}
          value={selectedSoftwareType}
          options={ToolTypeOption}
          isDisabled={checkStatus()}
          onBlur={() => formik.setFieldTouched("toolType", true)}
          getOptionLabel={(option: any) => option.group}
          getOptionValue={(option: any) => option.id}
        />
        {errors.toolType && touched.toolType && (
          <div className="error">
            <FormattedMessage
              id={errors.toolType.toString()}
              defaultMessage="require"
            />
          </div>
        )}
      </div> */}

      <div className="mb-2">
        <label>
          <FormattedMessage id="Tickets.Software" defaultMessage="Software" />
        </label>
        <Select
          options={toolList}
          onChange={onToolChange}
          isDisabled={checkStatus()}
          onBlur={() => formik.setFieldTouched("tool", true)}
          value={selectedSoftware}
          getOptionLabel={(option: any) => option.name}
          getOptionValue={(option: any) => option.id}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
        {errors.tool && touched.tool && (
          <div className="error">
            <FormattedMessage
              id={errors.tool.toString()}
              defaultMessage="require"
            />
          </div>
        )}
      </div>

      <div className="mb-2">
        <label>
          <FormattedMessage id="Tickets.Version" defaultMessage="Version" />
        </label>
        <Select
          value={selectedSoftwareVersion}
          onChange={onToolVersionChange}
          onBlur={() => formik.setFieldTouched("version", true)}
          options={toolVersionList}
          isDisabled={checkStatus()}
          getOptionLabel={(option: any) => option.version}
          getOptionValue={(option: any) => option.id}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
        {/* {errors.version && touched.version && (
          <div className="error">
            <FormattedMessage
              id={errors.version.toString()}
              defaultMessage="require"
            />
          </div>
        )} */}
      </div>
      <br />

      {/* <div className="mb-3">
        {!checkStatus() && (
          <Button onClick={() => formik.handleSubmit()}>Submit</Button>
        )}
      </div> */}
      <br />
      <div>
        <label>
          {ticketDetail.createdAt ? (
            <>
              {" "}
              <FormattedMessage id="CreatedAt" defaultMessage="Created At" />:
              {customFormatDatetimeWithLocale(
                ticketDetail.createdAt,
                locale,
                true,
              )}{" "}
            </>
          ) : null}
        </label>{" "}
        <label>
          {ticketDetail.updatedAt ? (
            <>
              {" "}
              <FormattedMessage id="updatedAt" defaultMessage="Updated At" />:
              {customFormatDatetimeWithLocale(
                ticketDetail.updatedAt,
                locale,
                true,
              )}{" "}
            </>
          ) : null}
        </label>{" "}
        <br />
        <label>
          {ticketDetail.updatedAt ? (
            <>
              <FormattedMessage id="Updated" defaultMessage="Updated" />{" "}
              {formatDateDiff(new Date(), ticketDetail.updatedAt)}{" "}
              <FormattedMessage id="DaysAgo" defaultMessage="days ago" />
            </>
          ) : null}
        </label>
      </div>
    </>
  );
};

export default TicketAssignee;
