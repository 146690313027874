import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { BsSortDown, BsSortUp } from "react-icons/bs";
import { FormattedMessage } from "react-intl";
import NoDatFound from "../../Components/common/NoDatFound";
import UserViewModal from "../../Components/User/UserViewModal";
import { dispatch } from "../../redux";
import { getStudentByIdList } from "../../redux/action";
import { USER_VIEW } from "../../util/constants/UserGroup";
import { arrayToStr } from "../../util/utils";

const RejectedStudents = ({
  studentList,
  setSorting,
  sortBy,
  sortOrder,
}: any) => {
  const [viewData, setViewData] = useState({ modal: false, user: {} });
  const onViewClick = (user: any) => {
    dispatch(getStudentByIdList(false, user.student_id));
    setViewData({ modal: true, user: user });
  };
  return (
    <div className="">
      <section className="">
        <div className="table-responsive">
          {studentList && studentList.length > 0 ? (
            <table className="table table-hover m-0">
              <thead>
                <tr>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => setSorting("slip")}
                  >
                    <FormattedMessage
                      id="table.head.slipId"
                      defaultMessage="SlipId"
                    />
                    {sortBy === "slip" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => setSorting("memberId")}
                  >
                    <FormattedMessage
                      id="table.head.MemberId"
                      defaultMessage="MemberId"
                    />
                    {sortBy === "memberId" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => setSorting("class")}
                  >
                    <FormattedMessage
                      id="table.head.Class"
                      defaultMessage="Class"
                    />
                    {sortBy === "class" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => setSorting("status")}
                  >
                    <FormattedMessage
                      id="table.head.Name"
                      defaultMessage="Name"
                    />
                    {sortBy === "status" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => setSorting("name")}
                  >
                    <FormattedMessage
                      id="table.head.Nickname"
                      defaultMessage="Nickname"
                    />
                    {sortBy === "name" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.PreviousClass"
                      defaultMessage="Previous Class"
                    />{" "}
                  </th>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => setSorting("name")}
                  >
                    <FormattedMessage
                      id="table.head.Type"
                      defaultMessage="Type"
                    />
                    {sortBy === "name" ? (
                      sortOrder === "ASC" ? (
                        <BsSortDown />
                      ) : (
                        <BsSortUp />
                      )
                    ) : null}
                  </th>
                  <th className="table-field-actions">
                    <FormattedMessage
                      id="table.head.View"
                      defaultMessage="View"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* <?<5;$i++) { ?> */}
                {studentList &&
                  studentList.length &&
                  studentList.map((i: any) => (
                    <tr>
                      <td>{i.payment_id}</td>
                      <td>{i.users_id}</td>
                      <td>{i.class_name}</td>
                      <td>
                        {i.users_englishFirstName}
                        {i.users_englishNickName
                          ? ` ${i.users_englishNickName} `
                          : " "}
                        {i.users_englishLastName}
                      </td>
                      <td>{i.users_preferredNickName}</td>
                      <td>
                        {(
                          i?.previousClass.map(
                            (i: any) => i?.class_name || "",
                          ) || []
                        ).join(", ")}
                      </td>
                      <td>{i?.role_roleName || "API"}</td>
                      <td>
                        <Button onClick={() => onViewClick(i)}>
                          <FormattedMessage id="Set" defaultMessage="SET" />
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <NoDatFound />
          )}
        </div>
      </section>
      {viewData.modal && (
        <UserViewModal
          onClose={() => {
            setViewData((prv) => ({ ...prv, modal: false, user: {} }));
          }}
          user={viewData.user}
          modal={viewData.modal}
          type={USER_VIEW.REJECTED}
        />
      )}
    </div>
  );
};

export default RejectedStudents;
