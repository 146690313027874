import { Button, Card } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { dispatch } from "../../redux";
import { Dropdown } from "react-bootstrap";
import { Store } from "../../redux/Actions";
import AddFaqModal from "../../Components/Faq/AddFaqModal";
import { AppendedMyComponent } from "../../Components/append-to-body-drop-down";
import EditFaqModal from "../../Components/Faq/EditFaqModal";
import DeletePopup from "../../Components/common/Popups/DeletePopup";
import NoDatFound from "../../Components/common/NoDatFound";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import { FormattedMessage } from "react-intl";
import { FaEdit, FaTrash, BsFillInfoCircleFill } from "../../reactIcons";
import { deleteFaq, getFAQList } from "../../redux/action/faqActions";
import DOMPurify from "dompurify";
import ViewFaqModal from "../../Components/Faq/ViewFaqModal";

const Faqs = () => {
  const FaqList = useSelector((state: Store) => state.faqReducer.FAQList);

  const [modal, setmodal] = useState<Record<string, any>>({
    modal: 3,
    faq: {},
  });
  const [delModalShow, setDelModalShow] = useState<Record<string, any>>({
    modal: false,
    id: 0,
  });

  const isLoading = useSelector((state: Store) => state.faqReducer.isLoading);

  const onDelete = () => {
    dispatch(deleteFaq(true, delModalShow.id));
    setDelModalShow({ modal: false, id: 0 });
  };

  useEffect(() => {
    dispatch(getFAQList());
  }, []);

  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                <FormattedMessage id="Admin.FAQ" defaultMessage="Admin FAQ" />
              </h1>
            </div>
            <div className="m-l-10 text-end">
              <Button
                type="button"
                onClick={() => setmodal({ modal: 0, faq: {} })}
              >
                <FormattedMessage id="head.AddFAQ" defaultMessage="Add FAQ" />
              </Button>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          {isLoading && <CenterLoader />}

          <Card style={{ width: "100%" }} className="mt-3">
            <Card.Body className="p-0">
              <div>
                <div className="table-responsive">
                  {FaqList && FaqList.length > 0 ? (
                    <>
                      {" "}
                      <table className="table table-hover m-0 faq-table">
                        <thead>
                          <tr>
                            <th>
                              <FormattedMessage
                                id="table.head.ID"
                                defaultMessage="Id"
                              />
                            </th>
                            <th>
                              <FormattedMessage
                                id="table.head.Question"
                                defaultMessage="Question"
                              />
                            </th>
                            <th>
                              <FormattedMessage
                                id="table.head.Answer"
                                defaultMessage="Answer"
                              />
                            </th>
                            <th>
                              <FormattedMessage
                                id="table.head.FaqCategory"
                                defaultMessage="FAQ Category"
                              />
                            </th>
                            <th>
                              <FormattedMessage
                                id="table.head.Code"
                                defaultMessage="Code"
                              />
                            </th>
                            <th>
                              <FormattedMessage
                                id="table.head.Isactive"
                                defaultMessage="Isactive"
                              />
                            </th>
                            <th className="table-field-status">
                              <FormattedMessage
                                id="table.head.Actions"
                                defaultMessage="Action"
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {FaqList &&
                            FaqList.length > 0 &&
                            FaqList.map((i: any) => (
                              <tr>
                                <td>{i?.id}</td>
                                <td>{i?.question}</td>
                                <td>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: DOMPurify.sanitize(i?.answer),
                                    }}
                                  ></div>
                                </td>
                                <td>{i?.faqCategory?.name}</td>
                                <td>{i?.code}</td>
                                <td>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={i.isActive}
                                      id="flexCheckChecked"
                                    />
                                  </div>
                                </td>
                                <td className="table-field-status">
                                  <Dropdown className="btn-group">
                                    <Dropdown.Toggle
                                      id="dropdown-basic"
                                      className="btn btn-sm btn-icon-only"
                                    >
                                      <i className="icon dripicons-dots-3 zmdi-hc-fw"></i>
                                    </Dropdown.Toggle>
                                    <AppendedMyComponent>
                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={() =>
                                            setmodal({ modal: 2, faq: i })
                                          }
                                        >
                                          <i className=" text-accent-custom">
                                            <BsFillInfoCircleFill />{" "}
                                          </i>
                                          <FormattedMessage
                                            id="classDetail.Details"
                                            defaultMessage="Details"
                                          />
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={() =>
                                            setmodal({ modal: 1, faq: i })
                                          }
                                        >
                                          <i className="text-accent-custom">
                                            <FaEdit />{" "}
                                          </i>
                                          <FormattedMessage
                                            id="page.Edit"
                                            defaultMessage="Edit"
                                          />
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={() =>
                                            setDelModalShow({
                                              modal: true,
                                              id: i.id,
                                            })
                                          }
                                        >
                                          <i className="fa-fw text-accent-custom">
                                            <FaTrash />{" "}
                                          </i>
                                          <FormattedMessage
                                            id="page.Delete"
                                            defaultMessage="Delete"
                                          />
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </AppendedMyComponent>
                                  </Dropdown>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <p>{!isLoading && <NoDatFound />}</p>
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>

          {modal.modal === 0 && (
            <AddFaqModal
              onModalClose={() => setmodal({ modal: 3, faq: {} })}
              faq={modal.faq}
            />
          )}

          {modal.modal === 1 && (
            <EditFaqModal
              onModalClose={() => setmodal({ modal: 3, faq: {} })}
              faq={modal.faq}
            />
          )}

          {modal.modal === 2 && (
            <ViewFaqModal
              onModalClose={() => setmodal({ modal: 3, faq: {} })}
              faq={modal.faq}
            />
          )}
          {delModalShow.modal && (
            <DeletePopup
              onClose={() => setDelModalShow({ modal: false, id: 0 })}
              onDeleteClick={onDelete}
              open={delModalShow.modal}
            />
          )}
        </section>
      </div>
    </div>
  );
};

export default Faqs;
