import React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import { dispatch } from "../../redux";
import { getExpressWayMemberDetail } from "../../redux/action";
// import { formatDateMM } from "../../util/utils";

const PendinUsers = ({ users }: any) => {
  const navigate = useNavigate();

  const viewDetail = (id: any) => {
    dispatch(getExpressWayMemberDetail(false, id));

    navigate("/users/expressWay/" + id);
  };

  const getClassName = (arr: any) => {
    let ar: any = "";
    if (arr.length != undefined && arr.length > 0) {
      arr.map((i: any) => {
        ar += `,  ${i.name}`;
      });
      ar = ar.replace(",", "");
      return ar;
    }
    return "";
  };

  return (
    <div className="table-responsive">
      <table className="table table-hover m-0">
        <thead>
          <tr>
            <th>
              <FormattedMessage id="table.head.MID" defaultMessage="MID" />
            </th>
            <th>
              <FormattedMessage id="table.head.Name" defaultMessage="Name" />
            </th>
            <th>
              <FormattedMessage
                id="table.head.Nickname"
                defaultMessage="Nickname"
              />
            </th>
            <th>
              <FormattedMessage id="table.head.Email" defaultMessage="Email" />
            </th>
            <th>
              <FormattedMessage
                id="table.head.MobileNumber"
                defaultMessage="Mobile Number"
              />
            </th>
            <th>
              <FormattedMessage
                id="table.head.repeater"
                defaultMessage="Repeater"
              />
            </th>
            <th>
              <FormattedMessage
                id="table.head.repeaterClass"
                defaultMessage="Repeater Class"
              />
            </th>
            <th>
              <FormattedMessage id="table.head.Class" defaultMessage="Class" />
            </th>
            {/* <th><FormattedMessage id="table.head.RegisterDate" defaultMessage="Register Date" /></th> */}
          </tr>
        </thead>
        <tbody>
          {users.length &&
            users.map((i: any) => (
              <tr onClick={() => viewDetail(i.id)} className="pointer">
                <td>{i.id}</td>
                <td>
                  {i.englishFirstName}
                  {i.englishNickName ? ` ${i.englishNickName} ` : " "}
                  {i.englishLastName}
                </td>
                <td>{i?.preferredNickName}</td>
                <td>{i.email}</td>
                <td>
                  +{i?.dialCode || ""} {i.phoneNumber}
                </td>
                <td>{i.repeater ? "Yes" : "No"}</td>
                <td>{i.repeater ? getClassName(i?.repeaterClass) : "-"}</td>

                <td>
                  {Object.entries(i?.classData).length > 0
                    ? getClassName(i.classData)
                    : ""}
                </td>

                {/* <td onClick={() => viewDetail(i.id)}>{formatDateMM(i.createdAt, "YYYY/MM/DD")} </td> */}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
export default PendinUsers;
