import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "react-select";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { dispatch } from "../../redux";
import { getUserClass, getUserClassRole } from "../../redux/action";
import { useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import { getToken } from "../../util/utils";
import { toast } from "react-toastify";
import secureLocalStorage from "react-secure-storage";

const UserPrintModal = ({ onClose, open, id }: any) => {
  const [route, setRoute] = React.useState(`users/print/${id}`);
  const [selectedClass, setSelectedClass] = React.useState<any>({});
  const [selectedRole, setSelectedRole] = React.useState<any>({});
  const [printNotForRole, setPrintNotForRole] = React.useState<any>(false);

  const myClass = useSelector(
    (state: Store) => state.userDataReducer.userClass,
  );
  const userClassRole =
    useSelector((state: Store) => state.userDataReducer.userClassRole) || [];
  const ref = useRef<any>();

  useEffect(() => {
    secureLocalStorage.setItem(
      "authToken",
      getToken("login-auth-token")?.toString() || "",
    );
  }, []);

  useEffect(() => {
    if (userClassRole.length > 0) {
      setSelectedRole(userClassRole[0]);
    }
  }, [userClassRole]);

  useEffect(() => {
    if (selectedClass.class_id && selectedRole.id) {
      setPrintNotForRole(false);
      if (selectedRole.roleName.toString().toLocaleLowerCase() === "student") {
        setRoute(`users/print/${id}?class=${selectedClass.class_id}`);
      } else if (
        selectedRole.roleName.toString().toLocaleLowerCase() === "repeater"
      ) {
        setRoute(`users/repeater/print/${id}?class=${selectedClass.class_id}`);
      } else {
        setPrintNotForRole(true);
        setRoute("");
      }
    }
  }, [selectedRole, selectedClass]);

  useEffect(() => {
    if (parseInt(id) > 0) {
      dispatch(getUserClass(false, id));
    }
  }, []);

  useEffect(() => {
    if (myClass.length > 0) {
      setSelectedClass(myClass[0]);
    }
  }, [myClass]);

  useEffect(() => {
    setSelectedRole({});
    if (selectedClass.class_id > 0)
      dispatch(getUserClassRole(false, id, selectedClass.class_id));
  }, [selectedClass]);

  const handleClose = () => {
    onClose(false);
  };

  const handleGo = () => {
    if (selectedClass.class_id && setRoute.toString().length > 0) {
      if (printNotForRole) {
        toast.warn("Application print not available for this role");
      } else {
        ref?.current.click();
        onClose(false);
      }
    }
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        {" "}
        <FormattedMessage
          id="head.ChooseClass"
          defaultMessage="Choose class"
        />{" "}
      </DialogTitle>
      <Row>
        <Col>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-standard-label">
              <FormattedMessage id="head.Class" defaultMessage="Class" />
            </InputLabel>
            <Select
              value={selectedClass}
              onChange={(option: any) => setSelectedClass(option)}
              options={myClass}
              getOptionLabel={(option: any) => option.class_name}
              getOptionValue={(option: any) => option.class_id}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
          </FormControl>
        </Col>
        <Col>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-standard-label">
              <FormattedMessage id="head.Role" defaultMessage="Role" />
            </InputLabel>
            <Select
              value={selectedRole}
              onChange={(option: any) => {
                setSelectedRole(option);
              }}
              options={userClassRole}
              getOptionLabel={(option: any) => option.roleName}
              getOptionValue={(option: any) => option.id}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
          </FormControl>
        </Col>
      </Row>
      <a style={{ display: "none" }} ref={ref} href={route} target="_blank">
        Go
      </a>
      <Button onClick={handleGo}>
        <FormattedMessage id="table.head.Go" defaultMessage="GO" />
      </Button>
    </Dialog>
  );
};

UserPrintModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
};
export default UserPrintModal;
