import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import UserPrintModal from "../../Components/User/UserPrintModal";
import UserVpsMachineModal from "../../Components/User/UserVpsMachineModal";
import { dispatch } from "../../redux";
import { formatDateMM } from "../../util/utils";
import { useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import { AppendedMyComponent } from "../../Components/append-to-body-drop-down";
import AccountLimitSetting from "../../Components/Account/AccountLimitSetting";
import MakeAdminModal from "../../Components/common/Popups/MakeAdminModal";
import { toast } from "react-toastify";
import { getAdminList, updateAdminUserStatus } from "../../redux/action";
import DeletePopup from "../../Components/common/Popups/DeletePopup";
import { Store } from "../../redux/Actions";

const AdminList = ({ users, pageNumber }: any) => {
  const [show, setShow] = useState({ modal: false, id: 0 });
  const [showVps, setShowVps] = useState({ modal: false, id: 0 });
  const [makeAdminModal, setMakeAdminModal] = useState({ modal: false, id: 0 });
  const [showConfirmDialogue, setShowConfirmDialogue] = useState(false);

  const [limitAccount, setLimitAccount] = useState({
    modal: false,
    id: 0,
    data: null,
  });
  const [deletePopup, setDeletePopup] = useState({ modal: false, id: 0 });
  const ApproveAdmin = (id: any) => {
    // dispatch(makeAdminInit(id));
    toast.success("Successfully approve");
    setMakeAdminModal({ modal: false, id: 0 });
  };

  const getClassName = (arr: any) => {
    let ar: any = "";
    if (arr.length != undefined && arr.length > 0) {
      arr.map((i: any) => {
        ar += `,  ${i.name}`;
      });
      ar = ar.replace(",", "");
      return ar;
    }
  };

  const [toggleActivePopup, setToggleActivePopup] = useState<any>({
    showModal: false,
    user: null,
  });

  useEffect(() => {
    return () => {
      setShow({ modal: false, id: 0 });
    };
  }, []);

  return (
    <div className="">
      <section className="">
        <div className="table-responsive">
          <table className="table table-hover m-0">
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="table.head.MID" defaultMessage="MID" />
                </th>
                <th>
                  <FormattedMessage
                    id="table.head.Name"
                    defaultMessage="Name"
                  />
                </th>
                <th style={{ cursor: "pointer" }}>
                  <FormattedMessage
                    id="table.head.Type"
                    defaultMessage="Type"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="table.head.Email"
                    defaultMessage="Email"
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="table.head.MobileNumber"
                    defaultMessage="Mobile Number"
                  />
                </th>
                <th className="text-center">
                  <FormattedMessage
                    id="Classes.Status"
                    defaultMessage="Status"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {users.length &&
                users.map((i: any) => (
                  <tr>
                    <td>{i.id}</td>
                    <td>
                      {i.englishFirstName}
                      {i.englishNickName ? ` ${i.englishNickName} ` : " "}
                      {i.englishLastName}
                    </td>
                    <td>{i?.userType}</td>
                    <td>{i.email}</td>
                    <td>
                      +{i?.dialCode || ""} {i.phoneNumber}
                    </td>
                    <td
                      onClick={() =>
                        setToggleActivePopup({
                          showModal: true,
                          user: i,
                        })
                      }
                      className="pointer table-field-status"
                    >
                      <span
                        className={
                          i.isActive
                            ? "badge badge-pill badge-success"
                            : "badge badge-pill badge-danger"
                        }
                      >
                        {i.isActive ? "Active" : "Inactive"}
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </section>

      {show.modal && (
        <UserPrintModal
          open={show.modal}
          onClose={() => setShow({ modal: false, id: 0 })}
          id={show.id}
        />
      )}

      {showVps.modal && (
        <UserVpsMachineModal
          open={showVps.modal}
          onClose={() => setShowVps({ modal: false, id: 0 })}
          id={showVps.id}
        />
      )}

      {limitAccount.modal && (
        <AccountLimitSetting
          open={limitAccount.modal}
          onClose={() => setLimitAccount({ modal: false, id: 0, data: null })}
          id={limitAccount.id}
          data={limitAccount.data}
        />
      )}

      {makeAdminModal && (
        <MakeAdminModal
          onClose={() => setMakeAdminModal({ modal: false, id: 0 })}
          onApproveClick={() => ApproveAdmin(makeAdminModal.id)}
          open={makeAdminModal.modal}
        />
      )}

      <DeletePopup
        onClose={() => setToggleActivePopup({ showModal: false, user: null })}
        open={toggleActivePopup.showModal}
        onDeleteClick={() =>
          dispatch(
            updateAdminUserStatus(
              true,
              !toggleActivePopup.user?.isActive,
              toggleActivePopup.user?.id,
            ),
          )
        }
        text={`Do you want to ${
          toggleActivePopup.user?.isActive ? "de" : ""
        }activate ${toggleActivePopup.user?.englishFirstName}
        ${
          toggleActivePopup.user?.englishNickName
            ? ` ${toggleActivePopup.user?.englishNickName} `
            : " "
        }
        ${toggleActivePopup.user?.englishLastName}?`}
      />
    </div>
  );
};

export default AdminList;
