import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
import UserSelectionByClass from "../../Components/Selection/UserSelectionByClass";
import { dispatch } from "../../redux";
import {
  addRentsList,
  getClasses,
  getGiftCategoryList,
  getRentsCategoryList,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import { AddRentSchema } from "../../util/validationSchema";
import { FormattedMessage } from "react-intl";
import Loader from "../../Components/common/Loader";
import { isPositiveNumbersOnly } from "../../util/utils";
const Rent = () => {
  const userSelectionRef = useRef<any>();

  const RentsTypeList = useSelector(
    (state: Store) => state.rentsReducer.RentsTypeList,
  );
  const isLoading = useSelector((state: Store) => state.rentsReducer.isLoading);
  const [buttonClick, setButtonClick] = useState({});
  const [toolId, setToolId] = useState({});

  useEffect(() => {
    dispatch(getClasses(false, 0, { by: "createdAt", order: "ASC" }));
    dispatch(getGiftCategoryList(false));
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: 0,
      quantity: 0,
      remark: "",
      valid: "",
      users: [],
    },
    validationSchema: AddRentSchema,
    onSubmit: async (values) => {
      if (!buttonClick) {
        values.remark = values.remark.trim();

        dispatch(addRentsList(false, values));
        setButtonClick(true);
      }
    },
  });

  useEffect(() => {
    dispatch(getRentsCategoryList(false));
  }, []);

  const { errors, touched, resetForm } = formik;

  useEffect(() => {
    if (!isLoading && buttonClick) {
      resetForm();
      setToolId({});
      userSelectionRef?.current();
      setButtonClick(false);
    }
  }, [isLoading]);

  const onFormReset = (e: any) => {
    e.preventDefault();
    setToolId({});
    resetForm();
    userSelectionRef?.current();
  };

  return (
    <form
      onSubmit={(e: any) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <div className="card-body">
        <Row>
          <Col xl={8}>
            <Row>
              <Col md={12}>
                <UserSelectionByClass
                  refs={userSelectionRef}
                  setFinalUsers={(users: any) =>
                    formik.setFieldValue("users", users)
                  }
                  users={formik.values.users}
                />
                {errors.users && touched.users && (
                  <div className="text-error">
                    <FormattedMessage
                      id={errors.users.toString()}
                      defaultMessage="This field is required"
                    />
                  </div>
                )}
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="control-label">
                    <FormattedMessage
                      id="table.head.RentType"
                      defaultMessage="Tool Id"
                    />
                  </label>
                  <Select
                    name="ToolId"
                    value={toolId}
                    onChange={(option: any) => {
                      formik.setFieldValue("type", option.id);
                      setToolId(option);
                    }}
                    onBlur={() => formik.setFieldTouched("type", true)}
                    options={RentsTypeList}
                    getOptionLabel={(option: any) => option.type}
                    getOptionValue={(option: any) => option.id}
                    className="custom-select-dropdown"
                    classNamePrefix="react-select-dropdown"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                  {errors.type && touched.type && (
                    <div className="text-error">
                      {" "}
                      <FormattedMessage
                        id={errors.type.toString()}
                        defaultMessage="This field is required"
                      />
                    </div>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="control-label">
                    <FormattedMessage
                      id="table.head.Quantity"
                      defaultMessage="Quantity"
                    />
                  </label>
                  <input
                    type="text"
                    value={formik.values.quantity}
                    name="quantity"
                    onChange={(e: any) => {
                      if (!isPositiveNumbersOnly(e, e.target.value)) {
                        return false;
                      }
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    className="form-control"
                    min={0}
                    // onKeyPress={(e: any) => {
                    //   if (!isPositiveNumbersOnly(e.code)) {
                    //     e.preventDefault();
                    //   }
                    // }}
                  />
                  {errors.quantity && touched.quantity && (
                    <div className="text-error">
                      <FormattedMessage
                        id={errors.quantity.toString()}
                        defaultMessage="This field is required"
                      />
                    </div>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="control-label">
                    <FormattedMessage
                      id="table.head.Remarks"
                      defaultMessage="Remarks"
                    />
                  </label>
                  <input
                    type="text"
                    name="remark"
                    value={formik.values.remark}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-control"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="control-label">
                    <FormattedMessage
                      id="table.head.ValidUntil"
                      defaultMessage="Valid until"
                    />
                  </label>
                  <input
                    type="date"
                    name="valid"
                    value={formik.values.valid}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-control"
                  />
                  {errors.valid && touched.valid && (
                    <div className="text-error">
                      <FormattedMessage
                        id={errors.valid.toString()}
                        defaultMessage="This field is required"
                      />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="card-footer bg-light text-right">
        <button
          className="btn btn-secondary mr-3"
          onClick={(e: any) => onFormReset(e)}
        >
          <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
        </button>
        <Button type="submit">
          <FormattedMessage id="Rents.rent.rents" defaultMessage="Rent" />
          {isLoading && <Loader />}
        </Button>
      </div>
    </form>
  );
};

export default Rent;
