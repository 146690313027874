import { AxiosError } from "axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { customFetch } from "../../../util/axios";
import { GemsClassResponse } from "../types";
import { GetAllGemsClassesQueryDto } from "../schemas";

export interface GetAllGemsClassesResponse {
  gemsClassesCount: number;
  gemsClassesTotalPages: number;
  gemsClassesHasNextPage: boolean;
  gemsClasses: GemsClassResponse[];
}

export const getAllGemsClasses = async (
  getAllGemsClassesQuery: GetAllGemsClassesQueryDto,
) => {
  const response = await customFetch.get("/gems-classes", {
    params: getAllGemsClassesQuery,
  });
  return response.data;
};

export const useGetAllGemsClasses = (
  getAllGemsClassesQuery: GetAllGemsClassesQueryDto,
) => {
  const queryClient = useQueryClient();

  const query = useQuery<GetAllGemsClassesResponse, AxiosError | Error>({
    queryKey: ["gems-classes", getAllGemsClassesQuery],
    queryFn: () => getAllGemsClasses(getAllGemsClassesQuery),
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000,
  });

  const pageIndex = getAllGemsClassesQuery.pageIndex || 0;
  const { data, isPreviousData } = query;

  useEffect(() => {
    if (!isPreviousData && data?.gemsClassesHasNextPage) {
      const nextParams = {
        ...getAllGemsClassesQuery,
        pageIndex: pageIndex + 1,
      };

      queryClient.prefetchQuery({
        queryKey: ["gems-classes", nextParams],
        queryFn: () => getAllGemsClasses(nextParams),
      });
    }
  }, [getAllGemsClassesQuery, data, isPreviousData, pageIndex, queryClient]);

  return query;
};
