import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import AddPointModal from "../../../Components/Activity/AddPointModal";
import NoDatFound from "../../../Components/common/NoDatFound";
import { dispatch } from "../../../redux";
import {
  // getActivityAttendence,
  // getActivityAttendenceLog,
  getActivityPoints,
} from "../../../redux/action";
import { Store } from "../../../redux/Actions";
import { formatDate } from "../../../util/utils";

const Point = () => {
  const urlParams = useParams();
  const pointsList = useSelector(
    (state: Store) => state.ActivityReducer.pointsList,
  );
  const [modal, setModal] = useState(false);

  useEffect(() => {
    const { id } = urlParams;
    dispatch(getActivityPoints(false, id));
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-end p-4">
        <a
          href="#"
          data-toggle="modal"
          onClick={() => setModal(true)}
          data-target="#AddCourse"
          type="button"
          className="btn btn-primary"
        >
          <FormattedMessage id="head.AddPoint" defaultMessage="Add Point" />
        </a>
      </div>
      <div className="table-responsive">
        {pointsList && pointsList.length ? (
          <table className="table table-hover m-0">
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="table.head.ID" defaultMessage="ID" />
                </th>
                <th>
                  <FormattedMessage
                    id="table.head.Role"
                    defaultMessage="Role"
                  />
                </th>
                <th className="table-field-status">
                  <FormattedMessage
                    id="Admin.GrantPoint.Points"
                    defaultMessage="Points"
                  />
                </th>
                <th className="table-field-actions">
                  <FormattedMessage
                    id="Admin.GrantPoint.Remark"
                    defaultMessage="Remark"
                  />
                </th>
                <th className="table-field-actions">
                  <FormattedMessage
                    id="table.head.Date"
                    defaultMessage="Date"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {pointsList &&
                pointsList.length &&
                pointsList.map((i: any) => (
                  <tr>
                    <td>{i.id}</td>
                    <td>{i?.role?.roleName || "N/A"}</td>
                    <td className="text-center">{i.points}</td>
                    <td className="text-center">{i.remarks}</td>
                    <td className="text-center">
                      {formatDate(i.createdAt, "YYYY-MM-DD    ")}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <NoDatFound />
        )}
      </div>
      {/* Point */}
      {modal && <AddPointModal onModalClose={() => setModal(false)} />}
    </div>
  );
};
export default Point;
