import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import { dispatch } from "../../redux";
import { resendOtp } from "../../redux/action";
import { Store } from "../../redux/Actions";
import { formatDateMM } from "../../util/utils";

const EmailVerificationUsers = ({ users }: any) => {
  const navigate = useNavigate();
  const isLoading = useSelector(
    (state: Store) => state.userDataReducer.isLoading,
  );
  const viewDetail = (id: any) => {
    navigate("/users/" + id);
  };

  const getClassName = (arr: any) => {
    let ar: any = "";
    if (arr.length != undefined && arr.length > 0) {
      arr.map((i: any) => {
        ar += `,  ${i.name}`;
      });
      ar = ar.replace(",", "");
      return ar;
    }
    return "";
  };

  return (
    <div className="table-responsive">
      {isLoading && <CenterLoader />}
      <table className="table table-hover m-0">
        <thead>
          <tr>
            <th>
              <FormattedMessage id="table.head.MID" defaultMessage="MID" />
            </th>
            <th>
              <FormattedMessage id="table.head.Class" defaultMessage="Class" />
            </th>
            <th>
              <FormattedMessage id="table.head.Name" defaultMessage="Last" />
            </th>
            {/* <th><FormattedMessage id="table.head.First" defaultMessage="First" /></th> */}
            {/* <th><FormattedMessage id="table.head.Middle" defaultMessage="Middle" /></th> */}
            <th>
              <FormattedMessage
                id="table.head.Nickname"
                defaultMessage="Nickname"
              />
            </th>
            <th>
              <FormattedMessage id="table.head.Email" defaultMessage="Email" />
            </th>
            <th>
              <FormattedMessage
                id="table.head.MobileNumber"
                defaultMessage="Mobile Number"
              />
            </th>
            <th>
              <FormattedMessage
                id="table.head.repeater"
                defaultMessage="Repeater"
              />
            </th>
            <th>
              <FormattedMessage
                id="table.head.repeaterClass"
                defaultMessage="Repeate Class"
              />
            </th>
            <th>
              <FormattedMessage
                id="table.head.RegisterDate"
                defaultMessage="Register Date"
              />
            </th>
            <th>
              <FormattedMessage
                id="table.head.SendEmail"
                defaultMessage="Send Email"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {(users || []).length &&
            users.map((i: any) => (
              <tr className="pointer">
                <td onClick={() => viewDetail(i.id)}>{i.id}</td>
                <td onClick={() => viewDetail(i.id)}>
                  {Object.entries(i?.classData).length > 0
                    ? getClassName(i.classData)
                    : ""}
                </td>
                <td onClick={() => viewDetail(i.id)}>
                  {i.englishFirstName}
                  {i.englishNickName ? ` ${i.englishNickName} ` : " "}
                  {i.englishLastName}
                </td>
                <td onClick={() => viewDetail(i.id)}>{i?.preferredNickName}</td>
                <td onClick={() => viewDetail(i.id)}>{i.email}</td>
                <td onClick={() => viewDetail(i.id)}>
                  +{i?.dialCode || ""} {i.phoneNumber}
                </td>
                <td onClick={() => viewDetail(i.id)}>
                  {i.repeater ? "Yes" : "No"}
                </td>
                <td onClick={() => viewDetail(i.id)}>
                  {i.repeater ? getClassName(i?.repeaterClass) : "-"}
                </td>
                <td onClick={() => viewDetail(i.id)}>
                  {formatDateMM(i.createdAt, "YYYY/MM/DD")}{" "}
                </td>
                <td>
                  <Button onClick={() => dispatch(resendOtp(i.email, false))}>
                    <FormattedMessage
                      id="msg.SendEmail"
                      defaultMessage="Send Email"
                    />
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default EmailVerificationUsers;
