import { useEffect } from "react";
import { useLocation, useParams } from "react-router";
import { formatDate, getCurrentDatetimeWithTimezone } from "../../util/utils";
import TopLogo from "../../assets/img/print-top-logo1.png";
import StampLogo from "../../assets/img/stamp-logo.png";
import { dispatch } from "../../redux";
import { getUserPrintDetail } from "../../redux/action";
import { useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import { toBase64 } from "../../util/utils";
import { CURTSYTITLE } from "./Memberdetail/MemberDetail";
import moment from "moment-timezone";
import secureLocalStorage from "react-secure-storage";
import Forbidden from "../Forbidden";

const PrintUserInfo = () => {
  const myInfo: any = useSelector(
    (state: Store) => state.userDataReducer.userPrintDetail.userData,
  );

  const authToken: any =
    secureLocalStorage.getItem("authToken")?.toString() || "";
  const urlParams: any = useParams();
  const location = useLocation();

  const a = new URLSearchParams(location.search);
  useEffect(() => {
    const id: string = urlParams.id?.toString();
    const classId = a.get("class");
    dispatch(getUserPrintDetail(id, classId, "student", authToken));
  }, []);

  return (
    <div className="" style={{ background: "white" }}>
      {myInfo && Object.keys(myInfo).length > 0 ? (
        <div className="print-container">
          <table width="100%">
            <tr>
              <td style={{ textAlign: "center" }}>
                <img
                  src={TopLogo}
                  style={{ maxWidth: "60%", marginBottom: "30px" }}
                  alt="Logo"
                />
              </td>
            </tr>
            <tr>
              <td>
                <table width="100%">
                  <tr>
                    <td style={{ width: "50%" }} className="align-top">
                      請以正楷填寫此表格 Please complete this form in BLOCK
                      LETTERS.
                      <br />
                      報讀課程 Course applied for:
                      <table width="100%" className="course-applied">
                        <tr>
                          <td style={{ width: "50%" }}>
                            課程名稱
                            <br />
                            Programme Title{" "}
                          </td>
                          <td style={{ width: "50%" }}>
                            <small>
                              Forex Forest Algorithmic Trading Course
                            </small>
                            Course No.: {myInfo?.class_name}
                            {/* {a.get("class")} */}
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td style={{ width: "50%" }} className="align-top">
                      <table width="100%" className="course-applied">
                        <tr>
                          <td>
                            FF-APPFORM-{getCurrentDatetimeWithTimezone()}
                            {/* {formatDate(new Date(), "YYYY/MM")} */}
                            {/* 2020/02 */}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            由本公司填寫
                            <br />
                            For Forex Forest Limited use only
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <small>
                              <span className="check-line-box"></span> Admitted
                            </small>{" "}
                            &nbsp; &nbsp;{" "}
                            <small>
                              <span className="check-line-box"></span> Rejected
                            </small>{" "}
                            &nbsp; &nbsp;{" "}
                            <small>
                              <span className="check-line-box"></span> Waiting
                              List
                            </small>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            HK$ {myInfo?.class_sellingPrice || 0.0}{" "}
                            _____________________ 學費已收 <br />
                            <small>Tuition fee accepted</small>
                          </td>
                        </tr>
                        <tr>
                          <td>#ID {myInfo.users_id}</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ paddingBottom: "20px", paddingTop: "20px" }}>
                個人資料 Personal Particulars所填報姓名須與香港身份證/護照相同
                Name should the same as shown on HKID Card / Passport
              </td>
            </tr>
            <tr>
              <td>
                <table width="100%">
                  <tr>
                    <td style={{ paddingRight: "40px", verticalAlign: "top" }}>
                      <table width="100%" className="line-table">
                        <tr>
                          <td style={{ width: "50%" }} className="gfg">
                            中文姓名
                            <br />
                            Name in Chinese
                          </td>
                          <td style={{ width: "50%", verticalAlign: "bottom" }}>
                            <p className="name-line">
                              {myInfo?.users_chineseLastName +
                                " " +
                                myInfo?.users_chineseFirstName}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>
                            英文姓名
                            <br />
                            Name in English
                          </td>
                          <td style={{ width: "50%", verticalAlign: "bottom" }}>
                            <p className="name-line">
                              {myInfo?.users_englishFirstName +
                                " " +
                                myInfo?.users_englishLastName}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>
                            香港身份證/護照號碼 <br /> HKID/Passport No.
                          </td>
                          <td style={{ width: "50%", verticalAlign: "bottom" }}>
                            <p className="name-line">
                              {myInfo?.users_identityId}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>
                            電子郵箱 <br /> Email Address
                          </td>
                          <td style={{ width: "50%", verticalAlign: "bottom" }}>
                            <p className="name-line">{myInfo?.users_email} </p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>
                            職業 <br />
                            Occupation
                          </td>
                          <td style={{ width: "50%", verticalAlign: "bottom" }}>
                            <p className="name-line">
                              {myInfo?.jobTitleDetails_name}{" "}
                            </p>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td style={{ verticalAlign: "top" }}>
                      <table width="100%" className="line-table">
                        <tr>
                          <td style={{ width: "50%" }}>
                            稱謂
                            <br />
                            Title
                          </td>
                          <td style={{ width: "50%", verticalAlign: "bottom" }}>
                            <p className="name-line">
                              {CURTSYTITLE[myInfo?.users_title]}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>
                            別稱
                            <br />
                            Nickname
                          </td>
                          <td style={{ width: "50%", verticalAlign: "bottom" }}>
                            <p className="name-line">
                              {myInfo?.users_preferredNickName}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>
                            出生日期
                            <br />
                            Date of Birth
                          </td>
                          <td style={{ width: "50%", verticalAlign: "bottom" }}>
                            <p className="name-line">
                              {formatDate(myInfo?.users_DOB, "DD-MM-YYYY")}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "50%" }}>
                            流動電話 <br /> Mobile Phone No.
                          </td>
                          <td style={{ width: "50%", verticalAlign: "bottom" }}>
                            <p className="name-line">
                              +{myInfo?.users_dialCode || ""}{" "}
                              {myInfo.users_phoneNumber}
                            </p>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table width="100%">
                  <tr>
                    <td style={{ width: "30%" }}>
                      通訊地址
                      <br />
                      Correspondence Address
                    </td>
                    <td style={{ width: "70%", verticalAlign: "bottom" }}>
                      <p className="name-line">
                        {myInfo?.users_address ||
                          myInfo?.districtLanguageDetails_name}
                      </p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ paddingBottom: "20px", paddingTop: "20px" }}>
                如遇緊急事故，可聯絡以下人士 Emergency Contact Person:
              </td>
            </tr>
            <tr>
              <td>
                <table width="100%">
                  <tr>
                    <td style={{ width: "33%" }}>
                      <table width="100%" className="line-table">
                        <tr>
                          <td style={{ width: "30%" }}>
                            姓名
                            <br />
                            Name
                          </td>
                          <td
                            style={{
                              width: "70%",
                              verticalAlign: "bottom",
                              paddingRight: "15px",
                            }}
                          >
                            <p className="name-line">
                              {myInfo?.users_emergencyContactName}
                            </p>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td style={{ width: "33%" }}>
                      <table width="100%" className="line-table">
                        <tr>
                          <td style={{ width: "30%" }} className="gfg">
                            關係 <br /> Relationship
                          </td>
                          <td
                            style={{
                              width: "70%",
                              verticalAlign: "bottom",
                              paddingRight: "15px",
                            }}
                          >
                            <p className="name-line">
                              {myInfo?.users_emergencyContactRelationship}
                            </p>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td style={{ width: "33%" }}>
                      <table width="100%" className="line-table">
                        <tr>
                          <td style={{ width: "30%" }} className="gfg">
                            電話 <br /> Phone
                          </td>
                          <td style={{ width: "70%", verticalAlign: "bottom" }}>
                            <p className="name-line">
                              {myInfo?.users_emergencyContactNumber}
                            </p>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td>申請人聲明</td>
            </tr>
            <tr>
              <td>
                <ol>
                  <li>本人謹此聲明在此報名表格中填報的資料均屬完整正確。</li>
                  <li>
                    本人明白若提供任何虛假及誤導性的資料，本人的申請資格將被取消。雖經註冊，亦屬無效，而一切已繳費用，概不退還。
                  </li>
                  <li>
                    本人如獲取錄入讀，本人當遵守Forex Forest Limited的規則。
                  </li>
                  <li>
                    本人已細閱、明白並同意「條款及細則」、「個人資料私隱及收集聲明」及申請協議的內容。
                  </li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>
                <table width="100%">
                  <tr>
                    <td
                      style={{
                        width: "40%",
                        height: "200px",
                        verticalAlign: "bottom",
                      }}
                    >
                      {myInfo.student_signaturePath ||
                      myInfo.users_signaturePath ? (
                        <div>
                          <img
                            src={
                              myInfo.student_signaturePath ||
                              myInfo.users_signaturePath
                            }
                            alt="signature"
                            style={{
                              height: "150px",
                            }}
                          />
                        </div>
                      ) : null}
                      <p className="name-line"></p>
                      <p>
                        姓名:{" "}
                        {myInfo?.users_englishFirstName +
                          " " +
                          myInfo?.users_englishLastName}{" "}
                        <span style={{ float: "right" }}>
                          日期：{getCurrentDatetimeWithTimezone()}
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "20%" }}></td>
                    <td
                      style={{
                        width: "40%",
                        height: "200px",
                        verticalAlign: "bottom",
                      }}
                    >
                      <img
                        src={StampLogo}
                        style={{ maxWidth: "25%" }}
                        alt="Logo"
                      />
                      <p className="name-line"></p>
                      <p>
                        公司：Forex Forest Limited
                        <span style={{ float: "right" }}>
                          日期：{getCurrentDatetimeWithTimezone()}
                          {/* // {moment(new Date()).add(3, 'hours').format("YYYY-MM-DD HH:MM")} */}
                          {/* {console.log("Here", (moment.tz(new Date().toString(), 'Asia/India')).clone().tz('Asia/Hong_Kong').format("YYYY-MM-DD HH:MM"))} */}
                          {/* 日期：{moment(new Date().toLocaleDateString(
                            'zh-HK', { timeZone: 'Asia/Hong_Kong', hour12: false })).format("YYYY-MM-DD HH:MM")} */}
                          {/* aa */}
                        </span>
                      </p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                注意:
                此課程及工具的所有內容及資料只供模擬測試及學述交流之用，並不構成任何與投資有關之邀約、招攬、邀請、誘使、申述、建議或推薦。投資涉及風險，投資者應就本身投資經驗、投資目標、財政資源、風險承擔能力及其他相關條件，小心衡量自己是否適合參與任何投資項目。
              </td>
            </tr>
          </table>
        </div>
      ) : null}
    </div>
  );
};

export default PrintUserInfo;
